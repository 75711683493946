import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { SSButton } from '@simplisafe/ss-react-components'
import { SensorProductBanner } from '@simplisafe/ss-react-components'
import { ButtonColor } from '@simplisafe/ss-react-components/SSButton'
import { graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import T from 'ramda/src/T'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulProductPageHeroSensor } from '../../../graphql'
import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import { leadingSlashIt, strToCamelCase } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'
import ModalComponent from '../ModalComponent'

export type SensorProductBannerComponentProps = {
  readonly data: Partial<ContentfulProductPageHeroSensor>
}

export default function SensorProductBannerComponent({
  data
}: SensorProductBannerComponentProps) {
  // TODO: fix type

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const image = prop('media', data)
  const addSensorToCartModal = prop('addSensorToCartModal', data)
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const buttonUrl = leadingSlashIt(
    pathOr<string, string>('', ['button', 'url'], data)
  )
  const buttonColor = strToCamelCase(pathOr('', ['button', 'type'], data))
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const buttonText = pathOr<ReactNode, ReactNode>(
    '',
    ['button', 'buttonText'],
    data
  )

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const sku = safePath(
    ['modalContent', 'productId'],
    addSensorToCartModal
  ).getOrElse('')
  const product = useSelector(selectProduct(sku))
  const isOnStock = product.cata(T, p => (prop('isOnStock', p) ? true : false))
  const isSellable = product.cata(T, p =>
    prop('isSellable', p) ? true : false
  )
  const outOfStockBannerText = prop('outOfStockBannerText', data)

  const notSellableText: ReactNode =
    (!isSellable || !isOnStock) && outOfStockBannerText ? (
      <p className="paragraph textSizeXS font-medium mt-0.5">
        {outOfStockBannerText}
      </p>
    ) : null
  return (
    <SensorProductBanner
      button={
        <Link to={buttonUrl}>
          <SSButton
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            color={buttonColor as ButtonColor}
            type="div"
          >
            {buttonText}
          </SSButton>
        </Link>
      }
      description={
        <>
          <ContentfulRichText raw={data?.description?.raw} />
          {notSellableText}
        </>
      }
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      headerText={propOr<string, string>('', 'headerText', data)}
      image={
        <GatsbyImage
          alt={image?.description || ''}
          image={getImage(image)}
          // @ts-expect-error TS(2322) FIXME: Type '{ alt: any; image: IGatsbyImageData; style: ... Remove this comment to see the full error message
          style={{
            height: '100%',
            width: '100%'
          }}
        />
      }
      outOfStockMessage={
        isSellable && renderOutOfStockMessage({ product: product })
      }
      popupButton={
        addSensorToCartModal && <ModalComponent data={addSensorToCartModal} />
      }
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      productName={propOr<string, string>('', 'sensorName', data)}
    />
  )
}

export const query = graphql`
  #graphql
  fragment sensorProductBannerFragment on ContentfulProductPageHeroSensor {
    id
    internal {
      type
    }
    button {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    description {
      raw
    }
    headerText
    media: image {
      description # TODO get description from gatsbyImageData
      gatsbyImageData(
        layout: CONSTRAINED
        width: 700
        quality: 90
        placeholder: BLURRED
      )
      title
    }
    addSensorToCartModal {
      ... on ContentfulModal {
        ...modalFragment
      }
    }
    outOfStockBannerText
    title
    sensorName
  }
`
