import { Column, Row, Text } from '@simplisafe/ss-react-components'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import { Maybe } from 'monet'
import React from 'react'

import usePromoBannerData from './usePromoBannerData'

const getEndsTextTag = (text: string) => (
  <span
    style={{
      color: 'var(--promo-secondary-text)',
      textTransform: 'uppercase'
    }}
  >
    {text}
  </span>
)

// Intended to show on the /cart page for mobile, tablet, desktop.
function CartLayout() {
  const {
    displayEndTimeCalloutOverride,
    endTime,
    endTimeCalloutOverride,
    locale
  } = usePromoBannerData()
  const endsSoonText = Promo.soon(locale)

  const endDate = displayEndTimeCalloutOverride
    ? endTimeCalloutOverride
    : endTime

  const endsTextMarkup = Maybe.fromFalsy(endDate)
    .map((dateString: string) => new Date(dateString))
    .cata(
      () => getEndsTextTag(endsSoonText),
      dateInstance => {
        const isEndsSoonText =
          Promo.isExpired(dateInstance) ||
          Promo.isMoreThan7DaysAway(dateInstance)
        return isEndsSoonText ? (
          getEndsTextTag(endsSoonText)
        ) : (
          <>
            <span>by</span>{' '}
            {getEndsTextTag(Promo.relativeEndTime(dateInstance, locale))}
          </>
        )
      }
    )

  return (
    <Row dataComponent="CartLayout" inheritTextColor={true} rounded="none">
      <Column alignSelf="center" rounded="none">
        <Text
          inheritTextColor={true}
          textAlignment="center"
          textSize="lg"
          useTailwind={true}
        >
          <strong>Checkout {endsTextMarkup} for this offer</strong>
        </Text>
      </Column>
    </Row>
  )
}

export default CartLayout
