import React from 'react'

type InfoButtonProps = {
  readonly onClick: () => void
}

function InfoButton({ onClick }: InfoButtonProps) {
  return (
    <svg
      data-component="InfoButton"
      height="21"
      id="InfoButton"
      onClick={onClick}
      style={{
        color: 'var(--btn-primary)',
        cursor: 'pointer'
      }}
      viewBox="0 0 21 21"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(3 3)">
          <path d="M9.228 5.208c0 .078-.052.13-.13.13H7.902c-.078 0-.13-.052-.13-.13V4.077c0-.078.052-.13.13-.13h1.196c.078 0 .13.052.13.13v1.131zm0 7.449c0 .078-.052.13-.13.13H7.902c-.078 0-.13-.052-.13-.13V6.651c0-.078.052-.13.13-.13h1.196c.078 0 .13.052.13.13v6.006zM8.5 0C3.805 0 0 3.806 0 8.5S3.805 17 8.5 17c4.695 0 8.5-3.806 8.5-8.5S13.194 0 8.5 0z" />
          <path
            d="M9.098 6.521H7.902c-.078 0-.13.053-.13.13v6.007c0 .078.052.13.13.13h1.196c.078 0 .13-.053.13-.13V6.652c0-.079-.052-.13-.13-.13M9.098 3.947H7.902c-.078 0-.13.052-.13.13v1.131c0 .078.052.13.13.13h1.196c.078 0 .13-.052.13-.13v-1.13c0-.079-.052-.13-.13-.13"
            fill="currentColor"
          />
          <circle cx="8.5" cy="8.5" r="7.5" stroke="currentColor" />
        </g>
      </g>
    </svg>
  )
}

export default InfoButton
