import { params } from '@lib/tracking'
import { setAwinCookie } from '@simplisafe/ss-ecomm-data/thirdparty/awin'
import { StringParam, useQueryParam } from 'use-query-params'

const useAwinTracking = (enabled = true) => {
  const [awnContentValue] = useQueryParam(params.awc, StringParam)

  enabled && awnContentValue && setAwinCookie(awnContentValue || '')

  return awnContentValue
}

export default useAwinTracking
