import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { toSiteColor } from '@lib/components'
import { selectPromoDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { FloatingBadge } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import map from 'ramda/src/map'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulBadgeText } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import { spanWrap } from '../ContentfulRichText/common'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'

export type BadgeTextProps = {
  readonly data: Partial<ContentfulBadgeText>
}

export const getBadgeDiscount = (discountText: string) => ({
  'Discount Percentage': discountText.replace('%', '')
})

export default function BadgeText({ data }: BadgeTextProps) {
  /**
   * GraphQL alias don't type correctly
   * https://simplisafe.atlassian.net/browse/ECP-4325
   */
  const badgeText = data.text?.raw
  const [showBadgeText, setShowBadgeText] = useState(false)
  const [placeholders, setPlaceholders] = useState({})

  // TODO: Gatsby 4 rich text
  const options: Options = {
    renderNode: {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'import("/home/joshderocher-vlk/D... Remove this comment to see the full error message
      [INLINES.EMBEDDED_ENTRY]: node => renderEmbeddedEntry(node, placeholders),
      [BLOCKS.HEADING_3]: (__: unknown, text) => {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const header3 = map(spanWrap, text as string)

        return <h3 className={'h3'}>{header3}</h3>
      }
    }
  }
  // TODO: Gatsby 4 rich text - handle custom rendering options
  const floatingBadgeContent = (
    <ContentfulRichText /*optionsCustom={options}*/ raw={badgeText} />
  )

  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountText = useSelector(selectPromoDiscountText)
  const shouldShowBadge = showBadgeText && !isPromoTopBanner

  useEffect(() => {
    discountText.cata(
      () => setShowBadgeText(false),
      text => {
        setShowBadgeText(true)
        setPlaceholders(getBadgeDiscount(text))
      }
    )
  }, [discountText])

  return shouldShowBadge ? (
    <div
      style={{
        bottom: 0,
        position: 'absolute',
        right: 0
      }}
    >
      <FloatingBadge
        backgroundColor={toSiteColor('brandPrimary')}
        borderColor={toSiteColor('brandPrimary')}
        floatingBadgeContent={floatingBadgeContent}
        textColor={toSiteColor('neutralWhite')}
      />
    </div>
  ) : null
}

export const BadgeTextQuery = graphql`
  #graphql
  fragment badgeText on ContentfulBadgeText {
    id
    internal {
      type
    }
    title
    promoCode
    badgeText_text: text {
      raw # todo
    }
  }
`
