import { AccordionGroup as AccordionGroupComponent } from '@lib/components'
import { GatsbyImage } from '@lib/components'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'

import {
  ContentfulAccordion,
  ContentfulAccordionGroup,
  ContentfulAsset
} from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

export type AccordionGroupProps = {
  readonly data: ContentfulAccordionGroup
}

export default function AccordionGroup({ data }: AccordionGroupProps) {
  const { images, accordionGroup } = data

  const getAccordions = (
    row: ReadonlyArray<ContentfulAccordion | null>
  ): readonly ContentfulAccordion[] =>
    row.filter((item): item is ContentfulAccordion => !!item)

  const getImages = (
    row: ReadonlyArray<ContentfulAsset | null>
  ): readonly ContentfulAsset[] =>
    row.filter((item): item is ContentfulAsset => !!item)

  const accordionProps =
    accordionGroup &&
    getAccordions(accordionGroup).map((accordion: ContentfulAccordion) => ({
      children: <ContentfulRichText raw={accordion?.description?.raw} />,
      id: accordion.id,
      isExpanded: false,
      key: accordion.id,
      theme: 'group',
      title: accordion?.accordionTitle?.accordionTitle || ''
    }))

  const mappedImages =
    images &&
    getImages(images).map((image: ContentfulAsset) => (
      <GatsbyImage
        alt={image.title || ''}
        // @ts-expect-error TS(2345)
        image={getImage(image)}
        imgStyle={{}}
        key={image.title}
        style={{
          height: '100%',
          objectFit: 'cover',
          width: '100%'
        }}
      />
    ))

  return (
    <AccordionGroupComponent
      accordions={accordionProps ?? []}
      images={mappedImages ?? []}
    />
  )
}
