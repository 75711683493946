import { PhoneCTA } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulPhoneCta } from '../../../graphql'

export type PhoneCTAComponentProps = {
  readonly data: ContentfulPhoneCta
}

function PhoneCTAComponent({ data }: PhoneCTAComponentProps) {
  const { id, phone, text } = data

  return phone && text ? <PhoneCTA key={id} phone={phone} text={text} /> : null
}

export default PhoneCTAComponent

export const PhoneCTAQuery = graphql`
  #graphql
  fragment phoneCTA on ContentfulPhoneCta {
    id
    internal {
      type
    }
    phone
    text
  }
`
