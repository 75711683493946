import { FormField, SSButton } from '@simplisafe/ss-react-components'
import React from 'react'

type SubmissionProps = {
  readonly submitButtonLabel: string
  readonly submitButtonDisabled: boolean
}

function Submission(props: SubmissionProps) {
  return (
    <div
      className={
        'rc-col-span-12 md:rc-col-span-4 rc-mt-4 md:rc-mt-0 md:rc-ml-3'
      }
      data-component={'Submission'}
    >
      <FormField
        hideLabel={true}
        label={props.submitButtonLabel}
        labelPosition={'top'}
        name="submit"
      >
        <SSButton
          disabled={props.submitButtonDisabled}
          minWidth="small"
          type="submit"
        >
          {props.submitButtonLabel}
        </SSButton>
      </FormField>
    </div>
  )
}

export default Submission
