import { handleAtAtTrackingEvent } from '../atat'
import { getUserId, getVisitorId } from '../cookies'
import { handleGTMTrackingEvent } from '../gtm'
import type { TrackingData } from '../types/tracking'

// Handler for tracking calls for the current event and tracking data
export const handleTrackingEvent = (data: TrackingData) => {
  // Augment data from the event with values that can potentially change after page load
  const augmentedData: TrackingData = {
    ...data,
    userId: getUserId(),
    visitorId: getVisitorId()
  }

  handleGTMTrackingEvent(augmentedData)
  handleAtAtTrackingEvent(augmentedData)
}
