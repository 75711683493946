import { GiftItemDTO } from '@simplisafe/ss-ecomm-data/prices/service'
import { useCookies } from 'react-cookie'
import Cookies, { CookieSetOptions } from 'universal-cookie'

export const cookies = new Cookies()

export const getAtAtToken = (): string | undefined =>
  cookies.get(COOKIE_ATAT_TOKEN)
export const getChatAppId = (): string => cookies.get(COOKIE_CHAT_APP_ID)
export const getChatOpened = (): string => cookies.get(COOKIE_CHAT_OPENED)
export const getUserId = (): string => cookies.get(COOKIE_DRUPAL_UID)
export const getLeadData = () => cookies.get(COOKIE_LEAD_DATA)
export const getMonitoringPlan = (): string =>
  cookies.get(COOKIE_MONITORING_PLAN)
export const getMonitoringGiftItems = (): GiftItemDTO =>
  cookies.get(COOKIE_MONITORING_GIFT_ITEM)
export const getNonMonitoringGiftItems = (): GiftItemDTO =>
  cookies.get(COOKIE_NON_MONITORING_GIFT_ITEM)
export const getPurchasedCartId = (): string =>
  cookies.get(COOKIE_PURCHASED_CART_ID)
export const getVisitorId = () => cookies.get(COOKIE_VID)

export const COOKIE_ATAT_TOKEN = 'atat_v2'
export const COOKIE_CHAT_APP_ID = 'chat_app_id'
export const COOKIE_CHAT_OPENED = 'chat_opened'
export const COOKIE_DRUPAL_UID = 'DRUPAL_UID'
export const COOKIE_FCP_ORDER_ID = 'FCP_ORDER_ID'
export const COOKIE_LEAD_DATA = 'leadData'
export const COOKIE_MONITORING_PLAN = 'mp'
export const COOKIE_MONITORING_GIFT_ITEM = 'monitoringGiftItem'
export const COOKIE_NON_MONITORING_GIFT_ITEM = 'nonMonitoringGiftItem'
export const COOKIE_PREACTIVATION = 'ssOauthAccessToken'
export const COOKIE_PURCHASED_CART_ID = 'purchasedCartId'
export const COOKIE_BRAZE_PROMO_VIEWED = 'braze_promo_viewed'
export const COOKIE_BRAZE_SITE_VISITED = 'braze_site_visited_fired'
export const COOKIE_VID = 'vid'

export type CookieNames =
  | typeof COOKIE_ATAT_TOKEN
  | typeof COOKIE_CHAT_APP_ID
  | typeof COOKIE_CHAT_OPENED
  | typeof COOKIE_DRUPAL_UID
  | typeof COOKIE_FCP_ORDER_ID
  | typeof COOKIE_LEAD_DATA
  | typeof COOKIE_MONITORING_GIFT_ITEM
  | typeof COOKIE_MONITORING_PLAN
  | typeof COOKIE_NON_MONITORING_GIFT_ITEM
  | typeof COOKIE_PREACTIVATION
  | typeof COOKIE_PURCHASED_CART_ID
  | typeof COOKIE_VID

export type Cookie = Record<CookieNames, string | undefined>

export const useTrackingCookies = (): readonly [
  Cookie,
  <T>(name: CookieNames, value: T, options?: CookieSetOptions) => void,
  (name: CookieNames, options?: CookieSetOptions) => void
] => {
  const [cookies, setCookie, removeCookie] = useCookies<CookieNames, Cookie>([
    COOKIE_ATAT_TOKEN,
    COOKIE_CHAT_APP_ID,
    COOKIE_CHAT_OPENED,
    COOKIE_DRUPAL_UID,
    COOKIE_FCP_ORDER_ID,
    COOKIE_LEAD_DATA,
    COOKIE_MONITORING_PLAN,
    COOKIE_MONITORING_GIFT_ITEM,
    COOKIE_NON_MONITORING_GIFT_ITEM,
    COOKIE_PREACTIVATION,
    COOKIE_PURCHASED_CART_ID,
    COOKIE_VID
  ])
  return [cookies, setCookie, removeCookie]
}
