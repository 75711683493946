import { useLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import {
  selectActivePromoOverrideDiscountText,
  selectMonthsOfServiceDisplay
} from '@simplisafe/ss-ecomm-data/redux/select'
import { SSButton } from '@simplisafe/ss-react-components'
import { ProductPlanProps } from '@simplisafe/ss-react-components/ProductPlan'
import { Maybe, None } from 'monet'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  ContentfulExpandableMonitoringPlan,
  ContentfulLinkAddToCart,
  ContentfulModal,
  ContentfulPopupButton,
  ContentfulProductPlan,
  ContentfulRichTextWithOptions
} from '../../../graphql'
import { formatDisplayPrice, isBmsSku } from '../../commercetools/price'
import useRequestPrice from '../../hooks/useRequestPrice'
import { PriceProvider } from '../../providers/PriceProvider'
import type { TrackEvent } from '../../util/analytics'
import { configureServicePlanTextPlaceholders } from '../../util/helper'
import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'
import LinkAddToCart from '../LinkAddToCart'
import ModalComponent from '../ModalComponent'
import renderCompareTable from './CompareTable'
import LimitedProMonitoringModal from './LimitedProMonitoringModal'
import ProductPlan from './ProductPlan'
import getRenderStateOne from './showLimitedPro'
import {
  comparePlansGTM,
  showOtherPlansGTM,
  trackEventChoosePlan
} from './trackingEvents'

// TODO add types to all function arguments in this file

export type ExpandableMonitoringPlanComponentProps = {
  readonly data: Partial<ContentfulExpandableMonitoringPlan>
}

export type MonitoringPlanTypeTokens =
  | 'interactive'
  | 'pro premium'
  | 'pro'
  | 'self'
  | 'standard'

export const getMonitoringConfirmModal = (
  button: Partial<ContentfulModal>,
  trackEvent: TrackEvent,
  titlePlan: string,
  monthsOfFreeService: string
) => {
  const buttonText: string = pathOr('', ['clickTarget', 'buttonText'], button)
  const clickTargetStyles: React.CSSProperties = { display: 'block' }
  return (
    button && (
      <ModalComponent
        clickTarget={
          <SSButton color="primary">
            {configureServicePlanTextPlaceholders(
              buttonText,
              monthsOfFreeService
            )}
          </SSButton>
        }
        clickTargetStyles={clickTargetStyles}
        // @ts-expect-error TS(2322) FIXME: Type '{ modalContent: ContentfulBannerContentfulFi... Remove this comment to see the full error message
        data={{
          modalContent: prop('modalContent', button),
          modalSize: prop('modalSize', button)
        }}
        onClick={() => {
          trackEventChoosePlan(trackEvent, titlePlan)
        }}
      />
    )
  )
}

export const getAddPlanButton = (button: Partial<ContentfulLinkAddToCart>) => {
  return button && <LinkAddToCart data={button} />
}

export const getPackageDiscountValue = (
  servicePlanId: string,
  productId: string,
  price: Maybe<number>,
  discountedPrice: Maybe<number>,
  discountedPriceWithServicePlan: Maybe<number>
) =>
  ifElse(
    equals(servicePlanId),
    () => discountedPriceWithServicePlan,
    () => discountedPrice
  )(productId)
    .chain(discount => price.map(_price => _price - discount))
    .chain(formatDisplayPrice)

export const getPackageDiscount = (
  servicePlanId: string,
  productId: string,
  discountedText: Maybe<string>,
  discountedTextWithServicePlan: Maybe<string>
) =>
  ifElse(
    equals(servicePlanId),
    () => discountedTextWithServicePlan,
    () => discountedText
  )(productId)

/**
 * If Package is a BMS package, render the absolute/relative discount directly from the pricing service
 * Otherwise, render the price difference between the original and discounted price
 */
export const useFormattedPackageDiscount = (
  servicePlanId: string,
  productId: string,
  sku: string
) => {
  const {
    getPrice,
    getDiscountedPrice,
    getDiscountedPriceWithServicePlan,
    getDiscountedText,
    getDiscountedTextWithServicePlan
  } = useRequestPrice(sku)

  return ifElse(
    isBmsSku,
    () =>
      getPackageDiscount(
        servicePlanId,
        productId,
        getDiscountedText,
        getDiscountedTextWithServicePlan
      ),
    () =>
      getPackageDiscountValue(
        servicePlanId,
        productId,
        getPrice,
        getDiscountedPrice,
        getDiscountedPriceWithServicePlan
      )
  )(sku)
}

const renderButton = (
  data: ContentfulPopupButton | Record<string, unknown>,
  onclick: () => void
) => {
  const textButton: string = propOr('', 'text', data)
  const textButtonContainer = (
    <div
      style={{
        textAlign: 'center',
        width: '100%'
      }}
    >
      <SSButton color={'primaryOutline'} onClick={onclick} target="_blank">
        {textButton}
      </SSButton>
    </div>
  )
  return ifElse(
    equals(''),
    () => null,
    () => textButtonContainer
  )(textButton)
}

const getProductPlanProductId = (
  data: Partial<ContentfulExpandableMonitoringPlan>,
  planTypeToken: MonitoringPlanTypeTokens
): readonly [string, Partial<ContentfulProductPlan>] => {
  const monitoringPlans: readonly ProductPlanProps[] = pathOr(
    [],
    ['monitoringPlans'],
    data
  )
  const monitoringPlan = monitoringPlans.filter(plan => {
    return plan.planType === planTypeToken
  })
  return [
    propOr('', 'productId', monitoringPlan[0]),
    propOr({}, '0', monitoringPlan)
  ]
}

export default function ExpandableMonitoringPlanComponent({
  data
}: ExpandableMonitoringPlanComponentProps) {
  const isUS = equals('en-US', process.env.LOCALE)
  const { Track, trackEvent } = useTracking()
  const [isShowComparePlan, setShowComparePlan] = useState(false)
  const [isShowOtherPlans, setIsShowOtherPlans] = useState(false)
  const [displayOtherPlans, setDisplayOtherPlans] = useState(false)
  const location = useLocation()
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const _packageSku = safePath(['state', 'packageSku'], location)
  const comparePlansButton: ContentfulPopupButton | Record<string, unknown> =
    pathOr({}, ['comparePlansButton'], data)
  const showOtherPlansButton: ContentfulPopupButton | Record<string, unknown> =
    pathOr({}, ['showOtherPlansButton'], data)
  // TODO: move these monitoring plan strings into Contentful.
  const professionalMonitoringTitle: MonitoringPlanTypeTokens = isUS
    ? 'interactive'
    : 'pro premium'
  const cameraRecordingsOnlyTitle: MonitoringPlanTypeTokens = isUS
    ? 'self'
    : 'pro'
  const limitedProMonitoringCTA = data?.limitedProMonitoringCTA?.raw
  const topRichText =
    data?.limitedProMonitoringModal?.topRichText?.richText?.raw || ''
  const leftRichTextWithOptions: ContentfulRichTextWithOptions | undefined =
    path(['limitedProMonitoringModal', 'leftRichTextWithOptions'], data)
  const rightRichTextWithOptions: ContentfulRichTextWithOptions | undefined =
    path(['limitedProMonitoringModal', 'rightRichTextWithOptions'], data)
  const bottomRichTextWithOptions: ContentfulRichTextWithOptions | undefined =
    path(['limitedProMonitoringModal', 'bottomRichTextWithOptions'], data)
  const acceptButton: Partial<ContentfulLinkAddToCart> | undefined = path(
    ['limitedProMonitoringModal', 'happyButton'],
    data
  )
  const limitedProMonitoringId: string = pathOr(
    '',
    ['limitedProMonitoringModal', 'productId'],
    data
  )
  const modalClickTargetText: string =
    path(['limitedProMonitoringModal', 'clickTarget', 'text'], data) || ''
  const alwaysShowOtherPlans: boolean = safePath(
    ['alwaysShowOtherPlans'],
    data
  ).getOrElse(false)

  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const theme: string = propOr<string, string>('', 'theme', data)
  const [proMonitoringProductId, proMonitoringPlan] = getProductPlanProductId(
    data,
    professionalMonitoringTitle
  )
  const [cameraRecordingsOnlyProductId, cameraRecordingsOnlyPlan] =
    getProductPlanProductId(data, cameraRecordingsOnlyTitle)

  // Type guard _packageSku because useLocation does not allow types
  const packageSku = _packageSku
    .map(val => (typeof val === 'string' ? val : ''))
    .orJust('')

  const monthsOfFreeService: string = useSelector(
    selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer())
  )

  const overrideTextMaybe = useSelector(selectActivePromoOverrideDiscountText)
    .chain(safeProp('chooseMonitoringPage'))
    .chain(val => val)
  const hasOverrideText = !overrideTextMaybe.isNone()

  const hideOtherPlans = useCallback(() => {
    setDisplayOtherPlans(true)
  }, [])

  const showOtherPlans = useCallback(() => {
    setDisplayOtherPlans(false)
  }, [])

  useEffect(() => {
    isShowOtherPlans ? hideOtherPlans() : showOtherPlans()
  }, [isShowOtherPlans, hideOtherPlans, showOtherPlans])

  useEffect(() => {
    alwaysShowOtherPlans && setIsShowOtherPlans(true)
  }, [alwaysShowOtherPlans])

  const islimitedProMonitoringModal: boolean = getRenderStateOne(
    isShowComparePlan,
    leftRichTextWithOptions,
    rightRichTextWithOptions,
    limitedProMonitoringCTA,
    topRichText
  )

  return (
    <Track>
      <PriceProvider
        skus={[
          packageSku,
          proMonitoringProductId,
          cameraRecordingsOnlyProductId,
          limitedProMonitoringId
        ]}
      >
        <div>
          <ProductPlan
            index={professionalMonitoringTitle}
            item={proMonitoringPlan}
            monthsOfFreeService={monthsOfFreeService}
            offerValue={hasOverrideText ? overrideTextMaybe : None()}
            packageSku={packageSku}
            productSku={proMonitoringProductId}
            theme={theme}
            trackEvent={trackEvent}
          />
          {displayOtherPlans && (
            <div data-test="CameraRecordingsOnlyPlan">
              <ProductPlan
                index={cameraRecordingsOnlyTitle}
                item={cameraRecordingsOnlyPlan}
                monthsOfFreeService={monthsOfFreeService}
                offerValue={None()}
                packageSku={packageSku}
                productSku={cameraRecordingsOnlyProductId}
                trackEvent={trackEvent}
              />
            </div>
          )}
          {!displayOtherPlans && (
            <div data-test="ShowOtherPlansButton">
              {renderButton(showOtherPlansButton, () => {
                showOtherPlansGTM(trackEvent)
                setIsShowOtherPlans(true)
              })}
            </div>
          )}
          {isShowOtherPlans &&
            !isShowComparePlan &&
            renderButton(comparePlansButton, () => {
              comparePlansGTM(trackEvent)
              setShowComparePlan(true)
            })}
          {isShowComparePlan && renderCompareTable(data)}
          {islimitedProMonitoringModal && (
            <LimitedProMonitoringModal
              acceptButton={acceptButton}
              bottomRichTextWithOptions={bottomRichTextWithOptions}
              leftRichTextWithOptions={leftRichTextWithOptions}
              // @ts-expect-error TS(2345): Argument of type 'Maybe<string> | undefined' is no... Remove this comment to see the full error message
              limitedProMonitoringCTA={limitedProMonitoringCTA}
              limitedProMonitoringSku={limitedProMonitoringId}
              modalClickTargetText={modalClickTargetText}
              rightRichTextWithOptions={rightRichTextWithOptions}
              topRichText={topRichText}
              trackEvent={trackEvent}
            />
          )}
        </div>
      </PriceProvider>
    </Track>
  )
}
