import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly name: string
  readonly label: string
  readonly placeholder: string
}

function GetAQuoteInput({ name, label, placeholder }: Props) {
  return (
    <div className="my-7" data-component={name}>
      <FormField hideLabel={true} label={label} name={name}>
        <Text fontWeight="medium">{label}</Text>
        <FormTextInput
          fullWidth={true}
          id={name}
          name={name}
          placeholder={placeholder}
        />
      </FormField>
    </div>
  )
}

export default GetAQuoteInput
