import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Grid } from '@simplisafe/ss-react-components'
import { GridProps, SocialLinks } from '@simplisafe/ss-react-components/Grid'
import { GridItemProps } from '@simplisafe/ss-react-components/GridItem'
import {
  Facebook,
  IconProps,
  Linkedin,
  Pinterest,
  Twitter,
  Youtube
} from '@simplisafe/ss-react-components/icons'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import contains from 'ramda/src/contains'
import defaultTo from 'ramda/src/defaultTo'
import head from 'ramda/src/head'
import map from 'ramda/src/map'
import split from 'ramda/src/split'
import toLower from 'ramda/src/toLower'
import React from 'react'
import { pipe } from 'ts-functional-pipe'

import { ContentfulPressKitGrid } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

function toHoverIcon<
  T extends {
    readonly title?: string
    readonly id?: string
    readonly file?: { readonly url?: string }
  }
>(icon: T) {
  const alt = prop('title', icon)
  const key = prop('id', icon)

  return <img alt={alt} key={key} src={path(['file', 'url'], icon)} />
}

const toProps = applySpec<IconProps>({
  dynamicClassName: pipe(split(' '), head, defaultTo(''), toLower)
})

const toSvgIcon = (icon: any) => {
  // TODO: Gatsby 4 rich text: Icons will need to be refactored too
  return pipe(
    prop('title'),
    defaultTo(''),
    toLower,
    cond([
      [contains('twitter'), (prop: any) => <Twitter {...toProps(prop)} />],

      [contains('pinterest'), (prop: any) => <Pinterest {...toProps(prop)} />],

      [contains('facebook'), (prop: any) => <Facebook {...toProps(prop)} />],

      [contains('youtube'), (prop: any) => <Youtube {...toProps(prop)} />],

      [contains('linkedin'), (prop: any) => <Linkedin {...toProps(prop)} />]
    ])
  )(icon)
}

const toSocialMediaIcon = applySpec<SocialLinks>({
  icon: pipe(path(['icon', '0']), toSvgIcon),
  id: prop('id'),
  linkText: prop('linkTitle'),
  linkUrl: prop('linkUrl')
})

const toGridItem = applySpec<GridItemProps>({
  hoverIcon: pipe(prop('hoverIcon'), toHoverIcon),
  hoverText: prop('hoverText'),
  id: prop('id'),
  image: prop('image')
})

const gridProps = applySpec<GridProps>({
  content: (data: Partial<ContentfulPressKitGrid>) => (
    <ContentfulRichText raw={data.description?.raw} />
  ),
  gridContent: (data: GridProps) => {
    return safeProp('gridContent', data)
      .map(gridItem =>
        gridItem.map((content, i) => {
          const height = i !== 0 ? undefined : { height: 'auto' }
          const image = prop('image', content)
          return {
            ...toGridItem(content),
            imageList: image && (
              <GatsbyImage
                // TODO: fix type
                className="styles-image"
                // TODO: fix type
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                image={image}
                imgStyle={{
                  bottom: '0',
                  margin: 'auto',
                  maxWidth: '100%',
                  ...height
                }}
                // TODO: fix type
                // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'string'.
                key={image?.id}
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            )
          }
        })
      )
      .orUndefined()
  },
  socialLinks: pipe(
    prop('socialMediaIcons'),
    map(toSocialMediaIcon),
    defaultTo([])
  ),
  title: prop('title')
})

type PressKitGridComponentProps = {
  readonly data: Partial<ContentfulPressKitGrid>
}

export default function PressKitGridComponent({
  data
}: PressKitGridComponentProps) {
  const gridDataProps = gridProps(data)
  return (
    <>
      <style>
        {`
            .styles-image {
              &:hover {
                opacity: 0.35;
              }
            }
          `}
      </style>
      <Grid {...gridDataProps} />
    </>
  )
}

export const query = graphql`
  #graphql
  fragment pressKitGridFragment on ContentfulPressKitGrid {
    id
    internal {
      type
    }
    description {
      raw
    }
    title
    socialMediaIcons {
      linkUrl
      linkTitle
      id
      icon {
        title
        description
        gatsbyImageData
      }
    }
    gridContent {
      hoverColor
      hoverIcon {
        id
        title
        file {
          url
        }
      }
      hoverText
      id
      image {
        id
        title
        description
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
        file {
          url
        }
      }
    }
  }
`
