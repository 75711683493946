import { LoadingSpinner } from '@simplisafe/ss-react-components'
import React from 'react'

import { IPlaceModel } from './types'

type SuggestionsListProps = {
  readonly showSuggestions: boolean
  readonly isLoading: boolean
  readonly errorMsg: string
  readonly suggestions: readonly IPlaceModel[]
  readonly onSelectedSuggestion: (index: number) => void
}

function SuggestionsList({
  showSuggestions,
  isLoading,
  errorMsg,
  suggestions,
  onSelectedSuggestion
}: SuggestionsListProps) {
  return (
    <>
      {showSuggestions ? (
        <div
          className="bg-white w-[96%] absolute top-10 p-2 border-solid border-gray-100 rounded m-0 mt-1 p-0 pb-1 border"
          data-component="suggestionsList"
        >
          {isLoading ? (
            <div className="text-center m-10" role="status">
              <LoadingSpinner />
            </div>
          ) : !errorMsg ? (
            <ul
              className={
                'text-black list-none m-0 p-0 text-left max-h-36 overflow-auto'
              }
            >
              {suggestions.map((item, index) => {
                return (
                  <li
                    className="cursor-pointer border-0 border-b border-gray-200 border-solid p-1.5"
                    key={index}
                    onClick={() => onSelectedSuggestion(index)}
                  >
                    {item.Place.Label}
                  </li>
                )
              })}
            </ul>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default SuggestionsList
