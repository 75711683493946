import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Node } from '@contentful/rich-text-types'
import { mutableArray, noValue } from '@lib/utils'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText
} from 'gatsby-source-contentful/rich-text'
import React from 'react'

import { GatsbyImage } from '..'

export type RichTextProps = {
  /** From Contentful */
  readonly raw?: string | null
  readonly references?: ReadonlyArray<Partial<ContentfulRichTextGatsbyReference> | null>
  readonly optionsCustom?: Options
}

function isContentfulRichTextGatsbyReference(
  t: ContentfulRichTextGatsbyReference | unknown
): t is ContentfulRichTextGatsbyReference {
  return (
    t !== null &&
    t !== noValue() &&
    Object.hasOwn(t, '__typename') &&
    Object.hasOwn(t, 'contentful_id')
  )
}

function safeguardType(
  t: RichTextProps['references']
): readonly ContentfulRichTextGatsbyReference[] {
  if (!t) {
    return []
  } else {
    const validReferences = t.filter(isContentfulRichTextGatsbyReference)
    return Array.of(...validReferences)
  }
}

function Paragraph<T>(_: T, children: React.ReactNode) {
  return <p>{children}</p>
}

export function getDefaultOptions(): Options {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: function <T extends Node>({ data }: T) {
        const { gatsbyImageData } = data['target'] || {}

        return gatsbyImageData ? <GatsbyImage image={gatsbyImageData} /> : null
      },
      // Remove empty trailing paragraphs https://github.com/contentful/rich-text/issues/101#issuecomment-966339506
      [BLOCKS.PARAGRAPH]: (_, _children) =>
        _children?.toString().trim() !== '' ? Paragraph(_, _children) : null
    }
  }
}

function ContentfulRichText({
  raw = null,
  references = [],
  optionsCustom = getDefaultOptions()
}: RichTextProps) {
  return raw ? (
    <>
      {renderRichText(
        {
          raw,
          references: mutableArray(safeguardType(references))
        },
        optionsCustom
      )}
    </>
  ) : null
}

export default ContentfulRichText
