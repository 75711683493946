import { getValueFromPartnerCookie } from '../util/partnerCookie'

export const useNoDiscountPartner = () => {
  // List of partners excluded from all discounts
  const NO_DISCOUNT_CUSTOMER_GROUPS = ['airlines']

  // Check if a partner is set in the cookies
  const partnerGroup = getValueFromPartnerCookie('partnerGroup') || ''

  // For partner groups like 'airlines', we shouldn't apply any discount
  // since they get specific promos/discounts like free miles in another platforms
  const shouldUseDiscountPrice =
    !NO_DISCOUNT_CUSTOMER_GROUPS.includes(partnerGroup)

  return {
    NO_DISCOUNT_CUSTOMER_GROUPS,
    partnerGroup,
    shouldUseDiscountPrice
  }
}
