import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type ZipCodeProps = {
  readonly label: string
  readonly placeholder: string
}

function ZipCode(props: ZipCodeProps) {
  return (
    <div data-component={'ZipCode'}>
      <FormField hideLabel={true} name="zipCode">
        <Text fontWeight="medium">{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name="zipCode"
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default ZipCode
