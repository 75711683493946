import React, { ReactElement } from 'react'

export type CardBadgeProps = {
  readonly title: string
  readonly description: string
  readonly image: ReadonlyArray<ReactElement>
  readonly cardImage: ReadonlyArray<ReactElement>
  readonly className?: string
  readonly dataComponent?: string
}

//This component is being used in OldVersion.tsx and DynamicHeroBannerComponent components
//to show the 60-day badge (Moved logic from RC CardBadge)
function CardBadge({
  title,
  description,
  image,
  cardImage,
  className,
  dataComponent = CardBadge.name
}: CardBadgeProps) {
  return (
    <div className={className} data-component={dataComponent}>
      <div className="my-4 p-2 md:hidden">
        <div className="flex items-center">
          <div className="w-[5.3125rem] mr-5">{cardImage}</div>
          <h4 className="uppercase text-lg font-medium">{title}</h4>
        </div>
        <p className="text-sm">{description}</p>
      </div>
      <div className="hidden group justify-center hover:w-full rounded-base hover:p-4 hover:bg-neutral-light-50 left-0 absolute top-8 z-[2] md:flex md:hover:flex-col md:hover:px-5 md:hover:pt-5 md:hover:text-center md:hover:w-64 md:w-64">
        <div className="hidden w-[104px] md:block md:group-hover:hidden drop-shadow-[0_0_0.125rem_var(--black)] mt-5">
          {image}
        </div>
        <div className="hidden w-[104px] md:group-hover:block md:group-hover:mx-auto">
          {cardImage}
        </div>
        <div className="hidden group-hover:items-center group-hover:flex group-hover:flex-col group-hover:p-0 group-hover:text-center">
          <h4 className="group-hover:mt-4 mb-2 text-base md:text-lg font-medium">
            {title}
          </h4>
          <p className="text-sm mb-0">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default CardBadge
