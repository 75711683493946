import { visitorIdAtAt } from '@lib/tracking'
import { safeProp } from '@simplisafe/monda'
import { PartnerFormSchema } from '@simplisafe/ss-ecomm-data/partners/partnersFormSchema'
import type { PartnersSubmitBody } from '@simplisafe/ss-ecomm-data/partners/submission'
import { partnersFormSubmit } from '@simplisafe/ss-ecomm-data/partners/submission'
import { CreatePartnerOrderResponse } from '@simplisafe/ss-ecomm-data/simplisafe/partnersClient'
import {
  Column,
  FormField,
  Row,
  Square,
  Text
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Field, Form, Formik } from 'formik'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import { useEffect, useRef } from 'react'
import * as React from 'react'
import Cookies from 'universal-cookie'
import { StringParam, useQueryParam } from 'use-query-params'

import { ContentfulPartnerForm } from '../../../graphql'
import { getWebAppUrl } from '../../util/common'
import ContentfulRichText from '../ContentfulRichText'
import ShippingAddress from './form-sections/ShippingInformationForm'
import Submit from './form-sections/submitButton'

export type FormValues = {
  readonly address1: string
  readonly address2: string
  readonly city: string
  readonly customerKey: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly optIn: boolean
  readonly partnerName: string
  readonly policyId: string
  readonly phone: string
  readonly zoneCode: string
  readonly postal: string
  readonly vidToken: string
}

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type PartnerFormProps = {
  readonly data: ContentfulPartnerForm
  readonly onSubmit: (values: PartnersSubmitBody) => void
}

type CookiesOptions = {
  readonly domain: '.simplisafe.com'
  readonly expires?: Date
}

const cookies = new Cookies()
const cookiesOptions: CookiesOptions = { domain: '.simplisafe.com' } // doing this as session cookie
const COOKIE_MANDO_TOKEN = 'ssOauthAccessToken'
const COOKIE_MANDO_EXPIRES_TOKEN = 'ssOauthAccessExpires'

export default function PartnerForm({ data, onSubmit }: PartnerFormProps) {
  //get the utm code and policy_id from the url
  const [keyParam] = useQueryParam('utm_key', StringParam)
  const [policyIdParam] = useQueryParam('utm_ID', StringParam) // wont be in for verizon but will be there for other partners
  const isMobile = !useMediaQuery('TabletAndUp')
  const setLoadingTimer = useRef<number>(0)
  const redirectTimer = useRef<number>(0)

  //TODO: Get the partnerName as part of the form, add a dropdown with partners to select in the form Content

  //initial values
  const initialFormValues: FormValues = {
    address1: '',
    address2: '',
    city: '',
    customerKey: Maybe.fromNull(keyParam).getOrElse(''),
    email: '',
    firstName: '',
    lastName: '',
    optIn: true,
    partnerName: Maybe.fromNull(data.partnerName).getOrElse(''),
    phone: '',
    policyId: Maybe.fromNull(policyIdParam).getOrElse(''),
    postal: '',
    vidToken: visitorIdAtAt() || 'NO_VISITOR_ID',
    zoneCode: ''
  }

  useEffect(() => {
    return () => {
      clearTimeout(redirectTimer.current)
      clearTimeout(setLoadingTimer.current)
    }
  }, [redirectTimer, setLoadingTimer])

  const forwardPreactivationFlowToWebApp = (
    response: CreatePartnerOrderResponse
  ) => {
    const webAppUrl = getWebAppUrl()
    cookies.set(COOKIE_MANDO_TOKEN, response.token, cookiesOptions)
    cookies.set(COOKIE_MANDO_EXPIRES_TOKEN, response.expires, cookiesOptions)
    redirectTimer.current = window.setTimeout(() => {
      window.location.href = `${webAppUrl}/#/collect-monitoring?funnel&orderid=${response.orderId}&sid=${response.sid}}`
    }, 1500)
  }

  // TODO: Gatsby 4 rich text - may need styles updated (see commented out code below)
  const partnerFormSuccessMessageDescription = safeProp(
    'successMessageDescription',
    data
  )
    .map(field => (
      <ContentfulRichText key="PartnerFormSuccessMessage" raw={field?.raw} />
    ))
    .orNull()

  // const partnerFormSuccessMessageDescription = safeProp(
  //   'successMessageDescription',
  //   data
  // )
  //   .map(field => (
  //     <RichText
  //       json={field?.json}
  //       key={prop('id', data)}
  //       maxWidth={true}
  //       padding="medium"
  //       textAlignment="left"
  //       textSize="md"
  //     />
  //   ))
  //   .orNull()

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (
        formData: PartnersSubmitBody,
        { setSubmitting, setStatus, setFieldError }
      ) => {
        const handleSuccess = (data: Maybe<CreatePartnerOrderResponse>) => {
          data.cata(
            () => null,
            response =>
              response.token && forwardPreactivationFlowToWebApp(response)
          )
          setStatus({ message: 'success' })
        }

        const handleFailure = (error: Error) => {
          const alreadyRedeemed = error.message.includes('Already Redeemed')
          const message = alreadyRedeemed
            ? `It looks like this offer may have already been redeemed. Please contact ${Maybe.fromNull(
                pathOr('', ['partnerName'], data)
              ).getOrElse('')} for further assistance.`
            : 'Uh oh! Looks like something is wrong! try again'
          setFieldError('customerKey', message)
        }
        setLoadingTimer.current = window.setTimeout(
          () => setSubmitting(true),
          200
        )
        partnersFormSubmit(formData)(handleFailure)(handleSuccess)
        onSubmit && onSubmit(formData)
      }}
      validationSchema={PartnerFormSchema}
    >
      {({ isSubmitting, status, errors }) => (
        <Form>
          <Square padding={3}>
            {!status && (
              <>
                <Text className={'p2'}>
                  <h5>
                    {propOr<string, string>(
                      '',
                      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      'formTitle',
                      data
                      // @ts-expect-error TS(2339) FIXME: Property 'toUpperCase' does not exist on type '<V>... Remove this comment to see the full error message
                    ).toUpperCase()}
                  </h5>
                </Text>
                <ShippingAddress
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  cityFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'cityFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  cityFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'cityFieldPlaceholder',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  emailFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'emailFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  emailFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'emailFieldPlaceholder',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  firstNameFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'firstNameFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  firstNameFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'firstNameFieldPlaceholder',
                    data
                  )}
                  isMobile={isMobile}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  lastNameFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'lastNameFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  lastNameFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'lastNameFieldPlaceholder',
                    data
                  )}
                  locale={'en-US'}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  phoneNumberFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'phoneFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  phoneNumberFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'phoneFieldPlaceholder',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  postalCodeFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'zipCodeLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  postalCodeFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'zipCodePlaceHolder',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  shippingSectionTitle={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'formTitle',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  stateFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'stateFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  streetAddress2FieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'street2FieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  streetAddress2FieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'street2FieldPlaceholder',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  streetAddressFieldLabel={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'street1FieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  streetAddressFieldPlaceholder={propOr<string, string>(
                    '',
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    'street1FieldPlaceholder',
                    data
                  )}
                ></ShippingAddress>
                <Row
                  alignItems="center"
                  equalHeightRows={true}
                  gap="none"
                  padding={2}
                >
                  <Column
                    alignSelf="center"
                    justifySelf="center"
                    padding={2}
                    spans={[12, 12, 12]}
                  >
                    <Text
                      className={'p2'}
                      fontWeight="light"
                      maxWidth={true}
                      textAlignment="left"
                      textSize="xs"
                    >
                      <label>
                        <Field
                          name="optIn"
                          style={{
                            height: '1rem',
                            width: '1rem'
                          }}
                          type="checkbox"
                        />
                        {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
                        {propOr<string, string>('', 'optInText', data)}
                      </label>
                      {errors.optIn && (
                        <Text
                          fontWeight="light"
                          textAlignment="left"
                          textColor="darkOrange"
                          textSize="xs"
                        >
                          {errors.optIn}
                        </Text>
                      )}
                    </Text>
                  </Column>
                </Row>

                <Column
                  justifySelf="center"
                  padding="small"
                  spans={[12, 12, 12]}
                >
                  <Submit
                    showSpinner={isSubmitting}
                    submitButtonDisabled={
                      isSubmitting ||
                      Maybe.fromNull(errors.customerKey).getOrElse('').length >
                        0
                    }
                    // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                    submitButtonLabel={propOr<string, string>(
                      '',
                      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      'submitButtonLabel',
                      data
                    )}
                  ></Submit>
                  {errors.customerKey && (
                    <Text
                      fontWeight="body"
                      textAlignment="center"
                      textColor="darkOrange"
                      textSize="sm"
                    >
                      {errors.customerKey}
                    </Text>
                  )}
                </Column>

                <Row gap="none" padding="small">
                  <Column justifySelf="center" spans={[12, 12, 12]}>
                    <Text fontWeight="light" textSize="xs" useTailwind={true}>
                      <span>
                        By submitting this order, you agree to SimpliSafe&apos;s{' '}
                        <Link to="/terms-sale">Terms of Sale</Link> ,{' '}
                        <Link to="/terms-of-service">Terms of Service</Link> and{' '}
                        <Link to="/privacy-policy">Privacy Policy</Link>. After
                        redeeming this offer, eligible users will be prompted to
                        begin creating their professional monitoring profile.
                      </span>
                    </Text>
                  </Column>
                </Row>
              </>
            )}
            {status && status.message && (
              <FormField hideLabel={true} name="partnerFormSuccessMessage">
                {propOr('', 'successMessageTitle', data) && (
                  <Row equalHeightRows={true} gap="none" padding={false}>
                    <Column justifySelf="center" spans={[12, 12, 12]}>
                      <Text
                        fontWeight="medium"
                        textSize="lg"
                        useTailwind={true}
                      >
                        {propOr('', 'successMessageTitle', data)}
                      </Text>
                    </Column>
                  </Row>
                )}
                {/* // TODO: Gatsby 4 rich text */}
                {partnerFormSuccessMessageDescription}
              </FormField>
            )}
          </Square>
        </Form>
      )}
    </Formik>
  )
}
