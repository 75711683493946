export default (
  isShowComparePlan: any,
  leftRichTextWithOptions: any,
  rightRichTextWithOptions: any,
  limitedProMonitoringCTA: any,
  topRichText: any
): boolean =>
  isShowComparePlan &&
  leftRichTextWithOptions &&
  rightRichTextWithOptions &&
  limitedProMonitoringCTA &&
  !!topRichText
