import {
  brazeTrackPromoView,
  COOKIE_LEAD_DATA,
  cookies,
  fbTrackLeadCreated,
  handleBrazeTrackingEvent,
  useActivate,
  useOptimizelyTrackSiteEvents
} from '@lib/tracking'
import type { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import {
  selectActivePromoCode,
  selectActivePromoDiscountTextWithOverrides,
  selectLocale,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  leadGenCapture,
  LeadGenCaptureParams,
  LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { exists, window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

// TODO: See comment in handleLeadCaptureSuccess
// import { trackSubmitLeadEvent } from '../../util/analytics'
import type { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import DesktopBanner from './DesktopBanner'
import MobileBottomBanner from './MobileBottomBanner'
import MobileTopBanner from './MobileTopBanner'
import PromoStyler from './PromoStyler'
import useScrollCondition from './useScrollCondition'
import React from 'react'

export type ActivePromoBannerType = 'cart' | 'none' | 'pdp-plp' | 'standard'

export type ActivePromoBannerProps = {
  readonly pagePath?: string
  /** Determines what type of banner to show. */
  readonly type: ActivePromoBannerType
}

const COOKIE_DISMISSED = 'promobanner_isDismissed'
const COOKIE_DISMISSED_AFTER_SCROLLING = 'promobanner_isDismissedAfterScrolling'
const COOKIE_MINIMIZED = 'promobanner_isMinimized'
const COOKIE_SUBMITTED = 'promobanner_isFormSubmitted'

const leadSourceI18N: Record<Locale, string> = {
  'en-GB': 'uk_promo_banner',
  'en-US': 'us_promo_banner'
}

function ActivePromoBannerComponent({
  pagePath,
  type
}: ActivePromoBannerProps) {
  const locale = useSelector(selectLocale)
  const promoCode = useSelector(selectActivePromoCode)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })
  const bottomBannerTest = useActivate('ECP_5362')
  const isBottomBannerVariation = bottomBannerTest === 'variation_1'
  const hasScrollCondition = useScrollCondition(isBottomBannerVariation)
  const liveChatVariation = useActivate('all___us_bms___midfunnel___live_chat')
  const isValidLiveChatVariation = ['control', 'variation_1'].includes(
    liveChatVariation
  )
  const isBmsLiveChat =
    pagePath && pagePath.includes('build-my-system') && isValidLiveChatVariation

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (
    email,
    onFailure
  ) => {
    const handleLeadCaptureFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
      onFailure("We've encountered an error. Please try again later.")
    }

    const handleLeadCaptureSuccess = async (
      value: Maybe<LeadGenCaptureResponse>
    ) => {
      setIsFormSubmitted(true)
      cookies.set(COOKIE_SUBMITTED, true)
      cookies.set(COOKIE_DISMISSED, true)
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())
      brazeTrackPromoView()
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackEvent({ event: 'submit' })
      // TODO: Check this trackEvent. Removed the original import from analytics as it has to be redone.
      // trackSubmitLeadEvent(trackEvent)
      trackEvent({ event: 'submitLead' })
      await fbTrackLeadCreated(email)
    }

    const leadGenParams: LeadGenCaptureParams = {
      email,
      promoCode: promoCode.getOrElse(''),
      source: `${leadSourceI18N[locale]}_${getDeviceType().toLowerCase()}`
    }

    leadGenCapture(leadGenParams)(handleLeadCaptureFailure)(
      handleLeadCaptureSuccess
    )
  }

  const isMobile = !useMediaQuery('TabletAndUp')
  // Hide bottom banner on page load for BMS LiveChat Experiment.
  const [isDismissed, setIsDismissed] = useState(
    isBmsLiveChat || cookies.get(COOKIE_DISMISSED) ? true : false
  )
  const [isDismissedAfterScrolling, setIsDismissedAfterScrolling] = useState(
    isBmsLiveChat || cookies.get(COOKIE_DISMISSED_AFTER_SCROLLING)
      ? true
      : false
  )
  const [isMinimized, setIsMinimized] = useState(
    cookies.get(COOKIE_MINIMIZED) ? true : false
  )
  const [isFormSubmitted, setIsFormSubmitted] = useState(
    cookies.get(COOKIE_SUBMITTED) ? true : false
  )
  const shouldSkipMinimize = isBmsLiveChat || isBottomBannerVariation

  const handleRedeemClick = () => {
    setIsDismissed(false)
    setIsMinimized(false)
  }

  const handleDismissClick = useCallback(() => {
    setIsDismissed(true)
    cookies.set(COOKIE_DISMISSED, true)
    hasScrollCondition && setIsDismissedAfterScrolling(true)
    hasScrollCondition && cookies.set(COOKIE_DISMISSED_AFTER_SCROLLING, true)
  }, [hasScrollCondition])

  const handleMinimizeClick = useCallback(() => {
    setIsMinimized(true)
    cookies.set(COOKIE_MINIMIZED, true)
    // Check if we should dismiss the whole thing (BMS LiveChat variation OR Mobile Bottom Banner variation).
    shouldSkipMinimize && handleDismissClick()
  }, [handleDismissClick, shouldSkipMinimize])

  const mobileBanner = (
    <>
      <MobileTopBanner
        isFormSubmitted={isFormSubmitted}
        onRedeemClick={handleRedeemClick}
        type={type}
      />
      <MobileBottomBanner
        isBottomBannerVariation={isBottomBannerVariation}
        isDismissed={isDismissed}
        isDismissedAfterScrolling={
          isBottomBannerVariation && isDismissedAfterScrolling
        }
        isFormSubmitted={isFormSubmitted}
        isMinimized={
          isMinimized ||
          (hasScrollCondition && isDismissed && !isDismissedAfterScrolling)
        }
        onDismiss={handleDismissClick}
        onEmailSubmit={handleEmailSubmit}
        onMinimize={handleMinimizeClick}
        onRedeemClick={handleRedeemClick}
        type={type}
      />
    </>
  )

  const desktopBanner = (
    <DesktopBanner
      isFormSubmitted={isFormSubmitted}
      onEmailSubmit={handleEmailSubmit}
      type={type}
    />
  )

  return <PromoStyler>{isMobile ? mobileBanner : desktopBanner}</PromoStyler>
}

function ActivePromoBanner({ pagePath = '', type }: ActivePromoBannerProps) {
  const isActivePromo = useSelector(selectTopBannerVisible)
  const promoDiscountText = useSelector(
    selectActivePromoDiscountTextWithOverrides
  )
  const showPromoBanner = isActivePromo && promoDiscountText.isSome()

  // Checking if the window exists makes sure this isn't rendered during gatsby build, but only when the page
  // hydrates when the end user hits the site
  const isBrowser = exists(window)

  return type !== 'none' && showPromoBanner && isBrowser ? (
    <ActivePromoBannerComponent pagePath={pagePath} type={type} />
  ) : null
}

export default ActivePromoBanner
