import { ContentfulButton } from '../../../../graphql'

export const KEY_QW_RECOMMENDATIONS_SUBMITTED = 'qw_recommendations_submitted'
export const KEY_RETURN_TO_QW = 'ss_return_to_qw'
export const POP_UP_STORAGE_KEY = 'popupWizardOpened'
export const UNIQUE_SESSION_ID_PARAM = 'unique_session_id'
export const UID_PARAM = 'uid'

export function appendVIDandUniqueSessionIdtoButtonUrl(
  button: ContentfulButton,
  vid: string | undefined,
  clientId: string | undefined,
  sessionId: string | undefined
): ContentfulButton {
  const url =
    button.url &&
    appendParamsToGSBLaunchUrl(button.url, vid, clientId, sessionId)
  return {
    ...button,
    url
  }
}

export function appendParamsToGSBLaunchUrl(
  url: string,
  vid: string | undefined,
  clientId: string | undefined,
  sessionId: string | undefined
): string {
  if (!vid) {
    return url
  } else {
    const searchParams = new URLSearchParams()
    const uniqueSessionId = clientId && sessionId ? sessionId + clientId : null
    searchParams.append(UID_PARAM, vid)
    uniqueSessionId &&
      searchParams.append(UNIQUE_SESSION_ID_PARAM, uniqueSessionId)
    return `${url}?${searchParams.toString()}`
  }
}
