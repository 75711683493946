import { ContentfulRichText, GatsbyImage } from '@lib/components'
import { useDecision } from '@lib/optimizely'
import {
  useTrackingProductDecrease,
  useTrackingProductIncrease,
  useTrackingBmsShieldCameraAccesoriesExpand,
  useTrackingBmsToolTip
} from '@lib/tracking'
import {
  COOKIE_MONITORING_GIFT_ITEM,
  COOKIE_NON_MONITORING_GIFT_ITEM,
  cookies,
  getMonitoringGiftItems,
  getMonitoringPlan,
  getNonMonitoringGiftItems
} from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import { setMiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import {
  GiftItemContainer,
  GiftItemDTO
} from '@simplisafe/ss-ecomm-data/prices/service'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import {
  liftSelectProduct,
  selectLocale,
  selectMiniCartLineItems,
  selectPackage,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  CartUpdatedMessage,
  ErrorMessage,
  Price,
  SSButton
} from '@simplisafe/ss-react-components'
import { BuildMySystemCardItem } from '@simplisafe/ss-react-components'
import { QuantityType } from '@simplisafe/ss-react-components/BuildMySystemCardItem'
import { graphql } from 'gatsby'
import { Either, Maybe } from 'monet'
import defaultTo from 'ramda/src/defaultTo'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import T from 'ramda/src/T'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContentfulBmsSensors } from '../../../graphql'
import {
  componentsNotInStock,
  renderOutOfStockMessage,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import { usePriceContext } from '../../providers/PriceProvider'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import ModalComponent from '../ModalComponent'

const defaultQuantity = 0

export const GIFT_ITEM_TTL = 14400
export const giftCookieOptions = {
  maxAge: GIFT_ITEM_TTL,
  path: '/'
}

const toFormImg = (props: any) => (
  <GatsbyImage
    className="w-full h-full"
    image={prop('image', props)}
    // @ts-expect-error TS(2322) FIXME: Type '{ className: string; image: any; objectFit: ... Remove this comment to see the full error message
    objectFit="contain"
    objectPosition="center center"
  />
)

export type BmsSensorProps = {
  readonly data: ContentfulBmsSensors
  readonly pkg?: Package
}

/**
 * Helper function to calculate the max quantity accounting for
 * how many are included in the package already
 */
export const getAdjustedMaxQuantity = (
  data: ContentfulBmsSensors,
  product: Either<Error, Product>,
  _package?: Package
): number | undefined => {
  // determine how many of this product are in the given package, default to 0
  const packageQuantity = product
    .toMaybe()
    .chain(safeProp('masterSku'))
    .chain(sku => {
      const packageProducts: readonly PackageProduct[] = propOr(
        [],
        'products',
        _package
      )
      return safeFind(
        packageProduct => propEq('sku', sku, packageProduct),
        packageProducts
      ).chain(safeProp('quantity'))
    })
    .getOrElse(0)

  return safeProp('maximumQuantity', data)
    .map(maxQuantity =>
      maxQuantity > packageQuantity
        ? maxQuantity - packageQuantity
        : packageQuantity
    )
    .orUndefined()
}

const POLL_INTERVAL = 1000

const setGiftTextOnceIfItExists = (
  text: string,
  data: GiftItemDTO | null,
  setFn: (title: string) => void
) => {
  !text && data && data.title && setFn(data.title)
}

export default function BmsSensor({ data, pkg }: BmsSensorProps) {
  const siteLocale = useSelector(selectLocale)
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const sensorsName: string = propOr<string, string>('', 'sensorsName', data)

  const trackEventBmsShieldCameraAccesoriesExpand =
    useTrackingBmsShieldCameraAccesoriesExpand(sensorsName)
  const trackEventBmsToolTip = useTrackingBmsToolTip(sensorsName)
  const trackEventBmsProductIncrease = useTrackingProductIncrease(sensorsName)
  const trackEventBmsProductDecrease = useTrackingProductDecrease(sensorsName)

  // todo make a selectLocalBmsPackage selector in ecomm-data and replace this

  const localeBmsPackage = useSelector(
    selectPackage(`simplisafe-custom-home-security-system-${locale}`)
  )

  /*
    As of this writing, BmsSensor is used at the page level for BMS
    which means it doesn't get a pkg context from a parent. However,
    we need that to get quantity of a product as it pre-exists in
    the package in order to determine adjusted max.
    Use pkg as passed if present, else try and use local BMS.
    Default to undefined.
  */
  const activePackage = Maybe.fromNull(pkg).cata(
    () =>
      localeBmsPackage.cata(
        (): Package | undefined => undefined,
        activePackage => activePackage
      ),
    activePackage => activePackage
  )

  const productId = safeProp('productId', data)
  const product = useSelector(liftSelectProduct(productId))
  // @ts-expect-error TS(2559) FIXME: Type '""' has no properties in common with type 'C... Remove this comment to see the full error message
  const descriptionValue = safeProp('description', data).getOrElse('')
  // @ts-expect-error TS(2559) FIXME: Type '""' has no properties in common with type 'C... Remove this comment to see the full error message
  const descriptionProTipValue = safeProp('descriptionProTip', data).getOrElse(
    ''
  )

  const description = descriptionValue.raw && (
    <ContentfulRichText raw={descriptionValue.raw} />
  )
  const descriptionProTip = descriptionProTipValue.raw && (
    <ContentfulRichText raw={descriptionProTipValue.raw} />
  )

  const [quantity, setQuantity] = useState<number>(defaultQuantity)
  const [checked, setChecked] = useState(false)
  const modalContent = safeProp('sensorDetailsModalContent', data).orUndefined()
  const dispatch = useDispatch()
  const itemList = useSelector(selectMiniCartLineItems)
  const defaultStepper: QuantityType = 'Stepper'
  const quantityType = defaultTo(defaultStepper)(prop('quantityType', data))
  const adjustedMaxQuantity = getAdjustedMaxQuantity(
    data,
    product,
    activePackage
  )
  const [updateFailedError, setUpdateFailedError] = useState(false)
  const [isCartUpdated, setCartUpdated] = useState(false)
  // TODO: safePath type issues (should be solved after upgrading monda)

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const updatedFailedErrorMessage: string = safePath(
    ['errorMessage', 'text', 'text'],
    data
  ).getOrElse('') as string
  const linkText = prop('popupAccessoryProductsLinkText', data)
  const modalLinkTextContent = prop('popupAccessoryProducts', data)
  const [isShieldNotInStockOptimizely] = useDecision('shield_is_not_in_stock')

  // Setup for Optimizely feature to display Out Of Stock Message for Shield
  // (the message is set in Optimizely)
  const isCMOB1: boolean = safeProp('productId', data).getOrElse('') === 'CMOB1'
  const showOptimizelyShieldOOSMessage: boolean =
    siteLocale === 'en-US' && isCMOB1 && !!isShieldNotInStockOptimizely.enabled
  // TODO: safePath type issues (should be solved after upgrading monda)

  // @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'string'.
  const shippingEstimate: string = safePath(
    ['variables', 'shipping_estimate'],
    isShieldNotInStockOptimizely
  ).getOrElse(undefined)
  const optimizelyShieldOOSMessage = showOptimizelyShieldOOSMessage
    ? shippingEstimate
    : undefined
  // @ts-expect-error TS(2559) FIXME: Type '""' has no properties in common with type 'C... Remove this comment to see the full error message
  const productPriceTextValue = safeProp('productPriceText', data).getOrElse('')
  const productPriceText = productPriceTextValue.raw && (
    <ContentfulRichText raw={productPriceTextValue.raw} />
  )

  const coreComponetsProducts = useSelector(
    selectProducts(systemCoreComponents)
  )
  const coreComponetsNotInStockList = useMemo(
    () => componentsNotInStock(coreComponetsProducts),
    [coreComponetsProducts]
  )
  const isSellable = product.cata(T, p =>
    prop('isSellable', p) ? true : false
  )

  const {
    getFormattedPrice,
    getDiscountedPrice,
    getPrice,
    getFreeGiftItems,
    getFreeGiftItemsWithServicePlan
  } = usePriceContext()

  const [monitoringGiftText, setMonitoringGiftText] = useState('')
  const [nonMonitoringGiftText, setNonMonitoringGiftText] = useState('')
  const [monitoringPlan, setMonitoringPlan] = useState('true')
  const [monitoringText, setMonitoringText] = useState('')

  const withMonitoringGiftItem: GiftItemDTO | null =
    getFreeGiftItemsWithServicePlan(productId.orJust('')).orNull()
  const withoutMonitoringGiftItem: GiftItemDTO | null = getFreeGiftItems(
    productId.orJust('')
  ).orNull()

  setGiftTextOnceIfItExists(
    monitoringGiftText,
    withMonitoringGiftItem,
    setMonitoringGiftText
  )
  setGiftTextOnceIfItExists(
    nonMonitoringGiftText,
    withoutMonitoringGiftItem,
    setNonMonitoringGiftText
  )

  const freeGiftItems: GiftItemContainer = useMemo(() => {
    return {
      withMonitoring: withMonitoringGiftItem,
      withoutMonitoring: withoutMonitoringGiftItem
    }
  }, [withMonitoringGiftItem, withoutMonitoringGiftItem])

  useEffect(() => {
    const monitoringCookie = getMonitoringGiftItems()
    const nonMonitoringCookie = getNonMonitoringGiftItems()
    // only set the giftItems cookie if none has ben set yet
    !monitoringCookie &&
      withMonitoringGiftItem &&
      cookies.set(
        COOKIE_MONITORING_GIFT_ITEM,
        JSON.stringify(withMonitoringGiftItem),
        giftCookieOptions
      )
    !nonMonitoringCookie &&
      withoutMonitoringGiftItem &&
      cookies.set(
        COOKIE_NON_MONITORING_GIFT_ITEM,
        JSON.stringify(withoutMonitoringGiftItem),
        giftCookieOptions
      )
  }, [freeGiftItems, withMonitoringGiftItem, withoutMonitoringGiftItem])

  // Poll for changes to the monitoringPlan cookie and set that value to the local state
  useEffect(() => {
    const timer = setInterval(
      () => setMonitoringPlan(getMonitoringPlan()),
      POLL_INTERVAL
    )
    return () => clearInterval(timer)
  }, [])

  const price = getFormattedPrice(productId.orJust(''))(pricePerUnit)
  const totalPrice = getDiscountedPrice(productId.orJust(''))
    .orElse(getPrice(productId.orJust('')))
    .map(price => price * quantity)
    .chain(formatDisplayPrice)

    .map(totalPrice => <Price regularPrice={totalPrice} />)

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdated(false), 5000)
    return () => clearTimeout(timer)
  }, [isCartUpdated])

  useEffect(() => {
    const product = productId.chain(sku =>
      safeFind(val => propEq('masterSku', sku, val), itemList)
    )

    product.chain(safeProp('quantity')).cata(
      () => {
        setChecked(false)
        setQuantity(defaultQuantity)
      },
      quantity => {
        setChecked(quantity > 0)
        setQuantity(quantity)
      }
    )
  }, [itemList, productId])

  useEffect(() => {
    monitoringPlan === 'true'
      ? setMonitoringText(monitoringGiftText)
      : setMonitoringText(nonMonitoringGiftText)
  }, [monitoringPlan, monitoringGiftText, nonMonitoringGiftText])

  const onQuantityChange = useCallback(
    <T extends QuantityType | string>(newQuantity: number, quantityType: T) => {
      const isChecked = newQuantity > 0
      const isCheckedType = quantityType === 'Checkbox' ? isChecked : false
      newQuantity > quantity
        ? trackEventBmsProductIncrease()
        : trackEventBmsProductDecrease()

      product.cata(
        () => {
          setUpdateFailedError(true)
        },
        _product => {
          setChecked(isChecked)
          setCartUpdated(true)
          dispatch(
            setMiniCartLineItem({
              ..._product,
              checked: isCheckedType,
              maxQuantity: adjustedMaxQuantity,
              quantity: newQuantity
            })
          )
        }
      )
    },
    [dispatch, product, adjustedMaxQuantity]
  )

  const outOfStockMessage: React.ReactNode = optimizelyShieldOOSMessage ? (
    <>{optimizelyShieldOOSMessage}</>
  ) : (
    renderOutOfStockMessage({
      coreComponentsNotInStock: coreComponetsNotInStockList,
      includedInPackage: true,
      product
    })
  )

  const outOfStockTitle = prop('outOfStockTitle', data)
  const outOfStockDescription = prop('outOfStockDescription', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const quantityText =
    !isSellable && outOfStockTitle
      ? outOfStockTitle
      : propOr<string, string>('', 'quantityText', data)
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const quantityAdditionalText =
    !isSellable && outOfStockDescription
      ? outOfStockDescription
      : pathOr<string, string>('', ['relatedText', 'relatedText'], data)
  /*
    The isClient var is used asa key to force two-pass rendering
    See https://github.com/gatsbyjs/gatsby/discussions/17914

    Without, hitting BMS directly results in price & selectors not displaying
  */
  const [isClient, setIsClient] = useState(false)
  useEffect(() => setIsClient(true), [])
  return (
    <BuildMySystemCardItem
      additionalDescription={descriptionProTip}
      additionalLink={
        !isNil(modalLinkTextContent) && (
          <ModalComponent
            clickTarget={
              linkText && (
                <SSButton
                  color="link"
                  onClick={() => trackEventBmsShieldCameraAccesoriesExpand()}
                  style={{
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    minHeight: 0,
                    padding: 0,
                    textAlign: 'left'
                  }}
                  type="button"
                >
                  {linkText}
                </SSButton>
              )
            }
            // @ts-expect-error TS(2322) FIXME: Type '{ modalContent: ContentfulGroupSection; }' i... Remove this comment to see the full error message
            data={{ modalContent: modalLinkTextContent }}
            style={{ content: { padding: '30px' } }}
          />
        )
      }
      cartUpdatedText={
        <CartUpdatedMessage
          isVisible={isCartUpdated}
          message={path(['cartUpdatedText', 'text', 'text'], data)}
        />
      }
      defaultQuantityValue={quantity}
      description={description}
      errorMessage={
        updatedFailedErrorMessage && (
          <ErrorMessage
            isVisible={updateFailedError}
            message={updatedFailedErrorMessage}
          />
        )
      }
      formattedPrice={productPriceText || price}
      freeGiftItem={monitoringText}
      image={toFormImg(data)}
      isNotSellable={!isSellable ? true : false}
      // forces a two-pass render, but key must be a string
      key={`${isClient}`}
      maxQuantity={adjustedMaxQuantity}
      onQuantityChange={(quantity: number) =>
        onQuantityChange(quantity, quantityType)
      }
      outOfStockMessage={isSellable && outOfStockMessage}
      quantityAdditionalText={quantityAdditionalText}
      // @ts-expect-error TS(2322) FIXME: Type 'string | (<V>(p: string) => V)' is not assig... Remove this comment to see the full error message
      quantityText={quantityText}
      // in this component quantityType is a string and might not match the expected value of the child component
      // This uses one of the mega react-components that needs to be rewritten, so it's not worth fixing at this time.
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'QuantityT... Remove this comment to see the full error message
      quantityType={quantityType}
      selected={checked}
      sensorModalResponsiveTarget={
        modalContent &&
        (clickTarget => (
          <ModalComponent
            clickTarget={clickTarget}
            // @ts-expect-error TS(2322) FIXME: Type '{ modalContent: ContentfulBannerContentfulSm... Remove this comment to see the full error message
            data={{ modalContent: modalContent }}
            onClick={() => trackEventBmsToolTip()}
          />
        ))
      }
      title={sensorsName}
      totalPrice={totalPrice.orNull()}
    />
  )
}

export const bmsSensorsQuery = graphql`
  #graphql
  fragment bmsSensors on ContentfulBmsSensors {
    cartUpdatedText {
      text {
        text
      }
    }
    description {
      raw
    }
    descriptionProTip {
      raw
    }
    errorMessage {
      text {
        text
      }
    }
    flashDuration
    id
    image {
      id
      gatsbyImageData(layout: CONSTRAINED, width: 150, placeholder: BLURRED)
      title
      description # TODO: get description from gatsbyImageData
    }
    internal {
      type
    }
    maximumQuantity
    productId
    productPriceText {
      raw
    }
    popupAccessoryProductsLinkText
    popupAccessoryProducts {
      ...nonCyclicalGroupSectionFragment
    }
    outOfStockDescription
    outOfStockTitle
    quantityText
    quantityType
    relatedText {
      relatedText
    }
    sensorDetailsModalContent {
      ... on ContentfulBanner {
        ...contentfulBanner
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
    }
    sensorsName
    systemComponent {
      displayName
      sku
      componentName
    }
  }
`
