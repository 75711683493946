import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { toSiteColor } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import applySpec from 'ramda/src/applySpec'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import React from 'react'

import { ContentfulCarousel } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import { spanWrap } from '../ContentfulRichText/common'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
export const toFloatingBadge = (
  contentfulCarousel: ContentfulCarousel,
  placeholders: Record<string, string>
) => {
  const floatingBadgeRawText = contentfulCarousel?.promoBadge?.text?.raw
  const floatingBadgeReferences =
    contentfulCarousel.promoBadge?.text?.references || []
  const options: Options = {
    renderNode: {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'import("/home/joshderocher-vlk/D... Remove this comment to see the full error message
      [INLINES.EMBEDDED_ENTRY]: node => renderEmbeddedEntry(node, placeholders),
      [BLOCKS.HEADING_3]: (__: unknown, text) => {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const header3 = map(spanWrap, text as string)

        return <h3 className={'h3'}>{header3}</h3>
      }
    }
  }

  return !isEmpty(placeholders)
    ? applySpec({
        backgroundColor: () => toSiteColor('brandPrimary'),
        borderColor: () => toSiteColor('brandPrimary'),
        floatingBadgeContent: () => (
          <ContentfulRichText
            optionsCustom={options}
            raw={floatingBadgeRawText}
            // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulPlaceholder[]' is not ass... Remove this comment to see the full error message
            references={floatingBadgeReferences}
          />
        ),
        linkUrl: prop('discountBadgeUrl'),
        textColor: () => toSiteColor('neutralWhite')
        // @ts-expect-error TS(2345) FIXME: Argument of type '[ContentfulCarousel]' is not ass... Remove this comment to see the full error message
      })(contentfulCarousel)
    : null
}
