import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Column } from '@simplisafe/ss-react-components'
import defaultTo from 'ramda/src/defaultTo'
import React from 'react'

import type { File } from './types'

type FileColumnProps = {
  readonly file: File
  readonly imageObjectFit?: string
}

function FileColumn({ file, imageObjectFit }: FileColumnProps) {
  const contentType = path(['file', 'contentType'], file)
  const url = path(['file', 'url'], file)

  const imgStyle = {
    height: '100%',
    'object-fit': defaultTo('contain')(imageObjectFit),
    width: '100%'
  }
  // the only supported type right now is mp4
  return contentType === 'video/mp4' ? (
    <Column firstRow={true} key={prop('id', file)} spans={[12, 6]}>
      <video
        autoPlay
        // enable controls when autoplay enabled to comply w/ WCAG Success Criterion 2.2.2 Pause, Stop, Hide
        controls
        id={prop('id', file)}
        loop
        muted
        playsInline
        preload="auto"
      >
        <source src={url} type={contentType} />
      </video>
    </Column>
  ) : contentType === 'image/jpeg' ? (
    <Column firstRow={true} key={prop('id', file)} spans={[12, 6]}>
      <img src={url} style={imgStyle} />
    </Column>
  ) : null
}

export default FileColumn
