/* eslint-disable no-undefined */
import {
  brazeTrackGuidedSystemBuilderRecommendation,
  COOKIE_LEAD_DATA,
  cookies,
  fbTrackLeadCreated,
  handleBrazeTrackingEvent
} from '@lib/tracking'
import { useLocation } from '@reach/router'
import {
  leadGenRecommendationsV2,
  leadGenRecommendationsV2Response
} from '@simplisafe/ss-ecomm-data/leads/quoteWizard/recommendations'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { Maybe, None } from 'monet'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import { KEY_RETURN_TO_QW } from '../components/QuoteWizardWrapper/lib'
import { trackSubmitLeadEvent } from '../util/analytics'
import { convertGuidedSystemBuilderParamsToPackageComponents } from '../util/guidedSystemBuilder'

/**
 * Captures and tracks Guided System Builder lead submission in our database and braze
 * @returns
 */
export const useGuidedSystemBuilderCaptureLeadAndTrack = () => {
  const [error, setError] = useState<Error | null>(null)
  const [leadGenResponse, setLeadGenResponse] = useState<
    Maybe<leadGenRecommendationsV2Response>
  >(None())
  const location = useLocation()
  const { trackEvent } = useTracking({
    appSection: 'GuidedSystemBuilderRecommendation'
  })

  useEffect(() => {
    const email = new URLSearchParams(location.search).get('email')
    const components = convertGuidedSystemBuilderParamsToPackageComponents(
      location.search
    )

    email
      ? leadGenRecommendationsV2({
          components,
          email
        })(e => {
          setError(e)
        })(response => {
          setLeadGenResponse(response)
          response.cata(
            () =>
              setError(
                new Error('Missing response from leadGenRecommendations')
              ),
            async res => {
              cookies.set(KEY_RETURN_TO_QW, res.attributeHash, cookiesOption)
              cookies.set(COOKIE_LEAD_DATA, res, cookiesOption)
              handleBrazeTrackingEvent({
                email: res.email || undefined,
                externalId: res.externalId || undefined,
                leadId: res.leadId || undefined,
                leadSource: res.leadSource || undefined
              })
              brazeTrackGuidedSystemBuilderRecommendation(
                res.attributeHash,
                location.search
              )
              trackSubmitLeadEvent(trackEvent)
              res.email && (await fbTrackLeadCreated(res.email))
            }
          )
        })
      : setError(new Error('email not found in query parameter'))
  }, [location.search, trackEvent])

  return [error, leadGenResponse] as const
}
