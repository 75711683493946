import { FormField, FormRadio, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type SubjectFieldProps = {
  readonly subjectDisclaimer?: string
  readonly subjectFieldLabel: string
  readonly subjectOptions: readonly {
    readonly text?: string
    readonly value?: string
  }[]
}

function Subject(props: SubjectFieldProps) {
  return (
    <FormField hideLabel={true} label={props.subjectFieldLabel} name="subject">
      <Text fontWeight="medium">{props.subjectFieldLabel}</Text>
      {props.subjectOptions.map((option, idx) => {
        return (
          <FormRadio
            key={idx}
            label={option.text}
            name="subject"
            value={option.value}
          />
        )
      })}
      <Text fontWeight="medium" textColor="darkOrange" textSize="xs">
        {props.subjectDisclaimer}
      </Text>
    </FormField>
  )
}

export default Subject
