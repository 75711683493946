import classNames from 'classnames'
import React from 'react'

import GatsbyImage from '../GatsbyImage'
import { ImageWithCaptionSchema } from './schema'

export type ImageWithCaptionProps = {
  readonly data: ImageWithCaptionSchema
}

function ImageWithCaption({ data }: ImageWithCaptionProps) {
  return (
    <div>
      {data.media && (
        <GatsbyImage image={data.media} imgStyle={{ width: '100%' }} />
      )}
      {data.caption && (
        <div
          className={classNames(
            'absolute left-0 bg-neutral-200/80 w-full h-14 lg:h-20 text-lg lg:text-3xl font-medium tracking-tight flex items-center justify-center',
            !data.position || data.position === 'bottom' ? 'bottom-0' : 'top-0'
          )}
        >
          {data.caption}
        </div>
      )}
    </div>
  )
}

export default ImageWithCaption
