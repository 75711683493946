import prop from '@simplisafe/ewok/ramda/prop'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import isNil from 'ramda/src/isNil'
import { useEffect, useState } from 'react'
import { UAParser } from 'ua-parser-js'

import { ContentfulCommunicationsContent } from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import { renderComponentFromData } from '../../util/render'

const userAgentParser = new UAParser()
const userAgentData = userAgentParser.getResult()

export type CommunicationsContentProps = {
  readonly data: ContentfulCommunicationsContent
}

function CommunicationsContent({ data }: CommunicationsContentProps) {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  const supportedDevices = ['iOS', 'Android']
  const deviceOS: string = userAgentData.os.name || ''

  const isDesktop = useMediaQuery('DesktopAndUp')
  const mobileContent: ContentfulComponent = prop('mobileContent', data) || {}
  const communicationsContentQuery =
    isDesktop || isNil(mobileContent) || !supportedDevices.includes(deviceOS)
      ? 'desktopContent'
      : 'mobileContent'
  const communicationsContent: ContentfulComponent =
    prop(communicationsContentQuery, data) || {}

  return hasMounted && communicationsContent
    ? renderComponentFromData(communicationsContent)
    : null
}

export const CommunicationsContentQuery = graphql`
  #graphql
  fragment communicationsContent on ContentfulCommunicationsContent {
    id
    internal {
      type
    }
    desktopContent {
      ... on ContentfulLiveChatTrigger {
        ...liveChatTrigger
      }
    }
    mobileContent {
      ... on ContentfulSmsBlock {
        ...smsBlock
      }
    }
  }
`

export default CommunicationsContent
