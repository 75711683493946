import {
  Ecommerce,
  Product as GtmProduct,
  TrackingData,
  brazeSetCustomUserAttributes
} from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import { safePath, safeProp } from '@simplisafe/monda'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Just, Maybe } from 'monet'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import pathEq from 'ramda/src/pathEq'
import pathOr from 'ramda/src/pathOr'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import values from 'ramda/src/values'

import { currencyCode, locale } from '../../commercetools/utils'

export type GtmData = {
  readonly event: string
  readonly eventCategory?: string
  readonly eventAction?: string
  readonly eventLabel?: string
  readonly ecommerce?: unknown
}

export type VariantItem = {
  readonly id?: string
  readonly name?: unknown
  readonly quantity?: number
  readonly sku: string
  readonly price?: number
}

export type CTLineItemChild = {
  readonly name: Record<string, string>
  readonly quantity: number
  readonly sku: string
}

export type productGTM = {
  readonly id?: string
  readonly name?: string
  readonly price?: number
  readonly quantity?: number
  readonly variant?: readonly VariantItem[]
  readonly category?: string
  readonly list?: string
  readonly brand?: string
}

export type GtmCustomEvent = {
  readonly event: 'buttonClick' | 'linkClick'
  readonly eventAction?: string
  readonly eventCategory: string
  readonly eventLabel?: string
}

export type TrackEvent = (
  _data: Partial<Record<string, unknown> | TrackingData>
) => void

// TODO this whole thing needs to be redone. we should never be passing the entire redux state around like this.

//TODO For current GTM requirements/specs we don't use custom attributes. Once it's available custom attributes need to be handle.

// todo break product-, package-, and line item-based functionality out into separate files to shrink this

export const sendGtmCustomEvent = (gtmData: GtmData) => {
  const dataLayer = window['dataLayer'] || []

  dataLayer.push({
    action: gtmData.eventAction,
    category: gtmData.eventCategory,
    ecommerce: gtmData.ecommerce,
    event: gtmData.event,
    label: gtmData.eventLabel
  })
}

const PACKAGE_PARENT_PRODUCT_TYPE = 'package_parent'
const PACKAGE_PARENT_ID_FIELD_KEY = 'package_parent_id'
const BMS_NAME = 'bms'

export const setGtmCustomEvent = (gtmEventData: GtmCustomEvent) => {
  const gtmData: GtmData = {
    event: prop('event', gtmEventData),
    eventAction: prop('eventAction', gtmEventData),
    eventCategory: prop('eventCategory', gtmEventData),
    eventLabel: safeProp('eventLabel', gtmEventData).getOrElse('')
  }
  sendGtmCustomEvent(gtmData)
}

export const trackShopNowEvent =
  (label: string) => (trackEvent: TrackEvent) => {
    trackEvent({
      action: 'click-shop-now',
      event: 'shopNow',
      label
    })
  }

export const trackSubmitLeadEvent = (trackEvent: TrackEvent) => {
  trackEvent({ event: 'submitLead' })
}

export const trackEventCompletedQuoteWizard = (trackEvent: TrackEvent) => {
  trackEvent({ event: 'completeQuoteWizard' })
}

export const trackEventIsMobile = (
  trackEvent: TrackEvent,
  isTabletUp: boolean
) => {
  trackEvent({ isMobile: !isTabletUp })
}

export const trackShippingPageView =
  (lineItems: readonly LineItem[]) => (trackEvent: TrackEvent) => {
    const ecomm = {
      ...getCommerceDataFromLineItems('checkout')(lineItems),
      actionField: { step: 2 }
    }

    trackEvent({
      ecommerce: ecomm,
      event: 'shippingInfo'
    })

    trackEvent({
      ecommerce: ecomm,
      event: 'eec.shipping',
      eventAction: 'checkout',
      eventCategory: 'eec',
      eventLabel: 'shipping information'
    })
  }

export const trackPaymentPageView =
  (lineItems: readonly LineItem[]) => (trackEvent: TrackEvent) => {
    const ecomm = {
      ...getCommerceDataFromLineItems('checkout')(lineItems),
      actionField: { step: 3 }
    }

    trackEvent({
      ecommerce: ecomm,
      event: 'paymentInfo'
    })

    trackEvent({
      ecommerce: ecomm,
      event: 'eec.payment',
      eventAction: 'checkout',
      eventCategory: 'eec',
      eventLabel: 'payment information'
    })
  }

export const trackEpsilonAbacusOptIn =
  (trackEvent: TrackEvent) => (epsilonAbacusOptIn: boolean) => {
    trackEvent({
      epsilonAbacusOptIn,
      event: 'epsilonAbacusToggle'
    })

    brazeSetCustomUserAttributes({ epsilonAbacusOptIn: epsilonAbacusOptIn })
  }

export const getCommerceDataFromPackageWithExtras = (
  _package: Package,
  product: Product,
  lineItems: readonly MiniCartLineItem[],
  isBms: boolean,
  action: string,
  selectState: ImmutableState,
  packageFinalPrice?: number,
  applyPackageBrandtoExtras = false
): Ecommerce => {
  const packageProducts = packageToEcommProducts(
    _package,
    product,
    isBms,
    selectState,
    packageFinalPrice
  )
  // eslint-disable-next-line no-undefined
  const extrasPrice = isBms ? 0 : undefined
  // eslint-disable-next-line no-undefined
  const brand =
    applyPackageBrandtoExtras && packageProducts.length > 0
      ? packageProducts[0].brand
      : undefined
  const extraProducts = lineItems.map(lineItem =>
    productToEcommProduct(
      lineItem,
      propOr(0, 'quantity', lineItem),
      isBms ? BMS_NAME : brand,
      extrasPrice
    )
  )
  const packageDetails = { products: packageProducts.concat(extraProducts) }
  return {
    [action]: packageDetails,
    currencyCode: currencyCode
  }
}

export const getCommerceDataFromProduct = (
  product: Product,
  action: string,
  quantity: number
): Ecommerce => {
  const price = prop('price')(product)
  const discountedPrice = prop('discountedPrice', product)

  const productPrice = ifElse(isNil, always(price), () =>
    discountedPrice.fold(price)(response => response)
  )(discountedPrice)
  const products = getProductsGTMFromProduct(
    {
      ...product,

      price: productPrice
    },
    quantity
  )
  return {
    [action]: products,
    currencyCode: currencyCode
  }
}

export const getCommerceDataFromLineItem =
  (action: string) =>
  (lineItems: readonly LineItem[]) =>
  (lineItem: LineItem): Ecommerce => {
    const products = lineItemToEcommProducts(lineItems)(lineItem)

    return {
      [action]: { products },
      currencyCode: currencyCode
    }
  }

export const getCommerceDataFromLineItems =
  (action: string) =>
  (lineItems: readonly LineItem[]): Ecommerce => {
    const products = lineItemsToEcommProducts(lineItems)

    return {
      [action]: { products },
      currencyCode: currencyCode
    }
  }

/**
 * Take ProductName object (keyed by locale) and safely return
 * the name for the locale
 */
export const getNameFromProductName =
  (locale: string) => (nameObject: Record<string, string>) =>
    safeProp(locale, nameObject)

/* ####### BEGIN LINE ITEM HELPERS ####### */

const lineItemsToEcommProducts = (lineItems: readonly LineItem[]) =>
  lineItems
    .map(lineItemToEcommProducts(lineItems))
    .reduce((acc, val) => acc.concat(val), [])

/**
 * Take a collection of LineItem representing a cart and a particular LineItem from that collection;
 * generate and return a collection of productGTM, varying behavior for packages, package children,
 * or other.
 *
 * @param lineItems
 * @param lineItem
 */
const lineItemToEcommProducts =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
    const packageParentId = safePath(
      ['custom', 'fields', PACKAGE_PARENT_ID_FIELD_KEY],
      lineItem
    )

    const isPackage =
      packageParentId.isSome() &&
      propOr('', 'productType', lineItem) === PACKAGE_PARENT_PRODUCT_TYPE
    const isPackageChild = !isPackage && packageParentId.isSome()

    return isPackage
      ? packageLineItemToEcommProducts(lineItems)(lineItem)
      : isPackageChild
      ? [packageChildLineItemToEcommProduct(lineItems)(lineItem)]
      : [standaloneLineItemToEcommProduct(lineItem)]
  }

/**
 * Take a collection of LineItem representing a cart and a particular LineItem representing a package;
 * generate and return a collection of productGTM consisting of one for this package (with all its
 * children attached as variants) and one for each of those children.
 *
 * Generate and use a "brand" name for these items based on the package to tie them together.
 *
 * Use discount or wholesale price if present, else sum up the value of its components.
 *
 * @param lineItems
 * @param lineItem
 */
const packageLineItemToEcommProducts =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
    const brand = getPackageLineItemName(lineItem)

    // generate a top-level return entry for each child
    const childProducts = safeProp('child', lineItem)
      .map(lineItemChildrenToEcommProducts(brand))
      .getOrElse([])

    // also convert each child to a variant for this entry
    const childVariants = safeProp('child', lineItem)
      .map(lineItemChildrenToVariants)
      .getOrElse([])

    // find other line items that are also children of this package
    const childLineItems = getPackageParentIdFromLineItem(lineItem)
      .map(parentPackageId =>
        findMatchingChildLineItems(parentPackageId)(lineItems)
      )
      .getOrElse([])

    // convert those other line items to variants for this entry
    const childLineItemVariants = lineItemsToVariants(childLineItems)

    // all the variants for this entry
    const variants = childVariants.concat(childLineItemVariants)

    /*
  use either the line item price or, if 0 (eg BMS), sum up its constituent components
  */
    const price = Just(getLineItemPriceEach(lineItem))
      .filter(price => price > 0)
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      .getOrElse(sumLineItemPrices(childLineItems))

    // entry for this particular item, with variants attached
    const lineItemProduct = lineItemToEcommProduct(
      price,
      brand,
      variants
    )(lineItem)

    return [lineItemProduct].concat(childProducts)
  }

/**
 * Take a collection of LineItem representing a cart and a particular LineItem representing a
 * child component of a package; generate and return a productGTM.
 *
 * Find the parent package for this item and get its "brand" name to use in the entry.
 *
 * Use 0 for price so value is not double-counted against the parent package's value.
 *
 * @param lineItems
 * @param lineItem
 */
const packageChildLineItemToEcommProduct =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
    // find parent line item, convert to brand name
    const packageBrand = findParentPackageLineItem(lineItems)(lineItem)
      .map(getPackageLineItemName)
      .getOrElse('')

    return lineItemToEcommProduct(0, packageBrand)(lineItem)
  }

/**
 * Take a LineItem representing a product that is not a package nor part of a package;
 * generate and return a productGTM.
 *
 * Use discounted or wholesale price as available.
 *
 * @param lineItem
 */
const standaloneLineItemToEcommProduct = (lineItem: LineItem) => {
  const price = getLineItemPriceEach(lineItem)
  return lineItemToEcommProduct(price)(lineItem)
}

/**
 * Get the price per item by dividing total by quantity; round to 2 decimal places
 */
const getLineItemPriceEach = (lineItem: LineItem) => {
  const quantity = propOr(1, 'quantity', lineItem)
  // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  const priceEach = propOr(0, 'totalPrice', lineItem) / quantity
  return parseFloat(priceEach.toFixed(2))
}

/**
 * Take a LineItem and return a Maybe of a non-empty package parent id string
 *
 * @param lineItem
 */
const getPackageParentIdFromLineItem = (lineItem: LineItem) =>
  safePath(['custom', 'fields', PACKAGE_PARENT_ID_FIELD_KEY], lineItem)
    .filter(packageParentId => packageParentId !== '')
    .map(String)

const getPackageLineItemName = (lineItem: LineItem) =>
  isLineItemBms(lineItem)
    ? BMS_NAME
    : safeProp('name', lineItem)
        .chain(getNameFromProductName(locale))
        .getOrElse('')

const isLineItemBms = (item: LineItem): boolean =>
  safePath(['custom', 'fields', 'product_is_bms'], item).getOrElse(false)

/**
 * Take a collection of LineItem and a particular LineItem from that collection;
 * find and return a Maybe of the given LineItem's parent package LineItem if present
 *
 * @param lineItems
 * @param lineItem
 */
const findParentPackageLineItem =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) =>
    getPackageParentIdFromLineItem(lineItem).chain(packageParentId =>
      Maybe.fromNull(
        lineItems.find(
          otherLineItem =>
            propOr('', 'productType', otherLineItem) ===
              PACKAGE_PARENT_PRODUCT_TYPE &&
            getPackageParentIdFromLineItem(otherLineItem)
              .map(equals(packageParentId))
              .getOrElse(false)
        )
      )
    )

const sumLineItemPrices = (lineItems: readonly LineItem[]) =>
  lineItems
    .map(propOr(0, 'totalPrice'))
    // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'unknown' ... Remove this comment to see the full error message
    .reduce((acc, curr) => acc + curr, 0)

const lineItemChildrenToEcommProducts =
  (brand: string) => (children: readonly CTLineItemChild[]) =>
    children.map(
      transformObject<CTLineItemChild, productGTM>({
        brand: () => brand,
        id: propOr('', 'sku'),
        name: child => {
          return safeProp('name', child)
            .chain(getNameFromProductName(locale))
            .getOrElse('')
        },
        price: () => 0,
        quantity: propOr(0, 'quantity')
      })
    )

const lineItemChildrenToVariants = (children: readonly CTLineItemChild[]) =>
  children.map(
    transformObject<CTLineItemChild, VariantItem>({
      name: propOr({}, 'name'),
      price: () => 0,
      quantity: propOr(0, 'quantity'),
      sku: propOr('', 'sku')
    })
  )

const lineItemsToVariants = (lineItems: readonly LineItem[]) =>
  lineItems.map(
    transformObject<LineItem, VariantItem>({
      name: propOr({}, 'name'),
      price: () => 0,
      quantity: propOr(0, 'quantity'),
      sku: propOr('', 'sku')
    })
  )

const lineItemToEcommProduct = (
  price: number,
  brand?: string,
  variants?: readonly VariantItem[]
) => {
  return transformObject<LineItem, productGTM>({
    brand: () => brand,
    id: propOr('', 'sku'),
    name: (lineItem: LineItem) => {
      return safeProp('name', lineItem)
        .chain(getNameFromProductName(locale))
        .getOrElse('')
    },
    price: () => price,
    quantity: propOr(0, 'quantity'),
    ...(variants && { variant: () => variants })
  })
}

const findMatchingChildLineItems =
  (parentPackageId: string) => (lineItems: readonly LineItem[]) =>
    lineItems
      // @ts-expect-error TS(2345) FIXME: Argument of type 'LineItem' is not assignable to p... Remove this comment to see the full error message
      .filter(
        lineItem =>
          !propEq('productType', PACKAGE_PARENT_PRODUCT_TYPE)(lineItem)
      )
      .filter(
        pathEq(
          ['custom', 'fields', PACKAGE_PARENT_ID_FIELD_KEY],
          parentPackageId
        )
      )

/* ####### END LINE ITEM HELPERS ####### */

/* ####### BEGIN PACKAGE HELPERS ####### */

export const getPackagePrice =
  (hasMonitoring: boolean) => (_package: Package) =>
    (hasMonitoring
      ? prop('discountedPriceWithServicePlan', _package)
      : prop('discountedPrice', _package)
    ).getOrElse(prop('price', _package))

/**
 * Convert a Product and PackageProduct to a Record<string, unknown> variant
 */
const productToVariant = (product: Product, includedProduct: PackageProduct) =>
  transformObject<Product, Record<string, unknown>>({
    name: product => propOr({}, 'name', product),
    price: () => 0,
    quantity: () => propOr(0, 'quantity', includedProduct),
    sku: () => propOr('', 'sku', includedProduct)
  })(product)

/**
 * Convert a PackageProduct to a Record<string, unknown> variant using state
 */
const packageProductToVariant = (
  includedProduct: PackageProduct,
  state: ImmutableState
) =>
  safeProp('sku', includedProduct)
    .chain(sku => selectProduct(sku)(state).toMaybe())
    .map(product => productToVariant(product, includedProduct))

/**
 * Convert a Package's included products to an array of Record<string, unknown> variants using state
 */
const includedProductsToVariants = (_package: Package, state: ImmutableState) =>
  safeProp('products', _package)
    .map(includedProducts =>
      includedProducts
        .map(includedProduct =>
          packageProductToVariant(includedProduct, state).orNull()
        )
        .filter(isNotNil)
    )
    .getOrElse([])

/**
 * Convert a Package to a GtmProduct including variants
 */
const packageToEcommProduct = (
  _package: Package,
  brand: string,
  state: ImmutableState,
  packagePrice?: number
): GtmProduct => {
  const variants = includedProductsToVariants(_package, state)

  return {
    brand: brand,
    id: propOr('', 'sku', _package),
    name: brand,
    price: packagePrice ? packagePrice : propOr(0, 'price', _package),
    quantity: 1,
    // TODO @lib/tracking.GtmProduct.variant is a string but we've always treated it as an object
    // @ts-expect-error TS(2322) FIXME: Type 'Record<string, unknown>[]' is not assignable... Remove this comment to see the full error message
    variant: variants
  }
}

/**
 * Convert a Product/PackageProduct pair to a GtmProduct
 */
const packageProductToEcommProduct = (
  product: Product,
  packageProduct: PackageProduct,
  brand: string
) =>
  transformObject<Product, GtmProduct>({
    brand: () => brand,
    id: () => propOr('', 'sku', packageProduct),

    name: product => pathOr('', ['name', locale], product),
    price: () => 0,
    quantity: () => propOr(0, 'quantity', packageProduct)
  })(product)

/**
 * Convert a Package's included products to an array of GtmProduct using state
 */
const includedProductsToEcommProducts = (
  _package: Package,
  brand: string,
  state: ImmutableState
) =>
  safeProp('products', _package)
    .map(includedProducts =>
      includedProducts
        .map(includedProduct =>
          safeProp('sku', includedProduct)
            .chain(sku => selectProduct(sku)(state).toMaybe())
            .map(product =>
              packageProductToEcommProduct(product, includedProduct, brand)
            )
            .orNull()
        )
        .filter(isNotNil)
    )
    .getOrElse([])

/**
 * Convert a Package/Product pair to an array of GtmProduct. Includes an entry for the package itself -- with
 * included items as variants within -- as well as entries for incuded items in parallel.
 */
export const packageToEcommProducts = (
  _package: Package,
  packageProduct: Product,
  isBms: boolean,
  state: ImmutableState,
  packagePrice?: number
) =>
  Maybe.fromNull(_package)
    .chain(_package =>
      Maybe.fromNull(packageProduct).map(packageProduct => {
        const packageName = isBms
          ? BMS_NAME
          : safeProp('name', packageProduct)
              .chain(getNameFromProductName(locale))
              .getOrElse('')

        const packageEntry = packageToEcommProduct(
          _package,
          packageName,
          state,
          packagePrice
        )

        const includedProductEntries = includedProductsToEcommProducts(
          _package,
          packageName,
          state
        )

        return [packageEntry].concat(includedProductEntries)
      })
    )
    .getOrElse([])

/* ####### END PACKAGE HELPERS ####### */

/* ####### BEGIN PRODUCT HELPERS ####### */

const getProductsGTMFromProduct = (product: Product, quantity: number) => {
  const productObject = productToEcommProduct(product, quantity)
  return { products: values({ productObject }) }
}

const productToEcommProduct = (
  product: Product,
  quantity: number,
  brand?: string,
  price?: number
) =>
  transformObject<Product, GtmProduct>({
    brand: () => brand,
    id: () => propOr('', 'masterSku', product),

    name: product => pathOr('', ['name', locale], product),
    price: product =>
      Maybe.fromNull(price).getOrElse(propOr(0, 'price', product)),
    quantity: () => quantity
  })(product)

/* ####### END PRODUCT HELPERS ####### */
