import { currencyCode, noValue, getLocale } from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  CustomData,
  FacebookEventData,
  handleFacebookEvent
} from '@simplisafe/ss-ecomm-data/facebook'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { window } from 'browser-monads-ts'
import T from 'ramda/src/T'
import { useCallback } from 'react'
import { UAParser } from 'ua-parser-js'
import Cookies from 'universal-cookie'

import { visitorIdAtAt } from '../atat'
import { getLeadData } from '../cookies'
import { OptimizelyPurchaseEvent } from '../optimizely'
import type { Product } from '../types/tracking'
import { getClientIPAddress, normalizePhone, sha256Web } from './lib'

const cookies = new Cookies()
const userAgentParser = new UAParser()
const userAgentData = userAgentParser.getResult()

function getEventTime() {
  return Math.floor(Date.now() / 1000)
}

export type FacebookPageViewData = {
  readonly eventId: string
  readonly pageUrl: string
}

export type FacebookPurchaseEventData = {
  readonly phone: string
  readonly email: string
  readonly eventId: string
  readonly orderId: string
  readonly value: number
  readonly contents: ReadonlyArray<OptimizelyPurchaseEvent>
}

export const generateFacebookEventId = (): string =>
  (visitorIdAtAt() || '') + Date.now()

export async function generateCommonFacebookEventData(
  retrieveLeadData = true
): Promise<Omit<FacebookEventData, 'eventName'>> {
  const leadData = getLeadData()
  const leadEmail = prop('email', leadData)
  const leadPhone = prop('phone', leadData)
  return {
    eventId: generateFacebookEventId(),
    eventSourceUrl: window.location.href,
    eventTime: getEventTime(),
    testCode: process.env['FACEBOOK_CAPI_TEST_CODE'],
    userData: {
      clientIpAddress: await getClientIPAddress(),
      clientUserAgent: userAgentData.ua,
      em: retrieveLeadData && leadEmail && (await sha256Web(leadEmail)),
      externalId: visitorIdAtAt() || '',
      fbc: cookies.get<string>('_fbc'),
      fbp: cookies.get<string>('_fbp'),
      ph:
        retrieveLeadData &&
        leadPhone &&
        (await sha256Web(normalizePhone(leadPhone, getLocale())))
    }
  }
}

export const buildContents = (
  contents: ReadonlyArray<OptimizelyPurchaseEvent>
) => {
  return contents.map(product => {
    return {
      id: prop('sku', product),
      item_price: prop('price', product),
      quantity: prop('qty', product)
    }
  })
}

export const useFacebookTrackProductPurchase = () =>
  useCallback(async (data: FacebookPurchaseEventData) => {
    const value = prop('value', data)
    const email = prop('email', data)
    const phone = prop('phone', data)
    const orderId = prop('orderId', data)
    const contents = data.contents
    const contentType: CustomData['contentType'] = 'product'
    const commonEventData = await generateCommonFacebookEventData(false)
    const eventData: FacebookEventData = {
      ...commonEventData,
      actionSource: 'website',
      customData: {
        ...commonEventData.customData,
        contentType,
        contents: buildContents(contents),
        currency: currencyCode,
        orderId: orderId,
        value: value
      },
      // our GTM tag FCP - Facebook - System Purchase - OT uses orderId as the eventId
      eventId: orderId,
      eventName: 'Purchase',
      userData: {
        ...commonEventData.userData,
        em: email,
        ph: await sha256Web(normalizePhone(phone, getLocale()))
      }
    }
    handleFacebookEvent(eventData)(() =>
      logError(Error('Conversion API Purchase failure'))
    )(() => T)
  }, [])

export function getPageViewEventName(pageUrl: string) {
  if (
    pageUrl &&
    (pageUrl.includes('home-security-shop-packages') ||
      pageUrl.includes('business-security-shop'))
  ) {
    return 'ShopPageView'
  } else if (pageUrl && pageUrl.includes('home-security-system')) {
    return 'PackagePageView'
  } else {
    return 'PageView'
  }
}

export const useFacebookTrackSiteVisits = () =>
  useCallback(async (data: FacebookPageViewData) => {
    const pageUrl = prop('pageUrl', data)
    const eventData: FacebookEventData = {
      ...(await generateCommonFacebookEventData()),
      actionSource: 'website',
      eventId: prop('eventId', data),
      eventName: getPageViewEventName(pageUrl),
      eventSourceUrl: pageUrl
    }
    handleFacebookEvent(eventData)(() =>
      logError(Error('Conversion API PageView failure'))
    )(() => T)
  }, [])

export async function fbTrackAddProductsToCart(
  products: ReadonlyArray<Product>,
  packageName?: string
) {
  const contentType: CustomData['contentType'] = 'product'
  const commonEventData = await generateCommonFacebookEventData(true)
  const eventData: FacebookEventData = {
    ...commonEventData,
    customData:
      products.length > 0
        ? {
            ...commonEventData.customData,
            contentIds: products.map(x => x.id ?? '').filter(x => x !== ''),
            contentName: packageName ?? noValue(),
            contentType,
            contents: products.map(x => {
              return {
                id: x.id,
                item_price: x.price,
                quantity: x.quantity
              }
            }),
            currency: currencyCode
          }
        : noValue(),
    eventName: 'AddToCart'
  }
  handleFacebookEvent(eventData)(() =>
    logError(Error('facebook trackAddToCart failed'))
  )(() => T)
}

export async function fbTrackLeadCreated(email: string, phone?: string) {
  const hashedEmail = await sha256Web(email)
  const hashedPhone =
    phone && (await sha256Web(normalizePhone(phone, getLocale())))
  const commonEventData = await generateCommonFacebookEventData()
  const eventData: FacebookEventData = {
    ...commonEventData,
    eventName: 'Lead',
    userData: {
      ...commonEventData.userData,
      em: hashedEmail,
      ph: hashedPhone || noValue()
    }
  }
  handleFacebookEvent(eventData)(() =>
    logError(Error('facebook TrackLeadCreated failed'))
  )(() => T)
}
