import { FormField, Text } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type Props = {
  readonly description?: ReactElement
  readonly title?: string
}

function SuccessMessage({ description, title }: Props) {
  return (
    <FormField hideLabel={true} name="partnerWithUsSuccessMessage">
      <div className="m-auto text-center">
        {title && (
          <Text fontWeight="light">
            <h3 className="text-center">{title}</h3>
          </Text>
        )}
        {description}
      </div>
    </FormField>
  )
}

export default SuccessMessage
