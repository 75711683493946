import { SSButton } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import React from 'react'

import GatsbyImage from '../GatsbyImage'
import { PackageCardSchema } from './schema'

export type PackageCardProps = {
  readonly data: PackageCardSchema
}

export default function PackageCard({ data }: PackageCardProps) {
  return (
    <div
      className={classNames(
        'neutralLightGrayBackgroundColor lg:text-center md:text-left grid lg:grid-cols-1 md:grid-cols-2 sm:grid-cols-1 text-center pt-0'
      )}
    >
      <div className="lg:pl-0 lg:pt-2 md:order-second md:pl-10 md:pt-6 p-2 text-2xl">
        {data.title}
        {data.quantity && data.price ? (
          <div className="grid grid-cols-2 text-[18px]">
            <div>{data.quantity} Pieces</div>
            <div>{data.price}</div>
          </div>
        ) : (
          <div className="text-[18px]"> {data.bmsDescription} </div>
        )}
      </div>
      <div className="lg:rc-pb-16 md:order-third md:pb-0 h-64">
        {data.image && (
          <GatsbyImage
            image={data.image}
            style={{
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'relative',
              width: '100%'
            }}
          />
        )}
      </div>
      <div className="relative md:absolute lg:relative md:bottom-0 md:order-second md:pl-10 p-5 lg:pl-0">
        <SSButton {...data.button}> {data.button.value} </SSButton>
      </div>
    </div>
  )
}
