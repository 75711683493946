import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { Column, Row, SSInput } from '@simplisafe/ss-react-components'
import { PatentTable } from '@simplisafe/ss-react-components'
import {
  PatentTableCell,
  PatentTableRow
} from '@simplisafe/ss-react-components/PatentTable'
import { graphql, Link } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
import React, { ReactNode, useState } from 'react'

import {
  ContentfulLegal,
  ContentfulTableCell,
  ContentfulTableRow
} from '../../../graphql'
import { renderComponentFromData } from '../../util/render'
import { PageProps } from '../Page'
import SubmitIdeaForm from '../SubmitIdeaForm'

type ContentfulLegalContainerProps = {
  readonly id: string
  readonly data: Partial<ContentfulLegal>
  readonly pageContext?: PageProps['pageContext']
}

const toFormContent = applySpec<PatentTableCell>({
  colspan: (x: ContentfulTableCell) => prop('colspan', x),
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulImageContentfulRichTex... Remove this comment to see the full error message
  content: (x: ContentfulTableCell) =>
    renderComponentFromData(prop('content', x))
})

const toGetPatentTableProps = (
  tableData: readonly ContentfulTableRow[]
): readonly PatentTableRow[] => {
  return (
    tableData &&
    tableData.map(data => {
      const { cell } = data
      return { cell: cell?.map(toFormContent) ?? [] }
    })
  )
}

export default function LegalContainer({
  data
}: ContentfulLegalContainerProps) {
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const patentTableProps = toGetPatentTableProps(
    pathOr<readonly ContentfulTableRow[], readonly ContentfulTableRow[]>(
      [],
      ['table', 'row'],
      data
    )
  )
  const submissionCheckbox = prop('submissionCheckbox', data)
  const submissionForm = prop('submissionFormV2', data)
  const [isShowForm, setShowForm] = useState(false)

  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_: unknown, text: ReactNode) => (
        <h1 className="h1">{text}</h1>
      ),
      [BLOCKS.HEADING_2]: (_: unknown, text: ReactNode) => (
        <h2 className="h2">{text}</h2>
      ),
      [BLOCKS.HEADING_3]: (_: unknown, text: ReactNode) => (
        <h3 className="h3">{text}</h3>
      ),
      [BLOCKS.HEADING_4]: (_: unknown, text: ReactNode) => (
        <h4 className="h4">{text}</h4>
      ),
      [BLOCKS.PARAGRAPH]: (_: unknown, children: ReactNode) => (
        <p className="paragraph">{children}</p>
      ),
      [INLINES.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target?.internal?.type === 'ContentfulLink' ? (
          <button className={data.target.className}>
            {data.target.linkText}
          </button>
        ) : (
          data?.target?.referenceId && <p id={data.target.referenceId}></p>
        ),

      [INLINES.HYPERLINK]: ({ data, content }) =>
        content.length &&
        content[0].nodeType === 'text' && (
          <Link className="link text-neutral-black" to={data?.uri}>
            {content[0].value}
          </Link>
        )
    }
  }

  return (
    <>
      <Row>
        <Column spans={[12, 12]}>
          <div className="richText breakLine neutralBlackTextColor">
            <ContentfulRichText
              optionsCustom={options}
              raw={data?.description?.raw}
              references={data?.description?.references}
            />
          </div>
        </Column>
      </Row>
      {patentTableProps && <PatentTable row={patentTableProps} />}
      {submissionCheckbox && (
        <SSInput
          checked={isShowForm}
          defaultChecked={!!prop('defaultChecked', submissionCheckbox)}
          id={prop('id', submissionCheckbox)}
          label={<p>{prop('placeholderText', submissionCheckbox)}</p>}
          name={prop('propName', submissionCheckbox) || ''}
          onChange={() => {
            setShowForm(!isShowForm)
          }}
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'inputType... Remove this comment to see the full error message
          type={prop('type', submissionCheckbox)}
        />
      )}
      {submissionForm && isShowForm && <SubmitIdeaForm data={submissionForm} />}
    </>
  )
}

export const query = graphql`
  #graphql
  fragment contentfulLegalFragment on ContentfulLegal {
    id
    internal {
      type
    }
    description {
      raw
      references {
        ... on ContentfulLink {
          ...contentfulLinkFragment
          className
        }
        ... on ContentfulSectionId {
          contentful_id
          __typename
          referenceId
        }
      }
    }
    table {
      row {
        cell {
          colspan
          content {
            ... on ContentfulImage {
              id
              internal {
                type
              }
              imageItem: image {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 160
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
            ... on ContentfulRichTextWithOptions {
              ...richTextWithOptions
            }
          }
        }
      }
    }
    submissionCheckbox {
      ... on ContentfulForms {
        answerOptions
        maximumCharacter
        propName
        title
        placeholderText
        placeholderTextMobile
        id
        fieldValidation {
          requirement
          errorMessage
        }
        type
        checked
        defaultChecked
        deviceToDisplay
      }
    }
    submissionFormV2 {
      ... on ContentfulSubmitIdeaForm {
        ...submitIdeaForm
      }
    }
  }
`
