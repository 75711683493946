import { useOptimizelyParams } from '@lib/tracking'
import { devParams } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { configureNewRelic } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  PageBody,
  PageMasthead,
  PageWrapper
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Maybe, None } from 'monet'
import defaultTo from 'ramda/src/defaultTo'
import React, { useContext, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import {
  ContentfulBannerTopPage,
  ContentfulFooter,
  ContentfulHeroBanner,
  ContentfulModalPromoPopup,
  ContentfulPageAnchorNavigation
} from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import ErrorBoundary from '../../errorComponents/ErrorBoundary'
import useAwinTracking from '../../hooks/useAwinTracking'
import useEnableLiveChat from '../../hooks/useEnableLiveChat'
import useScrollToHash from '../../hooks/useScrollToHash'
import useUtmContent from '../../hooks/useUtmContent'
import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import { PageTitleContext } from '../../tracking/pageTitleContext'
import { trackEventIsMobile } from '../../util/analytics'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import SEO from '../../util/seo'
import ApplyPromoCodeComponent from '../ApplyPromoCodeComponent'
import AuthenticationComponent from '../AuthenticationComponent'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import DefaultBannerComponent from '../DefaultBannerComponent'
import FloatingPromoWidgetComponent from '../FloatingPromoWidgetComponent'
import FooterComponent from '../FooterComponent'
import GlobalPromotionalComponent from '../GlobalPromotionalComponent'
import HeaderComponent from '../Header'
import { ContentfulHeaderFragment } from '../Header/query'
import HeaderPartnerComponent from '../HeaderPartnerComponent'
import HeaderProgressBarComponent from '../HeaderProgressBarComponent'
import HeroBannerComponent from '../HeroBannerComponent'
import LiveChat from '../LiveChat'
import ModalExitIntentComponent from '../ModalExitIntentComponent'
import ModalPromoPopupComponent from '../ModalPromoPopupComponent'
import PageAnchorNavigationComponent from '../PageAnchorNavigationComponent'
import PageBannerComponent from '../PageBannerComponent'
import PopupWizard from '../PopupWizard'
import SimpleFooterComponent from '../SimpleFooterComponent'
import TopPageBanner from '../TopPageBanner'
import TrustpilotReviewBanner from '../TrustpilotBanner'
import CountryRedirectModals from './CountryRedirectModals'
import { getPageLayoutComponents, renderPageComponents } from './helpers'
import PageToaster from './PageToaster'
import PromoBanner from './PromoBanner'
import {
  MappedComponentProps,
  PageComponent,
  PageLayoutComponent,
  PageProps
} from './types'

export const isBrowser = typeof window !== 'undefined'
export const getWindow = () => window

/*
NOTE: we need to continue importing getMappedComponent into this file for now.
Somehow GroupSection was unable to dynamically render ContentfulModal using getMappedComponent.
If we move this function back into helpers, be sure to check the choose monitoring page can
still render the 'Skip this step' modal to proceed without monitoring. (UK build)

UPDATE: exploration shows that if this function is moved to helpers.tsx, then they key for
ContentfulModal in the componentMappings.componentMappings map is null when it should be the
ModalComponent component. ModalComponent as an import is unchanged, it is present and correct.
But as a value in the map it's null. Unclear why this happens when moving the function from
one file to another but that's what causes the above condition.
*/
const mapPageComponentToTemplate = (
  pageComponent: PageComponent,
  pageContext?: PageProps['pageContext'],
  location?: PageProps['location']
) => {
  const Component = getMappedComponent<MappedComponentProps>(pageComponent)

  return Component ? (
    <ErrorBoundary key={pageComponent.id}>
      <Component
        data={pageComponent}
        key={pageComponent.id}
        location={location}
        pageContext={pageContext}
      />
    </ErrorBoundary>
  ) : null
}

function Page({ data, pageContext, location }: any) {
  useEffect(() => {
    configureNewRelic()
    isBrowser && getWindow().scrollTo(0, 0)
  }, [])

  useScrollToHash()

  const liveChatStatus = useEnableLiveChat(data)

  const liveChatId = liveChatStatus && liveChatStatus.liveChatId
  const liveChatEnabled = liveChatStatus && liveChatStatus.liveChatEnabled
  const locale = useSelector(selectLocale)

  const isTabletUp = useMediaQuery('TabletAndUp')
  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)
  // this is the value from contentful that we will no longer be using
  const globalPromoCode = defaultTo('')(
    path(['contentfulPage', 'pageLayout', 'promoCode'], data)
  )
  const pagePath = path(['contentfulPage', 'pageUrl'], data) || ''

  useUtmContent()
  useAwinTracking(locale === 'en-GB')

  useOptimizelyParams()

  // pageTitle is passed to our SEO component below, and also set via the setPageTitle context hook so it can be passed
  // to tracking endpoints like AT-AT (see InjectPageTracking).
  const pageTitle: string =
    path(['contentfulPage', 'seoDetails', 'metaTitle'], data) || ''
  const setPageTitle = useContext(PageTitleContext)
  const { trackEvent } = useTracking()

  useEffect(() => {
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])

  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [isTabletUp, trackEvent])

  const components: readonly PageLayoutComponent[] = safePath(
    ['contentfulPage', 'pageLayout', 'components'],
    data
  ).orJust([])
  const allComps = getPageLayoutComponents(components)

  const pageComponents: Maybe<readonly PageComponent[]> = safePath(
    ['contentfulPage', 'pageComponents'],
    data
  ).chain(x => Maybe.fromNull(x))
  const pageHeroBanner: Maybe<ContentfulHeroBanner> = safePath(
    ['contentfulPage', 'pageHeroBanner'],
    data
  ).chain(x => Maybe.fromNull(x))
  const belowComponents: readonly PageLayoutComponent[] = safePath(
    ['contentfulPage', 'pageLayout', 'belowComponents'],
    data
  ).orJust([])
  const countryRedirectModal: Maybe<SmallTextSectionRedirect> = safeProp(
    'contentfulSmallTextSection',
    data
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type '(component: PageComponent) => JS... Remove this comment to see the full error message
  const belowPageLayoutCompoments = belowComponents.map(
    (component: PageComponent) => mapPageComponentToTemplate(component)
  )
  const anchoredNav: ContentfulPageAnchorNavigation = safePath(
    ['contentfulPage', 'anchoredNavigation'],
    data
  ).orNull()
  const productId: Maybe<string> = safePath(
    ['contentfulPage', 'productId'],
    data
  )
  const pageUrl: string = safePath(
    ['contentfulPage', 'pageUrl'],
    data
  ).getOrElse('')
  const pageSchema = safePath(
    ['contentfulPage', 'seoDetails', 'pageSchema'],
    data
  ).orUndefined()
  const pageTopBanner: ContentfulBannerTopPage = safePath(
    ['contentfulPage', 'pageTopBanner'],
    data
  ).orUndefined()
  const siteLocales = data.contentfulPage?.site

  useEffect(() => {
    toast.remove('promoToast')
  }, [pageUrl])

  const dispatch = useDispatch()
  const partnerName = getValueFromPartnerCookie('partnerName')
  const partnerGroup = getValueFromPartnerCookie('partnerGroup')
  useEffect(() => {
    // Fetch partner banner to display in place of default site-wide promo banner
    partnerName &&
      partnerGroup &&
      dispatch(IOPartnerBanner(partnerName, partnerGroup))
  }, [dispatch, partnerName, partnerGroup])

  const renderPageBody = () =>
    pageContext && (
      <ErrorBoundary>
        <PageBody>
          {pageComponents.cata(
            () => null,
            renderPageComponents(pageContext, location)
          )}
        </PageBody>
      </ErrorBoundary>
    )

  return (
    <PageWrapper key={pageContext.id}>
      {anchoredNav && (
        <PageAnchorNavigationComponent
          data={anchoredNav}
          key="PageAnchorNavigationComponent"
        />
      )}
      <CountryRedirectModals
        countryRedirectModals={
          countryRedirectModal || None<SmallTextSectionRedirect>()
        }
        hidePopups={!!hidePopups}
      />
      <PageToaster />
      <ApplyPromoCodeComponent pagePath={pagePath} />
      <GlobalPromotionalComponent
        data={{ globalPromoCode }}
        key="GlobalPromotionalComponent"
        pageContext={pageContext}
      />
      {pageTopBanner && <TopPageBanner data={pageTopBanner} />}
      {allComps.pageBanner
        .map(data => <PageBannerComponent data={data} key={data.id} />)
        .orNull()}
      <PromoBanner pageData={data} />
      {
        /* eslint-disable @typescript-eslint/consistent-type-assertions*/
        allComps.contentfulHeaderVariationContainer
          .map(data =>
            Maybe.fromNull(
              <ContentfulVariationContainerComponent
                data={data}
                key={data.id}
                // @ts-expect-error TS(2339) FIXME: Property 'internal' does not exist on type 'Varian... Remove this comment to see the full error message
                renderVariant={variant =>
                  variant.internal.type === 'ContentfulHeader' ? (
                    <HeaderComponent
                      data={variant as ContentfulHeaderFragment}
                    />
                  ) : null
                }
              />
            )
          )
          .orNull()
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
      {allComps.contentfulHeader
        .map(data => <HeaderComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.headerProgressBar
        .map(data => <HeaderProgressBarComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.headerPartner
        .map(data => <HeaderPartnerComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.modalExitIntent
        .map(data => (
          // @ts-expect-error TS(2322) FIXME: Type 'PageLayoutComponent' is not assignable to ty... Remove this comment to see the full error message
          <ModalExitIntentComponent
            data={data}
            key={data.id}
            location={location}
            pageContext={pageContext}
          />
        ))
        .orNull()}
      {allComps.modalPromoPopup
        .map(data => <ModalPromoPopupComponent data={data} key={data.id} />)
        .orNull()}
      {allComps.contentfulModalPromoPopupVariationContainer
        .map(data =>
          Maybe.fromNull(
            <ContentfulVariationContainerComponent
              data={data}
              key={data.id}
              // Assert type since TypeScript doesn't infer type from variant.internal.type check

              renderVariant={variant => (
                <ModalPromoPopupComponent
                  data={variant as ContentfulModalPromoPopup}
                />
              )}
            />
          )
        )
        .orNull()}
      {
        pageHeroBanner
          .map(data =>
            data?.internal?.type === 'ContentfulVariationContainer' ? (
              /* eslint-disable @typescript-eslint/consistent-type-assertions */
              // @ts-expect-error TS(2322) FIXME: Type 'ContentfulHeroBanner' is not assignable to t... Remove this comment to see the full error message
              <ContentfulVariationContainerComponent
                data={data}
                renderVariant={variant => (
                  <HeroBannerComponent
                    data={variant as ContentfulHeroBanner}
                    key="hero-banner"
                  />
                )}
              />
            ) : (
              <HeroBannerComponent
                data={data as ContentfulHeroBanner}
                key="hero-banner"
              />
            )
          )
          .orNull()
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
      <div key="TrustpilotReviewBanner-wrapper">
        {allComps.trustpilotBanner
          .map(data => <TrustpilotReviewBanner data={data} key={data.id} />)
          .orNull()}
      </div>
      {allComps.contentfulDefaultBanner.cata(
        () => null,
        data => (
          <PageMasthead key={data.id}>
            <DefaultBannerComponent data={data} />
          </PageMasthead>
        )
      )}
      {allComps.contentfulFloatingPromoWidget
        .map(data => (
          <div key="floating-promo-widget">
            <FloatingPromoWidgetComponent
              data={data}
              key={data.id}
              liveChatAppId={liveChatId}
            />
          </div>
        ))
        .orNull()}
      {allComps.authentication.cata(renderPageBody, data => (
        <AuthenticationComponent
          // @ts-expect-error TS(2322) FIXME: Type 'PageLayoutComponent' is not assignable to ty... Remove this comment to see the full error message
          data={data}
          key="AuthenticationComponent"
          location={location}
          pageContext={pageContext}
        >
          {renderPageBody()}
        </AuthenticationComponent>
      ))}
      {allComps.popupWizard
        .map(data => (
          <ErrorBoundary key={data.id}>
            <PopupWizard data={data} />
          </ErrorBoundary>
        ))
        .orNull()}

      {
        // I think I have this logic correct, but it was hard to tell from the original code
        // If simplefooter is Just, render it, otherwise if contentfulFooter is Just, render that, otherwise render null
        <div key="Footer-wrapper">
          {allComps.simpleFooter.cata(
            () =>
              allComps.contentfulFooter
                .map((data: ContentfulFooter) => (
                  <div key="FooterComponent-wrapper">
                    <FooterComponent data={data} id={data.id} key={data.id} />
                  </div>
                ))
                .orNull(),
            simpleFooter => (
              <div key="SimpleFooter-wrapper">
                {/* @ts-expect-error TS(2322): Type 'PageLayoutComponent' is not assignable to ty... Remove this comment to see the full error message */}
                <SimpleFooterComponent
                  data={simpleFooter}
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'PageLayoutCo... Remove this comment to see the full error message
                  id={simpleFooter.id}
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'PageLayoutCo... Remove this comment to see the full error message
                  key={simpleFooter.id}
                />
              </div>
            )
          )}
        </div>
      }
      {belowPageLayoutCompoments}
      <SEO
        key="page-seo"
        lang={path(['contentfulPage', 'node_locale'], data)}
        metaDescription={path(
          [
            'contentfulPage',
            'seoDetails',
            'metaDescription',
            'metaDescription'
          ],
          data
        )}
        metaKeyword={path(
          ['contentfulPage', 'seoDetails', 'keywords', 'keywords'],
          data
        )}
        metaTitle={pageTitle}
        pageSchema={pageSchema}
        pageUrl={pageUrl}
        productId={productId}
        seoDetails={safePath(['contentfulPage', 'seoDetails'], data).getOrElse(
          {}
        )}
        sharedLocaleUrl={siteLocales ? siteLocales.length > 1 : false}
      />
      {liveChatEnabled && <LiveChat appId={liveChatId} />}
    </PageWrapper>
  )
}

export { Page as default, mapPageComponentToTemplate }

export type { PageProps, PageComponent }
