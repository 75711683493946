import type { TrackEvent } from '../../util/analytics'

// Remove GTM logic from component into some central place like ecomm-data
const GTM_ACTION_CHOOSE_PLAN = 'choose-plan'
const GTM_ACTION_COMPARE_PLAN = 'compare-plan'
const GTM_ACTION_OTHER_PLAN = 'show-other-plans'
const GTM_ACTION_OPEN_LIMITEDPRO_MODAL = 'compare-plan'
const GTM_CATEGORY = 'product-plan'
const GTM_EVENT = 'buttonClick'
const GTM_SITE = 'fcp'

// TODO: REPLACE ME - forcing GTM fields into TrackingData
export const showOtherPlansGTM = (trackEvent: TrackEvent) => {
  trackEvent({
    action: GTM_ACTION_OTHER_PLAN,
    category: GTM_CATEGORY,
    event: GTM_EVENT,
    label: 'show other plans',
    site: GTM_SITE
  })
}

// TODO: REPLACE ME - forcing GTM fields into TrackingData
export const comparePlansGTM = (trackEvent: TrackEvent) => {
  trackEvent({
    action: GTM_ACTION_COMPARE_PLAN,
    category: GTM_CATEGORY,
    event: GTM_EVENT,
    label: 'compare plans',
    site: GTM_SITE
  })
}

// TODO: REPLACE ME - forcing GTM fields into TrackingData
export const modalClickTargetGTM = (
  trackEvent: TrackEvent,
  price = '50¢/day'
) => {
  trackEvent({
    action: GTM_ACTION_OPEN_LIMITEDPRO_MODAL,
    category: GTM_CATEGORY,
    event: GTM_EVENT,
    label: `Standard Monitoring (${price}).`,
    site: GTM_SITE
  })
}

// TODO: REPLACE ME - forcing GTM fields into TrackingData
export const trackEventChoosePlan = (
  trackEvent: TrackEvent,
  titlePlan: string
) => {
  trackEvent({
    action: GTM_ACTION_CHOOSE_PLAN,
    category: GTM_CATEGORY,
    event: GTM_EVENT,
    label: titlePlan,
    site: GTM_SITE
  })
}
