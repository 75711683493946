import { ResponsiveContainer } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { ContentfulResponsiveContainer } from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import { renderComponentFromData } from '../../util/render'

export type ResponsiveContainerProps = {
  readonly data: ContentfulResponsiveContainer
}

export default function ResponsiveContainerComponent({
  data
}: ResponsiveContainerProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const content = propOr<
    readonly ContentfulComponent[],
    readonly ContentfulComponent[]
  >([], 'showContent', data)

  return (
    <ResponsiveContainer
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      desktop={propOr<boolean, boolean>(false, 'showOnDesktop', data)}
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      mobile={propOr<boolean, boolean>(false, 'showOnMobile', data)}
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      tablet={propOr<boolean, boolean>(false, 'showOnTablet', data)}
    >
      {/* @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type '<V>(p: stri... Remove this comment to see the full error message */}
      <>{content && content.map(data => renderComponentFromData(data))}</>
    </ResponsiveContainer>
  )
}

export const ResponsiveContainerQuery = graphql`
  #graphql
  fragment responsiveContainer on ContentfulResponsiveContainer {
    id
    entryTitle
    showContent: content {
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulGetaQuoteForm {
        ...getAQuoteForm
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
    }
    showOnMobile: mobile
    showOnTablet: tablet
    showOnDesktop: desktop
    internal {
      type
    }
  }

  fragment nonCyclicalResponsiveContainer on ContentfulResponsiveContainer {
    id
    entryTitle
    showContent: content {
      ... on ContentfulGroupSection {
        ...groupSectionInternals
        contentsToGroup {
          ... on ContentfulImage {
            ...contentfulImage
          }
          ... on ContentfulFindYourPerfectSystem {
            ...quoteWizard
          }
          ... on ContentfulSmallTextSection {
            ...nonCyclicalSmallTextSectionFragment
          }
          ... on ContentfulGroupSection {
            ...groupSectionInternals
            contentsToGroup {
              ... on ContentfulButton {
                ...contentfulButtonFragment
              }
            }
          }
        }
      }
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
      ... on ContentfulSmallTextSection {
        ...nonCyclicalSmallTextSectionFragment
      }
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    showOnMobile: mobile
    showOnTablet: tablet
    showOnDesktop: desktop
    internal {
      type
    }
  }
`
