import { SSButton } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import React from 'react'

function EmptyMessage() {
  return (
    <div className="w-full flex justify-center items-center h-full">
      <div className={classNames('flex justify-center flex-col h-auto')}>
        <h1 className="font-arizona text-center mb-5 text-4xl md:text-[65px] md:leading-[4.7rem]">
          Your shopping cart is currently empty.
        </h1>
        <SSButton
          className="m-auto w-full md:w-48"
          color="primary"
          href="/home-security-shop"
          type="link"
        >
          Shop now
        </SSButton>
      </div>
    </div>
  )
}

export default EmptyMessage
