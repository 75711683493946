import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { QuantityChanger, Text } from '@simplisafe/ss-react-components'
import { PriceSection } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import { Maybe } from 'monet'
import React from 'react'

import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import { usePriceContext } from '../../providers/PriceProvider'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import type { ProductHeroPriceProps } from './types'

function ProductHeroPrice({
  product,
  productData,
  quantity,
  setQuantity,
  isSellable
}: ProductHeroPriceProps) {
  const { getFormattedPrice } = usePriceContext()
  const formattedPrice = getFormattedPrice(
    safeProp('productId', productData).orJust('')
  )(pricePerUnit)

  return (
    <>
      <PriceSection
        message={productData?.priceMessage || undefined}
        messageClassName={classNames(productData?.priceMessageClassName, {
          [`${productData?.priceMessageTextColor}TextColor`]:
            productData?.priceMessageTextColor
        })}
        price={formattedPrice}
        priceDisclaimer={productData?.priceDisclaimerText || undefined}
        priceDisclaimerClassName={classNames(
          productData?.priceDisclaimerClassName,
          {
            [`${productData?.priceDisclaimerTextColor}TextColor`]:
              productData?.priceDisclaimerTextColor
          }
        )}
        quantitySelector={
          Maybe.fromNull(productData?.showQuantityChanger).orJust(true) && (
            <QuantityChanger
              className={'neutralBlackTextColor'}
              defaultValue={quantity}
              label={prop('quantityChangerLabel', productData)}
              max={productData?.maxQuantity || 4}
              min={1}
              onChange={setQuantity}
            />
          )
        }
      />
      {isSellable && (
        <Text fontWeight="medium" textColor="cautionTextYellow" textSize="sm">
          {renderOutOfStockMessage({ product: product })}
        </Text>
      )}
    </>
  )
}

export default ProductHeroPrice
