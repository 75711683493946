export type RichTextProps<T = unknown> = Record<string, T> & {
  readonly raw?: string | null
}

/**
 * @deprecated Use `ContentfulRichText` instead.
 *
 * This component is deprecated and is just a no-op. Replace any usage with packages/site/src/components/ContentfulRichText.
 */
export default function RichText(_: RichTextProps) {
  return null
}
