import { Cancel } from '@simplisafe/ss-react-components/icons'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import React from 'react'

import type { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import type { ActivePromoBannerType } from '.'
import MobileAltLayout from './MobileAltLayout'
import MobileMaximizedLayout from './MobileMaximizedLayout'
import MobileMinimizedLayout from './MobileMinimizedLayout'
import useScrollCondition from './useScrollCondition'

export type MobileBottomBannerProps = {
  readonly isDismissed: boolean
  readonly isBottomBannerVariation: boolean
  readonly isDismissedAfterScrolling: boolean
  readonly isFormSubmitted: boolean
  readonly isMinimized: boolean
  readonly onDismiss: () => void
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
  readonly onMinimize: () => void
  readonly onRedeemClick: () => void
  readonly type: ActivePromoBannerType
}

function MobileBottomBanner({
  isBottomBannerVariation,
  isDismissed,
  isDismissedAfterScrolling,
  isFormSubmitted,
  isMinimized,
  onDismiss,
  onEmailSubmit,
  onMinimize,
  onRedeemClick,
  type
}: MobileBottomBannerProps) {
  const hasScrollCondition = useScrollCondition(isBottomBannerVariation)

  const closeBtn = (
    <span
      style={{
        position: 'absolute',
        right: '12px',
        top: '12px'
      }}
    >
      <Cancel
        className="closebutton"
        onClick={() => (isMinimized ? onDismiss() : onMinimize())}
        titleA11y="Close"
      />
    </span>
  )

  const bottomBanner = isBottomBannerVariation ? (
    <MobileAltLayout
      isFormSubmitted={isFormSubmitted}
      onEmailSubmit={onEmailSubmit}
      type={type}
    />
  ) : (
    <MobileMaximizedLayout
      isFormSubmitted={isFormSubmitted}
      onEmailSubmit={onEmailSubmit}
      type={type}
    />
  )

  const isCart = type === 'cart'
  const shouldShowNormal = !isCart && !isDismissed
  const shouldShowInExperiment =
    shouldShowNormal ||
    (isBottomBannerVariation &&
      !isDismissedAfterScrolling &&
      hasScrollCondition)

  return shouldShowNormal || shouldShowInExperiment ? (
    <Promo.PromoBanner data-component="MobileBottomBanner" position="bottom">
      {closeBtn}
      {isMinimized ? (
        <MobileMinimizedLayout
          isFormSubmitted={isFormSubmitted}
          onRedeemClick={onRedeemClick}
        />
      ) : (
        bottomBanner
      )}
    </Promo.PromoBanner>
  ) : null
}

export default MobileBottomBanner
