import { Location } from '@simplisafe/ss-react-components/icons'
import React from 'react'

type LocationButtonProps = {
  readonly onClick: () => void
}

function LocationButton({ onClick }: LocationButtonProps) {
  return (
    <button
      aria-label="Location Button"
      className="mr-2 p-1 md:p-2 border-l border-gray-300 border-solid bg-white search-button z-10 -ml-11 md:-ml-14 border-0 cursor-pointer"
      onClick={onClick}
    >
      <Location className="w-5 h-5" />
    </button>
  )
}

export default LocationButton
