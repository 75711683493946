import { GatsbyImage, YoutubeVideo } from '@lib/components'
import { getYoutubeEmbedUrl, isYoutubeVideo } from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import { SSButton } from '@simplisafe/ss-react-components'
import {
  MinWidthType,
  SSButtonProps
} from '@simplisafe/ss-react-components/SSButton'
import { graphql, Link } from 'gatsby'
import _ from 'lodash/fp'
import propOr from 'ramda/src/propOr'
import React, { CSSProperties, MouseEventHandler } from 'react'

import { ContentfulButton } from '../../../graphql'
import { rewritePartnerShopUrl, toButton } from '../../util/helper'
import ModalComponent from '../ModalComponent'
import Play from './Play'

export type ContentfulButtonProps = {
  //todo data should be using a fragment here instead of a partial
  readonly data: Partial<ContentfulButton> & {
    readonly buttonText?: string
    readonly fontColor?: string
  }
  readonly className?: string
  readonly disabled?: boolean
  readonly onClick?: MouseEventHandler<HTMLElement>
  readonly showSpinner?: boolean
  readonly style?: CSSProperties
}

const iconStyle: CSSProperties = { marginLeft: '10px' }

// TODO: Gatsby 4 rich text - gatsby-plugin-image
// const toGatsbyImage = (data: ContentfulAsset) => {
//   const fluid = prop('fluid', data)
//   const title = prop('title', data)
//   const key = prop('contentful_id', data)
//   return <FluidImg alt={title}
//     fluid={fluid}
//     imgStyle={{
//       marginLeft: '0',
//       objectFit: 'contain'
//     }}
//     key={key}
//     style={imgStyle}
//   />
// }

export const isHashUrl = (url?: string): boolean => !!url && url.startsWith('#')
export const isLocalUrl = (url?: string): boolean =>
  !!url && url.startsWith('/')

export default function ButtonComponent({
  className,
  data,
  disabled,
  onClick,
  showSpinner,
  style = {}
}: ContentfulButtonProps) {
  const buttonText = prop('buttonText', data)
  const disabledText = prop('disabledText', data)
  const button: SSButtonProps = toButton({
    ...data,
    buttonText: disabled ? disabledText || buttonText : buttonText
  })
  // added this validation to prevent testing errors
  const url = prop('url', data)
  const isLinkExternal = prop('isLinkExternal', data)
  const isLocal = isLocalUrl(url)
  const isYoutubeLink = url ? isYoutubeVideo(url) : false
  const urlFormatted = rewritePartnerShopUrl(url)
  const openInNewTab = !!data.openInNewTab

  const fontColor: string = prop('fontColor', data) || ''

  const linkButtonType = isYoutubeLink ? 'button' : isLocal ? 'div' : 'link'

  /**
   * Local links should be wrapped with Gatsby links, so its child should be a div
   * Links should render as anchor links
   * undefined type defaults to a button as specified by SSButton.
   * https://github.com/simplisafe/ss-react-components/blob/240d6c072e2374d183faab850e75aa295ac9f839/src/atoms/SSButton/index.tsx#L122
   */
  const buttonProp: SSButtonProps = {
    ...button,
    target: openInNewTab ? '_blank' : undefined,
    type: url ? linkButtonType : undefined
  }
  const buttonUrl =
    isYoutubeLink && buttonProp.href
      ? getYoutubeEmbedUrl(buttonProp.href, true)
      : buttonProp.href

  const buttonSize = propOr<MinWidthType, MinWidthType>(
    'auto',
    'buttonSize',
    data
  )

  const gatsbyImageData = _.property(['icon', 'gatsbyImageData'])(data)

  const fileUrl = _.property(['icon', 'file', 'url'])(data)

  const altText = _.property(['icon', 'description'])(data)
  const icon = gatsbyImageData ? (
    <GatsbyImage image={_.property('icon')(data)} style={iconStyle} />
  ) : fileUrl ? (
    <img alt={altText} src={fileUrl} />
  ) : null

  const ssButton = (
    <SSButton
      {...buttonProp}
      className={className}
      disabled={disabled}
      minWidth={buttonSize}
      onClick={onClick}
      showSpinner={showSpinner}
      style={{
        ...style,
        color: style.color || fontColor
      }}
    >
      {buttonProp.children}
      {isYoutubeLink ? <Play /> : icon}
    </SSButton>
  )

  function renderButton(
    isLocal: boolean,
    url: string | undefined,
    useAnchor: boolean | undefined,
    newTab: boolean
  ) {
    const linkTarget = newTab ? '_blank' : undefined

    if (isLocal && url) {
      return useAnchor || newTab ? (
        <a
          href={urlFormatted}
          onClick={event => {
            onClick && onClick(event)
            return true
          }}
          style={{ textDecoration: 'none' }}
          target={linkTarget}
        >
          {ssButton}
        </a>
      ) : (
        <Link data-component="link" to={urlFormatted}>
          {ssButton}
        </Link>
      )
    } else {
      return ssButton
    }
  }

  const buttonResult = renderButton(isLocal, url, isLinkExternal, openInNewTab)

  return isYoutubeLink && buttonUrl ? (
    <ModalComponent
      clickTarget={buttonResult}
      dataComponent="YoutubeModal"
      modalContent={<YoutubeVideo link={buttonUrl} title={buttonUrl} />}
    />
  ) : (
    buttonResult
  )
}

export const query = graphql`
  #graphql
  fragment contentfulButtonFragment on ContentfulButton {
    contentful_id
    internal {
      type
    }
    addToCartButton
    buttonText: text
    buttonSize
    fontColor: textColor
    openInNewTab
    type
    url
    icon {
      title
      description
      gatsbyImageData(layout: FIXED, width: 20, placeholder: BLURRED)
      file {
        url
      }
    }
    disabledText
    isLinkExternal
  }
`
