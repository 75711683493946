import { useScrollPosition } from '@lib/react-hooks'
import { useEffect, useState } from 'react'

const useScrollCondition = (isBottomBannerVariation: boolean) => {
  const [hasScrollCondition, setHasScrollCondition] = useState(false)
  const { scrollPercentage } = useScrollPosition()

  useEffect(() => {
    const hasScrolledEnough = isBottomBannerVariation && scrollPercentage >= 20
    const hasNotScrolledEnough =
      isBottomBannerVariation && scrollPercentage < 20
    hasScrolledEnough && setHasScrollCondition(true)
    hasNotScrolledEnough && setHasScrollCondition(false)
  }, [isBottomBannerVariation, scrollPercentage])

  return hasScrollCondition
}

export default useScrollCondition
