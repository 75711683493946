import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath, safeProp } from '@simplisafe/monda'
import { PartnerTemplate } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import path from 'ramda/src/path'
import React from 'react'

import {
  ContentfulIncludedItem,
  ContentfulIncludedItems,
  ContentfulPartnerTemplate,
  ContentfulRichTextWithOptions
} from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import usePriceVariation from '../../hooks/usePriceVariation'
import documentFallback from '../../util/documentFallback'
import { getTokenizedJson } from '../../util/monitoring-price-placeholders'
import { renderComponentFromData } from '../../util/render'
import RichText from '../RichText'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
export type PartnerTemplateComponentProps = {
  readonly data: ContentfulPartnerTemplate
}

type Items = ContentfulComponent & {
  readonly includedItems: readonly unknown[]
}

export default function PartnerTemplateComponent({
  data
}: PartnerTemplateComponentProps) {
  const contentComponents = prop('contentComponents', data)

  const detailComponents = prop('detailComponents', data)
  const productSku = prop('productSku', data) || ''
  const product = usePriceVariation(productSku)

  const setModalPrice = (item: ContentfulIncludedItem | null) => {
    const itemModal =
      (item &&
        path<readonly ContentfulRichTextWithOptions[]>(
          ['infoModal', 'contentsToGroup'],
          item
        )) ||
      []
    const contents =
      itemModal &&
      itemModal.map((content: ContentfulRichTextWithOptions) => {
        const filteredText = safePath(['richText', 'raw'], content)
          // TODO: Gatsby 4 rich text - we might need to fix up this tokenization & price placeholder replacement
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          .map((text: string) =>
            getTokenizedJson(
              text,
              product.cata(
                () => 0,
                val => prop('price', val)
              )
            )
          )
          // TODO: Gatsby 4 rich text - remove documentFallback usage
          .getOrElse(documentFallback())

        return {
          ...content,
          // TODO: Gatsby 4 rich text - verify that this renders as expected
          richText: { raw: filteredText }
        }
      })

    return {
      ...item,
      infoModal: {
        ...prop('infoModal', item),
        contentsToGroup: contents
      }
    }
  }

  const detailsComponent =
    detailComponents &&
    detailComponents.map(
      (contentfulComponent): Items => ({
        ...contentfulComponent,
        includedItems: Maybe.fromNull(contentfulComponent)
          .filter(
            contentfulComponent =>
              path(['internal', 'type'], contentfulComponent) ===
              'ContentfulIncludedItems'
          )
          .chain((data: ContentfulIncludedItems) =>
            safeProp('includedItems', data)
          )
          .map(data => data.map(setModalPrice))
          .getOrElse([])
      })
    )

  return (
    <PartnerTemplate
      // @ts-expect-error TS(2322) FIXME: Type 'unknown[]' is not assignable to type 'ReactN... Remove this comment to see the full error message
      content={
        contentComponents &&
        contentComponents.map(renderComponentFromData).filter(isNotNil)
      }
      details={
        detailsComponent &&
        detailsComponent.map(renderComponentFromData).filter(isNotNil)
      }
      disclaimer={<RichText raw={data?.disclaimer?.raw} textSize="xs" />}
      key={prop('id', data) || ''}
    />
  )
}

export const PartnerTemplateQuery = graphql`
  #graphql
  fragment partnerTemplate on ContentfulPartnerTemplate {
    disclaimer {
      raw
    }
    contentComponents {
      ... on ContentfulDivider {
        ...divider
      }
      ... on ContentfulPhoneCta {
        ...phoneCTA
      }

      ... on ContentfulPartnerForm {
        ...partnerForm
      }

      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    detailComponents {
      ... on ContentfulImageGallery {
        ...imageGallery
      }
      ... on ContentfulHeading {
        ...headingFragment
      }
      ... on ContentfulIncludedItems {
        ...includedItems
      }
    }
    id
    internal {
      type
    }
    productSku
  }
`
