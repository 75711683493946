import prop from '@simplisafe/ewok/ramda/prop'
import {
  NavigationProductCategories,
  setNavigationProductCategory
} from '@simplisafe/ss-ecomm-data/deprecated/navigation'
import { TabNavigation } from '@simplisafe/ss-react-components'
import { TabNavigationProps } from '@simplisafe/ss-react-components/TabNavigation'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { ContentfulCategoryList } from '../../../graphql'

type TabNavigationComponentProps = {
  readonly data: Partial<ContentfulCategoryList>
}

const toTabNavigationProps = applySpec<TabNavigationProps>({
  tabs: prop('categoryFilter')
})

function TabNavigationComponent({ data }: TabNavigationComponentProps) {
  const dispatch = useDispatch()
  const onClick = useCallback(
    (selectedFilter: string) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      const action = setNavigationProductCategory(
        selectedFilter as NavigationProductCategories
      )
      dispatch(action)
    },
    [dispatch]
  )
  return <TabNavigation {...toTabNavigationProps(data)} onClick={onClick} />
}

//We have to integrate the filter selection to redux
export const query = graphql`
  #graphql
  fragment categoryListFragment on ContentfulCategoryList {
    id
    internal {
      type
    }
    categoryFilter
  }
`

export default TabNavigationComponent
