import { toColumnSpans } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { Column, Row } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { RowProps } from '@simplisafe/ss-react-components/Row'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import props from 'ramda/src/props'
import React, { CSSProperties, useState } from 'react'

import { ContentfulProductInformation } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { PriceProvider } from '../../providers/PriceProvider'
import { ImageProps } from '../Media'
import ProductPageHeroContentColumn, {
  renderAdditionalLink
} from './ContentColumn'
import type {
  AdditionalLinkData,
  ContentPosition,
  ImagePosition,
  ProductPageHeroProps,
  ProductVariationData
} from './types'

const contentPositionMapper: {
  [key: string]: [ImagePosition, ContentPosition]
} = {
  'Background - Content Left': ['background', 'left'],
  'Background - Content Right': ['background', 'right'],
  Left: ['left', 'right'],
  Right: ['right', 'left']
}
export const getContentPosition = (value?: string | null) =>
  (value && contentPositionMapper[value]) || ['background', 'left']

export default function ProductPageHero({
  data,
  location
}: ProductPageHeroProps) {
  const isTabletUp = useMediaQuery('TabletAndUp')
  const textColor: string =
    (isTabletUp ? data?.color : data?.colorMobile) || 'neutralBlack'
  const [selectedVariationIndex, selectVariationIndex] = useState(0)

  const padding = prop('padding', data)
  const height = prop('height', data)

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Omit<ContentfulProductPageHeroV2... Remove this comment to see the full error message
  const [leftSpans, rightSpans] = toColumnSpans(
    '12',
    ...props(['tabletColumnRatio', 'desktopColumnRatio'], data)
  )
  const [imagePosition, contentPosition] = getContentPosition(
    data.imagePosition
  )
  const isLeftContent = contentPosition === 'left'
  const isBackgroundImage = imagePosition === 'background' && isTabletUp

  const variationImages = Maybe.fromNull(data?.productVariationList?.imageList)
  const productImage = prop('productImage', data)
  const backgroundImagePosition = data?.backgroundImagePosition
  const bgImgStyles = backgroundImagePosition
    ? ({ backgroundPosition: backgroundImagePosition } as CSSProperties)
    : undefined
  const imageData = variationImages
    .chain(imgs => Maybe.fromNull(imgs[selectedVariationIndex]))
    .orElse(Maybe.fromNull(productImage))
    .orUndefined()
  const ImageComponent = imageData && getMappedComponent<ImageProps>(imageData)
  const imageProps = ImageComponent
    ? {
        bgImgStyles,
        data: imageData
      }
    : undefined

  const additionalLinks = safeProp('additionalLinks', data)
    .map(links =>
      links
        .filter((l): l is AdditionalLinkData => !!l)
        .map(renderAdditionalLink)
    )
    .orJust([])

  const productInformationList =
    data?.productVariationList?.productInformationList || []
  // Filters out all possible nulls/undefines from the Contentful's array of Maybes.
  const productInformationListItems = productInformationList.flatMap(f =>
    f ? [f] : []
  )
  const variationData = productInformationListItems.map(
    transformObject<ContentfulProductInformation, ProductVariationData>({
      description: variation =>
        variation?.description?.raw
          ? { raw: variation.description.raw }
          : undefined,
      key: variation => variation?.productInformationKey,
      productId: variation => variation?.productId
    })
  )

  const productData = transformObject(
    {
      description: product => product.productDescription?.raw,
      linksDisclaimer: product => product?.linksDisclaimer?.raw,
      linksDisclaimerTextColor: product =>
        product?.linksDisclaimerTextColor?.color,
      maxQuantity: product => product?.maxQuantity,
      priceDisclaimerClassName: product => product?.priceDisclaimerClassName,
      priceDisclaimerText: product => product?.priceDisclaimerText,
      priceDisclaimerTextColor: product =>
        product?.priceDisclaimerTextColor?.color,
      priceMessage: product => product?.priceMessage,
      priceMessageClassName: product => product?.priceMessageClassName,
      priceMessageTextColor: product => product?.priceMessageTextColor?.color,
      productId: product => product?.product?.sku,
      quantityChangerLabel: product =>
        product?.quantityChangerLabel?.text?.text,
      showQuantityChanger: product => product?.showQuantityChanger,
      hidePriceAndBuyBox: product => product?.hidePriceAndBuyBox
    },
    data
  )

  const productAddonData = data.productAddon
    ? transformObject(
        {
          // TODO: fix type
          // @ts-expect-error TS(2322) FIXME: Type '<O extends { description: V; }, T extends ke... Remove this comment to see the full error message
          description: prop('description'),
          // TODO: fix type
          // @ts-expect-error TS(2322) FIXME: Type '<O extends { maxQuantity: V; }, T extends ke... Remove this comment to see the full error message
          maxQuantity: prop('maxQuantity'),
          // TODO: fix type
          // @ts-expect-error TS(2322) FIXME: Type '<O extends { modal: V; }, T extends keyof O,... Remove this comment to see the full error message
          modal: prop('modal'),
          productId: addon => path(['product', 'sku'], addon),
          quantityChangerLabel: addon =>
            path(['quantityChangerLabel', 'text', 'text'], addon)
        },
        data.productAddon
      )
    : null

  const productId = safeProp('productId', productData)
  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<never>' is not assignable ... Remove this comment to see the full error message
  const productAddonId = Maybe.fromNull(productAddonData).chain(
    safeProp('productId')
  )
  const variationProductIds = variationData
    .map(variation => variation?.productId)
    .filter(isNotNil)
  const allProductIds = new Set<string>()
  productId.forEach(id => allProductIds.add(id))
  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  productAddonId.forEach(id => allProductIds.add(id))
  variationProductIds.forEach(id => allProductIds.add(id))

  // Allow for a mobile version of the MiniCart Btn if it exists.
  const miniCartBtnMobile = prop('addToMiniCartButtonMobile', data)
  const miniCartBtnDesktop = prop('addToMiniCartButton', data)
  const miniCartBtn =
    miniCartBtnMobile && !isTabletUp ? miniCartBtnMobile : miniCartBtnDesktop

  const contentColumn = (
    <ProductPageHeroContentColumn
      addToCartButtonData={prop('addToCartButton', data)}
      addToMiniCartButtonData={miniCartBtn}
      additionalLinks={additionalLinks}
      colorVariationData={variationData}
      columnSpans={isLeftContent ? leftSpans : rightSpans}
      id={prop('id', data)}
      location={location}
      onSelectVariationIndex={selectVariationIndex}
      outOfStockButtonText={prop('outOfStockButtonText', data)}
      productAddonData={productAddonData}
      productData={productData}
      selectedVariationIndex={selectedVariationIndex}
      textColor={textColor}
    />
  )

  const imageColumn = (
    <Column firstRow={true} spans={isLeftContent ? rightSpans : leftSpans}>
      {/* @ts-expect-error TS(2604): JSX element type 'ImageComponent' does not have an... Remove this comment to see the full error message */}
      {!isBackgroundImage && ImageComponent && (
        <ImageComponent {...imageProps} />
      )}
    </Column>
  )

  return (
    <PriceProvider skus={Array.from(allProductIds)}>
      <Row
        // @ts-expect-error
        BackgroundComponent={isBackgroundImage ? ImageComponent : undefined}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        backgroundColor={path(['productHeroBackgroundColor', 'color'], data)}
        backgroundComponentProps={isBackgroundImage ? imageProps : undefined}
        dataComponent={ProductPageHero.name}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
        height={defaultTo('standard', height) as RowProps['height']}
        padding={ifElse(
          equals(true),
          always('large'),
          always('none')
        )(isTabletUp && !padding)}
        textColor={textColor}
      >
        {isLeftContent ? contentColumn : imageColumn}
        {isLeftContent ? imageColumn : contentColumn}
      </Row>
    </PriceProvider>
  )
}

export const query = graphql`
  #graphql
  fragment productPageHero on ContentfulProductPageHeroV2 {
    productHeroBackgroundColor: backgroundColor {
      color
    }
    additionalLinks {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulLink {
        id
        internal {
          type
        }
        linkText
        linkItem {
          ... on ContentfulPage {
            pageUrl
          }
        }
      }
    }
    linksDisclaimer {
      raw
    }
    linksDisclaimerTextColor {
      color
    }
    addToCartButton {
      ...contentfulButtonFragment
    }
    addToMiniCartButton {
      ...contentfulButtonFragment
    }
    addToMiniCartButtonMobile {
      ...contentfulButtonFragment
    }
    backgroundImagePosition
    desktopColumnRatio
    padding
    height
    id
    priceDisclaimerText
    priceDisclaimerTextColor {
      color
    }
    priceDisclaimerClassName
    priceMessage
    priceMessageTextColor {
      color
    }
    priceMessageClassName
    productImage: image {
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulImageWithArtDirection {
        ...imageWithArtDirection
      }
    }
    productAddon {
      description
      product {
        sku
      }
      maxQuantity
      modal {
        ...modalFragment
      }
      quantityChangerLabel {
        text {
          text
        }
      }
    }
    productVariationList {
      imageList {
        ... on ContentfulImage {
          ...contentfulImage
        }
      }
      productInformationList {
        description {
          raw
          references {
            ... on ContentfulAsset {
              ...contentfulGenericAssetFragment
            }
            ... on ContentfulLink {
              ...contentfulLinkFragment
            }
            ... on ContentfulPlaceholder {
              ...placeholderFragment
            }
          }
        }
        productId
        productInformationKey
      }
    }
    imagePosition
    internal {
      type
    }
    maxQuantity
    outOfStockButtonText
    productDescription {
      raw
    }
    product {
      sku
    }
    quantityChangerLabel {
      text {
        text
      }
    }
    tabletColumnRatio
    showQuantityChanger
    hidePriceAndBuyBox
    color: textColor
    colorMobile: textColorMobile
  }
`
