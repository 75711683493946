import type { AffirmClient } from '@lib/components'
import { useFeature } from '@lib/optimizely'
import React from 'react'

import { ContentfulProduct } from '../../../graphql'
import PackageDetailPage from '../../PackageDetailPage'
import OldVersion from './OldVersion'

export type ItemContainerComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ContentfulProduct
}

// To see the new version set feature_flag to refactor_itemcontainercomponent in local storage.
function ItemContainerComponentWrapper(props: ItemContainerComponentProps) {
  const [newVersion] = useFeature('refactor_itemcontainercomponent')

  /**
   * Warning!
   * This component is being replaced by PackageDetailPage.
   * If you need to make changes, you should probably make those changes to PackageDetailPage.
   */

  return newVersion ? <PackageDetailPage /> : <OldVersion {...props} />
}

export default ItemContainerComponentWrapper
