import { Options } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import {
  toColumnSpans,
  toGapValue,
  toMarginValue,
  toPaddingValue,
  toSiteColor
} from '@lib/components'
import { useLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { selectPromoDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, Row } from '@simplisafe/ss-react-components'
import { FloatingBadge } from '@simplisafe/ss-react-components'
import { ColumnProps, Spans } from '@simplisafe/ss-react-components/Column'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { RowProps } from '@simplisafe/ss-react-components/Row'
import { Maybe } from 'monet'
import { applySpec } from 'ramda'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import join from 'ramda/src/join'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import propOr from 'ramda/src/propOr'
import split from 'ramda/src/split'
import T from 'ramda/src/T'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DeepPartial } from 'redux'

import { ContentfulTwoColumn } from '../../../graphql'
import {
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import { toFirstCharLower } from '../../util/helper'
import { renderComponentFromData } from '../../util/render'
import { getBadgeDiscount } from '../BadgeText'
import ContentfulRichText from '../ContentfulRichText'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'

export type TwoColumnBannerProps = {
  readonly data: DeepPartial<ContentfulTwoColumn>
}

const alignItemsValueMapper: { [key: string]: RowProps['alignItems'] } = {
  Center: 'center',
  Stretch: 'stretch'
}

export const toAlignItemsValue = (value: string) =>
  prop(value, alignItemsValueMapper)

const hasSingleMediaItem = (columnData: readonly ContentfulComponent[]) => {
  const mediaTypes = [
    'ContentfulImage',
    'ContentfulImageWithFocalPoint',
    'ContentfulImageWithArtDirection'
  ]
  const isSingleItem = prop('length', columnData) === 1
  const internalType: string = path([0, 'internal', 'type'], columnData) || ''
  const onlyHasAccompanyingCaption =
    prop('length', columnData) === 2 &&
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    safePath(['1', 'internal', 'type'], columnData).getOrElse('') ===
      'ContentfulSmallTextSection' &&
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    safePath(['1', 'uiTheme'], columnData).getOrElse('') === 'Text : Disclaimer'
  return (
    (isSingleItem || onlyHasAccompanyingCaption) &&
    mediaTypes.includes(internalType)
  )
}

const alignSelfFn = (
  columnData?: ReadonlyArray<ContentfulComponent | null> | null
): ColumnProps['alignSelf'] => {
  const imageTypes = [
    'ContentfulImage',
    'ContentfulImageWithFocalPoint',
    'ContentfulBadgeText'
  ]
  return (
    Maybe.fromNull(columnData)
      // returns None if columnData has the wrong length and the following chains/filters won't apply
      .filter(columnData => columnData.length === 1)
      // get the first item from columnData and turn it into a Maybe - this also takes care of the
      // possibility of an individual item being null, which the Contentful types seem to think can
      // happen. if the result is None, the following chains/filters won't apply
      .chain(columnData => Maybe.fromNull(columnData[0]))
      // map to the internal type of the first column. if None, the following filter won't apply
      .chain(firstColData => safePath(['internal', 'type'], firstColData))
      // check if the content type of the first column is contained in the image type array. returns None if it doesn't

      .filter(contentType => imageTypes.includes(contentType))
      // if None, return undefined, otherwise return 'center'
      .cata(always(undefined), always('center'))
  )
}

const renderRightSectionColoumn = (
  rightContent: readonly ContentfulComponent[],
  rightColOnTop: boolean,
  rightColumnHeight: ColumnProps['height'],
  id: string,
  columnPadding: string,
  columnCornerRounded: string,
  spans: readonly [Spans, Spans]
) =>
  rightContent.length ? (
    <Column
      alignSelf={alignSelfFn(rightContent)}
      firstRow={rightColOnTop}
      height={rightColumnHeight}
      key={`${id}-right`}
      padding={toPaddingValue(columnPadding)}
      rounded={columnCornerRounded}
      spans={spans[1]}
    >
      {rightContent && rightContent.map(data => renderComponentFromData(data))}
    </Column>
  ) : (
    <></>
  )

// key={propOr<string, string>('', 'id')}

const toFloatingBadge = (
  ContentfulTwoColumn: DeepPartial<ContentfulTwoColumn>,
  placeholders: Record<string, string>
) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'import("/home/joshderocher-vlk/D... Remove this comment to see the full error message
  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => renderEmbeddedEntry(node, placeholders)
    }
  }
  const raw = safePath(
    ['promoBadge', 'text', 'raw'],
    ContentfulTwoColumn
  ).getOrElse('')
  const references = safePath(
    ['promoBadge', 'text', 'references'],
    ContentfulTwoColumn
  ).getOrElse([])

  return !isEmpty(placeholders)
    ? applySpec({
        backgroundColor: () => toSiteColor('brandPrimary'),
        borderColor: () => toSiteColor('brandPrimary'),
        floatingBadgeContent: () =>
          raw && (
            <ContentfulRichText
              optionsCustom={options}
              raw={raw}
              references={references}
            />
          ),
        linkUrl: prop('promoBadgeUrl'),
        textColor: () => toSiteColor('neutralWhite')
        // @ts-expect-error TS(2345) FIXME: Argument of type '[DeepPartial<ContentfulTwoColumn... Remove this comment to see the full error message
      })(ContentfulTwoColumn)
    : null
}

// TODO Row doesn't support all margin/padding options yet
export default function TwoColumnBanner({ data }: TwoColumnBannerProps) {
  const {
    alignItems,
    // TODO Later needd to implment for all marigin options for both Row/Column.
    margin,
    desktopColumnRatio,
    gapSize,
    id = '',
    rowPadding,
    tabletColumnRatio
  } = data

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const height = propOr<RowProps['height'], RowProps['height']>(
    'standard',
    'height',
    data
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const columnPadding = propOr<string, string>('', 'columnPadding', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const columnCornerRounded = propOr<string, string>(
    'base',
    'columnCornerRounded',
    data
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const rightContent = propOr<
    readonly ContentfulComponent[],
    readonly ContentfulComponent[]
  >([], 'rightContent', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const leftContent = propOr<
    readonly ContentfulComponent[],
    readonly ContentfulComponent[]
  >([], 'leftContent', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const mobilleColumnRatio = propOr<string, string>(
    '',
    'mobilleColumnRatio',
    data
  )
  const location = useLocation()
  const isMobile = !useMediaQuery('TabletAndUp')
  const currentUrlName = prop('pathname', location)
  const isPartnerFormWithMobile = ifElse(
    equals(true),
    T,
    F
  )(currentUrlName === '/partner-with-us' && isMobile)
  const backgroundColor = safeProp('backgroundColor', data).cata(
    always(undefined),
    // @ts-expect-error TS(2345) FIXME: Argument of type '(list: readonly unknown[]) => st... Remove this comment to see the full error message
    pipe(toFirstCharLower, split(' '), join(''))
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
  const spans = toColumnSpans(
    mobilleColumnRatio,
    tabletColumnRatio,
    desktopColumnRatio
  )
  // A single image or video (with an optional accompanying caption) in either column should stack on top on mobile when content is in rows.
  // Currently this just supports ContentfulImageWithFocalPoint.
  // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
  const rightColOnTop =
    parseInt(mobilleColumnRatio, 10) === 12 &&
    // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
    hasSingleMediaItem(rightContent) &&
    // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
    !hasSingleMediaItem(leftContent)
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const rightColumnHeight = pathOr<
    ColumnProps['height'],
    ColumnProps['height']
  >('any', ['rightContent', 0, 'height'], data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const backgroundImageData = propOr<ContentfulComponent, ContentfulComponent>(
    {},
    'backgroundImageRef',
    data
  )
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const BackgroundImageComponent: React.ComponentType =
    getMappedComponent(backgroundImageData)
  const backgroundImageProps = BackgroundImageComponent
    ? { data: backgroundImageData }
    : {}

  const [placeholders, setPlaceholders] = useState({})
  const displayDiscountBadge = prop('displayDiscountBadge', data)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountText = useSelector(selectPromoDiscountText)

  const handleFailure = () => {
    setPlaceholders({})
  }

  useEffect(() => {
    discountText.cata(handleFailure, text =>
      setPlaceholders(getBadgeDiscount(text))
    )
    // @ts-expect-error TS(2339) FIXME: Property 'val' does not exist on type 'Maybe<strin... Remove this comment to see the full error message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountText.val])

  return (
    <>
      <Row
        BackgroundComponent={BackgroundImageComponent}
        alignItems={toAlignItemsValue(alignItems || '')}
        backgroundColor={backgroundColor}
        backgroundComponentProps={backgroundImageProps}
        gap={toGapValue(gapSize)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        height={height}
        key={id}
        // Replace padding type inside Row's component
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        padding={toPaddingValue(rowPadding) as string}
      >
        {displayDiscountBadge && !isPromoTopBanner && (
          // @ts-expect-error TS(2322) FIXME: Type '{ backgroundColor: any; borderColor: any; fl... Remove this comment to see the full error message
          <FloatingBadge
            className="bannerBadge"
            {...toFloatingBadge(data, placeholders)}
          />
        )}
        <Column
          // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
          alignSelf={alignSelfFn(leftContent)}
          key={`${id}-left`}
          marginLeftRight={toMarginValue(margin)}
          // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          padding={toPaddingValue(columnPadding) as string}
          rounded={columnCornerRounded}
          spans={spans[0]}
        >
          {/* @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type '<V>(p: stri... Remove this comment to see the full error message */}
          {leftContent &&
            leftContent.map(data => renderComponentFromData(data))}
        </Column>
        {/* @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message */}
        {!isPartnerFormWithMobile &&
          renderRightSectionColoumn(
            rightContent,
            rightColOnTop,
            rightColumnHeight,
            id,
            columnPadding,
            columnCornerRounded,
            spans
          )}
      </Row>
      {/* @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message */}
      {isPartnerFormWithMobile &&
        renderRightSectionColoumn(
          rightContent,
          rightColOnTop,
          rightColumnHeight,
          id,
          columnPadding,
          columnCornerRounded,
          spans
        )}
    </>
  )
}
