import { selectMonthsOfServiceDisplay } from '@simplisafe/ss-ecomm-data/redux/select'
import { Maybe } from 'monet'
import isEmpty from 'ramda/src/isEmpty'
import pathOr from 'ramda/src/pathOr'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  ContentfulLinkAddToCart,
  ContentfulProductPlan,
  ContentfulRichTextWithOptions
} from '../../../graphql'
import { usePriceContext } from '../../providers/PriceProvider'
import { replaceTokensWithPrice } from '../../util/monitoring-price-placeholders'
import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'
import ContentfulRichText from '../ContentfulRichText'
import { getAddPlanButton, getMonitoringConfirmModal } from '.'

export type PlanButtonProps = {
  readonly data: Partial<ContentfulProductPlan | ContentfulRichTextWithOptions>
  readonly titlePlan: string
}

export default function PlanButton({ data, titlePlan }: PlanButtonProps) {
  const { Track, trackEvent } = useTracking()
  const { getPrice } = usePriceContext()

  const monthsOfFreeService: string = useSelector(
    selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer())
  )

  const productSku: string = pathOr('', ['productId'], data)
  const rawDescription: string = pathOr('', ['description', 'raw'], data)
  const description = Maybe.of(productSku)
    .chain(id => getPrice(id))
    .cata(
      () => null,
      price => replaceTokensWithPrice(rawDescription, price)
    )

  // @ts-expect-error TS(2339) FIXME: Property 'button' does not exist on type 'Partial<... Remove this comment to see the full error message
  const button: Partial<ContentfulLinkAddToCart> = data?.button || {}
  const renderButtonType: string = pathOr(
    '',
    ['button', 'internal', 'type'],
    data
  )

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element[]; }' has no properties ... Remove this comment to see the full error message
    <Track>
      <ContentfulRichText raw={description} />
      {/* @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulLinkAddToCart>... Remove this comment to see the full error message */}
      {renderButtonType !== 'ContentfulModal'
        ? !isEmpty(button)
          ? getAddPlanButton(button)
          : null
        : getMonitoringConfirmModal(
            button,
            trackEvent,
            titlePlan,
            monthsOfFreeService
          )}
    </Track>
  )
}
