import type { AffirmClient } from '@lib/components'
import { visitorIdAtAt } from '@lib/tracking'
import { TrackingData } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import propOr from '@simplisafe/ewok/ramda/propOr'
import { safeProp } from '@simplisafe/monda'
import {
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  ZuoraClient,
  ZuoraPaymentResponse
} from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import { Column, Row, SSRadio, Text } from '@simplisafe/ss-react-components'
import { FormSection } from '@simplisafe/ss-react-components'
import { window } from 'browser-monads-ts'
import { navigate } from 'gatsby'
import { Maybe } from 'monet'
import concat from 'ramda/src/concat'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { ContentfulAssetFile, ContentfulPaymentForm } from '../../../graphql'
import { leadingSlashIt } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import AffirmCheckoutContent from './form-sections/AffirmCheckoutContent'
import InlineChasePaymentForm from './form-sections/InlineChasePaymentForm'
import InlineZuoraPaymentForm from './form-sections/InlineZuoraPaymentForm'
import PaymentFormBanner from './PaymentFormBanner'
import TermsOfSale from './TermsOfSale'
import usePayment from './usePayment'
import useTrackPurchaseComplete from './useTrackPurchaseComplete'
import { getBillingAddress } from './utils/zuora'

const cookies = new Cookies()

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type ContentfulPaymentFormProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ContentfulPaymentForm
  readonly zuoraClient?: ZuoraClient
}

/** Value for the Affirm checkout radio button. This value is sent to tracking endpoints. */
const PAYMENT_OPTION_AFFIRM = 'affirm'
/** Value for the credit card checkout radio button. This value is sent to tracking endpoints. */
const PAYMENT_OPTION_CARD = 'card'
/** Maps the value for selected payment option from a string to an accepted TrackingData value */
const paymentOptionMapper: Record<
  string,
  TrackingData['selectedPaymentOption']
> = {
  [PAYMENT_OPTION_AFFIRM]: PAYMENT_OPTION_AFFIRM,
  [PAYMENT_OPTION_CARD]: PAYMENT_OPTION_CARD
}

function ContentfulPaymentFormComponent({
  // allows passing in a different value for affirmClient in unit tests
  // @ts-expect-error TS(2339) FIXME: Property 'affirm' does not exist on type 'Window'.
  affirmClient = window.affirm,
  data,
  // @ts-expect-error TS(2339) FIXME: Property 'Z' does not exist on type 'Window'.
  zuoraClient = window.Z
}: ContentfulPaymentFormProps) {
  const paymentFormTitle = propOr('', 'title', data)
  const paymentErrorContent = path(['paymentErrorMessage', 'description'], data)
  const paymentErrorMessage = (
    <ContentfulRichText raw={paymentErrorContent?.raw} />
  )

  const cart = useSelector(selectCart)
  const locale = useSelector(selectLocale)
  const { Track, trackEvent } = useTracking({ appSection: 'paymentForm' })
  const [submitted, setSubmitted] = useState(false)

  const {
    chaseErrorCodes,
    dataCollectorSrc,
    handleSubmitAffirmOrder: submitAffirmOrder,
    iframeSrc,
    paymentState,
    handleSubmitZuoraOrder: submitZuoraOrder,
    handleZuoraFormRender,
    zuoraPaymentMethod,
    creditPaymentExperience,
    safeTechSdkUrl
  } = usePayment(cart, cookies, trackEvent)

  const handleSubmitAffirmOrder = () => submitAffirmOrder(setSubmitted)

  const handleSubmitZuoraOrder = (zuoraResponse: ZuoraPaymentResponse) => {
    setSubmitted(true)
    return submitZuoraOrder(zuoraResponse)
  }

  const [selectedPaymentOption, selectPaymentOption] =
    useState<TrackingData['selectedPaymentOption']>(PAYMENT_OPTION_CARD)

  // Tracks all purchase events after order payment is complete
  useTrackPurchaseComplete(paymentState, selectedPaymentOption, trackEvent)

  const onPaymentOptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const paymentOption = paymentOptionMapper[e.target.value]

      selectPaymentOption(paymentOption)
      trackEvent({
        event: 'paymentOptionToggle',
        selectedPaymentOption: paymentOption
      })
    },
    [trackEvent]
  )

  const buttonText = prop('navigationLinkText', data) || ''

  const backButtonUrl = prop('backButtonUrl', data) || ''
  const onBackButtonClick = useCallback(() => {
    backButtonUrl && navigate(leadingSlashIt(backButtonUrl))
  }, [backButtonUrl])

  /** ----- Payment form banner messaging ----- */

  //TODO: Gatsby 4 rich text
  const paymentFormRetrievalErrorMessage = safeProp(
    'paymentFormRetrievalErrorMessage',
    data
  )
    .map(data => <RichTextWithOptionsComponent data={data} key={data.id} />)
    .orNull()

  const paymentCompleteContent = prop('orderCompleteInterstitial', data)
  const paymentCompleteMessage = (
    <ContentfulRichText
      key="payment-complete-message"
      raw={paymentCompleteContent?.raw}
    />
  )

  const paymentProcessingContent = prop('paymentProcessingMessage', data)
  const paymentProcessingMessage = (
    <ContentfulRichText
      key="payment-processing-message"
      raw={paymentProcessingContent?.raw}
    />
  )

  const cardVerificationNoteContent = prop('cardVerificationNote', data)

  /** ----- Credit card checkout content ----- */

  const creditCardRadioButtonLabel = (
    <Text fontWeight="medium">
      {safeProp('creditCardCheckoutOptionTitle', data).orJust('')}
    </Text>
  )

  const chaseCheckoutContent = (): JSX.Element | boolean => {
    const sessionVid = visitorIdAtAt() || ''

    const fullKaptchaSrc = concat(
      dataCollectorSrc,
      sessionVid.replace(/[^A-Za-z0-9]+/gi, '').substr(0, 32)
    )

    return (
      paymentState !== 'loading' && (
        <InlineChasePaymentForm
          cardVerificationNote={cardVerificationNoteContent}
          chaseErrorCodes={chaseErrorCodes}
          cvvModalContent={safeProp('cvvModal', data).orUndefined()}
          fullKaptchaSrc={fullKaptchaSrc}
          iframeSrc={iframeSrc}
          // @ts-expect-error TS(2559) FIXME: Type 'Element' has no properties in common with ty... Remove this comment to see the full error message
          paymentErrorMessage={paymentErrorMessage}
          paymentState={paymentState}
        />
      )
    )
  }

  const zuoraCheckoutContent = (): JSX.Element => {
    return (
      <InlineZuoraPaymentForm
        billingAddress={getBillingAddress(cart)}
        cardVerificationNote={cardVerificationNoteContent}
        locale={locale}
        onOrderSubmit={handleSubmitZuoraOrder}
        onPaymentFormRender={handleZuoraFormRender}
        paymentMethod={Maybe.fromNull(zuoraPaymentMethod)}
        paymentState={paymentState}
        safeTechCollectorSdkUrl={Maybe.fromNull(safeTechSdkUrl).orJust('')}
        zuoraClient={Maybe.fromNull(zuoraClient)}
      />
    )
  }

  const creditCardCheckoutContent =
    creditPaymentExperience === 'zuora'
      ? zuoraCheckoutContent()
      : chaseCheckoutContent()

  /** ----- Affirm checkout content ----- */

  const isAffirmEnabled = !!(affirmClient && prop('affirmEnabled', data))

  const isCartAffirmEligible = cart
    .map(
      _cart =>
        _cart.totalPrice >= safeProp('affirmMinimumCartTotal', data).orJust(0)
    )
    .orJust(false)

  const affirmUnavailableContent = prop('affirmUnavailableMessage', data)
  const affirmUnavailableMessage = (
    <Row key="affirm-unavailable" padding="small">
      <Column>
        <ContentfulRichText raw={affirmUnavailableContent?.raw} />
      </Column>
    </Row>
  )
  const affirmCheckoutContent = (
    <AffirmCheckoutContent
      affirmClient={affirmClient}
      checkoutButton={safeProp('affirmCheckoutButton', data)}
      // @ts-expect-error TS(2322) FIXME: Type 'ContentfulPaymentFormAffirmCheckoutInstructi... Remove this comment to see the full error message
      instructions={prop('affirmCheckoutInstructions', data)}
      // @ts-expect-error TS(2322) FIXME: Type 'ContentfulPaymentFormAffirmCheckoutNote' is ... Remove this comment to see the full error message
      note={prop('affirmCheckoutNote', data)}
      onOrderSubmit={handleSubmitAffirmOrder}
    />
  )

  const affirmCheckoutOptionIcon = safeProp('affirmCheckoutOptionIcon', data)
  const affirmRadioButtonLabel = (
    <Text fontWeight="medium">
      {safeProp('affirmCheckoutOptionTitle', data).orJust('')}{' '}
      {affirmCheckoutOptionIcon
        .chain(icon => Maybe.fromNull(prop('file', icon)))
        .map((file: ContentfulAssetFile) => (
          <img
            alt={affirmCheckoutOptionIcon
              .chain(safeProp('description'))
              .orJust('')}
            key="affirm-checkout-option-icon"
            loading="eager"
            src={propOr('', 'url', file)}
          />
        ))}
    </Text>
  )

  const orderNoteContent = prop('orderNote', data)
  const orderNote = <ContentfulRichText raw={orderNoteContent?.raw} />

  /** ----- Render the payment section ----- */

  const shouldShowCheckoutOptions =
    paymentState !== 'processing' && paymentState !== 'complete'

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <FormSection name={paymentFormTitle}>
        <div className="payment-iframe-container" data-component="PaymentForm">
          <PaymentFormBanner
            paymentCompleteMessage={paymentCompleteMessage}
            paymentFormRetrievalErrorMessage={paymentFormRetrievalErrorMessage}
            paymentProcessingMessage={paymentProcessingMessage}
            paymentState={paymentState}
          />

          {/** This inline style isn't ideal, but this is to hide the checkout options if a payment is processing or
           * complete. If someone cancels checking out with Affirm, we want to render the checkout options again
           * without causing the Chase iframe to unmount/remount, so this shows/hides the options with CSS instead
           * not rendering them at all. */}
          <div
            style={{ display: shouldShowCheckoutOptions ? 'block' : 'none' }}
          >
            {isAffirmEnabled ? (
              <SSRadio
                dataComponent="payment-options"
                id="payment-options"
                name="payment-options"
                onChange={onPaymentOptionChange}
                options={[
                  {
                    content: affirmCheckoutContent,
                    disabled: !isCartAffirmEligible,
                    disabledContent: affirmUnavailableMessage,
                    text: affirmRadioButtonLabel,
                    value: PAYMENT_OPTION_AFFIRM
                  },
                  {
                    content: creditCardCheckoutContent,
                    defaultChecked: true,
                    text: creditCardRadioButtonLabel,
                    value: PAYMENT_OPTION_CARD
                  }
                ]}
              ></SSRadio>
            ) : (
              creditCardCheckoutContent
            )}
          </div>

          <TermsOfSale
            buttonText={buttonText}
            content={orderNote}
            isHidden={submitted}
            onClick={onBackButtonClick}
          />
        </div>
      </FormSection>
    </Track>
  )
}

export default ContentfulPaymentFormComponent
