import { DiscountCodePagedQueryResponse } from '@commercetools/platform-sdk'
import findFirstRightValue from '@simplisafe/ewok/monet-utils/findFirstRight'
import path from '@simplisafe/ewok/ramda/path'
import { IOAddDiscountCodeToCart } from '@simplisafe/ss-ecomm-data/cart'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart/actions'
import { commercetoolsRequestDiscountCode } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { selectEvergreenPromo } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoCode,
  selectActivePromoCodeWithMonitoring,
  selectCart,
  selectCartId
} from '@simplisafe/ss-ecomm-data/redux/select'
import { fork, map } from 'fluture'
import { Set } from 'immutable'
import { Maybe } from 'monet'
import { useDebugValue, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import { GtmData, sendGtmCustomEvent } from '../util/analytics'

/**
 * Applies promo codes to the cart.
 *
 * Should only ever apply 1 or two codes at a time in order of importance.
 *
 * 1. a utm code
 * 2. Active promo codes
 * 3. Evergreen promo codes
 */
const useApplyPromoCode = (utmCode: Maybe<string>) => {
  const [activeCodes, setActiveCodes] = useState<ReadonlySet<string>>(Set([]))

  const [evergreenCodes, setEvergreenCodes] = useState<ReadonlySet<string>>(
    Set([])
  )
  const [cartLoaded, setCartLoaded] = useState(false)
  const [codesApplied, setCodesApplied] = useState(false)
  const [toastFired, setToastFired] = useState(false)

  // @ts-expect-error TS(2339) FIXME: Property 'union' does not exist on type 'ReadonlyS... Remove this comment to see the full error message
  const codes = activeCodes.union(evergreenCodes)

  const dispatch = useDispatch()

  const evergreenPromo = useSelector(selectEvergreenPromo)
  const activePromo = useSelector(selectActivePromoCode)
  const activePromoWithMonitoring = useSelector(
    selectActivePromoCodeWithMonitoring
  )
  const cartId = useSelector(selectCartId)
  const cart = useSelector(selectCart)

  useEffect(() => {
    setCodesApplied(false)
    // make sure current codes are applied to the cart if the cart id or promo codes change
    // @ts-expect-error TS(2339) FIXME: Property 'val' does not exist on type 'Maybe<strin... Remove this comment to see the full error message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId.val, codes.join(',')])

  useEffect(() => {
    cart.cata(
      () => {
        setCartLoaded(false)
      },
      () => {
        setCartLoaded(false)
      },
      () => {
        setCartLoaded(false)
      },
      () => {
        setCartLoaded(true)
      }
    )
  }, [cart])

  useEffect(() => {
    const hasUtmCode: boolean = utmCode.isJust()

    const hasActivePromo: boolean = findFirstRightValue([
      activePromo,
      activePromoWithMonitoring
    ]).isJust()

    // apply active promo if one is defined and apply no other codes
    // @ts-expect-error TS(2339) FIXME: Property 'add' does not exist on type 'ReadonlySet... Remove this comment to see the full error message
    !hasUtmCode &&
      hasActivePromo &&
      activePromo.forEach(val => setActiveCodes(v => v.add(val)))
    // @ts-expect-error TS(2339) FIXME: Property 'add' does not exist on type 'ReadonlySet... Remove this comment to see the full error message
    !hasUtmCode &&
      hasActivePromo &&
      activePromoWithMonitoring.forEach(val => setActiveCodes(v => v.add(val)))
    // apply evergreen if there is not an active promo or evergreen and no other codes
    !hasUtmCode &&
      !hasActivePromo &&
      evergreenPromo.forEach(promo => {
        // @ts-expect-error TS(2339) FIXME: Property 'add' does not exist on type 'ReadonlySet... Remove this comment to see the full error message
        promo.promoCode.forEach(val => setEvergreenCodes(v => v.add(val)))
        // @ts-expect-error TS(2339) FIXME: Property 'add' does not exist on type 'ReadonlySet... Remove this comment to see the full error message
        promo.promoCodeWithMonitoring.forEach(val =>
          setEvergreenCodes(v => v.add(val))
        )
      })

    // we want to just target the .val of each monad, which isn't really on the type but it does exist
    // @ts-expect-error TS(2339) FIXME: Property 'val' does not exist on type 'Maybe<strin... Remove this comment to see the full error message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePromoWithMonitoring.val,
    activePromo.val,
    evergreenPromo.val,
    utmCode.val
  ])

  useEffect(() => {
    const utmCodeValid: ReadonlyArray<boolean> = []

    const hasUtmCode: boolean = utmCode.isJust()

    // if there are multiple codes, split them around their delimiter
    const utmCodes: ReadonlyArray<string> = utmCode
      .map((code: string) => code.trim().split(','))
      .getOrElse([])

    utmCodes.forEach(code => {
      commercetoolsRequestDiscountCode(
        'en-US',
        `code%3D%22${code.toUpperCase()}%22`
      )
        .pipe(
          map<Maybe<DiscountCodePagedQueryResponse>, boolean>(response => {
            return response.cata(
              () => false,
              res => {
                // pull date from resposne object
                const validUntil = Maybe.fromNull(
                  path(['results', 0, 'validUntil'], res)
                )
                const validFrom = Maybe.fromNull(
                  path(['results', 0, 'validFrom'], res)
                )
                const validResponse = Maybe.fromNull(
                  path(['results', 0, 'code'], res)
                ).isSome()

                // If the 'valid until' date exists, parse it to get its time in ms
                const validUntilDate = validUntil.cata(
                  () => 0,
                  _validUntil => Date.parse(String(_validUntil))
                )

                // If the 'valid from' date exists, parse it to get its time in ms
                const validFromDate = validFrom.cata(
                  () => 0,
                  _validFrom => Date.parse(String(_validFrom))
                )

                // Bulk discounts have no dates entered. Ensure the response has a code and that the valid date fields are empty.
                const noDateRequirement =
                  validResponse && validUntilDate === 0 && validFromDate === 0

                // determine if the code is valid at this moment in time
                return (
                  noDateRequirement ||
                  (validUntilDate > Date.parse(Date()) &&
                    validFromDate < Date.parse(Date()))
                )
              }
            )
          })
        )
        .pipe(
          fork(() => false)((res: boolean) => {
            // @ts-expect-error TS(2339) FIXME: Property 'push' does not exist on type 'readonly b... Remove this comment to see the full error message
            utmCodeValid.push(res)

            // apply utmCode if one is defined & valid and apply no other codes
            // @ts-expect-error TS(2339) FIXME: Property 'add' does not exist on type 'ReadonlySet... Remove this comment to see the full error message
            hasUtmCode && res && setActiveCodes(s => s.add(code.trim()))

            // once all codes have been checked, if at least 1 code is valid, create an empty cart for that code to be applied to
            hasUtmCode &&
              utmCodeValid.includes(true) &&
              utmCodeValid.length === utmCodes.length &&
              dispatch(
                IOAddToCart(
                  { products: [] },
                  () => null,
                  () => null
                )
              )

            // if no valid codes exist in utm string, clear local storage
            const noValidCodes =
              !utmCodeValid.includes(true) &&
              utmCodeValid.length === utmCodes.length
            noValidCodes && window.localStorage.removeItem('utm_code')
          })
        )
    })

    // just target the .val of this monad, which isn't really on the type but it does exist
    // @ts-expect-error TS(2339) FIXME: Property 'val' does not exist on type 'Maybe<strin... Remove this comment to see the full error message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utmCode.val])

  useEffect(() => {
    const _codes =
      activeCodes.size > 0
        ? Array.from(activeCodes.values())
        : Array.from(evergreenCodes.values())
    const shouldApplyCodes = _codes.length > 0 && !codesApplied && cartLoaded

    shouldApplyCodes &&
      dispatch(
        IOAddDiscountCodeToCart(
          _codes,
          () => {
            setCodesApplied(false)
          },
          () => {
            setCodesApplied(true)
            window.localStorage.removeItem('utm_code')
          }
        )
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps -- using codes.join() instead of codes directly
  }, [codes.join(','), codesApplied, cartLoaded])

  useEffect(() => {
    // prepare data for a GTM event
    const gtmData: GtmData = {
      event: 'toastAlert',
      eventAction: 'AddUTMCodeToCart',
      eventCategory: 'UTMAdditionAlert',
      eventLabel: codes.toArray().toString()
    }

    // fire an event to GTM to alert that a toast has been shown
    codes.toArray().length > 0 &&
      !codesApplied &&
      window.localStorage.utm_code &&
      sendGtmCustomEvent(gtmData)

    // if there is a UTM code waiting to be added to the cart that has already been validated, throw a toast message to the user

    const shouldFireToast =
      codes.toArray().length > 0 &&
      !codesApplied &&
      window.localStorage.utm_code &&
      !toastFired

    shouldFireToast &&
      toast('your discount will be applied in cart', {
        duration: 10000,
        id: 'promoToast',
        position: 'top-right',
        style: {
          margin: 0,
          maxWidth: '380px'
        }
      })

    // once a toast alert has been sent for UTM addition, don't send it again
    shouldFireToast && setToastFired(true)

    // clear UTM storage once a toast has been fired to ensure another doesn't fire
    shouldFireToast && toastFired && window.localStorage.removeItem('utm_code')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codes])

  useDebugValue(codes)

  return Array.from(codes)
}

export default useApplyPromoCode
