import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import { GetAQuoteSchema } from '@simplisafe/ss-ecomm-data/commercetools/getAQuoteFormSchema'
import { GetAQuoteFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/getAQuoteFormSubmission'
import { getAQuoteFormSubmit } from '@simplisafe/ss-ecomm-data/commercetools/getAQuoteFormSubmission'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SSButton } from '@simplisafe/ss-react-components'
import { Form, Formik, FormikHelpers } from 'formik'
import { Link } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React, { ReactElement } from 'react'

import {
  ContentfulGetaQuoteForm,
  ContentfulRadioButton
} from '../../../graphql'
import GetAQuoteInput from './form-sections/GetAQuoteInput'
import NumLocations from './form-sections/NumLocations'
import PrivacyPolicy from './form-sections/PrivacyPolicy'
import Submission from './form-sections/Submission'
import SuccessMessage from './form-sections/SuccessMessage'
import Title from './form-sections/Title'

export type FormValues = {
  readonly firstName: string
  readonly lastName: string
  readonly companyName: string
  readonly email: string
  readonly phone: string
  readonly numLocations: string
}

const initialValues: FormValues = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  numLocations: '',
  phone: ''
}

type GetAQuoteFormProps = {
  readonly data: ContentfulGetaQuoteForm
}

type OptionType = readonly Pick<ContentfulRadioButton, 'text' | 'value'>[]

export default function GetAQuoteForm({ data }: GetAQuoteFormProps) {
  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target && (
          <div className="flex justify-center">
            <Link to={data.target?.url}>
              <SSButton color="primary">{data.target?.buttonText}</SSButton>
            </Link>
          </div>
        )
    }
  }
  const getAQuoteFormPrivacyPolicy: ReactElement = data.privacyPolicy?.raw && (
    <ContentfulRichText raw={data.privacyPolicy?.raw} />
  )
  const getAQuoteFormSuccessMessageDescription: ReactElement =
    data.successMessageDescription && (
      <ContentfulRichText
        optionsCustom={options}
        raw={data.successMessageDescription?.raw}
        references={data.successMessageDescription?.references}
      />
    )

  const onSubmit = async (
    formData: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    const submitForm: GetAQuoteFormSubmitBody = {
      ...formData,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      businessType: propOr<string, string>('-', 'businessType', data),
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      source: propOr<string, string>('Business Owner', 'source', data)
    }
    setSubmitting(true)

    const handleSuccess = () => {
      setSubmitting(false)
      setStatus({ message: 'success' })
    }

    const handleFailure = (error: Error) => {
      setSubmitting(false)
      logError(error)
    }

    getAQuoteFormSubmit(submitForm)(handleFailure)(handleSuccess)
  }

  return (
    <div
      data-component={'GetAQuoteForm'}
      style={{
        backgroundColor: 'rgb(246, 246, 245)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px 0px',
        margin: '0',
        padding: '24px 32px'
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={GetAQuoteSchema}
      >
        {({ isSubmitting, status }) => (
          <Form>
            {!status && (
              <>
                {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
                <Title
                  formTitle={propOr<string, string>('', 'formTitle', data)}
                />
                <GetAQuoteInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>(
                    '',
                    'firstNameFieldLabel',
                    data
                  )}
                  name="firstName"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'firstNameFieldPlaceholder',
                    data
                  )}
                />
                <GetAQuoteInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'lastNameFieldLabel', data)}
                  name="lastName"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'lastNameFieldPlaceholder',
                    data
                  )}
                />
                <GetAQuoteInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'companyFieldLabel', data)}
                  name="companyName"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'companyFieldPlaceholder',
                    data
                  )}
                />
                <GetAQuoteInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'emailFieldLabel', data)}
                  name="email"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'emailFieldPlaceholder',
                    data
                  )}
                />
                <GetAQuoteInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'phoneFieldLabel', data)}
                  name="phone"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'phoneFieldPlaceholder',
                    data
                  )}
                />
                <NumLocations
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>(
                    '',
                    'numLocationsFieldLabel',
                    data
                  )}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  options={propOr<OptionType, OptionType>(
                    [],
                    'numLocationsOptions',
                    data
                  )}
                />
                <Submission
                  disabled={isSubmitting}
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'submitButtonLabel', data)}
                />
                <PrivacyPolicy text={getAQuoteFormPrivacyPolicy} />
              </>
            )}
            {status && status.message && (
              <SuccessMessage
                successMessageDescription={
                  getAQuoteFormSuccessMessageDescription
                }
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                successMessageTitle={propOr<string, string>(
                  '',
                  'successMessageTitle',
                  data
                )}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
