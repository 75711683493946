import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { window } from 'browser-monads-ts'
import { path, prop } from 'ramda'
import { useEffect, useState } from 'react'
/**
 * Polls Google Analytics clientId && sessionId values and returns them
 * @returns
 */
export function useGoogleAnalytics(): readonly [
  string | undefined,
  string | undefined
] {
  const [clientId, setClientId] = useState<string | undefined>()
  const [sessionId, setSessionId] = useState<string | undefined>()
  const [gaReady, setGaReady] = useState(
    isNotNil(path(['ga', 'getAll'], window))
  )
  useEffect(() => {
    const id =
      !gaReady &&
      setInterval(() => {
        setGaReady(isNotNil(path(['ga', 'getAll'], window)))
      }, 100)
    return () => {
      id && clearInterval(id)
    }
  }, [gaReady])

  useEffect(() => {
    // eslint-disable-next-line functional/no-conditional-statement
    if (gaReady) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ga: any = prop('ga', window)

      const gaGetAllResult = ga?.getAll?.()?.[0]

      setClientId(gaGetAllResult?.get('clientId'))

      setSessionId(gaGetAllResult?.get('_gt') || gaGetAllResult?.get('_gbt'))
    }
  }, [gaReady])

  return [clientId, sessionId]
}
