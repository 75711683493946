/* eslint-disable functional/no-class */
/* eslint-disable functional/no-this-expression */
import prop from '@simplisafe/ewok/ramda/prop'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { BannerError, Text } from '@simplisafe/ss-react-components'
import React, { ErrorInfo, ReactElement } from 'react'

type Props = {
  readonly children: ReactElement | readonly ReactElement[]
}

type State = {
  readonly hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(__error: string) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, { componentStack: prop('componentStack', errorInfo) })
  }

  render() {
    return this.state.hasError ? (
      <BannerError backgroundColor="neutralLightGray">
        <Text useTailwind>
          <h2>Something went wrong.</h2>
          <p className="text-center">Try reloading the page.</p>
        </Text>
      </BannerError>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
