import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { liftSelectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { ProductPlan } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { Just, Maybe } from 'monet'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { pipe } from 'ts-functional-pipe'

import {
  ContentfulLinkAddToCart,
  ContentfulModal,
  ContentfulProductPlan
} from '../../../graphql'
import { renderPriceWithTemplate } from '../../commercetools/price'
import { getMappedComponent } from '../../componentMappings'
import { parseArray } from '../../util/parseContentfulValues'
import ContentfulRichText from '../ContentfulRichText'
import ModalComponent from '../ModalComponent'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type ProductPlanComponentProps = {
  readonly data: ContentfulProductPlan
}

export default function ProductPlanComponent({
  data
}: ProductPlanComponentProps) {
  const productId = safeProp('productId', data)
  const product = useSelector(liftSelectProduct(productId))
  const productOfferContent = (
    <ContentfulRichText raw={data?.productOfferText?.raw} />
  )
  const keyFeaturesModals = parseArray(prop('keyFeaturesModals', data))
  const planDescription = <ContentfulRichText raw={data?.description?.raw} />

  const prepareButtonData = (
    buttonData: ContentfulLinkAddToCart | ContentfulModal
  ) => {
    return {
      ...buttonData,
      /**
       * TODO
       * Map.fromNull(buttonData).flatMap(safePath([ 'internal', 'type' ]))
       * can become:
       * safePath([ 'internal', 'type' ], buttonData)
       * once simplisafe/monda#13 (https://github.com/simplisafe/monda/issues/13) is complete
       */
      spinnerMobileAutoHeight: Maybe.fromNull(buttonData)
        .flatMap(safePath(['internal', 'type']))
        .map(type => type === 'ContentfulLinkAddToCart')
        .getOrElse(false)
    }
  }

  const buttonData = safeProp('button', data).map(prepareButtonData)
  const buttonComponent = buttonData.flatMap(
    pipe(getMappedComponent, Maybe.fromNull)
  )

  //TODO Get the right data for productOffer - how to switch between Promo/Evergreen offer?
  return (
    <ProductPlan
      button={Just(
        (buttonData: unknown) =>
          function (ButtonComponent: FC<{ readonly data: unknown }>) {
            return <ButtonComponent data={buttonData} />
          }
      )
        .apTo(buttonData)
        .apTo(buttonComponent)
        .orUndefined()}
      keyFeatures={keyFeaturesModals.map(feature => {
        return {
          clickTarget: <ModalComponent data={feature} key={feature.id} />,
          id: feature.id
        }
      })}
      planDescription={planDescription}
      price={renderPriceWithTemplate({
        product,
        showDiscountedPrice: false,
        template: prop('priceRate', data),
        templateVar: '{price}'
      })}
      productOfferContent={productOfferContent}
      relatedInformation={prop('relatedInformation', data) || ''}
      title={prop('title', data) || ''}
    />
  )
}

export const ProductPlanQuery = graphql`
  #graphql
  fragment contentfulProductPlan on ContentfulProductPlan {
    button {
      ... on ContentfulLinkAddToCart {
        ...contentfulLinkAddToCart
      }
      ... on ContentfulModal {
        ...modalFragment
      }
    }
    description {
      raw # todo
    }
    id
    internal {
      type
    }
    keyFeaturesModals {
      ... on ContentfulModal {
        ...modalFragment
      }
    }
    priceRate
    productOffer
    productOfferText {
      raw # todo
    }
    productId
    relatedInformation
    title
  }
`
