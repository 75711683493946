import React from 'react'

import ButtonCta from './ButtonCta'
import Countdown from './Countdown'
import EndsText from './EndsText'
import Offer from './Offer'
import SaleName from './SaleName'
import type { TopBannerProps } from './TopBanner'
import usePromoBannerData from './usePromoBannerData'

function Title() {
  const { hasCountdown } = usePromoBannerData()
  return (
    <div
      className="flex flex-col items-center mb-1"
      data-component="PromoTitle"
    >
      <SaleName isMobileBottomBanner={true} />
      {hasCountdown ? null : <EndsText isMobileBottomBanner={true} />}
    </div>
  )
}

function BottomLayout({ type }: TopBannerProps) {
  const showButton = type !== 'pdp-plp'

  return (
    <div
      className="flex flex-col items-center justify-items-center max-w-sm mx-auto my-0"
      data-component="BottomLayout"
    >
      <Title />
      <Countdown />
      <Offer isMobileBottomBanner={true} />
      {showButton ? <ButtonCta isFull={true} /> : null}
    </div>
  )
}

export default BottomLayout
