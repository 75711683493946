type getScratcherScriptParams = {
  readonly name: string
  readonly slug: string
  readonly src: string
}

export const placeholderId = 'scratcher-placeholder'

/**
 * It returns JS code as a string to be used to embed Scratcher interactive games into blog posts. See https://simplisafe.atlassian.net/browse/ECP-5543
 */
export const getScratcherScript = ({
  name,
  slug,
  src
}: getScratcherScriptParams) => {
  return `
    window['${name}'] = window['${name}'] || {};
    window['${name}'].options = {
      slug: '${slug}',
      width: '100%',
      height: 1100,
    };
    if (document.getElementById('${name}')) {
      document.getElementById('${name}').remove();
    }
    a = document.createElement("script");
    a.src= '${src}';
    a.async = 1;
    a.id = '${name}';
    a.onload = () => {
      const iframe = [].filter.call(
        document.getElementsByTagName('iframe'),
        (el) => el.src.startsWith('https://game.scratcher.io/')
      )[0];
      document.getElementById('${placeholderId}').before(iframe);
    }
    m = document.getElementsByTagName('script')[0];
    m.parentNode.insertBefore(a, m);
  `
}
