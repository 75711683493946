// TODO: clean up this file, there may be some unused functionality here

import { toButtonTypeValue, toButtonWidthValue } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart/actions'
import { buildCustomFieldUpdateAction } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  PartnerMembershipCaptureBody,
  partnersMembershipFormSubmit
} from '@simplisafe/ss-ecomm-data/partners/submission'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { FormField, SSButton, Text } from '@simplisafe/ss-react-components'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { Form, Formik } from 'formik'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import propOr from 'ramda/src/propOr'
import React, { CSSProperties, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { boolean, number, object, string } from 'yup'

import {
  ContentfulAsset,
  ContentfulButton,
  ContentfulModal,
  ContentfulPartnerCaptureForm
} from '../../../graphql'
import { toButton } from '../../util/helper'
import { parseArray } from '../../util/parseContentfulValues'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import ContentfulRichText from '../ContentfulRichText'
import ModalComponent from '../ModalComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import Description from './form-sections/Description'
import SimpleText from './form-sections/SimpleText'
import SuccessMessage from './form-sections/SuccessMessage'

type PartnerCaptureFormProps = {
  readonly closeModal: () => void
  readonly data: ContentfulPartnerCaptureForm
  readonly onSuccess?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
enum InputTypes {
  string = 'string',
  number = 'number',
  date = 'date',
  boolean = 'boolean'
}

type FormTypes = {
  readonly partnerName: string
  readonly firstName?: string
  readonly lastName?: string
  readonly memberNumber: string
}

// TODO: Refactor to use existing CTFL inputs instead of "Form Input" we created for this.
export default function PartnerCaptureForm({
  closeModal,
  data,
  onSuccess
}: PartnerCaptureFormProps) {
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()

  const partnerFields = parseArray(prop('partnerFields', data))
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const formTitle = propOr<string, string>('', 'formTitle', data)
  const termsAndConditions = safeProp(
    'termsAndConditionsModal',
    data
  ).orUndefined()
  const continueButton = safeProp('continueButton', data).orUndefined()
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const formDescription = (
    <p>{propOr<string, string>('', 'formDescription', data)}</p>
  )
  const submitButton = safeProp('submitButton', data).orUndefined()
  const image = safeProp('partnerImage', data).orUndefined()
  const promoInformation = propOr('', 'promoInformation', data)

  /**
   *
   * Note from Josh.
   *
   * This component defines a lot of internal functions, which is terrible for performance.
   *
   * Ideally all of these functions should be in different files, and each render function should just be a different component.
   *
   */

  const schema = partnerFields.reduce((prevSchema, currentField) => {
    const types = {
      boolean: boolean(),
      number: number(),
      string: string()
    }

    const minValidationNumber = prop('minRequirement', currentField)
    const maxValidationNumber = prop('maxRequirement', currentField)

    // TODO: refactor this code to fix lint and TS errors
    // eslint-disable-next-line functional/no-let
    let fieldValidation = types[prop('inputType', currentField)].label(
      currentField.label
    )

    prop('isEmail', currentField) && fieldValidation.type === 'string'
      ? (fieldValidation = fieldValidation.email())
      : undefined

    prop('required', currentField)
      ? (fieldValidation = fieldValidation.required())
      : undefined

    minValidationNumber &&
    (fieldValidation.type === 'number' || fieldValidation.type === 'string')
      ? (fieldValidation = fieldValidation.min(minValidationNumber))
      : undefined

    maxValidationNumber &&
    (fieldValidation.type === 'number' || fieldValidation.type === 'string')
      ? (fieldValidation = fieldValidation.max(maxValidationNumber))
      : undefined

    return {
      ...prevSchema,

      [currentField.name]: fieldValidation
    }
  }, {})

  const initialFormValues = partnerFields.reduce(
    (prevValue, currentField) => {
      return {
        ...prevValue,
        [currentField.name]: ''
      }
    },
    { locale }
  )

  // TODO: add variables for reused values to avoid duplicate code
  const renderPartnerField = (field: any) => ({
    number: (
      <SimpleText
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        key={propOr<string, string>('', 'id', field)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        label={propOr<string, string>('', 'label', field)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        name={propOr<string, string>('', 'name', field)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        placeholder={propOr<string, string>('', 'placeholder', field)}
        type="number"
      />
    ),

    string: (
      <SimpleText
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        key={propOr<string, string>('', 'id', field)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        label={propOr<string, string>('', 'label', field)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        name={propOr<string, string>('', 'name', field)}
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        placeholder={propOr<string, string>('', 'placeholder', field)}
        type={field.isEmail ? 'email' : 'text'}
      />
    )
  })

  const renderButton = (
    props: ContentfulButton,
    buttonType: SSButtonProps['type'],
    isSubmitting: boolean,
    onClick?: SSButtonProps['onClick'],
    styles?: CSSProperties
  ) => {
    const buttonProps = {
      ...toButton(props),
      disabled: isSubmitting,
      minWidth: toButtonWidthValue('medium'),
      onClick,
      type: toButtonTypeValue(buttonType)
    }
    return (
      <div className="mt-8" data-component={buttonType} style={styles}>
        <FormField hideLabel={true} name={`${buttonType}`}>
          <SSButton {...buttonProps} />
        </FormField>
      </div>
    )
  }

  const renderTermsAndConditions = (termsAndConditions: ContentfulModal) => {
    const termsAndConditionsLink = (
      <a
        href="#"
        style={{
          color: '#9D9CA0',
          padding: '10px'
        }}
      >
        Terms and Conditions
      </a>
    )

    const modalContentData = safeProp(
      'modalContent',
      termsAndConditions
    ).orUndefined()
    // @ts-expect-error TS(2322) FIXME: Type 'ContentfulBannerContentfulFindYourPerfectSys... Remove this comment to see the full error message
    const modalContent: ReactNode = modalContentData ? (
      <RichTextWithOptionsComponent data={modalContentData} />
    ) : (
      <></>
    )

    return (
      <ModalComponent
        clickTarget={termsAndConditionsLink}
        modalContent={modalContent}
      />
    )
  }

  const renderPartnerImage = (image: ContentfulAsset, small: boolean) => {
    return (
      <GatsbyImage
        // TODO this needs an alt tag
        alt=""
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
        image={getImage(image)}
        // imgStyle={{ objectFit: 'contain', }}
        style={{
          height: '64px',
          objectFit: 'contain',
          width: small ? '220px' : '320px'
        }}
      />
    )
  }

  const renderProcessingMessage = (
    <div
      style={{
        marginBottom: '-1rem',
        padding: '10px'
      }}
    >
      <Text fontWeight="medium" textColor="darkOrange" textSize="sm">
        {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
        {propOr<string, string>(
          'Thank you. Validation in-progress.',
          'submittedMessage',
          data
        )}
      </Text>
    </div>
  )

  const successMessageDescription = (
    <ContentfulRichText raw={data?.successMessageDescription?.raw} />
  )

  const formatFormData = (data: FormTypes) => {
    const partnerName = getValueFromPartnerCookie('partnerName') || ''
    const partnerGroup = getValueFromPartnerCookie('partnerGroup')

    const reward = partnerGroup === 'airlines' ? 7000 : 0

    return {
      memberNumber: propOr('', 'memberNumber', data),
      membershipData: {
        firstName: propOr('', 'firstName', data),
        lastName: propOr('', 'lastName', data),
        memberNumber: propOr('', 'memberNumber', data),
        rewardValue: reward
      },
      partnerName: partnerName
    }
  }

  const updateCartMemberNumber = (partnerMemberNumber: string) => {
    const partnerMemberNumberAction = buildCustomFieldUpdateAction({
      name: 'partnerMemberNumber',
      value: partnerMemberNumber
    })
    const updateActions = [partnerMemberNumberAction]
    dispatch(
      IOUpdateCart(updateActions, () =>
        logError(
          Error('something went wrong trying to update partnerMemberNumber')
        )
      )
    )
  }

  return (
    <Formik
      initialValues={initialFormValues}
      // @ts-expect-error TS(2322) FIXME: Type '(formData: PartnerMembershipCaptureBody, { s... Remove this comment to see the full error message
      onSubmit={(
        formData: PartnerMembershipCaptureBody,
        { setSubmitting, setStatus, setFieldError }
      ) => {
        const handleSuccess = () => {
          setStatus('success')
          setSubmitting(true)
          updateCartMemberNumber(formData.memberNumber)
          onSuccess && onSuccess()
        }

        const handleFailure = () => {
          const message = 'Uh oh! Looks like something is wrong! try again'
          setFieldError('memberNumber', message)
          setSubmitting(false)
        }
        setSubmitting(true)
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ memberNumber: unknown; members... Remove this comment to see the full error message
        partnersMembershipFormSubmit(formatFormData(formData))(handleFailure)(
          handleSuccess
        )
      }}
      validationSchema={object().shape(schema)}
    >
      {({ isSubmitting, status }) => (
        <div style={{ padding: '50px' }}>
          <div className="flex flex-wrap items-center justify-between">
            {image && renderPartnerImage(image, !!promoInformation)}
            {promoInformation && (
              <Text useTailwind={true}>
                <h1
                  className="md:ml-[9px]"
                  style={{
                    marginBottom: 0,
                    marginTop: '15px'
                  }}
                >
                  {promoInformation}
                </h1>
              </Text>
            )}
          </div>
          <Form>
            {!status && (
              <>
                {(formTitle || formDescription) && (
                  <Description
                    formDescription={formDescription}
                    // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                    formTitle={formTitle}
                  />
                )}
                <div style={{ marginBottom: '10px' }}>
                  {partnerFields.map(
                    field => renderPartnerField(field)[field.inputType]
                  )}
                </div>
                {termsAndConditions &&
                  renderTermsAndConditions(termsAndConditions)}
                {isSubmitting && renderProcessingMessage}
                {submitButton &&
                  renderButton(submitButton, 'submit', isSubmitting)}
                {continueButton &&
                  renderButton(
                    continueButton,
                    'button',
                    isSubmitting,
                    closeModal,
                    { margin: '-13px 0 0 -22px' }
                  )}
              </>
            )}
          </Form>
          {status && (
            <SuccessMessage
              successMessageDescription={successMessageDescription}
              // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
              successMessageTitle={propOr<string, string>(
                '',
                'successMessageTitle',
                data
              )}
            />
          )}
        </div>
      )}
    </Formik>
  )
}
