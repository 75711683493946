import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type CityProps = {
  readonly label: string
  readonly placeholder: string
}

function City(props: CityProps) {
  return (
    <div data-component={'City'}>
      <FormField hideLabel={true} name="city">
        <Text fontWeight="medium">{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name="city"
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default City
