import { FormField, Text } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type Props = {
  readonly successMessageDescription?: ReactElement
  readonly successMessageTitle?: string
}

function SuccessMessage({
  successMessageDescription,
  successMessageTitle
}: Props) {
  return (
    <FormField hideLabel={true} name="getAQuoteSuccessMessage">
      <div
        style={{
          margin: 'auto',
          textAlign: 'center'
        }}
      >
        {successMessageTitle && (
          <Text fontWeight="light">
            <h3 style={{ textAlign: 'center' }}>{successMessageTitle}</h3>
          </Text>
        )}
        {successMessageDescription}
      </div>
    </FormField>
  )
}

export default SuccessMessage
