import { FormField, SSButton } from '@simplisafe/ss-react-components'
import React from 'react'

type SubmissionProps = {
  readonly submitButtonLabel: string
  readonly submitButtonDisabled: boolean
}

function Submission(props: SubmissionProps) {
  return (
    <div data-component={'Submission'}>
      <FormField hideLabel={true} label={props.submitButtonLabel} name="submit">
        <SSButton
          disabled={props.submitButtonDisabled}
          minWidth="small"
          type="submit"
        >
          {props.submitButtonLabel}
        </SSButton>
      </FormField>
    </div>
  )
}

export default Submission
