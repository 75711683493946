import { toGapValue } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Column, Row } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import keys from 'ramda/src/keys'
import pick from 'ramda/src/pick'
import React, { useState } from 'react'

import { ContentfulExpandable } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { ContentfulButtonProps } from '../ButtonComponent'
import { GroupSectionProps } from '../GroupSection'

export type ExpandableComponentProps = {
  readonly data: Partial<ContentfulExpandable>
}

export default function ExpandableComponent({
  data
}: ExpandableComponentProps) {
  const [isExpand, setIsExpand] = useState(false)

  const onClickTarget = () => setIsExpand(true)

  const mapToComponent = (key: keyof ContentfulExpandable) =>
    safeProp(key, data)
      .map(
        (data: ContentfulButtonProps['data'] | GroupSectionProps['data']) => {
          const Component = getMappedComponent<
            ContentfulButtonProps | GroupSectionProps
          >(data)
          // @ts-expect-error TS(2322) FIXME: Type '{ data: (Partial<ContentfulButton> & { reado... Remove this comment to see the full error message
          return Component ? (
            <Component
              data={data}
              key={prop('id', data)}
              onClick={onClickTarget}
            />
          ) : null
        }
      )
      .orNull()

  const [component, expandedComponent, clickTarget] = keys(
    pick(['component', 'expandedComponent', 'clickTarget'], data)
  ).map(mapToComponent)

  return (
    <Row
      gap={isExpand ? toGapValue(prop('gapSize', data)) : 'medium'}
      padding={'medium'}
      rounded={'none'}
    >
      <Column rounded={'none'}>{component}</Column>
      <Column justifySelf={!isExpand ? 'center' : undefined} rounded={'none'}>
        {isExpand ? expandedComponent : clickTarget}
      </Column>
    </Row>
  )
}

export const ExpandableQuery = graphql`
  #graphql
  fragment expandable on ContentfulExpandable {
    title
    internal {
      type
    }
    component {
      ...nonCyclicalGroupSectionFragment
    }
    id
    expandedComponent {
      ...nonCyclicalGroupSectionFragment
    }
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    gapSize
  }
`
