/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  ContentfulRichText,
  contentfulRichTextGetDefaultOptions
} from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { BlogArticle } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { ContentfulBlogArticle } from '../../../graphql'
import FacebookPostComponent from '../FacebookPostComponent'
import { PageProps } from '../Page'
import { getScratcherScript, placeholderId } from './scratcher'

type BlogArticleComponentProps = {
  readonly data: Partial<ContentfulBlogArticle>
  readonly pageContext: PageProps['pageContext']
}

export default function BlogArticleComponent({
  data,
  pageContext
}: BlogArticleComponentProps) {
  const description = data?.description?.raw
  const references = data?.description?.references || []
  const lastPostedText = data?.lastPostedText?.raw
  const title = prop('title', data)

  const options: Options = {
    renderNode: {
      ...contentfulRichTextGetDefaultOptions().renderNode,
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target?.__typename === 'ContentfulScratcher' && (
          <>
            <Helmet>
              <script
                id={data.target.scriptId || 'scratcher-id'}
                type="text/javascript"
              >
                {getScratcherScript({
                  name: data.target.name || '',
                  slug: data.target.slug || '',
                  src: data.target.src || ''
                })}
              </script>
            </Helmet>
            <div id={placeholderId}></div>
          </>
        )
    }
  }

  const content = (
    <ContentfulRichText
      optionsCustom={options}
      raw={description}
      references={references}
    />
  )

  const lastPostedContent = (
    <ContentfulRichText raw={lastPostedText} references={references} />
  )

  return description && lastPostedText && title ? (
    <>
      <BlogArticle
        content={content}
        lastPostedContent={lastPostedContent}
        title={title}
      />
      {
        // @ts-expect-error TS(2345) FIXME: Argument of type '(ref: {    readonly type?: strin... Remove this comment to see the full error message
        references.findIndex(
          (ref: { readonly type?: string }) => ref?.type === 'Facebook Comment'
        ) !== -1 && <FacebookPostComponent pageContext={pageContext} />
      }
    </>
  ) : null
}

export const BlogArticleQuery = graphql`
  #graphql
  fragment contentfulBlogArticle on ContentfulBlogArticle {
    description {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          title
          description
          gatsbyImageData(width: 1440)
        }
        ... on ContentfulPlaceholder {
          contentful_id
          __typename
          type
        }
        ... on ContentfulScratcher {
          contentful_id
          __typename
          entryTitle
          name
          slug
          src
          scriptId
        }
      }
    }
    id
    internal {
      type
    }
    lastPostedText {
      raw
    }
    title
  }
`
