import { GatsbyImageSchema } from '@lib/components'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SimpleProductCard } from '@simplisafe/ss-react-components'
import { FooterTopSection } from '@simplisafe/ss-react-components'
import { graphql, navigate } from 'gatsby'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  ContentfulFooterTopSection,
  ContentfulProductCardSimple
} from '../../../graphql'
import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import AddToCartError, {
  AddToCartErrorType
} from '../../errorComponents/AddToCartError'
import useRequestPrice from '../../hooks/useRequestPrice'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { toButton } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'

type ContentfulFooterTopSectionProps = {
  readonly data: Partial<ContentfulFooterTopSection>
}

// TODO remove margin-top on regular page footer that causes a gap with this component?
export default function FooterTopSectionComponent({
  data
}: ContentfulFooterTopSectionProps) {
  const productData: Partial<ContentfulProductCardSimple> =
    prop('product', data) || {}
  const sku = path(['product', 'productId'], data) || ''
  const product = useSelector(selectProduct(sku))
  const dispatch = useDispatch()
  const navigateUrl = path(['product', 'button', 'url'], data)
  const [addToCartError, setAddToCartError] = useState<AddToCartErrorType>(null)
  const [showSpinner, setShowSpinner] = useState(true)
  const { Track, trackEvent } = useTracking()

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const { getFormattedPrice } = useRequestPrice(sku)
  const price = getFormattedPrice(pricePerUnit)

  const onAddToCart = useCallback(
    (quantity: number) => {
      const urlRedirect = (url: string) => {
        navigate(url)
      }
      setAddToCartError(null)

      const handleSuccess = () => {
        setShowSpinner(false)
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartEvent(product, trackEvent, quantity)
        navigateUrl && urlRedirect(navigateUrl)
      }
      const handleFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const skuIdWithOrNullProductError = sku
        ? `no product with sku ${sku} found in redux`
        : 'received null/empty sku'

      product.cata(
        () => {
          setAddToCartError('unrecoverable')
          setShowSpinner(false)
          logError(Error(`Cannot add to cart: ${skuIdWithOrNullProductError}`))
        },
        _product => {
          dispatch(
            IOAddToCart(
              {
                products: [
                  {
                    quantity,
                    sku: _product.masterSku
                  }
                ]
              },
              handleFailure,
              handleSuccess
            )
          )
        }
      )
    },
    [dispatch, product, sku, navigateUrl, trackEvent, optimizelyTrackSiteEvents]
  )

  const buttonData = prop('button', productData)

  const productCard = (
    <SimpleProductCard
      additionalText={safeProp('disclaimerText', productData).orUndefined()}
      buttonProps={{
        ...(buttonData ? toButton(buttonData) : { children: null }),
        showSpinner
      }}
      defaultQuantity={safeProp('defaultQuantity', productData).orJust(1)}
      errorMessage={
        addToCartError && (
          <AddToCartError errorType={addToCartError} textAlign="center" />
        )
      }
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      img={safePath(['image', '0'], productData)
        .map((imgProps: GatsbyImageSchema) => (
          /* <GatsbyImage */
          /*   image={getImage(imgProps)} */
          /*   alt={pathOr<string, string>('', ['image', '0', 'title'], productData)} */
          /*   key={pathOr<string, string>('', ['image', '0', 'id'], productData)} */
          /* /> */
          <img
            alt={productData?.image?.[0]?.title ?? ''}
            key={productData?.image?.[0]?.id}
            src={imgProps?.gatsbyImageData?.images?.fallback?.src ?? ''}
          />
        ))
        .orNull()}
      onButtonClick={onAddToCart}
      outOfStockMessage={renderOutOfStockMessage({ product: product })}
      price={price}
      quantityChangerProps={{
        label: 'Select quantity', // TODO this shouldn't be hardcoded
        max: safeProp('maximumQuantity', productData).orJust(4),
        min: 1
      }}
      title={safeProp('title', productData).orJust('')}
    />
  )

  const footerTopSectionText = <ContentfulRichText raw={data?.text?.raw} />

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <FooterTopSection
        content={footerTopSectionText}
        icon={safeProp('icon', data)
          // @ts-expect-error TS(2345) FIXME: Argument of type '(imgProps: GatsbyImageSchema & {... Remove this comment to see the full error message
          .map(
            (
              imgProps: GatsbyImageSchema & { readonly description: string } // TODO: img styles
            ) => (
              // <GatsbyImage
              //   alt={imgProps?.description || ''}
              //   image={getImage(imgProps)}
              // />
              <img
                alt={imgProps?.description}
                src={imgProps?.gatsbyImageData?.images?.fallback?.src ?? ''}
              />
            )
          )
          .orNull()}
        productCard={productCard}
      />
    </Track>
  )
}

export const query = graphql`
  #graphql
  fragment footerTopSectionFragment on ContentfulFooterTopSection {
    id
    internal {
      type
    }
    icon {
      id
      gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: BLURRED)
      title
      description # TODO get description from gatsbyImageData
    }
    text {
      raw
    }
    product {
      id
      internal {
        type
      }
      button {
        text
        type
        url
      }
      defaultQuantity
      disclaimerText
      image {
        id
        gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: BLURRED)
        title
      }
      maximumQuantity
      productId
      title
    }
  }
`
