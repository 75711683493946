import { PatentTable } from '@simplisafe/ss-react-components'
import {
  PatentTableCell,
  PatentTableRow,
  themeName
} from '@simplisafe/ss-react-components/PatentTable'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import React from 'react'

import {
  ContentfulTable,
  ContentfulTableCell,
  ContentfulTableRow
} from '../../../graphql'
import { renderComponentFromData } from '../../util/render'
import { PageProps } from '../Page'

type ContentfulTableContainerProps = {
  readonly id: string
  readonly data: Partial<ContentfulTable>
  readonly pageContext?: PageProps['pageContext']
}

const toFormContent = (x: ContentfulTableCell): PatentTableCell => ({
  colspan: pipe(
    x.colspan,
    O.fromNullable,
    O.fold(
      () => 1,
      colspan => colspan
    )
  ),
  content:
    (x.content?.__typename === 'ContentfulImage' ||
      x.content?.__typename === 'ContentfulRichTextWithOptions') &&
    renderComponentFromData(x.content)
})

const getCellData = (
  cell: ReadonlyArray<ContentfulTableCell | null>
): readonly ContentfulTableCell[] =>
  cell.filter((item): item is ContentfulTableCell => !!item)
const getTheme = (uiTheme: string) =>
  [uiTheme].filter((theme): theme is themeName => !!theme)[0]

const toGetPatentTableProps = (
  tableData: readonly ContentfulTableRow[]
): readonly PatentTableRow[] => {
  return (
    tableData &&
    tableData.map(data => {
      const cell = getCellData(data.cell ?? [])
      return { cell: cell?.map(toFormContent) }
    })
  )
}

const getRowData = (
  row: ReadonlyArray<ContentfulTableRow | null>
): readonly ContentfulTableRow[] =>
  row.filter((item): item is ContentfulTableRow => !!item)

export default function TableContainer({
  data
}: ContentfulTableContainerProps) {
  const row = getRowData(data.row ?? [])
  const patentTableProps = toGetPatentTableProps(row)
  const theme = getTheme(data.uiTheme ?? 'default')

  return <PatentTable row={patentTableProps} theme={theme} />
}
