import { GatsbyImage } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  HowItWorksDetail,
  HowItWorksDetailWrapper,
  HowItWorksHouse,
  HowItWorksHouseData,
  HowItWorksNav
} from '@simplisafe/ss-react-components'
import { ViewName } from '@simplisafe/ss-react-components/HowItWorksHouse/data'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import defaultTo from 'ramda/src/defaultTo'
import isNil from 'ramda/src/isNil'
import React, { useState } from 'react'

import {
  ContentfulHowItWorksDevice,
  ContentfulHowItWorksInteractiveHouse
} from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type HowItWorksInteractiveHouseProps = {
  readonly id: string
  readonly data: Partial<ContentfulHowItWorksInteractiveHouse>
}

/**
 * Contentful Nice Name => DeviceName
 *
 * The Contentful Nice Name comes from data.devices[x].device.
 * That value is a human-readable device they choose on the backend
 * to associate an icon, title, image, and more details with.
 *
 * This object maps those human-readable value to the DeviceName types
 * defined in ss-react-components/HowItWorksHouse/data, where
 * the DeviceName is used as CSS classes to highlight on each house view.
 */
type Keys =
  | '105dB Siren'
  | 'Base Station'
  | 'Bell Box'
  | 'Entry Sensor'
  | 'Glassbreak Sensor'
  | 'Keyfob'
  | 'Keypad'
  | 'Motion Sensor'
  | 'Outdoor Camera'
  | 'Panic Button'
  | 'SimpliCam'
  | 'Smoke Detector'
  | 'Temperature Sensor'
  | 'Video Doorbell Pro'
  | 'Water Sensor'
type Values =
  | 'baseStation'
  | 'bellBox'
  | 'entrySensor'
  | 'glassbreakSensor'
  | 'keyfob'
  | 'keypad'
  | 'motionSensor'
  | 'outdoorCamera'
  | 'panicButton'
  | 'simplicam'
  | 'siren'
  | 'smokeDetector'
  | 'temperatureSensor'
  | 'videoDoorbellPro'
  | 'waterSensor'

type activeDeviceValues = Values | undefined

const deviceSlugsByName: Record<Keys, Values> = {
  '105dB Siren': 'siren',
  'Base Station': 'baseStation',
  'Bell Box': 'bellBox',
  'Entry Sensor': 'entrySensor',
  'Glassbreak Sensor': 'glassbreakSensor',
  Keyfob: 'keyfob',
  Keypad: 'keypad',
  'Motion Sensor': 'motionSensor',
  'Outdoor Camera': 'outdoorCamera',
  'Panic Button': 'panicButton',
  SimpliCam: 'simplicam',
  'Smoke Detector': 'smokeDetector',
  'Temperature Sensor': 'temperatureSensor',
  'Video Doorbell Pro': 'videoDoorbellPro',
  'Water Sensor': 'waterSensor'
}

/**
 * Re-usable style for Gatsby Image.
 */
const defaultHouseImageProps = {
  imgStyle: { objectFit: 'contain' },
  style: {
    height: '100%',
    objectFit: 'contain',
    width: '100%'
  }
}

const createMenuItems = (devices: readonly ContentfulHowItWorksDevice[]) => {
  return devices.map(device => {
    const dev = prop('device', device)
    const image = device?.icon

    const id: string = isNil(dev) ? '' : deviceSlugsByName[dev]
    return {
      icon: (
        <GatsbyImage
          // @ts-expect-error TS(2559) FIXME: Type 'IGatsbyImageData' has no properties in commo... Remove this comment to see the full error message
          image={getImage(image)}
          loading="eager"
          {...defaultHouseImageProps}
        />
      ),
      id,
      key: prop('id', device),
      label: defaultTo('')(prop('title', device))
    }
  })
}

/**
 * Creates an object where keys are device slugs, and values
 * are the associated rendered JSX output.
 */
const createDetails = (devices: readonly ContentfulHowItWorksDevice[]) => {
  return devices.reduce((accum, device) => {
    const dev = prop('device', device)
    const image = device?.image

    const key: string = isNil(dev) ? '' : deviceSlugsByName[dev]
    return {
      ...accum,
      ...{
        [key]: (
          <HowItWorksDetail
            // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulButton[]' is not assignab... Remove this comment to see the full error message
            content={
              <ContentfulRichText
                raw={device?.description?.raw}
                references={device?.description?.references || []}
              />
            }
            image={
              <GatsbyImage
                alt={image?.description || image?.title || ''}
                // @ts-expect-error TS(2559) FIXME: Type 'IGatsbyImageData' has no properties in commo... Remove this comment to see the full error message
                image={getImage(image)}
                style={{ height: '100%' }}
              />
            }
            title={defaultTo('')(prop('title', device))}
          />
        )
      }
    }
  }, {})
}

export default function HowItWorksInteractiveHouse({
  data
}: HowItWorksInteractiveHouseProps) {
  const [activeDevice, setActiveDevice] =
    useState<activeDeviceValues>(undefined)
  const [activeView, setActiveView] = useState<ViewName>('insideFront')
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)

  const handleNavChange = (menuItemId: any) => {
    setActiveView(HowItWorksHouseData.getDeviceView(menuItemId))

    setActiveDevice(menuItemId)
    setIsDetailOpen(true)
  }

  const handleButtonChange = (buttonId: any) => {
    setActiveView(buttonId)
  }

  const dataDevices = prop('devices', data)
  const nonNullDevices = isNil(dataDevices)
    ? []
    : dataDevices.filter(
        (deva): deva is ContentfulHowItWorksDevice => !isNil(deva)
      )
  const navItems = createMenuItems(nonNullDevices)
  const details = createDetails(nonNullDevices)

  const houseFrontImage = data?.houseFrontImage
  const insideBackImage = data?.insideBackImage
  const insideFrontImage = data?.insideFrontImage

  return (
    <>
      <HowItWorksHouse
        activeDevice={activeDevice}
        activeView={activeView}
        houseFrontImage={
          <GatsbyImage
            // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
            image={getImage(houseFrontImage)}
            {...defaultHouseImageProps}
          />
        }
        houseFrontLabel={defaultTo('')(prop('houseFrontLabel', data))}
        insideBackImage={
          <GatsbyImage
            // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
            image={getImage(insideBackImage)}
            {...defaultHouseImageProps}
          />
        }
        insideBackLabel={defaultTo('')(prop('insideBackLabel', data))}
        insideFrontImage={
          <GatsbyImage
            // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
            image={getImage(insideFrontImage)}
            {...defaultHouseImageProps}
          />
        }
        insideFrontLabel={defaultTo('')(prop('insideFrontLabel', data))}
        onButtonChange={handleButtonChange}
        title={defaultTo('')(prop('title', data))}
      />
      <HowItWorksNav
        activeMenuItem={activeDevice}
        menuItems={navItems}
        onMenuItemChange={handleNavChange}
      />
      <HowItWorksDetailWrapper
        isOpen={isDetailOpen}
        onClose={() => {
          setActiveDevice(undefined)
          setIsDetailOpen(false)
        }}
      >
        {/* TODO: fix type */}
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        {activeDevice ? prop(activeDevice, details) : ''}
      </HowItWorksDetailWrapper>
    </>
  )
}

export const howItWorksInteractiveHouseQuery = graphql`
  #graphql
  fragment howItWorksInteractiveHouse on ContentfulHowItWorksInteractiveHouse {
    devices {
      device
      description {
        raw
        references {
          ... on ContentfulButton {
            ...contentfulButtonFragment
          }
        }
      }
      icon {
        title
        description
        gatsbyImageData(layout: CONSTRAINED, width: 72, placeholder: BLURRED)
      }
      image {
        title
        description
        gatsbyImageData(layout: CONSTRAINED, width: 1200, placeholder: BLURRED)
      }
      title
      id
    }
    houseFrontImage {
      title
      description
      gatsbyImageData(layout: FIXED, width: 1140, placeholder: BLURRED)
    }
    houseFrontLabel
    id
    insideBackImage {
      title
      description
      gatsbyImageData(layout: FIXED, width: 1140, placeholder: BLURRED)
    }
    insideBackLabel
    insideFrontImage {
      title
      description
      gatsbyImageData(layout: FIXED, width: 1140, placeholder: BLURRED)
    }
    insideFrontLabel
    internal {
      type
    }
    title
  }
`
