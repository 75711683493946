import { noValue } from '@lib/utils'
import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import type { PromoBanner } from '@simplisafe/ss-ecomm-data/promotions/lib'
import {
  selectActivePromoDiscountTextWithOverrides,
  selectActivePromoEndTime,
  selectLocale,
  selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, Row, Text } from '@simplisafe/ss-react-components'
import type { Span } from '@simplisafe/ss-react-components/Column'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import { Maybe, None } from 'monet'
import isNil from 'ramda/src/isNil'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { toSpan } from '../attributeMappings'
import { LeadCaptureForm, LeadCaptureFormProps } from '../LeadCaptureForm'
import { SimpliSafeCSSProperties } from '../types/SimpliSafeCSSProperties'
import type { DesktopBannerProps } from './DesktopBanner'

type BannerColumn = { readonly colSpan: Span }
type CustomColumn = 'addColumn' | 'removeColumn'

const calculateColumns = (
  bannerProps: Maybe<PromoBanner>,
  endTime: Maybe<Date>,
  discountText: string,
  customColSize?: CustomColumn
): Span => {
  // Countdown (Column 1)
  const hasCountdown = bannerProps
    .chain(safeProp('hasCountdown'))
    .getOrElse(false)

  // Promo Title / End Date (Column 2)
  const bannerTitle = bannerProps.chain(chainProp('saleName')).getOrElse('')
  const hasSaleName = bannerProps
    .chain(safeProp('hasSaleName'))
    .getOrElse(false)
  const hasEndDateCallout = bannerProps
    .chain(safeProp('hasEndDateCallout'))
    .getOrElse(false)
  const hasTitle =
    (hasSaleName && bannerTitle) || (hasEndDateCallout && endTime)
      ? true
      : false

  // Discount Offer with optional Free Gift Line Item (Column 3)
  const hasOffer = discountText !== ''

  // Leadgen Form Props (Column 4)
  const hasForm = bannerProps.chain(safeProp('hasEmailInput')).getOrElse(false)

  // Create an array of values representing if their column should show or not,
  // and get the resulting column count of only columns that are allowed to
  // display given the current data in redux.
  const columns = [hasCountdown, hasTitle, hasOffer, hasForm].filter(
    Boolean
  ).length

  const col = 12 / columns
  const customCol = customColSize === 'addColumn' ? col + 1 : col - 1
  return !isNil(customColSize) && hasCountdown ? toSpan(customCol) : toSpan(col)
}

const CountdownDesktop: FC<BannerColumn> = ({ colSpan }: BannerColumn) => {
  const bannerProps = useSelector(selectPromotionBanner)
  const endTime = useSelector(selectActivePromoEndTime).map(
    _endTime => new Date(_endTime)
  )
  const maybeHasCountdown = bannerProps.chain(safeProp('hasCountdown'))

  return maybeHasCountdown
    .chain(hasCountdown => (hasCountdown ? endTime : None<Date>()))
    .cata<JSX.Element | null>(
      () => null,
      date => (
        <Column
          alignSelf="center"
          justifySelf="center"
          rounded="none"
          spans={[colSpan]}
        >
          <Text
            fontWeight="medium"
            inheritTextColor={true}
            textSize="xl"
            useTailwind={true}
          >
            <Promo.Countdown endTime={date} showLabels={true} />
          </Text>
        </Column>
      )
    )
}

function TitleDesktop({ colSpan }: BannerColumn) {
  const locale = useSelector(selectLocale)
  const endTime = useSelector(selectActivePromoEndTime)
    .map(_endTime => new Date(_endTime))
    .orUndefined()
  const bannerProps = useSelector(selectPromotionBanner)
  const bannerTitle = bannerProps.chain(chainProp('saleName')).getOrElse('')
  const hasEndDateCallout = bannerProps
    .chain(safeProp('hasEndDateCallout'))
    .getOrElse(false)
  const hasSaleName = bannerProps
    .chain(safeProp('hasSaleName'))
    .getOrElse(false)
  const endTimeValue = hasEndDateCallout && endTime ? endTime : noValue()
  const title = hasSaleName && bannerTitle ? bannerTitle : noValue()

  return (hasSaleName && bannerTitle) || (hasEndDateCallout && endTime) ? (
    <Column
      alignSelf="center"
      justifySelf="center"
      rounded="none"
      spans={[colSpan]}
    >
      <Text
        fontWeight="medium"
        inheritTextColor={true}
        textAlignment="center"
        textSize="lg"
        useTailwind={true}
      >
        <Promo.PromoTitle
          endDate={endTimeValue}
          lineBreak={true}
          locale={locale}
          title={title}
        />
      </Text>
    </Column>
  ) : null
}

function OfferDesktop({ colSpan }: BannerColumn) {
  const discountText = useSelector(
    selectActivePromoDiscountTextWithOverrides
  ).getOrElse('')
  const bannerProps = useSelector(selectPromotionBanner)
  const lineItemLabel = bannerProps
    .chain(chainProp('freeGiftItemSecondaryText'))
    .orUndefined()
  const lineItemTitle = bannerProps
    .chain(chainProp('freeGiftItemPrimaryText'))
    .orUndefined()
  const offerLabel = bannerProps
    .chain(chainProp('discountSecondaryText'))
    .orUndefined()
  const hasFreeGiftItem = bannerProps
    .chain(safeProp('hasFreeGiftItem'))
    .getOrElse(false)
  return discountText ? (
    <Column
      alignSelf="center"
      justifySelf="center"
      rounded="none"
      spans={[colSpan]}
    >
      <Text inheritTextColor={true} textSize="2xl" useTailwind={true}>
        <Promo.OfferText
          hasBorder={false}
          lineBreak={false}
          lineItemLabel={
            hasFreeGiftItem && lineItemLabel ? lineItemLabel : noValue()
          }
          lineItemTitle={
            hasFreeGiftItem && lineItemTitle ? lineItemTitle : noValue()
          }
          offerLabel={offerLabel}
          offerTitle={<strong>{`${discountText} off`}</strong>}
        />
      </Text>
    </Column>
  ) : null
}

type LeadGenDesktopProps = BannerColumn &
  Pick<LeadCaptureFormProps, 'isSubmitted' | 'onSubmit' | 'showCta'>
function LeadGenDesktop({
  colSpan,
  isSubmitted,
  onSubmit,
  showCta
}: LeadGenDesktopProps) {
  const bannerProps = useSelector(selectPromotionBanner)
  const hasForm = bannerProps.chain(safeProp('hasEmailInput')).getOrElse(false)
  const buttonBackgroundColor = bannerProps
    .chain(chainProp('buttonBackgroundColor'))
    .orUndefined()
  const buttonTextColor = bannerProps
    .chain(chainProp('buttonTextColor'))
    .orUndefined()
  const buttonStyles: SimpliSafeCSSProperties = {
    '--btn-accent': buttonTextColor,
    '--btn-primary': buttonBackgroundColor,
    '--btn-primary-light': buttonBackgroundColor
  }

  return hasForm ? (
    <Column
      alignSelf="center"
      justifySelf="center"
      rounded="none"
      spans={[colSpan]}
    >
      <Text inheritTextColor={true} textSize="xs" useTailwind={true}>
        <LeadCaptureForm
          buttonStyles={buttonStyles}
          ctaButtonHref="/home-security-shop"
          ctaButtonText="Shop now"
          isSubmitted={isSubmitted}
          label="Enter your email"
          legal={
            <span>
              You may receive email offers from us in accordance with our{' '}
              <a href="/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>
              .
            </span>
          }
          onSubmit={onSubmit}
          placeholder="enter your email"
          showCta={showCta}
          submitButtonText="Get offer"
          successMessage="Thank you. Your code will automatically apply at checkout."
        />
      </Text>
    </Column>
  ) : null
}

function DesktopLayout({
  isFormSubmitted,
  onEmailSubmit,
  type
}: DesktopBannerProps) {
  const discountText = useSelector(
    selectActivePromoDiscountTextWithOverrides
  ).getOrElse('')
  const endTime = useSelector(selectActivePromoEndTime).map(
    _endTime => new Date(_endTime)
  )
  const bannerProps = useSelector(selectPromotionBanner)

  const colSpan = calculateColumns(bannerProps, endTime, discountText)
  const colSpanIncreased = calculateColumns(
    bannerProps,
    endTime,
    discountText,
    'addColumn'
  )
  const colSpanReduced = calculateColumns(
    bannerProps,
    endTime,
    discountText,
    'removeColumn'
  )

  return (
    <Row dataComponent="DesktopLayout" inheritTextColor={true} rounded="none">
      <CountdownDesktop colSpan={colSpan} />
      <TitleDesktop colSpan={colSpanReduced} />
      <OfferDesktop colSpan={colSpanIncreased} />
      <LeadGenDesktop
        colSpan={colSpan}
        isSubmitted={isFormSubmitted}
        onSubmit={onEmailSubmit}
        showCta={type !== 'pdp-plp'}
      />
    </Row>
  )
}

export default DesktopLayout
