import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import React from 'react'

import ButtonCta from './ButtonCta'
import Countdown from './Countdown'
import EndsText from './EndsText'
import Offer from './Offer'
import SaleName from './SaleName'
import type { TopBannerProps } from './TopBanner'

function Title() {
  return (
    <div
      className="flex flex-wrap gap-x-2 items-baseline md:flex-col md:gap-x-0 shrink-0"
      data-component="PromoTitle"
    >
      <SaleName />
      <EndsText />
    </div>
  )
}

function TopLayout({ type }: TopBannerProps) {
  const isTabletUp = useMediaQuery('TabletAndUp')

  const showButton = isTabletUp && type !== 'pdp-plp'
  const showCountdown = isTabletUp

  return (
    <div
      className="md:flex md:items-center md:px-8 lg:px-16"
      data-component="HolidayTopLayout"
    >
      <Title />
      {showCountdown ? <Countdown /> : null}
      <Offer />
      {showButton ? (
        <div className="flex-none ml-auto pl-8">
          <ButtonCta />
        </div>
      ) : null}
    </div>
  )
}

export default TopLayout
