import { OptimizelyContext } from '@lib/optimizely'
import { UserAttributes } from '@simplisafe/ss-ecomm-data/simplisafe/optimizely'
import { isString } from 'fp-ts/string'
import { useContext, useMemo } from 'react'

/**
 * Verifies that the experimentKey is not undefined, null, or an empty string.
 */
export function experimentKeyIsValid(
  key?: string | null | undefined
): key is string {
  return isString(key) && key !== ''
}

/**
 * An alternative to Optimizely's useActivate hook that can take in a possibly undefined or empty
 * experiment key and will only activate the user if the key is valid and the window exists.
 */
const useActivate = (
  experimentKey: string | null | undefined,
  overrideUserId?: string | undefined,
  overrideAttributes?: UserAttributes | undefined
): string => {
  const { optimizely } = useContext(OptimizelyContext)

  return useMemo(() => {
    const variation =
      global.window &&
      experimentKeyIsValid(experimentKey) &&
      optimizely &&
      optimizely.activate(experimentKey, overrideUserId, overrideAttributes)
    return variation || ''
  }, [])
}

export default useActivate
