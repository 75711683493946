import { getChatAppId } from '@lib/tracking'
import { Text } from '@simplisafe/ss-react-components'
import { window } from 'browser-monads-ts'
import { graphql } from 'gatsby'
import React, { MouseEvent } from 'react'

import { ContentfulLiveChatTrigger } from '../../../graphql'
import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import ContentfulRichText from '../ContentfulRichText'
import LiveChat from '.'

export type LiveChatTriggerProps = {
  readonly data: ContentfulLiveChatTrigger
}

export default function LiveChatTrigger({ data }: LiveChatTriggerProps) {
  const disclaimer = data?.disclaimerText
  const chatIdCookie: string = getChatAppId()
  const chatId: string = chatIdCookie || '610bd23d07ea1e23ca931dd8_app_979960'
  const hasAvailableAgents: boolean = useLiveChatAvailability(chatId)
  const clickTargetData = hasAvailableAgents
    ? data?.clickTarget
    : data?.clickTargetDisabled

  const references = clickTargetData?.references || []
  // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulIconWithTextContentfulLin... Remove this comment to see the full error message
  const clickTarget = (
    <ContentfulRichText raw={clickTargetData?.raw} references={references} />
  )

  const onClick = (e: MouseEvent) => {
    e.preventDefault()
    // so the build doesn't complain about missing type for window.sprChat
    // @ts-expect-error TS(2339) FIXME: Property 'sprChat' does not exist on type 'Window'... Remove this comment to see the full error message
    hasAvailableAgents &&
      window.sprChat &&
      window['sprChat'].call(window['sprChat'], 'open')
  }

  return (
    <>
      <LiveChat appId={chatId} />
      <span onClick={e => onClick(e)}>{clickTarget}</span>
      {!hasAvailableAgents && (
        <Text
          display="inline-flex"
          fontWeight="medium"
          textColor="neutralDarkGray"
        >
          {disclaimer}
        </Text>
      )}
    </>
  )
}

export const liveChatTrigger = graphql`
  #graphql
  fragment liveChatTrigger on ContentfulLiveChatTrigger {
    id
    internal {
      type
    }
    clickTarget {
      raw
      references {
        ... on ContentfulLink {
          ...contentfulLinkFragment
        }
        ... on ContentfulIconWithText {
          ...contentfulIconWithText
        }
      }
    }
    clickTargetDisabled {
      raw
      references {
        ... on ContentfulIconWithText {
          ...contentfulIconWithText
        }
      }
    }
    disclaimerText
  }
`
