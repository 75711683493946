import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { QuantityChanger } from '@simplisafe/ss-react-components'
import { ProductAddon } from '@simplisafe/ss-react-components'
import { Info } from '@simplisafe/ss-react-components/icons'
import React from 'react'

import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import { getProductName } from '../../commercetools/utils'
import { usePriceContext } from '../../providers/PriceProvider'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import ModalComponent from '../ModalComponent'
import type { ProductHeroAddonProps } from './types'

function ProductHeroAddon({
  product,
  productAddonData,
  isSellable,
  quantity,
  setQuantity
}: ProductHeroAddonProps) {
  const outOfStockMessage =
    isSellable && renderOutOfStockMessage({ product: product })
  const productName = product.toMaybe().chain(getProductName).orUndefined()

  const { getFormattedPrice } = usePriceContext()
  const formattedPrice = getFormattedPrice(
    safeProp('productId', productAddonData).orJust('')
  )(pricePerUnit)

  return (
    <ProductAddon
      outOfStockMessage={
        outOfStockMessage ? (
          <>
            {productName} {outOfStockMessage}
          </>
        ) : undefined
      }
      price={formattedPrice}
      productPopup={safeProp('modal', productAddonData)
        .map(modalData => (
          <ModalComponent
            clickTarget={<Info forceButtonMode={true} />}
            data={modalData}
            key={prop('id', modalData)}
          />
        ))
        .orUndefined()}
      quantityAfter={productAddonData.description || undefined}
      quantitySelector={
        <QuantityChanger
          defaultValue={quantity}
          label={prop('quantityChangerLabel', productAddonData) || undefined}
          max={productAddonData.maxQuantity || 4}
          min={0}
          onChange={setQuantity}
        />
      }
    />
  )
}

export default ProductHeroAddon
