import { TrackingData } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Price,
  ProductPlan as SSProductPlan
} from '@simplisafe/ss-react-components'
import { Just, Maybe, None } from 'monet'
import pathOr from 'ramda/src/pathOr'
import React from 'react'
import { useSelector } from 'react-redux'

import { ContentfulProductPlan } from '../../../graphql'
import { formatDisplayPrice } from '../../commercetools/price'
import { getMappedComponent } from '../../componentMappings'
import { usePriceContext } from '../../providers/PriceProvider'
import { localizeCents } from '../../providers/PriceProvider/utils'
import { replaceTokens } from '../../util/getTokenizedJson'
import { configureServicePlanTextPlaceholders } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'
import type { MonitoringPlanTypeTokens } from '.'
import { getAddPlanButton, getMonitoringConfirmModal } from '.'

export type ProductPlanProps = {
  readonly item: Partial<ContentfulProductPlan>
  readonly productSku: string
  readonly index: MonitoringPlanTypeTokens
  readonly trackEvent: (_: TrackingData) => void
  readonly offerValue: Maybe<string>
  readonly monthsOfFreeService: string
  readonly packageSku: string
  readonly theme?: string
}

function ProductPlan({
  item,
  productSku,
  index,
  trackEvent,
  offerValue: overrideOfferText,
  monthsOfFreeService,
  packageSku,
  theme
}: ProductPlanProps) {
  // Based on the product plan type, retrieve either the service plan discount
  // offer text or regular offer text.
  const {
    getDiscountedPrice,
    getDiscountedPriceWithServicePlan,
    getDiscountedText,
    getDiscountedTextWithServicePlan,
    getPrice
  } = usePriceContext()
  const locale = useSelector(selectLocale)
  const packagePrice: Maybe<number> = getPrice(packageSku)
  const discountedPrice: Maybe<number> = getDiscountedPrice(packageSku)
  const discountPriceWithPlan: Maybe<number> =
    getDiscountedPriceWithServicePlan(packageSku)

  const discountAmount = packagePrice
    .map(price => price - discountedPrice.getOrElse(0))
    .map(_price => formatDisplayPrice(_price))
    .chain(_price => (_price.isSome() ? _price : None<string>()))
    .getOrElse('')

  const discountAmountWithPlan = packagePrice
    .map(price => price - discountPriceWithPlan.getOrElse(0))
    .map(_price => formatDisplayPrice(_price))
    .chain(_price => (_price.isSome() ? _price : None<string>()))
    .getOrElse('')

  const planIndexes = ['pro premium', 'interactive']

  const defaultOfferText: Maybe<string> = planIndexes.includes(index)
    ? getDiscountedTextWithServicePlan(packageSku)
    : getDiscountedText(packageSku)

  const discountAmountText = planIndexes.includes(index)
    ? discountAmountWithPlan
    : discountAmount

  // Default to defaultOfferText if package does not have a price ex. BMS
  const defaultDiscountAmountText =
    packagePrice.getOrElse(0) === 0
      ? defaultOfferText.getOrElse('')
      : discountAmountText

  const productOfferText: Maybe<string> = overrideOfferText.cata(
    () => defaultOfferText,
    _overrideOfferText => Just(_overrideOfferText)
  )

  // This retrieves the RichText for +{Discount Percentage} off your system today or Save Discount Value today
  const productOffer = (packageSku && prop('productOfferText', item)) || {}
  const productOfferValue = productOfferText.orUndefined()

  const keyFeatures = safeProp('keyFeatures', item).orJust([])

  const relatedInformation = prop('relatedInformation', item)
  const typeButton: string = pathOr('', ['button', 'internal', 'type'], item)
  const button = prop('button', item)
  const titlePlan = prop('title', item) || ''

  // TODO: we should set the theme from CTFL instead of parsing the title
  const productPlanTheme =
    theme && theme !== ''
      ? theme
      : /months? of Interactive Monitoring/i.test(titlePlan)
      ? 'fullWidthBlue'
      : 'fullwidth'

  // TODO: Gatsby 4 rich text -- need to verify that this replaces tokens correctly
  const productOfferWithDiscountAmount =
    productOffer.raw &&
    replaceTokens(
      productOffer.raw,
      '{{discount_amount}}',
      overrideOfferText.orJust(defaultDiscountAmountText)
    )
  const productOfferRichText =
    productOfferWithDiscountAmount &&
    replaceTokens(
      productOfferWithDiscountAmount,
      '{{discount_percentage}}',
      overrideOfferText.orElse(defaultOfferText).orJust('')
    )

  const productOfferContent = productOfferValue && productOfferRichText && (
    <ContentfulRichText raw={productOfferRichText} />
  )

  const planDescriptionValue = item && item?.description
  const planDescription = planDescriptionValue?.raw && (
    <ContentfulRichText raw={planDescriptionValue.raw} />
  )

  // Defines the monitoring price
  const priceRate: string = safeProp('priceRate', item).getOrElse('{price}/day')
  const isPriceRatePerDay = new RegExp(/(day)/gi).test(priceRate)
  const price = Maybe.of(productSku)
    .chain(id => getPrice(id))
    .chain(price =>
      isPriceRatePerDay
        ? Just(localizeCents(locale, price))
        : formatDisplayPrice(price)
    )
    .map(price => priceRate.replace('{price}', price))
    .cata(
      () => null,
      price => <Price regularPrice={price} />
    )

  // TODO: use the value from Contentful (Product Offer Text)
  const extraPriceText =
    theme === 'fullWidthWhite' ? 'after two month free trial' : undefined

  return (
    <SSProductPlan
      button={
        typeButton !== 'ContentfulModal'
          ? // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulButtonContentfulLinkAd... Remove this comment to see the full error message
            button && getAddPlanButton(button)
          : // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulButtonContentfulLinkAd... Remove this comment to see the full error message
            button &&
            getMonitoringConfirmModal(
              button,
              trackEvent,
              titlePlan,
              monthsOfFreeService
            )
      }
      extraPriceText={extraPriceText}
      key={`productplan ${index}`}
      keyFeatures={keyFeatures.map(data => {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        const Component = data && getMappedComponent(data)
        return data
          ? {
              // @ts-expect-error TS(2322) FIXME: Type 'ContentfulIconWithTextContentfulPopupLinkCon... Remove this comment to see the full error message
              clickTarget: Component ? <Component data={data} /> : null,
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              id: prop('id', data)
            }
          : {
              clickTarget: null,
              id: ''
            }
      })}
      planDescription={planDescription}
      price={price}
      productOfferContent={productOfferContent}
      relatedInformation={relatedInformation || ''}
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"standard... Remove this comment to see the full error message
      theme={productPlanTheme}
      title={configureServicePlanTextPlaceholders(
        prop('title', item) || '',
        monthsOfFreeService
      )}
    />
  )
}

export default ProductPlan
