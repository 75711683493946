import { useOptimizelyTrackSiteEvents } from '@lib/tracking'
import propOr from '@simplisafe/ewok/ramda/propOr'
import { IOAddBmsToCart } from '@simplisafe/ss-ecomm-data/cart'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart'
import {
  selectMiniCart,
  selectPackage,
  selectProduct
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { navigate } from 'gatsby'
import { List, Maybe } from 'monet'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { AddToCartErrorType } from '../errorComponents/AddToCartError'
import {
  trackAddToCartEvent,
  trackAddToCartPackageWithExtrasEvent
} from '../util/analytics/addToCart'
import usePriceVariation from './usePriceVariation'

const getPackageItem = (miniCartLineItems: List<MiniCartLineItem>) =>
  miniCartLineItems
    .find(propOr(false, 'isBaseBundle'))
    .map(({ quantity, sku }) => ({
      quantity,
      sku
    }))

const isRedundantForCart = (lineItem: MiniCartLineItem) =>
  lineItem.isBaseBundle || lineItem.isBundleIncluded || false

const useDynamicPackageAddToCart = (
  packageSku: string,
  price: Maybe<number>,
  monitoringPlanSku: string,
  includeMonitoringPlan: boolean
) => {
  const dispatch = useDispatch()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking()
  const select = useSelector((state: ImmutableState) => state)

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [addToCartError, setAddToCartError] = useState<AddToCartErrorType>(null)

  const _package = useSelector(selectPackage(packageSku)).toMaybe()
  const packageProduct = useSelector(selectProduct(packageSku)).toMaybe()
  const lineItems = List.from(useSelector(selectMiniCart).orJust([]))
  const packageItem = getPackageItem(lineItems)
  const cartItems = lineItems.filterNot(isRedundantForCart)

  const servicePlanProduct = usePriceVariation(monitoringPlanSku)

  const onAddToCartClick = (redirectUrl = '/cart') => {
    setAddToCartError(null)

    setIsAddingToCart(true)

    const handleFailure = () => {
      setIsAddingToCart(false)
      setAddToCartError('recoverable')

      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const handleSuccess = () => {
      setIsAddingToCart(false)

      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartPackageWithExtrasEvent(
        _package,
        packageProduct,
        lineItems.toArray(),
        true,
        trackEvent,
        select,
        includeMonitoringPlan,
        undefined,
        price.orJust(0)
      )
      includeMonitoringPlan &&
        trackAddToCartEvent(servicePlanProduct, trackEvent, 1)

      navigate(redirectUrl, { state: { packageSku } })
    }

    const products = cartItems
      .map(({ quantity, sku }) => ({
        quantity,
        sku
      }))
      .toArray()

    packageItem.cata(
      () => {
        setAddToCartError('unrecoverable')
        setIsAddingToCart(false)
        logError(
          Error('Cannot add dynamic package to cart: missing base bundle')
        )
      },
      packageItem => {
        dispatch(
          IOAddBmsToCart(
            {
              package: packageItem,
              products: includeMonitoringPlan
                ? products.concat([
                    {
                      quantity: 1,
                      sku: monitoringPlanSku
                    }
                  ])
                : products
            },
            handleFailure,
            handleSuccess
          )
        )
      }
    )
  }

  return [
    onAddToCartClick,
    isAddingToCart,
    addToCartError,
    cartItems.toArray()
  ] as const
}

export default useDynamicPackageAddToCart
