import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type StreetAddress1Props = {
  readonly label: string
  readonly placeholder: string
}

function StreetAddress1(props: StreetAddress1Props) {
  return (
    <div data-component={'StreetAddress1'}>
      <FormField hideLabel={true} name="streetAddress1">
        <Text fontWeight="medium">{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name="streetAddress1"
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default StreetAddress1
