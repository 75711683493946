import { Address } from '@commercetools/platform-sdk'
import propOr from '@simplisafe/ewok/ramda/propOr'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { abbreviationToName } from '@simplisafe/ss-ecomm-data/commercetools/usStates'
import { PaymentMethodResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  getZuoraMITConsentBrandList,
  getZuoraMITConsentReference,
  splitZuoraCountryList,
  ZuoraClient,
  zuoraCountryCode,
  ZuoraCreditCardFields,
  zuoraLocale,
  ZuoraPaymentResponse,
  ZuoraRenderParameters,
  zuoraSdkUrl
} from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import { Maybe } from 'monet'
import equals from 'ramda/src/equals'
import React, { useEffect, useState } from 'react'

import useScript from '../../../hooks/useScript'
import { PaymentState } from '../usePayment'
import CardVerificationNote from './CardVerificationNote'

type InlineZuoraPaymentFormProps = {
  readonly billingAddress: Maybe<Address>
  readonly cardVerificationNote?: { readonly raw?: string }
  readonly locale: Locale
  readonly onOrderSubmit: (_zuoraResponse: ZuoraPaymentResponse) => void
  readonly onPaymentFormRender: () => void
  readonly paymentMethod: Maybe<PaymentMethodResponse>
  readonly paymentState: PaymentState
  readonly safeTechCollectorSdkUrl: string
  readonly zuoraClient: Maybe<ZuoraClient>
}

function InlineZuoraPaymentComponent(
  props: InlineZuoraPaymentFormProps
): JSX.Element {
  useScript(props.safeTechCollectorSdkUrl)

  const zuoraSdkStatus = useScript(zuoraSdkUrl)

  const [renderedPayment, setRenderedPayment] =
    useState<PaymentMethodResponse>()

  useEffect(() => {
    props.paymentMethod.forEach((paymentMethod: PaymentMethodResponse) => {
      Maybe.fromFalsy(!equals(paymentMethod, renderedPayment)).forEach(() => {
        props.zuoraClient.forEach((zuoraSdk: ZuoraClient) => {
          const zuoraOptions: ZuoraRenderParameters = {
            ...paymentMethod,
            locale: zuoraLocale(props.locale),
            style: 'inline',
            submitEnabled: true
          }

          const onPaymentFormLoad = () => {
            zuoraSdk.setAgreement(
              'External',
              'Recurring',
              getZuoraMITConsentBrandList(props.locale),
              getZuoraMITConsentReference()
            )

            props.onPaymentFormRender()
          }

          const cardFields = formatCreditCardFields(
            props.billingAddress,
            props.locale,
            paymentMethod
          )

          zuoraSdk.setEventHandler<void>('onloadCallback', onPaymentFormLoad)

          zuoraSdk.render(zuoraOptions, cardFields, props.onOrderSubmit)

          setRenderedPayment(paymentMethod)
        })
      })
    })
  }, [props, renderedPayment, setRenderedPayment])

  return zuoraSdkStatus === 'ready' ? (
    <>
      <CardVerificationNote
        cardVerificationNote={props.cardVerificationNote}
        paymentState={props.paymentState}
      />
      <div data-component="zuora-payment" id="zuora_payment" />
    </>
  ) : (
    <></>
  )
}

const formatCreditCardFields = (
  billingAddress: Maybe<Address>,
  locale: Locale,
  paymentMethod: PaymentMethodResponse
): ZuoraCreditCardFields =>
  billingAddress
    .map((address: Address): ZuoraCreditCardFields => {
      const addressValue = (key: keyof Omit<Address, 'custom'>): string =>
        propOr('', key)(address)
      const allowedCountries = splitZuoraCountryList(
        paymentMethod.countryWhiteList
      )

      return {
        creditCardAddress1: addressValue('streetName'),
        creditCardAddress2: addressValue('additionalStreetInfo'),
        creditCardCity: addressValue('city'),
        creditCardCountry: zuoraCountryCode(locale).orJust(allowedCountries[0]),
        creditCardHolderName: `${addressValue('firstName')} ${addressValue(
          'lastName'
        )}`,
        creditCardPostalCode: addressValue('postalCode'),
        creditCardState: abbreviationToName(address.state).orUndefined(),
        email: addressValue('email'),
        phone: addressValue('phone')
      }
    })
    .orJust({})

export default InlineZuoraPaymentComponent
