import { OptimizelyContext } from '@lib/optimizely'
import {
  COOKIE_CHAT_APP_ID,
  cookies,
  getChatAppId,
  getChatOpened,
  useOptimizelyActivateExperiment,
  useOptimizelyTrackSiteEvents,
  visitorIdAtAt
} from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'
import { useCallback, useContext, useEffect, useState } from 'react'

import useLiveChatAvailability from '../../src/hooks/useLiveChatAvailability'
import { PageProps } from '../components/Page/types'

const enableLiveChat = (
  liveChat: boolean,
  bmsLiveChat: boolean,
  isVariation1: boolean,
  chatOpened: string
) => {
  return (
    (liveChat && !bmsLiveChat) ||
    (bmsLiveChat && isVariation1) ||
    chatOpened === 'true'
  )
}

const useEnableLiveChat = (data: PageProps['data']) => {
  const [isInLiveChatExperiment, setIsInLiveChatExperiment] = useState(false)
  const [liveChatVariation, setLiveChatVariation] = useState('')
  const chatIdContentful: string =
    path(['contentfulPage', 'pageLayout', 'liveChatAppId'], data) || ''
  // Enable BMS LiveChat in webConfig
  const bmsLiveChat: boolean =
    path(['contentfulPage', 'pageLayout', 'bmsLiveChatTest'], data) || false
  const hasAvailableAgents: boolean = useLiveChatAvailability(chatIdContentful)
  const isLiveChatVariation1 =
    liveChatVariation === 'variation_1' ? true : false

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { optimizely } = useContext(OptimizelyContext)
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()

  const atatToken = visitorIdAtAt()

  const onActivateCallbackFn = useCallback(() => {
    setIsInLiveChatExperiment(true)
    setLiveChatVariation(
      (optimizely &&
        optimizely.getVariation(
          'all___us_bms___midfunnel___live_chat',
          atatToken
        )) ||
        ''
    )
    // If the user is in any variation within Sales Livechat experiment, push impacted_audience event.
    hasAvailableAgents &&
      optimizelyTrackSiteEvents({ eventType: 'impacted_22704150968' })
  }, [atatToken, hasAvailableAgents, optimizely, optimizelyTrackSiteEvents])

  useEffect(() => {
    bmsLiveChat &&
      optimizelyActivateExperiment(
        { experimentId: 'all___us_bms___midfunnel___live_chat' },
        onActivateCallbackFn
      )
  }, [
    bmsLiveChat,
    hasAvailableAgents,
    onActivateCallbackFn,
    optimizelyActivateExperiment
  ])

  // Enable regular LiveChat on page or the Sales Livechat
  const hasLiveChat: boolean =
    path(['contentfulPage', 'pageLayout', 'hasLiveChat'], data) || false
  const chatOpened: string = getChatOpened()
  const _enableLiveChat = enableLiveChat(
    hasLiveChat,
    bmsLiveChat,
    isLiveChatVariation1,
    chatOpened
  )

  // Get AppId Cookie for Sales LiveChat
  const chatIdCookie: string = getChatAppId()
  const chatId = chatIdCookie || chatIdContentful
  // Set Cookie for Sales LiveChat
  isInLiveChatExperiment &&
    chatOpened === 'true' &&
    cookies.set(COOKIE_CHAT_APP_ID, chatIdContentful, cookiesOption)

  return {
    liveChatEnabled: _enableLiveChat,
    liveChatId: chatId
  }
}

export default useEnableLiveChat
