import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { PopupButton } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import join from 'ramda/src/join'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import React, { FC } from 'react'

import { ContentfulPopupButton } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { toFirstCharLower } from '../../util/helper'
import { stringProp } from '../../util/parseContentfulValues'
import { verifyButtonColor } from '../../util/verifyButtonColor'

type PopupButtonComponentProps = {
  readonly data: ContentfulPopupButton
}

export default function PopupButtonComponent({
  data
}: PopupButtonComponentProps) {
  const buttonType = safeProp('buttonType', data)
    // @ts-expect-error TS(2345) FIXME: Argument of type '(list: readonly unknown[]) => st... Remove this comment to see the full error message
    .map(pipe(toFirstCharLower, split(' '), join('')))
    .orUndefined()
  const buttonColor = buttonType ? verifyButtonColor(buttonType) : undefined

  const popupContent = prop('popupContent', data)
  // TODO: define explicit return type for getMappedComponent
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const PopupComponent =
    popupContent &&
    (getMappedComponent(popupContent) as FC<{ readonly data: unknown }>)
  const buttonPositionList = ['center', 'inline', 'left', 'right'] as const
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code */
  const isButtonPosition = (
    str: any
  ): str is (typeof buttonPositionList)[number] =>
    buttonPositionList.includes(str)
  const buttonPosition = prop('buttonPosition', data)
  const buttonText = stringProp('buttonText', data)

  return (
    <PopupButton
      buttonContent={buttonText}
      buttonPosition={
        buttonPosition && isButtonPosition(buttonPosition)
          ? buttonPosition
          : undefined
      }
      buttonProps={{ color: buttonColor }}
      modalContent={PopupComponent && <PopupComponent data={popupContent} />}
      modalProps={{ appElementId: '___gatsby' }}
    />
  )
}

export const query = graphql`
  #graphql
  fragment contentfulPopupButton on ContentfulPopupButton {
    contentful_id
    id
    internal {
      type
    }
    buttonPosition
    buttonText
    buttonType
    popupContent {
      ... on ContentfulSmallTextSection {
        ...nonCyclicalSmallTextSectionFragment
      }
      ... on ContentfulModalSensorAdditionToSystem {
        ...addExtraSensors
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
    }
  }
`
