import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import { SimpleFooter } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import React from 'react'

import { ContentfulSmallTextSection } from '../../../graphql'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type SimpleFooterComponentProps = {
  readonly id?: string
  // TODO: import fragment from graphql.d.ts
  readonly data: ContentfulSmallTextSection
}

const richTextOptions: Options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: node => {
      const type: string | undefined = node.data.target?.internal?.type

      const linkUrl: string | undefined = node.data?.target?.linkUrl

      const linkText: string | undefined = node.data?.target?.linkText
      return type === 'ContentfulLink' ? (
        <a
          className="text-primary-100 no-underline hover:underline"
          href={linkUrl}
        >
          {linkText}
        </a>
      ) : null
    },
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="my-0 text-white whitespace-pre">{children}</p>
    )
  }
}

function SimpleFooterComponent({ data }: SimpleFooterComponentProps) {
  const isMobile = !useMediaQuery('TabletAndUp')
  return (
    <SimpleFooter
      content={
        <ContentfulRichText
          optionsCustom={richTextOptions}
          raw={isMobile ? data?.descriptionMobile?.raw : data?.description?.raw}
          // @ts-expect-error TS(2322): Type 'Maybe<readonly Maybe<ContentfulAssetContentf... Remove this comment to see the full error message
          references={data?.description?.references}
        />
      }
    />
  )
}

export default SimpleFooterComponent
