import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type EmailProps = {
  readonly emailFieldLabel: string
  readonly emailFieldPlaceholder: string
}

function Email(props: EmailProps) {
  return (
    <div data-component={'Email'}>
      <FormField hideLabel={true} label={props.emailFieldLabel} name="email">
        <Text fontWeight="medium">{props.emailFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          name="email"
          placeholder={props.emailFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Email
