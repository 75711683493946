import { getDeployEnv, noValue } from '@lib/utils'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/leads/capture'
import {
  apiKey,
  brazeUKSubscriptionGroup,
  brazeUSSubscriptionGroup,
  proxyApiUrl
} from '@simplisafe/ss-ecomm-data/secrets/braze'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { window } from 'browser-monads-ts'
import { get, set } from 'local-storage'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'

import { cookies } from '../cookies'
import {
  COOKIE_BRAZE_PROMO_VIEWED,
  COOKIE_BRAZE_SITE_VISITED,
  COOKIE_LEAD_DATA,
  getLeadData,
  getUserId,
  getVisitorId
} from '../cookies'
import { BrazeProps } from '../types/braze'
import { BrazeQuoteWizardResponse, Ecommerce, Product } from '../types/tracking'
import {
  convertQueryParamsToObject,
  generateDynamicPackagePageURL
} from './lib'

export type BrazeLineItem = {
  readonly name: string
  readonly sku: string
  readonly quantity: number
}

const LOCAL_STORAGE_EMAIL_PROMO = 'email_promo'

// Braze need a proper format for SMS group messages
export const addCountryCodeToPhoneNumber = (
  phoneNumber: string,
  countryCode = process.env['LOCALE'] === 'en-GB' ? '44' : '1'
) =>
  phoneNumber &&
  !phoneNumber.startsWith('+') &&
  !phoneNumber.startsWith(`${countryCode}`)
    ? `+${countryCode}${phoneNumber}`
    : phoneNumber

// initialize Braze Web SDK
export const initBraze = () => {
  window.braze &&
    (window.braze.initialize(apiKey, { baseUrl: proxyApiUrl }),
    window.braze.automaticallyShowInAppMessages(),
    // optionally set the current user's External ID
    window.location.hash.includes('braze_eid')
      ? setUserIdOnInit(window.braze)
      : null)
}

// Set User ID from URL hash if available
const setUserIdOnInit = (braze: BrazeProps) => {
  const windowUrl: string = path<string>(['location', 'href'], window) || ''
  const match: readonly string[] = windowUrl.match(
    /#braze_eid=[A-Za-z0-9]+/
  ) || ['']
  const brazeEid: string = match.toString().split('=')[1]
  braze.openSession()
  braze.changeUser(brazeEid)
  set('braze_eid', brazeEid)
}

// TODO: add type for attributesMapping
export const brazeSetCustomUserAttributes = (
  attributesMapping: Record<string, boolean | string> = {}
) => {
  const braze = window.braze
  braze &&
    Object.keys(attributesMapping).forEach(key => {
      braze.getUser().setCustomUserAttribute(key, attributesMapping[key])
    })
}

// Braze events need to fire up only if Braze EID is set in local storage or if Lead Data cookie is set
// If a lead data cookie is set, it will contain a Leads Email. It also gets set when handleBrazeTrackingEvent() is fired up
// If handleBrazeTrackingEvent() fired up, User was captured in Braze
const shouldTrackUserEvent = (): boolean => {
  const brazeSessionId = get('braze_eid') || null
  const leadData = getLeadData()
  return (
    window.braze !== noValue() &&
    (isNotNil(leadData) || isNotNil(brazeSessionId))
  )
}

export const brazeLogCustomEvent = (
  eventId: string,
  properties?: Record<string, unknown>
) => {
  shouldTrackUserEvent() && window.braze.logCustomEvent(eventId, properties)
}

// Fires up when a leads form is submitted
export const handleBrazeTrackingEvent = (data?: LeadGenCaptureResponse) => {
  !cookies.get(COOKIE_LEAD_DATA) &&
    cookies.set(COOKIE_LEAD_DATA, data, cookiesOption)
  window.braze && updateLeadsData(data)
}

const updateLeadsData = (data?: LeadGenCaptureResponse) => {
  const leadID: string = safeProp('leadId', data).getOrElse('')
  const email: string = safeProp('email', data).getOrElse('')
  const externalID: string = safeProp('externalId', data).getOrElse('')
  const leadSource: string = safeProp('leadSource', data).getOrElse('')
  window.braze.openSession()

  window.braze.getUser().getUserId(function (brazeUserId) {
    const userId = externalID || `L${leadID}`
    isNil(brazeUserId) ? setUserId(leadSource, userId) : null
  })

  brazeSetCustomUserAttributes({
    'External ID': externalID,
    'Last Lead ID': leadID,
    'Last Lead Source': leadSource.replace(/[^a-zA-Z0-9]/g, ' ')
  })

  window.braze.getUser().addAlias('simplisafe_lead_id', leadID)
  window.braze.getUser().setEmail(email)
  window.braze.getUser().setCountry(getCountryValue())
}

const getCountryValue = () => {
  // Safari returns 'en' for US
  const getBrowserLanguage = (browserLanguage: string): string =>
    browserLanguage === 'en' ? 'en-us' : browserLanguage
  // Use country value from navigator language. If undefined, use the value from env variable
  const siteLanguage =
    typeof window.navigator !== 'undefined'
      ? getBrowserLanguage(window.navigator.language.toLowerCase())
      : process.env['LOCALE']
  return siteLanguage ? siteLanguage.split('-')[1].toUpperCase() : null
}

const setUserId = (leadSource: string, userId: string) => {
  const properties = { lead_source: leadSource }
  brazeLogCustomEvent('lead_created', properties)
  window.braze.changeUser(userId)
}

// Tracks page visit
export const brazeTrackPageVisit = () =>
  shouldTrackUserEvent() && pageVisitTrackingEvent()

const pageVisitTrackingEvent = () => {
  const utmParams = getUTMParams()
  setEmailSessionStorageValues(utmParams)
  !cookies.get(COOKIE_BRAZE_SITE_VISITED) &&
    window.braze.logCustomEvent('site_visited', utmParams)
  cookies.set(COOKIE_BRAZE_SITE_VISITED, true)
  setEmailAddressFromLeadDataCookie()
  logPageVisited()
}

const setEmailSessionStorageValues = (utmParams: Record<string, string>) => {
  const emailPromoLocalStorage = get(LOCAL_STORAGE_EMAIL_PROMO) || null
  const setEmailPromo: boolean =
    isNotNil(utmParams['utm_code']) && isNil(emailPromoLocalStorage)
  const utmSource: string = prop<string>('utm_source', utmParams) || ''
  const utmMedium: string = prop<string>('utm_medium', utmParams) || ''
  const isEmailUrl: boolean =
    equals('lead_email', utmSource) && equals('email', utmMedium)
  const captureEmailVisit: boolean = isEmailUrl && isNil(emailPromoLocalStorage)
  const captureEmailUser: boolean =
    isEmailUrl && isNotNil(emailPromoLocalStorage)

  setEmailPromo && set(LOCAL_STORAGE_EMAIL_PROMO, utmParams['utm_code'])
  captureEmailVisit && set('email_visit', 'true')
  captureEmailUser && set('email_user', 'true')
}

// returns an object containing each UTM parameter in page URL
const getUTMParams = () => {
  const locationSearch: string = getWindowLocationValue('search')
  return locationSearch
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj: Record<string, string>, pair) => {
      const [key, value] = pair.map(decodeURIComponent)
      return { ...obj, [key]: value }
    }, {})
}

// Capture pages visited events
const logPageVisited = () => {
  const pageUrl: string = getWindowLocationValue('href')
  const urlHash: string = getWindowLocationValue('hash')

  const cartPage: boolean =
    pageUrl.includes('cart') && !pageUrl.includes('checkout')
  const checkoutShippingPage: boolean =
    pageUrl.includes('cart/checkout') && isEmpty(urlHash)

  const urlEventIdMapping: { [key: string]: string } = {
    'build-my-system': 'visit_bms',
    'choose-monitoring': 'visit_choose_monitoring',
    'contact-us': 'visit_contact_us',
    feature: 'visit_features',
    'home-security-shop': 'visit_shop',
    'home-security-system-': 'visit_package',
    reviews: 'visit_reviews'
  }

  Object.keys(urlEventIdMapping).forEach(
    key =>
      pageUrl.includes(key) &&
      window.braze.logCustomEvent(urlEventIdMapping[key])
  )
  cartPage && window.braze.logCustomEvent('visit_cart')
  checkoutShippingPage && window.braze.logCustomEvent('visit_checkout_shipping')
}

// Capture answers submitted in Quote Wizard
export const brazeTrackQuoteWizardSubmission = (
  responses: readonly BrazeQuoteWizardResponse[],
  qwWithRecs = false
) => {
  updateBrazeLeadCustomData()
  const eventId = `qw_${qwWithRecs ? 'recs_' : ''}complete`
  brazeLogCustomEvent(eventId)
  window.braze &&
    responses
      .filter(
        response => isNotNil(response.question) && isNotNil(response.answer)
      )
      .forEach(response =>
        window.braze
          .getUser()
          .setCustomUserAttribute(
            safeProp('question', response).getOrElse(''),
            safeProp('answer', response).getOrElse('')
          )
      )
}

export const brazeTrackGuidedSystemBuilderRecommendation = (
  attributeHash: string,
  guidedSystemBuilderQueryParams: string
) => {
  const eventId = 'qw_recs_complete'
  const queryParams = convertQueryParamsToObject(guidedSystemBuilderQueryParams)
  updateBrazeLeadCustomData()
  brazeLogCustomEvent(eventId, queryParams)
  window.braze &&
    window.braze
      .getUser()
      .setCustomUserAttribute(
        'QW_RECS_URL',
        generateDynamicPackagePageURL(attributeHash)
      )
}

// Capture whether a user saw the promo
export const brazeTrackPromoView = () => {
  const logPromoViewedEvent: boolean =
    shouldTrackUserEvent() && !cookies.get(COOKIE_BRAZE_PROMO_VIEWED)
  logPromoViewedEvent &&
    brazeLogCustomEvent('promo_viewed', {
      channel: attributeChannel(),
      promo_offer_seen: true
    })
  shouldTrackUserEvent() && cookies.set(COOKIE_BRAZE_PROMO_VIEWED, true)
  updateBrazeLeadCustomData()
}

export const updateBrazeLeadCustomData = () => {
  const leadId: string = getLeadDataCookieValue('leadId')
  !isEmpty(leadId) && window.braze ? setEventUserAttributes() : null
}

// Track which products are added to cart and removed from cart
export const brazeTrackCartEvent = (eventData: {
  readonly eventType: 'add_to_cart' | 'remove_from_cart'
  readonly productTrackingData: Ecommerce
}) => {
  const eventType: string = prop('eventType', eventData)
  const actionTypeKey: string = eventType.substring(0, eventType.indexOf('_'))

  const productData: Product =
    path<Product>(
      ['productTrackingData', actionTypeKey, 'products', '0'],
      eventData
    ) || {}
  const properties = {
    channel: attributeChannel(),
    env: getWindowLocationValue('hostname'),
    id: prop<string>('id', productData) || '',
    name: prop<string>('name', productData) || '',
    price: prop<number>('price', productData) || 0,
    quantity: prop<number>('quantity', productData) || 1
  }

  window.braze &&
    (window.braze.openSession(),
    brazeLogCustomEvent(eventType, properties),
    window.braze.requestImmediateDataFlush())
}

// Purchase data capturing
export const brazeTrackPurchaseComplete = (eventData: {
  readonly orderId: string
  readonly systemInOrder?: boolean
  readonly phoneNumber: string
  readonly products: readonly Product[]
  readonly currencyCode: string // it is passed in as string type from where it's coming from in ss-ecomm-frontend
}) => {
  const userId: string = getUserId() || ''
  const orderId: string = prop('orderId', eventData)
  const currencyCode: string = prop('currencyCode', eventData)
  const products = prop('products', eventData)
  const phoneNumber = prop('phoneNumber', eventData)
  const systemInOrder: boolean = safeProp('systemInOrder', eventData).getOrElse(
    false
  )

  brazeSetCustomUserAttributes({
    'Last Lead Source': 'purchase',
    'Last Order ID': orderId,
    'Last User ID': userId,
    phone_number: phoneNumber
  })

  window.braze &&
    (setEventUserAttributes(),
    isNotEmpty(userId) &&
      window.braze.getUser().addAlias(userId, 'simplisafe_user_id'),
    setEmailAddressFromLeadDataCookie(),
    setPhoneNumber(phoneNumber),
    addUserToSubscriptionGroup())

  const logPurchase = (sku: string, price: number, quantity = 1) =>
    window.braze &&
    window.braze.logPurchase(sku, price, currencyCode, quantity, {
      order_id: orderId
    })

  products.forEach(product => {
    const sku: string = prop<string>('id', product) || ''
    const price: number = prop<number>('price', product) || 0
    const quantity: number = prop<number>('quantity', product) || 1
    logPurchase(sku, price, quantity)
    // if it has an underscore, strip and log that too; see ECP-1838
    sku.includes('_') &&
      logPurchase(sku.substr(0, sku.indexOf('_')), price, quantity)
  })

  systemInOrder && logPurchase('SSCS3', 0)

  const properties = {
    order_id: orderId,
    user_id: userId
  }

  brazeLogCustomEvent('purchase_complete', properties)
  brazeLogCustomEvent('checkout', properties)
  window.braze && window.braze.requestImmediateDataFlush()
}

export const brazeTrackCartDetails = (eventData: {
  readonly cartId: string
  readonly cartTotal: string
  readonly coupon: string
  readonly discountedCartTotal: string
  readonly products: readonly BrazeLineItem[]
}) => {
  const properties = {
    last_cart_components: prop('products', eventData),
    last_cart_coupon: prop('coupon', eventData),
    last_cart_id: prop('cartId', eventData),
    last_cart_total: prop('cartTotal', eventData),
    last_discounted_cart_value: prop('discountedCartTotal', eventData)
  }
  brazeLogCustomEvent('cart_details', properties)
}

const setEventUserAttributes = () =>
  brazeSetCustomUserAttributes({
    Channel: attributeChannel(),
    Environment: getWindowLocationValue('hostname'),
    page_path: getWindowLocationValue('path'),
    vid: getVisitorId()
  })

const setEmailAddressFromLeadDataCookie = () => {
  const emailAddress: string = getLeadDataCookieValue('email')
  window.braze.getUser().setEmail(emailAddress)
}

export const setPhoneNumber = (phoneNumber: string) =>
  window.braze
    .getUser()
    .setPhoneNumber(addCountryCodeToPhoneNumber(phoneNumber))

const addUserToSubscriptionGroup = () =>
  window.braze
    .getUser()
    .addToSubscriptionGroup(
      process.env['LOCALE'] === 'en-GB'
        ? brazeUKSubscriptionGroup
        : brazeUSSubscriptionGroup
    )

export const addUserToSMSSubscriptionGroup = () =>
  window.braze
    .getUser()
    .addToSubscriptionGroup(
      getDeployEnv() === 'prd'
        ? '80c94793-df1f-43ff-aabd-120d3561d0ff'
        : 'be20f594-fd9a-4816-8698-400fa644d05e'
    ) //Marketing sms subscription group id

const getWindowLocationValue = (key: string) =>
  path<string>(['location', key], window) || ''

const getLeadDataCookieValue = (key: string): string => {
  // TODO: add leadData type in ss-ecomm-data
  const leadDataObj = getLeadData() || {}
  return prop<string>(key, leadDataObj) || ''
}

const attributeChannel = () =>
  `FCP ${process.env['LOCALE'] === 'en-GB' ? 'UK' : 'US'} Site`
