import {
  Block,
  BLOCKS,
  Inline,
  INLINES,
  MARKS
} from '@contentful/rich-text-types'
import { getYoutubeEmbedUrl, isYoutubeVideo } from '@lib/utils'
import { Divider, SSButton } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import { Link } from 'gatsby'
import pathOr from 'ramda/src/pathOr'
import React, { ReactNode } from 'react'

import { getEmbeddedAssetComponent } from './embeddedAssets'
import {
  renderEmbeddedBlockComponent,
  renderEmbeddedEntry,
  renderModalVideoHyperlink
} from './embeddedEntries'

export const defaultRenderBlockNode: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_, children) =>
    children && (
      <p className={classNames('paragraph whitespace-pre-line')}>{children}</p>
    ),
  [BLOCKS.HEADING_1]: (_, children) =>
    children && <h1 className={classNames('h1')}>{children}</h1>,
  [BLOCKS.HEADING_2]: (_, children) =>
    children && <h2 className={classNames('h2')}>{children}</h2>,
  [BLOCKS.HEADING_3]: (_, children) =>
    children && <h3 className={classNames('h3')}>{children}</h3>,
  [BLOCKS.HEADING_4]: (_, children) =>
    children && <h4 className={classNames('h4')}>{children}</h4>,
  [BLOCKS.HEADING_5]: (_, children) =>
    children && <h5 className={classNames('h5')}>{children}</h5>,
  [BLOCKS.UL_LIST]: (_, children) =>
    children && <ul className={classNames('ul')}>{children}</ul>,
  [BLOCKS.HR]: () => <Divider />,
  [BLOCKS.EMBEDDED_ENTRY]: node => renderEmbeddedBlockComponent(node),
  [BLOCKS.EMBEDDED_ASSET]: node => getEmbeddedAssetComponent(node)
}

export const defaultRenderInlineNode = (
  onLinkClick?: (
    _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    _url: string
  ) => void
): RenderNode => ({
  [INLINES.HYPERLINK]: (node, children) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const uri: string = node?.data?.uri || ''
    const className = 'underline text-inherit hover:no-underline'

    const isGatsbyLink = uri.startsWith('/')
    const isExternalLink =
      uri.startsWith('http') ||
      uri.startsWith('tel:') ||
      uri.startsWith('mailto:')
    const isYoutubeLink = isYoutubeVideo(uri)
    const youtubeTrigger = (
      <SSButton
        color="link"
        style={{
          minHeight: 0,
          padding: 0
        }}
        type="button"
      >
        {children}
      </SSButton>
    )

    const onClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      onLinkClick && onLinkClick(event, uri)
    }

    return children ? (
      isGatsbyLink ? (
        <Link className={className} onClick={onClick} to={uri}>
          {children}
        </Link>
      ) : isYoutubeLink ? (
        renderModalVideoHyperlink(
          pathOr<string, string>('', ['0'], children),
          getYoutubeEmbedUrl(uri, true),
          youtubeTrigger
        )
      ) : isExternalLink ? (
        <a className={className} href={uri} onClick={onClick}>
          {children}
        </a>
      ) : (
        <a className={className}>
          INLINES.HYPERLINK - {JSON.stringify(node)} {children}
        </a>
      )
    ) : null
  },
  [INLINES.ENTRY_HYPERLINK]: (node, children) =>
    // TODO: Gatsby 4 rich text: this is placeholder behavior; need to implement this. Do we have any current usage of this? Do we need any special handling here?
    children && (
      <span>
        INLINES.ENTRY_HYPERLINK - {JSON.stringify(node)} {children}
      </span>
    ),
  [INLINES.ASSET_HYPERLINK]: (node, children) => {
    // TODO: Gatsby 4 rich text: this is placeholder behavior; need to implement this. Do we have any current usage of this? Do we need any special handling here?
    // Do we need to update renderAssetHyperlink (see deprecated frontend RichText component) and use that here?
    return (
      <span>
        INLINES.ASSET_HYPERLINK - {JSON.stringify(node)} {children}
      </span>
    )
  },
  [INLINES.EMBEDDED_ENTRY]: (node, children) => {
    return (
      renderEmbeddedEntry(node) || (
        <span>
          INLINES.EMBEDDED_ENTRY - {JSON.stringify(node)} {children}
        </span>
      )
    )
  }
})

export const defaultRenderMark: RenderMark = {
  [MARKS.BOLD]: text => <b>{text}</b>,
  [MARKS.ITALIC]: text => <i>{text}</i>,
  [MARKS.UNDERLINE]: text => <u>{text}</u>,
  // Ported from ss-react-components/RichText for backwards compatibility.
  // @ts-expect-error TS(2322): Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message

  [MARKS.CODE]: text => <span dangerouslySetInnerHTML={{ __html: text }} />,
  [MARKS.SUPERSCRIPT]: text => <sup>{text}</sup>,
  [MARKS.SUBSCRIPT]: text => <sub>{text}</sub>
}

export type NodeRenderer = (
  node: Block | Inline,
  children: ReactNode
) => ReactNode

export type RenderNode = {
  readonly [k: string]: NodeRenderer
}

export type RenderMark = {
  readonly [k: string]: (text: ReactNode) => ReactNode
}
