import { z } from 'zod'

const promoBannerSchema = z.object({
  buttonBackgroundColor: z.string(),
  buttonTextColor: z.string(),
  discountText: z.string(),
  displayEndTimeCalloutOverride: z.boolean().default(false),
  endTime: z.string(),
  endTimeCalloutOverride: z.string(),
  hasCountdown: z.boolean().default(false),
  hasEmailInput: z.boolean().default(false),
  hasEndDateCallout: z.boolean().default(false),
  hasFreeGiftItem: z.boolean().default(false),
  hasSaleName: z.boolean().default(false),
  lineItemLabel: z.string(),
  lineItemTitle: z.string(),
  locale: z.string(),
  offerLabel: z.string(),
  offerTitle: z.string(),
  saleName: z.string()
})

export type PromoBannerSchema = z.TypeOf<typeof promoBannerSchema>

export default promoBannerSchema
