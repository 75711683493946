import path from '@simplisafe/ewok/ramda/path'
import { safeProp } from '@simplisafe/monda'
import { ComparisonTableRows } from '@simplisafe/ss-react-components'
import { Maybe } from 'monet'
import React from 'react'

import {
  ContentfulExpandableMonitoringPlan,
  ContentfulPlanComparisonTable,
  ContentfulPlanComparisonTableRow,
  ContentfulProductPlanContentfulRichTextWithOptionsUnion
} from '../../../graphql'
import ComparisonTableRowComponent from '../ComparisonTableRowComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import PlanButton from './PlanButton'

const renderCompareTable = (
  data: Partial<ContentfulExpandableMonitoringPlan>
) => {
  const planComparisonTable: Maybe<ContentfulPlanComparisonTable> =
    Maybe.fromNull(path(['planComparisonTable'], data))
  const dataComponent = planComparisonTable
    .chain(value => safeProp('title', value))
    .getOrElse('compare-table')
  const headers = planComparisonTable
    .chain(value => safeProp('tableHeaders', value))
    .getOrElse([''])
  const footers = planComparisonTable
    .chain(value => safeProp('tableFooters', value))
    .getOrElse([''])
  const footerPlan = planComparisonTable
    .chain(value => safeProp('planTableFooters', value))
    .getOrElse([])
  const rowsList = planComparisonTable
    .chain(value => safeProp('tableRows', value))
    .getOrElse([null])

  return (
    <ComparisonTableRows
      dataComponent={dataComponent}
      footerPlan={footerPlan
        .filter(
          (
            val
          ): val is ContentfulProductPlanContentfulRichTextWithOptionsUnion =>
            !!val
        )
        .map(plan => {
          // @ts-expect-error TS(2345) FIXME: Argument of type '"title"' is not assignable to pa... Remove this comment to see the full error message
          const titlePlan = safeProp('title', plan).getOrElse('')
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          const getType = path(['internal', 'type'], plan)
          return getType === 'ContentfulRichTextWithOptions' ? (
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulProductPlanContentfulRichTextWithO... Remove this comment to see the full error message
            <RichTextWithOptionsComponent data={plan} />
          ) : (
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulProductPlanContentfulRichTextWithO... Remove this comment to see the full error message
            <PlanButton data={plan} titlePlan={titlePlan} />
          )
        })}
      footers={footers.filter((str): str is string => !!str)}
      headers={headers.filter((str): str is string => !!str)}
      key={'id-compare-table'}
      rowsList={
        rowsList &&
        rowsList
          .filter((val): val is ContentfulPlanComparisonTableRow => !!val)
          .map((row, i) => {
            return <ComparisonTableRowComponent data={row} key={`row-${i}`} />
          })
      }
    />
  )
}

export default renderCompareTable
