import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import {
  selectActivePromoDiscountTextWithOverrides,
  selectActivePromoEndTime,
  selectLocale,
  selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, Row, Text } from '@simplisafe/ss-react-components'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import { None } from 'monet'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  LeadCaptureForm,
  LeadCaptureFormProps,
  LeadCaptureFormPropsOnSubmit
} from '../LeadCaptureForm'
import { SimpliSafeCSSProperties } from '../types/SimpliSafeCSSProperties'
import type { ActivePromoBannerType } from '.'
import { noValue } from '@lib/utils'

export type MobileAltLayoutProps = {
  readonly isFormSubmitted: boolean
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
  readonly type: ActivePromoBannerType
}

const CountdownMobile: FC = () => {
  const bannerProps = useSelector(selectPromotionBanner)
  const endTime = useSelector(selectActivePromoEndTime).map(
    _endTime => new Date(_endTime)
  )
  const maybeHasCountdown = bannerProps.chain(safeProp('hasCountdown'))

  return maybeHasCountdown
    .chain(hasCountdown => (hasCountdown ? endTime : None<Date>()))
    .cata<JSX.Element | null>(
      () => null,
      date => (
        <Column justifySelf="center" rounded="none" spans={[12]}>
          <Text
            inheritTextColor={true}
            textAlignment="center"
            textSize="md"
            useTailwind={true}
          >
            <Promo.Countdown endTime={date} />
          </Text>
        </Column>
      )
    )
}

function TitleMobile() {
  const locale = useSelector(selectLocale)
  const endTime = useSelector(selectActivePromoEndTime)
    .map(_endTime => new Date(_endTime))
    .orUndefined()
  const bannerProps = useSelector(selectPromotionBanner)
  const bannerTitle = bannerProps.chain(chainProp('saleName')).getOrElse('')
  const hasEndDateCallout = bannerProps
    .chain(safeProp('hasEndDateCallout'))
    .getOrElse(false)
  const hasSaleName = bannerProps
    .chain(safeProp('hasSaleName'))
    .getOrElse(false)
  const endTimeValue = hasEndDateCallout && endTime ? endTime : noValue()
  const title = hasSaleName && bannerTitle ? bannerTitle : noValue()

  return (hasSaleName && bannerTitle) || (hasEndDateCallout && endTime) ? (
    <Column justifySelf="center" rounded="none" spans={[12]}>
      <Text
        fontWeight="medium"
        inheritTextColor={true}
        textAlignment={'center'}
        textSize={'md'}
        useTailwind={true}
      >
        <Promo.PromoTitle
          endDate={endTimeValue}
          lineBreak={true}
          locale={locale}
          title={title}
        />
      </Text>
    </Column>
  ) : null
}

function OfferMobile() {
  const discountText = useSelector(
    selectActivePromoDiscountTextWithOverrides
  ).getOrElse('')
  const bannerProps = useSelector(selectPromotionBanner)
  const lineItemTitle = bannerProps
    .chain(chainProp('freeGiftItemPrimaryText'))
    .orUndefined()
  const offerLabel = bannerProps
    .chain(chainProp('discountSecondaryText'))
    .orUndefined()
  const hasFreeGiftItem = bannerProps
    .chain(safeProp('hasFreeGiftItem'))
    .getOrElse(false)
  return discountText ? (
    <Column justifySelf="center" rounded="none" spans={[12]}>
      <Text
        fontWeight="medium"
        inheritTextColor={true}
        textAlignment={'center'}
        textSize={'xl'}
        useTailwind={true}
      >
        <Promo.OfferText
          hasBorder={false}
          lineBreak={false}
          lineItemTitle={
            hasFreeGiftItem && lineItemTitle ? lineItemTitle : noValue()
          }
          offerLabel={offerLabel}
          offerTitle={`${discountText} off`}
        />
      </Text>
    </Column>
  ) : null
}

type LeadGenMobileProps = Pick<
  LeadCaptureFormProps,
  'isSubmitted' | 'onSubmit' | 'showCta'
>
function LeadGenMobile({ isSubmitted, onSubmit, showCta }: LeadGenMobileProps) {
  const bannerProps = useSelector(selectPromotionBanner)
  const hasForm = bannerProps.chain(safeProp('hasEmailInput')).getOrElse(false)
  const buttonBackgroundColor = bannerProps
    .chain(chainProp('buttonBackgroundColor'))
    .orUndefined()
  const buttonTextColor = bannerProps
    .chain(chainProp('buttonTextColor'))
    .orUndefined()
  const buttonStyles: SimpliSafeCSSProperties = {
    '--btn-accent': buttonTextColor,
    '--btn-primary': buttonBackgroundColor,
    '--btn-primary-light': buttonBackgroundColor
  }

  return hasForm ? (
    <Column
      dataComponent="MobileLeadCaptureForm"
      justifySelf="center"
      rounded="none"
      spans={[12]}
    >
      <Text inheritTextColor={true} textSize="sm" useTailwind={true}>
        <div style={{ marginTop: '8px' }}>
          <LeadCaptureForm
            buttonStyles={buttonStyles}
            ctaButtonHref="/home-security-shop"
            ctaButtonText="Shop now"
            isSubmitted={isSubmitted}
            label="Enter your email"
            legal={
              <span>
                You may receive email offers from us in accordance with our{' '}
                <a href="/privacy-policy">
                  <strong>Privacy Policy</strong>
                </a>
                .
              </span>
            }
            onSubmit={onSubmit}
            placeholder="Enter your email"
            showCta={showCta}
            style="full"
            submitButtonText="Get offer"
            successMessage="Thank you. Your code will automatically apply at checkout."
          />
        </div>
      </Text>
    </Column>
  ) : null
}

function MobileAltLayout({
  isFormSubmitted,
  onEmailSubmit,
  type
}: MobileAltLayoutProps) {
  return (
    <Row
      dataComponent="MobileAltLayout"
      gap="none"
      inheritTextColor={true}
      rounded="none"
    >
      <TitleMobile />
      <CountdownMobile />
      <OfferMobile />
      <LeadGenMobile
        isSubmitted={isFormSubmitted}
        onSubmit={onEmailSubmit}
        showCta={type === 'standard'}
      />
    </Row>
  )
}

export default MobileAltLayout
