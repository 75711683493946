import path from '@simplisafe/ewok/ramda/path'
import { safeProp } from '@simplisafe/monda'
import { BuildMySystemHeroItem } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import React from 'react'
import { useTracking } from 'react-tracking'

import RichText from '../../../../components/src/ContentfulRichText/index'
import { ContentfulBmsHeroItem } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { SimpleBannerComponentProps } from '../SimpleBannerComponent/types'

type ContentfulBmsHeroItemProps = {
  readonly data: Partial<ContentfulBmsHeroItem>
}

export default function BmsHeroItem({ data }: ContentfulBmsHeroItemProps) {
  const detailsModalContent = safeProp(
    'detailsModalContent',
    data
  ).orUndefined()
  const { trackEvent } = useTracking()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackEventBasePlanTooltip = (trackEvent: any, title: any) => {
    trackEvent({
      action: 'tooltip',
      category: 'bms',
      event: 'clickTooltip',

      label: title,
      site: 'fcp'
    })
  }
  const DetailsModalComponent = detailsModalContent
    ? getMappedComponent<SimpleBannerComponentProps>(detailsModalContent)
    : undefined
  const description = safeProp('description', data).getOrElse({})

  return (
    <BuildMySystemHeroItem
      description={description.raw && <RichText raw={description.raw} />}
      detailsModalContent={
        DetailsModalComponent && (
          <DetailsModalComponent data={detailsModalContent} id="" />
        )
      }
      image={
        <BgImage
          alt={path(['icon', 'description'], data) || ''}
          // @ts-expect-error TS(2345): Argument of type 'Maybe<ContentfulAsset> | undefin... Remove this comment to see the full error message
          image={getImage(data.icon)}
          // @ts-expect-error TS(2322): Type '{ alt: string; image: IGatsbyImageData | undefin... Remove this comment to see the full error message
          style={{
            backgroundSize: 'contain',
            height: '100%',
            width: '100%'
          }}
        />
      }
      onClick={() =>
        trackEventBasePlanTooltip(
          trackEvent,
          safeProp('displayTitle', data).getOrElse('')
        )
      }
      title={safeProp('displayTitle', data).getOrElse('')}
    />
  )
}

export const query = graphql`
  #graphql
  fragment contentfulBmsHeroItemFragment on ContentfulBmsHeroItem {
    id
    internal {
      type
    }
    displayTitle
    description {
      raw
    }
    icon {
      gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      title
      #   description # TODO: get description from gatsbyImageData
    }
    detailsModalContent {
      ... on ContentfulBanner {
        ...contentfulBanner
      }
    }
  }
`
