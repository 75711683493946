import {
  OptimizelyContext,
  ReactSDKClient,
  useExperiment
} from '@lib/optimizely'
import {
  get as sessionStorageGet,
  getDeployEnv,
  getLocale,
  set as sessionStorageSet
} from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import leadingSlashIt from '@simplisafe/ewok/string-utils/leadingSlashIt'
import untrailingSlashIt from '@simplisafe/ewok/string-utils/untrailingSlashIt'
import { safePath, safeProp } from '@simplisafe/monda'
import {
  activateOptimizelyExperiment,
  optimizelyTrackEvent
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { UserAttributes } from '@simplisafe/ss-ecomm-data/simplisafe/optimizely'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import isEmpty from 'ramda/src/isEmpty'
import pathOr from 'ramda/src/pathOr'
import rTest from 'ramda/src/test'
import { useCallback, useContext } from 'react'
import { UAParser } from 'ua-parser-js'
import Cookies from 'universal-cookie'

import { visitorIdAtAt } from '../atat'

const cookies = new Cookies()
const userAgentParser = new UAParser()
const userAgentData = userAgentParser.getResult()

const SESSION_STORAGE_FIRST_SEEN_URL = 'firstSeenUrl'

const getFirstSeenUrl = (vid?: string) => {
  const firstSeenUrl = sessionStorageGet(SESSION_STORAGE_FIRST_SEEN_URL)
  const vidToFirstSeenUrl: Record<string, string> = firstSeenUrl
    ? JSON.parse(firstSeenUrl)
    : {}
  return prop(vid, vidToFirstSeenUrl) || null
}

export const updateFirstSeenUrl = (vid: string) => {
  const hasFirstSeenUrl = getFirstSeenUrl(vid)
  !hasFirstSeenUrl &&
    sessionStorageSet(
      SESSION_STORAGE_FIRST_SEEN_URL,
      JSON.stringify({ [vid]: window.location.pathname })
    )
}

export const userAttributes = (): UserAttributes => ({
  device: safePath(['device', 'type'], userAgentData).getOrElse('desktop'), // UAParser doesn't return type for desktop devices, so defaulting it to 'desktop' if there is no value for type
  environment: getDeployEnv() === 'prd' ? 'production' : 'development',
  firstSeenUrl: getFirstSeenUrl(visitorIdAtAt()) || window.location.pathname,
  geoLocation: getLocale(),
  isLoggedIn: isNotNil(cookies.get('auth0.is.authenticated')),
  platform: 'fcp',
  userAgent: safePath(['browser', 'name'], userAgentData).orNull()
})

export type OptimizelyEvent = {
  readonly eventKey?: string | undefined
  // any event added here needs to be created in Optimizely Full Stack UI too
  readonly eventType?:
    | 'add_to_cart_clicked'
    | 'add_to_mini_cart_clicked'
    | 'auto_activation'
    | 'click_affirm_learn_more'
    | 'click_sprinklr_live_chat'
    | 'impacted_22146810067'
    | 'impacted_22704150968'
    | 'impacted_22778431687'
    | 'impacted_22878822366'
    | 'impacted_23160010080'
    | 'lead_captured_fs'
    | 'quote_wizard_complete_fs'
    | 'saved_system'
    | 'select_affirm_in_checkout'
    | 'website_error'
  readonly pageUrl?: string | undefined
  readonly revenue?: number | undefined
  readonly value?: number | string | undefined
}

type OptimizelyExperiment = {
  readonly experimentId: string
  readonly variationId?: string | undefined
}

export type OptimizelyPurchaseEvent = {
  readonly price?: number | undefined
  readonly productType?: string | undefined
  readonly qty?: number | undefined
  readonly sku: string | undefined
}

// TODO we need a way to map URLs to page type
const packagePagesList = [
  '/build-my-system',
  '/home-security-system-bamburgh',
  '/home-security-system-windsor',
  '/home-security-system-warwick',
  '/home-security-system-essentials',
  '/home-security-system-starter',
  '/home-security-system-foundation',
  '/home-security-system-haven',
  '/home-security-system-knox',
  '/home-security-system-hearth',
  '/home-security-system-essentials',
  '/home-security-system-foundation',
  '/product/system'
]

const navigationItemPagesList = [
  '/alarm-sensors',
  '/how-simplisafe-works',
  '/features-alarm-monitoring',
  '/meet-the-system',
  '/wireless-home-security-feature-overview',
  '/professional-alarm-monitoring',
  '/reviews',
  '/crime-in-the-uk',
  '/blog',
  '/contact-us',
  '/refer-a-friend'
]

const trackOptimizelyEvent = (
  data: OptimizelyEvent,
  optimizelyClient: ReactSDKClient
) => {
  const eventId = safeProp('eventKey', data).getOrElse('')

  const eventKeyFormatted = eventId
    .replace(/\//g, '')
    .replace(/-/g, '_')
    .toLowerCase()
  const eventKeyLastCharacter = eventKeyFormatted.length - 1
  const eventKey =
    eventKeyFormatted[eventKeyLastCharacter] === '_'
      ? eventKeyFormatted.substring(0, eventKeyLastCharacter)
      : eventKeyFormatted

  optimizelyClient
    .onReady()
    .then(() =>
      optimizelyTrackEvent(
        {
          eventId: eventKey,
          revenue: safeProp('revenue', data).orUndefined(),
          userAttributes: userAttributes(),
          userId: visitorIdAtAt() || '', // wait until optimizelyClient is ready before evaluating atat cookie value
          value: safeProp('value', data).orUndefined()
        },
        optimizelyClient
      )
    )
    .catch(logError)
}

export const useOptimizelyTrackPageVisit = () => {
  const { optimizely } = useContext(OptimizelyContext)

  return useCallback(
    (data: OptimizelyEvent) => {
      const pageUrl = safeProp('pageUrl', data)
        .map(leadingSlashIt)
        .map(untrailingSlashIt)
        .getOrElse('')
      // numbers are replaced to track variations of the page with the same URL as the original page - this includes the dynamic package page
      const pageUrlEvent = pageUrl.replace(/[0-9]/g, '')

      Maybe.fromNull(optimizely).cata(
        () => {
          logError(
            Error('useOptimizelyTrackPageVisit: optimizely client not defined')
          )
        },
        _optimizely => {
          trackOptimizelyEvent(
            { eventKey: `page_${pageUrlEvent}` },
            _optimizely
          )
          packagePagesList.includes(pageUrlEvent) &&
            trackOptimizelyEvent({ eventKey: 'page_package_page' }, _optimizely)
          pageUrlEvent.includes('-refurb-all-new') &&
            trackOptimizelyEvent(
              { eventKey: 'page_refurb_package_page' },
              _optimizely
            )
          navigationItemPagesList.includes(pageUrlEvent) &&
            trackOptimizelyEvent(
              { eventKey: 'page_navigation_item' },
              _optimizely
            )
        }
      )
    },
    [optimizely]
  )
}

export const useOptimizelyTrackProductPurchase = () => {
  const { optimizely } = useContext(OptimizelyContext)

  return useCallback(
    (data: OptimizelyPurchaseEvent) => {
      const productType = safeProp('productType', data).orUndefined()
      const systemProduct = productType === 'package_parent'
      const productSku = safeProp('sku', data).getOrElse('')
      const eventProductSku = rTest(/__/i, productSku)
        ? productSku.substring(0, productSku.indexOf('__'))
        : productSku
      const systemPurchaseKey = productSku.includes('ss3-refurbished')
        ? 'refurbished_system'
        : 'system_ss3'
      const eventKey = systemProduct ? systemPurchaseKey : eventProductSku
      const trackProductType: boolean = !isEmpty(productType) && !systemProduct

      Maybe.fromNull(optimizely).cata(
        () => {
          logError(
            Error(
              'useOptimizelyTrackProductPurchase: optimizely client not defined'
            )
          )
        },
        _optimizely => {
          trackOptimizelyEvent(
            {
              eventKey: `buy_${eventKey}_fs`,
              revenue: safeProp('price', data).orUndefined(),
              value: safeProp('qty', data).orUndefined()
            },
            _optimizely
          )

          // track product type purchase
          trackProductType &&
            trackOptimizelyEvent(
              { eventKey: `buy_${productType}_fs` },
              _optimizely
            )
        }
      )
    },
    [optimizely]
  )
}

export const useOptimizelyTrackSiteEvents = () => {
  const { optimizely } = useContext(OptimizelyContext)

  return useCallback(
    (data: OptimizelyEvent) => {
      const eventType = safeProp('eventType', data).orUndefined()

      Maybe.fromNull(optimizely).cata(
        () => {
          logError(
            Error('useOptimizelyTrackSiteEvents: optimizely client not defined')
          )
        },
        _optimizely => {
          eventType &&
            trackOptimizelyEvent({ eventKey: eventType }, _optimizely)

          isNotNil(prop('eventKey', data)) &&
            trackOptimizelyEvent(
              {
                eventKey: safeProp('eventKey', data).getOrElse(''),
                revenue: safeProp('revenue', data).orUndefined(),
                value: safeProp('value', data).orUndefined()
              },
              _optimizely
            )
        }
      )
    },
    [optimizely]
  )
}

export const useOptimizelyActivateExperiment = () => {
  const { optimizely } = useContext(OptimizelyContext)

  return useCallback(
    (data: OptimizelyExperiment, callbackFn?: () => void) => {
      Maybe.fromNull(optimizely).cata(
        () => {
          logError(
            Error(
              'useOptimizelyActivateExperiment: optimizely client not defined'
            )
          )
        },
        _optimizely => {
          _optimizely
            .onReady()
            .then(() => {
              return activateOptimizelyExperiment(
                {
                  experimentId: safeProp('experimentId', data).getOrElse(''),
                  userAttributes: userAttributes(),
                  userId: pathOr(
                    visitorIdAtAt() || '',
                    ['user', 'id'],
                    _optimizely
                  ), // wait until optimizely client is ready before evaluating atat cookie value
                  variation: safeProp('variationId', data).orUndefined()
                },
                _optimizely
              )
            })
            .then(() => callbackFn && callbackFn())
            .catch(logError)
        }
      )
    },
    [optimizely]
  )
}

export const useOptimizelyAffirm = () => {
  const { optimizely } = useContext(OptimizelyContext)

  return {
    optimizelyAffirmLearnMore: useCallback(
      (e: React.MouseEvent) => {
        Maybe.fromNull(optimizely).cata(
          () =>
            logError(
              Error('useOptimizelyAffirm: optimizely client not defined')
            ),
          _optimizely =>
            e.currentTarget.className.includes('affirm-as-low-as') &&
            always(
              trackOptimizelyEvent(
                { eventKey: 'click_affirm_learn_more' },
                _optimizely
              )
            )
        )
      },
      [optimizely]
    )
  }
}

export type OptimizelyUpsellProps = {
  readonly optimizelyUpsellStatus: (showCartUpsell: boolean) => void
  readonly optimizelyUpsellReady: boolean
  readonly optimizelyUpsellVariation: string | null
}
export const useOptimizelyUpsell: () => OptimizelyUpsellProps = () => {
  const { optimizely } = useContext(OptimizelyContext)
  const [variation, clientReady, didTimeout] = useExperiment('ECP-5192')

  return {
    optimizelyUpsellReady: clientReady || didTimeout,
    optimizelyUpsellStatus: useCallback(
      (showCartUpsell: boolean) => {
        Maybe.fromNull(optimizely).cata(
          () =>
            logError(
              Error('useOptimizelyUpsell: optimizely client not defined')
            ),
          _optimizely =>
            showCartUpsell &&
            trackOptimizelyEvent(
              { eventKey: 'impacted_22534680009' },
              _optimizely
            )
        )
      },
      [optimizely]
    ),
    optimizelyUpsellVariation: variation
  }
}
