import { ContentfulRichText } from '@lib/components'
import { MeetTheSystem } from '@simplisafe/ss-react-components'
import { deviceProps } from '@simplisafe/ss-react-components/MeetTheSystem'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import { useTracking } from 'react-tracking'

import {
  ContentfulIconWithPointer,
  ContentfulMeetTheSystem
} from '../../../graphql'
import { trackShopNowEvent } from '../../util/analytics'

type MeetTheSystemComponentProps = {
  readonly data: ContentfulMeetTheSystem
}

const toGatsbyImage = (asset: ContentfulMeetTheSystem): ReactNode => {
  return (
    asset && (
      <GatsbyImage
        // @ts-expect-error TS(2322) FIXME: Type 'string | ContentfulMeetTheSystemDescription'... Remove this comment to see the full error message
        alt={asset?.description || asset?.title || ''}
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulMeetTheSystem' is not ... Remove this comment to see the full error message
        image={getImage(asset)}
      />
    )
  )
}

const createDeviceImage = (icon: ContentfulIconWithPointer): deviceProps => ({
  content: <ContentfulRichText raw={icon.description?.raw} />,
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
  deviceImage: toGatsbyImage(icon.image),
  id: icon.id,
  title: icon.title
})

function MeetTheSystemComponent({ data }: MeetTheSystemComponentProps) {
  const { trackEvent } = useTracking()

  // @ts-expect-error TS(2322) FIXME: Type '{ onClickCtaButton: () => void; children: re... Remove this comment to see the full error message
  const ctaButton: SSButtonProps = {
    ...data.button,
    onClickCtaButton: () => {
      trackShopNowEvent('lp-shop-now-button')(trackEvent)
    }
  }

  const deviceImages = data.deviceList?.map(createDeviceImage) ?? []

  const content = <ContentfulRichText raw={data.description?.raw} />

  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
  const badgeImage = toGatsbyImage(data.badgeImage)

  return (
    <MeetTheSystem
      badgeImage={badgeImage}
      button={ctaButton}
      content={content}
      deviceList={deviceImages}
      title={data.title ?? ''}
    />
  )
}

export default MeetTheSystemComponent
