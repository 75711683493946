import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { SiteColor, toSiteColor } from '@lib/components'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import {
  selectPromoDiscountText,
  selectPromoWithMonitoringDiscountText
} from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectDisplayMonitoringDiscount,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { Divider } from '@simplisafe/ss-react-components'
import { HeroBannerContainer } from '@simplisafe/ss-react-components/banners'
import { HeightTypes } from '@simplisafe/ss-react-components/Row'
import { Maybe } from 'monet'
import { applySpec } from 'ramda'
import always from 'ramda/src/always'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'

import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  ContentfulHeroBanner,
  ContentfulHeroBannerColumn
} from '../../../graphql'
import { getBadgeDiscount } from '../BadgeText'
import ContentfulRichText from '../ContentfulRichText'
import { spanWrap } from '../ContentfulRichText/common'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'
import HeroColumn from './HeroColumn'

export type HeroBannerProps = {
  readonly data: ContentfulHeroBanner
}

const toFloatingBadge = (
  data: ContentfulHeroBanner,
  placeholders: Record<string, string | null>
) => {
  const promoBadge = prop('promoBadge', data)
  const options: Options = {
    renderMark: { [MARKS.BOLD]: text => <strong>{text}</strong> },
    renderNode: {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'import("/home/joshderocher-vlk/D... Remove this comment to see the full error message
      [INLINES.EMBEDDED_ENTRY]: node => renderEmbeddedEntry(node, placeholders),
      [BLOCKS.HR]: () => <Divider color="neutralWhite" />,
      [BLOCKS.HEADING_3]: (__: unknown, text) => {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const header3 = map(spanWrap, text as string)

        return <h3 className={'h3'}>{header3}</h3>
      },
      [BLOCKS.PARAGRAPH]: (__: unknown, text) => <p className={'p'}>{text}</p>
    }
  }

  return !isEmpty(placeholders) && isNotNil(promoBadge)
    ? applySpec({
        backgroundColor: always('complementaryBlue100'),
        borderColor: always('complementaryBlue100'),
        floatingBadgeContent: () => (
          <div className="max-w-[150px]">
            <ContentfulRichText
              optionsCustom={options}
              raw={promoBadge?.text?.raw}
              // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulPlaceholder[]' is not ass... Remove this comment to see the full error message
              references={promoBadge?.text?.references}
            />
          </div>
        ),
        linkUrl: prop('promoBadgeUrl'),
        textColor: () => toSiteColor('neutralWhite')
      })(data)
    : null
}

export default function HeroBannerComponent({ data }: HeroBannerProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const columns = propOr<
    readonly ContentfulHeroBannerColumn[],
    readonly ContentfulHeroBannerColumn[]
  >([], 'bannerColumns', data)
  // @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type '<V>(p: stri... Remove this comment to see the full error message
  const backgroundColors = columns
    .map(col => prop<SiteColor>('backgroundColor', col))
    .filter(c => !!c)
  const isFullWidth = prop('isFullWidth', data)
  const disclaimerText = prop<string>('disclaimerText', data)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)

  const [placeholders, setPlaceholders] = useState<
    Record<string, string | null>
  >({})
  const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
  const discountTextWithMonitoring: Maybe<string> = useSelector(
    selectPromoWithMonitoringDiscountText
  )
  const discountTextWithoutMonitoring: Maybe<string> = useSelector(
    selectPromoDiscountText
  )
  const discountText = displayMonitoringDiscount
    ? discountTextWithMonitoring
    : discountTextWithoutMonitoring

  useEffect(() => {
    discountText.cata(
      () => setPlaceholders({}),
      text => setPlaceholders(getBadgeDiscount(text))
    )
  }, [discountText])

  const floatingPromo = !isPromoTopBanner
    ? toFloatingBadge(data, placeholders)
    : null

  return columns.length ? (
    <HeroBannerContainer
      backgroundColors={backgroundColors}
      className={
        prop<boolean>('hasRoundedCorners', data)
          ? 'rc-rounded-base rc-overflow-hidden'
          : ''
      }
      disclaimerText={disclaimerText}
      floatingPromoBadgeProps={floatingPromo}
      heightType={prop<HeightTypes>('heightType', data)}
      isFullWidth={isFullWidth}
    >
      {/* @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type '<V>(p: stri... Remove this comment to see the full error message */}
      {columns?.map((col, index) => (
        <HeroColumn colsLength={columns.length} column={col} key={index} />
      ))}
    </HeroBannerContainer>
  ) : null
}
