import { useOptimizelyParams, userAttributes } from '@lib/tracking'
import { selectCustomerGroupKey } from '@simplisafe/ss-ecomm-data/cart/select'
import {
  PriceData,
  Prices,
  requestPrices
} from '@simplisafe/ss-ecomm-data/prices/service'
import { selectItemsFromSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import { Maybe } from 'monet'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { IsPartnerPageContext } from '../contexts/isPartnerPageContext'
import { getRawDiscountedPrice, getRawPrice } from '../providers/PriceProvider'
import {
  getDiscountedTextHelper,
  getFormattedPriceHelper,
  getRawDiscountedPriceWithServicePlan
} from '../providers/PriceProvider/PriceProvider'
import { getValueFromPartnerCookie } from '../util/partnerCookie'

const useRequestPrice = (
  sku: string,
  showAbsoluteDiscountAsRelative = false
) => {
  const [isMounted, setIsMounted] = useState(true)
  const [prices, setPrices] = useState<Prices>({})
  const [isLoading, setLoading] = useState(true)

  const isPartnerPage = useContext(IsPartnerPageContext)
  const maybeCustomerGroup = useSelector(selectCustomerGroupKey)
  const partnerCookie = getValueFromPartnerCookie('partnerGroup') || undefined
  const customerGroup = maybeCustomerGroup.orUndefined() || partnerCookie
  const partnerName = getValueFromPartnerCookie('partnerName') || undefined

  /* Prevent fetching incorrect prices until after partner cookie is set.
  This works somehow, but it shouldn't since we're triggering requestPrices on a cookie change,
  but we don't poll for that cookie value. 🤷 */
  // TODO: refactor to move partner state outside of cookies so we can reliably respond to changes
  const hasPartnerCookieIfNeeded = partnerCookie || !isPartnerPage

  const optimizelyParams = useOptimizelyParams()

  const fallback = useSelector(selectItemsFromSkus([sku]))

  useEffect(() => {
    const attributes = userAttributes()
    isMounted &&
      hasPartnerCookieIfNeeded &&
      requestPrices(
        [sku],
        attributes,
        customerGroup,
        optimizelyParams,
        partnerName
      )(() => {
        setLoading(false)
      })((response: Maybe<Record<string, PriceData>>) => {
        response.forEach((newPrices: Record<string, PriceData>) =>
          setPrices(newPrices)
        )
        setLoading(false)
      })

    return () => setIsMounted(false)
  }, [
    sku,
    isMounted,
    customerGroup,
    optimizelyParams,
    partnerName,
    hasPartnerCookieIfNeeded
  ])

  return {
    getDiscountedPrice: getRawDiscountedPrice(prices, fallback, isLoading)(sku),
    getDiscountedPriceWithServicePlan: getRawDiscountedPriceWithServicePlan(
      prices,
      fallback,
      isLoading
    )(sku),
    getDiscountedText: getDiscountedTextHelper(
      prices,
      fallback,
      false,
      isLoading,
      showAbsoluteDiscountAsRelative
    )(sku),
    getDiscountedTextWithServicePlan: getDiscountedTextHelper(
      prices,
      fallback,
      true,
      isLoading,
      showAbsoluteDiscountAsRelative
    )(sku),
    getFormattedPrice: getFormattedPriceHelper(
      prices,
      fallback,
      isLoading
    )(sku),
    getPrice: getRawPrice(prices, fallback, isLoading)(sku)
  }
}

export default useRequestPrice
