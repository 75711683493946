import { SSButton, Text } from '@simplisafe/ss-react-components'
import React, { CSSProperties } from 'react'

type SubmissionProps = {
  readonly cancelButtonLabel: string
  readonly isMobile: boolean
  readonly disableSubmit: boolean
  readonly showErrorMessage: boolean
  readonly submitButtonLabel: string
  readonly submissionErrorMessage: string
}

function Submission(props: SubmissionProps) {
  const desktopStyles: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    gap: '20px',
    justifyContent: 'flex-end'
  }
  const mobileStyles: CSSProperties = { display: 'block' }

  const styles = props.isMobile === false ? desktopStyles : mobileStyles

  return (
    <>
      <Text
        className="prose-a:text-complementary-blue-100"
        data-component={'Submission'}
        style={styles}
        useTailwind
      >
        {!props.isMobile ? (
          <a href="/cart" id="cancelLink">
            {props.cancelButtonLabel}
          </a>
        ) : null}
        <SSButton
          disabled={props.disableSubmit}
          minWidth={props.isMobile ? 'full' : 'auto'}
          type="submit"
        >
          {props.submitButtonLabel}
        </SSButton>
        {props.isMobile && (
          <a
            href="/cart"
            id="cancelLink"
            style={{
              display: 'block',
              marginTop: '10px',
              textAlign: 'center'
            }}
          >
            {props.cancelButtonLabel}
          </a>
        )}
      </Text>
      <div style={{ marginTop: '20px' }}>
        {props.showErrorMessage && (
          <Text
            fontWeight="medium"
            textAlignment={props.isMobile ? 'left' : 'right'}
            textColor="errorRed"
            textSize="sm"
          >
            {props.submissionErrorMessage}
          </Text>
        )}
      </div>
    </>
  )
}

export default Submission
