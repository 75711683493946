import prop from '@simplisafe/ewok/ramda/prop'
import { selectPackage } from '@simplisafe/ss-ecomm-data/redux/select'
import { CardKit } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulCardKit } from '../../../graphql'
import { renderPrice } from '../../commercetools/price'

export type CardKitComponentProps = {
  readonly data: ContentfulCardKit
}

function CardKitComponent({ data }: CardKitComponentProps) {
  const { tagPosition, img } = data

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const url = propOr<string, string>('', 'url', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const kitName = propOr<string, string>('', 'kitName', data)

  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const productId: string = propOr<string, string>('', 'productId', data)
  const _package = useSelector(selectPackage(productId))

  const toGatsbyImage = (imgData: ContentfulCardKit['img']): ReactNode => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const altText = propOr<string, string>('', 'title', imgData)

    return Maybe.fromNull(imgData).cata(
      () => null,
      imgProps => (
        <GatsbyImage
          // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
          alt={altText}
          // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
          image={getImage(imgProps)}
          imgStyle={{ objectFit: 'cover' }}
          style={{ width: '100%' }}
        />
      )
    )
  }

  return (
    <CardKit
      image={toGatsbyImage(img)}
      price={_package && renderPrice(_package, false)}
      tagPosition={tagPosition === 'left' ? 'left' : 'right'}
      tagText={prop('tagText', data)}
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      title={kitName}
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      url={url}
    />
  )
}
export default CardKitComponent

export const CardKitQuery = graphql`
  #graphql
  fragment cardKit on ContentfulCardKit {
    entryTitle
    kitName
    tagText
    tagPosition
    productId
    url
    img {
      gatsbyImageData(layout: CONSTRAINED, width: 540, placeholder: BLURRED)
      title
    }
    internal {
      type
    }
  }
`
