import { OptimizelyContext } from '@lib/optimizely'
import { noValue } from '@lib/utils'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { get, set } from 'local-storage'
import { Maybe } from 'monet/dist/monet'
import { useContext } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

import { useOptimizelyActivateExperiment } from '../optimizely'
import { params } from '../queryParams'

export const useOptimizelyParams = () => {
  const { optimizely } = useContext(OptimizelyContext)
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()

  const [query, setQuery] = useQueryParams({
    [params.experimentKey]: StringParam,
    [params.variationKey]: StringParam
  })

  const { experiment, variation } = query

  const cachedExperiment = get<string>('experiment')
  const cachedVariation = get<string>('variation')

  experiment && set('experiment', experiment)
  variation && set('variation', variation)

  experiment &&
    variation &&
    Maybe.fromNull(optimizely).cata(
      () => {
        logError(
          Error(
            'useOptimizelyActivateExperiment: optimizely client not defined'
          )
        )
      },
      _optimizely => {
        return optimizelyActivateExperiment({
          experimentId: experiment,
          variationId: variation
        })
      }
    )

  experiment &&
    variation &&
    setQuery({
      [params.experimentKey]: noValue(),
      [params.variationKey]: noValue()
    })

  return {
    experimentKey: experiment || cachedExperiment,
    variationKey: variation || cachedVariation
  }
}
