import { PackageCard } from '@lib/components'
import { selectDisplayMonitoringDiscount } from '@simplisafe/ss-ecomm-data/redux/select'
import React from 'react'
import { useSelector } from 'react-redux'

import useRequestPrice from '../../hooks/useRequestPrice'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import { PackageCardFragment } from './schema'

export type PackageCardProps = {
  readonly data: PackageCardFragment
}

function PackageCardComponent({ data }: PackageCardProps) {
  const packageTitle: string = data.product.title || ''
  const quantity: number = data.product.quantity || 0
  const skuID: string = data.product.productId || ''
  const displayMonitoringPrice = useSelector(selectDisplayMonitoringDiscount)
  const { getFormattedPrice } = useRequestPrice(skuID)
  const ssButtonProps = {
    color: 'primaryHollow',
    href: data.button.url || '',
    type: 'link',
    value: data.button.text || ''
  }
  const packageCardData = {
    bmsDescription: data.bmsDescription,
    button: ssButtonProps,
    image: data.img,
    price:
      quantity && getFormattedPrice(priceDefault, true, displayMonitoringPrice),
    quantity: quantity,
    title: packageTitle
  }
  return <PackageCard data={packageCardData} />
}

export default PackageCardComponent
