/* eslint-disable @typescript-eslint/consistent-type-assertions */

// @ts-nocheck

import { AffirmClient } from '@lib/components'
import {
  OptimizelyUpsellProps,
  params,
  useOptimizelyAffirm,
  useOptimizelyTrackSiteEvents,
  useOptimizelyUpsell
} from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import {
  clearCartError,
  IOAddToCart,
  IORemoveFromCart,
  IOUpdateDiscountCodeToCart,
  IOUpdateQuantity
} from '@simplisafe/ss-ecomm-data/cart'
import {
  getShippingInfoKey,
  LOCAL_STORAGE_CARTID
} from '@simplisafe/ss-ecomm-data/cart/actions'
import {
  getCartDetails,
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  selectCart,
  selectHiddenProductSkus,
  selectLocale,
  selectProduct,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Text } from '@simplisafe/ss-react-components'
import {
  AffirmPromoMessage,
  BannerError
} from '@simplisafe/ss-react-components'
import { LoadingSpinner } from '@simplisafe/ss-react-components'
import type { CartLineItemProps } from '@simplisafe/ss-react-components/CartLineItem'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { window } from 'browser-monads-ts'
import { graphql, Link, navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { get, remove, set } from 'local-storage'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import length from 'ramda/src/length'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import T from 'ramda/src/T'
import uniq from 'ramda/src/uniq'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { debounce, throttle } from 'throttle-debounce'
import Cookies from 'universal-cookie'

import {
  ContentfulAsset,
  ContentfulCartDetails,
  ContentfulProductCardAccessories,
  ContentfulProductInformation
} from '../../../graphql'
import {
  componentsNotInStock,
  CoreComponentsData,
  renderCartLineOutOfStockMessage,
  renderCoreComponentsCartMessage,
  renderCoreComponentsNotInStockMsg,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice } from '../../commercetools/price'
import { ContentfulComponent } from '../../componentMappings'
import useUtmCode from '../../hooks/useUtmCode'
import { outdoorCamCrm } from '../../hooks/useUtmContent'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { trackLoadCartEvent } from '../../util/analytics/loadCart'
import { trackRemoveCartEvent } from '../../util/analytics/removeFromCart'
import { leadingSlashIt, toButton } from '../../util/helper'
import { renderComponentFromData } from '../../util/render'
import { USER_DISCOUNT_CODE } from '../GlobalPromotionalComponent'
import { bannerClickEvent } from '../SmallTextSection'
import CartLineItemDescription from './cart-sections/CartLineItemDescription'
import CartWidgetItem from './cart-sections/CartWidgetItem'
import CartContent, { CartDetailsProps } from './CartContent'
import NavyCheckmark from './icons/NavyCheckmark'
import {
  getCartDiscountCode,
  getCartDiscountValue,
  getLocalizedCartSubtotal,
  toDiscountApplied,
  toItemList
} from './transformLineItem'

const cookies = new Cookies()
const SHIELD_SKU = 'CMOB1'

type CartDetailsComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: Partial<ContentfulCartDetails>
}

type FileTypeProp = {
  readonly file: {
    readonly fileName: string
    readonly url: string
  }
  readonly title: string
}

type bannerDataProps = {
  readonly backgroundColor: {
    readonly color: string
  }
  readonly childContentfulIconWithTextTextRichTextNode: {
    readonly raw: string
  }
  readonly icon: FileTypeProp
  readonly iconPosition: string
  readonly textAlignment: string
  readonly textPosition: string
  readonly title: string
}

type ServerError = Error & {
  readonly status?: number
  readonly statusText?: string
}

const renderCreditCardLogoImg = (data: Partial<ContentfulCartDetails>) => {
  const creditCardLogo = prop('creditCardLogo', data)
  return map(toFormImg)(creditCardLogo as readonly ContentfulAsset[])
}
const toFormImg = (data: Partial<ContentfulCartDetails>) => (
  <BgImage
    alt={data?.description || ''}
    image={getImage(data)}
    key={data?.id}
    style={{
      height: '25px',
      width: '45px'
    }}
  />
)
const isShieldWarningOnLocalStorage = () =>
  equals(get(params.content), outdoorCamCrm)

// TODO this should be exported and unit tested
// TODO switch this from applySpec to transformObject
const toContentfulCartDetails = (
  data: Partial<ContentfulCartDetails>,
  isMobile: boolean,
  shieldIsInCart: boolean
) => {
  const standaloneWithoutSystemMessage = (
    <p>There is no home security system in your cart.</p>
  )
  const shieldWithoutSystemMessage = (
    <p>
      Heads up! You need the latest SimpliSafe system to use our outdoor
      security camera.
    </p>
  )
  const withoutSystemMessage =
    shieldIsInCart && !isShieldWarningOnLocalStorage()
      ? shieldWithoutSystemMessage
      : standaloneWithoutSystemMessage

  return applySpec<CartDetailsProps>({
    affirmMessage: () => data?.affirmMessage?.raw,
    belowCartContentsSection: (data: Partial<ContentfulCartDetails>) =>
      renderComponentFromData(prop('belowCartContent', data)),
    checkOutButtonFooter: pipe(prop('checkoutButtonFooter'), toButton),
    checkOutButtonHeader: pipe(prop('checkoutButtonHeader'), toButton),
    creditCardLogo: renderCreditCardLogoImg,
    guaranteeNote: () => data?.guaranteeNote?.raw,
    linkHeader: prop('linkHeader'),
    mobileLink: data =>
      safeProp('linkHeader', data)
        .chain(linkData =>
          safeProp('linkText', linkData).chain(linkText =>
            safeProp('linkUrl', linkData).map(linkUrl => (
              <Link
                key={`mobile-link-${linkText}`}
                to={leadingSlashIt(linkUrl)}
              >
                {linkText}
              </Link>
            ))
          )
        )
        .orUndefined(),
    partnerCaptureForm: data => {
      const components: readonly ContentfulComponent[] = safeProp(
        'partnerCaptureModal',
        data
      ).getOrElse([])
      return components.map((component: ContentfulComponent) =>
        renderComponentFromData(component)
      )
    },
    subTotalContent: () => data?.subtotal?.raw || '',
    titleHeader: prop('titleHeader'),
    withoutSystemMessage: () => withoutSystemMessage
  })(data)
}

// checks if only refurbished system is in cart,
// if any other package is in cart, it returns false
export const onlyRefurbishedSystemIsInCart = (
  items: readonly CartLineItemProps[]
): boolean => {
  // return an array of all systems in cart
  const allSystems = items.filter(
    item => safeProp('subItems', item).getOrElse([]).length > 0
  )
  // returns an array of all refurbished systems in cart
  const refurbishedSystems = allSystems.filter(item =>
    safeProp('sku', item).getOrElse('').includes('ss3-refurbished')
  )
  return (
    refurbishedSystems.length > 0 &&
    refurbishedSystems.length === allSystems.length
  )
}

const toCoreComponentsOosProps = (
  coreComponentsNotInStockList: readonly CoreComponentsData[],
  systemInCart: boolean,
  items: readonly CartLineItemProps[]
) => {
  const showCoreComponentsMessage =
    coreComponentsNotInStockList.length > 0 &&
    systemInCart &&
    !onlyRefurbishedSystemIsInCart(items)
  return showCoreComponentsMessage
    ? {
        coreComponentNotInStock: showCoreComponentsMessage,
        coreComponentShipEstimate: renderCoreComponentsNotInStockMsg(
          coreComponentsNotInStockList,
          false
        ),
        coreComponentsShipDelayText: renderCoreComponentsCartMessage(
          coreComponentsNotInStockList
        )
      }
    : { coreComponentNotInStock: false }
}

export const isPackage = (lineItem: LineItem): boolean =>
  prop('productType', lineItem) === 'package_parent'

export const isCartQualifiedFreeShipping = (cart: ImmutableCart): boolean =>
  safeProp('lineItems', cart)
    .map(lineItems => lineItems.some(isPackage))
    .orJust(false)

export const isCartQualifiedFreeShippingUS = (cart: ImmutableCart): boolean =>
  isCartQualifiedFreeShipping(cart)

export const isCartQualifiedFreeShippingUK = (cart: ImmutableCart): boolean =>
  isCartQualifiedFreeShipping(cart) || prop('totalPrice', cart) > 50

export const getFreeShippingItem = (
  shippingText: string,
  cart: ImmutableCart,
  siteLocale: string
): CartLineItemProps => {
  const hasFreeShippingText = ifElse(
    equals('en-GB'),
    () => isCartQualifiedFreeShippingUK(cart),
    () => isCartQualifiedFreeShippingUS(cart)
  )

  const freeShippingItem: CartLineItemProps = {
    isHighlightedLineItem: true,
    itemDescriptionContent:
      siteLocale === 'en-US' ? (
        <p>You may select a different shipping method at checkout.</p>
      ) : undefined,
    itemName: shippingText,
    price: formatDisplayPrice(0).orUndefined()
  }

  return hasFreeShippingText(siteLocale) ? freeShippingItem : { itemName: '' }
}

export const renderCart = (prop: CartDetailsProps) => {
  return <CartContent {...prop} />
}

export const shouldShowMonitoringPlanWidget =
  (monitoringSku: string) =>
  (cart: ImmutableCart): boolean => {
    const hasLineItems = !!length(cart.lineItems)
    const cartHasSystem = !!cart.isThereAnySecurity
    const cartHasMonitoringPlan = safeProp('lineItems', cart)
      .map(lineItems => lineItems.some(item => item.sku === monitoringSku))
      .orJust(false)

    return hasLineItems && cartHasSystem && !cartHasMonitoringPlan
  }

// Gets a number of shield products in cart
const shieldQuantityInCart = (lineItems: readonly LineItem[]) => {
  // TODO: product quantity limit should be added in product data
  return lineItems
    .filter(
      lineItem =>
        safeProp('sku', lineItem).getOrElse('').toUpperCase() === SHIELD_SKU
    )
    .reduce((prev, cur) => prev + cur.quantity, 0)
}

/**
 * Find the maximum number of a particular product allowed in a cart by SKU.
 * @param sku The product SKU
 * @param productMaxQuantity Product configuration data
 */
const getMaxQuantityBySku = (
  sku: string,
  productMaxQuantity?: readonly (ContentfulProductCardAccessories | null)[]
): number => {
  const product = productMaxQuantity
    .filter(product => product)
    .find(product => prop('productId', product) === sku)

  return Maybe.fromUndefined(product)
    .chain(safeProp('maximumQuantity'))
    .orJust(0)
}

const getShieldMaxQuantity = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
): number => getMaxQuantityBySku(SHIELD_SKU, productMaxQuantity)

/** Filter out Products from lineItmes that do not have cameras
 * outdoor camera: CMOB1, indoor camera: SSCM2, video doorbell: SSDB3
 */
// TODO: set tags for sensors with cameras in Commercetools, remove hardcoded filters
const sensorWithCamera = [SHIELD_SKU, 'SSCM2', 'SSDB3']
const cameraSensorQuantityInCart = (lineItems: readonly LineItem[]) => {
  // TODO: product quantity limit should be added in product data
  return lineItems
    .map(quantity => quantity)
    .filter(product => sensorWithCamera.includes(prop('sku', product)))
    .reduce((prev, cur) => prev + cur.quantity, 0)
}

const productIsInCart = (
  items: readonly CartLineItemProps[],
  productSku: string
) => {
  return isNotEmpty(
    items.filter(
      item => safeProp('sku', item).getOrElse('').toUpperCase() === productSku
    )
  )
}

const isInteractiveMonitoringInCart = (
  items: readonly CartLineItemProps[],
  siteLocale: string
) =>
  productIsInCart(
    items,
    siteLocale === 'en-US' ? 'SSEDSM2__4867366' : 'SSEDSM2_GB__5229044'
  )

// Sensors with cameras warning message
const renderSensorWarningMessage = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[],
  cameraSensorQuantity: number,
  shieldQuantity: number,
  items: readonly CartLineItemProps[],
  siteLocale: string,
  selfMonitoringInCart: boolean
) => {
  const interactiveMonitoringInCart: boolean = isInteractiveMonitoringInCart(
    items,
    siteLocale
  )
  const maxCameraSensors = 10
  const maxShieldQuantity: number = getShieldMaxQuantity(productMaxQuantity)
  const cameraSensorLimit: number = interactiveMonitoringInCart
    ? maxCameraSensors
    : 5
  const showShieldWarning = shieldQuantity > maxShieldQuantity ? true : false
  const showCameraSensorWarning: boolean =
    (interactiveMonitoringInCart || selfMonitoringInCart) &&
    cameraSensorQuantity > cameraSensorLimit

  return showShieldWarning || showCameraSensorWarning
    ? [
        showShieldWarning && (
          <Text
            key={'outdoor-camera-warning'}
          >{`Max number of outdoor security cameras allowed per user is ${maxShieldQuantity}.`}</Text>
        ),
        showCameraSensorWarning ? (
          selfMonitoringInCart ? (
            <Text key={'self-monitoring-warning'}>
              Heads up! You can only get security recordings on{' '}
              {cameraSensorLimit} cameras. Upgrade to 24/7 professional
              monitoring to get recordings on up to {maxCameraSensors} cameras.
            </Text>
          ) : (
            <Text key={'interactive-monitoring-warning'}>
              Heads up! You can only get security recordings on{' '}
              {cameraSensorLimit} cameras.
            </Text>
          )
        ) : undefined
      ]
    : undefined
}

const navigateLink = (url: string, parentId: string, sku: string) => {
  Maybe.fromFalsy(url === '/change-monitoring').forEach(() =>
    set('parentId', parentId)
  )
  navigate(url, { state: { packageSku: sku } })
}

export default function CartDetailsComponent({
  affirmClient = window.affirm,
  data
}: CartDetailsComponentProps) {
  const siteLocale = useSelector(selectLocale)
  const isMobile = !useMediaQuery('TabletAndUp')
  // TODO this can just be an array instead of a Maybe
  const [lineItem, setLineItems] = useState(
    None<readonly CartLineItemProps[]>()
  )
  const [totalPrice, setTotalPrice] = useState(None<string>())
  const [shieldQuantity, setShieldQuantity] = useState(0)
  const [cameraSensorQuantity, setCameraSensorQuantity] = useState(0)
  const [showApplyCoupon, setShowApplyCoupon] = useState(false)
  const [showApplyCouponError, setShowApplyCouponError] = useState<
    boolean | undefined
  >(undefined)
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const hiddenProductSkus = useSelector(selectHiddenProductSkus)
  const [isMounted, setMounted] = useState(false)
  const productMaxQuantity = useMemo(
    () => prop('productMaxQuantity', data) || [],
    [data]
  )
  const shieldMaxQuantity = getShieldMaxQuantity(productMaxQuantity)
  const subItemsSkusRepeated = lineItem
    .orJust([])
    .reduce(
      (acc: readonly string[], item) =>
        acc.concat(
          (item?.subItems || []).map(subItem => subItem?.subItemSku || '')
        ),
      []
    )
  const subItemsSkus = uniq<string>(subItemsSkusRepeated)
  const itemsSkusRepeated = lineItem.orJust([]).map(item => item.sku || '')
  const itemsSkus = uniq<string>(itemsSkusRepeated)
  const subItemsProducts = useSelector(selectProducts(subItemsSkus))
  const itemsProducts = useSelector(selectProducts(itemsSkus))

  const freeMonitoringSku = safePath(['freeMonitoring', 'productId'], data)

  const { Track, trackEvent } = useTracking()
  const shippingText = defaultTo('')(prop('shippingText', data))
  const productReference = data?.productReference || []

  const freeMonitoringDetails = useSelector((state: ImmutableState) =>
    freeMonitoringSku.chain(sku => selectProduct(sku)(state).toMaybe())
  ).toEither(Error('Unable to obtain free monitoring product details'))

  const coreComponentsProducts = useSelector(
    selectProducts(systemCoreComponents)
  )

  const coreComponentsNotInStockList = useMemo(
    () => componentsNotInStock(coreComponentsProducts),
    [coreComponentsProducts]
  )

  const liftFreeMonitoringSku = useMemo(
    () => freeMonitoringSku.map(shouldShowMonitoringPlanWidget),
    [freeMonitoringSku]
  )

  const productMessageWithoutSystemData = safeProp(
    'productMessageWithoutSystem',
    data
  ) as Maybe<readonly ContentfulProductInformation[]>

  const [productWarningMessage, setProductWarningMessage] = useState(
    None<ContentfulProductInformation>()
  )
  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  /** handle add to cart in cart monitaoring right pane section */
  const onAddToCart = useCallback(() => {
    const handleSuccess = () => {
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(freeMonitoringDetails, trackEvent, 1)
    }

    freeMonitoringSku.forEach(sku =>
      dispatch(
        IOAddToCart(
          {
            products: [
              {
                quantity: 1,
                sku
              }
            ]
          },
          undefined,
          handleSuccess
        )
      )
    )
  }, [
    dispatch,
    freeMonitoringDetails,
    freeMonitoringSku,
    trackEvent,
    optimizelyTrackSiteEvents
  ])

  /**
   * If there is an error, or the monitoring plan should not render, this is false.
   *
   * If the `.apTo` is new to you, you can read more about applicative functors here: https://mostly-adequate.gitbook.io/mostly-adequate-guide/ch10
   **/
  const [showCartMonitoringPlan, setShowCartMonitoringPlan] = useState(
    liftFreeMonitoringSku
      .apTo(cart.toMaybe())
      .cata(F, shouldShowMonitoringPlanWidget => shouldShowMonitoringPlanWidget)
  )

  const {
    optimizelyUpsellStatus,
    optimizelyUpsellVariation,
    optimizelyUpsellReady
  }: OptimizelyUpsellProps = useOptimizelyUpsell()
  optimizelyUpsellStatus(showCartMonitoringPlan)

  const affirmCardContent = useCallback(
    () =>
      siteLocale === 'en-US' ? (
        <CartWidgetItem
          dataComponentName="AffirmCard"
          description={
            <p className="text-[0.875rem] text-center mb-0 leading-normal">
              Pay for your SimpliSafe at your own pace. It&apos;s easy to sign
              up, and there&apos;s no late fees and no surprises. Select affirm
              at checkout today.
            </p>
          }
          heading={
            <h3 className="text-5 mt-2 mb-3 text-center">
              0% APR financing available
            </h3>
          }
          icon={
            <img
              alt="Affirm logo for the card content on the cart page"
              className={'block w-[5.625rem] h-auto mx-auto'}
              height="36"
              src="//images.ctfassets.net/br4ichkdqihc/79n6SshSFQkVCiBfVb7qgP/5ef77aedba32f3589036bed48eb66e63/affirm_cart.svg"
              width="90"
            />
          }
          onClick={() => {
            bannerClickEvent(trackEvent, 'Affirm card content')
          }}
          shouldTrackClicks
        />
      ) : null,
    [trackEvent, siteLocale]
  )

  const customerServiceCardContent = useCallback(() => {
    return siteLocale === 'en-GB' ||
      (siteLocale === 'en-US' &&
        showCartMonitoringPlan &&
        optimizelyUpsellReady &&
        optimizelyUpsellVariation &&
        optimizelyUpsellVariation === 'variation_1') ? undefined : (
      <CartWidgetItem
        dataComponentName="CustomerServiceCard"
        description={
          <>
            <p className="text-[0.875rem] text-center leading-normal">
              Have questions before you checkout? We&apos;re here to help.
            </p>
            <p className="text-[0.875rem] text-center mb-0 leading-normal">
              Call us at 800-548-9508
            </p>
          </>
        }
        heading={
          <h3 className="text-5 mt-2 mb-3 text-center">Help is available</h3>
        }
        icon={
          <img
            alt="customer support yellow"
            className={'block w-[5.625rem] h-auto mx-auto'}
            height="120"
            src="//images.ctfassets.net/br4ichkdqihc/3cxGSXyzBAjCV0kTx5DbBD/8be983945d6b8f15815f98112a12da63/png_Support-60_2x.png"
            width="120"
          />
        }
        onClick={() => {
          bannerClickEvent(trackEvent, 'Customer Service Card')
        }}
        shouldTrackClicks
      />
    )
  }, [
    trackEvent,
    siteLocale,
    optimizelyUpsellReady,
    optimizelyUpsellVariation,
    showCartMonitoringPlan
  ])

  const tryItTestItCardContent = useCallback(() => {
    return (
      <CartWidgetItem
        dataComponentName="TryItTestItCard"
        description={
          <ul className="mt-6 p-0 list-none">
            <li className="text-sm pl-1 pb-[1.1rem] last:pb-0 ml-6 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="px-2.5 m-0">60 day trial period</p>
              </div>
            </li>
            <li className="text-sm pl-1 pb-[1.1rem] last:pb-0 ml-6 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="px-2.5 m-0">
                  Subscription lifetime hardware warranty
                </p>
              </div>
            </li>
            <li className="text-sm pl-1 pb-[1.1rem] last:pb-0 ml-6 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="px-2.5 m-0">No long-term contracts</p>
              </div>
            </li>
            <li className="text-sm pl-1 pb-[1.1rem] last:pb-0 ml-6 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="px-2.5 m-0">100% money-back guarantee</p>
              </div>
            </li>
          </ul>
        }
        heading={
          <h3 className="text-5 mt-2 mb-3 text-center">
            Try it, test it, love it or return it
          </h3>
        }
        icon={
          <img
            alt="100% Satisfaction Guarantee"
            className={'block w-[5.625rem] h-auto mx-auto'}
            height="440"
            src="//images.ctfassets.net/br4ichkdqihc/dXDphKOK8PVlC0FMxBazu/e4e699c89ba2e8e5316aff8af8545b99/100-seal_2x.png"
            width="436"
          />
        }
        onClick={() => bannerClickEvent(trackEvent, 'Try It Test It Card')}
        shouldTrackClicks
      />
    )
  }, [trackEvent])

  const cartMonitoringPlanContent = useCallback(() => {
    return (siteLocale === 'en-GB' && showCartMonitoringPlan) ||
      (siteLocale === 'en-US' &&
        optimizelyUpsellReady &&
        optimizelyUpsellVariation &&
        optimizelyUpsellVariation === 'variation_1' &&
        showCartMonitoringPlan) ? (
      <CartWidgetItem
        alignContentCenter
        buttonText="Add free month"
        dataComponentName="MonitoringPlanCard"
        description={
          <>
            <p className="my-[5px] text-sm font-bold text-[#bf4436]">
              Get 30 Days Free
            </p>
            <p className="text-left text-sm">
              Get 24/7 monitoring, police & fire dispatch, and more money off
              your system today.
            </p>
          </>
        }
        heading={<h3 className="mb-[5px]">Complete Your System</h3>}
        onClick={onAddToCart}
        subHeading={
          <h4 className="mb-[7px] mt-[10px]">
            {siteLocale === 'en-GB'
              ? 'Pro Premium Monitoring'
              : 'Interactive Monitoring'}
          </h4>
        }
      />
    ) : undefined
  }, [
    data,
    optimizelyUpsellReady,
    optimizelyUpsellVariation,
    onAddToCart,
    showCartMonitoringPlan,
    siteLocale
  ])

  const onUpdateQuantity = debounce(
    500,
    (lineItemId: string, quantity: number) => {
      dispatch(IOUpdateQuantity(lineItemId, quantity))
    }
  )

  const onRemoveProduct = (lineItems: readonly LineItem[]) =>
    throttle(500, (lineItem: LineItem) => {
      const lineItemId = prop('lineItemId', lineItem)
      const handleSuccess = () => {
        trackRemoveCartEvent(lineItem, trackEvent, lineItems)
      }
      dispatch(IORemoveFromCart(lineItemId, undefined, handleSuccess))
    })

  useEffect(() => {
    // TODO this function needs to be abstracted, exported, and have unit tests
    cart.forEach(_cart => {
      const cartItems = toItemList(
        getCartDetails(hiddenProductSkus)(_cart.lineItems)
      )(trackEvent, onUpdateQuantity, onRemoveProduct(_cart.lineItems))
      const hasLineItems = !!length(_cart.lineItems)

      // set total qty of sensors with cameras
      setCameraSensorQuantity(cameraSensorQuantityInCart(_cart.lineItems))

      // Check if shield is in cart and shield quantity state
      hasLineItems && setShieldQuantity(shieldQuantityInCart(_cart.lineItems))
      hasLineItems &&
        !_cart.isThereAnySecurity &&
        setProductWarningMessage(
          productMessageWithoutSystemData.chain(data =>
            safeFind(
              p => _cart.lineItems.some(l => equals(l.sku, p.productId)),
              data
            )
          )
        )
      setLineItems(cartItems)
      setTotalPrice(getLocalizedCartSubtotal(_cart))

      setShowCartMonitoringPlan(
        liftFreeMonitoringSku
          .apTo(cart.toMaybe())
          .cata(
            F,
            shouldShowMonitoringPlanWidget => shouldShowMonitoringPlanWidget
          )
      )

      // Send Custom Event for eec.details when first page load.
      ifElse(
        _isMounted => {
          return !_isMounted && !isEmpty(_cart.lineItems)
        },
        () => {
          trackLoadCartEvent(_cart.lineItems, trackEvent)
          setMounted(true)
        },
        always(undefined)
      )(isMounted)
    })
  }, [cart, isMobile, dispatch])

  const shieldIsInCart: boolean = shieldQuantity > 0

  const props = useMemo(
    () =>
      toContentfulCartDetails(
        data,
        isMobile,
        shieldIsInCart,
        productWarningMessage
      ),
    [data, isMobile, shieldIsInCart, productWarningMessage]
  )

  /** if the user manually enters a promo code we want to remove the utm_code */
  const [enableUtmCode, setEnableUtmCode] = useState(true)
  useUtmCode(enableUtmCode)

  const getCartDetailsProps = useCallback(
    (cart: ImmutableCart, cartIsUpdating: boolean): CartDetailsProps => {
      const standaloneProduct = prop('standaloneProduct', data) || []
      const discountApplied = Maybe.fromNull(getCartDiscountValue(cart)).map(
        toDiscountApplied(
          safeProp('discountAppliedText', data).getOrElse(''),
          getCartDiscountCode(cart)
        )
      )
      // This is messier than ideal, but this disables the quantity changers if the cart is currently updating
      const items: readonly CartLineItemProps[] = lineItem
        .map(_lineItems => {
          return _lineItems.map<CartLineItemProps>((lineItemProps, index) => {
            // TODO: fix type
            const parentId = defaultTo('')(
              prop('packageParentId', lineItemProps)
            )
            const previousItemSku = Maybe.fromUndefined(_lineItems[index - 1])
              .chain(safeProp('sku'))
              .getOrElse('')

            const product = Maybe.fromNull(
              productReference.find(
                product => product?.productId === lineItemProps?.sku
              )
            )

            const getQuantityProp = quantity => {
              const maxQuantity = productMaxQuantity.find(
                item => item && item.productId === prop('sku', lineItemProps)
              )
              return {
                ...quantity,
                disabled: cartIsUpdating,
                max: Maybe.fromNull(maxQuantity)
                  .chain(safeProp('maximumQuantity'))
                  .getOrElse(100)
              }
            }

            const description = product
              .chain(productInformation =>
                safeProp('productId', productInformation).map(sku => (
                  <CartLineItemDescription
                    key={sku}
                    onClick={(url: string) =>
                      navigateLink(url, parentId, previousItemSku)
                    }
                    productInformation={productInformation}
                    sku={sku}
                  />
                ))
              )
              .orNull()

            const subItemsLineItemSkus = (lineItemProps.subItems || []).map(
              subItem => subItem.subItemSku
            )
            const itemsNotInStock = componentsNotInStock(
              itemsProducts.map(products =>
                products.filter(
                  product =>
                    safeProp('sku', product).getOrElse('') ===
                    prop('sku', lineItemProps)
                )
              )
            )
            const subItemsProductsNotInStock = componentsNotInStock(
              subItemsProducts.map(products =>
                products.filter(product =>
                  subItemsLineItemSkus.includes(
                    safeProp('sku', product).getOrElse('')
                  )
                )
              )
            )
            const subItemsOOSMessage =
              subItemsProductsNotInStock.length > 0 &&
              renderCartLineOutOfStockMessage(subItemsProductsNotInStock)
            const itemsOOSMessage =
              itemsNotInStock.length > 0 &&
              renderCartLineOutOfStockMessage(itemsNotInStock)

            return {
              ...lineItemProps,
              itemDescriptionContent: description,
              outOfStockMessage: subItemsOOSMessage || itemsOOSMessage,
              // quantity changer props
              quantity: Maybe.fromNull(lineItemProps.quantity)
                .map(getQuantityProp)
                .orUndefined()
            }
          })
        })
        .orJust([])

      const selfMonitoringInCart: boolean = productIsInCart(
        items,
        'SSSLFCAM__7711192'
      )
      const sensorWarningMessage: ReactNode | undefined =
        renderSensorWarningMessage(
          productMaxQuantity,
          cameraSensorQuantity,
          shieldQuantity,
          items,
          siteLocale,
          selfMonitoringInCart
        )

      const disableCartSubmit: boolean =
        shieldIsInCart && shieldQuantity > shieldMaxQuantity
      const coreComponentsOosProps = toCoreComponentsOosProps(
        coreComponentsNotInStockList,
        cart.isThereAnySecurity,
        items
      )
      const freeShippingItem = getFreeShippingItem(
        shippingText,
        cart,
        siteLocale
      )
      const isHaveStandAloneProduct = items.find(item => {
        return standaloneProduct.find(
          product => product && product.sku === item.sku
        )
      })
      const isShowMessage = isHaveStandAloneProduct
        ? !isHaveStandAloneProduct
        : !cart.isThereAnySecurity
      isShowMessage
        ? cookies.set('standAloneProduct', true)
        : cookies.set('standAloneProduct', false)
      const isShowProductMessage = productWarningMessage.cata(
        always(isShowMessage),
        T
      )
      const rawTotalPrice = cart.get('totalPrice')

      const affirmPromoMessage = siteLocale === 'en-US' && (
        <AffirmPromoMessage
          affirmClient={affirmClient}
          className="affirm-as-low-as"
          onLearnMoreClick={optimizelyAffirmLearnMore}
          pageType="cart"
          price={rawTotalPrice}
          textAlignment="right"
        />
      )

      return {
        ...props,
        ...coreComponentsOosProps,
        affirmPromoMessage,
        cartMonitoringPlan: cartMonitoringPlanContent(),
        disabledCartSubmit: disableCartSubmit,
        // Adding the shipping & discount info when the cart is not empty.
        itemList:
          items.length > 0
            ? items.concat(freeShippingItem, discountApplied.toArray())
            : items,
        quantityLimitMessage: sensorWarningMessage,
        selfMonitoringInCart: selfMonitoringInCart,
        showWarning: isShowProductMessage,
        subTotal: totalPrice.getOrElse(''),
        ...(items.length > 0 && {
          affirmCard: affirmCardContent(),
          customerServiceCard: customerServiceCardContent(),
          tryItTestItCard: tryItTestItCardContent()
        })
      }
    },
    [
      itemsProducts,
      subItemsProducts,
      affirmClient,
      data,
      lineItem,
      cameraSensorQuantity,
      shieldQuantity,
      siteLocale,
      shieldIsInCart,
      coreComponentsNotInStockList,
      shippingText,
      props,
      totalPrice,
      productReference,
      productWarningMessage,
      productMaxQuantity,
      shieldMaxQuantity,
      affirmCardContent,
      customerServiceCardContent,
      tryItTestItCardContent,
      optimizelyAffirmLearnMore,
      optimizelyUpsellVariation,
      optimizelyUpsellReady
    ]
  )

  const cartRetrievalErrorMessage = () => {
    const cartRetrievalErrorPhoneNumber: string =
      siteLocale === 'en-GB' ? '0800-920-2420' : '888-910-1458'

    return (
      <div>
        <h2>Something isn&apos;t right. Try reloading the page.</h2>
        <p>
          If the issue persists, please call {cartRetrievalErrorPhoneNumber} to
          complete your order.
        </p>
      </div>
    )
  }

  const applyCouponCodeClick = useCallback(
    (code: string) => {
      setEnableUtmCode(false)
      const showMessage = (isError: boolean) => () => {
        setShowApplyCouponError(isError)
        isError ? dispatch(clearCartError()) : set(USER_DISCOUNT_CODE, code)
      }

      // Resetting apply coupon form and its message display.
      setShowApplyCoupon(true)
      setShowApplyCouponError(undefined)

      isEmpty(code)
        ? showMessage(true)
        : dispatch(
            IOUpdateDiscountCodeToCart(
              [code],
              showMessage(true),
              showMessage(false)
            )
          )
    },
    [dispatch]
  )

  const handleInvalidLocalCartId = (error: ServerError) => {
    error.status === 404 &&
      (() => {
        remove(LOCAL_STORAGE_CARTID)
        remove(getShippingInfoKey(LOCAL_STORAGE_CARTID))
        dispatch(clearCartError())
      })()
  }

  return (
    <Track>
      <>
        {cart.cata(
          () => {
            // Normally we would always use cart.cata(), but in this case we want to avoid showing the
            // loading view if the cart has a value and is just being updated, so the user doesn't see
            // a flicker when changing quantities
            return cart
              .map(_cart =>
                renderCart({
                  ...getCartDetailsProps(_cart, true),
                  applyCouponCodeClick,
                  showApplyCoupon,
                  showApplyCouponError
                })
              )
              .orJust(
                <div className="h-full w-full flex items-center justify-center content-center flex-col">
                  <h2 className="font-arizona sm:text-[1px] text-[35px] md:text-[55px] lg:text-[55px] m-0 my-6">
                    Loading your cart...
                  </h2>
                  <LoadingSpinner />
                </div>
              )
          },
          // Error state.
          (error: ServerError) => {
            handleInvalidLocalCartId(error)
            return (
              <BannerError height="responsive">
                {cartRetrievalErrorMessage()}
              </BannerError>
            )
          },
          // Cart empty state. CartDetails renders an empty cart message under the hood if there is no cart.
          () =>
            renderCart({
              ...props,
              applyCouponCodeClick,
              showApplyCoupon,
              showApplyCouponError
            }),
          _cart =>
            renderCart({
              ...getCartDetailsProps(_cart, false),
              applyCouponCodeClick,
              showApplyCoupon,
              showApplyCouponError
            })
        )}
      </>
    </Track>
  )
}

export const query = graphql`
  #graphql
  fragment cartDetailsInformation on ContentfulCartDetails {
    id
    internal {
      type
    }
    titleHeader
    shippingText
    discountAppliedText
    linkHeader {
      linkText
      linkUrl
    }
    checkoutButtonHeader {
      text
      type
      url
    }
    checkoutButtonFooter {
      text
      type
      url
    }
    creditCardLogo {
      id
      title
      description # TODO: get description from gatsbyImageData
      gatsbyImageData(layout: CONSTRAINED, width: 300, placeholder: BLURRED)
    }
    productReference {
      productId
      description {
        raw
        references {
          ... on ContentfulPlaceholder {
            contentful_id
            __typename
            type
          }
        }
      }
    }
    productMaxQuantity {
      maximumQuantity
      productId
    }
    standaloneProduct {
      sku
    }

    freeMonitoring: rightSection2 {
      ... on ContentfulCartFreeMonitoringPlanWidget {
        cartDetailsTitle
        cartDetailsPromoText
        button {
          text
          type
          url
        }
        descriptionImage {
          title
          text {
            raw
          }
        }
        title
        productId
      }
    }
    belowCartContent {
      ... on ContentfulConditionalContent {
        ...conditionalContent
      }
    }
    partnerCaptureModal {
      ... on ContentfulConditionalContent {
        ...conditionalContent
      }
    }
    productMessageWithoutSystem {
      productId
      description {
        raw
      }
    }
  }
`
