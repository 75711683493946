import { GatsbyImage } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Heading, SSButton } from '@simplisafe/ss-react-components'
import { CardShop } from '@simplisafe/ss-react-components'
import type { HeadingProps } from '@simplisafe/ss-react-components/Heading'
import { graphql, Link } from 'gatsby'
import { Maybe } from 'monet'
import applySpec from 'ramda/src/applySpec'
import path from 'ramda/src/path'
import React, { ReactNode } from 'react'

import { ContentfulCardShop } from '../../../graphql'
import { leadingSlashIt } from '../../util/helper'

// @ts-expect-error TS(2339) FIXME: Property 'img' does not exist on type 'ContentfulC... Remove this comment to see the full error message
const toGatsbyImage = (
  imgData: ContentfulCardShop['img'] | null
): ReactNode => {
  const altText = Maybe.fromNull(imgData).chain(safeProp('title')).getOrElse('')

  return Maybe.fromNull(imgData).cata(
    () => null,
    imgProps => (
      <GatsbyImage
        // @ts-expect-error TS(2322) FIXME: Type '{ alt: any; image: any; imgStyle: { objectFi... Remove this comment to see the full error message
        alt={altText}
        image={imgProps}
        imgStyle={{ objectFit: 'contain' }}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    )
  )
}

const toButtonLink = (
  pageLink: ContentfulCardShop['pageLink'],
  linkText: ContentfulCardShop['linkText']
): ReactNode => {
  // Link URL.
  const url: string = Maybe.fromNull(pageLink)
    .chain(safeProp('pageUrl'))
    .map(leadingSlashIt)
    .getOrElse('')

  // Link Text.
  const text: string = Maybe.fromNull(linkText).getOrElse('')

  // Only return a link url and link text exists; else, return an empty ReactNode.
  return url && text ? (
    <Link to={url}>
      <SSButton color="primaryHollow" minWidth="small" type="div">
        {text}
      </SSButton>
    </Link>
  ) : null
}

export type CardShopComponentProps = {
  readonly data: ContentfulCardShop
}

const toHeadingProps = applySpec<HeadingProps>({
  bold: prop('bold'),
  children: path(['text', 'text']),
  color: path(['color', 'color']),
  element: prop('headingType'),
  headingAspect: prop('fontSizeAspect'),
  useTailwind: prop('useTailwind')
})

function CardShopComponent({ data }: CardShopComponentProps) {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'bgColor' does not exist on type 'Content... Remove this comment to see the full error message
    bgColor: backgroundColor,
    flag,
    hasPadding,
    heading,
    id,
    // @ts-expect-error TS(2339) FIXME: Property 'img' does not exist on type 'ContentfulC... Remove this comment to see the full error message
    img: image,
    linkText,
    pageLink,
    textColor,
    title
  } = data

  return (
    <CardShop
      backgroundColor={Maybe.fromNull(backgroundColor)
        .chain(safeProp('color'))
        .getOrElse('neutralLightGray')}
      color={Maybe.fromNull(textColor)
        .chain(safeProp('color'))
        .getOrElse('neutralBlack')}
      flag={flag ? flag : undefined}
      footer={toButtonLink(pageLink, linkText)}
      // Default hasPadding to true unless explicitly set to false. Undefined or null will default to true.
      hasPadding={hasPadding === false ? false : true}
      heading={<Heading {...toHeadingProps(heading)} />}
      img={toGatsbyImage(image)}
      key={id}
      title={title ? title : ''}
    />
  )
}

export default CardShopComponent

export const CardShopQuery = graphql`
  #graphql
  fragment cardShop on ContentfulCardShop {
    bgColor: backgroundColor {
      color
    }
    flag
    hasPadding
    heading {
      ... on ContentfulHeading {
        ...headingFragment
      }
    }
    id
    img: image {
      id
      # Quality set to 95 because PNG as a webP looked pixelated at 50% quality (default).
      # 95% gives us filesize savings (65kB => 16kB) with almost perfect quality.
      gatsbyImageData(
        layout: CONSTRAINED
        width: 540
        quality: 95
        placeholder: BLURRED
      )
      title
    }
    internal {
      type
    }
    linkText
    pageLink {
      pageUrl
    }
    textColor {
      color
    }
    title
  }
`
