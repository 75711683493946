import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type NameProps = {
  readonly nameFieldLabel: string
  readonly nameFieldPlaceholder: string
}

function Name(props: NameProps) {
  return (
    <div data-component={'Name'}>
      <FormField hideLabel={true} label={props.nameFieldLabel} name="name">
        <Text fontWeight="medium" useTailwind>
          {props.nameFieldLabel}
        </Text>
        <FormTextInput
          fullWidth={true}
          name="name"
          placeholder={props.nameFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Name
