import addDays from 'date-fns/addDays'
import addWeeks from 'date-fns/addWeeks'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import getYear from 'date-fns/getYear'
import isSameDay from 'date-fns/isSameDay'
import isWeekend from 'date-fns/isWeekend'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { always, cond, defaultTo, equals, find, T } from 'lodash/fp'

// Takes a date and a day index and returns the next occurence of that day. I.E.
// (MondayToday(2022-08-01), Monday(1)) => Monday 1st
// (Tuesday(2022-08-02), Monday(1) => Monday 8th
export const getNextOccurence = (date: Date, dayOfWeekIndex: number) =>
  cond([
    [equals(dayOfWeekIndex), always(date)],
    [
      d => d > dayOfWeekIndex,
      (day: number) => addDays(date, 7 - day + dayOfWeekIndex)
    ],
    [T, (day: number) => addDays(date, dayOfWeekIndex - day)]
  ])(getDay(date))

// Takes a date and a day index and returns the previuous occurence of that day. I.E.
// (Tuesday(2022-08-02), Monday(1)) => Monday 1st
export const getPrevOccurence = (date: Date, dayOfWeekIndex: number) =>
  cond([
    [equals(dayOfWeekIndex), always(date)],
    [
      d => d > dayOfWeekIndex,
      (day: number) => addDays(date, -1 * (day - dayOfWeekIndex))
    ],
    [T, (day: number) => addDays(date, -1 * (day - 0 + (7 - dayOfWeekIndex)))]
  ])(getDay(date))

// Get the next occurence of a day from the beginning of the month
export const getFirstOccurence = (date: Date, dayOfWeekIndex: number) =>
  getNextOccurence(startOfMonth(date), dayOfWeekIndex)

export const getCurrentYear = () => getYear(new Date())

export const getLastOfMonth = (date: Date, dayOfWeekIndex: number) =>
  getPrevOccurence(lastDayOfMonth(date), dayOfWeekIndex)

export const getLastMemorialDay = (year: number) =>
  getLastOfMonth(new Date(year, 4), 1)

export const getLaborDay = (year: number) =>
  getFirstOccurence(new Date(year, 8), 1)
// adds 3 weeks to the first occurence of thursday(4) in november(10)
export const getThanksgivingDate = (year: number) =>
  addWeeks(getFirstOccurence(new Date(year, 10), 4), 3)

/**
 * Return a list of holidays
 * This includes a list of holidays for US only
 */
export const businessHolidaysList = (): readonly Date[] => {
  const currentYear = getCurrentYear()
  const memorialDay = getLastMemorialDay(currentYear)
  const julyFourthHoliday = new Date(currentYear, 6, 4)
  const thanksgivingDay = getThanksgivingDate(currentYear)
  const christmasEve = new Date(currentYear, 11, 24)
  const christmasHoliday = new Date(currentYear, 11, 25)
  const newYearsHoliday = new Date(currentYear + 1, 0, 1)
  const laborDay = getLaborDay(currentYear)

  return [
    memorialDay,
    julyFourthHoliday,
    laborDay,
    thanksgivingDay,
    christmasEve,
    christmasHoliday,
    newYearsHoliday
  ]
}

export const isHoliday = (date: Date) => {
  const holidaysList = businessHolidaysList()
  return holidaysList.some(d => isSameDay(date, d))
}

export const isBusinessDay = (date: Date) =>
  !isHoliday(date) && !isWeekend(date)

//Finds in the next 7 days, the first one that is a business day
export const returnNextBusinessDay = () => {
  const currentDate = new Date()

  const next7Days = [0, 0, 0, 0, 0, 0, 0].map((_, index) =>
    addDays(currentDate, index + 1)
  )

  //Find the next business day in a week
  const nextBusinessDay = find(d => isBusinessDay(d), next7Days)

  return format(defaultTo(next7Days[0], nextBusinessDay), 'LLLL d')
}
