import { FormField, FormTextInput } from '@simplisafe/ss-react-components'
import React from 'react'

type EmailProps = {
  readonly emailFieldLabel: string
  readonly emailFieldPlaceholder: string
}

function Email(props: EmailProps) {
  return (
    <div className="rc-col-span-12 md:rc-col-span-8" data-component={'Email'}>
      <FormField
        hideLabel={true}
        label={props.emailFieldLabel}
        labelPosition={'top'}
        name="email"
      >
        <FormTextInput
          aria-label={props.emailFieldLabel}
          fullWidth={true}
          name="email"
          placeholder={props.emailFieldPlaceholder}
          style={{
            maxHeight: '56px',
            width: '100%'
          }}
        />
      </FormField>
    </div>
  )
}

export default Email
