import { safeProp } from '@simplisafe/monda'
import {
  HeaderNavigation,
  HeaderNavigationDrawer
} from '@simplisafe/ss-react-components'
import { Maybe } from 'monet'
import map from 'ramda/src/map'
import propOr from 'ramda/src/propOr'
import React, { HTMLAttributes, ReactElement } from 'react'
import { useTracking } from 'react-tracking'

import { setGtmCustomEvent } from '../../util/analytics'
import { HeaderDropdownItem } from './HeaderDropdown'
import { isLogo, logo } from './helpers'
import { useHeaderData } from './hooks'
import NavigationDrawerBottomItem from './NavigationDrawerBottomItem'
import { renderNavItem } from './NavItem'
import { ContentfulHeaderFragment, NavItem } from './query'

function MobileOrTabletHeader({
  data,
  className
}: HTMLAttributes<HTMLDivElement> & {
  readonly data: ContentfulHeaderFragment
}) {
  const { cartItemCount, mobileOrTabletRightSideContentFieldName } =
    useHeaderData()
  const { trackEvent } = useTracking()

  const mobileLogo = safeProp(
    mobileOrTabletRightSideContentFieldName,
    data
  ).chain(_data => {
    const logo = _data.find(navItem => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      const linkType = propOr<string, string>('', 'linkType', navItem)
      // @ts-expect-error TS(2352) FIXME: Conversion of type '<V>(p: string) => V' to type '... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      return isLogo(linkType as string)
    })
    return Maybe.fromNull(logo).chain(_logo =>
      Maybe.fromNull(renderNavItem(logo, true, cartItemCount, true, trackEvent))
    )
  })

  const drawerTopContent = safeProp('mobileDrawerTopContent', data).map(
    map(item => (
      <HeaderDropdownItem
        isMobile={true}
        item={item}
        key={item.id}
        showImage={true}
        trackEvent={trackEvent}
      />
    ))
  )

  const drawerCenterContent = safeProp('mobileDrawerCenterContent', data).map(
    map<NavItem, ReactElement | null>(navItem =>
      renderNavItem(navItem, false, cartItemCount, true, trackEvent)
    )
  )

  const drawerBottomContent = safeProp('mobileDrawerBottomContent', data).map(
    map<NavItem, ReactElement>(navItem => (
      <NavigationDrawerBottomItem
        cartItemCount={cartItemCount}
        item={navItem}
        key={navItem.id}
        trackEvent={trackEvent}
      />
    ))
  )

  const headerContents = safeProp(mobileOrTabletRightSideContentFieldName, data)
    .map(x =>
      x.reduce((acc: readonly ReactElement[], item, i) => {
        const showHamburgerMenu =
          drawerTopContent.isJust() ||
          drawerCenterContent.isJust() ||
          drawerBottomContent.isJust()

        const contentGroup = item?.['contentsToGroup']

        const navItems = !!contentGroup
          ? contentGroup.map((item: any) =>
              renderNavItem(item, true, cartItemCount, true, trackEvent)
            )
          : renderNavItem(item, true, cartItemCount, true, trackEvent)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        const linkType = propOr<string, string>('', 'linkType', item)

        const hamburgerMenu =
          showHamburgerMenu && i === 0 ? (
            <HeaderNavigationDrawer
              bottom={<>{drawerBottomContent.orNull()}</>}
              display={true}
              header={mobileLogo.orUndefined()}
              key={'mobile-navigation-drawer'}
              middle={<>{drawerCenterContent.orNull()}</>}
              onHamburgerClick={() =>
                setGtmCustomEvent({
                  event: 'buttonClick',
                  eventAction: 'open',
                  eventCategory: 'navigation',
                  eventLabel: 'hamburger'
                })
              }
              top={<>{drawerTopContent.orNull()}</>}
            />
          ) : null

        return [
          ...acc,
          <HeaderNavigation
            key={`header-nagivation=${item.id}`}
            // @ts-expect-error TS(2322) FIXME: Type 'string | (<V>(p: string) => V)' is not assig... Remove this comment to see the full error message
            navigationType={isLogo(linkType) ? `${logo}-${i}` : linkType}
          >
            <>
              {hamburgerMenu}
              {navItems}
            </>
          </HeaderNavigation>
        ]
      }, [])
    )
    .orNull()

  return <div className={className}>{headerContents}</div>
}

export { MobileOrTabletHeader }
