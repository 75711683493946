import { GatsbyImage, GatsbyImageSchema } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import hasPath from '@simplisafe/ewok/ramda-adjunct/hasPath'
import { safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import props from 'ramda/src/props'
import React, { CSSProperties, FC, ReactNode } from 'react'

import { ContentfulAsset, ContentfulImage } from '../../../graphql'

/**
 *
 * A component to render an image, or a video without controls.
 *
 */
export type ImageProps = {
  readonly bgImgStyles?: CSSProperties
  /** If children are passed when rendering an image, it will be rendered as a background image */
  readonly children?: ReactNode
  /** If rendering a background image for a Row, it needs to accept a class name for styling */
  readonly className?: string
  readonly data: ContentfulImage
  readonly roundedCorners?: boolean
}

// TODO Look into gatsby video converters
// the videos we have now are already optimized, but we need to make sure we're handling these correctly
// we also want to generate multiple formats
const renderVideo = (asset: Partial<ContentfulAsset>) => {
  const id = prop('id', asset)
  const url = path(['file', 'url'], asset)
  const type = path(['file', 'contentType'], asset)
  return (
    <video
      autoPlay
      // enable controls when autoplay enabled to comply w/ WCAG Success Criterion 2.2.2 Pause, Stop, Hide
      controls
      key={id}
      loop
      muted
      playsInline
      preload="auto"
    >
      <source src={url} type={type} />
    </video>
  )
}

type RenderImageProps = {
  readonly children?: ReactNode
  readonly className?: string
  readonly description?: string
  readonly id?: string
  readonly bgImgStyles?: CSSProperties
  readonly title?: string
  readonly roundedCorners?: boolean
}

const renderImage = ({
  children,
  className,
  description,
  id,
  title,
  roundedCorners = true,
  bgImgStyles: bgImgStylesProp
}: RenderImageProps) =>
  function (image: GatsbyImageSchema) {
    const styles = roundedCorners ? { borderRadius: '10px' } : {}
    const bgImgStyles = bgImgStylesProp
      ? { ...styles, ...bgImgStylesProp }
      : styles

    return children ? (
      <BgImage
        alt={description}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: string | number | true | ReactEl... Remove this comment to see the full error message
        className={className}
        image={getImage(image)}
        style={bgImgStyles}
        key={id}
        title={title}
      >
        {children}
      </BgImage>
    ) : (
      <GatsbyImage
        // @ts-expect-error TS(2322) FIXME: Type '{ alt: string; className: string; image: { d... Remove this comment to see the full error message
        alt={description}
        className={className}
        image={image}
        style={styles}
      />
    )
  }

const Media: FC<ImageProps> = ({
  bgImgStyles,
  children,
  className,
  data
}: ImageProps) => {
  // @ts-expect-error TS(2345): Argument of type '"imageItem"' is not assignable t... Remove this comment to see the full error message
  const image = safeProp('imageItem', data)
  const [id, title, description] = image
    .map(props(['id', 'title', 'description']))
    .orJust([])
    .map(val => val ?? undefined)
  // @ts-expect-error TS(2345) FIXME: Argument of type '"imageRoundedCorners"' is not as... Remove this comment to see the full error message
  const roundedCorners = safeProp('imageRoundedCorners', data).orJust(true)
  const assetWithFileUrl = image.filter(hasPath(['file', 'url']))

  return (
    <>
      {
        // if an asset has a fluid value that means it's an image
        image
          .map(
            renderImage({
              bgImgStyles,
              children,
              className,
              // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
              description,
              // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
              id,
              roundedCorners,
              // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
              title
            })
          )
          // if it doesn't have a fluid value that means it's a video
          // catchMap will be run if the above .map is a None. If it's not an image, we try and render the video content
          .catchMap(() => assetWithFileUrl.map(renderVideo))
          // Now we either render the image, video, or default to null
          .orNull()
      }
    </>
  )
}

export const query = graphql`
  #graphql
  fragment contentfulImage on ContentfulImage {
    id
    internal {
      type
    }
    imageRoundedCorners: roundedCorners
    imageItem: image {
      # id
      # contentful_id
      # title
      # description
      # file {
      #   url
      #   contentType
      # }
      gatsbyImageData(layout: CONSTRAINED, width: 1440, placeholder: BLURRED)
    }
  }
`

export default Media
