import { Just } from 'monet'

import type { PromoBannerSchema } from './promoBannerSchema'
import type { PromoOverridesSchema } from './promoOverridesSchema'

const mockValidUntil = Just('2030-04-22T10:00:00-06:00')

export const mockUsePromoBannerData: PromoBannerSchema = {
  buttonBackgroundColor: '',
  buttonTextColor: '',
  discountText: 'discount text',
  displayEndTimeCalloutOverride: false,
  endTime: mockValidUntil.getOrElse(''),
  endTimeCalloutOverride: mockValidUntil.getOrElse(''),
  hasCountdown: true,
  hasEmailInput: true,
  hasEndDateCallout: true,
  hasFreeGiftItem: true,
  hasSaleName: true,
  lineItemLabel: 'mock line item label',
  lineItemTitle: 'mock line item title',
  locale: 'en-US',
  offerLabel: 'mock offer label',
  offerTitle: 'mock offer title',
  saleName: 'mock sale name'
}

export const mockUsePromoOverrideData: PromoOverridesSchema = {
  chooseMonitoringPage: '30% Plus an Extra 10%',
  discountSummary: 'EXTRA 10% OFF',
  // begin holiday banner workflows.
  endsTextLabel: 'Early Access',
  heroStickerBackgroundColor: '#5DBA82',
  heroStickerDisclaimerText: 'Any new system with Interactive Monitoring',
  heroStickerFreeGiftLineItemText: '+ Free Camera',
  heroStickerImage: 'mock-img-src.png', // For a potential A/B test showing promo product image inside the sticker.
  heroStickerSaleName: 'Black Friday Sale',
  heroStickerTextColor: '#FFFFFF',
  isHolidayBanner: false,
  // end holiday banner workflows.
  listingFlag: 'an extra 10%',
  productFlag: 'EXTRA 10% OFF',
  toggleBox: 'an extra 10%'
}
