import { GatsbyImage } from '@lib/components'
import { TrackingData } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { LOCALE_INFO } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { brazeLogCustomEvent } from '@lib/tracking'
import { CartCount } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import and from 'ramda/src/and'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { setGtmCustomEvent } from '../../util/analytics'
import { handleLinkClick, isLogo, logoLinkUrl } from './helpers'
import {
  ContentfulGroupSectionFragment,
  ContentfulLinkFragment,
  ContentfulMenuItemsFragment
} from './query'

const imgLogoStyle = {
  objectPosition: 'left center' /* //used for us logo on the left side */
}

type HeaderLinkProps = {
  readonly link:
    | ContentfulGroupSectionFragment
    | ContentfulLinkFragment
    | ContentfulMenuItemsFragment
  readonly cartItemCount?: number
  readonly trackEvent: (_data: Partial<TrackingData>) => void
}

export function HeaderLink({
  link,
  cartItemCount,
  trackEvent
}: HeaderLinkProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkText = propOr<string, string>('', 'linkText', link)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkUrlCtfl = propOr<string, string>('', 'linkUrl', link)
  const id = prop('id', link)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkSubtext = propOr<string, string>('', 'linkSubtext', link)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkIcon = propOr<
    ContentfulLinkFragment['linkIcon'],
    ContentfulLinkFragment['linkIcon']
  >(undefined, 'linkIcon', link)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkImage = propOr<
    ContentfulLinkFragment['linkImage'],
    ContentfulLinkFragment['linkImage']
  >(undefined, 'linkImage', link)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkType = propOr<string, string>('', 'linkType', link)

  const { domain } = LOCALE_INFO
  // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
  const linkUrl = isLogo(linkType) ? logoLinkUrl(linkUrlCtfl) : linkUrlCtfl

  const cartIcon =
    linkUrl === '/cart' ? (
      <CartCount count={cartItemCount} key={`cart-count-${cartItemCount}`} />
    ) : null

  const imageNode = linkIcon ? (
    <GatsbyImage
      alt={linkText}
      // @ts-expect-error TS(2560) FIXME: Value of type '<V>(p: string) => V' has no propert... Remove this comment to see the full error message
      image={linkIcon}
      loading="eager"
      style={{
        height: '24px',
        width: '24px'
      }}
    />
  ) : null

  const children = (
    <>
      {!cartIcon && imageNode}
      {/* @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message */}
      {!linkIcon && !isLogo(linkType) && linkText}
      {cartIcon}
      {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
      {linkSubtext && (
        <p>
          <small>{linkSubtext}</small>
        </p>
      )}
    </>
  )

  // @ts-expect-error TS(2339) FIXME: Property 'startsWith' does not exist on type 'stri... Remove this comment to see the full error message
  return linkUrl && linkUrl.startsWith('tel:') ? (
    <a
      // @ts-expect-error TS(2322) FIXME: Type 'string | (<V>(p: string) => V)' is not assig... Remove this comment to see the full error message
      href={linkUrl}
      key={id}
      onClick={() => {
        setGtmCustomEvent({
          event: 'buttonClick',
          eventAction: 'phone',
          eventCategory: 'contact-us',
          // @ts-expect-error TS(2339) FIXME: Property 'replace' does not exist on type 'string ... Remove this comment to see the full error message
          eventLabel: linkUrl.replace('tel:', '')
        })
        brazeLogCustomEvent('click_to_call')
      }}
      style={{
        fontSize: '14px',
        textDecoration: 'inherit'
      }}
    >
      {children}
    </a>
  ) : (
    <Link
      aria-label={cartIcon ? `Cart with ${cartItemCount} items` : undefined}
      key={id}
      // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      onClick={() => isNotNil(linkUrl) && handleLinkClick(linkText, trackEvent)}
      state={{ source: 'header' }}
      style={{ textDecoration: 'inherit' }}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      to={linkUrl || ''}
    >
      {linkImage && (
        <GatsbyImage
          alt={linkText}
          // @ts-expect-error TS(2560) FIXME: Value of type '<V>(p: string) => V' has no propert... Remove this comment to see the full error message
          image={linkImage}
          imgStyle={{
            objectFit: 'contain',

            // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
            ...(and(equals(domain, 'us'), isLogo(linkType)) && imgLogoStyle)
          }}
          loading="eager"
          style={{
            height: '100%',
            width: '100%'
          }}
        />
      )}
      {children}
    </Link>
  )
}
