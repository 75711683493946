import path from '@simplisafe/ewok/ramda/path'
import { FacebookPost } from '@simplisafe/ss-react-components'
import { FacebookPostProps } from '@simplisafe/ss-react-components/FacebookPost'
import defaultTo from 'ramda/src/defaultTo'
import isEmpty from 'ramda/src/isEmpty'
import React from 'react'
import { Helmet } from 'react-helmet'

import { PageProps } from '../Page'

export type FacebookPostComponentProps = {
  readonly pageContext: PageProps['pageContext']
}

function FacebookPostComponent({ pageContext }: FacebookPostComponentProps) {
  const config: FacebookPostProps = {
    numOfPosts: 5,
    orderBy: 'time',
    width: '100%'
  }
  // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | { readonly head: readonly... Remove this comment to see the full error message
  const sdkUrl: string = defaultTo('')(
    path(['fbConfig', 'sdkUrl'], pageContext)
  )
  // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | { readonly head: readonly... Remove this comment to see the full error message
  const appId: string = defaultTo('')(path(['fbConfig', 'appId'], pageContext))
  const isFBPostAvailable = !isEmpty(sdkUrl) && !isEmpty(appId)
  return isFBPostAvailable ? (
    <>
      <Helmet>
        <meta content={appId} property="fb:app_id" />
        <script async crossOrigin="anonymous" defer src={sdkUrl}></script>
      </Helmet>
      <FacebookPost {...config}></FacebookPost>
    </>
  ) : null
}

export default FacebookPostComponent
