import { constVoid } from 'fp-ts/function'

/**
 * Takes in any number of arguments and always returns void.
 */
function voidFn<T>(...__args: readonly T[]) {
  return constVoid()
}

export default voidFn
