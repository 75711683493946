import { safeProp } from '@simplisafe/monda'
import { Column, Row, Text } from '@simplisafe/ss-react-components'
import React from 'react'

import CrimeAssessmentItem from './CrimeAssessmentItem'
import PropertyStatsBar from './PropertyStatsBar'
import { IRiskDataModel } from './types'
import { getPropertyDangerous, scoreToBGColor } from './utils'

type CrimeRiskAssessmentProps = {
  readonly riskData: IRiskDataModel
}

function CrimeRiskAssessment({ riskData }: CrimeRiskAssessmentProps) {
  const violentCrimeScore = safeProp('crimeData', riskData).chain(
    safeProp('violentCrimeScore')
  )
  const violentCrimeNumber = safeProp('crimeData', riskData).chain(
    safeProp('violentCrimeNumber')
  )

  const propertyCrimeScore = safeProp('crimeData', riskData).chain(
    safeProp('propertyCrimeScore')
  )
  const propertyCrimeNumber = safeProp('crimeData', riskData).chain(
    safeProp('propertyCrimeNumber')
  )

  // vehicule typo is in the API
  const vehicleTheftScore = safeProp('crimeData', riskData).chain(
    safeProp('vehiculeTheftScore')
  )
  const vehicleTheftNumber = safeProp('crimeData', riskData).chain(
    safeProp('vehicleTheftNumber')
  )

  const vandalismScore = safeProp('crimeData', riskData).chain(
    safeProp('vandalismScore')
  )
  const vandalismNumber = safeProp('crimeData', riskData).chain(
    safeProp('vandalismNumber')
  )

  const crimeScore = safeProp('crimeScore', riskData)
  const population = safeProp('crimeData', riskData).chain(
    safeProp('population')
  )

  return (
    <div className="max-w-8xl lg:px-7 m-auto mt-5">
      <Text
        className="text-simplyblue-400 text-center text-3xl font-medium mb-10"
        useTailwind
      >
        Crime Risk Assessment <p className="inline text-xs align-super"> (2)</p>
      </Text>
      <Row alignItems="center" rounded="none">
        {riskData.crimeData && riskData.crimeData.source && (
          <Column rounded="none" spans={[12, 6]}>
            {violentCrimeScore.isSome() && violentCrimeNumber.isSome() && (
              <CrimeAssessmentItem
                crimeNumber={violentCrimeNumber.getOrElse(0)}
                crimeScore={Math.round(violentCrimeScore.getOrElse(0))}
                description={`violent crimes ${riskData.crimeData.source} in this area yearly`}
                fillClassName={scoreToBGColor(violentCrimeScore.getOrElse(0))}
                icon={
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M622.3 271.1l-115.1-45.01c-4.125-1.629-12.62-3.754-22.25 0L369.8 271.1C359 275.2 352 285.1 352 295.1c0 111.6 68.75 188.8 132.9 213.9c9.625 3.75 18 1.625 22.25 0C558.4 489.9 640 420.5 640 295.1C640 285.1 633 275.2 622.3 271.1zM496 462.4V273.2l95.5 37.38C585.9 397.8 530.6 446 496 462.4zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM320.6 310.3C305.9 306.3 290.6 304 274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66 512H413.3c3.143 0 5.967-1.004 8.861-1.789C369.7 469.8 324.1 400.3 320.6 310.3z"
                      fill="#162546"
                    />
                  </svg>
                }
                title="Violent Crime"
              />
            )}
            {propertyCrimeScore.isSome() && propertyCrimeNumber.isSome() && (
              <CrimeAssessmentItem
                className="mt-8"
                crimeNumber={propertyCrimeNumber.getOrElse(0)}
                crimeScore={Math.round(propertyCrimeScore.getOrElse(0))}
                description={`property crimes ${riskData.crimeData.source} in this area yearly`}
                fillClassName={scoreToBGColor(propertyCrimeScore.getOrElse(0))}
                icon={
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L522.1 193.9C513.6 192.7 504.9 192 496 192C404.2 192 328.8 262.3 320.7 352L320 352zM352 368C352 288.5 416.5 224 496 224C575.5 224 640 288.5 640 368C640 447.5 575.5 512 496 512C416.5 512 352 447.5 352 368zM496 464C509.3 464 520 453.3 520 440C520 426.7 509.3 416 496 416C482.7 416 472 426.7 472 440C472 453.3 482.7 464 496 464zM479.1 288V368C479.1 376.8 487.2 384 495.1 384C504.8 384 511.1 376.8 511.1 368V288C511.1 279.2 504.8 272 495.1 272C487.2 272 479.1 279.2 479.1 288z"
                      fill="#162546"
                    />
                  </svg>
                }
                title="Property Crime"
              />
            )}
            {vehicleTheftScore.isSome() && vehicleTheftNumber.isSome() && (
              <CrimeAssessmentItem
                className="mt-8"
                crimeNumber={vehicleTheftNumber.getOrElse(0)}
                crimeScore={Math.round(vehicleTheftScore.getOrElse(0))}
                description={`vehicle theft ${riskData.crimeData.source} in this area yearly`}
                fillClassName={scoreToBGColor(vehicleTheftScore.getOrElse(0))}
                icon={
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z"
                      fill="#162546"
                    />
                  </svg>
                }
                title="Vehicle Theft"
              />
            )}
            {vandalismScore.isSome() && vandalismNumber.isSome() && (
              <CrimeAssessmentItem
                className="mt-8"
                crimeNumber={vandalismNumber.getOrElse(0)}
                crimeScore={Math.round(vandalismScore.getOrElse(0))}
                description={`vandalism crimes ${riskData.crimeData.source} in this area yearly`}
                fillClassName={scoreToBGColor(vandalismScore.getOrElse(0))}
                icon={
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M568.1 196.3l-22.62-22.62c-4.533-4.533-10.56-7.029-16.97-7.029s-12.44 2.496-16.97 7.029l-5.654 5.656l-20.12-20.12c4.596-23.46-2.652-47.9-19.47-64.73l-45.25-45.25C390.2 17.47 347.1 0 303.1 0C258.2 0 216 17.47 184.3 49.21L176.5 57.05L272.5 105.1v13.81c0 18.95 7.688 37.5 21.09 50.91l49.16 49.14c13.44 13.45 31.39 20.86 50.54 20.86c4.758 0 9.512-.4648 14.18-1.387l20.12 20.12l-5.654 5.654c-9.357 9.357-9.357 24.58-.002 33.94l22.62 22.62c4.535 4.533 10.56 7.031 16.97 7.031s12.44-2.498 16.97-7.031l90.53-90.5C578.3 220.8 578.3 205.6 568.1 196.3zM270.9 192.4c-3.846-3.846-7.197-8.113-10.37-12.49l-239.5 209.2c-28.12 28.12-28.16 73.72-.0371 101.8C35.12 505 53.56 512 71.1 512s36.84-7.031 50.91-21.09l209.1-239.4c-4.141-3.061-8.184-6.289-11.89-9.996L270.9 192.4z"
                      fill="#162546"
                    />
                  </svg>
                }
                title="Vandalism"
              />
            )}

            {riskData.crimeData && riskData.crimeData.source && (
              <div className="text-xs mt-5 text-gray-500">
                (2) Average yearly number of crimes {riskData.crimeData.source}{' '}
                in your block group. The crime score takes into account the
                number and severity of crimes as well as the population density
                of your block group.
                <a
                  className="underline text-gray-500"
                  href="https://www.mdpi.com/2220-9964/9/11/645/htm"
                  rel="noreferrer"
                  target="_blank"
                >
                  More information
                </a>
                .
              </div>
            )}
          </Column>
        )}

        <Column rounded="none" spans={[12, 6]}>
          <div className="w-full bg-gray-100 border border-gray-200 border-solid shadow-sm shadow-gray-200 py-4 px-6 text-simplyblue-400">
            <div className="font-light mb-3">
              How this property compares to others
            </div>

            {crimeScore.isSome() && (
              <div className="grid grid-cols-5">
                <div
                  className={
                    'col-span-2 xl:col-span-1 text-xs mx-auto mt-2 font-bold'
                  }
                >
                  Crime Score
                </div>
                <div className="col-span-3 xl:col-span-4 my-2">
                  <div
                    className={
                      'col-span-2 xl:col-span-1 text-xs mx-auto font-bold'
                    }
                  >
                    % of US Neighborhoods
                  </div>
                </div>
                <PropertyStatsBar
                  barClassName="w-[25%]"
                  barFillClassName="bg-rose-700"
                  barLabel="11.3%"
                  highlight={
                    crimeScore.getOrElse(0) > 80 &&
                    crimeScore.getOrElse(0) <= 100
                  }
                  label={'80-100'}
                />
                <PropertyStatsBar
                  barClassName="w-[14%]"
                  barFillClassName="bg-rose-500"
                  barLabel="6.5%"
                  highlight={
                    crimeScore.getOrElse(0) > 60 &&
                    crimeScore.getOrElse(0) <= 80
                  }
                  label={'60-80'}
                />
                <PropertyStatsBar
                  barClassName="w-[26%]"
                  barFillClassName="bg-orange-400"
                  barLabel="11.5%"
                  highlight={
                    crimeScore.getOrElse(0) > 40 &&
                    crimeScore.getOrElse(0) <= 60
                  }
                  label={'40-60'}
                />
                <PropertyStatsBar
                  arrowOnly={true}
                  barClassName="w-[56%]"
                  barFillClassName="bg-emerald-500"
                  barLabel="25.4%"
                  highlight={
                    crimeScore.getOrElse(0) > 20 &&
                    crimeScore.getOrElse(0) <= 40
                  }
                  label={'20-40'}
                />
                <PropertyStatsBar
                  arrowOnly={true}
                  barClassName="w-[100%]"
                  barFillClassName="bg-emerald-800"
                  barLabel="45.2%"
                  highlight={
                    crimeScore.getOrElse(0) > 0 && crimeScore.getOrElse(0) <= 20
                  }
                  label={'0-20'}
                />
              </div>
            )}

            {population.isSome() &&
              population.getOrElse(0) > 100 &&
              crimeScore.isSome() &&
              violentCrimeNumber.isSome() &&
              propertyCrimeNumber.isSome() && (
                <div>
                  <div className="font-light my-3">More details</div>
                  <div className="text-xs">
                    <p className="my-2">
                      {getPropertyDangerous(crimeScore.getOrElse(0))}
                    </p>
                    <p className="my-2">
                      <b>
                        {(
                          (violentCrimeNumber.getOrElse(0) * 1000) /
                          population.getOrElse(1)
                        ).toFixed(0)}
                      </b>{' '}
                      violent crimes per <b>1000</b> inhabitants.
                    </p>
                    <p className="my-2">
                      <b>
                        {(
                          (propertyCrimeNumber.getOrElse(0) * 1000) /
                          population.getOrElse(1)
                        ).toFixed(0)}
                      </b>{' '}
                      property crimes per <b>1000</b> inhabitants.
                    </p>
                  </div>
                </div>
              )}
          </div>
        </Column>
      </Row>
    </div>
  )
}

export default CrimeRiskAssessment
