import { useExperiment } from '@lib/optimizely'
import { Heading, PageSection } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import pathOr from 'ramda/src/pathOr'
import React from 'react'

import {
  ContentfulCheckoutForm,
  ContentfulCheckoutOrderDetails,
  ContentfulPaymentForm,
  ContentfulPaymentFormErrorMessage
} from '../../../graphql'
import CheckoutForm from '../../components/CheckoutForm'
import CheckoutOrderDetails from '../../components/CheckoutOrderDetails'
import ContentfulPaymentFormComponent from '../../components/ContentfulPaymentFormComponent'
import PaymentFormErrorMessageComponent from '../../components/PaymentFormErrorMessage'
export type CheckoutPageWrapperProps = {
  readonly formData: ContentfulCheckoutForm | ContentfulPaymentForm
  readonly orderSummaryData: ContentfulCheckoutOrderDetails
  readonly pageTitle: string
  readonly paymentErrorMessage: Partial<ContentfulPaymentFormErrorMessage>
}

function CheckoutPageWrapper({
  formData,
  orderSummaryData,
  pageTitle,
  paymentErrorMessage
}: CheckoutPageWrapperProps) {
  const [variation, clientReady, didTimeout] = useExperiment('ECP_5258')
  const optimizelyReady = clientReady || didTimeout
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const formDataType = pathOr<string, string>(
    '',
    ['internal', 'type'],
    formData
  )
  const isMobile = !useMediaQuery('TabletAndUp')

  return optimizelyReady && variation === 'variation_1' ? (
    <PageSection>
      <PaymentFormErrorMessageComponent data={paymentErrorMessage} />
      {isMobile === false && (
        <div className="mt-2">
          <Heading element="h3" headingAspect="h3" useTailwind>
            {pageTitle}
          </Heading>
        </div>
      )}

      <div
        className="flex flex-col lg:flex-row-reverse lg:gap-7"
        data-component="CheckoutPageWrapper"
      >
        <div className="lg:w-4/12">
          <CheckoutOrderDetails
            data={orderSummaryData}
            isOrderSummaryRedesign={true}
          />
        </div>
        <div className="lg:w-8/12">
          {formDataType === 'ContentfulCheckoutForm' ? (
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulCheckoutForm | ContentfulPaymentFo... Remove this comment to see the full error message
            <CheckoutForm data={formData} />
          ) : (
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulCheckoutForm | ContentfulPaymentFo... Remove this comment to see the full error message
            <ContentfulPaymentFormComponent data={formData} />
          )}
        </div>
      </div>
    </PageSection>
  ) : (
    <div data-component="CheckoutPageWrapper">
      <PageSection>
        <PaymentFormErrorMessageComponent data={paymentErrorMessage} />
        {isMobile === false && (
          <div className="mt-2">
            <Heading element="h3" headingAspect="h3" useTailwind>
              {pageTitle}
            </Heading>
          </div>
        )}

        <CheckoutOrderDetails data={orderSummaryData} />
      </PageSection>
      <PageSection>
        {formDataType === 'ContentfulCheckoutForm' ? (
          // @ts-expect-error TS(2322) FIXME: Type 'ContentfulCheckoutForm | ContentfulPaymentFo... Remove this comment to see the full error message
          <CheckoutForm data={formData} />
        ) : (
          // @ts-expect-error TS(2322) FIXME: Type 'ContentfulCheckoutForm | ContentfulPaymentFo... Remove this comment to see the full error message
          <ContentfulPaymentFormComponent data={formData} />
        )}
      </PageSection>
    </div>
  )
}
export default CheckoutPageWrapper
