import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import { BuildMySystemItemContainer } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import React from 'react'

import {
  ContentfulBmsSensorGroup,
  ContentfulBmsSensors,
  ContentfulVariationContainer
} from '../../../graphql'
import { PriceProvider } from '../../providers/PriceProvider'
import { mapPageComponentToTemplate } from '../Page'

type BmsSensorGroupProps = {
  readonly data: ContentfulBmsSensorGroup
}

export default function BmsSensorGroup({ data }: BmsSensorGroupProps) {
  const components = safeProp('sensors', data).cata(
    () => [],
    sensors =>
      sensors
        .filter(sensor => !!sensor)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulBmsSensorsContentfulVa... Remove this comment to see the full error message
        .map(sensor => sensor && mapPageComponentToTemplate(sensor))
  )

  const skus = safeProp('sensors', data)
    .map(sensors =>
      sensors.filter(
        (sensor): sensor is ContentfulBmsSensors =>
          !!sensor && 'productId' in sensor
      )
    )
    // TODO: fix type
    // @ts-expect-error TS(2345) FIXME: Argument of type '<O extends { productId: V; }, T ... Remove this comment to see the full error message
    .map(sensors => sensors.map(prop('productId')))
    .orJust([])
    .filter(isNotNil)

  const variationSkus = safeProp('sensors', data)
    .map(sensors =>
      sensors.filter(
        (sensor): sensor is ContentfulVariationContainer =>
          !!sensor && 'variations' in sensor
      )
    )
    // TODO: fix type
    // @ts-expect-error TS(2345) FIXME: Argument of type '<O extends { variations: V; }, T... Remove this comment to see the full error message
    .map(variations => variations.map(prop('variations')))
    .map(variations => variations.filter(isNotNil))
    // TODO: fix type

    // @ts-expect-error TS(2339) FIXME: Property 'filter' does not exist on type 'unknown'... Remove this comment to see the full error message
    .map(variations =>
      variations.map(variation =>
        variation.filter(
          (_variation: any): _variation is ContentfulBmsSensors =>
            !!_variation && 'productId' in _variation
        )
      )
    )

    .map(variations =>
      variations.map(sensors => sensors.map(prop('productId')))
    )

    .map(skus => skus.reduce((allSkus, skus) => allSkus.concat(skus), []))
    .orJust([])
    .filter(isNotNil)

  return ifElse(
    equals(true),
    () => null,
    () => (
      // TODO: fix type

      // @ts-expect-error TS(2322) FIXME: Type 'unknown[]' is not assignable to type 'readon... Remove this comment to see the full error message
      <PriceProvider skus={skus.concat(variationSkus)}>
        <BuildMySystemItemContainer
          title={safeProp('title', data).getOrElse('')}
        >
          {components}
        </BuildMySystemItemContainer>
      </PriceProvider>
    )
  )(isEmpty(components))
}

export const bmsSensorGroupQuery = graphql`
  #graphql
  fragment bmsSensorGroup on ContentfulBmsSensorGroup {
    internal {
      type
    }
    id
    title
    sensors {
      ... on ContentfulBmsSensors {
        ...bmsSensors
      }
      ... on ContentfulVariationContainer {
        ...variationContainer
      }
    }
  }
`
