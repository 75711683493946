import { ContentfulCheckoutForm } from '../../../../graphql'
import { AddressValidationsSchema, UserCheckoutSchema } from './schema'
import type {
  AddressValidation,
  Correction
} from '@simplisafe/ss-ecomm-data/checkout/checkout'
import type { Address } from '@commercetools/platform-sdk'
import { CheckoutFormValues } from '@simplisafe/ss-ecomm-data/cart'
import * as O from 'fp-ts/lib/Option'
import * as F from 'fp-ts/lib/function'
import { mergeDeep } from 'immutable'

const variationAllowedForOpeningTheModal = 'variation_1'
const defaultPaymentPage = '/payment-page'
export const validatedAddressId = 'validated_address'

/**
 * Determines if the API found a correction of the address entered
 */
export const hasItFoundAddressSuggestion = (
  addressValidation: AddressValidation
) =>
  addressValidation.foundValidAddress && !!addressValidation.suggestions?.length

/**
 * Determines if the user variation is the proper to render the modal
 */
export const isCorrectShippingAddressVariation = (
  variation: string | null
): boolean => variation === variationAllowedForOpeningTheModal

/**
 * Determines if the user variation is the proper to render the modal
 */
export const isCorrectShippingAddressLocale = (locale: string): boolean =>
  locale === 'en-US'

/**
 * Determines if the shipping address validation modal should render
 *
 * The modal should appear if:
 *
 *   1. The user is in the US.
 *   2. The Optimizely experiment is running.
 *   3. It has variation_1 as experiment variation.
 *   4. The API found a correction in the entered address.
 *   5. Corrections array is not empty
 *   6. Corrections array does not have an invalid correction (only FixedAbbreviations for now)
 */
export const shouldRenderShippingAddressModal =
  (addressValidation: AddressValidation) =>
  (variation: string | null) =>
  (locale: string) =>
    isCorrectShippingAddressLocale(locale) &&
    isCorrectShippingAddressVariation(variation) &&
    addressValidation &&
    hasItFoundAddressSuggestion(addressValidation) &&
    hasItFoundAddressCorrections(addressValidation) &&
    !hasItFoundInvalidAddressCorrections(addressValidation)

/**
 * Determines if the we need to silently update the user address -> without opening the modal
 *
 * Should be true if:
 *
 *   1. The user is in the US.
 *   2. The Optimizely experiment is running.
 *   3. It has variation_1 as experiment variation.
 *   4. The API found a correction in the entered address.
 *   5. Corrections array is empty
 *   6. Corrections array have an invalid correction (only FixedAbbreviations for now)
 */
export const shouldSilentlyUpdate =
  (addressValidation: AddressValidation) =>
  (variation: string | null) =>
  (locale: string) =>
    isCorrectShippingAddressLocale(locale) &&
    isCorrectShippingAddressVariation(variation) &&
    addressValidation &&
    hasItFoundAddressSuggestion(addressValidation) &&
    (!hasItFoundAddressCorrections(addressValidation) ||
      hasItFoundInvalidAddressCorrections(addressValidation))

/**
 * Determines if the we need to fire the impacted flag
 *
 * Should be true if:
 *
 *   1. The user is in the US.
 *   2. The Optimizely experiment is running.
 *   3. It is variation_1 or control (for both).
 *   4. The API found a correction in the entered address.
 *   5. Should not silently update
 */
export const shouldFireAddressImpactedFlag =
  (addressValidation: AddressValidation) => (locale: string) =>
    isCorrectShippingAddressLocale(locale) &&
    addressValidation &&
    hasItFoundAddressSuggestion(addressValidation) &&
    hasItFoundAddressCorrections(addressValidation) &&
    !hasItFoundInvalidAddressCorrections(addressValidation)

/**
 * Get the redirect url from Contentful
 */
export const getCheckoutPaymentUrl = (data: ContentfulCheckoutForm) =>
  data.redirectToPaymentPage || defaultPaymentPage

/**
 * Format postalCode with plus4Code if exists
 */
const formatZipCode = (postalCode: string | undefined, plus4Code?: string) =>
  plus4Code ? `${postalCode}-${plus4Code}` : postalCode

/**
 * Map an AddressValidation object to convert in in a Address one
 */
type BaseAddress = Partial<AddressValidationsSchema> &
  Partial<CheckoutFormValues> &
  Partial<UserCheckoutSchema>
export const getFormattedUserAddress = <T extends BaseAddress>(
  values: T,
  locale: string
): Address => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values?.email,
  phone: values?.phone,
  country: locale === 'en-US' ? 'US' : 'GB',
  city: values.city,
  state: locale === 'en-US' ? values.state : undefined,
  streetName: values.streetName || values.street,
  postalCode: formatZipCode(
    values.postalCode || values?.zipcode,
    values?.plus4Code
  ),
  additionalStreetInfo: values?.additionalStreetInfo || ''
})

type CheckoutBody = {
  readonly billingAddress: Address
  readonly lead?: string
  readonly leadOther?: string
  readonly setBillingAddress: boolean
  readonly shippingAddress: Address
  readonly shippingMethod: string
  readonly shouldValidate: boolean
}
export const getCheckoutBody = (body: CheckoutBody) => ({
  billing: {
    address: body.billingAddress,
    setBillingAddress: body.setBillingAddress
  },
  metaData: {
    leadOther: body.leadOther || '',
    leadSource: body.lead || ''
  },
  shipping: {
    address: body.shippingAddress,
    method: body.shippingMethod,
    validateAddress: body.shouldValidate
  }
})

/**
 * Map an Address object to convert in in a AddressValidationsSchema one
 */
export const getFormattedShippingAddress = (
  address: Address
): AddressValidationsSchema => ({
  street: address?.streetName,
  city: address?.city,
  state: address?.state,
  zipcode: address?.postalCode,
  additionalStreetInfo: address?.additionalStreetInfo,
  plus4Code: ''
})

/**
 * Determines if we need a new request when the user submit his address option
 *
 * Should be true if the user selected the validated address option
 */
export const shouldUpdateShippingAddress = (id: string) =>
  id === validatedAddressId

/**
 * Get the first suggestion address. We receive an array of corrections (if exists), but for now we have only one result
 *
 * If the array is empty we return an object to make the spread secure
 */
export const getFirstSuggestionAddress = (
  addressValidation: AddressValidation
) =>
  F.pipe(
    O.of(addressValidation.suggestions),
    O.chain(values => (values.length ? O.some(values[0]) : O.none)),
    O.map(el => el.address),
    O.toNullable
  )

/**
 * Determines if it has corrections to show up the modal
 */
export const hasItFoundAddressCorrections = (
  addressValidation: AddressValidation
) =>
  F.pipe(
    O.of(addressValidation.suggestions),
    O.chain(values => (values.length ? O.some(values[0].corrections) : O.none)),
    O.chain(values => (values?.length ? O.some(values) : O.none)),
    O.isSome
  )

/**
 * Determines if it has invalid corrections (we do not want to show the modal if it has one of this) to show up the modal
 */
const checkInvalidCorrections = (corrections: readonly Correction[]) =>
  corrections.every(val => val === 'FixedAbbreviations')
    ? O.none
    : O.some(corrections)
export const hasItFoundInvalidAddressCorrections = (
  addressValidation: AddressValidation
) =>
  F.pipe(
    O.of(addressValidation.suggestions),
    O.chain(values => (values.length ? O.some(values[0].corrections) : O.none)),
    O.chain(values => (values?.length ? O.some(values) : O.none)),
    O.chain(checkInvalidCorrections),
    O.isNone
  )

/**
 * Check is an address object is empty
 */
export const isAddressEmpty = (obj: Address | undefined) =>
  F.pipe(
    O.of(obj),
    O.chain(obj => (obj && Object.keys(obj).length ? O.none : O.some(obj))),
    O.isSome
  )

/**
 * Build user personal data object
 */
export const getPersonalUserDataFromCheckout = (
  values?: Address
): UserCheckoutSchema => ({
  firstName: values?.firstName,
  lastName: values?.lastName,
  email: values?.email,
  phone: values?.phone
})

/**
 * Combine user personal data to the shipping address
 */
export const combineUserDataAndAddress =
  (userData: UserCheckoutSchema) =>
  (shippingAddress: AddressValidationsSchema) =>
  (locale: 'en-GB' | 'en-US') => {
    const selectedData = mergeDeep(userData, shippingAddress)
    return getFormattedUserAddress(selectedData, locale)
  }
