import { Modal } from '@simplisafe/ss-react-components'
import React, { useState } from 'react'
import { ShippingValidationAddressSchema } from './schema'
import { navigate } from 'gatsby'
import { useTracking } from 'react-tracking'
import ShippingAddressModalValidationPicker from './ShippingAddressModalValidationPicker'
import ShippingAddressModalValidationSubmission from './ShippingAddressModalValidationSubmission'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCartLoading,
  selectCartShippingAddress
} from '@simplisafe/ss-ecomm-data/cart/select'
import { handleCheckoutSubmit } from '@simplisafe/ss-ecomm-data/checkout/checkout'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  combineUserDataAndAddress,
  getCheckoutBody,
  getFormattedShippingAddress,
  shouldUpdateShippingAddress,
  validatedAddressId
} from './utils'
import type { TrackEvent } from '../../../util/analytics'

type Props = {
  readonly modal: ShippingValidationAddressSchema
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
}

const onConfirmAddress = (trackEvent: TrackEvent, event: string) =>
  trackEvent({
    action: 'confirm_shipping_address',
    category: 'checkout',
    event: 'confirm_shipping_address_validation',
    label: event === 'validated_address' ? 'Validated' : 'User Entered'
  })

const defaultAddressId = validatedAddressId

export default function ShippingAddressModal({
  modal: { data, redirectUrl },
  open,
  setOpen
}: Props) {
  const [shippingSelected, setShippingSelected] = useState({
    id: defaultAddressId,
    shouldValidate: false,
    shippingAddress: data.validatedAddress
  })
  const [disableSubmit, setDisableSubmit] = useState(false)

  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const cartIsLoading = useSelector(selectCartLoading)
  const locale = useSelector(selectLocale)
  const shippingAddress = useSelector(selectCartShippingAddress)

  const handleSubmit = () => {
    onConfirmAddress(trackEvent, shippingSelected.id)
    setDisableSubmit(true)

    const handleSubmitSuccess = () => {
      setDisableSubmit(false)
      navigate(redirectUrl)
    }
    const handleSubmitError = (err: Error) =>
      logError(
        Error(`something went wrong updating user address: ${err.message}`)
      )
    const handleUpdateAddress = () => {
      const addressData = combineUserDataAndAddress(data.userCheckoutData)(
        shippingSelected.shippingAddress
      )(locale)
      const body = getCheckoutBody({
        billingAddress: addressData,
        setBillingAddress: !!data.config.setBillingAddress,
        shippingAddress: addressData,
        shippingMethod: data?.shippingMethod || '',
        shouldValidate: shippingSelected.shouldValidate,
        lead: data.metadata.lead,
        leadOther: data.metadata.leadOther
      })
      dispatch(
        handleCheckoutSubmit(body)(handleSubmitError)(handleSubmitSuccess)
      )
    }

    shouldUpdateShippingAddress(shippingSelected.id)
      ? handleUpdateAddress()
      : navigate(redirectUrl)
  }

  return (
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} isClosable>
      <div className="flex flex-col p-8 w-full" data-component="modal_content">
        <div className="w-full">
          <h3 className="font-medium md:font-bold text-3xl md:text-2xl m-0">
            Select the correct address
          </h3>
          <p className="text-lg md:text-sm mt-2 text-neutral-black">
            Please check and confirm your address for shipping accuracy.
          </p>
        </div>
        <div className="flex flex-col md:flex-row w-full gap-4">
          <ShippingAddressModalValidationPicker
            id="validated_address"
            title="Verified Address:"
            address={data.validatedAddress}
            userData={data.userCheckoutData}
            onClick={setShippingSelected}
            isSelected={!shippingSelected.shouldValidate}
            shouldValidate={false}
          />
          <ShippingAddressModalValidationPicker
            id="entered_address"
            title="Address Entered:"
            address={shippingAddress.cata(
              () => null,
              getFormattedShippingAddress
            )}
            userData={data.userCheckoutData}
            onClick={setShippingSelected}
            isSelected={shippingSelected.shouldValidate}
            shouldValidate
          />
        </div>
        <div className="flex w-full justify-center md:justify-end">
          <ShippingAddressModalValidationSubmission
            handleSubmit={handleSubmit}
            isLoading={cartIsLoading || disableSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}
