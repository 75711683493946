import { InitializeStore } from '@lib/gatsby-wrappers'
import { createStore } from '@simplisafe/ss-ecomm-data/redux/store'
import { initBraze } from '@lib/tracking'
import { Script, ScriptStrategy } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

import TrackingProvider from './TrackingProvider'

const scriptRoot = process.env.PATH_PREFIX ? `/${process.env.PATH_PREFIX}` : ''
const PX_ID = process.env.GATSBY_PX_APP_ID

function WrapWithProvider({ element }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  const store = createStore()

  return (
    <>
      <Provider store={store}>
        <CookiesProvider>
          <InitializeStore>
            <TrackingProvider>
              <QueryParamProvider adapter={ReachAdapter}>
                {element}
              </QueryParamProvider>
            </TrackingProvider>
          </InitializeStore>
        </CookiesProvider>
      </Provider>
      <Script
        src={`${scriptRoot}/ecomm-payment-plugin/payment-callback-20230207.js`}
        strategy={ScriptStrategy.idle}
      />
      <Script
        src={`${scriptRoot}/old-browsers.js`}
        strategy={ScriptStrategy.idle}
      />
      {/* @ts-expect-error TS(2322) FIXME: Type '{ appId: string; src: string; }' is not assi... Remove this comment to see the full error message */}
      <Script appId={PX_ID} src={`${scriptRoot}/perimeterx.js`} />
      <Script
        onLoad={() => initBraze()}
        src={`${scriptRoot}/braze-web-sdk-20230207.js`}
      />
    </>
  )
}

WrapWithProvider.propTypes = { element: PropTypes.element }

export default WrapWithProvider
