import { COOKIE_LEAD_DATA, handleBrazeTrackingEvent } from '@lib/tracking'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking'
import { fbTrackLeadCreated } from '@lib/tracking'
import { safeProp } from '@simplisafe/monda'
import {
  cookiesOption,
  leadGenCapture,
  LeadGenCaptureParams,
  LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import {
  FormField,
  FormTextInput,
  Modal,
  SSButton,
  Text
} from '@simplisafe/ss-react-components'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import * as Yup from 'yup'

import { ContentfulEmailOverlayModal } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type FormValues = {
  readonly email: string
}

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type EmailOverlayModalProps = {
  readonly data: ContentfulEmailOverlayModal
}

export default function EmailOverlayModal({ data }: EmailOverlayModalProps) {
  const cookies = new Cookies()
  const emailSubmitted = !!cookies.get<string>(COOKIE_LEAD_DATA)

  const formRef = useRef<FormikProps<FormValues>>(null)
  const [visible, setVisible] = useState(!emailSubmitted)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const title = propOr<string, string>('', 'title', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const submitText = propOr<string, string>('', 'submitText', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const emailPlaceholder = propOr<string, string>('', 'emailPlaceholder', data)
  const privacyPolicy = safeProp('privacyPolicyNotice', data)
    // TODO: Gatsby 4 rich text
    // .map((json: Document) => <SmallTextSection description={json} key={'privacyPolicy'} />)
    .map(field => <ContentfulRichText key="privacyPolicy" raw={field.raw} />)
    .orUndefined()
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const isClosable = propOr<boolean, boolean>(true, 'isClosable', data)

  const onSubmit = async (
    formData: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const leadGenParams: LeadGenCaptureParams = {
      email: formData.email.trim(),
      leadSource: 'crimenearyou',
      source: `crimenearyou_${getDeviceType().toLowerCase()}`,
      sourceType: 'crime_near_you'
    }

    const handleLeadCaptureSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      setVisible(false)
      setSubmitting(false)
      value.forEach(
        async response =>
          response.email && (await fbTrackLeadCreated(response.email))
      )
    }

    const handleLeadCaptureFailure = () => {
      setSubmitting(false)
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    leadGenCapture(leadGenParams)(handleLeadCaptureFailure)(
      handleLeadCaptureSuccess
    )
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .trim()
      .required('Please fill in your email address')
  })

  return (
    // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
    <Modal
      appElementId="___gatsby"
      isClosable={isClosable}
      isOpen={visible}
      style={{
        overlay: {
          WebkitBackdropFilter: 'blur(16px)',
          backdropFilter: 'blur(16px)'
        }
      }}
    >
      <div className="p-10 w-auto items-center justify-center text-center">
        <Text
          className="mb-5 text-lg md:text-3xl w-auto md:w-[30rem]"
          textAlignment="center"
        >
          {title}
        </Text>
        <Formik
          initialValues={{ email: '' }}
          innerRef={formRef}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <div data-component={'Email'}>
                <FormField hideLabel={true} label="email" name="email">
                  <FormTextInput
                    fullWidth={true}
                    id="email"
                    // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                    name="email"
                    placeholder={emailPlaceholder}
                  />
                </FormField>
              </div>
              <div data-component={'Submission'}>
                <FormField hideLabel={true} label={'submit'} name="submit">
                  <SSButton
                    disabled={isSubmitting}
                    minWidth="full"
                    role="submit"
                    type="submit"
                  >
                    {submitText}
                  </SSButton>
                </FormField>
              </div>
            </Form>
          )}
        </Formik>
        <Text className="my-2" textAlignment="center" textSize="xs">
          {privacyPolicy}
        </Text>
      </div>
    </Modal>
  )
}
