import { FormField, Text } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type ContactFormPrivacyPolicyProps = {
  readonly contactFormPrivacyPolicy?: ReactElement
}

function PrivacyPolicy(props: ContactFormPrivacyPolicyProps) {
  return (
    <FormField hideLabel={true} name="contactFormPrivacyPolicy">
      <Text textSize="xs">{props.contactFormPrivacyPolicy}</Text>
    </FormField>
  )
}

export default PrivacyPolicy
