export const mockIntersectionObserver = class {
  count = 0
  entries: any[]
  root = null
  rootMargin = ''
  thresholds = [0]
  takeRecords = () => []
  constructor(
    callback: IntersectionObserverCallback,
    __options: IntersectionObserverInit | undefined
  ) {
    this.entries = []
    window.addEventListener('scroll', () => {
      this.count++
      this.entries.map(entry => {
        entry.isIntersecting = this.count % 2 === 1
      })
      callback(this.entries, this)
    })
  }

  observe(target: HTMLElement) {
    this.entries.push({
      isIntersecting: false,
      target
    })
  }

  unobserve(target: HTMLElement) {
    this.entries = this.entries.filter(ob => ob.target !== target)
  }

  disconnect() {
    this.entries = []
  }
}
