export const getPropertyDangerous = (score: number): string =>
  score > 80 && score <= 100
    ? 'This area is in the top 11.3% riskiest neighborhoods in the US.'
    : score > 60 && score <= 80
    ? 'This area is in the top 17.8% riskiest neighborhoods in the US.'
    : score > 40 && score <= 60
    ? 'This area is in the top 29.3% riskiest neighborhoods in the US.'
    : score > 20 && score <= 40
    ? 'This area is in the top 25.4% safest neighborhoods in the US.'
    : score > 0 && score <= 20
    ? 'This area is in the top 70.6% safest neighborhoods in the US.'
    : ''

export const scoreToWords = (risk: number): string | undefined =>
  risk <= 15
    ? 'Very low'
    : risk < 30
    ? 'Low'
    : risk < 50
    ? 'Moderate'
    : risk < 70
    ? 'High'
    : risk < 100
    ? 'Severe'
    : ''

export const getScoreColor = (risk: number): string | undefined =>
  risk < 15
    ? 'text-teal-500'
    : risk < 30
    ? 'text-teal-300'
    : risk < 50
    ? 'text-orange-400'
    : risk < 70
    ? 'text-rose-500'
    : 'text-rose-700'

export const scoreToBGColor = (risk: number): string =>
  risk < 15
    ? 'bg-emerald-800'
    : risk < 30
    ? 'bg-emerald-500'
    : risk < 50
    ? 'bg-orange-400'
    : risk < 70
    ? 'bg-rose-500'
    : 'bg-rose-700'

export const getRiskColor = (scoreValue: number): string =>
  scoreValue < 15
    ? 'text-blue'
    : scoreValue < 30
    ? 'text-white'
    : scoreValue < 50
    ? 'text-blue-900'
    : scoreValue < 70
    ? 'text-white'
    : 'text-white'
