import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { AccordionItem } from '@simplisafe/ss-react-components'
import { AccordionItemProps } from '@simplisafe/ss-react-components/AccordionItem'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import React from 'react'

import { ContentfulAccordion } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type AccordionItemComponentProps = {
  readonly data: Partial<ContentfulAccordion>
}

const accordionThemeMapper: { [key: string]: AccordionItemProps['theme'] } = {
  Default: 'default',
  'Title Border': 'border'
}
export const toAccordionThemeValue = (
  value?: string | null
): AccordionItemProps['theme'] | undefined =>
  Maybe.fromNull(value)
    .chain(val => safeProp(val, accordionThemeMapper))
    .orUndefined()

export default function AccordionItemComponent({
  data
}: AccordionItemComponentProps) {
  return (
    <AccordionItem
      id={prop('id', data)}
      theme={toAccordionThemeValue(prop('theme', data))}
      title={path(['accordionTitle', 'accordionTitle'], data)}
    >
      <ContentfulRichText raw={data?.description?.raw} />
    </AccordionItem>
  )
}

export const contentfulAccordionItem = graphql`
  #graphql
  fragment contentfulAccordionItemFragment on ContentfulAccordion {
    internal {
      type
    }
    accordionTitle {
      accordionTitle
    }
    description {
      raw
    }
    id
    theme
  }
`
