import { toSiteColor } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { FloatingBadge } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maybe } from 'monet'
import React, { ReactNode } from 'react'

import type { ContentfulFloatingBadge } from '../../../graphql'
import { renderComponentFromData } from '../../util/render'

export type FloatingBadgeComponentProps = {
  readonly data: ContentfulFloatingBadge
}

const toGatsbyImage = (imgData: any): ReactNode => {
  const altText = Maybe.fromNull(imgData)
    .chain(safeProp('description'))
    .getOrElse('')

  return Maybe.fromNull(imgData).cata(
    () => null,
    imgProps => (
      <GatsbyImage
        alt={altText}
        image={getImage(imgProps)}
        imgStyle={{ objectFit: 'contain' }}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    )
  )
}

const renderImage = (content: any) => {
  return safeProp('image', content).cata(
    () => null,
    imgData => toGatsbyImage(imgData)
  )
}

// @ts-expect-error TS(2304) FIXME: Cannot find name 'FloatingBadgeFragment'.
const routeToComponent = (content: FloatingBadgeFragment['content']) => {
  const type = safePath(['internal', 'type'], content).getOrElse('')
  return Maybe.fromNull(content).cata(
    () => null,
    c =>
      type === 'ContentfulImage' ? renderImage(c) : renderComponentFromData(c)
  )
}

export default function FloatingBadgeComponent({
  data
}: FloatingBadgeComponentProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type '"bgColor"' is not assignable to ... Remove this comment to see the full error message
  const background = safeProp('bgColor', data)
    .chain(safeProp('color'))
    .getOrElse('none')

  const childComponent = routeToComponent(prop('content', data))

  return Maybe.fromNull(childComponent).cata(
    () => null,
    component => {
      return (
        <div className="absolute right-0 top-[65%] md:top-1/2 -translate-y-2/4 z-10 scale-75 md:scale-100">
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code */}
          <FloatingBadge backgroundColor={toSiteColor(background)}>
            {component}
          </FloatingBadge>
        </div>
      )
    }
  )
}

export const FloatingBadgeQuery = graphql`
  #graphql
  fragment floatingBadge on ContentfulFloatingBadge {
    bgColor: backgroundColor {
      color
    }
    content {
      ... on ContentfulImage {
        id
        internal {
          type
        }
        image {
          id
          description
          gatsbyImageData(layout: CONSTRAINED, width: 320, placeholder: BLURRED)
        }
      }
    }
    id
    internal {
      type
    }
  }
`
