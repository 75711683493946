import { SSButton } from '@simplisafe/ss-react-components'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import React, { ReactElement, ReactNode } from 'react'

import { LinkHeaderProps } from '../CartContent'
import CartAffirmCartMessage from './CartAffirmCartMessage'

export type CartFooterProps = {
  readonly affirmPromoMessage: ReactNode | null
  readonly checkOutButtonFooter: SSButtonProps
  readonly creditCardLogo: readonly ReactElement[]
  readonly disabledCartSubmit: boolean
  readonly link: LinkHeaderProps
}

function CartFooter({
  affirmPromoMessage,
  creditCardLogo,
  checkOutButtonFooter,
  link,
  disabledCartSubmit
}: CartFooterProps) {
  return (
    <div className="justify-items-end order-none text-right w-full md:float-right md:order-1 md:ml-7 md:mt-4 md:w-5/12 lg:grid lg:grid-cols-[auto_max-content] lg:w-full">
      <SSButton
        className="items-center cursor-pointer flex flex-center mb-4 md:w-full lg:order-1 lg:w-auto"
        {...checkOutButtonFooter}
        disabled={disabledCartSubmit}
        type="link"
      />
      <SSButton
        className="mb-4 md:!hidden text-neutral-medium-120 hover:underline"
        color="none"
        href={link.linkUrl}
        type="link"
      >
        {' '}
        {link.linkText}{' '}
      </SSButton>
      <div className="order-none">
        <div>
          <p className="inline-grid text-sm mt-0 mr-0 lg:mr-12 ">
            All orders come with our unconditional
            <b>60 Day Money-Back Guarantee</b>
          </p>
        </div>
      </div>
      <div
        className="flex justify-end order-3 col-start-2 col-end-3 my-3"
        key={`credit_logo_card_${Math.random()}`}
      >
        {creditCardLogo}
      </div>
      {affirmPromoMessage && <CartAffirmCartMessage />}
    </div>
  )
}

export default CartFooter
