import React from 'react'
import classNames from 'classnames'
import type { AddressValidationsSchema, UserCheckoutSchema } from '../schema'

type Props = {
  readonly id: string
  readonly title: string
  readonly address: AddressValidationsSchema | null
  readonly userData: UserCheckoutSchema
  readonly isSelected: boolean
  readonly shouldValidate: boolean
  readonly onClick: (props: {
    id: string
    shouldValidate: boolean
    shippingAddress: AddressValidationsSchema
  }) => void
}

export default function ShippingValidationAddressModalPicker({
  id,
  title,
  address,
  userData,
  isSelected,
  shouldValidate,
  onClick
}: Props) {
  return (
    <div
      className="cursor-pointer"
      data-component={id}
      onClick={() =>
        onClick &&
        address &&
        onClick({ id, shouldValidate, shippingAddress: address })
      }
    >
      <p className="text-xl md:text-base font-medium md:font-semibold mb-2 mt-0 md:mt-4">
        {title}
      </p>
      <div
        className={classNames(
          'flex flex-col w-full md:w-80 lg:w-96 min-h-36 px-6 py-4 rounded-lg border-solid',
          {
            'border-1 border-neutral-medium-100': !isSelected,
            'border-4 border-btn-primary': isSelected
          }
        )}
      >
        <span className="text-xl md:text-base font-bold mb-1">
          {userData?.firstName} {userData?.lastName}
        </span>
        <span
          className={classNames(
            'text-xl md:text-base font-normal text-neutral-black',
            { 'font-bold italic': isSelected }
          )}
        >
          {address?.street}
        </span>
        {address?.additionalStreetInfo && (
          <span
            className={classNames(
              'text-xl md:text-base font-normal text-neutral-black',
              { 'font-bold italic': isSelected }
            )}
          >
            {address.additionalStreetInfo}
          </span>
        )}
        <span
          className={classNames(
            'text-xl md:text-base font-normal text-neutral-black',
            { 'font-bold italic': isSelected }
          )}
        >
          {address?.city}, {address?.state} {address?.zipcode}
          {address?.plus4Code ? `-${address?.plus4Code}` : ''}
        </span>
      </div>
    </div>
  )
}
