/*
  NOTE: This component has a lot of shared and/or copied functionality with ItemContainerComponent.
  As such, when making changes in one, consider whether the same changes need to be made in the other one.
  TODO combine these two components into a single one to handle dual responsibility
*/
/* eslint-disable max-lines */
import {
  AffirmClient,
  ContentfulRichText,
  GatsbyImage,
  toButtonTypeValue
} from '@lib/components'
import {
  getMonitoringGiftItems,
  getNonMonitoringGiftItems,
  useOptimizelyAffirm
} from '@lib/tracking'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import {
  MiniCartLineItem,
  setMiniCartLineItem
} from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import {
  GiftItemContainer,
  GiftItemDTO
} from '@simplisafe/ss-ecomm-data/prices/service'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import {
  liftSelectProduct,
  selectActivePromoOverrideDiscountText,
  selectCustomSystemDiscountedPrice,
  selectCustomSystemTotalPrice,
  selectLocale,
  selectMiniCart,
  selectPackage,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  AffirmPromoMessage,
  BannerError,
  BannerLoading,
  IncludedItemProduct,
  ItemContainer,
  OutOfStockMessage,
  Price,
  SSInput,
  Text as Typography
} from '@simplisafe/ss-react-components'
import { AdditionalOptionItemType } from '@simplisafe/ss-react-components/AdditionalOptionItems/types'
import { Caution, Info } from '@simplisafe/ss-react-components/icons'
import {
  IncludedItemProps,
  ItemProduct
} from '@simplisafe/ss-react-components/IncludedItem'
import { ItemDetailProps } from '@simplisafe/ss-react-components/ItemDetail'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { inputTypeName } from '@simplisafe/ss-react-components/SSInput'
import { window } from 'browser-monads-ts'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { List, Maybe, None } from 'monet'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import head from 'ramda/src/head'
import omit from 'ramda/src/omit'
import pathEq from 'ramda/src/pathEq'
import pluck from 'ramda/src/pluck'
import propEq from 'ramda/src/propEq'
import subtract from 'ramda/src/subtract'
import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { useExperiment } from '@lib/optimizely'

import {
  ContentfulAsset,
  ContentfulBmsSensors,
  ContentfulDynamicPackageHeroBanner,
  ContentfulGuaranteeBadge,
  ContentfulPdpIncludedItemModal,
  ContentfulProductDetail,
  ContentfulProductImageCarouselDynamic
} from '../../../graphql'
import {
  componentsNotInStock,
  CoreComponentsData,
  coreComponentsRestockDate,
  getLatestDate,
  renderCoreComponentsNotInStockMsg,
  showProductsOutOfStock,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import {
  formatDisplayPrice,
  renderPriceNumber
} from '../../commercetools/price'
import {
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import AddToCartError from '../../errorComponents/AddToCartError'
import useDynamicPackageAddToCart from '../../hooks/useDynamicPackageAddToCart'
import usePriceVariation from '../../hooks/usePriceVariation'
import { usePriceContext } from '../../providers/PriceProvider'
import { toButton } from '../../util/helper'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import { renderComponentFromData } from '../../util/render'
import CardBadge, { CardBadgeProps } from '../CardBadge'
import { getTotalFormattedPrice } from '../CartSummaryComponent'
import {
  getAddToCartRedirectUrl,
  includedItemsRichTextOptions,
  toAdditionalOptionItem,
  withPlanKey
} from '../ItemContainerComponent/OldVersion'
import ModalComponent from '../ModalComponent'
import {
  interactivePlanCustomOptions,
  linkContentRichTextOptions,
  priceCalculationCustomOptions
} from './embeddedComponentOptions'
import { extractHeroBannerComponentByType, getPlanText } from './utils'

export type DynamicPackageHeroBannerProps = {
  readonly affirmClient?: AffirmClient
  readonly data: Partial<ContentfulDynamicPackageHeroBanner>
}

type DynamicPackageIncludedItem = ItemProduct & {
  readonly sku: Maybe<string>
}

type ImageWithSku = {
  readonly image: ReactElement
  readonly sku: string
}

const getCardBadgeImageArray = (x?: ContentfulAsset | null) =>
  x
    ? [
        <GatsbyImage
          className="overflow-visible"
          image={{
            ...x,
            description: x.description ?? '',
            title: x.title ?? ''
          }}
          imgClassName="m-[inherit]"
        />
      ]
    : []

// TODO define a type for the value passed to this function
const toCardBadge = transformObject<ContentfulGuaranteeBadge, CardBadgeProps>({
  cardImage: data => getCardBadgeImageArray(data?.images?.[1]),
  description: path(['description', 'description']),
  image: data => getCardBadgeImageArray(data?.images?.[0]),
  title: path(['title', 'title'])
})

const toIncludedItemsProps =
  (includedItems: ReadonlyArray<DynamicPackageIncludedItem>) =>
  (setSelectedItem: (sku: Maybe<string>) => void) =>
    transformObject<ContentfulProductDetail, IncludedItemProps>({
      includedItems: always(
        includedItems.map(item => {
          const productSku = item.sku.orJust(item.productName)
          const isFreeItem: boolean = safeProp('isFreeItem', item).getOrElse(
            false
          )

          return (
            <IncludedItemProduct
              {...item}
              isFreeItem={isFreeItem}
              key={isFreeItem ? `FREE-${productSku}` : productSku}
              modalType="link"
              onHoverEnd={() => setSelectedItem(None())}
              onHoverStart={() => setSelectedItem(item.sku)}
            />
          )
        })
      ),
      includedItemsLinkContent: data => (
        <div className="prose text-sm font-medium">
          <ContentfulRichText
            optionsCustom={includedItemsRichTextOptions}
            // @ts-expect-error TS(2339): Property 'description' does not exist on type 'Con... Remove this comment to see the full error message
            raw={data?.customizedSystem?.description?.raw}
          />
        </div>
      ),
      includedItemsTitle: data => prop('includedItemsTitle', data) || '',
      numColumnsDesktop: () => 3,
      numColumnsMobile: () => 2,
      numColumnsTablet: () => 3
    })

// exported for testing
export const getProSetupCheckbox = (
  productDetailData: ContentfulProductDetail,
  isProSetupChecked: boolean,
  setProSetupCheckbox: (checked: boolean) => void
) => {
  const maybeCheckbox = safeProp('proSetupCheckbox', productDetailData)
  const modal = safeProp('proSetupModal', productDetailData).map(modalData => (
    <ModalComponent
      clickTarget={<Info forceButtonMode={true} />}
      data={modalData}
      key={prop('id', modalData)}
      style={{ content: { padding: '32px' } }}
    />
  ))
  const label = maybeCheckbox
    .chain(checkbox => safePath(['customLayoutField', 'raw'], checkbox))
    .map(raw => (
      <div
        className="flex gap-1 pt-2 text-xs prose-p:m-0"
        key={maybeCheckbox.map(c => `${c.id}-label`).orNull()}
      >
        <ContentfulRichText raw={raw} />
        {modal.orNull()}
      </div>
    ))
    .orNull()

  return maybeCheckbox
    .map(checkboxData => (
      <SSInput
        checked={isProSetupChecked}
        key={prop('id', checkboxData)}
        label={label}
        name={prop('propName', checkboxData) || ''}
        onChange={() => setProSetupCheckbox(!isProSetupChecked)}
        // TODO this should have type='checkbox' instead of 'Checkbox', but that apparently applies some extra classes in the react component that breaks the layout here
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
        type={prop('type', checkboxData) as inputTypeName}
        // TODO: fix type
        // value does not exist on checkBoxData
        // @ts-expect-error TS(2769): No overload matches this call.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        value={prop('value', checkboxData)}
      />
    ))
    .orNull()
}

const toItemDetail = (
  onClick: () => void,
  productTitle: string,
  includedItems: ReadonlyArray<DynamicPackageIncludedItem>,
  price: string | undefined,
  onChange: (key: string) => void,
  showSpinner: boolean,
  setSelectedItem: (sku: Maybe<string>) => void,
  isProSetupChecked: boolean,
  setProSetupCheckbox: (b: boolean) => void,
  _package: Package | undefined,
  planPrice: number,
  overrideToggleTextMaybe: Maybe<string>,
  hasOverrideToggleText: boolean
) =>
  transformObject<ContentfulProductDetail, ItemDetailProps>({
    additionalItem: data =>
      getProSetupCheckbox(data, isProSetupChecked, setProSetupCheckbox),
    additionalOptionItemsProps: toAdditionalOptionItem(
      onChange,
      _package,
      undefined,
      undefined,
      undefined,
      planPrice,
      overrideToggleTextMaybe,
      hasOverrideToggleText
    ),
    buttonProps: data => ({
      ...toButton(prop('button', data) || {}),
      showSpinner
    }),
    content: data => <ContentfulRichText raw={data?.description?.raw} />,
    includedItemProps: toIncludedItemsProps(includedItems)(setSelectedItem),
    onClickButton: () => onClick,
    price: () => (isNotNil(price) ? <Price regularPrice={price} /> : <></>),
    productTitle: () => productTitle
  })

const toSensors = (
  data: ContentfulProductImageCarouselDynamic
): readonly ReactNode[] =>
  Maybe.fromNull(data)
    .chain<ReadonlyArray<ContentfulBmsSensors>>(
      safePath(['extraSensors', 'sensor'])
    )
    .map(sensorsData =>
      sensorsData.map(sensorData => renderComponentFromData(sensorData))
    )
    .getOrElse([])

const restockDate = (miniCartLineItem: Product) =>
  safeProp('restockDate', miniCartLineItem).getOrElse('')
const displayOutOfStockMessage = (miniCartLineItem: Product) =>
  showProductsOutOfStock(restockDate(miniCartLineItem)) === true

const renderOutOfStockMessage = (
  restockDate: string,
  coreCopomnentsRestockDate: string
) => {
  const restockDateDisplay: string = isNotEmpty(coreCopomnentsRestockDate)
    ? getLatestDate([coreCopomnentsRestockDate, restockDate])
    : restockDate
  const includedInPackage: boolean = restockDateDisplay === restockDate

  return (
    <>
      <Caution />
      <span>
        <OutOfStockMessage
          backInStockDate={restockDateDisplay}
          includedInPackage={includedInPackage}
          lowStockMessage={true}
        />
      </span>
    </>
  )
}

export const toDiscountText =
  (
    radioKey: string,
    promoDiscountText: Maybe<string>,
    promoWithMonitoringDiscountText: Maybe<string>,
    overrideTextMaybe: Maybe<string>,
    hasOverrideText: boolean
  ) =>
  (onChange: (x: string) => void) =>
  (data: ContentfulProductDetail | undefined, planPrice: number) =>
  (_package: Package): Maybe<string> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const additionalOptionItemsProps = toAdditionalOptionItem(
      onChange,
      _package,
      undefined,
      promoDiscountText.orUndefined(),
      promoWithMonitoringDiscountText.orUndefined(),
      planPrice,
      overrideTextMaybe,
      hasOverrideText
    )(data)
    const additionalData = Maybe.fromUndefined(additionalOptionItemsProps)
      .chain(safeProp('additionalOptionItems'))
      .chain(safeFind<AdditionalOptionItemType>(propEq('skuId', radioKey)))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
      .getOrElse(additionalOptionItemsProps.additionalOptionItems[0]) // taking first index if None()

    const discountValue =
      radioKey === withPlanKey
        ? promoWithMonitoringDiscountText
        : promoDiscountText

    return discountValue.map(x => {
      // sensorListDiscountText does not exist on additionalData
      // @ts-expect-error TS(2769): No overload matches this call.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      const service = prop('sensorListDiscountText', additionalData)
      return `+ ${x} ${service}:`
    })
  }

const getCarouselImage = (
  imgProps: ContentfulAsset,
  alt: string,
  key: string
) => (
  <div className="h-[340px] min-w-[380px] overflow-hidden rounded-base md:h-[600px] md:w-full">
    <BgImage
      alt={alt}
      image={getImage(imgProps.gatsbyImageData)}
      key={key}
      // @ts-expect-error TS(2322): Type '{ alt: string; image: IGatsbyImageData | und... Remove this comment to see the full error message
      style={{
        height: '100%',
        width: '100%'
      }}
      title={imgProps.description ?? ''}
    />
  </div>
)

const getCarouselImages = (
  data: Partial<ContentfulDynamicPackageHeroBanner>,
  miniCartLineItems: List<MiniCartLineItem>
): readonly ImageWithSku[] => {
  const posibleCarouselImages = data?.possibleCarouselImages ?? []

  const imageWithSkuArray: readonly ImageWithSku[] = posibleCarouselImages
    .map(pdpCarouselImage => {
      const image = pdpCarouselImage ?? null

      const displayName = image?.systemComponent?.displayName ?? ''
      const id = image?.id ?? ''
      const sku = image?.systemComponent?.sku ?? ''
      const imageData = image?.image

      const miniCartItem = miniCartLineItems
        .find(li => li.masterSku === sku)
        .orNull()

      return miniCartItem && imageData
        ? {
            image: getCarouselImage(imageData, displayName, id),
            sku
          }
        : null
    })
    .filter((val): val is ImageWithSku => val !== null)

  return imageWithSkuArray
}

const getIncludedItems = (
  data: Partial<ContentfulDynamicPackageHeroBanner>,
  miniCartLineItems: any,
  coreComponetsNotInStockList: readonly CoreComponentsData[],
  locale: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const modalsData: readonly ContentfulPdpIncludedItemModal[] = safePath(
    ['possibleIncludedItemModals', 'includedItemModals'],
    data
  ).orJust([])
  const coreCopomnentsRestockDate: string =
    coreComponetsNotInStockList.length > 0
      ? coreComponentsRestockDate(coreComponetsNotInStockList)
      : ''
  return safeProp('possibleSystemComponents', data)
    .map(systemComponents =>
      systemComponents
        .map(systemComponent =>
          Maybe.fromNull(systemComponent).flatMap(systemComponent => {
            const systemComponentSku = prop('sku', systemComponent)

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- legacy code
            const outOfStockDisplay = miniCartLineItems
              .find(propEq('masterSku', systemComponentSku))
              .filter((miniCartLineItem: Product) =>
                displayOutOfStockMessage(miniCartLineItem)
              )
              .map((miniCartLineItem: Product) =>
                renderOutOfStockMessage(
                  restockDate(miniCartLineItem),
                  coreCopomnentsRestockDate
                )
              )
              .getOrElse(<></>)

            const modalData =
              modalsData.find(
                pathEq(['systemComponent', 'sku'], systemComponentSku)
              ) || {}
            // TODO: fix type
            // modalContent does not exist on modalData
            // @ts-expect-error TS(2769): No overload matches this call.
            const modalContent: ContentfulComponent =
              prop('modalContent', modalData) || {}
            const ModalComponent = getMappedComponent(modalContent)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- legacy code
            return (
              miniCartLineItems
                .find(propEq('masterSku', systemComponentSku))
                // @ts-expect-error TS(2769): No overload matches this call.
                .filter(
                  (miniCartLineItem: object) =>
                    prop('quantity', miniCartLineItem) > 0
                )
                .map(
                  ({
                    quantity,
                    name
                  }: {
                    readonly quantity: number
                    readonly name: string
                  }) => ({
                    modalContent: ModalComponent && (
                      <ModalComponent data={modalContent} />
                    ),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
                    outOfStockMessage: outOfStockDisplay,
                    productName: `${quantity} ${name[locale]}`,
                    sku: Maybe.fromNull(systemComponent.sku)
                  })
                )
            )
          })
        )
        .filter(val => val.isSome())
        .map(val => val.just())
    )
    .getOrElse([])
}

const DynamicPackageHeroBannerComponent: FC<DynamicPackageHeroBannerProps> = ({
  affirmClient = window.affirm,
  data
}: DynamicPackageHeroBannerProps) => {
  const [areSensorsVisible, setSensorsVisible] = useState(false)
  const locale = useSelector(selectLocale)
  const extractComponentByType = extractHeroBannerComponentByType(data)
  const [selectedIncludedItemSku, setSelectedIncludedItemSku] = useState<
    Maybe<string>
  >(None())
  const dispatch = useDispatch()
  const isAirlinePartner =
    getValueFromPartnerCookie('partnerGroup') === 'airlines'

  const { getDiscountedText, getDiscountedTextWithServicePlan } =
    usePriceContext()

  const packageSku = safeProp('packageSku', data).getOrElse('')
  const _package = useSelector(selectPackage(packageSku))
  const coreComponetsProducts = useSelector(
    selectProducts(systemCoreComponents)
  )
  const coreComponetsNotInStockList = useMemo(
    () => componentsNotInStock(coreComponetsProducts),
    [coreComponetsProducts]
  )
  const miniCart = useSelector(selectMiniCart)
  const miniCartLineItems = List.from(miniCart.orJust([]))
  const itemCount = miniCartLineItems
    .filter(miniCartLineItem => prop('price', miniCartLineItem) > 0)
    .map(prop('quantity'))
    .foldLeft(0)((acc, val) => acc + val)
  // TODO use placeholder instead of partial string in CTFL
  const packageTitle = `${itemCount}${prop('titleFragment', data)}`
  // @ts-expect-error TS(2322): Type '{}[]' is not assignable to type 'readonly Dy... Remove this comment to see the full error message
  const includedItems: ReadonlyArray<DynamicPackageIncludedItem> =
    getIncludedItems(
      data,
      miniCartLineItems,
      coreComponetsNotInStockList,
      locale
    )
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const imagesWithSku: ReadonlyArray<ImageWithSku> = getCarouselImages(
    data,
    miniCartLineItems
  )
  const images: ReadonlyArray<ReactElement> = pluck('image', imagesWithSku)
  // Show the first image if no image is found for the given sku
  const selectedImageIndex = Math.max(
    0,
    imagesWithSku.findIndex(
      i => i.sku === selectedIncludedItemSku.getOrElse('')
    )
  )
  const [radioKey, setRadioKey] = useState(withPlanKey)
  const { Track } = useTracking()
  const totalPrice: Maybe<number> = useSelector(
    selectCustomSystemTotalPrice(packageSku, false)
  )
  const rawTotalPriceNumber: number = renderPriceNumber(totalPrice)
  const promoDiscountText = getDiscountedText(packageSku)
  const promoWithMonitoringDiscountText =
    getDiscountedTextWithServicePlan(packageSku)

  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()

  const [variation, clientReady, didTimeout] = useExperiment(
    'all___uk___prebuilt_packages_bms___monitoring'
  )
  const optimizelyMonitoringReady = clientReady || didTimeout
  const isMonitoringVariation =
    optimizelyMonitoringReady && variation === 'variation_1'
  const noPlanMonitoringURL =
    locale === 'en-US' ? '/choose-monitoring' : '/choose-monitoring2'

  // We should separate both carouselComponentData and detailComponentData into different variables and join them into a general structure to pass to ItemContainer
  // @ts-expect-error TS(2322): Type 'Maybe<ContentfulProductDetail | ContentfulPr... Remove this comment to see the full error message
  const maybeDetailComponentData: Maybe<ContentfulProductDetail> =
    extractComponentByType('ContentfulProductDetail')

  const initialServicePlanSku = maybeDetailComponentData
    .chain(safeProp('priceOptions'))
    // TS is not happy with .chain(safeHead), unclear why
    .chain(priceOptions => Maybe.fromNull(head(priceOptions)))
    .chain(safeProp('productSku'))

  const [servicePlanSku, setServicePlanSku] = useState(initialServicePlanSku)

  const proSetupSku = maybeDetailComponentData.chain(data =>
    safeProp('proSetupId', data)
  )

  const proSetupProduct = useSelector(liftSelectProduct(proSetupSku))

  const isProSetupChecked = proSetupProduct.cata(
    // eslint-disable-next-line ramda/prefer-ramda-boolean -- legacy code
    () => false,
    p =>
      !!miniCartLineItems.filter(item => item.masterSku === p.masterSku).size()
  )
  const setProSetupCheckbox = (checked: boolean) => {
    proSetupProduct.forEach(product => {
      dispatch(
        setMiniCartLineItem({
          ...product,
          checked: true,
          quantity: checked ? 1 : 0
        })
      )
    })
  }

  const servicePlanProduct = usePriceVariation(servicePlanSku.getOrElse(''))
  const servicePlanProductPrice = servicePlanProduct.cata(
    () => 0,
    value => prop('price', value)
  )

  const price = getTotalFormattedPrice(totalPrice)
  const discountedPrice = useSelector(
    selectCustomSystemDiscountedPrice(packageSku, radioKey, false, undefined)
  )
  const discountedPriceEqualsTotalPrice: boolean = equals(
    totalPrice,
    discountedPrice
  )

  const includeMonitoringPlan =
    locale === 'en-US' || (isMonitoringVariation && radioKey === withPlanKey)
  const [onAddToCartClick, isAddingToCart, addToCartError, _cartItems] =
    useDynamicPackageAddToCart(
      packageSku,
      discountedPrice,
      servicePlanSku.orJust(''),
      includeMonitoringPlan
    )

  const onPlanChange = useCallback(
    (radioKey: string, servicePlanSku?: string) => {
      setRadioKey(radioKey)
      setServicePlanSku(Maybe.fromNull(servicePlanSku))
    },
    []
  )

  const overrideToggleTextMaybe = useSelector(
    selectActivePromoOverrideDiscountText
  )
    .chain(safeProp('toggleBox'))
    .chain(val => val)
  const hasOverrideToggleText = !overrideToggleTextMaybe.isNone()

  const getPriceProps = useCallback(
    (packageValue: Package, componentData) => {
      // eslint-disable-next-line ramda/cond-simplification -- legacy code
      const placeholderText = cond([
        [
          equals(withPlanKey),
          always(discountedPrice.map(subtract(rawTotalPriceNumber)))
        ],
        [equals('No Plan'), always(discountedPrice)]
      ])(radioKey)
        .chain(formatDisplayPrice)
        .orUndefined()

      const formattedDiscountedPrice = getTotalFormattedPrice(discountedPrice)

      const discountAppliedPrice = discountedPrice
        .map(p => subtract(p, rawTotalPriceNumber))
        .chain(formatDisplayPrice)
        .orUndefined()

      const rawDiscountedPrice = discountedPrice.orUndefined()

      return {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment -- legacy code, legacy code */
        additionalOptionItemsProps: toAdditionalOptionItem(
          onPlanChange,
          packageValue,
          undefined,
          promoDiscountText.orUndefined(),
          promoWithMonitoringDiscountText.orUndefined(),
          servicePlanProductPrice,
          overrideToggleTextMaybe,
          hasOverrideToggleText
        )(componentData),
        discountAppliedPrice,
        interactivePlanContent: placeholderText && (
          <ContentfulRichText
            optionsCustom={interactivePlanCustomOptions(placeholderText)}
            {...getPlanText(radioKey, componentData)}
          />
        ),
        placeholderText,
        price: (
          <Price
            discountedPrice={
              discountedPriceEqualsTotalPrice || isAirlinePartner
                ? undefined
                : formattedDiscountedPrice
            }
            regularPrice={price}
          />
        ),
        rawDiscountedPrice
      }
    },
    [
      radioKey,
      onPlanChange,
      discountedPrice,
      discountedPriceEqualsTotalPrice,
      price,
      rawTotalPriceNumber,
      servicePlanProductPrice,
      promoDiscountText,
      promoWithMonitoringDiscountText
    ]
  )

  const withMonitoringGiftItem: GiftItemDTO = getMonitoringGiftItems()
  const withoutMonitoringGiftItem: GiftItemDTO = getNonMonitoringGiftItems()

  const applicableGiftItem = useMemo(() => {
    const freeGiftItems: GiftItemContainer = {
      withMonitoring: withMonitoringGiftItem || null,
      withoutMonitoring: withoutMonitoringGiftItem || null
    }
    const freeGiftsExist = withMonitoringGiftItem || withoutMonitoringGiftItem
    const monitoringPlan =
      radioKey === withPlanKey ? 'withMonitoring' : 'withoutMonitoring'
    // first need to ensure freeGiftItems and at least one relevant field exist
    // then check that a gift item exists for that plan before returning its title
    const monitoringChoiceFreeGiftExists =
      freeGiftsExist && freeGiftItems[monitoringPlan]
    return monitoringChoiceFreeGiftExists
      ? {
          isFreeItem: true,
          productName: `1 FREE ${freeGiftItems[monitoringPlan]?.title}`,
          sku: Maybe.fromNull(freeGiftItems[monitoringPlan]?.sku)
        }
      : null
  }, [withMonitoringGiftItem, withoutMonitoringGiftItem, radioKey])

  const itemContainer = extractComponentByType(
    'ContentfulProductImageCarouselDynamic'
  )
    // We should separate both carouselComponentData and detailComponentData into different variables and join them into a general structure to pass to ItemContainer
    // @ts-expect-error TS(2345): Argument of type '(carouselComponentData: Contentf... Remove this comment to see the full error message
    .flatMap((carouselComponentData: ContentfulProductImageCarouselDynamic) =>
      maybeDetailComponentData.map(
        (detailComponentData: ContentfulProductDetail) => {
          const cardBadge = carouselComponentData?.guaranteeBadge
            ? toCardBadge(carouselComponentData?.guaranteeBadge)
            : undefined
          const checkoutButtonFooter: SSButtonProps = {
            children: path(
              ['extraSensors', 'button', 'text'],
              carouselComponentData
            ),
            href: path(
              ['extraSensors', 'button', 'url'],
              carouselComponentData
            ),
            showSpinner: isAddingToCart,
            type: toButtonTypeValue(
              path(['extraSensors', 'button', 'type'], carouselComponentData)
            )
          }

          const pricePropData = _package.cata(
            () => null,
            v => getPriceProps(v, detailComponentData)
          )
          const maybePriceProps = Maybe.fromNull(pricePropData)
          const affirmPrice = maybePriceProps
            .chain(props => {
              return Maybe.fromUndefined(props.rawDiscountedPrice)
            })
            .getOrElse(rawTotalPriceNumber)
          const discountAppliedPrice = maybePriceProps
            .chain(props => {
              return Maybe.fromUndefined(props.discountAppliedPrice)
            })
            .getOrElse('')

          const affirmPromoMessage = (
            <AffirmPromoMessage
              affirmClient={affirmClient}
              className="affirm-as-low-as"
              onLearnMoreClick={optimizelyAffirmLearnMore}
              pageType="product"
              price={affirmPrice}
            />
          )

          const originalRedirectUrl = path(
            ['extraSensors', 'button', 'url'],
            carouselComponentData
          )
          const redirectUrl =
            originalRedirectUrl &&
            getAddToCartRedirectUrl(locale)(
              originalRedirectUrl,
              radioKey,
              isMonitoringVariation,
              noPlanMonitoringURL
            )

          const dynamicPackageIncludedItems = applicableGiftItem
            ? [...includedItems, applicableGiftItem]
            : includedItems

          const itemDetail: ItemDetailProps = {
            affirmPromoMessage,
            ...toItemDetail(
              () => onAddToCartClick(redirectUrl),
              packageTitle,
              dynamicPackageIncludedItems,
              price,
              onPlanChange,
              isAddingToCart,
              setSelectedIncludedItemSku,
              isProSetupChecked,
              setProSetupCheckbox,
              _package.toMaybe().orUndefined(),
              servicePlanProductPrice,
              overrideToggleTextMaybe,
              hasOverrideToggleText
            )(detailComponentData),
            ...omit(['discountAppliedPrice'], pricePropData),
            outOfStockMessage:
              coreComponetsNotInStockList.length > 0
                ? renderCoreComponentsNotInStockMsg(coreComponetsNotInStockList)
                : undefined
          }
          type Mutable<T> = { -readonly [P in keyof T]: T[P] }
          /* TODO: Gatsby 4 rich text */
          //Both fields are using RichText in react-components with references
          const linkText = carouselComponentData?.sensorLinkText?.raw
          const priceCalculation =
            carouselComponentData?.extraSensors?.priceCalculation?.raw
          const priceCalculationReferences =
            carouselComponentData?.extraSensors?.priceCalculation?.references ??
            []
          /* END OF TODO  */

          const discountText = _package.cata(
            () => '',
            _package =>
              toDiscountText(
                radioKey,
                promoDiscountText,
                promoWithMonitoringDiscountText,
                overrideToggleTextMaybe,
                hasOverrideToggleText
              )(onPlanChange)(
                detailComponentData,
                servicePlanProductPrice
              )(_package).getOrElse('')
          )

          const sensorsArray = toSensors(carouselComponentData)
          const sensors = sensorsArray.map((el: ReactNode, index: number) => (
            <React.Fragment key={`sensor-${index}`}>{el}</React.Fragment>
          ))
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
          const systemTotalPrice = safePath(
            ['price', 'props', 'discountedPrice'],
            pricePropData
          )
            .orElse(safePath(['price', 'props', 'price'], pricePropData))
            .orUndefined()

          const priceList = {
            'Discount Text & Value': discountAppliedPrice,
            Total: systemTotalPrice
          }

          return (
            <Typography className="relative" key={prop('id', data)} useTailwind>
              <Track key={prop('id', data)}>
                <ItemContainer
                  areSensorsVisible={areSensorsVisible}
                  checkOutButtonFooter={checkoutButtonFooter}
                  checkOutClick={() => onAddToCartClick(redirectUrl)}
                  // @ts-expect-error TS(2322): Type '{ areSensorsVisible: boolean; cardBadge: Car... Remove this comment to see the full error message
                  discountAppliedPrice={discountAppliedPrice}
                  discountText={discountText}
                  errorMessage={
                    addToCartError && (
                      <AddToCartError errorType={addToCartError} />
                    )
                  }
                  images={images}
                  itemDetail={itemDetail}
                  linkContent={
                    <div className="prose-p:my-3 prose-p:whitespace-nowrap prose-p:text-white">
                      <ContentfulRichText
                        optionsCustom={linkContentRichTextOptions(() =>
                          setSensorsVisible(true)
                        )}
                        raw={linkText}
                      />
                    </div>
                  }
                  priceCalculationContent={
                    <ContentfulRichText
                      optionsCustom={priceCalculationCustomOptions(
                        priceList,
                        discountText
                      )}
                      raw={priceCalculation}
                      references={priceCalculationReferences}
                    />
                  }
                  selectedImageIndex={selectedImageIndex}
                  sensors={sensors}
                  setSensorsVisible={setSensorsVisible}
                  systemTotalPrice={systemTotalPrice}
                />
                {cardBadge && <CardBadge {...cardBadge} />}
              </Track>
            </Typography>
          )
        }
      )
    )

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const packageLoadingMessage = (
    <ContentfulRichText raw={data.packageLoadingMessage?.richText?.raw} />
  )
  const packageErrorMessage = (
    <BannerError height="responsive">
      <ContentfulRichText
        raw={data.packageRetrievalErrorMessage?.richText?.raw}
      />
    </BannerError>
  )
  return (
    <Typography useTailwind>
      {miniCart.cata(
        () => (
          <BannerLoading>{packageLoadingMessage}</BannerLoading>
        ), // Loading state.
        () => packageErrorMessage, // Error state.
        () => (
          <BannerLoading>{packageLoadingMessage}</BannerLoading>
        ), // No line items state. This flashes briefly on page load before loading = true is dispatched to the minicart
        _miniCart =>
          itemContainer
            .catchMap(() => {
              // Log the error if itemContainer is None - this likely indicates that we're missing the expected component entry/entries in Contentful
              logError(
                Error(
                  'Could not display dynamic package hero banner. Check that expected components exist in CMS.'
                )
              )
              return None()
            })
            .orJust(packageErrorMessage) // TODO render error if we don't have an item container component to show
      )}
    </Typography>
  )
}

export default DynamicPackageHeroBannerComponent
