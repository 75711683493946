import { ContentfulRichText, GatsbyImage } from '@lib/components'
import { GatsbyImageSchema } from '@lib/components'
import deriveHtmlId from '@simplisafe/ewok/contentful-utils/deriveHtmlId'
import { HiddenAnchor } from '@simplisafe/ss-react-components'
import { Text as Typography } from '@simplisafe/ss-react-components'
import React, { useState } from 'react'

import { ContentfulFloorPlan } from '../../../graphql'
import { nullToUndefined } from '../../util/helper'
import FloorPlanProductCardList from './FloorPlanProductCardList'

export type FloorPlanProps = {
  readonly data: ContentfulFloorPlan
}

type selectedFloorPlanImagePropsType = GatsbyImageSchema & {
  readonly description: string
}

export default function FloorPlan({ data }: FloorPlanProps) {
  const [index, setIndex] = useState<number>(0)
  const productCardData = data?.productCards
  const sectionDescription = data?.description?.raw
  const listOFfloorPlanImages = data?.floorPlanImages || []
  // @ts-expect-error TS(2322) FIXME: Type 'ContentfulAsset' is not assignable to type '... Remove this comment to see the full error message
  const selectedFloorPlanImageProps: selectedFloorPlanImagePropsType =
    listOFfloorPlanImages[index]

  const htmlId = deriveHtmlId({
    ...data,
    title: nullToUndefined(data.title)
  })

  return sectionDescription && selectedFloorPlanImageProps ? (
    <Typography
      className="prose md:prose-md prose-h3:text-body-size prose-h3:mb-3 prose-h3:font-bold prose-h3:leading-normal"
      key={data.id}
      useTailwind
    >
      <HiddenAnchor id={htmlId} />
      <div className="grid items-center grid-cols-2 gap-4 md:gap-8 lg:gap-10">
        <div className="col-span-2 lg:col-span-1 lg:pl-0 lg:pr-16 px-0 md:px-10 lg:order-2">
          <GatsbyImage image={selectedFloorPlanImageProps} />
        </div>
        <div className="col-span-2 lg:col-span-1 lg:pl-16 lg:pr-0 px-4 md:px-20 lg:order-1">
          <ContentfulRichText raw={sectionDescription} />
        </div>
      </div>
      <div className="py-10 gap-4 md:gap-8 px-4 md:px-20 lg:px-16 lg:gap-16 grid grid-cols-12 items-stretch">
        {productCardData && (
          <FloorPlanProductCardList
            items={productCardData}
            selectItemIndex={setIndex}
            selectedItemIndex={index}
          />
        )}
      </div>
    </Typography>
  ) : null
}
