import { chainProp } from '@simplisafe/monda/chain'
import { selectActivePromoOverrideDiscountText } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

import promoOverridesSchema from './promoOverridesSchema'

const usePromoOverrideData = () => {
  const overrideProps = useSelector(selectActivePromoOverrideDiscountText)

  const data = promoOverridesSchema.parse({
    chooseMonitoringPage: overrideProps
      .chain(chainProp('chooseMonitoringPage'))
      .getOrElse(''),
    discountSummary: overrideProps
      .chain(chainProp('discountSummary'))
      .getOrElse(''), // 'EXTRA 10% OFF'
    // begin holiday banner workflows.
    endsTextLabel: overrideProps
      .chain(chainProp('endsTextLabel'))
      .getOrElse(''), // 'Early Access'
    heroStickerBackgroundColor: overrideProps
      .chain(chainProp('heroStickerBackgroundColor'))
      .getOrElse(''), // '#5DBA82'
    heroStickerDisclaimerText: overrideProps
      .chain(chainProp('heroStickerDisclaimerText'))
      .getOrElse(''), // 'Any new system with Interactive Monitoring'
    heroStickerFreeGiftLineItemText: overrideProps
      .chain(chainProp('heroStickerFreeGiftLineItemText'))
      .getOrElse(''), // '+ Free Camera'
    heroStickerImage: overrideProps
      .chain(chainProp('heroStickerImage'))
      .getOrElse(''), // For a potential A/B test showing promo product image inside the sticker.
    heroStickerSaleName: overrideProps
      .chain(chainProp('heroStickerSaleName'))
      .getOrElse(''), // 'Black Friday Sale')
    heroStickerTextColor: overrideProps
      .chain(chainProp('heroStickerTextColor'))
      .getOrElse(''), // '#FFFFFF'
    isHolidayBanner: overrideProps
      .chain(chainProp('isHolidayBanner'))
      .getOrElse(false), // Determines if HolidayPromoBanner should replace ActivePromoBanner.
    // end holiday banner workflows.
    listingFlag: overrideProps.chain(chainProp('listingFlag')).getOrElse(''), // 'an extra 10%'
    productFlag: overrideProps.chain(chainProp('productFlag')).getOrElse(''), // 'EXTRA 10% OFF'
    toggleBox: overrideProps.chain(chainProp('toggleBox')).getOrElse('') // 'an extra 10%'
  })

  return data
}

export default usePromoOverrideData
