// @ts-strict

import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { CountryGridContainer } from '@simplisafe/ss-react-components'
import { CountryGridProps } from '@simplisafe/ss-react-components/CountryGridItem'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React, { useMemo } from 'react'

import { ContentfulCrimeLocationGrid } from '../../../graphql'
import Media from '../Media'

type ContentfulCrimeLocationGridProps = {
  readonly id: string
  readonly data: ContentfulCrimeLocationGrid
}

/** The gridContent from the ContentfulLocationGridFragment with undefined and null excluded. */
type GridContentArray = Exclude<
  ContentfulCrimeLocationGrid['gridContent'],
  null | undefined
>

/** A single item from the GridContentArray with undefined and null excluded. */
type GridContent<ArrayType extends readonly unknown[]> = Exclude<
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never,
  null | undefined
>

/** Transforms the raw GridContent into props for the CountryGrid. */
export const toCountryGridContainer = transformObject<
  GridContent<GridContentArray>,
  CountryGridProps
>({
  BackgroundComponent: data => (prop('linkIcon', data) ? Media : undefined),
  backgroundComponentProps: data => ({
    data: { imageItem: path(['linkIcon'], data) }
  }),
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  linkText: data => propOr<string, string>('', 'linkText', data),
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  linkUrl: data => propOr<string, string>('', 'linkUrl', data)
})

/** Gets the gridContent from data with a fallback to an empty array. */
export const getGridContent = (
  data: ContentfulCrimeLocationGrid
): GridContentArray => prop('gridContent', data) || []

/** A typegaurd to allow us to filter out anything that is not CountryGridProps */
const isCountryGridProps = (
  item: CountryGridProps | undefined
): item is CountryGridProps => !!item

export const toLocationGrid = (
  data: Exclude<ContentfulCrimeLocationGrid, null | undefined>
): { readonly locationGridProps: readonly CountryGridProps[] } => ({
  locationGridProps: getGridContent(data)
    /** If val is not null or undefined we convert it to props. */
    .map(val => (val ? toCountryGridContainer(val) : undefined))
    /** Remove anything that is null or undefined so we just have an array of valid props. */
    .filter(isCountryGridProps)
})

function CountryGridContainerComponent({
  data
}: ContentfulCrimeLocationGridProps) {
  const gridProps = useMemo(() => toLocationGrid(data), [data])
  return gridProps.locationGridProps.length > 0 ? (
    <CountryGridContainer {...gridProps} />
  ) : null
}

export default CountryGridContainerComponent

export const query = graphql`
  #graphql
  fragment contentfulLocationGrid on ContentfulCrimeLocationGrid {
    id
    internal {
      type
    }
    gridContent {
      linkText
      linkUrl
      linkIcon {
        gatsbyImageData(layout: CONSTRAINED, width: 220, placeholder: BLURRED)
      }
    }
  }
`
