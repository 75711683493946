import { safeProp } from '@simplisafe/monda'
import { Modal } from '@simplisafe/ss-react-components'
import { useScrollPosition } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import map from 'ramda/src/map'
import React, { useEffect, useState } from 'react'

import { ContentfulModalExitIntent } from '../../../graphql'
import { mapPageComponentToTemplate, PageComponent, PageProps } from '../Page'

type ModalExitIntentProps = {
  readonly data: ContentfulModalExitIntent
  readonly pageContext: PageProps['pageContext']
  readonly location: PageProps['location']
}

export default function ModalExitIntentComponent({
  data,
  pageContext,
  location
}: ModalExitIntentProps) {
  const [hasOpened, setHasOpened] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const shouldOpen = useScrollPosition()

  useEffect(() => {
    !hasOpened && !isOpen && shouldOpen && setIsOpen(true)
  }, [shouldOpen, hasOpened, isOpen])

  const dontOpenThisSession = () => {
    setIsOpen(false)
    setHasOpened(true)
  }

  const contentComponents = safeProp('content', data).chain(Maybe.fromNull)

  return (
    <Modal
      appElementId="___gatsby"
      isOpen={isOpen}
      onRequestClose={dontOpenThisSession}
    >
      {contentComponents.cata(
        () => null,
        map((comp: PageComponent | null) =>
          comp ? (
            <React.Fragment key={comp.id}>
              {mapPageComponentToTemplate(comp, pageContext, location)}
            </React.Fragment>
          ) : null
        )
      )}
    </Modal>
  )
}

export const AlarmSensorsQuery = graphql`
  #graphql
  fragment modalExitIntent on ContentfulModalExitIntent {
    id
    internal {
      type
    }
    content {
      ... on ContentfulGroupSection {
        ...contentfulGroupSectionFragment
      }
    }
    theme
  }
`
