import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImageSchema } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { Carousel } from '@simplisafe/ss-react-components'
import { CarouselProps } from '@simplisafe/ss-react-components/Carousel'
import { PaginationPosition } from '@simplisafe/ss-react-components/CarouselContainer'
import {
  HeroSlideProps,
  PreloadLink
} from '@simplisafe/ss-react-components/HeroSlide'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import last from 'ramda/src/last'
import map from 'ramda/src/map'
import split from 'ramda/src/split'
import toLower from 'ramda/src/toLower'
import unless from 'ramda/src/unless'
import React from 'react'
import { pipe } from 'ts-functional-pipe'

import { ContentfulBanner, ContentfulCarouselFeatures } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import HeadingComponent from '../HeadingComponent'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type CarouselFeaturesComponentProps = {
  readonly data: ContentfulCarouselFeatures
}

const getCustomRenderOptions = (headingData: any): Options => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: _ => <HeadingComponent data={headingData} />
  }
})

const getContent = (keyDescription = 'description') => {
  return applySpec({
    content: bannerData => (
      <ContentfulRichText
        optionsCustom={getCustomRenderOptions(
          bannerData?.[keyDescription]?.references?.[0]
        )}
        raw={bannerData?.description?.raw}
      />
    ),
    title: prop('title')
  })
}

// @ts-expect-error TS(2769) FIXME: No overload matches this call.
const getImage = (
  banner: ContentfulBanner
): Maybe<GatsbyImageSchema & { readonly description: string }> =>
  safePath(['media', '0'], banner).filter(img => !!img)

const toDisplayMode = cond([
  [equals('Center-Left'), always('left')],
  [equals('Center-Right'), always('right')],
  [equals('Top-Center'), always('top')],
  [equals('Top-Left'), always('top-left')]
])

// @ts-expect-error TS(2344) FIXME: Type 'string' does not satisfy the constraint 'any... Remove this comment to see the full error message
const toPaginationPosition = cond<string, PaginationPosition>([
  [equals('Left'), always('below-text')],
  [equals('Center'), always('bottom-center')],
  [equals('Right'), always('bottom-right-to-center')]
])

const getHeroSlide = (keyDescription: string, data: any) => {
  const toContent = getContent(keyDescription)
  return applySpec<HeroSlideProps>({
    backgroundColor: pipe(
      prop('backgroundColor'),
      unless(isNil, split(' ')),
      unless(isNil, last),
      unless(isNil, toLower),
      defaultTo('gray')
    ),
    children: (banner: ContentfulBanner) =>
      getImage(banner)
        // use image from the first slide if the current slide doesn't have one (not sure why, this was existing behavior)

        .orElse(getImage(safePath(['banner', '0'], data).orUndefined()))
        .map(
          (
            img /*<GatsbyImage
        alt={img.description || ''}
        key={`hero-slide-${keyDescription}`}
        image={getImageGatsby(img)} />*/
          ) => (
            <img
              alt={img.description || ''}
              key={`hero-slide-${keyDescription}`}
              src={img?.gatsbyImageData?.images?.fallback?.src}
            />
          )
        )
        .orUndefined(),
    childrenPosition: pipe(
      prop('mediaPosition'),
      unless(isNil, toLower),
      defaultTo('center')
    ),
    content: pipe(unless(isNil, toContent), defaultTo({})),
    displayMode: pipe(
      prop('textPosition'),
      unless(isNil, toDisplayMode),
      defaultTo('top-left')
    ),
    preload: () => None<PreloadLink>(),
    // TECH DEBT: We should change the way that the colors are passed to the HeroSlide.
    textColor: pipe(
      path(['textColor', 'color']),
      unless(
        isNil,
        ifElse(equals('neutralWhite'), always('light'), always('dark'))
      ),
      defaultTo('dark')
    )
  })
}

const toCarouselFeaturesData = (data: ContentfulCarouselFeatures) => {
  const toCarouselFeatures = applySpec<CarouselProps>({
    mediatype: always('image'),
    slideData: pipe(prop('banner'), map(getHeroSlide('description', data)))
  })

  return toCarouselFeatures(data)
}

const isNumberCarousel = pipe(
  prop('carouselIndicatorType'),
  defaultTo(''),
  equals('Chevron with Number')
)

export default function CarouselFeaturesComponent({
  data
}: CarouselFeaturesComponentProps) {
  const numberCarouselIndicatorType =
    // TODO: fix type
    // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulCarouselFeatures' is n... Remove this comment to see the full error message
    isNumberCarousel(data)
  const isMobile = !useMediaQuery('TabletAndUp')
  const paginationAlignment = isMobile
    ? safeProp('paginationPositionMobile', data)
    : safeProp('paginationPosition', data)
  return (
    <Carousel
      {...toCarouselFeaturesData(data)}
      carouselContainerData={{
        arrows: numberCarouselIndicatorType,
        autoplay: !numberCarouselIndicatorType,
        dotsColor: 'blue',

        enableDots: !numberCarouselIndicatorType,
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Paginati... Remove this comment to see the full error message
        paginationPosition: paginationAlignment
          .map(toPaginationPosition)
          .orUndefined(),
        playButton: false
      }}
      enableInlineArrow={numberCarouselIndicatorType}
      slideMobileData={safeProp('banner', data)
        .map(val => val.map(getHeroSlide('description', data)))
        .orUndefined()}
    />
  )
}

export const query = graphql`
  #graphql
  fragment carouselFeaturesInformation on ContentfulCarouselFeatures {
    id
    internal {
      type
    }
    title
    descriptionMobile {
      raw # todo
    }
    backgroundColor
    carouselIndicatorType
    paginationPosition
    paginationPositionMobile
    banner {
      title
      backgroundColor
      textColor {
        color
      }
      description {
        raw
        references {
          ... on ContentfulHeading {
            ...headingFragment
          }
        }
      }
      textPosition
      mediaPosition
      media {
        description # TODO: get description from gatsbyImageData
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        id
        title
      }
    }
  }
`
