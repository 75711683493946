import { getUserId, getVisitorId, handleAtAtTrackingEvent } from '@lib/tracking'
import type { TrackingData } from '@lib/tracking'

import { handleGTMTrackingEvent } from './gtm'

// Handler for tracking calls for the current event and tracking data
const handleTrackingEvent = (data: TrackingData) => {
  // Augment data from the event with values that can potentially change after page load
  const augmentedData: TrackingData = {
    ...data,
    userId: getUserId(),

    visitorId: getVisitorId()
  }

  handleGTMTrackingEvent(augmentedData)
  handleAtAtTrackingEvent(augmentedData)
}

export default handleTrackingEvent
