// @ts-expect-error TS(2307) FIXME: Cannot find module 'mapbox-gl' or its correspondin... Remove this comment to see the full error message
import mapboxgl, { GeoJSONSourceOptions } from 'mapbox-gl'
import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { ContentfulCrimeLocationResults } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type MapProps = {
  readonly latitude: number
  readonly longitude: number
  readonly zoom: number
  readonly geojsonData: GeoJSONSourceOptions['data']
  readonly error: string
  readonly data: Partial<ContentfulCrimeLocationResults>
}

// eslint-disable-next-line functional/immutable-data
mapboxgl.accessToken = process.env.AUGURISK_MAP_BOX_TOKEN || ''

function Map({ longitude, latitude, zoom, geojsonData, data }: MapProps) {
  const mapRef = React.useRef<HTMLDivElement>(null)
  const map = React.useRef<mapboxgl.Map | null>(null)

  const initMap = useCallback(() => {
    map.current = new mapboxgl.Map({
      // style URL
      center: [longitude, latitude],
      container: mapRef.current || 'crime-risk-map',
      // starting zoom
      maxZoom: 18,
      // starting zoom
      minZoom: 4,
      // starting zoom
      projection: { name: 'globe' },
      // container ID
      style: 'mapbox://styles/mapbox/light-v10',
      // starting position [lng, lat]
      zoom: zoom // display the map as a 3D globe
    })

    new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map.current)
    const sourceName = 'crime-layer'

    map.current.on('style.load', () => {
      // Set the default atmosphere style
      map.current && map.current.setFog({})
    })

    map.current.on('load', () => {
      map.current &&
        map.current.addSource(sourceName, {
          data: geojsonData,
          type: 'geojson'
        })

      // Loading Moderate Risk Layer
      map.current &&
        map.current.addLayer({
          filter: ['==', 'score', 'moderate'],
          id: 'moderate-risk-layer',
          layout: {},
          paint: {
            'fill-color': '#ffa668',
            'fill-opacity': 0.4
          },
          source: sourceName,
          type: 'fill'
        })

      // Loading High Risk Layer
      map.current &&
        map.current.addLayer({
          filter: ['==', 'score', 'high'],
          id: 'high-risk-layer',
          layout: {},
          paint: {
            'fill-color': '#f43f5e',
            'fill-opacity': 0.4
          },
          source: sourceName,
          type: 'fill'
        })
      map.current && map.current.setMaxBounds(map.current.getBounds())
    })
  }, [geojsonData, latitude, longitude, zoom])

  useEffect(() => {
    !map.current && initMap()
  }, [initMap])

  return (
    <>
      <Helmet>
        <link
          href={'https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css'}
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div
        className="bg-teal-600 w-full h-64 xl:h-[500px]"
        id="crime-risk-map"
        ref={mapRef}
        role="map"
      ></div>
      <div className="bg-gray-100 w-full px-5 pb-3">
        <div className="text-simplyblue-400 text-sm pt-3 font-semibold mb-2">
          Crime Risk
        </div>
        <div className="bg-gray-100 grid grid-cols-3 mb-1">
          <div className="bg-[#c9d1d2] pt-1"></div>
          <div className="bg-[#f7ba8e] pt-1"></div>
          <div className="bg-[#fd96a8] pt-1"></div>
          <div className="text-[10px] text-simplyblue-400 pt-1">Lowest</div>
          <div></div>
          <div className="text-[10px] text-simplyblue-400 pt-1 text-right">
            Highest
          </div>
        </div>
      </div>

      <div className="my-2 mx-2 hidden md:block">
        <ContentfulRichText raw={data.mapLegalText?.raw} />
      </div>
    </>
  )
}
export default Map
