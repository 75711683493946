import { Text as Typography } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import React from 'react'

export type LinkComponentProps = {
  readonly data: {
    readonly linkText: string
    readonly linkUrl: string
  }
}

/** Simple wrapper component for Gatsby's Link, intended to be used on componentMappings */
function LinkComponent({ data }: LinkComponentProps) {
  const { linkText, linkUrl } = data

  const isInternalLink = linkUrl && linkUrl.startsWith('/')

  return (
    <Typography useTailwind>
      {isInternalLink ? (
        <Link to={linkUrl}>{linkText}</Link>
      ) : (
        <a href={linkUrl}>{linkText}</a>
      )}
    </Typography>
  )
}

export default LinkComponent
