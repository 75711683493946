import * as O from 'fp-ts/Option'
import _ from 'lodash/fp'

/**
 * Safely use lodash's prop function that returns an Option instead of something that could be undefined.
 */
function safeProp<K extends string>(key: K) {
  return function <O extends { readonly [K1 in K]?: V }, V>(
    obj: O
  ): O.Option<NonNullable<O[K]>> {
    return O.fromNullable(_.prop(key, obj))
  }
}

export default safeProp
