import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { PageProps } from 'gatsby'
import React from 'react'

import { ContentfulPlaceholder } from '../../../graphql'
import EmbeddedCrimeTable from './EmbeddedCrimeTable'
import IframePlaceholder from './IframePlaceholder'

type PlaceholderComponentProps = {
  readonly data: Partial<ContentfulPlaceholder>
  readonly pageContext: PageProps['pageContext'] & {
    readonly iframeConfig: Record<string, string>
  }
}

const COMPONENT_TYPES = {
  'Crime in The UK - City Crime Comparison': 'UK_CRIME_TABLE'
}

const placeholderMapping = { UK_CRIME_TABLE: EmbeddedCrimeTable }

export const getPlaceholder = (data: any) => {
  const placeholderType = prop('type', data)
  const PlaceholderComponent =
    placeholderMapping[COMPONENT_TYPES[placeholderType]]

  return PlaceholderComponent ? <PlaceholderComponent data={data} /> : null
}

function PlaceholderComponent({
  data,
  pageContext
}: PlaceholderComponentProps) {
  const placeholderType = prop('type', data)
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const iframeUrl = path<string>(['iframeConfig', placeholderType], pageContext)

  // @ts-expect-error TS(2322) FIXME: Type 'Exclude<string[keyof O], null>' is not assig... Remove this comment to see the full error message
  return iframeUrl ? (
    <IframePlaceholder iframeUrl={iframeUrl} />
  ) : (
    getPlaceholder(data)
  )
}

export default PlaceholderComponent
