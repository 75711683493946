import React, { HTMLAttributes } from 'react'

import { shouldCardSpan } from './helpers'
import ReviewCard from './ReviewCard'
import { ReviewBlockProps } from './types'

function ReviewBlock({
  content = [],
  className = ''
}: HTMLAttributes<HTMLDivElement> & ReviewBlockProps) {
  return (
    <div className={`grid grid-cols-2 gap-4 ${className}`}>
      {content.map((card, index, cards) => {
        return (
          <ReviewCard
            className={shouldCardSpan(index, cards.length) ? 'col-span-2' : ''}
            icon={card.icon}
            iconPosition={card.iconPosition}
            iconSize={card.iconSize}
            key={`review_card_${card.id}`}
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulIconWithTextText' is not assignabl... Remove this comment to see the full error message
            text={card.text}
            textPosition={card.textPosition}
          />
        )
      })}
    </div>
  )
}

export default ReviewBlock
