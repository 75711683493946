import { selectCartCount } from '@simplisafe/ss-ecomm-data/redux/select'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export function useHeaderData() {
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isTablet = useMediaQuery('TabletAndUp')
  const [
    mobileOrTabletRightSideContentFieldName,
    setMobileOrTabletRightSideContentFieldName
  ] = useState<'mobileHeaderContent' | 'tabletHeaderContent'>(
    'mobileHeaderContent'
  )

  useEffect(() => {
    isTablet
      ? setMobileOrTabletRightSideContentFieldName('tabletHeaderContent')
      : setMobileOrTabletRightSideContentFieldName('mobileHeaderContent')
  }, [isTablet])

  const cartItemCount = useSelector(selectCartCount)

  return {
    cartItemCount,
    isDesktop,
    isTablet,
    mobileOrTabletRightSideContentFieldName
  }
}
