import { GatsbyImageSchema } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { getImage } from 'gatsby-plugin-image'
import { isEmpty } from 'ramda'

import { ContentfulHeroBannerColumn } from '../../../graphql'

const emptyImage = {
  aspectRatio: 1,
  sizes: '',
  src: '',
  srcSet: ''
}

export const useHeroColumnImage = (column: ContentfulHeroBannerColumn) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type '"imageMobile"' is not assignable... Remove this comment to see the full error message
  const imageMobile = getImage(prop<GatsbyImageSchema>('imageMobile', column))
  // @ts-expect-error TS(2345) FIXME: Argument of type '"imageTablet"' is not assignable... Remove this comment to see the full error message
  const imageTablet = getImage(prop<GatsbyImageSchema>('imageTablet', column))
  // @ts-expect-error TS(2345) FIXME: Argument of type '"image"' is not assignable to pa... Remove this comment to see the full error message
  const imageDesktop = getImage(prop<GatsbyImageSchema>('image', column))

  const images = [
    imageDesktop || imageTablet || imageMobile,
    imageTablet
      ? {
          ...imageTablet,
          media: '(max-width: 1239px)'
        }
      : null,
    imageMobile
      ? {
          ...imageMobile,
          media: '(max-width: 767px)'
        }
      : null
    // @ts-expect-error TS(2677) FIXME: A type predicate's type must be assignable to its ... Remove this comment to see the full error message
  ].filter((el): el is GatsbyImageSchema => !!el)

  return { images: !isEmpty(images) ? images : [] }
}
