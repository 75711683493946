import {
  generateFacebookEventId,
  TrackingData,
  useFacebookTrackSiteVisits,
  useOptimizelyActivateExperiment,
  useOptimizelyTrackPageVisit,
  brazeTrackPageVisit
} from '@lib/tracking'
import { getDeployEnv } from '@lib/utils'
import { useLocation } from '@reach/router'
import { safeProp } from '@simplisafe/monda'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { PageTitleContext } from './pageTitleContext'
import useReferrerUrl from './useReferrerUrl'

type InjectPageTrackingProps = {
  readonly children: ReactElement
}

// NOTE: This is a legacy component; when making changes here, also consider
// whether the same change needs to be added to the TrackingProvider exported
// by @lib/tracking.

function InjectPageTracking({ children }: InjectPageTrackingProps) {
  const location = useLocation()
  const { key: locationKey, pathname, search } = location
  const referrer = useReferrerUrl(location)
  const facebookEventId = generateFacebookEventId()
  const [pageTitle, setPageTitle] = useState<string>()
  const [isPageTitleSet, setIsPageTitleSet] = useState(false)
  const [isPageLoadTracked, setPageLoadTracked] = useState(false)
  const { Track, trackEvent } = useTracking({
    environment: getDeployEnv(),
    facebookEventId: facebookEventId,
    pagePath: pathname,
    pageTitle,
    queryString: search,
    referrer: referrer
  })
  const locationPath = safeProp('pathname', location).orUndefined()
  const hostName = safeProp('hostname', location).getOrElse('')
  const siteLocale = useSelector(selectLocale)

  const optimizelyTrackPageVisit = useOptimizelyTrackPageVisit()
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()

  const facebookTrackSiteVisits = useFacebookTrackSiteVisits()

  /* --- react-tracking page load tracking --- */
  useEffect(() => {
    // This might be overkill, and not sure if/when this would happen, but this but makes sure that
    // we only track a page load once, and not if the page title changes multiple times within one page load
    setPageLoadTracked(false)
    setIsPageTitleSet(false)
  }, [locationKey])

  useEffect(() => {
    // Tracks a page load event if pageTitle and location have changed
    !isPageLoadTracked &&
      isPageTitleSet &&
      (trackEvent({
        event: 'pageLoad',
        facebookEventId: facebookEventId
      }),
      setPageLoadTracked(true))
    // don't include facebookEventId in the dependency array
  }, [isPageLoadTracked, isPageTitleSet, trackEvent])

  const handleSetPageTitle = (pageTitle: string) => {
    setPageTitle(pageTitle)
    setIsPageTitleSet(true)
  }

  /* --- optimizely and braze page view tracking --- */

  useEffect(() => {
    optimizelyTrackPageVisit({ pageUrl: locationPath })
    brazeTrackPageVisit()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only track this once per page load
  }, [locationPath])

  useEffect(() => {
    facebookTrackSiteVisits({
      eventId: facebookEventId,
      pageUrl: location.origin + locationPath
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only track this once per page load
  }, [location])

  const activateGenericUSExperiment: boolean =
    siteLocale === 'en-US' && !hostName.includes('us.prd.')
  useEffect(() => {
    activateGenericUSExperiment &&
      optimizelyActivateExperiment({ experimentId: 'test_for_affirm_events' })
  }, [activateGenericUSExperiment, optimizelyActivateExperiment])

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <PageTitleContext.Provider value={handleSetPageTitle}>
        {children}
      </PageTitleContext.Provider>
    </Track>
  )
}

export default InjectPageTracking
