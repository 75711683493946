import { Document } from '@contentful/rich-text-types'
import { Maybe } from 'monet'

import documentFallback from './documentFallback'

export const replaceTokens = (
  text: string,
  search: string,
  replace: string
): string => {
  const regex = new RegExp(`(?:${search})`, 'g')
  return text.replace(regex, replace)
}

// Converts Document to string, executes string/replace and returns as Document.
// Usage:
// const result = getTokenizedJson(document, '{{discount_value}}', '$100.00')
/**
 * @deprecated - replace usages with `replaceTokens`
 */
const getTokenizedJson = (
  json: Document,
  token: string,
  value: string
): Document => {
  const jsonStringified = Maybe.fromNull(json)
    .chain(json => Maybe.of(JSON.stringify(json)))
    .getOrElse('')
  const tokenizedString = replaceTokens(jsonStringified, token, value)

  return documentFallback(JSON.parse(tokenizedString))
}

export default getTokenizedJson
