import { CSSProperties } from 'react'

export const shouldCardSpan = (
  index: number,
  amountOfCards: number
): boolean => {
  return amountOfCards % 2 === 1 && index === amountOfCards - 1
}

export const getImageCardClassName = (size: any): string => {
  const classes = {
    extralarge: 'w-24 max-h-32',
    large: 'w-16',
    medium: 'w-10 h10',
    responsive: 'w-32 max-h-16',
    small: 'w-8 h-8'
  }
  return classes[size] ?? 'w-5/6'
}

export const getImageWrapperStyle = (
  iconPosition: string,
  iconSize: string
): string => {
  return iconPosition === 'Background'
    ? 'h-full w-full'
    : getImageCardClassName(iconSize)
}

export const getImageStyle = (iconPosition: string): CSSProperties => {
  return iconPosition === 'Background'
    ? {
        borderRadius: '10px',
        height: '100%'
      }
    : null
}

export const getImageCardPadding = (iconPosition: string): string => {
  return iconPosition !== 'Background' ? 'py-2 px-4 gap-2' : ' '
}
