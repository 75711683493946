import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { CheckMark, CloseX } from '@simplisafe/ss-react-components/icons'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulRichTextWithOptions } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import { RichTextProps } from '../RichText'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
export type RichTextWithOptionsComponentProps = {
  readonly data: ContentfulRichTextWithOptions
}

const textAlignMapper: { [key: string]: 'center' | 'left' | 'right' } = {
  Center: 'center',
  Left: 'left',
  Right: 'right'
}

const toTextAlignment = (contentfulTextAlignValue?: string) =>
  contentfulTextAlignValue
    ? textAlignMapper[contentfulTextAlignValue]
    : undefined

const listStyleMapper: { [key: string]: RichTextProps['listStyle'] } = {
  Checkmark: 'checkmark',
  Disc: 'disc',
  RedX: 'redx',
  Square: 'square'
}

const toListStyle = (contentfulListStyleValue?: string) =>
  contentfulListStyleValue && contentfulListStyleValue !== 'standard'
    ? listStyleMapper[contentfulListStyleValue]
    : undefined

const toMaxWidth = (contentfulMaxWidth: boolean | null | undefined): boolean =>
  contentfulMaxWidth ? true : false

const textSizeMapper: { [key: string]: RichTextProps['textSize'] } = {
  md: 'md',
  sm: 'sm',
  xs: 'xs'
}

const toTextSize = (value?: string | null) =>
  value ? textSizeMapper[value] : undefined

const paddingMapper: { [key: string]: RichTextProps['padding'] } = {
  large: 'large',
  medium: 'medium',
  small: 'small'
}

const toPadding = (value?: string | null) =>
  value ? paddingMapper[value] : undefined

const marginMapper: { [key: string]: RichTextProps['margin'] } = {
  auto: 'auto'
}

const toMargin = (value?: string | null) =>
  value ? marginMapper[value] : undefined

const listElements: Record<string, JSX.Element> = {
  checkmark: <CheckMark className="text-complementary-sage-100" />,
  redx: <CloseX className="text-complementary-red-100" />
}

const options = (listStyle: string): Options => ({
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul className="mx-0 my-4 p-0">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => {
      // It's checkmark only for now but we can add more list types here to be parsed...
      return (
        <li
          className={classNames('py-2 gap-2 ml-4', {
            'flex items-center ml-0':
              listStyle === 'checkmark' || listStyle === 'redx',
            'list-[square]': listStyle === 'square'
          })}
        >
          {listElements[listStyle]}
          {children}
        </li>
      )
    }
  }
})

export default function RichTextWithOptionsComponent({
  data
}: RichTextWithOptionsComponentProps) {
  // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
  const listStyle: string = toListStyle(data.listStyle ?? '')
  const textColor: string = path(['textColor', 'color'], data) ?? ''
  const maxWidth = toMaxWidth(prop('hasMaxWidth', data))
  const textAlignment = toTextAlignment(prop('textAlignment', data))
  const textSize = toTextSize(prop('textSize', data))
  const padding = toPadding(prop('padding', data))

  return (
    <div
      className={classNames({
        [`${textColor}TextColor`]: !!textColor,
        alignCenter: textAlignment === 'center',
        alignRight: textAlignment === 'right',
        'max-w-prose': maxWidth,
        'rc-p-4': padding === 'small',
        'rc-py-8 rc-px-4 md:rc-py-8': padding === 'medium',
        'rc-py-8 rc-px-4 md:rc-py-8 lg:rc-p-16': padding === 'large',
        textSizeSM: textSize === 'sm',
        textSizeXS: textSize === 'xs'
      })}
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Margin<s... Remove this comment to see the full error message
      style={{ margin: toMargin(prop('margin', data)) }}
    >
      <ContentfulRichText
        optionsCustom={options(listStyle)}
        raw={data?.richText?.raw}
        // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulAssetContentfulButtonCont... Remove this comment to see the full error message
        references={data?.richText?.references}
      />
    </div>
  )
}

export const query = graphql`
  #graphql
  fragment richTextWithOptions on ContentfulRichTextWithOptions {
    id
    internal {
      type
    }
    listStyle
    hasMaxWidth
    containerMargin
    richText {
      raw
      references {
        ... on ContentfulIconWithText {
          ...contentfulIconWithTextBase
        }
        ... on ContentfulModal {
          ...secondaryModalFragment
        }
        ... on ContentfulHeading {
          ...headingFragment
        }
      }
    }
    textAlignment
    textColor {
      color
    }
    textSize
    padding
    margin
  }
`
