import { Options } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { AffirmClient } from '@lib/components'
import type { SimpliSafeCSSProperties } from '@lib/components'
import { useOptimizelyAffirm } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safePath, safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { getLocalizedString } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import {
  selectActivePromoFlag,
  selectActivePromoOverrideDiscountText,
  selectDisplayMonitoringDiscount,
  selectItemFromSku,
  selectNavigationProductCategory,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SSButton } from '@simplisafe/ss-react-components'
import { AffirmPromoMessage } from '@simplisafe/ss-react-components'
import { CardItemBanner } from '@simplisafe/ss-react-components'
import { CardItemBannerProps } from '@simplisafe/ss-react-components/CardItemBanner'
import { ItemProduct } from '@simplisafe/ss-react-components/IncludedItem'
import { OfferTagProps } from '@simplisafe/ss-react-components/OfferTag'
import { window } from 'browser-monads-ts'
import { graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import concat from 'ramda/src/concat'
import contains from 'ramda/src/contains'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import isNil from 'ramda/src/isNil'
import pipe from 'ramda/src/pipe'
import React, { ReactElement, ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  ContentfulButton,
  ContentfulLinkAddToCart,
  ContentfulProductCard
} from '../../../graphql'
import { formatDiscountDifferenceText } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount'
import useRequestPrice from '../../hooks/useRequestPrice'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import { toButton } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'
import LinkAddToCart from '../LinkAddToCart'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type ContentfulCardItemBannerProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ContentfulProductCard
}

const renderProductButton = (
  button: Pick<
    ContentfulButton,
    'addToCartButton' | 'buttonSize' | 'text' | 'type' | 'url'
  >,
  productSku?: string
) => {
  const buttonText = safeProp('text', button).getOrElse('')
  const buttonType = safeProp('type', button).getOrElse('')
  const buttonUrl = safeProp('url', button).getOrElse('')
  const buttonSize = safeProp('buttonSize', button).getOrElse('auto')
  const addToCartButton: boolean = safeProp(
    'addToCartButton',
    button
  ).getOrElse(false)
  const data: Partial<ContentfulButton | ContentfulLinkAddToCart> = {
    buttonSize,
    buttonType,
    internal: {
      contentDigest: '',
      owner: '',
      type: 'ContentfulLinkAddToCart'
    },
    productSku,
    text: buttonText,
    url: buttonUrl
  }

  return addToCartButton === true ? (
    // @ts-expect-error TS(2322) FIXME: Type 'Partial<ContentfulButton | ContentfulLinkAdd... Remove this comment to see the full error message
    <LinkAddToCart data={data} />
  ) : buttonUrl ? (
    <Link to={buttonUrl}>
      <SSButton
        color={toButton(button).color}
        dataComponent="ProductButton"
        type="div"
      >
        {buttonText}
      </SSButton>
    </Link>
  ) : (
    <div />
  )
}

const toCardItemBannerProps = (
  data: any,
  isNoPlanDiscount: any,
  skuID?: string
) =>
  applySpec<CardItemBannerProps>({
    disclaimerText: d => (isNoPlanDiscount ? '' : prop('disclaimerText', d)),
    imagePosition: prop('imagePosition'),
    itemImage: path(['image', '0', 'file', 'url']),

    locale: always(locale),
    productButton: (data: ContentfulProductCard) =>
      safeProp('button', data)
        .map(x => renderProductButton(x, skuID))
        .orJust(<div />),
    productDescriptionContent: () => (
      <ContentfulRichText raw={data?.productDescription?.raw} />
    ),
    productHighlightsContent: () => (
      <ContentfulRichText raw={data?.productHighlights?.raw} />
    ),
    showSensorList: prop('showSensorList'),
    textPosition: prop('textPosition'),
    title: prop('title')
  })(data)

export const toSensorList = transformObject<PackageProduct, ItemProduct>({
  productName: packageProduct =>
    pipe(
      (packageProduct: any) => defaultTo('')(prop('name', packageProduct)),

      getLocalizedString(locale),
      (prodName: string) =>
        concat(prop('quantity', packageProduct).toString(), ` ${prodName}`)
    )(packageProduct)
})

// Filter Products from the Sensor List: window decal: SSWD2, yard sign: SSYS3
const sensorFilter = ['SSWD2', 'SSYS3']
const toItemProducts = (packageVal: Package) =>
  safeProp('products', packageVal).map(products =>
    products
      .filter(
        packageProduct =>
          !!prop('isViewable', packageProduct) &&
          !sensorFilter.includes(prop('sku', packageProduct))
      )
      .map(toSensorList)
  )

// @ts-expect-error TS(2339) FIXME: Property 'img' does not exist on type 'ContentfulP... Remove this comment to see the full error message
const toGatsbyImage = (imgData: ContentfulProductCard['img']): ReactNode => {
  const altText: string = Maybe.fromNull(imgData)
    .chain(safePath(['0', 'title']))
    .getOrElse('')

  const id: number = Maybe.fromNull(imgData)
    .chain(safePath(['0', 'id']))
    .getOrElse(null)

  return Maybe.fromNull(imgData)
    .chain(safeProp('0'))
    .map(imgProps => (
      <GatsbyImage
        alt={altText}
        image={getImage(imgProps)}
        imgStyle={{ objectFit: 'cover' }}
        key={id}
        style={{
          height: '100%',
          position: 'absolute',
          width: '100%'
        }}
      />
    ))
    .orNull()
}

export default function ContentfulCardItemBanner({
  affirmClient = window.affirm,
  data
}: ContentfulCardItemBannerProps) {
  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()

  const category = useSelector(selectNavigationProductCategory)

  const isActive = React.useMemo(
    () =>
      safeProp('categoryFilterInclusion', data).cata(
        // eslint-disable-next-line ramda/prefer-ramda-boolean -- legacy code
        () => true,
        categories => contains(category, categories)
      ),
    [data, category]
  )
  const skuID: string = safeProp('productId', data).orJust('')
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  // For US, when the top banner promo is displayed then priority should be given to discount without plan.
  const isNoPlanDiscount =
    equals('en-US', process.env.LOCALE) && isPromoTopBanner
  const props = toCardItemBannerProps(data, isNoPlanDiscount, skuID)

  const _packageOrProduct = useSelector(selectItemFromSku(skuID))
  const packageProps = useMemo(
    () =>
      _packageOrProduct.map(item =>
        item['@@type'] === 'package'
          ? toItemProducts(item)
          : None<readonly ItemProduct[]>()
      ),
    [_packageOrProduct]
  ).cata<readonly ItemProduct[]>(
    () => [],
    p => p.getOrElse([])
  )

  // Log an error if we don't have a valid package or product. Only triggers after initial mount in order to avoid
  // logging errors before we've hydrated the redux store with product/package data from pageContext.
  useEffectAfterMount(() => {
    _packageOrProduct.forEachLeft(e => {
      logError(e)
    })
  }, [_packageOrProduct, skuID])

  const displayMonitoringPrice = useSelector(selectDisplayMonitoringDiscount)

  const {
    getPrice,
    getDiscountedPrice,
    getDiscountedText,
    getFormattedPrice,
    getDiscountedTextWithServicePlan
    // @ts-expect-error - generating types via graphql-codegen failed
  } = useRequestPrice(skuID, data.showAbsoluteDiscountAsRelative)
  const maybePrice = getDiscountedPrice.catchMap(() => getPrice)
  const formattedPrice = getFormattedPrice(
    priceDefault,
    true,
    displayMonitoringPrice
  )

  const priceDetails = {
    pieces: safeProp('quantity', data).orUndefined(),
    // PriceDetails type should be updated to be a ReactNode instead of ReactElement
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    price: formattedPrice as ReactElement
  }

  const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
  const discountText = displayMonitoringDiscount
    ? getDiscountedTextWithServicePlan
    : getDiscountedText

  const affirmPromoMessage =
    maybePrice &&
    maybePrice
      .map(_price => {
        return (
          <AffirmPromoMessage
            affirmClient={affirmClient}
            className="affirm-as-low-as"
            key={_price}
            onLearnMoreClick={optimizelyAffirmLearnMore}
            pageType="product"
            price={_price}
          />
        )
      })
      .orNull()

  const taggingTextRaw = data?.promotionalTagging?.taggingText?.raw
  const modifiedTaggingTextRaw =
    taggingTextRaw && taggingTextRaw.replace('"value":" OFF"', '"value":""')

  const promoFlag = useSelector(selectActivePromoFlag)
  const tagBackgroundColor = promoFlag
    .chain(chainProp('backgroundColor'))
    .orUndefined()
  const tagTextColor = promoFlag.chain(chainProp('textColor')).orUndefined()

  const discountValueText = useMemo(
    () =>
      _packageOrProduct
        .map(p => formatDiscountDifferenceText(p, true))
        .cata(
          error => JSON.stringify(error),
          val => val
        ),
    [_packageOrProduct]
  )

  // TODO: Gatsby 4 rich text - change additionalInfo code to render a richText with raw content
  const additionalInfo =
    data?.additionalInfo?.raw?.match(/value":"([^"]+)"/)?.[1]

  const rawTokens = additionalInfo && additionalInfo.split(/(?:{{|}})/g)
  const textTokens =
    rawTokens &&
    rawTokens.map((token: string, idx: number) =>
      token === 'legacy_discount_percent' ? (
        <span key={`discount-${idx}`}>{discountText.orJust('')}</span>
      ) : token === 'legacy_discount_value' ? (
        <span key={`discount-value-${idx}`}>{discountValueText}</span>
      ) : (
        <span key={`text-${idx}`}>{token}</span>
      )
    )

  const text = !isNil(additionalInfo) ? (
    <div
      className={'placeholder-wrapper'}
      data-component={`${ContentfulCardItemBanner.name}-discountInfo`}
    >
      {textTokens}
    </div>
  ) : null

  const overrideTextMaybe = useSelector(selectActivePromoOverrideDiscountText)
    .chain(safeProp('listingFlag'))
    .chain(val => val)
  const hasOverrideText = !overrideTextMaybe.isNone()

  const offerTag: OfferTagProps | undefined = discountText
    .filter(val => !!val && !!taggingTextRaw)
    .filter(__ => isPromoTopBanner)
    .map(val => {
      const placeholderText =
        (displayMonitoringDiscount &&
          hasOverrideText &&
          overrideTextMaybe.some()) ||
        val
      const options: Options = {
        renderNode: { [INLINES.EMBEDDED_ENTRY]: _ => <>{placeholderText}</> }
      }
      const style: SimpliSafeCSSProperties = tagTextColor
        ? { '--prose-body-color': tagTextColor }
        : {}

      const content = (
        <div data-component="ContentfulCardItemTag" style={style}>
          <ContentfulRichText
            optionsCustom={options}
            raw={
              (displayMonitoringDiscount &&
                hasOverrideText &&
                modifiedTaggingTextRaw) ||
              taggingTextRaw
            }
          />
        </div>
      )

      return {
        content,
        tagBackgroundColor,
        tagTextColor
      }
    })
    .orUndefined()

  // @ts-expect-error TS(2769): No overload matches this call.
  const productImage = toGatsbyImage(prop('img', data))

  return (
    <>
      {isActive && (
        <CardItemBanner
          additionalInfo={text}
          affirmPromoMessage={affirmPromoMessage}
          key={data.id}
          {...props}
          itemImage={productImage}
          offerTag={offerTag}
          priceDetails={priceDetails}
          productSensors={packageProps}
        />
      )}
    </>
  )
}

export const query = graphql`
  #graphql
  fragment productCard on ContentfulProductCard {
    id
    internal {
      type
    }
    productId
    imagePosition
    showSensorList
    textPosition
    title
    productDescription {
      raw
    }
    productHighlights {
      raw
    }
    additionalInfo {
      raw
    }
    disclaimerText
    button {
      text
      type
      url
      buttonSize
      addToCartButton
    }
    categoryFilterInclusion
    img: image {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      id
      title
    }
    promotionalTagging {
      taggingText {
        raw
      }
    }
    showAbsoluteDiscountAsRelative
    quantity
  }
`
