import { useScrollPosition } from '@lib/react-hooks'
import {
  COOKIE_LEAD_DATA,
  cookies,
  devParams,
  getLeadData,
  useCookieChange,
  useOptimizelyTrackSiteEvents,
  visitorIdAtAt,
  brazeLogCustomEvent,
  brazeTrackQuoteWizardSubmission,
  handleBrazeTrackingEvent
} from '@lib/tracking'
import { fbTrackLeadCreated } from '@lib/tracking'
import { get as sessionStorageGet } from '@lib/utils'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  leadGenCreate,
  LeadGenCreateParams
} from '@simplisafe/ss-ecomm-data/leads/quoteWizard'
import {
  selectActivePromoCode,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import {
  PopupCard,
  PopupCardWizardWrapper
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { get, set } from 'local-storage'
import { Maybe } from 'monet'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import {
  ContentfulFindYourPerfectSystem,
  ContentfulFloatingBar
} from '../../../graphql'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
import {
  trackEventCompletedQuoteWizard,
  trackSubmitLeadEvent
} from '../../util/analytics'
import ContentfulRichText from '../ContentfulRichText'
import QuoteWizardComponent from '../QuoteWizardComponent'
import { buildPopupWizardResponseData } from './popupWizardTrackingData'

export type PopupWizardProps = {
  readonly data: Partial<ContentfulFloatingBar>
  readonly includePhone?: boolean
  readonly isNewUser?: boolean
}

const isRecord = <T,>(
  value: Record<string, T> | unknown
): value is Record<string, T> => {
  return !isNil(value) && typeof value === 'object'
}

const popupOpenedStorageKey = 'popupWizardOpened'

function PopupWizard({
  data,

  // TODO Needs to untilize AT-AT when it is fully integrated
  isNewUser = true
}: PopupWizardProps) {
  const leadDataCookie = getLeadData()
  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const [defaultEmail, setDefaultEmail] = useState(
    propOr<string, string>('', 'email', leadDataCookie)
  )
  const bottomBannerVisible = sessionStorageGet('bottom_banner_visible')
  const [showPopupQuoteWizard, setShowPopupQuoteWizard] = useState(false)
  const showPopup = get(popupOpenedStorageKey) ? false : true
  const promoCode = useSelector(selectActivePromoCode)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const { hasGoneDownAndUp } = useScrollPosition()
  const isMobile = !useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('TabletAndUp')
  const isEvergreenMobileEligible =
    !hidePopups && isMobile && isPromoTopBanner === false
  const isPromoMobileEligible =
    !hidePopups &&
    isMobile &&
    isPromoTopBanner === true &&
    bottomBannerVisible === 'hidden'
  const isDesktopPopUpEligible = !hidePopups && isDesktop
  const { Track, trackEvent } = useTracking({
    appSection: 'quoteWizard',
    wizardType: 'popup'
  })

  useCookieChange(COOKIE_LEAD_DATA, data =>
    setDefaultEmail(propOr('', 'email', JSON.parse(data)))
  )

  const isEligible =
    showPopup &&
    (isEvergreenMobileEligible ||
      isPromoMobileEligible ||
      isDesktopPopUpEligible)
  const { hidePopupWizard } = useContext(HidePopupWizard)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const timeout = (prop('timeDelay', data) || 15) * 1000
  useEffect(() => {
    const timer = setTimeout(
      () =>
        isNewUser && isEligible ? setShowPopupQuoteWizard(true) : undefined,
      timeout
    )
    return () => clearTimeout(timer)
  }, [isNewUser, isEligible, timeout])

  useEffect(() => {
    isEligible && setShowPopupQuoteWizard(hasGoneDownAndUp)
  }, [isEligible, hasGoneDownAndUp])

  useEffect(() => {
    !hidePopupWizard && setShowPopupQuoteWizard(hidePopupWizard)
  }, [hidePopupWizard])

  const handleWizardSubmitFailure = (error: Error) => {
    // eslint-disable-next-line no-console
    console.error(error)
    optimizelyTrackSiteEvents({ eventType: 'website_error' })
  }

  const handleWizardSubmitSuccess =
    (responses: Record<string, string>) =>
    async (value: Maybe<readonly unknown[]>) => {
      const response = value.fold({})(response => response)
      cookies.set(COOKIE_LEAD_DATA, response, cookiesOption)
      handleBrazeTrackingEvent(response)

      const wizardResponseData = buildPopupWizardResponseData(responses)
      brazeTrackQuoteWizardSubmission(wizardResponseData)

      trackEventCompletedQuoteWizard(trackEvent)
      trackSubmitLeadEvent(trackEvent)

      optimizelyTrackSiteEvents({ eventType: 'quote_wizard_complete_fs' })
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      const email = propOr<string, string>('', 'email', response)

      // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      email && (await fbTrackLeadCreated(email).catch(e => logError(e)))
    }
  const handlePopupWizardSubmit = (responses: Record<string, unknown>) => {
    const parsedResponses = isRecord<string>(responses) ? responses : {}
    const leadGenParams: LeadGenCreateParams = {
      format: 'popup',
      leadPromoOffer: promoCode.getOrElse('NO_CODE'),
      leadSourceVersion: {},
      locale: 'en-GB', // TODO pull in from page context
      responses: parsedResponses,
      showPhoneField: false,
      visitorId: visitorIdAtAt() || 'NO_VISITOR_ID'
    }

    leadGenCreate(leadGenParams)(handleWizardSubmitFailure)(
      handleWizardSubmitSuccess(parsedResponses)
    )
  }

  const onClose = () => {
    // TODO: this tracks QW to be closed/dismissed even when a user is on the last screen after submitting an email
    // we should not track close/dismiss events on the last screen of QW where user sees a confirmation of email submitted
    trackEvent({ event: 'closePopover' })
    brazeLogCustomEvent('qw_dismiss')
    set(popupOpenedStorageKey, true)
    setShowPopupQuoteWizard(false)
  }

  const finalTabContentsData: ContentfulFindYourPerfectSystem['finalTabContents'] =
    pathOr([], ['component', 'finalTabContents'], data)

  const finalTabContents = finalTabContentsData
    ? finalTabContentsData.map(componentData => (
        <ContentfulRichText
          // TODO: Gatsby 4 rich text
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          key={`popup-${path(['description', 'id'], componentData)}`}
          // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type 'Con... Remove this comment to see the full error message
          raw={componentData?.description?.raw}
          // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type 'Con... Remove this comment to see the full error message
          references={componentData?.description?.references}
        />
      ))
    : []

  const quoteWizardComponent = prop('component', data)
  return quoteWizardComponent ? (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <PopupCard onCloseCallback={onClose} showOpen={showPopupQuoteWizard}>
        <PopupCardWizardWrapper>
          <QuoteWizardComponent
            // @ts-expect-error : Type '"ContentfulVariationContainer"' is not assignable to type '"ContentfulFindYourPerfectSystem" | undefined'.
            data={quoteWizardComponent}
            // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
            defaultEmail={defaultEmail}
            finalTabContents={finalTabContents}
            handleEmailSubmit={handlePopupWizardSubmit}
            includePhone={false}
            showTitle={false}
            type="popup"
          />
        </PopupCardWizardWrapper>
      </PopupCard>
    </Track>
  ) : null
}

export default PopupWizard
