import { FormField, Text } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type Props = {
  readonly text?: ReactElement
}

function PrivacyPolicy({ text }: Props) {
  return (
    <FormField hideLabel={true} name="getAQuoteFormPrivacyPolicy">
      <Text textAlignment="center" textSize="xs">
        {text}
      </Text>
    </FormField>
  )
}

export default PrivacyPolicy
