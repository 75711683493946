import { TrackingData } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { ComparisonTableRow } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Info } from '@simplisafe/ss-react-components/icons'
import { graphql } from 'gatsby'
import isNil from 'ramda/src/isNil'
import React from 'react'
import { useTracking } from 'react-tracking'

import {
  ContentfulModal,
  ContentfulPlanComparisonTableRow
} from '../../../graphql'
import ModalComponent from '../ModalComponent'

export const trackComparePlanTooltipEvent = (trackEvent: any, label: any) => {
  trackEvent({
    action: 'compare-plan-tooltip',
    event: 'tooltip',

    label: label,
    site: 'fcp'
  })
}

type ComparisonTableRowComponentProps = {
  readonly data: Partial<ContentfulPlanComparisonTableRow>
}

export const renderTarget = (
  rowLabel: string,
  infoButtonText: string,
  comparisonTableRowModal: ContentfulModal,
  isMobile: boolean,
  trackEvent: (data: Partial<TrackingData>) => void
) => (
  <>
    {!isMobile && <p style={{ margin: 0 }}>{rowLabel}</p>}
    <ModalComponent
      clickTarget={
        !isMobile ? (
          <Info
            className="icon"
            forceButtonMode={true}
            titleA11y={infoButtonText}
          />
        ) : (
          <a>{rowLabel}</a>
        )
      }
      data={comparisonTableRowModal}
      onClick={() => {
        trackComparePlanTooltipEvent(trackEvent, rowLabel)
      }}
    />
  </>
)

export default function ComparisonTableRowComponent({
  data
}: ComparisonTableRowComponentProps) {
  const comparisonTableRowModal = prop('rowModal', data)

  const isProPremiumFeature = prop('isProPremiumFeature', data) || false

  const isStandardFeature = prop('isStandardFeature', data) || false

  const rowLabel = prop('rowLabel', data) || ''

  // TODO the types here are all wrong

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const infoButtonText: string = path(
    ['rowModal', 'clickTarget', 'icon', 'description'],
    data
  )

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const monitoringFeature = prop('planType', data) as readonly string[]

  const isMobile = !useMediaQuery('TabletAndUp')

  const { trackEvent } = useTracking()

  return (
    <ComparisonTableRow
      clickTarget={
        !isNil(comparisonTableRowModal) ? (
          renderTarget(
            rowLabel,
            infoButtonText,
            comparisonTableRowModal,
            isMobile,
            trackEvent
          )
        ) : (
          <p className="rowLabel">{rowLabel}</p>
        )
      }
      isProPremiumFeature={isProPremiumFeature}
      isStandardFeature={isStandardFeature}
      monitoringFeature={monitoringFeature}
      rowLabel={rowLabel}
    />
  )
}

export const ComparisonTableRowQuery = graphql`
  #graphql
  fragment comparisonTableRowFragment on ContentfulPlanComparisonTableRow {
    id
    internal {
      type
    }
    planType
    isProPremiumFeature
    isStandardFeature
    rowModal {
      ... on ContentfulModal {
        ...modalFragment
      }
      clickTarget {
        ... on ContentfulButton {
          icon {
            description
          }
        }
      }
    }
    rowLabel
  }
`
