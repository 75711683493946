import { PackageComponents } from '@simplisafe/ss-ecomm-data/leads/quoteWizard/recommendations'

export enum QueryParams {
  homeSize = 'hm',
  floors = 'fl',
  doors = 'dr',
  bedrooms = 'bd',
  hazards = 'hz'
}

export enum HazardCodes {
  videoDoorbellPro = 'vd',
  smokeDetector = 'sm',
  panicButton = 'pnc',
  freezeSensor = 'tmp',
  simplicam = 'sc',
  waterSensor = 'ws',
  outdoorCamera = 'oc'
}

/**
 * Converts jebbit query params into a package components
 * Handles both encoded and unencoded params
 * @param searchParams string that contains query params, it can be url encoded
 * @returns
 *
 */
export const convertGuidedSystemBuilderParamsToPackageComponents = (
  searchParams: string
): PackageComponents => {
  const params = new URLSearchParams(searchParams)
  const homeSize = parseInt(params.get(QueryParams.homeSize) || '1') || 1
  const doors = parseInt(params.get(QueryParams.doors) || '1') || 1
  const hazards =
    params
      .get(QueryParams.hazards)
      ?.replace(/\+/g, '')
      .split(',')
      .map(x => x.trim()) || []

  return {
    auxiliarySiren: homeSize >= 4 ? 1 : 0,
    entrySensor: doors,
    freezeSensor: hazards.includes(HazardCodes.freezeSensor) ? 1 : 0,
    keypad: homeSize >= 4 ? 2 : 1,
    motionSensor: homeSize > 1 ? 2 : 1,
    outdoorCamera: hazards.includes(HazardCodes.outdoorCamera) ? 1 : 0,
    panicButton: hazards.includes(HazardCodes.panicButton) ? 1 : 0,
    simplicam: hazards.includes(HazardCodes.simplicam) ? 1 : 0,
    smokeDetector: hazards.includes(HazardCodes.smokeDetector) ? 1 : 0,
    videoDoorbellPro: hazards.includes(HazardCodes.videoDoorbellPro) ? 1 : 0,
    waterSensor: hazards.includes(HazardCodes.waterSensor) ? 1 : 0
  }
}
