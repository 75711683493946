import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

const useTrackingBmsNextSensorGroup = (sensorGroup: string) => {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(() => {
    trackEvent({
      action: 'bms',
      category: 'next-add-ons',
      event: 'buttonClick',
      label: sensorGroup.replace(/ /g, '-').toLowerCase()
    })
  }, [trackEvent, sensorGroup])
}

export default useTrackingBmsNextSensorGroup
