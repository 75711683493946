import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { getPurchasedCartId, TrackingData } from '@lib/tracking'
import { useLocation } from '@reach/router'
import { safeProp } from '@simplisafe/monda'
import {
  commercetoolsGetCart,
  CTCartToRecord,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { selectCurrencyCode } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { getLiveChatScriptUrl } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { SSButton } from '@simplisafe/ss-react-components'
import { fork } from 'fluture'
import { Link } from 'gatsby'
import { Just, Maybe } from 'monet'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulPaymentConfirmationContainer } from '../../../graphql'
import useLiveChatClientExists from '../../hooks/useLiveChatClientExists'
import useScript from '../../hooks/useScript'
import type { TrackEvent as TrackEventType } from '../../util/analytics'
import liveChatOrderTracking, {
  OrderTracking
} from '../../util/analytics/liveChatOrderTracking'
import { getCartDiscountCode } from '../CartDetailsComponent/transformLineItem'
import ContentfulRichText from '../ContentfulRichText'

type PaymentConfirmationContainerComponentProps = {
  readonly id?: string
  readonly data: Partial<ContentfulPaymentConfirmationContainer>
}

export type TrackEvent = (
  data: Partial<Record<string, unknown> | TrackingData>
) => void

/**
 * TODO: This piece of tracking code is specifically for ECP-3110, where the UK just wants to
 * double check perceived discrepencies in GA orders
 *
 * I expect that once any discrepencies are found or identified, this piece of code can
 * be removed as it adds no further value.
 */
const trackSystemPurchase = (trackEvent: TrackEventType) =>
  trackEvent({ event: 'purchaseSystemConfirmation' })

export const toOrderTrackingObject = (
  orderId: string,
  cartTotal: Maybe<number>,
  currency: CurrencyCode,
  lineItems: readonly LineItem[]
): OrderTracking => {
  return {
    cartTotal: cartTotal,
    currency: currency,
    lineItems: lineItems,
    orderId: orderId
  }
}

const QS_PARAM_ORDER_ID = 'orderId'

export default function PaymentConfirmationContainerComponent({
  data
}: PaymentConfirmationContainerComponentProps) {
  const currencyCode = useSelector(selectCurrencyCode)

  const [orderTracking, setOrderTracking] = useState<OrderTracking>({
    cartTotal: Just(0),
    currency: currencyCode,
    lineItems: [],
    orderId: ''
  })
  const { Track, trackEvent } = useTracking()
  const location = useLocation()
  const orderId =
    new URLSearchParams(location.search).get(QS_PARAM_ORDER_ID) || ''

  useEffect(() => {
    !orderId && logError(Error('Missing orderId on payment confirmation page'))
  }, [orderId])

  // Normally, we'd want to get cartId from redux instead of directly from local storage. This component is an
  // exception because redux clears the cart out of state and removes cartId from local storage once the cart
  // is ordered, which it is by the time the user gets to this page. Here, we're grabbing the cart id out of
  // local storage before redux has a chance to clear it, and then making a separate cart request (but not
  // storing it) so that we can track the purchased line items.

  const cartId = getPurchasedCartId()

  useEffect(() => {
    Maybe.fromNull(cartId).forEach(_cartId => {
      // TODO turn this into a specialized function in ecomm-data instead of doing fetching & parsing here
      commercetoolsGetCart(_cartId).pipe(
        fork((e: Error) => {
          logError(e)
        })(ctCart => {
          ctCart
            .filter(ctCart => ctCart.cartState === 'Ordered')
            .forEach(ctCart => {
              const cart = CTCartToRecord(ctCart)

              const shippingAddress = safeProp('shippingAddress', ctCart)
              const email = shippingAddress.chain(safeProp('email'))
              const firstName = shippingAddress.chain(safeProp('firstName'))
              const lastName = shippingAddress.chain(safeProp('lastName'))

              const transactionTotal = safeProp('totalPrice', cart)

              const systemInOrder = cart.isThereAnySecurity

              systemInOrder && trackSystemPurchase(trackEvent)

              setOrderTracking(
                toOrderTrackingObject(
                  orderId,
                  transactionTotal,
                  currencyCode,
                  cart.lineItems
                )
              )

              trackEvent({
                // TODO add 'paymentConfirmation' to TrackingEvent type to remove this ts-ignore
                // @ts-expect-error TS(2322) FIXME: Type '"paymentConfirmation"' is not assignable to ... Remove this comment to see the full error message
                event: 'paymentConfirmation',
                transactionCoupon: getCartDiscountCode(cart),
                transactionId: orderId,
                transactionTotal: transactionTotal.getOrElse(0),
                userData: {
                  email: email.getOrElse(''),
                  firstName: firstName.getOrElse(''),
                  lastName: lastName.getOrElse('')
                }
              })
            })
        })
      )
    })
  }, [cartId, orderId, trackEvent, currencyCode])

  const status = useScript(
    getLiveChatScriptUrl('610bd23d07ea1e23ca931dd8_app_979960')
  )
  const clientExists = useLiveChatClientExists(status)

  useEffect(() => {
    clientExists && liveChatOrderTracking(orderTracking)
  }, [clientExists, orderTracking])

  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: () => <span>{orderId}</span>,
      [BLOCKS.PARAGRAPH]: (_: unknown, children: ReactNode) => (
        <p className="paragraph text-center whitespace-pre-line">{children}</p>
      ),
      [BLOCKS.HEADING_3]: (_: unknown, text: ReactNode) => (
        <h3 className="h3 text-center">{text}</h3>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target && (
          <div className="flex justify-center">
            <Link to={data.target?.url}>
              <SSButton color="primary">{data.target?.buttonText}</SSButton>
            </Link>
          </div>
        )
    }
  }

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <ContentfulRichText
        optionsCustom={options}
        raw={data.successMessage?.description?.raw}
        // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulAssetContentfulButtonCont... Remove this comment to see the full error message
        references={data.successMessage?.description?.references}
      />
    </Track>
  )
}
