import {
  SimpliSafeCSSProperties,
  usePromoBannerData,
  usePromoOverrideData
} from '@lib/components'
import { PromoStyler } from '@lib/components'
import {
  selectActivePromoLoading,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { Column } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import React from 'react'
import { useSelector } from 'react-redux'

import { stickerSpans } from './helpers'

function PromoStickerComponent() {
  const { discountText } = usePromoBannerData()
  const {
    heroStickerBackgroundColor,
    heroStickerDisclaimerText,
    heroStickerFreeGiftLineItemText,
    heroStickerImage, // For a potential A/B test showing promo product image inside the sticker.
    heroStickerSaleName,
    heroStickerTextColor
  } = usePromoOverrideData()
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const isDesktopAndUp = useMediaQuery('DesktopAndUp')
  const hasContent =
    !!heroStickerDisclaimerText ||
    !!heroStickerFreeGiftLineItemText ||
    !!heroStickerSaleName
  const hasImage = !!heroStickerImage
  const showSticker = hasContent && isTabletAndUp && !!discountText
  const saleNameStyles: SimpliSafeCSSProperties = {
    '--h3-size': isDesktopAndUp ? '2.25rem' : '1.5rem',
    '--lg-margin': '.25rem'
  }
  const discountFontSize = hasImage
    ? isDesktopAndUp
      ? '4rem'
      : '3rem'
    : isDesktopAndUp
    ? '160px'
    : '92px'
  const discountLetterSpacing = hasImage
    ? '-1.34px'
    : isDesktopAndUp
    ? '-2.95px'
    : '1.34px'
  const discountStyles = {
    fontSize: discountFontSize,
    letterSpacing: discountLetterSpacing,
    lineHeight: isDesktopAndUp && !hasImage ? '130px' : '80px'
  }
  const freeGiftLineItemStyles: SimpliSafeCSSProperties = {
    '--h3-size': isDesktopAndUp ? '2.25rem' : '1.5rem',
    '--lg-margin': '.75rem'
  }
  const disclaimerStyles: SimpliSafeCSSProperties = { '--sm-margin': 0 }

  const styles: SimpliSafeCSSProperties = {
    '--promo-banner-bg': heroStickerBackgroundColor,
    '--promo-primary-text': heroStickerTextColor,
    '--prose-body-color': heroStickerTextColor,
    '--prose-headings-color': heroStickerTextColor,
    '--prose-links-color': heroStickerTextColor,
    color: heroStickerTextColor
  }

  return showSticker ? (
    <Column dataComponent="PromoSticker" rounded="none" spans={stickerSpans}>
      <PromoStyler>
        <div
          className="rc-bg-promo-banner-bg rc-text-promo-primary-text rc-prose rc-rounded-base px-3 pt-8 pb-4 lg:px-6 lg:py-8 text-center"
          data-component="HeroSticker"
          style={styles}
        >
          {hasImage ? (
            <div
              className="m-auto mb-2 text-center w-36"
              data-component="PromoStickerImage"
            >
              <img
                alt="image of promo product"
                className="max-w-full h-auto"
                src={heroStickerImage}
              />
            </div>
          ) : null}
          {heroStickerSaleName ? (
            <h3 data-component="PromoStickerSaleName" style={saleNameStyles}>
              {heroStickerSaleName}
            </h3>
          ) : null}
          <h2
            className="m-0"
            data-component="PromoStickerDiscount"
            style={discountStyles}
          >{`${discountText} OFF`}</h2>
          {heroStickerFreeGiftLineItemText ? (
            <h3
              className="rc-font-bold"
              data-component="PromoStickerFreeGiftLineItem"
              style={freeGiftLineItemStyles}
            >
              {heroStickerFreeGiftLineItemText}
            </h3>
          ) : null}
          {heroStickerDisclaimerText ? (
            <p
              className="rc-text-sm"
              data-component="PromoStickerDisclaimerText"
              style={disclaimerStyles}
            >
              {heroStickerDisclaimerText}
            </p>
          ) : null}
        </div>
      </PromoStyler>
    </Column>
  ) : null
}

function PromoSticker() {
  const isActivePromoLoading = useSelector(selectActivePromoLoading)
  const isTopBannerVisible = useSelector(selectTopBannerVisible)
  const hasBanner = !isActivePromoLoading && isTopBannerVisible
  return hasBanner ? <PromoStickerComponent /> : null
}

export default PromoSticker
