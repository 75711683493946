import { GatsbyImage } from '@lib/components'
import { useLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import { safeHead, safeProp } from '@simplisafe/monda'
import { Header, HeaderNavigation } from '@simplisafe/ss-react-components'
import { LabeledProgressbar } from '@simplisafe/ss-react-components'
import { LabeledProgressbarProps } from '@simplisafe/ss-react-components/LabeledProgressbar'
import { graphql, Link } from 'gatsby'
import { Maybe } from 'monet'
import isNil from 'ramda/src/isNil'
import React, { useMemo } from 'react'

import { ContentfulHeaderWithProgressBar } from '../../../graphql'
import { logoLinkUrl as headerLogoLinkUrl } from '../Header/helpers'

type HeaderWithProgressBarProps = {
  readonly data: Partial<ContentfulHeaderWithProgressBar>
}

const progressByPath = {
  '^build-my-system$': 10,
  '^cart$': 10,
  '^cart/checkout$': 50,
  '^change-monitoring$': 53,
  '^choose-monitoring$': 53,
  '^choose-monitoring2$': 53,
  '^dynamic-package$': 10,
  '^home-security-system-': 10,
  '^payment-confirmation$': 100,
  '^payment-page$': 75,
  '^product/system/': 10
}

function HeaderProgressBarComponent({ data }: HeaderWithProgressBarProps) {
  const logoLinkUrl: string | undefined = path(
    ['simpliSafeLogo', 'linkUrl'],
    data
  )

  const steps: LabeledProgressbarProps['stepList'] = safeProp('steps', data)
    .getOrElse([])
    .filter((s): s is string => !!s)

  const pageLocation = useLocation()

  const getProgress = () => {
    const path = pageLocation.pathname
    const cleanPath = path.replace(/^\/|\/$/g, '')

    return safeHead(
      Object.entries(progressByPath)
        .filter(([pathFragment, _percentage]) =>
          Maybe.fromNull(cleanPath.match(pathFragment)).isSome()
        )
        .map(([_pathFragment, percentage]) => percentage)
    ).getOrElse(0)
  }

  const progress = useMemo(getProgress, [pageLocation])

  const image = path(['simpliSafeLogo', 'linkImage'], data)

  const logoImage = image ? (
    <GatsbyImage
      // @ts-expect-error TS(2322) FIXME: Type '{ alt: string; image: ContentfulAsset; imgSt... Remove this comment to see the full error message
      alt="SimpliSafe Logo"
      image={image}
      imgStyle={{
        objectFit: 'contain',
        objectPosition: 'left'
      }}
      loading="eager"
      style={{
        height: '100%',
        width: '100%'
      }}
    />
  ) : (
    <div></div>
  )

  return (
    <Header hasProgressBar={true}>
      <HeaderNavigation>
        {!isNil(logoLinkUrl) ? (
          <Link to={headerLogoLinkUrl(logoLinkUrl)}>{logoImage}</Link>
        ) : (
          logoImage
        )}
      </HeaderNavigation>
      <HeaderNavigation>
        <LabeledProgressbar percent={progress} stepList={steps} />
      </HeaderNavigation>
    </Header>
  )
}

export default HeaderProgressBarComponent

export const HeaderProgressBarQuery = graphql`
  #graphql
  fragment headerProgressBarFragment on ContentfulHeaderWithProgressBar {
    __typename
    id
    internal {
      type
    }
    simpliSafeLogo {
      id
      linkImage {
        description
        id
        gatsbyImageData(layout: FIXED, height: 36, placeholder: BLURRED)
      }
      linkUrl
    }
    steps
  }
`
