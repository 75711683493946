/**
 * Logs a value then returns it.
 * Useful for adding to a pipe or map function.
 */
function log<T>(x: T): T {
  console.log(x)
  return x
}

export default log
