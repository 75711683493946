import {
  Block,
  BLOCKS,
  Document,
  Inline,
  INLINES,
  TopLevelBlock,
  TopLevelBlockEnum
} from '@contentful/rich-text-types'
import { IfEquals } from '@lib/utils'
import { DeepNullable, DeepPartial } from 'ts-essentials'
import { z } from 'zod'

const nodeSchema = z.object({
  data: z.record(z.string(), z.any()),
  nodeType: z.string()
})

const markSchema = z.object({ type: z.string() })

const textSchema = nodeSchema.extend({
  marks: z.array(markSchema),
  nodeType: z.literal('text'),
  value: z.string()
})

const inlinesEnum = z.nativeEnum(INLINES)

const inlineSchema: z.ZodType<Inline> = z.lazy(() =>
  nodeSchema.extend({
    content: z.array(z.union([textSchema, inlineSchema])),
    nodeType: inlinesEnum
  })
)

const blocksEnum = z.nativeEnum(BLOCKS)

const blockSchema: z.ZodType<Block> = z.lazy(() =>
  nodeSchema.extend({
    content: z.array(z.union([blockSchema, inlineSchema, textSchema])),
    nodeType: blocksEnum
  })
)

const topLevelBlockEnum: z.ZodType<TopLevelBlockEnum> = z.enum([
  BLOCKS.PARAGRAPH,
  BLOCKS.HEADING_1,
  BLOCKS.HEADING_2,
  BLOCKS.HEADING_3,
  BLOCKS.HEADING_4,
  BLOCKS.HEADING_5,
  BLOCKS.HEADING_6,
  BLOCKS.OL_LIST,
  BLOCKS.UL_LIST,
  BLOCKS.HR,
  BLOCKS.QUOTE,
  BLOCKS.EMBEDDED_ENTRY,
  BLOCKS.EMBEDDED_ASSET
])

// this was supposed to extend blockSchema but it introduces type ambiguity since blockSchema is lazy
const topLevelBlockSchema: z.ZodType<TopLevelBlock> = nodeSchema.extend({
  content: z.array(z.union([blockSchema, inlineSchema, textSchema])),
  nodeType: topLevelBlockEnum
})

/**
 * @deprecated - Gatsby 4
 */
export const documentSchema: z.ZodType<Document> = nodeSchema.extend({
  content: z.array(topLevelBlockSchema),
  nodeType: z.literal(BLOCKS.DOCUMENT)
})

/**
 * @deprecated - Gatsby 4
 */
export type RichTextFragment = IfEquals<
  z.TypeOf<typeof documentSchema>,
  Document
>

/**
 * @deprecated - Gatsby 4
 */
export const parseRichText = (
  richText: DeepNullable<DeepPartial<Document>>
): RichTextFragment => documentSchema.parse(richText)
