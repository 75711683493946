import {
  createInstance,
  OptimizelyProvider as DefaultOptimizelyProvider,
  setLogLevel
} from '@lib/optimizely'
import { noValue } from '@lib/utils'
import { exists, window } from 'browser-monads-ts'
import { get } from 'local-storage'
import React, { ReactNode, useMemo, useRef } from 'react'

import { updateFirstSeenUrl, userAttributes } from '.'
import getVisitorId from './getVisitorId'

type OptimizelyProviderProps = {
  readonly children: ReactNode
  readonly datafile: Record<string, string>
}

// TODO: this component needs unit tests

/**
 * A wrapper around the default OptimizelyProvider that handles behavior like
 * waiting for the AT-AT cookie to be set before initializing.
 */
export function OptimizelyProvider({
  children,
  datafile
}: OptimizelyProviderProps) {
  const isSSR = !exists(window)

  /** Allows a user to bucket themselves into an Optimizely group for feature testing. */
  const feature_flag = get('feature_flag')

  // https://github.com/optimizely/react-sdk#load-the-datafile-synchronously
  // "When initializing with both the SDK key and datafile, the SDK will use the given datafile to start,
  // then download the latest version of the datafile in the background."
  // Wrapped in a useRef so we don't re-initialize an Optimizely client on every page transition.
  const optimizely = useRef(
    createInstance({
      datafile,
      eventBatchSize: 10,
      eventFlushInterval: 1000,
      // This SSR check prevents a bunch of Optimizely console spam at build time by not attempting to re-fetch updated datafiles for each page build
      sdkKey: isSSR ? noValue() : process.env['OPTIMIZELY_FS_SDK_KEY']
    })
  )

  setLogLevel(process.env['GATSBY_OPTIMIZELY_LOG_LEVEL'] || 'error')

  const userInfo = useMemo(() => {
    const id = isSSR ? noValue() : getVisitorId()
    id && updateFirstSeenUrl(id)
    return id
      ? {
          attributes: {
            ...userAttributes(),
            feature_flag
          },
          id
        }
      : noValue()
  }, [])

  return (
    <DefaultOptimizelyProvider optimizely={optimizely.current} user={userInfo}>
      {children}
    </DefaultOptimizelyProvider>
  )
}
