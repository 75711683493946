import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image'
import React from 'react'

import { GatsbyImageSchema } from './gatsbyImageSchema'

type Props = Omit<GatsbyImageProps, 'alt' | 'image'> & {
  readonly image: GatsbyImageSchema
}

function InternalGatsbyImage({ image, ...rest }: Props) {
  const imageProp = getImage(image)

  return imageProp ? (
    <GatsbyImage
      {...rest}
      alt={image?.description || image?.title}
      image={imageProp}
    />
  ) : null
}

export default InternalGatsbyImage
