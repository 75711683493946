import type { Block, Inline, Node } from '@contentful/rich-text-types'
import { LinkComponent, YoutubeVideo } from '@lib/components'
import { returnNextBusinessDay } from '@lib/utils'
import path from '@simplisafe/ewok/ramda/path'
import isNilOrEmpty from '@simplisafe/ewok/ramda-adjunct/isNilOrEmpty'
import { safeFind, safeProp } from '@simplisafe/monda'
import {
  selectMonthsOfServiceDisplay,
  selectMonitoringDiscountText
} from '@simplisafe/ss-ecomm-data/redux/select'
import { SSButton } from '@simplisafe/ss-react-components'
import { always, cond } from 'ramda'
import equals from 'ramda/src/equals'
import React, { cloneElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { getMappedComponent } from '../../componentMappings'
import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'
import ButtonComponent from '../ButtonComponent'
import IconWithTextComponent from '../IconWithTextComponent'
import ModalComponent from '../ModalComponent'
import ImageButton from './ImageButton'

export function getContentType(obj: Block | Inline): string {
  //Not sure about if internal still exists, so i just added another path to get the type for now
  return (
    path(['data', 'target', 'internal', 'type'], obj) ||
    obj?.data?.target?.__typename ||
    ''
  )
}

export function getFieldType(obj: Block | Inline): string {
  return path(['data', 'target', 'type'], obj) || ''
}

// Can be used when we need to display a number of free months only (example: "free month", "2 free months", "3 free months")
export function MonthOfFreeService() {
  const monthsOfFreeService = useSelector(
    selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer())
  )
  return <>{monthsOfFreeService}</>
}

export const renderHomeMoversDownloadButton = (node: Node) => {
  const data = node?.data?.target

  const linkText = data?.linkItem?.buttonText || ''
  const linkUrl = data?.downloadedItem?.file?.url || ''

  return (
    <SSButton href={linkUrl} minWidth="full" target="_blank" type="link">
      {linkText}
    </SSButton>
  )
}

// Can be used in a Service plan description text (example: "2 months are", "month is")
export function MonthOfServiceText() {
  const monthsOfService = useSelector(
    selectMonthsOfServiceDisplay(false, isPartnerUpgradePromoOffer())
  )
  return <>{monthsOfService}</>
}

type DiscountPercentageProps = {
  readonly placeholders?: Record<string, string | null>
}

function DiscountPercentage(props: DiscountPercentageProps) {
  const discountPercentage = useSelector(
    selectMonitoringDiscountText
  ).orUndefined()
  const placeholderText = path(['placeholders', 'Discount Percentage'], props)

  return isNilOrEmpty(placeholderText) ? (
    <>{discountPercentage}</>
  ) : (
    <>{placeholderText}</>
  )
}

type DiscountValueProps = {
  readonly placeholders?: Record<string, string | null>
}

function DiscountValue(props: DiscountValueProps) {
  const placeholderText = path(['placeholders', 'Discount Value'], props)

  return isNilOrEmpty(placeholderText) ? null : <>{placeholderText}</>
}

// Map which content needs to render based on placeholder type
const placeholderTypeRenderMaps: readonly {
  readonly placeholder: string
  readonly renderComponent: JSX.Element
}[] = [
  {
    placeholder: 'Months of Free Service Description',
    renderComponent: <MonthOfServiceText />
  },
  {
    placeholder: 'Months of Free Service',
    renderComponent: <MonthOfFreeService />
  },
  {
    placeholder: 'Discount Percentage',
    renderComponent: <DiscountPercentage />
  },
  {
    placeholder: 'Discount Value',
    renderComponent: <DiscountValue />
  },
  {
    placeholder: 'Shipping Estimate Date',
    renderComponent: <span>{returnNextBusinessDay()}</span>
  }
]

const renderPlaceholderContent = (
  node: Block | Inline,
  placeholders?: Record<string, string | null>
) => {
  const fieldType = getFieldType(node)

  return safeFind(
    entry => entry.placeholder === fieldType,
    placeholderTypeRenderMaps
  )
    .chain(safeProp('renderComponent'))
    .map(renderComponent => cloneElement(renderComponent, { placeholders }))
    .orNull()
}

export const renderEmbeddedBlockComponent = (node: Block | Inline) => {
  const data =
    path(['data', 'target', 'fields'], node) || path(['data', 'target'], node)
  const EmbeddedComponent = getMappedComponent(path(['data', 'target'], node))

  // Used on the /manuals page. DO NOT extend this branching logic.
  const isLinkDownload =
    node?.data?.target?.internal?.type === 'ContentfulLink' &&
    node?.data?.target?.linkType === 'Download'

  return isLinkDownload ? (
    <ImageButton node={node} />
  ) : EmbeddedComponent ? (
    <EmbeddedComponent data={data} />
  ) : null
}

/**
 * Renders embedded entry in ContentfulRichText
 */
export const renderEmbeddedEntry = (
  node: Block | Inline,
  placeholders?: Record<string, string | null>
) => {
  const contentType = getContentType(node)

  return cond([
    [
      equals('ContentfulPlaceholder'),
      always(renderPlaceholderContent(node, placeholders))
    ],
    [
      equals('ContentfulSectionId'),
      always(<p id={node?.data?.target?.referenceId} />)
    ],
    [
      equals('ContentfulLink'),
      always(<LinkComponent data={node?.data?.target} />)
    ],
    [
      equals('ContentfulIconWithText'),
      always(<IconWithTextComponent data={node?.data?.target} />)
    ],
    [
      equals('ContentfulModal'),
      always(<ModalComponent data={node?.data?.target} />)
    ],
    [
      equals('ContentfulButton'),
      always(<ButtonComponent data={node?.data?.target} />)
    ]
  ])(contentType)
}

export const renderModalVideoHyperlink = (
  title: string,
  uri: string,
  clickTarget: ReactNode
): ReactNode => (
  <ModalComponent
    clickTarget={clickTarget}
    modalContent={
      <>
        <YoutubeVideo link={uri} title={title} />
      </>
    }
  />
)
