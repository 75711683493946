import { toModalSize } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import { Modal } from '@simplisafe/ss-react-components'
import { ModalStyle } from '@simplisafe/ss-react-components/Modal'
import { graphql } from 'gatsby'
import { Maybe } from 'monet/dist/monet'
import React, { ReactNode, useState } from 'react'

import {
  ContentfulButtonContentfulSmallTextSectionUnion,
  ContentfulModal
} from '../../../graphql'
import {
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import { mapToPartnerClaimNowClickTargetComponent } from '../PartnerClaimNowClickTargetComponent'
import QuoteWizardWrapper from '../QuoteWizardWrapper'

export type ModalComponentProps = {
  /* Click Target and Model Content data from Contentful, Click target field is optional in Contentful */
  // Once other components using ModalComponent are changed to not use Partial<> types anymore,
  // this should be changed to just ModalFragmentFragment
  readonly data?: Omit<ContentfulModal, 'clickTarget' | 'id' | 'internal'> & {
    readonly internal?: ContentfulModal['internal']
    readonly id?: ContentfulModal['id']
    readonly clickTarget?:
      | ContentfulButtonContentfulSmallTextSectionUnion
      | ContentfulModal['clickTarget']
  }
  /* Click target component can be passed in here, instead of setting it in Contentful */
  readonly clickTarget?: ReactNode
  /** Allows default styles on the clickTarget to be overridden. */
  readonly clickTargetStyles?: React.CSSProperties
  readonly dataComponent?: string
  /* Modal content component can be passed in here, instead of setting it in Contentful */
  readonly modalContent?: ReactNode
  /** custom style for modal */
  readonly style?: ModalStyle
  /* External click to be called when the button is clicked */
  readonly onClick?: () => void
  /* Size of modal */
  readonly size?: 'large' | 'medium' | 'small'
}

const externalClick = (onClick?: () => void) => {
  onClick && onClick()
}

const isRichTextWithButtons = <T extends ContentfulComponent>(data: T) =>
  !!data && path(['internal', 'type'], data) === 'ContentfulRichTextWithButtons'

type ClickTargetComponentProps = {
  readonly data:
    | ContentfulButtonContentfulSmallTextSectionUnion
    | ContentfulModal['clickTarget']
  readonly disabled: boolean
}

type ContentComponentProps = {
  readonly data: ContentfulModal['modalContent']
  readonly closeModal: () => void
  readonly onUrlFragmentClick?: () => void
  readonly columnType?: string
  readonly onSuccess?: () => void
}

export default function ModalComponent({
  data: dataProp,
  clickTarget,
  clickTargetStyles,
  dataComponent = ModalComponent.name,
  modalContent: modalContentProp,
  style,
  onClick,
  size
}: ModalComponentProps) {
  const data = dataProp || {}
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const clickTargetContent = prop('clickTarget', data)
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const modalContent = prop('modalContent', data)
  const [isOpen, setIsOpen] = useState(false)
  const ClickTargetComponent =
    clickTargetContent &&
    (mapToPartnerClaimNowClickTargetComponent(clickTargetContent) ||
      getMappedComponent<ClickTargetComponentProps>(clickTargetContent))
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const disableClickTargetOnSuccess = prop('disableClickTargetOnSuccess', data)
  const [clickTargetDisabled, setClickTargetDisabled] = useState<boolean>(false)

  const ContentComponent =
    modalContent && getMappedComponent<ContentComponentProps>(modalContent)
  const clickTargetIsSet = !!(ClickTargetComponent || clickTarget)
  const modalContentIsSet = !!(ContentComponent || modalContentProp)
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const modalSize = size
    ? toModalSize(size)
    : toModalSize(prop('modalSize', data))
  const closeModal = () => setIsOpen(false)

  const handleSuccess = () => {
    disableClickTargetOnSuccess && setClickTargetDisabled(true)
  }
  function renderModalContent() {
    return ContentComponent
      ? safePath(['modalContent', 'variations'], data).cata(
          // @ts-expect-error TS(2604): JSX element type 'ContentComponent' does not have ... Remove this comment to see the full error message
          () => (
            <ContentComponent
              closeModal={closeModal}
              columnType="none"
              data={modalContent}
              // Prop is specific to RichTextWithButtonsComponent
              onSuccess={handleSuccess}
              onUrlFragmentClick={Maybe.fromNull(modalContent)
                .filter(isRichTextWithButtons)
                .map(() => closeModal)
                .orUndefined()}
            />
          ),
          //@ts-expect-error
          _ => (
            <ContentfulVariationContainerComponent
              data={data.modalContent}
              renderVariant={variant => (
                <QuoteWizardWrapper
                  columnType="none"
                  data={variant}
                  type="floating"
                ></QuoteWizardWrapper>
              )}
            ></ContentfulVariationContainerComponent>
          )
        )
      : null
  }

  return clickTargetIsSet && modalContentIsSet ? (
    //TODO apply click event to ClickTargetComponent
    <>
      <span
        className="clickTarget"
        data-component="click-target"
        onClick={e => {
          e.preventDefault()
          externalClick(onClick)
          !clickTargetDisabled && setIsOpen(true)
        }}
        style={{
          alignItems: 'center',
          display: 'inline-flex',
          ...clickTargetStyles
        }}
      >
        {/* @ts-expect-error TS(2604) FIXME: JSX element type 'ClickTargetComponent' does not h... Remove this comment to see the full error message */}
        {clickTarget ? (
          clickTarget
        ) : (
          <ClickTargetComponent
            className="px-0"
            data={clickTargetContent}
            disabled={clickTargetDisabled}
          />
        )}
      </span>
      <Modal
        appElementId="___gatsby"
        dataComponent={dataComponent}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={modalSize}
        style={style}
      >
        {modalContentProp ? modalContentProp : renderModalContent()}
      </Modal>
    </>
  ) : null
}

export const query = graphql`
  #graphql
  fragment modalFragment on ContentfulModal {
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulSmallTextSection {
        ...nonCyclicalSmallTextSectionFragment
      }
    }
    id
    internal {
      type
    }
    modalContent {
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulModalSensorAdditionToSystem {
        ...addExtraSensors
      }
      ... on ContentfulRichTextWithButtons {
        ...contentfulRichTextWithButtonsFragment
      }
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
      ... on ContentfulPartnerCaptureForm {
        ...contentfulPartnerCaptureForm
      }
      ... on ContentfulSmallTextSection {
        ...nonCyclicalSmallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulBanner {
        ...contentfulBanner
      }
      ... on ContentfulPdpPackageSensorEdit {
        ...contentfulPackageSensorEditFragment
      }
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
      ... on ContentfulVariationContainer {
        id
        internal {
          type
        }
        experimentId
        experimentKey
        experimentTitle
        meta {
          internal {
            content
          }
        }
        variations {
          ... on ContentfulFindYourPerfectSystem {
            contentful_id
            ...quoteWizard
          }
        }
      }
    }
    modalSize
    disableClickTargetOnSuccess
  }

  # this fragment has been made to support a scenario where adding modalFragment to
  # contentfulTwoColumnFragment resulted in a recursive graphql error since
  # modalFragment has twoColumnBanner as potential content in contentful
  fragment secondaryModalFragment on ContentfulModal {
    contentful_id
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    id
    internal {
      type
    }
    modalContent {
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
      ... on ContentfulSmallTextSection {
        ...nonCyclicalSmallTextSectionFragment
      }
      ... on ContentfulVariationContainer {
        id
        internal {
          type
        }
        experimentId
        experimentKey
        experimentTitle
        meta {
          internal {
            content
          }
        }
        variations {
          ... on ContentfulFindYourPerfectSystem {
            contentful_id
            ...quoteWizard
          }
        }
      }
    }
    modalSize
  }
`
