import { useLocation } from '@reach/router'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart'
import {
  selectPackage,
  selectProduct
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  selectCustomSystemDiscountedPrice,
  selectCustomSystemTotalPrice,
  selectMiniCart,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import {
  BannerError,
  BannerLoading,
  Heading,
  IncludedItem,
  IncludedItemProduct,
  Price,
  SSButton,
  Text
} from '@simplisafe/ss-react-components'
import { Caution } from '@simplisafe/ss-react-components/icons'
import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Either, List, Maybe, None } from 'monet'
import { add, equals, propEq } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulQuizRecommendedSystem } from '../../../graphql'
import {
  componentsNotInStock,
  CoreComponentsData,
  renderOutOfStockMessage,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice } from '../../commercetools/price'
import AddToCartError from '../../errorComponents/AddToCartError'
import useDynamicPackageAddToCart from '../../hooks/useDynamicPackageAddToCart'
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics'
import { useGuidedSystemBuilderCaptureLeadAndTrack } from '../../hooks/useGuidedSystemBuilderCaptureLeadAndTrack'
import { useGuidedSystemBuilderTrackRecommendation } from '../../hooks/useGuidedSystemBuilderTrackRecommendation'
import { useNoDiscountPartner } from '../../hooks/useNoDiscountPartner'
import { usePollVid } from '../../hooks/usePollVid'
import DynamicPackageMiniCart from '../DynamicPackageMiniCart'
import { handleLinkClick } from '../Header/helpers'
import { withPlanKey } from '../ItemContainerComponent/OldVersion'
import { appendParamsToGSBLaunchUrl } from '../QuoteWizardWrapper/lib'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import SimpleBannerComponent from '../SimpleBannerComponent'
import QuizRecommendationExplanation from './QuizRecommendationExplanation'

type QuizRecommendedSystemProps = {
  readonly data: ContentfulQuizRecommendedSystem
}

export type IncludedItem = {
  readonly displayName: string
  readonly quantity: number
  readonly displayNamePlural?: string
  readonly modalContent?: JSX.Element
  readonly sku: Maybe<string>
  readonly outOfStockMessage?: JSX.Element
}

const getIncludedItems = (
  possibleComponents: ContentfulQuizRecommendedSystem['possibleSystemComponents'],
  possibleModals: ContentfulQuizRecommendedSystem['possibleIncludedItemModals'],
  lineItems: List<MiniCartLineItem>,
  coreComponentsNotInStock: readonly CoreComponentsData[]
): readonly IncludedItem[] =>
  possibleComponents
    ?.filter(isNotNil)
    .map(component => {
      const modalContent =
        possibleModals?.includedItemModals?.find(
          item => item?.systemComponent?.sku === component.sku
        )?.modalContent || undefined

      return lineItems
        .find(propEq('masterSku', component.sku))
        .filter(item => item.quantity > 0)
        .chain(item => {
          const outOfStockMessage =
            renderOutOfStockMessage({
              coreComponentsNotInStock,
              product: Either.Right(item)
            }) || undefined
          return Maybe.fromUndefined(
            component.displayName
              ? {
                  displayName: component.displayName,
                  displayNamePlural: component.displayNamePlural || undefined,
                  modalContent: modalContent && (
                    <SimpleBannerComponent
                      data={modalContent}
                      id={modalContent.id}
                    />
                  ),
                  outOfStockMessage: outOfStockMessage && (
                    <>
                      <Caution />
                      <span>{outOfStockMessage}</span>
                    </>
                  ),
                  quantity: item.quantity,
                  sku: Maybe.fromEmpty(component.sku)
                }
              : undefined
          )
        })
    })
    .filter(x => x.isSome())
    .map(x => x.just()) || []

export default function QuizRecommendedSystem({
  data
}: QuizRecommendedSystemProps) {
  const select = useSelector((state: ImmutableState) => state)
  const location = useLocation()
  const { trackEvent } = useTracking({
    appSection: 'GuidedSystemBuilderRecommendation'
  })

  const name = new URLSearchParams(location.search).get('name')
  const systemImage = data?.systemImage
  const packageSku = data.packageSku ? data.packageSku : ''
  const _package = useSelector(selectPackage(packageSku)).toMaybe()
  const packageProduct = useSelector(selectProduct(packageSku)).toMaybe()
  const [leadGenError, leadGenResponse] =
    useGuidedSystemBuilderCaptureLeadAndTrack()
  const miniCart = useSelector(selectMiniCart)
  const lineItems = List.from(miniCart.orJust([]))

  const { shouldUseDiscountPrice } = useNoDiscountPartner()

  const lineItemCount = lineItems
    .filter(lineItem => prop('price', lineItem) > 0)
    .map(prop('quantity'))
    .foldLeft(0)(add)

  const coreComponentProducts = useSelector(
    selectProducts(systemCoreComponents)
  )
  const coreComponentNotInStockList = componentsNotInStock(
    coreComponentProducts
  )
  const includedItems = getIncludedItems(
    data.possibleSystemComponents,
    data.possibleIncludedItemModals,
    lineItems,
    coreComponentNotInStockList
  )

  const price = useSelector(
    data.packageSku
      ? selectCustomSystemTotalPrice(data.packageSku, false)
      : () => None<number>()
  )
  const discountPrice = useSelector(
    data.packageSku
      ? selectCustomSystemDiscountedPrice(data.packageSku, withPlanKey, false)
      : () => None<number>()
  )
  const discountedPrice = shouldUseDiscountPrice ? discountPrice : price

  const [onAddToCartClick, isAddingToCart, addToCartError, cartItems] =
    useDynamicPackageAddToCart(
      packageSku,
      discountedPrice,
      data.monitoringPlanSku || '',
      true
    )
  useGuidedSystemBuilderTrackRecommendation(
    _package,
    packageProduct,
    cartItems,
    trackEvent,
    select,
    discountedPrice
  )

  const packageErrorMessage = data?.packageRetrievalErrorMessage && (
    <RichTextWithOptionsComponent data={data.packageRetrievalErrorMessage} />
  )
  const packageLoadingMessage = data?.packageLoadingMessage && (
    <RichTextWithOptionsComponent data={data.packageLoadingMessage} />
  )

  const [clientId, sessionId] = useGoogleAnalytics()
  const vid = usePollVid()
  const retakeQuizUrl = appendParamsToGSBLaunchUrl(
    '/quiz',
    vid,
    clientId,
    sessionId
  )

  return leadGenResponse
    .map(({ attributeHash }) => {
      const linkText = 'See details'
      const ctaButtons = (
        <div className="mt-8 mb-4 flex flex-col relative">
          {addToCartError && (
            <div className="absolute -top-6">
              <AddToCartError errorType={addToCartError} />
            </div>
          )}
          <div className="flex gap-4">
            <SSButton
              color="primary"
              minWidth="auto"
              onClick={() => onAddToCartClick()}
              showSpinner={isAddingToCart}
            >
              Add to cart
            </SSButton>
            <Link to={`/product/system/${attributeHash}`}>
              <SSButton
                color="primaryOutline"
                minWidth="auto"
                onClick={() => {
                  handleLinkClick(linkText, trackEvent)
                }}
              >
                {linkText}
              </SSButton>
            </Link>
          </div>
        </div>
      )

      return (
        <>
          <DynamicPackageMiniCart attributeHash={attributeHash} />
          {miniCart.cata(
            () => (
              <BannerLoading>{packageLoadingMessage}</BannerLoading>
            ),
            () => (
              <BannerError height="responsive">
                {packageErrorMessage}
              </BannerError>
            ),
            () => (
              <BannerLoading>{packageLoadingMessage}</BannerLoading>
            ),
            () => (
              <div>
                <Heading
                  className="text-center md:mb-11"
                  element="h1"
                  margin="extraSmall"
                  useTailwind
                >
                  {name && <span className="capitalize">{name}, </span>}
                  {name ? 'h' : 'H'}ere’s our recommendation for your home
                </Heading>

                <div className="flex flex-col items-center justify-center gap-6 md:flex-row md:gap-20 md:mb-16">
                  {/* @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message */}
                  {systemImage && <GatsbyImage image={getImage(systemImage)} />}
                  <div>
                    <Heading
                      element="h2"
                      headingAspect="h3"
                      margin="extraSmall"
                      useTailwind
                    >
                      {name && <span className="capitalize">{name}’s </span>}
                      Custom {lineItemCount}-Piece Security System
                    </Heading>
                    <IncludedItem
                      includedItems={includedItems?.map(item =>
                        item.sku.map(sku => (
                          <IncludedItemProduct
                            key={sku}
                            modalContent={item.modalContent}
                            modalType="link"
                            outOfStockMessage={item.outOfStockMessage}
                            productName={`${item.quantity} ${
                              item.quantity > 1 && item.displayNamePlural
                                ? item.displayNamePlural
                                : item.displayName
                            }`}
                          />
                        ))
                      )}
                      includedItemsTitle=""
                      numColumnsDesktop={3}
                      numColumnsMobile={2}
                      numColumnsTablet={3}
                    />
                    <Text className="mb-3" textColor="errorRed" textSize="xs">
                      + 1 Month FREE Professional Monitoring
                    </Text>
                    <div className="text-3xl mb-3">
                      <Price
                        discountedPrice={price
                          .chain(price =>
                            discountedPrice
                              .filterNot(equals(price))
                              .chain(formatDisplayPrice)
                          )
                          .orUndefined()}
                        regularPrice={price
                          .chain(formatDisplayPrice)
                          .orUndefined()}
                      />
                    </div>
                    {ctaButtons}
                  </div>
                </div>

                <div className="max-w-4xl mx-auto">
                  <Heading
                    element="h2"
                    headingAspect="h4"
                    margin="small"
                    useTailwind
                  >
                    Why we recommended this system.
                  </Heading>
                  {
                    <QuizRecommendationExplanation
                      includedItems={includedItems}
                    />
                  }
                  {ctaButtons}
                  <Text useTailwind={true}>
                    <b>Doesn’t feel right?</b>{' '}
                    <a href={retakeQuizUrl}>Retake the quiz</a>
                  </Text>
                </div>
              </div>
            )
          )}
        </>
      )
    })
    .orSome(
      leadGenError ? (
        <BannerError height="responsive">{packageErrorMessage}</BannerError>
      ) : (
        <BannerLoading>{packageLoadingMessage}</BannerLoading>
      )
    )
}
