import { SSButton } from '@simplisafe/ss-react-components'
import React from 'react'

type SubmitProps = {
  readonly submitButtonLabel: string
  readonly submitButtonDisabled: boolean
  readonly showSpinner: boolean
}

function Submit(props: SubmitProps) {
  return (
    <div data-component={'Submission'}>
      <SSButton
        disabled={props.submitButtonDisabled}
        isLoading={props.submitButtonDisabled}
        minWidth={'full'}
        showSpinner={props.showSpinner}
        type="submit"
      >
        {props.submitButtonLabel}
      </SSButton>
    </div>
  )
}

export default Submit
