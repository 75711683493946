import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { CartUpdatedMessage } from '@simplisafe/ss-react-components'
import { SSInput } from '@simplisafe/ss-react-components'
import { SSButton } from '@simplisafe/ss-react-components'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

export type ApplyCouponProps = {
  readonly showApplyCoupon: boolean
  readonly showApplyCouponError: boolean
  readonly applyCouponCodeClick?: (
    code: string,
    showApplyCouponError?: boolean
  ) => void
}

function ApplyCoupon({
  showApplyCoupon,
  showApplyCouponError,
  applyCouponCodeClick
}: ApplyCouponProps) {
  const [promoCode, setPromoCode] = useState('')
  const [isClose, setIsClose] = useState(!showApplyCoupon)
  const locale = useSelector(selectLocale)

  const handleApplyCoupon = useCallback(e => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const v = e.target.value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setPromoCode(v)
  }, [])

  const handleOpenForm = () => setIsClose(false)

  return (
    <div className="block md:hidden">
      {locale === 'en-GB' && (
        <div
          className="text-[#006fee] grid-cols-auto no-underline text-[18px]"
          id="mmWrapper"
        ></div>
      )}
      <div className="mt-[16px]">
        {isClose ? (
          <div className="flex items-center">
            <p className="font-bold">Coupon Code</p>
            <a
              className="text-[18px] underline hover:no-underline pl-2"
              data-test="coupon-code-trigger"
              onClick={handleOpenForm}
            >
              apply
            </a>
          </div>
        ) : (
          <>
            <div className="flex flex-col mt-[10px] max-w[340px]">
              <p className="font-bold mb-3">Coupon Code</p>
              <div className="flex">
                <SSInput
                  className="mr-4"
                  id="couponCode"
                  label=""
                  name="couponCode"
                  onChange={handleApplyCoupon}
                  placeholder="Add code"
                  type="text"
                ></SSInput>
                <SSButton
                  className="w-2/5"
                  onClick={() =>
                    applyCouponCodeClick && applyCouponCodeClick(promoCode)
                  }
                >
                  apply
                </SSButton>
              </div>
            </div>
            <div>
              {showApplyCouponError ? (
                <div className="flex gap-[10px] mt-[10px] max-w-[340px]">
                  this coupon code is invalid or has expired
                </div>
              ) : (
                promoCode && (
                  <CartUpdatedMessage
                    className="flex gap-[10px] mt-[10px] max-w-[340px]"
                    duration={5000}
                    message="Discount code applied"
                  />
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ApplyCoupon
