import { selectPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/select'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { IsPartnerPageContext } from '../contexts/isPartnerPageContext'
import { getValueFromPartnerCookie, PartnerCookie } from '../util/partnerCookie'

const useIsPartner = (): boolean => {
  const isPartnerPageRedux = useSelector(selectPartnerBanner).isSome()
  const isPartnerPageReactContext = useContext(IsPartnerPageContext)
  const partnerCookiesKeys: ReadonlyArray<keyof PartnerCookie> = [
    'partnerGroup',
    'partnerName',
    'partnerUrl'
  ]
  const hasPartnerCookie = partnerCookiesKeys
    .map(getValueFromPartnerCookie)
    .some(Boolean)

  return isPartnerPageRedux || isPartnerPageReactContext || hasPartnerCookie
}

export default useIsPartner
