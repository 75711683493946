import { useLocation } from '@reach/router'
import prop from '@simplisafe/ewok/ramda/prop'
import { window } from 'browser-monads-ts'
import { graphql } from 'gatsby'
import React, { MouseEvent } from 'react'
import { UAParser } from 'ua-parser-js'

import { ContentfulSmsBlock } from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import { renderComponentFromData } from '../../util/render'

const userAgentParser = new UAParser()
const userAgentData = userAgentParser.getResult()

// Google Business Messenger URL param
const GBM = 'businessMessenger'

export type SmsBlockProps = {
  readonly data: ContentfulSmsBlock
}

const appleURL = (id: string) => `https://bcrw.apple.com/urn:biz:${id}`
const googleURL = (id: string) =>
  `https://businessmessages.google.com/widget/agent/${id}?i=CiQyMjZjYzg1NC1hM2ZiLTQzOGUtYmRiZS1iYzM5ZjcwNDU4MDQSCwj5k-iYBhCA1flnGgJ2MSo7CjlodHRwczovL3NpbXBsaXNhZmUuY29tL2NvbnRhY3QtdXM_YnVzaW5lc3NNZXNzZW5nZXI9ZmFsc2U&ctx=CONTACT_US`
export const getAPI = (os: string, appleId: string, googleId: string): string =>
  os === 'iOS' ? appleURL(appleId) : googleURL(googleId)

export default function SmsBlock({ data }: SmsBlockProps) {
  const deviceOS: string = userAgentData.os.name || ''
  const browserType: string = userAgentData.browser.name || ''

  const location = useLocation()
  const smsFallback = new URLSearchParams(location.search).get(GBM) || ''

  // Google Business Messenger is not supported on Android + Firefox
  const smsDisabled: boolean =
    smsFallback !== '' || (browserType === 'Firefox' && deviceOS === 'Android')
  const clickTargetData = smsDisabled ? 'clickTargetDisabled' : 'clickTarget'
  const clickTarget: ContentfulComponent = prop(clickTargetData, data) || {}

  const appleBusinessId: string = prop('appleBusinessId', data) || ''
  const googleBusinessId: string = prop('googleBusinessId', data) || ''

  const onClick = (e: MouseEvent) => {
    e.preventDefault()
    !smsDisabled &&
      window.location.assign(
        getAPI(deviceOS, appleBusinessId, googleBusinessId)
      )
  }

  return (
    <span onClick={e => onClick(e)}>
      {renderComponentFromData(clickTarget)}
    </span>
  )
}

export const SmsBlockQuery = graphql`
  #graphql
  fragment smsBlock on ContentfulSmsBlock {
    id
    internal {
      type
    }
    clickTarget {
      ... on ContentfulIconWithText {
        ...contentfulIconWithText
      }
    }
    clickTargetDisabled {
      ... on ContentfulIconWithText {
        ...contentfulIconWithText
      }
    }
    appleBusinessId
    googleBusinessId
  }
`
