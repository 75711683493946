import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { BlogArticlePreview } from '@simplisafe/ss-react-components'
import { graphql, Link } from 'gatsby'
import concat from 'ramda/src/concat'
import React from 'react'

import { ContentfulBlogArticleList } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type BlogArticleListComponentProps = {
  readonly data: Partial<ContentfulBlogArticleList>
}

const prependWithSlash = (str: string) =>
  str.startsWith('/') ? str : concat('/', str)

export default function BlogArticleListComponent({
  data
}: BlogArticleListComponentProps) {
  const linkText = prop('blogPreviewLinkText', data)

  return (
    <>
      {prop('blogPages', data).map(blogPage => {
        const url = prependWithSlash(prop('pageUrl', blogPage))

        const overviewUrl = <Link to={url}>{linkText}</Link>

        return (
          prop('pageComponents', blogPage)
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            .filter(
              pageComponent =>
                path(['internal', 'type'], pageComponent) ===
                'ContentfulBlogArticle'
            )
            .map(blogArticle => {
              const title = (
                <Link to={url}>
                  {/* title does not exist on the blogArticle type */}
                  {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
                  {prop('title', blogArticle)}
                </Link>
              )

              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              const previewDescription = (
                <ContentfulRichText
                  raw={path(['previewDescription', 'raw'], blogArticle)}
                />
              )

              const previewImage = (
                <Link to={url}>
                  <GatsbyImage
                    className="w-full rounded-base"
                    // @ts-expect-error TS(2339) FIXME: Property 'previewImage' does not exist on type 'Co... Remove this comment to see the full error message
                    image={blogArticle?.previewImage}
                  />
                </Link>
              )

              // TODO lastPostedText does not exist
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              const lastPostedText = (
                <ContentfulRichText
                  raw={path(['lastPostedText', 'raw'], blogArticle)}
                />
              )

              return (
                <BlogArticlePreview
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  key={prop('id', blogArticle)}
                  lastPostedText={lastPostedText}
                  overviewUrl={overviewUrl}
                  previewDescription={previewDescription}
                  previewImage={previewImage}
                  title={title}
                />
              )
            })
        )
      })}
    </>
  )
}

export const query = graphql`
  #graphql
  fragment contentfulBlogArticleList on ContentfulBlogArticleList {
    blogPages {
      pageComponents {
        ... on ContentfulBlogArticle {
          id
          internal {
            type
          }
          lastPostedText {
            raw
          }
          previewImage {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400
              placeholder: BLURRED
            )
            # id
            title
            description # TODO: get description from gatsbyImageData
          }
          previewDescription {
            raw
          }
          title
        }
      }
      pageUrl
    }
    blogPreviewLinkText
    id
    internal {
      type
    }
  }
`
