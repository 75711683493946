import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

import safeProp from '../safe/safeProp'
import { VariationFragment } from './types'
import { metaSchema } from './variationSchema'

/**
 * Gets the meta details from a fragment and parses them with the meta schema.
 *
 * @package internal use only
 */
function getVariationMeta<T extends VariationFragment<U>, U>(fragment?: T) {
  return pipe(
    O.fromNullable(fragment),
    O.chain(safeProp('variations')),
    O.chain(safeProp('meta')),
    O.map(meta => metaSchema.parse(meta)),
    O.getOrElse(() => metaSchema.parse({}))
  )
}

export default getVariationMeta
