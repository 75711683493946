import prop from '@simplisafe/ewok/ramda/prop'
import {
  Expander
  // RichText
} from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { ContentfulImageWithOverlay } from '../../../graphql'
import ImageWithFocalPoint from '../ImageWithFocalPoint'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type ImageWithOverlayProps = {
  readonly data: ContentfulImageWithOverlay
}

function ImageWithOverlay({ data }: ImageWithOverlayProps) {
  const image = data?.overlay?.badge?.image
  const badgeComponent = (
    <GatsbyImage
      alt=""
      // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
      image={getImage(image)}
      style={{ filter: 'drop-shadow( 0 0 2px rgba(0, 0, 0, 1))' }}
    />
  )

  //  TODO: Gatsby 4 rich text
  const richTextComponent = <p>Gatsby 4 rich text</p>
  // const richTextComponent = <RichText raw={data?.overlay?.textContent?.raw}
  //   textColor={'neutralWhite'}/>

  const imageData = prop('image', data)

  return (
    <div
      style={{
        height: '100%',
        position: 'relative'
      }}
    >
      <div
        style={{
          left: 20,
          position: 'absolute',
          right: 20,
          top: 20
        }}
      >
        <Expander left={badgeComponent} right={richTextComponent} />
      </div>
      {imageData && <ImageWithFocalPoint data={imageData} />}
    </div>
  )
}

export default ImageWithOverlay
export const ImageWithOverlayQuery = graphql`
  #graphql
  fragment imageWithOverlay on ContentfulImageWithOverlay {
    id
    internal {
      type
    }
    image {
      ... on ContentfulImageWithFocalPoint {
        ...imageWithFocalPoint
      }
    }
    overlay {
      id
      badge {
        image {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      textContent {
        raw
      }
    }
    overlayPosition
  }
`
