import '@simplisafe/ss-react-components/styles.css'
import './src/styles/global.css'
import 'react-loading-skeleton/dist/skeleton.css'

// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore -- this file will not exist on a fresh checkout
import datafile from './optimizely-datafile.json'

/**
 * IMPORTANT NOTE: interceptFetchHeaders MUST be imported first, otherwise fetch-intercept
 * can break in unexpected ways and cause us to stop sending the X-Vid-Token header to the backend.
 * Long-term, we should stop using fetch-intercept, and the headers required for SS API requests
 * should just be handled by the actual functions that make the requests (in ss-ecomm-data, etc).
 * - csims
 */
import { interceptFetchHeaders } from '@lib/tracking'
import { navigateForBmsTest } from '@lib/gatsby-wrappers'
import wrapWithContext from './config/wrap-with-context'
import wrapWithProvider from './config/wrap-with-provider'

export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithContext

// Registers fetch intercept.
// Called when the Gatsby browser runtime first starts: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
export const onClientEntry = () => {
  interceptFetchHeaders()

  // initialize prerender
  // @ts-expect-error TS(2339) FIXME: Property 'prerenderReady' does not exist on type '... Remove this comment to see the full error message
  window.prerenderReady = false
  // initialize GTM dataLayer
  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  window.dataLayer = window.dataLayer || []
  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  window.dataLayer.push({ site: 'fcp' })

  // START: ECP-5444 - BMS A/B test
  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  navigateForBmsTest(datafile)
  // END: ECP-5444 - BMS A/B test
}
