import {
  selectActivePromoDiscountTextWithOverrides,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { exists, window } from 'browser-monads-ts'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'

import BottomBanner from './BottomBanner'
import PromoStyler from './PromoStyler'
import TopBanner from './TopBanner'
import useScrollCondition from './useScrollCondition'

export type HolidayPromoBannerType = 'cart' | 'none' | 'pdp-plp' | 'standard'

export type HolidayPromoBannerProps = {
  /** Determines what type of banner to show. */
  readonly type: HolidayPromoBannerType
}

const cookies = new Cookies()
const COOKIE_DISMISSED = 'promobanner_isDismissed'
const COOKIE_DISMISSED_AFTER_SCROLLING = 'promobanner_isDismissedAfterScrolling'

function HolidayPromoBannerComponent({ type }: HolidayPromoBannerProps) {
  const isMobile = !useMediaQuery('TabletAndUp')
  const hasScrollCondition = useScrollCondition(isMobile)
  const [isDismissed, setIsDismissed] = useState(
    cookies.get(COOKIE_DISMISSED) ? true : false
  )
  const [isDismissedAfterScrolling, setIsDismissedAfterScrolling] = useState(
    cookies.get(COOKIE_DISMISSED_AFTER_SCROLLING) ? true : false
  )

  const handleDismissClick = useCallback(() => {
    setIsDismissed(true)
    cookies.set(COOKIE_DISMISSED, true)
    hasScrollCondition && setIsDismissedAfterScrolling(true)
    hasScrollCondition && cookies.set(COOKIE_DISMISSED_AFTER_SCROLLING, true)
  }, [hasScrollCondition])

  return (
    <PromoStyler>
      <TopBanner type={type} />
      {isMobile ? (
        <BottomBanner
          isDismissed={isDismissed}
          isDismissedAfterScrolling={isDismissedAfterScrolling}
          isMinimized={
            hasScrollCondition && isDismissed && !isDismissedAfterScrolling
          }
          onDismiss={handleDismissClick}
          type={type}
        />
      ) : null}
    </PromoStyler>
  )
}

function HolidayPromoBanner({ type }: HolidayPromoBannerProps) {
  const isActivePromo = useSelector(selectTopBannerVisible)
  const promoDiscountText = useSelector(
    selectActivePromoDiscountTextWithOverrides
  )
  const showPromoBanner = isActivePromo && promoDiscountText.isSome()

  // Checking if the window exists makes sure this isn't rendered during gatsby build, but only when the page
  // hydrates when the end user hits the site
  const isBrowser = exists(window)

  return type !== 'none' && showPromoBanner && isBrowser ? (
    <HolidayPromoBannerComponent type={type} />
  ) : null
}

export default HolidayPromoBanner
