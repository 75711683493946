import { GatsbyImage } from '@lib/components'
import { SSButton } from '@simplisafe/ss-react-components'
import { IconWithText } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import T from 'ramda/src/T'
import React, { ReactNode } from 'react'

import { ContentfulAlarmSensorSystem } from '../../../graphql'
import { toButton } from '../../util/helper'

export type LegacyBannerProps = Partial<ContentfulAlarmSensorSystem>

const interactiveMonitoringKey = 'Interactive Monitoring'
const legacySystemKey = 'Legacy System'

const textClasses = {
  [interactiveMonitoringKey]:
    'fontSize36 neutralWhiteTextColor fontWeightLight',
  [legacySystemKey]: 'prose neutralBlackTextColor'
}

const textDiscountClasses = {
  [interactiveMonitoringKey]: 'fontSize44 neutralWhiteTextColor',
  [legacySystemKey]: 'complementaryBlue100TextColor'
}

const subtextClasses = {
  [interactiveMonitoringKey]: 'fontWeightLight neutralWhiteTextColor',
  [legacySystemKey]: 'neutralBlackTextColor'
}

// TODO: We shouldn't be defining custom helper functions in components, explore using stringProp instead
const getOrEmptyString = (
  obj: { readonly [key: string]: string },
  key: string
): string => obj[key] || ''

type DiscountTextProps = {
  readonly children?: ReactNode
  readonly className?: string
  readonly style: string
}

function DiscountText({ children, className, style }: DiscountTextProps) {
  return (
    <span
      className={className}
      style={{
        display: 'inline-block',
        margin: '0 4px'
      }}
    >
      {children}
    </span>
  )
}

const renderPlaceholderText = (
  text: string,
  className: string,
  discountClassName: string,
  style: string,
  legacyDiscount: string | null | undefined,
  legacyPromoCode: string | null | undefined
): readonly ReactNode[] =>
  text
    .split(/(?:{{|}})/g)
    .filter(token => token.length > 0)
    .map((token, idx) =>
      cond([
        [
          equals('legacy_discount'),
          () => (
            <DiscountText
              className={discountClassName}
              key={`discount-${idx}`}
              style={style}
            >
              {legacyDiscount}
            </DiscountText>
          )
        ],
        [
          equals('legacy_promo_code'),
          () => (
            <DiscountText
              className={discountClassName}
              key={`promo-code-${idx}`}
              style={style}
            >
              {legacyPromoCode}
            </DiscountText>
          )
        ],
        [
          T,
          (token: string) => (
            <span className={className} key={`text-${idx}`}>
              {token}
            </span>
          )
        ]
      ])(token)
    )

export default function LegacyBanner({
  legacyDiscount,
  legacyPromoCode,
  legacyText,
  legacySubtext,
  legacyIcon,
  legacyButton,
  legacyStyle
}: LegacyBannerProps) {
  const style = legacyStyle || legacySystemKey

  // @ts-expect-error TS(2322) FIXME: Type '{ alt: string; image: ContentfulAsset; }' is... Remove this comment to see the full error message
  const icon = legacyIcon && (
    <GatsbyImage alt="Legacy Banner Icon" image={legacyIcon} />
  )

  const text = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {legacyText && (
        <div
          style={{
            textAlign: 'center',
            width: '100%'
          }}
        >
          {renderPlaceholderText(
            legacyText,
            getOrEmptyString(textClasses, style),
            getOrEmptyString(textDiscountClasses, style),
            style,
            legacyDiscount,
            legacyPromoCode
          )}
        </div>
      )}
      {legacySubtext && (
        <div
          style={{
            textAlign: 'center',
            width: '100%'
          }}
        >
          {renderPlaceholderText(
            legacySubtext,
            getOrEmptyString(subtextClasses, style),
            '',
            style,
            legacyDiscount,
            legacyPromoCode
          )}
        </div>
      )}
    </div>
  )

  const button = legacyButton ? (
    <div style={{ marginLeft: '2rem' }}>
      <SSButton {...toButton(legacyButton)} type="link" />
    </div>
  ) : null

  return legacyText ? (
    <div
      className={classNames(
        'items-center flex-wrap flex justify-center border border-solid border-neutral-light-50',
        {
          'bg-neutral-black p-8 rounded-base':
            style === interactiveMonitoringKey
        }
      )}
    >
      {icon ? <IconWithText icon={icon} iconSize="medium" text={text} /> : text}
      {button}
    </div>
  ) : null
}
