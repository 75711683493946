import { safeProp } from '@simplisafe/monda'
import { CarouselContainer } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maybe } from 'monet'
import React from 'react'

import { ContentfulImageGallery } from '../../../graphql'

export type ImageGalleryComponentProps = {
  readonly data: ContentfulImageGallery
}

const toGatsbyImage = (imgData: any, isMobile: boolean) => {
  // todo this needs to be done in react components, not here
  // todo also move it out of DynamicPackageHeroBannerComponent
  const getHeight = isMobile
    ? {
        height: '340px',
        minWidth: '380px'
      }
    : {
        height: '600px',
        width: '100%'
      }

  const altText = Maybe.fromNull(imgData)
    .chain(safeProp('description'))
    .getOrElse('')

  const id = Maybe.fromNull(imgData).chain(safeProp('id')).getOrElse('')

  return Maybe.fromNull(imgData).map(imgProps => (
    <GatsbyImage
      alt={altText}
      image={getImage(imgProps)}
      key={id}
      style={getHeight}
    />
  ))
}

// Copied from ItemContainerComponent's `toProductImage` fn + ItemContainer.
const ImageGalleryComponent = ({ data }: ImageGalleryComponentProps) => {
  const isMobile = !useMediaQuery('TabletAndUp')
  const images = safeProp('images', data)
    .map(imagesArray => {
      return imagesArray.map(x => toGatsbyImage(x, isMobile))
    })
    .orNull()
  return Maybe.fromNull(images).cata(
    () => null,
    images => (
      <CarouselContainer
        arrows={true}
        autoplay={false}
        paginationPosition={'bottom-right-to-center'}
        playButton={false}
        slides={images}
      />
    )
  )
}

export default ImageGalleryComponent

export const ImageGalleryQuery = graphql`
  #graphql
  fragment imageGallery on ContentfulImageGallery {
    id
    images {
      description
      gatsbyImageData(layout: CONSTRAINED, width: 833, placeholder: BLURRED)
      id
    }
    internal {
      type
    }
  }
`
