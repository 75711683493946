import { useIsomorphicLayoutEffect } from '@lib/react-hooks'
import { userAttributes } from '@lib/tracking'
import { useOptimizelyParams } from '@lib/tracking'
import verifyMaybe from '@simplisafe/ewok/monet-utils/verifyMaybe'
import verifyMaybeObj from '@simplisafe/ewok/monet-utils/verifyMaybeObj'
import { safePath } from '@simplisafe/monda'
import { initializeMiniCart } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import {
  ImmutablePackages,
  initializePackages,
  Package
} from '@simplisafe/ss-ecomm-data/packages'
import {
  ImmutableProducts,
  initializeProducts,
  Product
} from '@simplisafe/ss-ecomm-data/products'
import {
  IOActivePromotion,
  IOEvergreenPromotion,
  IOPromoCode,
  setEvergreenPromotionAction
} from '@simplisafe/ss-ecomm-data/promotions/actions'
import { EvergreenPromotion } from '@simplisafe/ss-ecomm-data/promotions/lib'
import { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { isImmutable, Map } from 'immutable'
import { Maybe } from 'monet'
import once from 'ramda/src/once'
import React, { FC, ReactElement, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

type InjectContextProps = {
  readonly children: ReactElement
  readonly products: ImmutableProducts | { readonly [key: string]: Product }
  readonly packages: ImmutablePackages | { readonly [key: string]: Package }
  readonly evergreenPromotion: Maybe<EvergreenPromotion>
}

const PackageInfoPageAnchorId = '#package-info'

// TODO: this type should be exported out of ss-ecomm-data
type EcommDispatch = ThunkDispatch<ImmutableState, void, ACTION>

/**
 * Data like products and packages need to be added to Redux ASAP (before
 * `useEffect` hooks run) and need to only run once on initial mount.
 * `useLayoutEffect` doesn't play nicely with SSR for things that can affect
 * how a page renders, so this is pulled out into a separate function wrapped
 * in `once` that gets called directly in the component.
 */
const useHydrateReduxOnce = () => {
  const hydrateReduxOnce = useRef(
    once(
      (
        dispatch: EcommDispatch,
        products: InjectContextProps['products'],
        packages: InjectContextProps['packages'],
        evergreenPromotion: InjectContextProps['evergreenPromotion']
      ) => {
        // Products and packages seemingly get turned into JS objects at build time.
        // This turns them back into an Immutable Map before adding them to redux.
        dispatch(
          initializeProducts(isImmutable(products) ? products : Map(products))
        )
        dispatch(
          initializePackages(isImmutable(packages) ? packages : Map(packages))
        )

        // This sets promotion data based on data fetched at build time
        verifyMaybe(evergreenPromotion).forEach(promo =>
          dispatch(setEvergreenPromotionAction(promo))
        )

        verifyMaybe(evergreenPromotion)
          .chain(verifyMaybeObj)
          .forEach(promo => {
            promo.promoCode.forEach(code => dispatch(IOPromoCode(code)))
            promo.promoCodeWithMonitoring.forEach(code =>
              dispatch(IOPromoCode(code))
            )
          })
      }
    )
  )

  return hydrateReduxOnce.current
}

const InjectContext: FC<InjectContextProps> = ({
  children,
  products,
  packages,
  evergreenPromotion
}: InjectContextProps) => {
  const dispatch = useDispatch()
  const optimizelyParams = useOptimizelyParams()
  const hydrateReduxOnce = useHydrateReduxOnce()

  hydrateReduxOnce(dispatch, products, packages, evergreenPromotion)

  // Fetch data from the promo service
  useIsomorphicLayoutEffect(() => {
    // pull the current userAttribute data to send to promo service to properly bucket the user
    const attributes = userAttributes()

    dispatch(IOActivePromotion(new Date(), attributes, optimizelyParams))
    dispatch(IOEvergreenPromotion(attributes, optimizelyParams))

    // Only fetch promo data once on mount
  }, [])

  // Reset the minicart state on page load/navigate to prevent errors from stale/invalid data when navigating between the BMS and dynamic package pages.
  useIsomorphicLayoutEffect(() => {
    // check if we should clear minicart, don't clear minicart if we are on the package page navigating to #package-info anchor
    // fixes https://simplisafe.atlassian.net/browse/ECP-3325
    const pageAnchorPath = safePath(
      ['props', 'location', 'hash'],
      children
    ).chain(x => {
      return x === PackageInfoPageAnchorId ? Maybe.Just(x) : Maybe.None()
    })

    pageAnchorPath.isNone() && dispatch(initializeMiniCart({}))
  })

  return <span>{children}</span>
}

export default InjectContext
