import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  CardItemSmallBanner,
  OfferTag,
  SSButton
} from '@simplisafe/ss-react-components'
import { CardItemSmallBannerProps } from '@simplisafe/ss-react-components/CardItemSmallBanner'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import join from 'ramda/src/join'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import React from 'react'
import { useSelector } from 'react-redux'

import { ContentfulProductCard } from '../../../graphql'
import { usePriceContext } from '../../providers/PriceProvider'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import { toFirstCharLower } from '../../util/helper'
import { getRenderNodeWithPlaceholder } from '../../util/renderNodeWithPlaceholder'
import ContentfulRichText from '../ContentfulRichText'

type CardItemSmallBannerComponentProps = {
  readonly id?: string
  // CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
  readonly data: ContentfulProductCard
}

const toButtonProps = applySpec<SSButtonProps>({
  // @ts-expect-error TS(2345) FIXME: Argument of type '<U>(b: U) => string | U' is not ... Remove this comment to see the full error message
  color: pipe(
    prop('type'),
    defaultTo('primaryOutline'),
    toFirstCharLower,
    split(' '),
    join('')
  ),
  href: path(['button', 'url'])
})

export default function CardItemSmallBannerComponent({
  data
}: CardItemSmallBannerComponentProps) {
  const skuID = safeProp('productId', data)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  // For US, when the top banner promo is displayed then priority should be given to discount without plan.
  const isNoPlanDiscount =
    isPromoTopBanner && equals('en-US', process.env.LOCALE)

  const {
    getDiscountedText,
    getDiscountedTextWithServicePlan,
    getFormattedPrice
  } = usePriceContext()
  const price = getFormattedPrice(skuID.orJust(''))(
    priceDefault,
    true,
    !isNoPlanDiscount
  )

  const discountedText = getDiscountedText(skuID.orJust(''))
  const discountedTextWithServicePlan = getDiscountedTextWithServicePlan(
    skuID.orJust('')
  )

  const placeholderText = isNoPlanDiscount
    ? discountedText
    : discountedTextWithServicePlan

  const tagBackgroundColor: string | undefined =
    data?.promotionalTagging?.tagBackgroundColor
  const tagTextColor: string | undefined = data?.promotionalTagging?.textColor

  const additionalInfoContent = () => (
    <ContentfulRichText raw={data?.additionalInfo?.raw} />
  )
  const productDescriptionContent = () => (
    <ContentfulRichText raw={data?.productDescription?.raw} />
  )
  const productHighlightsContent = () => (
    <ContentfulRichText raw={data?.productHighlights?.raw} />
  )

  const options = getRenderNodeWithPlaceholder(
    <>{placeholderText.orJust('')}</>
  )
  const offerTagRichText = (
    <ContentfulRichText
      optionsCustom={options}
      raw={data?.promotionalTagging?.taggingText?.raw}
      // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulPlaceholder[]' is not ass... Remove this comment to see the full error message
      references={data?.promotionalTagging?.taggingText?.references}
    />
  )

  const buttonProps = toButtonProps(data)
  const url = path(['button', 'url'], data)
  const isInternalLink = url && url.startsWith('/')
  const ssButton = (
    <SSButton {...buttonProps} type={isInternalLink ? 'div' : 'link'}>
      {path(['button', 'text'], data)}
    </SSButton>
  )
  const button =
    url && url.startsWith('/') ? <Link to={url}>{ssButton}</Link> : ssButton

  // TODO: Gatsby-4-Upgrade - test new Offer Tag API
  const props = transformObject<
    ContentfulProductCard,
    CardItemSmallBannerProps
  >({
    additionalInfoContent,
    bannerImage: data => {
      const image = data?.image?.[0]
      return (
        image && (
          <GatsbyImage
            className="w-full"
            image={image}
            imgStyle={{ objectFit: 'cover' }}
          />
        )
      )
    },
    button: () => button,
    offerTagContent: () => {
      return (
        isPromoTopBanner && (
          <OfferTag
            className={
              'w-full lg:w-auto lg:min-w-[15.5rem] lg:px-4 items-center bottom-0 flex h-12 justify-center absolute right-0 text-center'
            }
            content={offerTagRichText}
            tagBackgroundColor={tagBackgroundColor}
            tagTextColor={tagTextColor}
          />
        )
      )
    },
    price: () => price,
    productDescriptionContent,
    productHighlightsContent,
    // different to propOr because data.title is string | null | undefined
    title: _data => prop('title', _data) || ''
  })(data)

  return <CardItemSmallBanner {...props} />
}

export const query = graphql`
  #graphql
  fragment promotionalTagging on ContentfulPromotionalTagging {
    id
    tagBackgroundColor
    textColor
    borderColor
    taggingText {
      raw
      references {
        ... on ContentfulPlaceholder {
          ...placeholderFragment
        }
      }
    }
    tagBackgroundColor
  }
  fragment contentfulCardItemSmallBanner on ContentfulProductCard {
    id
    internal {
      type
    }
    textPosition
    spaceId
    additionalInfo {
      raw
    }
    productDescription {
      raw
    }
    productHighlights {
      raw
    }
    title
    button {
      text
      url
      type
    }
    image {
      title
      description
      gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
    }
    productId
    promotionalTagging {
      ...promotionalTagging
    }
  }
`
