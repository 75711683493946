import { Address } from '@commercetools/platform-sdk'
import {
  TrackingData,
  addCountryCodeToPhoneNumber,
  brazeTrackPurchaseComplete
} from '@lib/tracking'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { awinRequest } from '@simplisafe/ss-ecomm-data/thirdparty/awin'
import { Maybe } from 'monet'
import path from 'ramda/src/path'
import propOr from 'ramda/src/propOr'
import Cookies from 'universal-cookie'

import {
  getCartDiscountCode,
  getCartDiscountValue
} from '../../components/CartDetailsComponent/transformLineItem'
import { getCommerceDataFromLineItems, TrackEvent } from '.'

const EVENT_CATEGORY_PURCHASE_SYSTEM = 'Purchase System'
const EVENT_CATEGORY_PURCHASE = 'Purchase'

const GTM_TRANSACTION_INFO_ACTION = 'transaction'
const GTM_TRANSACTION_INFO_CATEGORY = 'eec'
const GTM_TRANSACTION_INFO_EVENT = 'eec.transaction'
const GTM_TRANSACTION_INFO_LABEL = 'transactions'
const AWIN_COOKIE = 'awin_awc'

const getOrderPhoneNumber = (shippingAddress: Maybe<Address>) =>
  shippingAddress.cata(
    () => '',
    // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
    (data: Address) =>
      addCountryCodeToPhoneNumber(propOr<string, string>('', 'phone', data))
  )

export const trackPaymentConfirmation = (
  trackEvent: TrackEvent,
  cart: ImmutableCart,
  systemInOrder: boolean,
  orderId: string,
  cartTotal: number,
  email: string,
  selectedPaymentOption: TrackingData['selectedPaymentOption'],
  shippingAddress: Maybe<Address>,
  locale: string
) => {
  const ecommerceData = getCommerceDataFromLineItems('purchase')(
    cart.get('lineItems')
  )
  const orderPhoneNumber = getOrderPhoneNumber(shippingAddress)
  const cookies = new Cookies()

  trackEvent({
    ecommerce: getCommerceDataFromLineItems('purchase')(cart.get('lineItems')),
    event: systemInOrder ? 'purchaseSystem' : 'purchase',
    // todo add eventCategory param to TrackingData
    eventCategory: systemInOrder
      ? EVENT_CATEGORY_PURCHASE_SYSTEM
      : EVENT_CATEGORY_PURCHASE,
    // todo add orderTotal param to TrackingData
    orderTotal: cart.subTotal,
    selectedPaymentOption: selectedPaymentOption,
    // todo add transactionCoupon param to TrackingData
    transactionCoupon: getCartDiscountCode(cart),
    // todo add transactionDiscount param to TrackingData
    transactionDiscount: getCartDiscountValue(cart),
    transactionId: orderId,
    transactionTotal: Number(cartTotal),
    userData: shippingAddress.cata(
      () => null,
      (data: Address) => ({
        email,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        firstName: propOr<string, string>('', 'firstName', data),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        lastName: propOr<string, string>('', 'lastName', data),
        phoneNumber: orderPhoneNumber
      })
    )
  })

  brazeTrackPurchaseComplete({
    // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
    currencyCode: propOr<string, string>(
      'en-US',
      'currencyCode',
      ecommerceData
    ),
    orderId,
    phoneNumber: orderPhoneNumber,
    products: path(['purchase', 'products'], ecommerceData) || [],
    systemInOrder
  })

  const awinCookie: string = cookies.get<string>(AWIN_COOKIE)
  locale === 'en-GB' &&
    awinCookie &&
    awinRequest({
      amount: Number(cartTotal),
      clickSource: awinCookie,
      orderRef: orderId,
      voucher: getCartDiscountCode(cart)
    })(err => logError(err))(() => null)
}

export function trackTransactionEvent(
  trackEvent: TrackEvent,
  cart: ImmutableCart,
  selectedPaymentOption: TrackingData['selectedPaymentOption']
) {
  trackEvent({
    ecommerce: getCommerceDataFromLineItems('purchase')(cart.get('lineItems')),
    // todo add eec.transaction to TrackingEvent union type
    event: GTM_TRANSACTION_INFO_EVENT,
    // todo add eventAction param to TrackingData
    eventAction: GTM_TRANSACTION_INFO_ACTION,
    // todo add eventCategory param to TrackingData
    eventCategory: GTM_TRANSACTION_INFO_CATEGORY,
    // todo add eventLabel param to TrackingData
    eventLabel: GTM_TRANSACTION_INFO_LABEL,
    selectedPaymentOption: selectedPaymentOption
  })
}
