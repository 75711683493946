import { COOKIE_PREACTIVATION, OptimizelyEvent } from '@lib/tracking'
import { CreateOrderV1Response as CreateOrderResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption as yodaCookieOptions } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { Either } from 'monet'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import Cookies from 'universal-cookie'

import { getCookieDomain } from '../../../util/common'

const preactivationCookieSettings = () => ({
  ...yodaCookieOptions,
  ...{
    domain: getCookieDomain(),
    // Override default behavior so value is not encoded. However, the service
    // consuming this value should decode it.
    encode: (str: string) => str
  }
})

export const handlePreactivationEvents =
  (trackEvent: <T>(_data: T) => void) =>
  (optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void) => {
    optimizelyTrackSiteEvents({ eventType: 'auto_activation' })
    trackEvent({ event: 'userInAutoActivation' })
  }

export const handlePreactivationCookie =
  (cookies: Cookies) => (value: string) => {
    // The cookie has characters that have been escaped when saving from API to
    // hidden input value. We need to decode any escaped characters so the
    // cookie is back in its raw value.
    cookies.set(COOKIE_PREACTIVATION, value, preactivationCookieSettings())
  }

export const checkPreActivationEligibilityStatus = (
  orderResponse: CreateOrderResponse
): Either<Error, CreateOrderResponse> => {
  return orderResponse.preActivationEligibility === 'eligible'
    ? Either.right(orderResponse)
    : Either.left(
        Error(
          'createOrder: initPreactivationFlow - order is ineligible for preactivation'
        )
      )
}

export const validateWebAppToken = (
  orderResponse: CreateOrderResponse
): Either<Error, CreateOrderResponse> =>
  checkPreActivationToken(orderResponse)
    ? Either.right(orderResponse)
    : Either.left(
        Error(
          'createOrder: initPreactivationFlow - missing webappToken on eligible preactivation order'
        )
      )

const checkPreActivationToken = (
  orderResponse: CreateOrderResponse
): boolean => {
  const isNewUser = orderResponse.custom.fields.uidCreated
  const hasToken =
    !isNil(orderResponse.webappToken) && !isEmpty(orderResponse.webappToken)

  return (isNewUser && hasToken) || !isNewUser
}
