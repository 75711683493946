/* eslint-disable max-lines -- legacy code */
import { GatsbyImage, GatsbyImageSchema } from '@lib/components'
import { devParams } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import { safePath, safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { IOCreateOrSetPartnerAssociationCart } from '@simplisafe/ss-ecomm-data/cart'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/actions'
import { selectPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoDiscountText,
  selectMonthsOfServiceDisplay,
  selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  configureNewRelic,
  logError
} from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  Heading,
  PageBody,
  PageSection,
  PageWrapper,
  PartnerHero,
  Text,
  ToastCheckMarkAlert
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql, PageProps } from 'gatsby'
import { set } from 'local-storage'
import { Just, Maybe, None } from 'monet'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import React, { useContext, useEffect, useState } from 'react'
import { Toast, toast, ToastBar, Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { PageContext } from '../../config/wrap-with-context'
import {
  ContentfulAdditionalInfoBanner,
  ContentfulAsset,
  ContentfulBanner,
  ContentfulCarousel,
  ContentfulCompanyReviewBanner,
  ContentfulContentSelector,
  ContentfulDeviceVariations,
  ContentfulFloatingBar,
  ContentfulFooter,
  ContentfulGroupSection,
  ContentfulHeroBanner,
  ContentfulImageWithArtDirection,
  ContentfulPartnerPage,
  ContentfulPartnerPageTemplate,
  ContentfulPrefectSystemBanner,
  ContentfulResponsiveContainer,
  ContentfulSiteWideMessages,
  ContentfulSmallTextSection,
  ContentfulTwoColumn,
  ContentfulVariationContainer
} from '../../graphql'
import { ContentfulComponent, getMappedComponent } from '../componentMappings'
import ContentfulRichText from '../components/ContentfulRichText'
import ContentfulVariationContainerComponent from '../components/ContentfulVariationContainerComponent'
import CountryRedirectModal from '../components/CountryRedirectModal'
import FooterComponent from '../components/FooterComponent'
import Header from '../components/Header'
import { ContentfulHeaderFragment } from '../components/Header/query'
import HeroBannerComponent from '../components/HeroBannerComponent'
import ImageWithArtDirection from '../components/ImageWithArtDirection'
import PartnerBanner from '../components/PartnerBanner'
import PopupWizard from '../components/PopupWizard'
import { HidePopupWizard } from '../contexts/hidePopupWizardContext'
import { IsPartnerPageContext } from '../contexts/isPartnerPageContext'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'
import ErrorBoundary from '../errorComponents/ErrorBoundary'
import useApplyPromoCode from '../hooks/useApplyPromoCode'
import { PageTitleContext } from '../tracking/pageTitleContext'
import { sendGtmCustomEvent, trackEventIsMobile } from '../util/analytics'
import { getLocalStorageCartIdAsString } from '../util/helper'
import { setPartnerCookie } from '../util/partnerCookie'
import SEO from '../util/seo'

// gatsby-4-upgrade manually defined media page query type
// caution: fragments are no longer generated types, so they are substituted for Partial<ContentfulXYZ> Schema types.
export type PartnerPageQuery = {
  readonly contentfulSiteWideMessages?: Partial<ContentfulSiteWideMessages> | null
  readonly contentfulPartnerPage?:
    | (Pick<
        ContentfulPartnerPage,
        | 'coBrandTitle'
        | 'customShopUrl'
        | 'node_locale'
        | 'pageTitle'
        | 'partnerGroup'
        | 'partnerName'
        | 'termsAndConditionsPosition'
        | 'termsAndConditionsTitle'
      > & {
        readonly template?:
          | (Pick<ContentfulPartnerPageTemplate, 'utmCode'> & {
              readonly header?: ContentfulHeaderFragment | null
              readonly mainContent?: ReadonlyArray<
                | ContentfulAdditionalInfoBanner
                | ContentfulBanner
                | ContentfulCarousel
                | ContentfulCompanyReviewBanner
                | ContentfulContentSelector
                | ContentfulDeviceVariations
                | ContentfulGroupSection
                | ContentfulPrefectSystemBanner
                | ContentfulResponsiveContainer
                | ContentfulSmallTextSection
                | ContentfulTwoColumn
                | null
              > | null
              readonly footer?: Partial<ContentfulFooter> | null
              readonly popupWizard?: Partial<ContentfulFloatingBar> | null
              readonly countryRedirectModal?: Partial<ContentfulSmallTextSection> | null
            })
          | null
        readonly coBrandLogo?:
          | (Pick<ContentfulAsset, 'description'> & {
              readonly fixed?: GatsbyImageSchema | null
            })
          | null
        readonly heroImage?: ContentfulImageWithArtDirection | null
        readonly heroBody?: { readonly raw?: string | null } | null
        readonly termsAndConditions?: { readonly raw?: string | null } | null
      })
    | null
}

export type PartnerPageContext = PageContext & {
  readonly id: string
}

export type PartnerPageProps = PageProps<PartnerPageQuery, PartnerPageContext>

function PartnerPage({
  data,
  pageContext,
  path: pagePath,
  location
}: PartnerPageProps) {
  const dispatch = useDispatch()
  useEffect(() => {
    // This sets up New Relic with our configuration from ecomm-data
    configureNewRelic()
    // Scroll to top on route change
    window && window.scrollTo(0, 0)
  }, [])

  const pageTitle: string =
    path(['contentfulPartnerPage', 'pageTitle'], data) || ''
  const partnerName: string =
    path(['contentfulPartnerPage', 'partnerName'], data) || ''
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const partnerGroup = pathOr<string, string>(
    '',
    ['contentfulPartnerPage', 'partnerGroup'],
    data
  )
  // format partner group to match keys in Commercetools
  const partnerGroupFormatted = partnerGroup.toLowerCase().replace(' ', '-')
  const customShopUrl = path(['contentfulPartnerPage', 'customShopUrl'], data)

  const handlePartnerAssociationCartFailure = () => {
    logError(
      Error('there was an error when adding a partner association to cart')
    )
  }

  useEffect(() => {
    setPartnerCookie({
      customShopUrl: customShopUrl,
      partnerGroup: partnerGroupFormatted,
      partnerName: partnerName,
      partnerUrl: pagePath
    })

    partnerName &&
      partnerGroupFormatted !== '' &&
      dispatch(IOPartnerBanner(partnerName, partnerGroupFormatted))
    const cartId = getLocalStorageCartIdAsString()
    dispatch(
      IOCreateOrSetPartnerAssociationCart(
        partnerGroupFormatted,
        partnerName,
        cartId,
        handlePartnerAssociationCartFailure
      )
    )
  }, [customShopUrl, dispatch, partnerName, partnerGroupFormatted, pagePath])
  const setPageTitle = useContext(PageTitleContext)
  const { trackEvent } = useTracking()
  useEffect(() => {
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])

  const partnerBanner = useSelector(selectPartnerBanner)
  const monthsOfServiceDisplay = useSelector(
    selectMonthsOfServiceDisplay(true, true)
  )
  const discountText = useSelector(selectActivePromoDiscountText).map(
    text => `${text} off`
  )
  const lineItemText = useSelector(selectPromotionBanner).chain(
    chainProp('freeGiftItemPrimaryText')
  )

  const isTabletUp = useMediaQuery('TabletAndUp')
  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [isTabletUp, trackEvent])

  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)

  const [hidePopupWizard, setHidePopupWizard] = useState(true)

  const handleHidePopup = (hide: boolean) => setHidePopupWizard(hide)

  const termsAndConditions = (
    <ContentfulRichText
      raw={path(['contentfulPartnerPage', 'termsAndConditions', 'raw'], data)}
    />
  )
  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"Bottom" ... Remove this comment to see the full error message
  const termsAndConditionsPosition:
    | 'Bottom'
    | 'Co-Brand Box'
    | 'Hero Image'
    | 'None' =
    path(['contentfulPartnerPage', 'termsAndConditionsPosition'], data) ||
    'None'
  const termsAndConditionsTitle = path(
    ['contentfulPartnerPage', 'termsAndConditionsTitle'],
    data
  )
  const utmCode = path(['contentfulPartnerPage', 'template', 'utmCode'], data)

  utmCode && set('utm_code', utmCode)

  useApplyPromoCode(Maybe.fromNull(utmCode))
  return (
    <ErrorBoundary>
      <IsPartnerPageContext.Provider value={true}>
        <HidePopupWizard.Provider
          value={{
            handleHidePopup,
            hidePopupWizard
          }}
        >
          {/* @ts-expect-error TS(2322): Type 'Partial<ContentfulSiteWideMessages>' is not ... Remove this comment to see the full error message */}
          <SiteWideMessagesContext.Provider
            value={safeProp('contentfulSiteWideMessages', data).orJust({})}
          >
            <PageWrapper>
              <SEO
                lang={
                  path(['contentfulPartnerPage', 'node_locale'], data) ||
                  'en-US'
                }
                metaTitle={pageTitle}
                pageUrl={pagePath}
                productId={None()}
                seoDetails={{
                  isNofollow: true,
                  isNoindex: true
                }}
              />
              <Toaster toastOptions={{ style: { padding: 0 } }}>
                {(t: Toast) => (
                  <ToastBar toast={t}>
                    {({ message }) => (
                      <ToastCheckMarkAlert
                        hasCloseButton={true}
                        message={message}
                        onCloseHandler={() => {
                          sendGtmCustomEvent({
                            event: 'toastAlert',
                            eventAction: 'closeToastAlert',
                            eventCategory: 'UTMAdditionAlert',
                            eventLabel: 'closedToastAlert'
                          })
                          toast.dismiss(t.id)
                          window.localStorage.utm_code &&
                            window.localStorage.removeItem('utm_code')
                        }}
                      />
                    )}
                  </ToastBar>
                )}
              </Toaster>
              {partnerBanner.map(data => (
                <PartnerBanner
                  backgroundColor={data.backgroundColor.orUndefined()}
                  discountSecondaryText={data.discountSecondaryText.orUndefined()}
                  discountText={discountText.orSome('15% off')}
                  displayEmailInputField={data.displayEmailInputField.orUndefined()}
                  key="partner-banner"
                  lineItemText={lineItemText.orSome('free SimpliCam')}
                  linkPath={data.linkPath.orUndefined()}
                  logoDescription={data.logoDescription.orUndefined()}
                  logoUrl={data.logoUrl.orUndefined()}
                  monitoringText={`${monthsOfServiceDisplay} of monitoring`}
                  partnerName={partnerName}
                  primaryOfferText={data.primaryOfferText.orUndefined()}
                  primaryTextColor={data.primaryTextColor.orUndefined()}
                  secondaryOfferText={data.secondaryOfferText.orUndefined()}
                  secondaryOfferTextMobile={data.secondaryOfferTextMobile.orUndefined()}
                  secondaryTextColor={data.secondaryTextColor.orUndefined()}
                ></PartnerBanner>
              ))}
              {safePath(['contentfulPartnerPage', 'template', 'header'], data)
                .map(
                  (
                    header:
                      | ContentfulHeaderFragment
                      | ContentfulVariationContainer
                  ) => (
                    // eslint-disable-next-line react/jsx-key
                    <ErrorBoundary>
                      {
                        /* eslint-disable @typescript-eslint/consistent-type-assertions*/
                        header?.internal?.type ===
                        'ContentfulVariationContainer' ? (
                          <ContentfulVariationContainerComponent
                            data={header as ContentfulVariationContainer}
                            renderVariant={variant => (
                              <Header
                                data={variant as ContentfulHeaderFragment}
                              />
                            )}
                          />
                        ) : (
                          <Header data={header as ContentfulHeaderFragment} />
                        )
                      }
                    </ErrorBoundary>
                  )
                )
                .orUndefined()}
              {/* @ts-expect-error */}
              {safePath(['contentfulPartnerPage', 'heroBanner'], data)
                .map((heroBanner: ContentfulHeroBanner) => (
                  <HeroBannerComponent data={heroBanner} key="hero-banner" />
                ))
                .orUndefined()}
              <ErrorBoundary>
                <PageBody>
                  {Just(
                    heroImage =>
                      function (heroBodyRaw) {
                        return (
                          <ErrorBoundary>
                            <PartnerHero
                              appElementId="___gatsby"
                              body={<ContentfulRichText raw={heroBodyRaw} />}
                              coBrandLogo={safePath(
                                ['contentfulPartnerPage', 'coBrandLogo'],
                                data
                              )
                                .map(logo => (
                                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
                                  <GatsbyImage
                                    image={logo}
                                    key="cobrand-logo"
                                  />
                                ))
                                .orUndefined()}
                              coBrandTitle={safePath(
                                ['contentfulPartnerPage', 'coBrandTitle'],
                                data
                              ).orUndefined()}
                              img={
                                <ImageWithArtDirection
                                  data={heroImage}
                                  style={{ height: '100%' }}
                                />
                              }
                              termsAndConditions={termsAndConditions}
                              termsAndConditionsPosition={
                                termsAndConditionsPosition !== 'Bottom'
                                  ? termsAndConditionsPosition
                                  : 'None'
                              }
                              termsAndConditionsTitle={termsAndConditionsTitle}
                            />
                          </ErrorBoundary>
                        )
                      }
                  )
                    .apTo(
                      safePath(['contentfulPartnerPage', 'heroImage'], data)
                    )
                    .apTo(
                      safePath(
                        ['contentfulPartnerPage', 'heroBody', 'raw'],
                        data
                      )
                    )
                    .orUndefined()}
                  {safePath(
                    ['contentfulPartnerPage', 'template', 'mainContent'],
                    data
                  )
                    .map(
                      map((entry: ContentfulComponent) => {
                        const Component = getMappedComponent(entry)
                        return Component ? (
                          <ErrorBoundary key={entry.id}>
                            <PageSection>
                              <Component
                                data={entry}
                                location={location}
                                pageContext={pageContext}
                              />
                            </PageSection>
                          </ErrorBoundary>
                        ) : null
                      })
                    )
                    .orUndefined()}
                  {termsAndConditionsPosition === 'Bottom' && (
                    <ErrorBoundary>
                      <PageSection>
                        {termsAndConditionsTitle && (
                          <Heading element="h2" headingAspect="paragraph">
                            {termsAndConditionsTitle}
                          </Heading>
                        )}
                        <Text textSize="sm">{termsAndConditions}</Text>
                      </PageSection>
                    </ErrorBoundary>
                  )}
                </PageBody>
              </ErrorBoundary>
              {safePath(['contentfulPartnerPage', 'template', 'footer'], data)
                .map(footer => (
                  <ErrorBoundary>
                    <FooterComponent data={footer} />
                  </ErrorBoundary>
                ))
                .orUndefined()}
              {safePath(
                ['contentfulPartnerPage', 'template', 'countryRedirectModal'],
                data
              )
                .map(
                  countryRedirectModal =>
                    !hidePopups && (
                      <ErrorBoundary>
                        <CountryRedirectModal data={countryRedirectModal} />
                      </ErrorBoundary>
                    )
                )
                .orUndefined()}
              {safePath(
                ['contentfulPartnerPage', 'template', 'popupWizard'],
                data
              )
                .map(popupWizard => (
                  <ErrorBoundary>
                    {}
                    <PopupWizard data={popupWizard} />
                  </ErrorBoundary>
                ))
                .orUndefined()}
            </PageWrapper>
          </SiteWideMessagesContext.Provider>
        </HidePopupWizard.Provider>
      </IsPartnerPageContext.Provider>
    </ErrorBoundary>
  )
}

// the #graphql tag is required for eslint and GraphQL for VSCode
export const query = graphql`
  #graphql
  query PartnerPage($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulPartnerPage(id: { eq: $id }) {
      pageTitle
      node_locale
      partnerName
      heroBanner {
        ... on ContentfulHeroBanner {
          ...heroBannerFragment
        }
      }
      template {
        utmCode
        header {
          ... on ContentfulHeader {
            ...contentfulHeaderFragment
          }
          ... on ContentfulVariationContainer {
            ...variationContainer
          }
        }
        mainContent {
          ... on ContentfulPrefectSystemBanner {
            ...perfectSystemBanner
          }
          ... on ContentfulAdditionalInfoBanner {
            ...OverlayBanner
          }
          ... on ContentfulCarousel {
            ...Carousel
          }
          ... on ContentfulTwoColumn {
            ...contentfulTwoColumnFragment
          }
          ... on ContentfulBanner {
            ...contentfulBanner
          }
          ... on ContentfulCompanyReviewBanner {
            ...CompanyReviewBannerInformation
          }
          ... on ContentfulContentSelector {
            ...contentSelector
          }
          ... on ContentfulDeviceVariations {
            ...deviceVariationsFragment
          }
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
          ... on ContentfulResponsiveContainer {
            ...responsiveContainer
          }
          ... on ContentfulGroupSection {
            ...contentfulGroupSectionFragment
          }
          ... on ContentfulExpandableMonitoringPlan {
            ...expandableMonitoringPlanFragment
          }
          ... on ContentfulProductCard {
            ...productCard
          }
        }
        footer {
          ... on ContentfulFooter {
            ...contentfulFooter
          }
        }
        popupWizard {
          ... on ContentfulFloatingBar {
            ...popupWizardFragment
          }
        }
        countryRedirectModal {
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
        }
      }
      partnerGroup
      partnerName
      customShopUrl
      coBrandTitle
      termsAndConditionsPosition
      termsAndConditionsTitle
      coBrandLogo {
        description # TODO get description from gatsbyImageData
        gatsbyImageData(layout: FIXED, width: 160, placeholder: BLURRED)
      }
      heroImage {
        ... on ContentfulImageWithArtDirection {
          ...imageWithArtDirection
        }
      }
      heroBody {
        raw
      }
      termsAndConditions {
        raw
      }
    }
  }
`

export default PartnerPage
