import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import classNames from 'classnames'
import React from 'react'

import usePromoBannerData from './usePromoBannerData'

type OfferProps = { readonly isMobileBottomBanner?: boolean }

function Offer({
  isMobileBottomBanner: isMobileBottomBannerProp = false
}: OfferProps) {
  const {
    discountText,
    hasCountdown,
    hasFreeGiftItem,
    // lineItemLabel, // not used in holiday banner designs.
    lineItemTitle,
    offerLabel,
    offerTitle
  } = usePromoBannerData()
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isTablet = useMediaQuery('TabletAndUp') && !isDesktop
  const isMobile = !useMediaQuery('TabletAndUp')

  const isMobileBottomBanner = isMobile && isMobileBottomBannerProp
  const isMobileTopBanner = isMobile && !isMobileBottomBanner
  const offerTitleText =
    hasFreeGiftItem && lineItemTitle
      ? `${offerTitle} + ${lineItemTitle}`
      : offerTitle

  return discountText ? (
    <div
      className={classNames('rc-font-default md:ml-8', {
        ['lg:ml-20']: hasCountdown,
        ['lg:ml-32']: !hasCountdown,
        ['mb-4 mt-0 text-center']: isMobileBottomBanner
      })}
      data-component="OfferText"
    >
      <div
        className={classNames(
          'rc-text-inherit',
          'lg:mb-1',
          'leading-none',
          'md:leading-none',
          {
            'md:text-2xl': isTablet,
            'rc-font-medium': isDesktop || isMobileBottomBanner,
            'rc-text-4xl': isDesktop,
            'text-2xl': isMobileBottomBanner,
            'text-xl': isMobileTopBanner
          }
        )}
        data-component="OfferText_Title"
      >
        {offerTitleText}
      </div>
      <div className="text-xs" data-component="OfferText_Label">
        {offerLabel}
      </div>
    </div>
  ) : null
}

export default Offer
