import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown'

/**
 * Async function to convert a string of markdown into raw Contentful RichText.
 *
 * Useful for mocks and unit tests.
 *
 *
 * @example
 * const mocks = async () => ({
 *   description: await createMockRichText('Shop now!')
 * })
 *
 * test('test', async () => {
 *   expect(renderRichText({raw: await mocks().desciption, references: []}))
 * })
 */
export default async function createMockRichText(
  markdown: string
): Promise<string> {
  const text = await richTextFromMarkdown(markdown)
  return JSON.stringify(text)
}
