import { Maybe } from 'monet'

import useApplyPromoCode from '../../hooks/useApplyPromoCode'
import useUtmCode from '../../hooks/useUtmCode'

type Props = {
  readonly pagePath: string
}

/**
 * ApplyPromoCodeComponent is a wrapper for the useApplyPromoCode hook
 * so Redux dependencies don't end up spilling on the Page component and cause unnecessary rerenders.
 */
export default function ApplyPromoCodeComponent({ pagePath }: Props) {
  const utmCode = useUtmCode(true, Maybe.fromFalsy(pagePath))
  useApplyPromoCode(Maybe.fromNull(utmCode))

  return null
}
