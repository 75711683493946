import { PageBanner } from '@lib/components'
import React from 'react'

import { ContentfulPageBanner } from '../../../graphql'

export type PageBannerComponentProps = {
  readonly data: ContentfulPageBanner
}

function PageBannerComponent({ data }: PageBannerComponentProps) {
  const backgroundColor = data?.backgroundColor
  const text = data?.primaryText
  const textColor = data?.primaryTextColor

  return text ? (
    <PageBanner
      backgroundColor={backgroundColor ? backgroundColor : undefined}
      textColor={textColor ? textColor : undefined}
    >
      <span className="rc-font-bold rc-uppercase">{text}</span>
    </PageBanner>
  ) : null
}

export default PageBannerComponent
