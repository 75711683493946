import { GatsbyImage } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { SSButton, TestimonialBanner } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import {
  CompanyReview,
  TestimonialBannerProps
} from '@simplisafe/ss-react-components/TestimonialBanner'
import { graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { propOr } from 'ramda'
import applySpec from 'ramda/src/applySpec'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'
import React from 'react'

import { ContentfulCompanyReviewBanner } from '../../../graphql'

type CompanyReviewBannerComponentProps = {
  readonly id: string
  readonly data: Partial<ContentfulCompanyReviewBanner>
}

const toCompanyList = (x: any) => {
  const image = x?.companyIcon
  return (
    image && (
      <GatsbyImage
        className="max-h-20"
        image={image}
        imgClassName="object-contain object-center"
      />
    )
  )
}

const toCompanyReviewItem = applySpec<CompanyReview>({
  companyIcon: toCompanyList,
  companyName: prop('companyName'),
  companyReview: prop('companyReview'),
  iconSize: prop('iconSize')
})

const renderButton = (data: Partial<ContentfulCompanyReviewBanner>) =>
  safeProp('button', data)
    .map(button =>
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      propOr<boolean, boolean>(false, 'isLinkExternal', data) ? (
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        <SSButton href={propOr<string, string>('#', 'url', button)} type="link">
          {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
          {propOr<string, string>('', 'text', button)}
        </SSButton>
      ) : (
        <Link
          key="link"
          role="button"
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          to={propOr<string, string>('', 'url', button)}
        >
          {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
          <SSButton type="div">
            {propOr<string, string>('', 'text', button)}
          </SSButton>
        </Link>
      )
    )
    .orUndefined()

const toCompanyReviewBanner = applySpec<TestimonialBannerProps>({
  companyReviewList: pipe(
    prop('companyReviewComponent'),
    map(toCompanyReviewItem)
  ),
  sidePaddingSize: data =>
    safeProp('sidePadding', data).map(toLower).orUndefined()
})

function CompanyReviewBannerComponent({
  data
}: CompanyReviewBannerComponentProps) {
  const isMobile = !useMediaQuery('TabletAndUp')

  const button = renderButton(data)

  const image = isMobile ? data.mediaMobile : data.media
  const backgroundProps = image
    ? {
        alt: image.title,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
        image: getImage(image)
      }
    : undefined

  return (
    <TestimonialBanner
      {...{
        ...toCompanyReviewBanner(data),
        buttonElement: button
      }}
      BackgroundComponent={image ? BgImage : undefined}
      backgroundComponentProps={backgroundProps}
    />
  )
}

export const query = graphql`
  #graphql
  fragment mediaAsset on ContentfulAsset {
    file {
      url
    }
  }
  fragment CompanyReviewBannerInformation on ContentfulCompanyReviewBanner {
    id
    internal {
      type
    }
    title
    companyReviewComponent {
      companyIcon {
        title
        description
        gatsbyImageData(layout: CONSTRAINED, width: 511, placeholder: BLURRED)
      }
      companyName
      companyReview
      iconSize
    }
    button {
      url
      text
      type
      isLinkExternal
    }
    media {
      title
      description
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    mediaMobile {
      title
      description
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    sidePadding
  }
`

export default CompanyReviewBannerComponent
