import * as IO from 'fp-ts/IO'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { window } from 'browser-monads-ts'

/**
 * Pushes to an array and returns the mutated array.
 */
function push<T>(arr: Array<T>) {
  return function (t: T) {
    arr.push(t)
    return arr
  }
}

/**
 * Pushes an item to the window data layer and returns the data layer.
 *
 * Checks if window is defined and doesn't throw an error.
 *
 * Has type of `IO` to make it clear this is an impure function that mutates data.
 *
 * @example
 * // functions should either return an IO or the updated dataLayer
 * function handleGTMTrackingEvent<T>(t: T): IO<Array<T>> {
 *   return pushToDataLayer(t)
 * }
 *
 * // or
 * function handleGTMTrackingEvent<T>(t: T): Array<T> {
 *   return pushToDataLayer(t)()
 * }
 *
 * @example
 * // unit testing
 * // You don't need to spy on or mock the window in unit tests, just look at the results
 *
 * test('pushes to data layer', () => {
 *   const dataLayer = pushToDataLayer({ userId: 'foo' })
 *   expect(dataLayer()).toEqual({ userId: 'foo'})
 * })
 */
export function pushToDataLayer(
  t: Record<string, unknown>,
  _window = window
): IO.IO<Record<string, unknown>[]> {
  return pipe(
    O.fromNullable(_window),
    O.map(w => {
      // if the dataLayer does not exist we want to add it
      w.dataLayer = w.dataLayer || []
      return w.dataLayer
    }),
    O.fold(
      () => IO.of([]),
      w => IO.of(push(w)(t))
    )
  )
}

/**
 * Removes all values from window.dataLayer.
 *
 * Useful for unit tests.
 *
 * @example
 * beforeEach(() => {
 *   resetDataLayer()
 * })
 */
export function resetDataLayer(_window = window) {
  window.dataLayer = []
  return window.dataLayer
}
