import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import defaultWhen from '@simplisafe/ewok/ramda-adjunct/defaultWhen'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { BgImage } from 'gbimage-bridge'
import __ from 'ramda/src/__'
import complement from 'ramda/src/complement'
import contains from 'ramda/src/contains'
import isNil from 'ramda/src/isNil'
import React, { ReactNode } from 'react'

import { ContentfulImageWithFocalPoint } from '../../../graphql'

export type ImageWithFocalPointProps = {
  /** Renders a background image if children are passed */
  readonly children?: ReactNode
  /** If rendering a background image for a Row, it needs to accept a class name for styling */
  readonly className?: string
  readonly data: ContentfulImageWithFocalPoint
  readonly roundedCorners?: boolean
}

export default function ImageWithFocalPoint({
  children,
  className,
  data,
  roundedCorners = true
}: ImageWithFocalPointProps) {
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const title = path(['desktopImage', 'title'], data)
  const styles = roundedCorners ? { borderRadius: '10px' } : {}

  const fillDimension = defaultWhen<string>(
    // TODO have this default to an empty string
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    complement(contains(__, ['height', 'width'])),
    'height',
    prop('fillDimension', data)
  )

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const mobileSources = path(
    ['mobileImage', 'gatsbyImageData', 'images', 'sources'],
    data
  )
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const desktopSources = path(
    ['desktopImage', 'gatsbyImageData', 'images', 'sources'],
    data
  )
  const mobileImageHeight = prop('mobileImageHeight', data)

  const sources = !isNil(mobileSources) && !isNil(desktopSources)

  const isMobile = !useMediaQuery('TabletAndUp')
  const getHeight =
    isMobile && mobileImageHeight
      ? { minHeight: mobileImageHeight + 'px' }
      : { minHeight: '100%' }
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const src = prop(isMobile ? 'mobileImage' : 'desktopImage', data)

  return children ? (
    <BgImage
      // @ts-expect-error TS(2322): Type '{ children: true | ReactChild | ReactFragmen... Remove this comment to see the full error message
      className={className}
      image={src}
      style={styles}
    >
      {children}
    </BgImage>
  ) : sources ? (
    <GatsbyImage
      image={src}
      style={{
        [fillDimension]: '100%',
        ...getHeight,
        ...styles
      }}
      title={title}
    />
  ) : null
}
