import { AffirmClient } from '@lib/components'
import {
  useOptimizelyAffirm,
  useOptimizelyTrackSiteEvents
} from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import propOr from '@simplisafe/ewok/ramda/propOr'
import { safeProp } from '@simplisafe/monda'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, Row, Text } from '@simplisafe/ss-react-components'
import { AffirmPromoMessage } from '@simplisafe/ss-react-components'
import { window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulAsset, ContentfulAssetFile } from '../../../../graphql'
import ContentfulRichText from '../../ContentfulRichText'

export type CheckoutButton = Maybe<
  Pick<ContentfulAsset, 'description' | 'file' | 'title'>
>

export type AffirmCheckoutContentProps = {
  readonly affirmClient?: AffirmClient
  readonly checkoutButton: CheckoutButton
  readonly instructions: { readonly raw: string }
  readonly note: { readonly raw: string }
  readonly onOrderSubmit: () => void
}

const AFFIRM_CHECKOUT_ID = 'affirm-checkout'

function AffirmCheckoutContent({
  // allows passing in a different value for affirmClient in unit tests
  // @ts-expect-error TS(2339) FIXME: Property 'affirm' does not exist on type 'Window'.
  affirmClient = window.affirm,
  checkoutButton,
  instructions,
  note,
  onOrderSubmit
}: AffirmCheckoutContentProps) {
  const cart = useSelector(selectCart)
  const { Track, trackEvent } = useTracking()

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()

  const onCheckoutButtonClick = useCallback(() => {
    trackEvent({ event: 'affirmCheckoutClick' })
    optimizelyTrackSiteEvents({ eventType: 'select_affirm_in_checkout' })
    onOrderSubmit()
  }, [onOrderSubmit, optimizelyTrackSiteEvents, trackEvent])
  return affirmClient ? (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <Row
        dataComponent="AffirmCheckoutContent"
        id={AFFIRM_CHECKOUT_ID}
        padding="small"
      >
        <Column>
          {cart
            .map(_cart => (
              <AffirmPromoMessage
                affirmClient={affirmClient}
                className="affirm-as-low-as"
                elementType="span"
                key="affirm-promo-msg"
                onLearnMoreClick={optimizelyAffirmLearnMore}
                pageType="cart"
                price={_cart.totalPrice}
                textSize="md"
              />
            ))
            .orNull()}
          {
            <ContentfulRichText
              key="affirm-instructions"
              raw={instructions.raw}
            />
          }
          {checkoutButton
            .chain(img => Maybe.fromNull(prop('file', img)))
            .map((file: ContentfulAssetFile) => (
              <Text key="affirm-checkout-link" textSize="sm">
                <a
                  href={`#${AFFIRM_CHECKOUT_ID}`}
                  onClick={onCheckoutButtonClick}
                >
                  <img
                    alt={checkoutButton
                      .chain(safeProp('description'))
                      .orJust('')}
                    loading="eager"
                    src={propOr('', 'url', file)}
                  />
                </a>
                {<ContentfulRichText key="affirm-note" raw={note.raw} />}
              </Text>
            ))}
        </Column>
      </Row>
    </Track>
  ) : null
}

export default AffirmCheckoutContent
