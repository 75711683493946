import { COOKIE_DRUPAL_UID, COOKIE_FCP_ORDER_ID } from '@lib/tracking'
import { get as sessionStorageGet, set as sessionStorageSet } from '@lib/utils'
import { safePath } from '@simplisafe/monda'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import {
  CreateOrderRequest,
  createOrderV1 as createApiOrder,
  CreateOrderV1Response as CreateOrderResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { chain, fork, FutureInstance, reject, resolve } from 'fluture'
import { get as localStorageGet } from 'local-storage'
import { Either, Maybe } from 'monet'
import { prop } from 'ramda'
import Cookies from 'universal-cookie'

import { getWebAppUrl } from '../../util/common'
import {
  checkPreActivationEligibilityStatus,
  validateWebAppToken
} from './utils/pre-activation'

export type ChaseOrderData = {
  readonly chaseCustomerRefNumber: string
  readonly chaseToken: string
  readonly transactionSecret: string | undefined
  readonly locale: string
  /** Type field is sent to the orders api endpoint. */
  readonly type: 'credit'
  readonly provider: 'chase'
}

export type AffirmOrderData = {
  readonly affirmCheckoutToken: string
  /** Type field is sent to the orders api endpoint. */
  readonly type: 'affirm'
}

export type ZuoraOrderData = {
  readonly paymentMethodId: string
  readonly token: string
  readonly type: 'credit'
  readonly provider: 'zuora'
}

export type OrderData = AffirmOrderData | ChaseOrderData | ZuoraOrderData

const cookies = new Cookies()

export const logErrorWithOrderInfo = (e: Error) => {
  const orderId = sessionStorageGet('orderId')
  const cartId = localStorageGet<string>(LOCAL_STORAGE_CARTID)
  logError(e, {
    cartId,
    orderId
  })
}

// exported for testing
export const getOrderId = (response: CreateOrderResponse) =>
  prop('orderNumber', response)

const forwardToPaymentConfirmation = (orderId: string) => {
  const urlPayment = '/payment-confirmation?orderId=' + orderId
  window.location.href = urlPayment
}

const forwardPreactivationFlowToWebApp = (
  hasPlan: boolean,
  orderId: string
) => {
  const webAppUrl = getWebAppUrl()
  const hasPlanUrl = `${webAppUrl}/#/collect-monitoring?funnel`
  const noPlanUrl = `${webAppUrl}/#/collect-monitoring?funnel&orderId=${orderId}`

  window.location.href = hasPlan ? hasPlanUrl : noPlanUrl
}

export const forwardToPaymentErrorUrl = (error: Error) => {
  window.location.href = `${window.location.origin}${
    window.location.pathname
  }?error=${encodeURIComponent(error.message)}`
}

// exported for testing
export const initPreactivationFlow = (
  orderResponse: CreateOrderResponse,
  onPreactivationReady: (_webAppToken: string) => void
) => {
  Either.of<Error, CreateOrderResponse>(orderResponse)
    .chain(checkPreActivationEligibilityStatus)
    .chain(validateWebAppToken)
    .cata(
      error => {
        logErrorWithOrderInfo(error)
        forwardToPaymentConfirmation(getOrderId(orderResponse))
      },
      _orderResponse => {
        // Fire tracking events to GTM and Optimizely.
        // Set auth token for webapp to extract user from.
        onPreactivationReady(_orderResponse.webappToken)
        forwardPreactivationFlowToWebApp(
          _orderResponse.hasPlan,
          getOrderId(orderResponse)
        )
      }
    )
}

const validateOrder = chain((response: Maybe<CreateOrderResponse>) =>
  response.cata<FutureInstance<Error, CreateOrderResponse>>(
    () =>
      reject(Error('createOrder: order response does not have a valid body')),
    res => resolve(res)
  )
)

const trackUid = chain((orderResponse: CreateOrderResponse) => {
  safePath(['custom', 'fields', 'uid'], orderResponse).forEach(uid => {
    cookies.set(COOKIE_DRUPAL_UID, uid, {
      path: '/',
      sameSite: 'strict',
      secure: true
    })
  })

  return resolve(orderResponse)
})

const trackOrderId = chain((orderResponse: CreateOrderResponse) => {
  const orderId = getOrderId(orderResponse)

  sessionStorageSet('orderId', orderId)

  // For Braze - we can use the ecomm cart data for purchase info,
  // but we need the order ID for the tag as well.
  const orderData = { orderID: orderId }
  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  window.dataLayer = window.dataLayer || []
  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  window.dataLayer.push(orderData)

  cookies.set(COOKIE_FCP_ORDER_ID, orderId, {
    path: '/',
    sameSite: 'strict',
    secure: true
  })

  return resolve(orderResponse)
})

const createOrderRequest = (
  cartId: string,
  orderData: OrderData
): CreateOrderRequest => ({
  cart: { id: cartId },
  custom: {
    fields:
      orderData.type === 'affirm'
        ? { paymentToken: orderData.affirmCheckoutToken }
        : orderData.provider === 'zuora'
        ? { paymentMethodId: orderData.paymentMethodId }
        : {
            // @ts-expect-error TS(2322) FIXME: Type '{ paymentToken: string; } | { paymentMethodI... Remove this comment to see the full error message
            paymentProfileId: orderData.chaseCustomerRefNumber,
            paymentToken: orderData.chaseToken,
            // eslint-disable-next-line no-undefined
            transactionSecret:
              orderData?.locale === 'en-GB'
                ? orderData.transactionSecret
                : undefined
          }
  },
  type: orderData.type
})

const validateOrderData = (orderData: OrderData) => {
  orderData.type === 'affirm' &&
    !orderData.affirmCheckoutToken &&
    logErrorWithOrderInfo(Error('createOrder: missing affirmCheckoutToken'))

  orderData.type === 'credit' &&
    orderData.provider === 'chase' &&
    !orderData.chaseToken &&
    logErrorWithOrderInfo(Error('createOrder: missing chaseToken'))

  orderData.type === 'credit' &&
    orderData.provider === 'chase' &&
    !orderData.chaseCustomerRefNumber &&
    logErrorWithOrderInfo(Error('createOrder: missing chaseCustomerRefNumber'))

  orderData.type === 'credit' &&
    orderData.provider === 'zuora' &&
    !orderData.paymentMethodId &&
    logErrorWithOrderInfo(Error('createOrder: missing paymentMethodId'))
}

type CreateOrderProps = {
  readonly cartId: string
  readonly onPaymentComplete: () => void
  readonly onPaymentError: (_e: Error) => void
  readonly onPreactivationReady: (_webAppToken: string) => void
}

/**
 * Creates an order in Commercetools and redirects the user to either the payment confirmation page
 * or the webapp (if eligible for preactivation).
 */
const createOrder = (props: CreateOrderProps) => (orderData: OrderData) => {
  validateOrderData(orderData)

  return createApiOrder(createOrderRequest(props.cartId, orderData))
    .pipe(validateOrder)
    .pipe(trackUid)
    .pipe(trackOrderId)
    .pipe(
      chain(orderResponse => {
        // Displays loading message for post payment flows such as preactivation.
        props.onPaymentComplete()
        return resolve(orderResponse)
      })
    )
    .pipe(
      fork((error: Error) => {
        logErrorWithOrderInfo(error)
        props.onPaymentError(error)
        forwardToPaymentErrorUrl(error)
      })((orderResponse: CreateOrderResponse) => {
        // Wait a second so user has change to read message/avoid jarring experience, and to allow
        // enough time for tracking pixels to fire.
        setTimeout(
          () =>
            initPreactivationFlow(orderResponse, props.onPreactivationReady),
          1000
        )
      })
    )
}

export default createOrder
