import { safePath, safeProp } from '@simplisafe/monda'
import { Record as ImmutableRecord } from 'immutable'
import { None } from 'monet'
import propOr from 'ramda/src/propOr'
import { useMemo } from 'react'

import { ContentfulForm2GUpgrade } from '../../../graphql'

type FormField = {
  readonly label: string
  readonly placeholder: string
  readonly propName: string
}

function fn<
  T extends {
    readonly title?: string | null
    readonly placeholderText?: string | null
    readonly propName?: string | null
  } | null
>(fields: readonly T[]): readonly FormField[] {
  return fields.map(field => {
    const fieldValue: FormField = {
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      label: propOr<string, string>('', 'title', field),
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      placeholder: propOr<string, string>('', 'placeholderText', field),
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      propName: propOr<string, string>('', 'propName', field)
    }
    return fieldValue
  })
}

const emptyFormFields: readonly FormField[] = []

const useForm2gUpgradeFragment = (data: ContentfulForm2GUpgrade) =>
  useMemo(() => {
    const descriptionValue = safePath(['description', 'raw'], data)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const fieldsDescriptionValue = propOr<string, string>(
      '',
      'fieldsDescription',
      data
    )
    const formFieldsValue: readonly FormField[] = safeProp('formFields', data)
      .map(fn)
      .getOrElse([])
    const infoModalImageValue = safeProp('sidNumberOverlayImage', data)
    const sidNumberOverlayCopyValue = safePath(
      ['sidNumberOverlayCopy', 'raw'],
      data
    )
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const sidNumberOverlayTitleValue = propOr<string, string>(
      '',
      'sidNumberOverlayTitle',
      data
    )
    const submitButton = safeProp('submitButton', data).orUndefined()
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const submitButtonLabelValue = propOr<string, string>(
      '',
      'text',
      submitButton
    )
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const titleValue = propOr<string, string>('', 'title', data)

    return ImmutableRecord({
      // Defines the shape of the object and defaults.
      description: None<string>(),
      fieldsDescription: '',
      formFields: emptyFormFields,
      infoModalImage: None(),
      sidNumberOverlayCopy: None<string>(),
      sidNumberOverlayTitle: '',
      submitButtonErrorMessage: '',
      submitButtonLabel: '',
      title: '',
      type: 'Form2GUpgradeFragment'
    })({
      // Redefines the object with values to apply over the defaults.
      description: descriptionValue,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      fieldsDescription: fieldsDescriptionValue,
      formFields: formFieldsValue,
      infoModalImage: infoModalImageValue,
      sidNumberOverlayCopy: sidNumberOverlayCopyValue,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      sidNumberOverlayTitle: sidNumberOverlayTitleValue,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      submitButtonLabel: submitButtonLabelValue,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      title: titleValue,
      type: 'Form2GUpgradeFragment'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

export default useForm2gUpgradeFragment
