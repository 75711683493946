import path from '@simplisafe/ewok/ramda/path'
import defaultTo from 'ramda/src/defaultTo'
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'

import ContentfulRichText from '../ContentfulRichText'
import { renderHomeMoversDownloadButton } from './embeddedEntries'

type Props = {
  // @ts-expect-error TS(2552) FIXME: Cannot find name 'Block'. Did you mean 'Lock'?
  readonly node: Block | Inline
}

/**
 * LEGACY COMPONENT: DO NOT EXTEND/COPY/REFERENCE
 * This component comes from a legacy RichText embed in ss-react-component that introduces a LOT of anti-pattern.
 * You should not be using this component as a basis/starting point for any future component development.
 *
 * The only two pages that use this component are /manual and /home-movers-hub, which renders their styles differently.
 */
function ImageButton({ node }: Props) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const linkText = path(
    ['data', 'target', 'linkItem', 'text', 'raw'],
    node
  ) as string
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const linkUrl = path(
    ['data', 'target', 'linkItem', 'icon', 'file', 'url'],
    node
  ) as string
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const downloadUrl = path(
    ['data', 'target', 'downloadedItem', 'file', 'url'],
    node
  ) as string
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Exclude<string[keyof O], null>' ... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const imageObjectFit = pipe(
    path<string>(['data', 'target', 'linkItem', 'iconObjectFit']),
    defaultTo('contain'),
    toLower
  )(node) as string
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const backgroundColor = pipe(
    path(['data', 'target', 'linkItem', 'backgroundColor', 'color'])
  )(node) as string

  const isHomeMovers =
    node?.data?.target?.contentful_id === '75uLRbeYiMX9T1ferLAHhO'

  return isHomeMovers ? (
    renderHomeMoversDownloadButton(node)
  ) : (
    <div>
      <a
        className="flex bg-center bg-no-repeat pl-[15.625rem] pt-[.625rem] my-[1.25rem] h-[9.375rem] items-center justify-center no-underline text-center border-4 border-white hover:border-primary-100 border-solid rounded-sm box-border"
        href={downloadUrl}
        style={{
          backgroundImage: `url(${linkUrl})`,
          backgroundSize: imageObjectFit,
          fontFamily: 'Arizona'
        }}
      >
        <ContentfulRichText raw={linkText} />
      </a>
    </div>
  )
}

export default ImageButton
