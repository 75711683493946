import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import classNames from 'classnames'
import React from 'react'

import usePromoBannerData from './usePromoBannerData'
import usePromoOverrideData from './usePromoOverrideData'

type EndsTextProps = { readonly isMobileBottomBanner?: boolean }

function EndsText({ isMobileBottomBanner = false }: EndsTextProps) {
  const {
    displayEndTimeCalloutOverride,
    endTime,
    endTimeCalloutOverride,
    hasEndDateCallout,
    locale
  } = usePromoBannerData()
  const { endsTextLabel } = usePromoOverrideData()
  const isMobile = !useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('DesktopAndUp')

  const endDate = displayEndTimeCalloutOverride
    ? endTimeCalloutOverride
    : endTime

  const endsText =
    hasEndDateCallout && endDate ? (
      <span
        className="rc-text-promo-secondary-text"
        data-component="PromoEndsText_Date"
        style={{ textTransform: 'capitalize' }}
      >{`Ends ${Promo.relativeEndTime(new Date(endDate), locale)}`}</span>
    ) : null

  const endsLabel = endsTextLabel ? (
    <span
      className="rc-text-promo-secondary-text"
      data-component="PromoEndsText_Label"
    >
      {endsTextLabel}
    </span>
  ) : null

  const result =
    endsLabel && endsText ? (
      <>
        {endsLabel} | {endsText}
      </>
    ) : (
      endsText
    )

  return result ? (
    <div
      className={classNames('rc-font-default', 'rc-text-inherit', {
        'rc-font-bold': !isMobile,
        'rc-font-regular': isMobile,
        'rc-text-[18px]': isDesktop,
        'rc-text-xs': !isDesktop && !isMobileBottomBanner,
        'text-sm': isMobileBottomBanner
      })}
      data-component="PromoEndsText"
    >
      {result}
    </div>
  ) : null
}

export default EndsText
