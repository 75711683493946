import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import classNames from 'classnames'
import React from 'react'

import usePromoBannerData from './usePromoBannerData'

type SaleNameProps = { readonly isMobileBottomBanner?: boolean }

function SaleName({ isMobileBottomBanner = false }: SaleNameProps) {
  const { hasSaleName, saleName } = usePromoBannerData()
  const isTabletUp = useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isMobile = !isTabletUp

  return hasSaleName && saleName ? (
    <div
      className={classNames(
        'rc-font-default',
        'rc-text-inherit',
        'rc-leading-none',
        'md:mb-0.5',
        'lg:mb-1',
        {
          'rc-font-bold': isMobile,
          'rc-font-medium': isDesktop,
          'rc-font-regular': isTabletUp && !isDesktop,
          'rc-text-2xl': isTabletUp && !isDesktop,
          'rc-text-4xl': isDesktop,
          'rc-text-base': isMobile && !isMobileBottomBanner,
          'text-lg': isMobileBottomBanner
        }
      )}
      data-component="PromoSaleName"
    >
      {saleName}
    </div>
  ) : null
}

export default SaleName
