import React from 'react'

function PackageDetailPage() {
  return (
    <div data-component="PackageDetailPage">
      You are currently viewing an experiemental version of the Package Detail
      Page.
    </div>
  )
}

export default PackageDetailPage
