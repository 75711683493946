import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { ContactFormSchema } from '@simplisafe/ss-ecomm-data/leads/schema/contactUsFormSchema'
import type { ContactUsFormSubmitBody } from '@simplisafe/ss-ecomm-data/leads/submission'
import { contactUsFormSubmit } from '@simplisafe/ss-ecomm-data/leads/submission'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import { Form, Formik } from 'formik'
import propOr from 'ramda/src/propOr'
import React from 'react'
import { useSelector } from 'react-redux'

import { ContentfulContactUsForm } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import Description from './form-sections/Description'
import Email from './form-sections/Email'
import Message from './form-sections/Message'
import Name from './form-sections/Name'
import PrivacyPolicy from './form-sections/PrivacyPolicy'
import Subject from './form-sections/Subject'
import Submission from './form-sections/Submission'
import SuccessMessage from './form-sections/SuccessMessage'

export type FormValues = {
  readonly email: string
  readonly locale: string
  readonly message: string
  readonly name: string
  readonly subject: string
}

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type ContactUsFormProps = {
  readonly data: ContentfulContactUsForm
}

export default function ContactUsForm({ data }: ContactUsFormProps) {
  const contactFormDescriptionRaw = path(
    ['contactFormDescription', 'raw'],
    data
  )
  const contactFormDescription = contactFormDescriptionRaw ? (
    <ContentfulRichText
      key={prop('id', data)}
      raw={contactFormDescriptionRaw}
    />
  ) : null

  const contactFormPrivacyPolicyRaw = path(['privacyPolicy', 'raw'], data)
  const contactFormPrivacyPolicy = contactFormPrivacyPolicyRaw ? (
    <SmallTextSection
      content={<ContentfulRichText raw={contactFormPrivacyPolicyRaw} />}
      key={prop('id', data)}
    />
  ) : null

  const successMessageDescriptionRaw = path(
    ['successMessageDescription', 'raw'],
    data
  )
  const contactFormsuccessMessageDescription = successMessageDescriptionRaw ? (
    <SmallTextSection
      content={<ContentfulRichText raw={successMessageDescriptionRaw} />}
      key={prop('id', data)}
      verticalPadding={'small'}
    />
  ) : null

  const locale = useSelector(selectLocale)

  const initialFormValues: FormValues = {
    email: '',
    locale: locale,
    message: '',
    name: '',
    subject: ''
  }

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (
        formData: ContactUsFormSubmitBody,
        { setSubmitting, setStatus }
      ) => {
        setSubmitting(false)
        const handleSuccess = () => {
          setStatus({ message: 'success' })
        }
        const handleFailure = (error: Error) => logError(error)
        contactUsFormSubmit(formData)(handleFailure)(handleSuccess)
      }}
      validationSchema={ContactFormSchema}
    >
      {({ isSubmitting, status }) => (
        <Form>
          {!status && (
            <>
              <Description
                contactFormDescription={contactFormDescription}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                formTitle={propOr<string, string>('', 'formTitle', data)}
              />
              <Name
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                nameFieldLabel={propOr<string, string>(
                  '',
                  'nameFieldLabel',
                  data
                )}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                nameFieldPlaceholder={propOr<string, string>(
                  '',
                  'nameFieldPlaceholder',
                  data
                )}
              />
              <Email
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                emailFieldLabel={propOr<string, string>(
                  '',
                  'emailFieldLabel',
                  data
                )}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                emailFieldPlaceholder={propOr<string, string>(
                  '',
                  'emailFieldPlaceholder',
                  data
                )}
              />
              <Subject
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                subjectDisclaimer={propOr<string, string>(
                  '',
                  'subjectDisclaimer',
                  data
                )}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                subjectFieldLabel={propOr<string, string>(
                  '',
                  'subjectFieldLabel',
                  data
                )}
                subjectOptions={prop('subjectOptions', data)}
              />
              <Message
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                messageFieldLabel={propOr<string, string>(
                  '',
                  'messageFieldLabel',
                  data
                )}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                messageFieldPlaceholder={propOr<string, string>(
                  '',
                  'messageFieldPlaceholder',
                  data
                )}
              />
              <Submission
                submitButtonDisabled={isSubmitting}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                submitButtonLabel={propOr<string, string>(
                  '',
                  'submitButtonLabel',
                  data
                )}
              />
              <PrivacyPolicy
                contactFormPrivacyPolicy={contactFormPrivacyPolicy}
              />
            </>
          )}
          {status && status.message && (
            <SuccessMessage
              successMessageDescription={contactFormsuccessMessageDescription}
              successMessageTitle={propOr('', 'successMessageTitle', data)}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}
