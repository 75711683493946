import { Link } from '@reach/router'
import { safeProp } from '@simplisafe/monda'
import {
  commentFormSchema,
  feedbackFormSubmit
} from '@simplisafe/ss-ecomm-data/feedback'
import { FeedbackSubmitBody } from '@simplisafe/ss-ecomm-data/feedback/request'
import {
  FormField,
  FormTextAreaInput,
  SSButton
} from '@simplisafe/ss-react-components'
import { Text } from '@simplisafe/ss-react-components'
import { Form, Formik, FormikHelpers } from 'formik'
import { navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import propOr from 'ramda/src/propOr'
import React, { useEffect, useState } from 'react'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'

import { ContentfulFeedbackForm } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type Props = {
  readonly data: ContentfulFeedbackForm
}

type FormValues = {
  readonly comment: string
}

const initialValues = { comment: '' }
const COMMENT_SIZE = 500

function FeedbackForm({ data }: Props) {
  const [submitted, setSubmitted] = useState(false)
  const [campaignId] = useQueryParam('campaign_id', StringParam)
  const [user] = useQueryParam('user', NumberParam)

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const commentPlaceholder = propOr<string, string>(
    '',
    'commentPlaceholder',
    data
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const successMessage = propOr<string, string>('', 'successMessage', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const submitButtonLabel = propOr<string, string>(
    '',
    'submitButtonLabel',
    data
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const title = propOr<string, string>('', 'title', data)

  const remainingValuesText = (comment: string) =>
    comment.length === 0 || comment.length >= 500
      ? ''
      : 'Remaining characters: ' + (500 - comment.length)

  useEffect(() => {
    !campaignId && navigate('/')
  }, [])

  const onSubmit = async (
    formData: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    const handleSuccess = () => {
      setStatus({ message: 'success' })
      setSubmitted(true)
      setSubmitting(false)
    }

    const handleFailure = (error: Error) => {
      logError(error)
      setSubmitting(false)
    }

    // eslint-disable-next-line functional/no-conditional-statement
    if (!campaignId) {
      handleFailure(new Error("Campaign Id couldn't be found"))
      return
    }

    const submitForm: FeedbackSubmitBody = {
      campaignId: campaignId,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      positiveFeedback: propOr<boolean, boolean>(
        true,
        'positiveFeedback',
        data
      ),
      userComment: formData.comment.trim(),
      userId: user || 0
    }

    feedbackFormSubmit(submitForm)(handleFailure)(handleSuccess)
  }

  return (
    <div
      className="md:p-10 text-center md:max-w-xl m-auto"
      data-component={'EmailFeedback'}
    >
      <Link to={'/'}>
        {safeProp('logo', data)
          .map(logo => (
            <GatsbyImage
              alt="SimpliSafe Logo"
              // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
              image={getImage(logo)}
              // @ts-expect-error TS(2322) FIXME: Type '{ alt: string; image: IGatsbyImageData; imgS... Remove this comment to see the full error message
              imgStyle={{ position: 'center' }}
              key="logo"
              style={{
                height: 'auto',
                width: '320px'
              }}
            />
          ))
          .orUndefined()}
      </Link>
      {!submitted ? (
        <>
          <Text className="pb-14" textAlignment="center" textSize="2xl">
            {title}
          </Text>
          {data.description?.raw && (
            <ContentfulRichText raw={data.description?.raw} />
          )}
        </>
      ) : (
        <Text className="pb-14" textAlignment="center" textSize="2xl">
          {successMessage}
        </Text>
      )}
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={commentFormSchema}
        >
          {({ isSubmitting, status, values }) => (
            <Form>
              {!status && (
                <>
                  <FormField hideLabel={true} name="comment">
                    {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
                    <FormTextAreaInput
                      name="comment"
                      placeholder={commentPlaceholder}
                    />
                    <div className="md:inline-flex gap-x-10">
                      <Text fontWeight="light">
                        Max Characters: {COMMENT_SIZE}
                      </Text>
                      <Text fontWeight="light">
                        {!!values.comment &&
                          remainingValuesText(values.comment)}
                      </Text>
                    </div>
                  </FormField>
                  {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
                  <FormField
                    hideLabel={true}
                    label={submitButtonLabel}
                    name="submit"
                  >
                    <div className="w-full justify-center flex">
                      <SSButton disabled={isSubmitting} type="submit">
                        {submitButtonLabel}
                      </SSButton>
                    </div>
                  </FormField>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default FeedbackForm
