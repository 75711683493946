import type { TrackingData } from '@lib/tracking'
import { exists, window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import has from 'ramda/src/has'

export const handleGTMTrackingEvent = (data: TrackingData) => {
  // TODO It's not explained very well in the GTM docs, but when GTM populates its internal representation of the data
  // layer it recursively merges the properties (alluded to in https://developers.google.com/tag-manager/devguide)
  // Basically this means data layer values stick around until overwritten. We've gotta decide what to do about this.
  // We could manually clear the data layer every time we push, or try to determine on a per-property basis whether to
  // set null or undefined values for properties that shouldn't be persisted.

  const augmentedData: TrackingData = {
    ...data,
    appSection: has('appSection', data) ? data.appSection : undefined,
    wizardType: has('wizardType', data) ? data.wizardType : undefined
  }

  Maybe.fromFalsy(exists(window)).forEach(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
    window.dataLayer = window.dataLayer || []

    // Pass event data to GTM data layer
    // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
    window.dataLayer.push(augmentedData)
  })
}
