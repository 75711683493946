import { safeProp } from '@simplisafe/monda'
import { Column, ProgressBar, Row, Text } from '@simplisafe/ss-react-components'
import { Modal } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import React, { useState } from 'react'

import { ContentfulCrimeLocationResults } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import { IRiskDataModel } from './types'
import {
  getPropertyDangerous,
  getRiskColor,
  getScoreColor,
  scoreToBGColor,
  scoreToWords
} from './utils'

type HeroBannerProps = {
  readonly loading: boolean
  readonly address: string
  readonly error?: string
  readonly riskData?: IRiskDataModel
  readonly data: Partial<ContentfulCrimeLocationResults>
}

function HeroBanner({
  loading,
  address,
  error,
  riskData,
  data
}: HeroBannerProps) {
  const [isLegalTextModalOpen, setIsLegalTextModalOpen] =
    useState<boolean>(false)
  const closeLegalTextModal = () => setIsLegalTextModalOpen(false)
  const openLegalTextModal = () => setIsLegalTextModalOpen(true)

  const violentCrimeScore = safeProp('crimeData', riskData).chain(
    safeProp('violentCrimeScore')
  )
  const propertyCrimeScore = safeProp('crimeData', riskData).chain(
    safeProp('propertyCrimeScore')
  )
  const crimeScore = safeProp('crimeScore', riskData)

  return (
    <div className="-mt-4 md:-mt-8 -ml-4 md:-ml-16 rc-bg-neutral-black w-[calc(100%+2rem)] md:w-[calc(100%+8rem)]">
      <div className="max-w-8xl lg:px-7 m-auto">
        <Row alignItems="center" rounded="none">
          <Column
            className="!rc-overflow-y-hidden rc-h-full text-center"
            rounded="none"
            spans={[12]}
          >
            <Text
              className="text-xl font-light text-white text-center"
              useTailwind
            >
              Crime Risk Overview for
            </Text>
            <Text
              className="text-3xl font-normal text-white text-center"
              useTailwind
            >
              {address}
            </Text>
            <Link
              className="text-center text-gray-200 text-xs underline font-light mt-1 mb-7 hover:text-blue-200 cursor-pointer"
              to="/crime-near-you"
            >
              Back to search page
            </Link>
            {loading && (
              <div className="text-center m-10" role="status">
                <svg
                  className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  fill="none"
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {error && (
              <div className={' text-xl mt-10'}>
                <p className="text-blue-400"> {error}</p>
              </div>
            )}
            {riskData && (
              <>
                <Text
                  className={`text-center ml-2 mt-3 mb-2 text-5xl font-semibold ${getScoreColor(
                    crimeScore.getOrElse(0)
                  )}`}
                  useTailwind
                >
                  {crimeScore.isSome() && scoreToWords(crimeScore.getOrElse(0))}{' '}
                  Risk
                  <p
                    className={`inline text-lg md:text-xs align-super ${getScoreColor(
                      crimeScore.getOrElse(0)
                    )}`}
                  >
                    <button
                      className={`md:hidden bg-transparent border-0 ${getScoreColor(
                        crimeScore.getOrElse(0)
                      )}`}
                      onClick={openLegalTextModal}
                    >
                      {' '}
                      (1)
                    </button>
                    <span className="hidden md:inline"> (1)</span>
                  </p>
                </Text>
                <Modal
                  isOpen={isLegalTextModalOpen}
                  onRequestClose={closeLegalTextModal}
                  size="small"
                >
                  <div className="p-6">
                    <ContentfulRichText raw={data.mapLegalText?.raw} />
                  </div>
                </Modal>
                <Text
                  className="text-white text-center mb-2 text-xs font-light"
                  useTailwind
                >
                  {getPropertyDangerous(riskData.crimeScore || 0)}
                </Text>
                <Row gap="small" padding="medium">
                  {violentCrimeScore.isSome() && (
                    <Column rounded="none" spans={[12, 6]}>
                      <div className="flex items-center justify-center">
                        <svg
                          className="h-6 w-6"
                          viewBox="0 0 640 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M622.3 271.1l-115.1-45.01c-4.125-1.629-12.62-3.754-22.25 0L369.8 271.1C359 275.2 352 285.1 352 295.1c0 111.6 68.75 188.8 132.9 213.9c9.625 3.75 18 1.625 22.25 0C558.4 489.9 640 420.5 640 295.1C640 285.1 633 275.2 622.3 271.1zM496 462.4V273.2l95.5 37.38C585.9 397.8 530.6 446 496 462.4zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM320.6 310.3C305.9 306.3 290.6 304 274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66 512H413.3c3.143 0 5.967-1.004 8.861-1.789C369.7 469.8 324.1 400.3 320.6 310.3z"
                            fill="#fff"
                          />
                        </svg>

                        <Text
                          className="text-white text-center text-2xl ml-2"
                          useTailwind
                        >
                          Violent Crime
                        </Text>
                      </div>
                      <div className="flex items-center justify-center">
                        <ProgressBar
                          className="rounded-lg h-7 mt-4 w-3/4 bg-slate-200"
                          fillClassName={`flex items-center justify-start rounded-l-lg ${scoreToBGColor(
                            violentCrimeScore.getOrElse(0)
                          )}`}
                          innerLabel={`${violentCrimeScore
                            .getOrElse(0)
                            .toFixed(0)}% - ${scoreToWords(
                            violentCrimeScore.getOrElse(0)
                          )} Crime Risk`}
                          innerLabelClassName={`pl-2 drop-shadow text-xs ${getRiskColor(
                            violentCrimeScore.getOrElse(0)
                          )}`}
                          percent={violentCrimeScore.getOrElse(0)}
                        />
                      </div>
                    </Column>
                  )}
                  {propertyCrimeScore.isSome() && (
                    <Column rounded="none" spans={[12, 6]}>
                      <div className="flex items-center justify-center">
                        <svg
                          className="h-6 w-6"
                          viewBox="0 0 640 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L522.1 193.9C513.6 192.7 504.9 192 496 192C404.2 192 328.8 262.3 320.7 352L320 352zM352 368C352 288.5 416.5 224 496 224C575.5 224 640 288.5 640 368C640 447.5 575.5 512 496 512C416.5 512 352 447.5 352 368zM496 464C509.3 464 520 453.3 520 440C520 426.7 509.3 416 496 416C482.7 416 472 426.7 472 440C472 453.3 482.7 464 496 464zM479.1 288V368C479.1 376.8 487.2 384 495.1 384C504.8 384 511.1 376.8 511.1 368V288C511.1 279.2 504.8 272 495.1 272C487.2 272 479.1 279.2 479.1 288z"
                            fill="#fff"
                          />
                        </svg>

                        <Text
                          className="text-white text-center text-2xl ml-2"
                          useTailwind
                        >
                          Property Crime
                        </Text>
                      </div>
                      <div className="flex items-center justify-center">
                        <ProgressBar
                          className="rounded-lg h-7 mt-4 w-3/4 bg-slate-200"
                          fillClassName={`flex items-center justify-start rounded-l-lg ${scoreToBGColor(
                            propertyCrimeScore.getOrElse(0)
                          )}`}
                          innerLabel={`${propertyCrimeScore
                            .getOrElse(0)
                            .toFixed(0)}% - ${scoreToWords(
                            propertyCrimeScore.getOrElse(0)
                          )} Crime Risk`}
                          innerLabelClassName={`pl-2 drop-shadow text-xs ${getRiskColor(
                            propertyCrimeScore.getOrElse(0)
                          )}`}
                          percent={propertyCrimeScore.getOrElse(0)}
                        />
                      </div>
                    </Column>
                  )}
                </Row>
              </>
            )}
          </Column>
        </Row>
      </div>
    </div>
  )
}

export default HeroBanner
