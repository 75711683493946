import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { TestimonialCard } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { ContentfulTestimonialCard } from '../../../graphql'

export type TestimonialCardProps = {
  readonly data: ContentfulTestimonialCard
}

const TestimonialCardComponent = ({ data }: TestimonialCardProps) => {
  const quote = prop('quote', data)
  const company = prop('company', data)
  const subtext = safeProp('subtext', data).orUndefined()
  const noBackground = prop('noBackground', data)

  return quote && company ? (
    <TestimonialCard
      company={company}
      noBackground={noBackground}
      quote={quote}
      subtext={subtext}
    />
  ) : null
}

export default TestimonialCardComponent

export const TestimonialCardQuery = graphql`
  #graphql
  fragment testimonialCard on ContentfulTestimonialCard {
    internal {
      type
    }
    noBackground
    company
    quote
    subtext
  }
`
