import React from 'react'
import { Helmet } from 'react-helmet'
import { match } from 'ts-pattern'

import { SEOProps } from './types'

/**
 * Inject SEO properties to <head> of html document.
 *
 * Open Graph SEO Types: https://ogp.me/#types
 * Twitter Cards: https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started

 * TODO: Extend to support pageSchema SEO Content
 */
export function SEO(props: SEOProps) {
  const {
    metaTitle,
    metaDescription,
    lang,
    canonicalLink,
    metaKeywords,
    isNoindex,
    isNofollow
  } = props

  const canonicalLinkContainer = canonicalLink
    ? [
        {
          href: canonicalLink,
          rel: 'canonical'
        }
      ]
    : []

  const keywords = metaKeywords ? metaKeywords.join(',') : ''

  const metaRobot = match<readonly [boolean, boolean], string>([
    isNoindex,
    isNofollow
  ])
    .with([true, true], () => 'noindex, nofollow')
    .with([true, false], () => 'noindex')
    .with([false, true], () => 'nofollow')
    .with([false, false], () => '')
    .exhaustive()

  const metaData = [
    {
      content: metaDescription,
      name: 'description'
    },
    {
      content: metaTitle,
      property: 'og:title'
    },
    {
      content: metaDescription,
      property: 'og:description'
    },
    {
      content: 'website',
      property: 'og:type'
    },
    {
      content: 'summary',
      name: 'twitter:card'
    },
    {
      content: '@SimpliSafe',
      name: 'twitter:creator'
    },
    {
      content: metaTitle,
      name: 'twitter:title'
    },
    {
      content: metaDescription,
      name: 'twitter:description'
    },
    {
      ...(keywords && {
        content: keywords,
        name: 'keywords'
      })
    },
    {
      ...(metaRobot && {
        content: metaRobot,
        name: 'robots'
      })
    }
  ]

  return (
    <Helmet
      htmlAttributes={{ lang }}
      link={canonicalLinkContainer}
      meta={metaData}
      title={metaTitle}
    />
  )
}
