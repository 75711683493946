import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Node } from '@contentful/rich-text-types'
import { GatsbyImage, toPaddingValue } from '@lib/components'
import { Column } from '@simplisafe/ss-react-components'
import type { Spans } from '@simplisafe/ss-react-components/Column'
import { ContentfulRichTextGatsbyReference } from 'gatsby-source-contentful/rich-text'
import React from 'react'

import { getMappedComponent } from '../../componentMappings'
import ContentfulRichText from '../ContentfulRichText'
import { renderHomeMoversDownloadButton } from '../ContentfulRichText/embeddedEntries'

export type TextColumnProps = {
  readonly listStyle?: string
  readonly references?: readonly ContentfulRichTextGatsbyReference[]
  readonly text?: string
  readonly textAlignment?: 'center' | 'left' | 'right'
  readonly textColor?: string
  readonly id?: string
  readonly padding?: 'Large' | 'Medium' | 'Small'
  readonly spans: Spans
  readonly columnCount?: number
}

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: function <T extends Node>(node: T) {
      const { gatsbyImageData } = node.data.target

      return <GatsbyImage image={gatsbyImageData} />
    },
    [BLOCKS.EMBEDDED_ENTRY]: function <T extends Node>(node: T) {
      const data = node.data.target

      const isDownloadButton =
        data?.internal?.type === 'ContentfulLink' &&
        data?.linkType === 'Download'

      const Component = data && getMappedComponent(data)

      return isDownloadButton ? (
        renderHomeMoversDownloadButton(node)
      ) : Component ? (
        <Component data={data} />
      ) : null
    }
  }
}

function TextColumn({
  listStyle,
  padding,
  text,
  textAlignment,
  textColor,
  id,
  references,
  spans,
  columnCount = 1
}: TextColumnProps) {
  return (
    <Column
      key={id}
      padding={toPaddingValue(padding)}
      rounded={'none'}
      spans={spans}
    >
      <ContentfulRichText
        optionsCustom={options}
        raw={text}
        references={references}
      />
      {/* TODO: Gatsby 4 rich text */}
      {/* <RichText
          raw={text}
          key={id}
          listStyle={toListStyleValue(listStyle)}
          style={{ columnCount:columnCount }}
          textAlignment={textAlignment}
          textColor={textColor}
        /> */}
    </Column>
  )
}

export default TextColumn
