import {
  CheckoutLogin,
  FormCheckbox,
  FormField,
  FormSection,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React, { ReactNode } from 'react'

type EmailProps = {
  readonly emailSectionTitle: string
  readonly emailFieldLabel: string
  readonly emailFieldPlaceholder: string
  readonly emailCheckboxLabel: string
  readonly emailDescription: ReactNode
  readonly isMobile: boolean
  readonly isValidCustomer: boolean
  readonly locale: string
  readonly loginSuccess: boolean
  readonly validatedDescription: ReactNode
}

function Email(props: EmailProps) {
  return (
    <div data-component={'Email'}>
      <FormSection name={props.emailSectionTitle}>
        <FormField hideLabel={props.isMobile} name="introduction">
          {props.loginSuccess ? (
            <Text>{props.validatedDescription}</Text>
          ) : (
            <CheckoutLogin content={props.emailDescription} />
          )}
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          label={props.emailFieldLabel}
          name="email"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="email"
            name="email"
            placeholder={props.emailFieldPlaceholder}
          />
        </FormField>
        <FormField hideLabel={props.isMobile} name={'exclusive-offers'}>
          {props.locale === 'en-US' &&
          props.isMobile === false &&
          props.loginSuccess === false ? (
            <FormCheckbox
              checked
              label={props.emailCheckboxLabel}
              name="offerAndTip"
            />
          ) : null}
        </FormField>
      </FormSection>
    </div>
  )
}

export default Email
