import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

const useTrackingPackageDetailToggleMonitoring = () => {
  const { trackEvent } = useTracking()

  return useCallback(
    (hasMonitoringPlan: boolean) => {
      trackEvent({
        action: 'package-details',
        category: 'monitoring-toggle',
        event: 'onChange',
        label: hasMonitoringPlan ? 'add-plan' : 'no-plan'
      })
    },
    [trackEvent]
  )
}

export default useTrackingPackageDetailToggleMonitoring
