import { GatsbyImageSchema } from './gatsbyImageSchema'

export default function createMockGatsbyImage(
  src: string,
  title = 'Test Image'
): GatsbyImageSchema {
  return {
    gatsbyImageData: {
      height: 1,
      images: {
        fallback: { src: src },
        sources: [
          {
            srcSet: src,
            type: 'image/webp'
          }
        ]
      },
      layout: 'fullWidth',
      width: 1
    },
    title
  }
}
