/* eslint-disable react/function-component-definition */
import { OptimizelyEvent, useOptimizelyTrackSiteEvents } from '@lib/tracking'
import { TrackingData, brazeLogCustomEvent } from '@lib/tracking'
import { safePath } from '@simplisafe/monda'
import { StickyPopoverLink } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC, useContext, useState } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulFloatingBar } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
import ContentfulVariationContainerComponent, {
  ContentfulVariationContainerComponentProps
} from '../ContentfulVariationContainerComponent'
import QuoteWizardWrapper from '../QuoteWizardWrapper'
import { QuoteWizardWrapperProps } from '../QuoteWizardWrapper'

const handleLinkClick =
  (trackEvent: (_data: Partial<TrackingData>) => void) =>
  (optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void) => {
    optimizelyTrackSiteEvents({ eventKey: 'get_a_quote_click_fs' })
    // TODO this event needs to be added to @lib/tracking
    // @ts-expect-error TS(2322) FIXME: Type '"quote-wizard-floating-start"' is not assign... Remove this comment to see the full error message
    trackEvent({ event: 'quote-wizard-floating-start' })
    brazeLogCustomEvent('qw_start')
  }

type FloatingQuizDialogueProps = {
  readonly data: Partial<ContentfulFloatingBar>
}

const FloatingQuizDialogue: FC<FloatingQuizDialogueProps> = ({
  data
}: FloatingQuizDialogueProps) => {
  const [isShow, setIsShow] = useState(false)
  const { hidePopupWizard: showStickyPopup } = useContext(HidePopupWizard)
  // @ts-expect-error TS(2322): Argument of type 'ContentfulFindYourPerfectSystem | ContentfulVariationContainer | { readonly __typename?: "%other" | undefined; }' is not assignable to parameter of type 'ContentfulComponent'. Type '{ readonly __typename?: "%other" | undefined; }' has no properties in common with type 'ContentfulComponent'.
  const Component =
    data.component &&
    getMappedComponent<
      ContentfulVariationContainerComponentProps | QuoteWizardWrapperProps
    >(data.component)
  const { Track, trackEvent } = useTracking({
    appSection: 'quoteWizard',
    wizardType: 'floating'
  })

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  function renderComponent() {
    return Component
      ? safePath(['component', 'variations'], data).cata(
          () => (
            <Component
              columnType="none"
              // @ts-expect-error
              data={data.component}
              type="floating"
              // @ts-expect-error
            />
          ),
          _ => (
            <ContentfulVariationContainerComponent
              data={data.component}
              renderVariant={variant => (
                <QuoteWizardWrapper
                  columnType="none"
                  data={variant}
                  type="floating"
                ></QuoteWizardWrapper>
              )}
            ></ContentfulVariationContainerComponent>
          )
        )
      : null
  }

  return data.title && data.component && (isShow || showStickyPopup) ? (
    <Track>
      <StickyPopoverLink
        linkText={data.title}
        onLinkClick={() => {
          handleLinkClick(trackEvent)(optimizelyTrackSiteEvents),
            setIsShow(true)
        }}
      >
        {renderComponent()}
      </StickyPopoverLink>
    </Track>
  ) : null
}

export default FloatingQuizDialogue

export const fragment = graphql`
  #graphql
  fragment floatingQuizDialogue on ContentfulFloatingBar {
    title
    internal {
      type
    }
    component {
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
      ... on ContentfulVariationContainer {
        id
        internal {
          type
        }
        experimentId
        experimentKey
        experimentTitle
        meta {
          internal {
            content
          }
        }
        variations {
          ... on ContentfulFindYourPerfectSystem {
            contentful_id
            ...quoteWizard
          }
        }
      }
    }
  }
`
