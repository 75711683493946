import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { toColorSelectorColor } from '@lib/components'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking'
import { useLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeNth, safeProp } from '@simplisafe/monda'
import {
  IOAddToCart,
  IOCreateOrUpgradeCart
} from '@simplisafe/ss-ecomm-data/cart'
import { ProductBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import type { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  ColorSelector,
  Column,
  Row,
  SSButton,
  Text
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import type { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import classNames from 'classnames'
import { Link, navigate } from 'gatsby'
import { get, set } from 'local-storage'
import { Either, List, Maybe } from 'monet'
import cond from 'ramda/src/cond'
import propOr from 'ramda/src/propOr'
import T from 'ramda/src/T'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulButton } from '../../../graphql'
import AddToCartError, {
  AddToCartErrorType
} from '../../errorComponents/AddToCartError'
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount'
import { TrackEvent } from '../../util/analytics'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { trackProductDetailEvent } from '../../util/analytics/details'
import { leadingSlashIt, toButton } from '../../util/helper'
import ButtonComponent from '../ButtonComponent'
import ContentfulRichText from '../ContentfulRichText'
import { PageProps } from '../Page'
import ProductHeroAddon from './ProductHeroAddon'
import ProductHeroPrice from './ProductHeroPrice'
import type {
  AdditionalLinkData,
  ProductData,
  ProductPageHeroContentColumnProps,
  ProductVariationData
} from './types'

const isProductTrackEvent = { currentPath: '' }

const ignoreSystemFlag = 'ignoreSystemWarningInCart'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
// @ts-expect-error TS(2769) FIXME: No overload matches this call.
const isButtonComponent = (
  link: AdditionalLinkData
): link is ContentfulButton =>
  path(['internal', 'type'], link) === 'ContentfulButton'

export const setIgnoreFlags = (location: PageProps['location']) => {
  const locationHash = safeProp('hash', location).orJust('')

  const [key, value] = locationHash
    .substr(1, locationHash.length - 1)
    .split('=')

  key === 'utm_content' &&
    ['outdoorcamCRM'].includes(value) &&
    set(ignoreSystemFlag, true)
}

export const getAddToCartButtonWidth = (
  isTabletUp: boolean,
  hasAdditionalLinks: boolean
): SSButtonProps['minWidth'] =>
  // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'MinWidth... Remove this comment to see the full error message
  cond<SSButtonProps['minWidth']>([
    [() => isTabletUp, () => 'medium'],
    [() => hasAdditionalLinks, () => 'small'],
    [T, () => 'full']
  ])()

const linksDisclaimerCustomOptions = (productData: ProductData): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) =>
      children && (
        <span
          className={classNames(
            'textSizeSM',
            `${productData?.linksDisclaimerTextColor}TextColor`
          )}
        >
          {children}
        </span>
      )
  }
})

const renderColorSelector = (
  colorVariationData: ReadonlyArray<ProductVariationData>,
  selectedIndex: number,
  selectIndex: (x: number) => void,
  id?: string | null
) => (
  <ColorSelector
    handleClick={selectIndex}
    id={`color-selector-${id}`}
    key={id}
    options={colorVariationData.map(v => {
      const description = v?.description
      const raw = description?.raw
      const references = description?.references
      return {
        color: toColorSelectorColor(v.key),
        description: description ? (
          <ContentfulRichText raw={raw} references={references} />
        ) : (
          ''
        )
      }
    })}
    selectedIndex={selectedIndex}
  />
)

export const renderAdditionalLink = (
  link: AdditionalLinkData
): ReactElement => {
  const buttonProps = isButtonComponent(link) ? toButton(link) : undefined

  // TODO fix type
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const linkText: string | undefined = prop('linkText', link)
  // TODO fix type
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const linkUrl: string | undefined = path(['linkItem', 'pageUrl'], link)

  const buttonUrl = safeProp('href', buttonProps).orNull()
  const buttonText = isButtonComponent(link) ? buttonProps?.children : undefined

  return (
    <>
      {buttonText && buttonUrl && (
        <SSButton
          {...buttonProps}
          onClick={() => navigate(leadingSlashIt(buttonUrl))}
        >
          {buttonText}
        </SSButton>
      )}
      {linkText && linkUrl && (
        <SSButton color="link" type="div">
          <Link to={leadingSlashIt(linkUrl)}>{linkText}</Link>
        </SSButton>
      )}
    </>
  )
}

const productDetailEvent = (
  trackEvent: TrackEvent,
  selectedProduct: Either<Error, Product>,
  currentPath: string
) => {
  setTimeout(() => {
    trackProductDetailEvent(selectedProduct, trackEvent)
  }, 2000)
  isProductTrackEvent.currentPath = currentPath
}

function ProductPageHeroContentColumn({
  additionalLinks,
  addToCartButtonData,
  addToMiniCartButtonData,
  colorVariationData,
  columnSpans,
  id,
  location,
  productAddonData: productAddonDataProp,
  productData,
  onSelectVariationIndex,
  outOfStockButtonText,
  selectedVariationIndex,
  textColor
}: ProductPageHeroContentColumnProps) {
  const productAddonData = productAddonDataProp || {}
  const hasProductAddon = !!productAddonDataProp
  const hasColorVariations = !!colorVariationData.length

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const dispatch = useDispatch()
  const { Track, trackEvent } = useTracking()
  const isTabletUp = useMediaQuery('TabletAndUp')
  const [productQuantity, setProductQuantity] = useState(1)
  const [addonQuantity, setAddonQuantity] = useState(0)
  const [addToCartError, setAddToCartError] = useState<AddToCartErrorType>(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const locationRoute = useLocation()
  const currentPath = locationRoute.pathname

  const _productId: string = safeProp('productId', productData).orJust(
    'missing productId for ProductPageHero product'
  )
  const _addonProductId: string = safeProp(
    'productId',
    productAddonData
  ).orJust('missing productId for ProductPageHero product addon')
  // TODO: fix type
  // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
  const _selectedVariationProductId: string = safeNth(
    selectedVariationIndex,
    colorVariationData.map(prop('productId'))
  )
    .chain(id => Maybe.fromNull(id))
    .orJust('missing productId for selected ProductPageHero color variation')

  // Disabling selector error logging here; errors will be logged in a useEffect hook below
  const _product = useSelector(selectProduct(_productId, false))
  const _selectedVariationProduct = useSelector(
    selectProduct(_selectedVariationProductId, false)
  )
  const addonProduct = useSelector(selectProduct(_addonProductId, false))

  // If we have data for color variations, use the selected one; otherwise use the base product id
  const selectedProduct = hasColorVariations
    ? _selectedVariationProduct
    : _product

  // If the selected product is missing from redux, log an error
  useEffectAfterMount(() => {
    selectedProduct.forEachLeft(e => {
      logError(e)
    })
  }, [selectedProduct])

  // If we have product addon data from Contentful, but it's missing from Redux, log an error
  useEffectAfterMount(() => {
    hasProductAddon &&
      addonProduct.forEachLeft(e => {
        logError(e)
      })
  }, [addonProduct, hasProductAddon])

  // A hack to also add the customer group for /live-guard-protection-beta page
  // hero when add to cart is clicked.
  const maybeAddToWatchtowerBeta = () => {
    currentPath === '/live-guard-protection-beta' &&
      dispatch(IOCreateOrUpgradeCart(get('userId'), 'watchtowerBeta'))
  }

  const handleCartSuccess = useCallback(
    (quantity: number, url?: string | null) => {
      setShowSpinner(false)
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(selectedProduct, trackEvent, quantity)
      maybeAddToWatchtowerBeta()
      Maybe.fromNull(url).forEach(_url => {
        navigate(leadingSlashIt(_url))
      })
    },
    [selectedProduct, trackEvent, optimizelyTrackSiteEvents]
  )

  const handleMiniCartSuccess = useCallback(
    (url?: string | null, miniCartItemSku?: string, quantity?: number) => {
      setShowSpinner(false)
      optimizelyTrackSiteEvents({ eventType: 'add_to_mini_cart_clicked' })

      Maybe.fromNull(url).forEach(_url => {
        navigate(leadingSlashIt(_url), {
          state: {
            miniCartItem: {
              maxQuantity: productData.maxQuantity,
              quantity,
              sku: miniCartItemSku
            }
          }
        })
      })
    },
    [productData, optimizelyTrackSiteEvents]
  )

  const handleCartFailure = useCallback(() => {
    setShowSpinner(false)
    setAddToCartError('recoverable')
    optimizelyTrackSiteEvents({ eventType: 'website_error' })
  }, [optimizelyTrackSiteEvents])

  const handleCartInvalidProducts = useCallback((e: Error) => {
    setShowSpinner(false)
    setAddToCartError('unrecoverable')
    logError(Error(`Cannot add to cart: ${e.message}`))
  }, [])

  const handleMiniCartInvalidProducts = useCallback((e: Error) => {
    setShowSpinner(false)
    logError(Error(`Cannot add to minicart: ${e.message}`))
  }, [])

  const addToCartProductBody: ReadonlyArray<ProductBody> = useMemo(() => {
    const products = [
      {
        quantity: productQuantity,
        sku: selectedProduct.cata(
          () => null,
          p => p.masterSku
        )
      },
      {
        quantity: addonQuantity,
        sku: addonProduct.cata(
          () => null,
          p => p.masterSku
        )
      }
    ]

    return products.filter((p): p is ProductBody => !!p.sku && p.quantity > 0)
  }, [addonProduct, productQuantity, addonQuantity, selectedProduct])

  // Handle adding the selected product (and product addon, if applicable) to the cart
  const onAddToCart = useCallback(
    (quantity: number, url?: string | null) => {
      setShowSpinner(true)
      setAddToCartError(null)
      const selectedProductList = List.from([selectedProduct])
      const productList = hasProductAddon
        ? selectedProductList.concat(List.from([addonProduct]))
        : selectedProductList

      setIgnoreFlags(location)

      productList
        .sequenceEither<Error, List<Product>>()
        .cata(handleCartInvalidProducts, () =>
          dispatch(
            IOAddToCart(
              { products: addToCartProductBody },
              handleCartFailure,
              () => {
                handleCartSuccess(quantity, url)
              }
            )
          )
        )
    },
    [
      addToCartProductBody,
      addonProduct,
      hasProductAddon,
      selectedProduct,
      dispatch,
      handleCartSuccess,
      handleCartFailure,
      handleCartInvalidProducts,
      location
    ]
  )

  // Handle adding the selected product to the Mini Cart
  const onAddToMiniCart = useCallback(
    (quantity: number, url?: string | null) => {
      selectedProduct.cata(handleMiniCartInvalidProducts, product =>
        handleMiniCartSuccess(url, product.sku, quantity)
      )
    },
    [selectedProduct, handleMiniCartSuccess, handleMiniCartInvalidProducts]
  )
  prop('currentPath', isProductTrackEvent) !== currentPath &&
    productDetailEvent(trackEvent, selectedProduct, currentPath)

  const isSellable = selectedProduct.cata(
    () => true,
    p => (prop('isSellable', p) ? true : false)
  )
  const addToCartButtonProps = Maybe.fromFalsy(isSellable).cata(
    () => ({
      ...toButton(addToCartButtonData),
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      children: outOfStockButtonText
        ? outOfStockButtonText
        : propOr<string, string>('', 'buttonText', addToCartButtonData),
      disabled: true
    }),
    // @ts-expect-error TS(2322) FIXME: Type 'SSButtonProps' is not assignable to type '{ ... Remove this comment to see the full error message
    () => toButton(addToCartButtonData)
  )

  const linksOptionsCustom = linksDisclaimerCustomOptions(productData)

  const isMiniCartBtnForCart = prop('addToCartButton', addToMiniCartButtonData)
    ? true
    : false

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <Column rounded={'none'} spans={columnSpans}>
        <Row
          padding={isTabletUp ? 'none' : 'large'}
          rounded={'none'}
          textColor={textColor}
        >
          {productData.description && (
            <Column rounded={'none'}>
              <ContentfulRichText raw={productData.description} />
            </Column>
          )}
          {!productData?.hidePriceAndBuyBox && (
            <Column rounded={'none'}>
              <ProductHeroPrice
                isSellable={isSellable}
                product={selectedProduct}
                productData={productData}
                quantity={productQuantity}
                setQuantity={setProductQuantity}
              />
            </Column>
          )}
          {addonProduct.cata(
            () => null,
            () => (
              <Column rounded={'none'}>
                <ProductHeroAddon
                  isSellable={isSellable}
                  product={addonProduct}
                  productAddonData={productAddonData}
                  quantity={addonQuantity}
                  setQuantity={setAddonQuantity}
                />
              </Column>
            )
          )}
          {hasColorVariations && (
            <Column rounded={'none'}>
              {renderColorSelector(
                colorVariationData,
                selectedVariationIndex,
                onSelectVariationIndex,
                id
              )}
            </Column>
          )}
          <Column>
            <div className="flex flex-wrap gap-2">
              {addToCartButtonData && (
                <SSButton
                  {...addToCartButtonProps}
                  minWidth={getAddToCartButtonWidth(
                    isTabletUp,
                    !!additionalLinks.length
                  )}
                  onClick={() =>
                    onAddToCart(productQuantity, addToCartButtonData.url)
                  }
                  showSpinner={showSpinner}
                />
              )}
              {addToMiniCartButtonData && (
                <ButtonComponent
                  data={{
                    ...addToMiniCartButtonData,
                    // Remove URL if we're adding to cart; instead the URL will
                    // be navigated via onAddToMiniCart. If not adding to cart,
                    // proceed with the url set as normal.
                    url: isMiniCartBtnForCart
                      ? null
                      : addToMiniCartButtonData?.url
                  }}
                  onClick={() =>
                    isMiniCartBtnForCart &&
                    onAddToMiniCart(
                      productQuantity,
                      addToMiniCartButtonData.url
                    )
                  }
                  showSpinner={isMiniCartBtnForCart && showSpinner}
                />
              )}
              <Text>{additionalLinks}</Text>
            </div>
          </Column>
          <Column>
            <AddToCartError errorType={addToCartError} />
          </Column>
          {productData?.linksDisclaimer && (
            <Column>
              <ContentfulRichText
                optionsCustom={linksOptionsCustom}
                raw={productData?.linksDisclaimer}
              />
            </Column>
          )}
        </Row>
      </Column>
    </Track>
  )
}

export default ProductPageHeroContentColumn
