import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, Row, SSButton } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  ContentfulLinkAddToCart,
  ContentfulRichTextWithOptions
} from '../../../graphql'
import { usePriceContext } from '../../providers/PriceProvider'
import { localizeCents } from '../../providers/PriceProvider/utils'
import type { TrackEvent } from '../../util/analytics'
import { replaceTokensWithPrice } from '../../util/monitoring-price-placeholders'
import ContentfulRichText from '../ContentfulRichText'
import ModalComponent from '../ModalComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import richTextWithOptionsFallback from '../RichTextWithOptionsComponent/richTextWithOptionsFallback'
import { getAddPlanButton } from './'
import { modalClickTargetGTM } from './trackingEvents'

export const inlineParagraphOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) =>
      children && (
        <p className={classNames('inline text-[18px] leading-6')}>{children}</p>
      )
  }
}

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
export const renderModalContent = (
  topRichText: string | undefined,
  leftRichTextWithOptions: ContentfulRichTextWithOptions,
  rightRichTextWithOptions: ContentfulRichTextWithOptions,
  bottomRichTextWithOptions: ContentfulRichTextWithOptions,
  acceptButton?: Partial<ContentfulLinkAddToCart> | undefined
) => {
  return (
    <div data-compoment="LimitedProMonitoringModal" style={{ padding: '50px' }}>
      <Column spans={[6, 6, 6]}>
        {topRichText && (
          <Row>
            <Column spans={[12, 12, 12]}>
              <ContentfulRichText raw={topRichText} />
            </Column>
          </Row>
        )}
        <Row gap="none">
          <Column alignSelf={'start'} spans={[6, 6, 6]}>
            <Column spans={[12, 12, 12]}>
              <RichTextWithOptionsComponent data={leftRichTextWithOptions} />
            </Column>
          </Column>
          <Column alignSelf={'start'} spans={[6, 6, 6]}>
            <Column spans={[12, 12, 12]}>
              <RichTextWithOptionsComponent data={rightRichTextWithOptions} />
            </Column>
            {acceptButton && (
              <Column spans={[12, 12, 12]}>
                <div
                  style={{
                    margin: '0 auto',
                    minWidth: '156px',
                    textAlign: 'center',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {getAddPlanButton(acceptButton)}
                </div>
              </Column>
            )}
          </Column>
        </Row>
        <Row>
          <Column spans={[12, 12, 12]}>
            <RichTextWithOptionsComponent data={bottomRichTextWithOptions} />
          </Column>
        </Row>
      </Column>
    </div>
  )
}

type LimitedProMonitoringModalProps = {
  readonly limitedProMonitoringSku: string
  readonly trackEvent: TrackEvent
  readonly modalClickTargetText?: string
  readonly limitedProMonitoringCTA?: string
  readonly topRichText?: string
  readonly leftRichTextWithOptions?: ContentfulRichTextWithOptions
  readonly rightRichTextWithOptions?: ContentfulRichTextWithOptions
  readonly bottomRichTextWithOptions?: ContentfulRichTextWithOptions
  readonly acceptButton?: Partial<ContentfulLinkAddToCart> | undefined
}

function LimitedProMonitoringModal({
  limitedProMonitoringSku,
  trackEvent,
  modalClickTargetText,
  limitedProMonitoringCTA,
  topRichText,
  leftRichTextWithOptions,
  rightRichTextWithOptions,
  bottomRichTextWithOptions,
  acceptButton
}: LimitedProMonitoringModalProps) {
  const locale = useSelector(selectLocale)
  const { getPrice } = usePriceContext()
  const price = getPrice(limitedProMonitoringSku).cata(
    () => 0,
    price => price
  )

  const leftText = leftRichTextWithOptions?.richText?.raw
  const leftTextTokenized = leftText && replaceTokensWithPrice(leftText, price)

  return (
    <div
      style={{
        border: '1px solid #ebeae8',
        margin: '0 auto',
        maxWidth: '1080px',
        padding: '20px 40px'
      }}
    >
      {
        <>
          <ContentfulRichText
            optionsCustom={inlineParagraphOptions}
            raw={limitedProMonitoringCTA}
          />
          <ModalComponent
            clickTarget={
              <span style={{ display: 'inline' }}>
                <SSButton
                  className={'w-auto p-0  text-[18px] text-left'}
                  color="link"
                  onClick={() =>
                    modalClickTargetGTM(
                      trackEvent,
                      `${localizeCents(locale, price)}/day`
                    )
                  }
                >
                  {replaceTokensWithPrice(modalClickTargetText || '', price)}
                </SSButton>
              </span>
            }
            modalContent={renderModalContent(
              topRichText,
              richTextWithOptionsFallback(
                leftRichTextWithOptions && {
                  ...leftRichTextWithOptions,
                  richText: {
                    ...leftRichTextWithOptions['richText'],
                    raw: leftTextTokenized
                  }
                }
              ),
              richTextWithOptionsFallback(rightRichTextWithOptions),
              richTextWithOptionsFallback(bottomRichTextWithOptions),
              acceptButton
            )}
            size={'medium'}
          />
        </>
      }
    </div>
  )
}

export default LimitedProMonitoringModal
