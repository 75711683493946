// START: ECP-5444 - BMS A/B test
// TODO: this should be temporary and should be ripped out when the BMS A/B test is complete.

import { createInstance } from '@lib/optimizely'
import { fetchAtAtVisitorId, visitorIdAtAt } from '@lib/tracking'
import { userAttributes } from '@lib/tracking'
import { getDeployEnv } from '@lib/utils'
import { globalHistory, Redirect, useLocation } from '@reach/router'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { exists, window } from 'browser-monads-ts'
import { get } from 'local-storage'
import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Cookies from 'universal-cookie'

export const COOKIE_OPTIMIZELY_EXCLUDED = 'optimizely_excluded'
export const BMS_EXPERIMENT_KEY = 'all___us_bms___bms_page_overhaul'
export const BMS_CONTROL_URL = '/build-my-system'
export const BMS_CONTROL_REGEX = /\/build-my-system\/?$/
export const BMS_VARIATION_URL = '/build-my-system2'
export const BMS_VARIATION_REGEX = /\/build-my-system2\/?$/

const isBmsUrl = (pathname: string) =>
  [BMS_CONTROL_URL, BMS_VARIATION_URL].some(url => pathname.includes(url))

/**
 * Exported for testing.
 * Activates the BMS Optimizely experiment if on a BMS page and not excluded. Returns whether
 * the test was activated, and the url the user should be redirected to (or null if no redirect
 * is needed).
 */
export const activateBmsRedirectExperiment = (
  pathname: string,
  userId: string,
  datafile: Record<string, string>
) => {
  const cookies = new Cookies()
  const isExcluded = !!cookies.get<string>(COOKIE_OPTIMIZELY_EXCLUDED)
  const isPreProd = getDeployEnv() !== 'prd'

  const optimizelyInstance = createInstance({
    datafile,
    datafileOptions: { autoUpdate: false },
    // don't batch events
    eventBatchSize: 1,
    eventFlushInterval: 1
  })

  const shouldActivateTest = !isExcluded && isBmsUrl(pathname)

  // For testing purposes in qa/stg only, set `bms_version` in local storage with either `control` or `variation_1`

  const forcedVariation = get<string>('bms_version')
  isPreProd &&
    shouldActivateTest &&
    forcedVariation &&
    optimizelyInstance.setForcedVariation(
      BMS_EXPERIMENT_KEY,
      userId,
      forcedVariation
    )

  const variationId = shouldActivateTest
    ? optimizelyInstance.activate(BMS_EXPERIMENT_KEY, userId, userAttributes())
    : null

  const redirectUrl =
    BMS_CONTROL_REGEX.test(pathname) && variationId === 'variation_1'
      ? BMS_VARIATION_URL
      : BMS_VARIATION_REGEX.test(pathname) &&
        (variationId === 'control' || !variationId)
      ? BMS_CONTROL_URL
      : null

  return {
    redirectUrl,
    testActivated: shouldActivateTest
  }
}

function BmsLoadingSkeleton({
  redirectUrl = null
}: {
  readonly redirectUrl?: string | null
}) {
  return (
    <div
      className="prose md:prose-md lg:prose-lg p-4 md:p-8 lg:p-16"
      data-testid="loading-skeleton"
    >
      <div className="mb-4 md:mb-8">
        <h1>
          <Skeleton />
        </h1>
        <p>
          <Skeleton count={3} />
        </p>
      </div>
      {Array.from(Array(4)).map((_, i) => (
        <div className="my-4 md:my-8" key={`bms-skeleton-${i}`}>
          <Skeleton count={4} />
        </div>
      ))}
      {/* reach-router's Redirect component claims it *should* work without the `noThrow` prop, but
       * it completely broke when I tried it that way, and the way that the redirect works under the
       * hood without the `noThrow` prop is to throw an error that must be re-thrown if caught, which
       * seems non-ideal for New Relic. -csims*/}
      {redirectUrl && <Redirect noThrow to={redirectUrl} />}
    </div>
  )
}

/**
 * Called from gatsby-browser.ts to activate the experiment & perform the redirect as quickly as possible
 * if an initial/full page happens on the BMS page.
 * Redirect will only occur if a user has visited the site previously (i.e. atat token is present). Otherwise
 * the client-side context will poll and wait for the cookie to be set (see useBmsTestRedirect below)
 */
export const navigateForBmsTest = (datafile: Record<string, string>) => {
  const userId = visitorIdAtAt()
  const { redirectUrl } = userId
    ? activateBmsRedirectExperiment(window.location.pathname, userId, datafile)
    : { redirectUrl: null }
  redirectUrl && globalHistory.navigate(redirectUrl, { replace: true })
}

/**
 * A hook to activate the experiment on the BMS page, render a loading skeleton, and perform an in-app redirect if needed.
 * Returns a Redirect component if a redirect is needed, or null otherwise.
 */
export const useBmsTestRedirect = (datafile: Record<string, string>) => {
  const { pathname } = useLocation()
  const isSSR = !exists(window)
  const isBms = isBmsUrl(pathname)
  const [isLoading, setLoading] = useState(true)
  const [experimentLoaded, setExperimentLoaded] = useState(false)
  const [userId, setUserId] = useState<string>()
  const { redirectUrl, testActivated } = useMemo(
    () =>
      !isSSR && isBms && userId
        ? activateBmsRedirectExperiment(pathname, userId, datafile)
        : {
            redirectUrl: null,
            testActivated: false
          },
    [pathname, isSSR, isBms, userId]
  )

  // Ensures that we're not prematurely firing the Optimizely decision (similar to our wrap-with-context implementation)
  fetchAtAtVisitorId()
    .then(id => setUserId(id))
    .catch(logError)

  useEffect(() => {
    testActivated && setExperimentLoaded(true)
  }, [testActivated])

  // This timer is a dumb workaround to force the loading skeleton to render a little bit longer.
  // The timeout length can be tweaked, but without it, we sometimes end up with a brief flash of
  // the original page before the new page appears.
  useEffect(() => {
    redirectUrl && !experimentLoaded && setLoading(true)

    const timer = setTimeout(() => {
      setLoading(false)
    }, 100)

    return () => clearTimeout(timer)
  }, [pathname, redirectUrl, experimentLoaded])

  const renderLoadingSkeleton = isBms && (isLoading || !!redirectUrl)

  return renderLoadingSkeleton ? (
    <BmsLoadingSkeleton redirectUrl={redirectUrl} />
  ) : null
}

// END: ECP-5444 - BMS A/B test
