import { safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'

import DynamicPackageMiniCart from '../components/DynamicPackageMiniCart'
import Page, { PageProps } from '../components/Page'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'

export type PackageLineItem = {
  readonly isAutomaticallyIncluded: boolean
  readonly masterSku: string
  readonly quantity: number
}

export default function DynamicPackagePage({
  data,
  location,
  pageContext,
  params
}: PageProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const attributeHash = propOr<string, string>('', 'payload', params)

  return (
    <>
      <DynamicPackageMiniCart attributeHash={attributeHash} />
      <SiteWideMessagesContext.Provider
        value={safeProp('contentfulSiteWideMessages', data).orJust({})}
      >
        <Page data={data} location={location} pageContext={pageContext} />
      </SiteWideMessagesContext.Provider>
    </>
  )
}

// the #graphql tag is required for eslint and GraphQL for VSCode

export const query = graphql`
  #graphql
  query DynamicPackagePageQuery($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulPage(id: { eq: $id }) {
      ...pageFragment
    }
    # Country Redirect Modal
    # https://app.contentful.com/spaces/br4ichkdqihc/entries/5XAuk7m84n6SKiMzt4hdfy
    contentfulSmallTextSection(
      contentful_id: { eq: "5XAuk7m84n6SKiMzt4hdfy" }
    ) {
      # id
      description {
        raw
        references {
          ... on ContentfulHeading {
            ...headingFragment
          }
          ... on ContentfulButton {
            ...contentfulButtonFragment
          }
        }
      }
    }
  }
`
