import prop from '@simplisafe/ewok/ramda/prop'
import { Text } from '@simplisafe/ss-react-components'
import React from 'react'

import { ContentfulProductInformation } from '../../../../graphql'
import usePriceVariation from '../../../hooks/usePriceVariation'
import { replaceTokensWithPrice } from '../../../util/monitoring-price-placeholders'
import ContentfulRichText from '../../ContentfulRichText'

export type CartLineItemDescriptionProps = {
  readonly onClick: (_url: string) => void
  readonly productInformation: ContentfulProductInformation
  readonly sku: string
}

function CartLineItemDescription({
  onClick,
  productInformation,
  sku
}: CartLineItemDescriptionProps) {
  const planProduct = usePriceVariation(sku)
  const planProductPrice = planProduct.cata(
    () => 0,
    value => prop('price', value)
  )
  const description =
    productInformation.description?.raw &&
    replaceTokensWithPrice(
      productInformation.description?.raw,
      planProductPrice
    )
  const references = productInformation.description?.references || []

  return (
    <div data-component="CartLineItemDescriptionWrapper">
      <Text>
        <ContentfulRichText
          onLinkClick={(e, uri) => {
            e.preventDefault()
            onClick && onClick(uri)
          }}
          raw={description}
          // @ts-expect-error TS(2322): Type 'readonly Maybe<ContentfulAssetContentfulLink... Remove this comment to see the full error message
          references={references}
        />
      </Text>
    </div>
  )
}

export default CartLineItemDescription
