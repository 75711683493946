import { ContentfulRichText } from '@lib/components'
import {
  COOKIE_LEAD_DATA,
  cookies,
  getLeadData,
  useCookieChange,
  useOptimizelyTrackSiteEvents,
  handleBrazeTrackingEvent
} from '@lib/tracking'
import { fbTrackLeadCreated } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { IOSaveMySystem } from '@simplisafe/ss-ecomm-data/cart'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import {
  leadGenCapture,
  LeadGenCaptureParams,
  LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/leads/capture'
import {
  selectActivePromoCode,
  selectMiniCartLineItems
} from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import {
  LoadingSpinner,
  Modal,
  SSButton,
  SSInput,
  Text
} from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maybe } from 'monet'
import contains from 'ramda/src/contains'
import propOr from 'ramda/src/propOr'
import React, { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulModalSaveYourSystem } from '../../../graphql'
import { trackSubmitLeadEvent } from '../../util/analytics'
import { nullToUndefined, toButton } from '../../util/helper'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
export type SaveMySystemModalProps = {
  readonly data: ContentfulModalSaveYourSystem
}

const replaceEmailAddressPlaceholder = (
  raw: string,
  emailAddress: string
): string => raw.replace(/{{email_address}}/g, emailAddress)

const mapLineItemToRequestBody = (item: MiniCartLineItem) => ({
  quantity: item.quantity,
  sku: item.sku
})

function SaveMySystemModalComponent({ data }: SaveMySystemModalProps) {
  const leadDataCookie = getLeadData()
  const [isOpen, setIsOpen] = useState(false)

  const [emailAddress, setEmailAddress] = useState(
    propOr('', 'email', leadDataCookie)
  )
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [inputError, setInputError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {
    openModalButton,
    emailTextbox,
    headerText,
    image,
    description,
    saveMySystemButton,
    disclaimerNote,
    successMessage
  } = data

  const promoCode = useSelector(selectActivePromoCode)
  useCookieChange(COOKIE_LEAD_DATA, data =>
    setEmailAddress(propOr('', 'email', JSON.parse(data)))
  )
  const lineItems = useSelector(selectMiniCartLineItems)
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const dependentProduct = prop('dependentProduct', data) || []
  const inputHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setEmailAddress(e.target.value)
  }
  const handleSubmit = () => {
    // These validation keys are defined in Contentful
    const defaultValidations = {
      'Invalid Input': 'Please enter a valid email address.',
      'Required Field': 'Please enter a valid email address.'
    }
    const validations = (
      (emailTextbox && emailTextbox.fieldValidation) ||
      []
    ).reduce((obj, validation) => {
      return validation && validation.requirement && validation.errorMessage
        ? {
            ...obj,
            [validation.requirement]: validation.errorMessage
          }
        : obj
    }, defaultValidations)

    const leadBody: LeadGenCaptureParams = {
      // @ts-expect-error TS(2322) FIXME: Type '() => never' is not assignable to type 'stri... Remove this comment to see the full error message
      email: emailAddress,
      leadSource: 'exit_intent',
      promoCode: promoCode.getOrElse('NO_CODE'),
      source: 'bms quote',
      sourceType: 'save_system'
    }

    const handleLeadError = () => {
      setIsLoading(false)
      setInputError(validations['Invalid Input'])
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const handleLeadSuccess = (e: Maybe<LeadGenCaptureResponse>) => {
      setIsLoading(false)
      cookies.set(COOKIE_LEAD_DATA, e.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(e.orUndefined())
      const externalId = e.chain(safeProp('externalId')).orJust('')
      const leadId = e.chain(safeProp('leadId')).orJust('')
      const saveMySystemBody = {
        email: emailAddress,
        externalId: externalId,
        leadId: leadId,
        products: lineItems.map(mapLineItemToRequestBody)
      }

      // Don't wait for success/failure of IOSaveMySystem call, and immediately assume email is sent to reduce UI loading time
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ email: () => never; externalId... Remove this comment to see the full error message
      dispatch(
        IOSaveMySystem(
          saveMySystemBody,
          () => null,
          () => null
        )
      )
      setIsEmailSent(true)
      optimizelyTrackSiteEvents({ eventType: 'saved_system' })
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackSubmitLeadEvent(trackEvent)
      e.forEach(
        async response =>
          response.email && (await fbTrackLeadCreated(response.email))
      )
    }

    const sendLeadsRequest = () => {
      setIsLoading(true)
      leadGenCapture(leadBody)(handleLeadError)(handleLeadSuccess)
    }

    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    emailAddress === ''
      ? setInputError(validations['Required Field'])
      : sendLeadsRequest()
  }

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const isOnlyDependentProduct = lineItems.find(
    item => item && !contains({ sku: item.masterSku }, dependentProduct)
  )
  const floatingButton = isOnlyDependentProduct && openModalButton && (
    <div
      key={'floating-button-container'}
      style={{
        borderRadius: '10px',
        boxShadow: '-2px 2px 6px 0 rgba(0,0,0,.18)',
        margin: '15px 20px',
        position: 'fixed',
        right: '10px',
        top: '170px',
        zIndex: 15
      }}
    >
      <SSButton
        {...toButton(openModalButton)}
        //We must change this btn color by using tw classes in main (and also try to replace SSButton by SimpleButton)
        buttonColor="var(--neutral-light-50)"
        className={'m0'}
        key={'floating-button'}
        onClick={() => setIsOpen(true)}
      />
    </div>
  )

  const submissionForm = (
    <div>
      {emailTextbox && (
        <div style={{ margin: '16px 0' }}>
          <SSInput
            error={inputError !== ''}
            errorMsg={inputError}
            id={emailTextbox.id}
            label={emailTextbox.title}
            onChange={inputHandleChange}
            placeholder={nullToUndefined(emailTextbox.placeholderText)}
            type="email"
            // @ts-expect-error TS(2322) FIXME: Type '() => never' is not assignable to type 'stri... Remove this comment to see the full error message
            value={emailAddress}
          />
        </div>
      )}
      {saveMySystemButton && (
        <SSButton {...toButton(saveMySystemButton)} onClick={handleSubmit} />
      )}
      <Text textSize="sm">
        {disclaimerNote && <ContentfulRichText raw={disclaimerNote.raw} />}
      </Text>
    </div>
  )

  const successMessageRaw = successMessage?.raw
  const successView = successMessageRaw ? (
    <div style={{ margin: '16px 0' }}>
      {/* @ts-expect-error TS(2345) FIXME: Argument of type '() => never' is not assignable t... Remove this comment to see the full error message */}
      {
        <ContentfulRichText
          raw={replaceEmailAddressPlaceholder(successMessageRaw, emailAddress)}
        />
      }
    </div>
  ) : null

  const loading = (
    <div
      className="m2_t"
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <LoadingSpinner />
    </div>
  )

  const modal = (
    <Modal
      appElementId="___gatsby"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={{ content: { width: '640px' } }}
    >
      {image && (
        <GatsbyImage
          alt={image?.description || ''}
          // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
          image={getImage(image)}
        />
      )}
      <div style={{ padding: '16px 64px 50px 64px' }}>
        <Text>
          <h3>{headerText}</h3>
          {description && <ContentfulRichText raw={description.raw} />}
        </Text>
        {!isEmailSent ? (isLoading ? loading : submissionForm) : successView}
      </div>
    </Modal>
  )

  return (
    <section key={'save-my-system-modal-container'}>
      {floatingButton}
      {modal}
    </section>
  )
}

export default SaveMySystemModalComponent

export const SaveMySystemModalQuery = graphql`
  #graphql
  fragment saveMySystemModal on ContentfulModalSaveYourSystem {
    id
    internal {
      type
    }
    image {
      description # TODO get description from gatsbyImageData
      gatsbyImageData(layout: CONSTRAINED, width: 768, placeholder: BLURRED)
    }
    headerText
    description {
      raw
    }
    dependentProduct {
      sku
    }
    emailTextbox {
      ... on ContentfulForms {
        id
        title
        placeholderText
        fieldValidation {
          requirement
          errorMessage
        }
      }
    }
    saveMySystemButton {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    successMessage {
      raw
    }
    disclaimerNote {
      raw
    }
    openModalButton {
      ... on ContentfulButton {
        ...contentfulButtonFragment
        buttonColor
        textColor
      }
    }
  }
`
