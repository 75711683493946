import { ProgressBar } from '@simplisafe/ss-react-components'
import React from 'react'

type PropertyStatsBarProps = {
  readonly highlight: boolean
  readonly label: string
  readonly barClassName: string
  readonly barFillClassName: string
  readonly barLabel: string
  readonly arrowOnly?: boolean
}

function PropertyStatsBar({
  highlight,
  label,
  barClassName,
  barFillClassName,
  barLabel,
  arrowOnly = false
}: PropertyStatsBarProps) {
  return (
    <>
      <div
        className={`col-span-2 xl:col-span-1 text-xs mx-auto mt-2 ${
          highlight ? 'font-bold' : ''
        }`}
      >
        {label}
      </div>
      <div className="col-span-3 xl:col-span-4 my-2 flex items-center justify-between">
        <ProgressBar
          className={`h-5 inline-block ${barClassName}`}
          fillClassName={`flex items-center justify-start ${barFillClassName}`}
          innerLabel={barLabel}
          innerLabelClassName="pl-2 text-white drop-shadow text-xs"
          percent={100}
        />
        {highlight && (
          <div className="animate-pulse inline-block font-bold text-xs">
            &larr; {!arrowOnly ? 'Your Neighborhood' : ''}
          </div>
        )}
      </div>
    </>
  )
}

export default PropertyStatsBar
