import React, { ReactNode } from 'react'

import CartWarningMessageItem from './CartWarningMessageItem'

export type CartWarningMessagesProps = {
  readonly quantityLimitMessage?: ReactNode
  readonly withoutSystemMessage?: ReactNode
  readonly mixedComponentsMessage?: ReactNode
  readonly showQuantityLimitMessage?: boolean
  readonly showWithoutSystemMessage?: boolean
  readonly showMixedComponentsWarning?: boolean
  readonly selfMonitoringInCart?: boolean
}

function CartWarningMessages({
  showQuantityLimitMessage,
  showWithoutSystemMessage,
  showMixedComponentsWarning,
  quantityLimitMessage,
  withoutSystemMessage,
  mixedComponentsMessage,
  selfMonitoringInCart
}: CartWarningMessagesProps) {
  return (
    <>
      {showQuantityLimitMessage && quantityLimitMessage && (
        <CartWarningMessageItem
          key="quantitylimit"
          message={quantityLimitMessage}
          showChangePlanLink={selfMonitoringInCart}
          showIcon={false}
        />
      )}
      {showWithoutSystemMessage && withoutSystemMessage && (
        <CartWarningMessageItem
          iconSrc="//images.ctfassets.net/br4ichkdqihc/1YbER6o3QQAGkeRvxBRSs/5a4dd142ebb4f0318dfdc4759be27856/Icon_-_Caution__Grey_.png"
          key="withoutsystem"
          message={withoutSystemMessage}
          showIcon
        />
      )}
      {showMixedComponentsWarning && mixedComponentsMessage && (
        <CartWarningMessageItem
          iconSrc="//images.ctfassets.net/br4ichkdqihc/1YbER6o3QQAGkeRvxBRSs/5a4dd142ebb4f0318dfdc4759be27856/Icon_-_Caution__Grey_.png"
          key="mixedcomponents"
          message={mixedComponentsMessage}
          showIcon
        />
      )}
    </>
  )
}

export default CartWarningMessages
