import React, { ReactNode } from 'react'

export type CartAffirmPromoMessageProps = {
  readonly content: ReactNode
}

function CartAffirmPromoMessage({ content }: CartAffirmPromoMessageProps) {
  return <div className="text-xs mb-0">{content}</div>
}

export default CartAffirmPromoMessage
