import { OrderedList } from '@simplisafe/ss-react-components'
import { path, prop } from 'ramda'
import React from 'react'

import ContentfulRichText from '../ContentfulRichText'

export type OrderedListContents = {
  readonly richText: {
    readonly raw: string
  }
}

export type OrderedListProps = {
  readonly data: {
    readonly contents: readonly OrderedListContents[]
    readonly listStyle: string
  }
}

export default function OrderedListComponent({ data }: OrderedListProps) {
  const contents = prop('contents', data)
  const listStyle = prop('listStyle', data)

  const richTextContents = contents.map(raw => {
    const content = path(['richText', 'raw'])(raw) as string
    return <ContentfulRichText raw={content} />
  })
  return (
    <OrderedList
      contents={richTextContents}
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"circular... Remove this comment to see the full error message
      listStyle={listStyle}
    />
  )
}
