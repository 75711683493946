import { ProgressBar, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type CrimeAssessmentItemProps = {
  readonly icon: JSX.Element
  readonly title: string
  readonly crimeScore: number
  readonly crimeNumber: number
  readonly description: string
  readonly className?: string
  readonly fillClassName?: string
}

function CrimeAssessmentItem({
  icon,
  title,
  crimeScore,
  crimeNumber,
  description,
  className = '',
  fillClassName = ''
}: CrimeAssessmentItemProps) {
  return (
    <>
      <div className={`flex items-center justify-start ${className}`}>
        {icon}
        <Text
          className="text-simplyblue-400 text-lg font-light ml-2"
          useTailwind
        >
          {title}
        </Text>
      </div>

      <ProgressBar
        className="h-1"
        fillClassName={fillClassName}
        percent={crimeScore}
      />

      <Text className="text-simplyblue-400 text-xs font-light" useTailwind>
        <strong>{crimeNumber}</strong> {description}
      </Text>
    </>
  )
}

export default CrimeAssessmentItem
