import { ContentfulRichTextWithOptions } from '../../../graphql'

const richTextWithOptionsFallback = (
  richTextWithOptions?: ContentfulRichTextWithOptions
): ContentfulRichTextWithOptions =>
  // @ts-expect-error TS(2322) FIXME: Type 'ContentfulRichTextWithOptions | { id: string... Remove this comment to see the full error message
  richTextWithOptions
    ? richTextWithOptions
    : {
        id: '',
        internal: { type: '' }
      }

export default richTextWithOptionsFallback
