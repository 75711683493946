import { GatsbyImage } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import { Column, Row } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulPrefectSystemBanner } from '../../../graphql'
import { renderComponentFromData } from '../../util/render'
import ContentfulRichText from '../ContentfulRichText'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
export type PerfectSystemBannerComponentProps = {
  readonly data: Partial<ContentfulPrefectSystemBanner>
}

export const toNumber = (n: number | undefined): number =>
  n === undefined ? NaN : n

export default function PerfectSystemBannerComponent({
  data
}: PerfectSystemBannerComponentProps) {
  const findYourSystemComponent = prop('findYourSystemComponent', data)
  const description = data?.description?.raw
  // @ts-expect-error TS(2339) FIXME: Property 'media' does not exist on type 'Partial<C... Remove this comment to see the full error message
  const image = data?.media?.image

  return (
    <Row inheritTextColor key={prop('id', data)} padding="small">
      <Column spans={[0, 5, 6]}>
        <GatsbyImage image={image} style={{ height: '100%' }} />
      </Column>
      <Column spans={[12, 7, 6]}>
        <ContentfulRichText raw={description} />
        {findYourSystemComponent &&
          renderComponentFromData(findYourSystemComponent)}
      </Column>
    </Row>
  )
}

export const perfectSystemFragment = graphql`
  #graphql
  fragment perfectSystemBanner on ContentfulPrefectSystemBanner {
    id
    internal {
      type
    }
    title
    description {
      raw
    }
    media: image {
      id
      image {
        title
        description
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1440
          cropFocus: RIGHT
          resizingBehavior: FILL
          placeholder: BLURRED
        )
      }
    }
    findYourSystemComponent {
      ...quoteWizard
    }
  }
`
