import { getDeployEnv } from '@lib/utils'
import { useLocation } from '@reach/router'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import { brazeTrackPageVisit, initBraze } from '../braze'
import {
  generateFacebookEventId,
  useFacebookTrackSiteVisits
} from '../facebook'
import { handleTrackingEvent } from '../handleTrackingEvent'
import { useOptimizelyTrackPageVisit } from '../optimizely'
import useReferrerUrl from './useReferrerUrl'

type Props = {
  readonly children: ReactNode
  readonly metaTitle: string
}

/**
 * TrackingProvider is a wrapper for react-tracking's tracking logic
 * and contains any events triggered on page visit
 */
export function TrackingProvider({ children, metaTitle }: Props) {
  const location = useLocation()
  const { href, pathname, search } = location
  const referrer = useReferrerUrl(location)

  const facebookEventId = generateFacebookEventId()
  const facebookTrackSiteVisits = useFacebookTrackSiteVisits()

  // The values below will be merged and included in any child component's
  // `trackEvent` calls (unless overridden there).
  // See https://github.com/nytimes/react-tracking#usage-with-react-hooks for docs on how to use
  // trackEvent and the Track component.
  const { Track, trackEvent } = useTracking(
    {
      environment: getDeployEnv(),
      facebookEventId,
      pagePath: pathname,
      pageTitle: metaTitle,
      queryString: search,
      referrer: referrer,
      site: 'fcp'
    },
    { dispatch: handleTrackingEvent }
  )

  const optimizelyTrackPageVisit = useOptimizelyTrackPageVisit()

  const [isPageTracked, setIsPageTracked] = useState(false)

  useEffect(() => {
    initBraze()
  }, [])

  // Handles any page-level tracking events
  const trackPageVisit = useCallback(() => {
    // GTM + At-At via react-tracking
    trackEvent({
      event: 'pageLoad',
      facebookEventId
    })

    // Optimizely
    optimizelyTrackPageVisit({ pageUrl: pathname })

    // Braze
    brazeTrackPageVisit()

    // Facebook
    facebookTrackSiteVisits({
      eventId: facebookEventId,
      pageUrl: href
    })

    // don't include facebookEventId in dependency array
  }, [
    href,
    pathname,
    trackEvent,
    referrer,
    metaTitle,
    optimizelyTrackPageVisit
  ])

  useEffect(() => {
    !isPageTracked && trackPageVisit()
    !isPageTracked && setIsPageTracked(true)
  }, [isPageTracked, trackPageVisit])

  return <Track>{children}</Track>
}
