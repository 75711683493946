import { InjectContext, useBmsTestRedirect } from '@lib/gatsby-wrappers'
import { OptimizelyProvider } from '@lib/tracking'
import { ImmutablePackages } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableProducts } from '@simplisafe/ss-ecomm-data/products'
import { EvergreenPromotion } from '@simplisafe/ss-ecomm-data/promotions/lib'
import { Map } from 'immutable'
import { Maybe, None } from 'monet'
import React, { ReactElement } from 'react'

// These build-time generated json files are a workaround for not being able to pass large objects via page context as of Gatsby 4
// @ts-ignore -- this file will not exist on a fresh checkout
import evergreenPromotion from '../evergreen-promotion.json'
// @ts-ignore -- this file will not exist on a fresh checkout
import datafile from '../optimizely-datafile.json'
// @ts-ignore -- this file will not exist on a fresh checkout
import packages from '../site-packages.json'
// @ts-ignore -- this file will not exist on a fresh checkout
import products from '../site-products.json'
import InjectPageTracking from '../src/tracking/InjectPageTracking'

type WrapWithContextProps = {
  readonly element: ReactElement
  readonly props: {
    // readonly pageContext: PageContext
  }
}

function isEvergreenPromo(
  t: Maybe<EvergreenPromotion> | unknown
): t is Maybe<EvergreenPromotion> {
  return !!t
}

function isProducts(t: ImmutableProducts | unknown): t is ImmutableProducts {
  return !!t
}

function isPackages(t: ImmutablePackages | unknown): t is ImmutablePackages {
  return !!t
}

function isDatafile(
  t: Record<string, string> | unknown
): t is Record<string, string> {
  return !!t
}

function WrapWithContext({ element }: WrapWithContextProps) {
  // START: ECP-5444 - BMS A/B test
  // If we're on a BMS url and need to redirect to another BMS page for the A/B test, this returns a Redirect component with a loading skeleton.
  // Otherwise it returns null.

  const bmsTestRedirect = useBmsTestRedirect(
    isDatafile(datafile) ? datafile : {}
  )
  // END: ECP-5444 - BMS A/B test

  return (
    bmsTestRedirect || (
      <OptimizelyProvider datafile={isDatafile(datafile) ? datafile : {}}>
        <InjectPageTracking>
          <InjectContext
            evergreenPromotion={
              isEvergreenPromo(evergreenPromotion) ? evergreenPromotion : None()
            }
            packages={isPackages(packages) ? packages : Map()}
            products={isProducts(products) ? products : Map()}
          >
            {element}
          </InjectContext>
        </InjectPageTracking>
      </OptimizelyProvider>
    )
  )
}

// Wrap the wrapper in another wrapper to allow the use of React hooks
// https://github.com/gatsbyjs/gatsby/issues/22833#issuecomment-609370401
export default function Wrapper({ element, props }: WrapWithContextProps) {
  return <WrapWithContext element={element} props={props} />
}
