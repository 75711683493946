import fetchIntercept from 'fetch-intercept'
import { get as localStorageGet } from 'local-storage'

import { fetchAtAtToken } from '../atat'

export const getRequestConfig = async (
  config: RequestInit,
  isOverride: boolean
): Promise<RequestInit> => {
  const atatToken: string | null = (await fetchAtAtToken()) || ''
  !atatToken &&
    console.warn('Cannot find at-at token when intercepting fetch headers.')

  const locale = process.env['LOCALE'] || ''
  !locale &&
    console.warn(
      'Cannot find process.env.LOCALE when intercepting fetch headers.'
    )

  // Retrieve the PerimeterX cookie from local storage
  const pxCookie: string = localStorageGet('pxcookie') || ''
  !pxCookie &&
    console.warn('Cannot find pxcookie when intercepting fetch headers.')

  const modifiedHeaders: RequestInit['headers'] = {
    ...(config && config.headers),
    'Accept-Language': locale,
    'X-PX-Cookies': pxCookie,
    'X-Vid-Token': atatToken
  }

  const baseConfig = {
    ...config,
    headers: modifiedHeaders
  }

  return {
    ...baseConfig,
    ...(isOverride && { credentials: 'include' })
  }
}

const knownApiUrlOverrides = [
  process.env['PRICES_API_OVERRIDE_URL'],
  process.env['PROMOTIONS_API_OVERRIDE_URL']
]

export const interceptFetchHeaders = () =>
  fetchIntercept.register({
    request: async (url: string, config: RequestInit) => {
      const isLocalSSApiRequest = knownApiUrlOverrides.some(
        x => x && url.includes(x)
      )
      const isSSApiRequest =
        url.includes(process.env['BASE_SS_API_URL'] || '') ||
        isLocalSSApiRequest
      const requestConfig = isSSApiRequest
        ? await getRequestConfig(config, isLocalSSApiRequest)
        : config

      return [url, requestConfig]
    }
  })
