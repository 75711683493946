/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

// @ts-nocheck
/*
  NOTE: This component has a lot of shared and/or copied functionality with DynamicPackageHeroBannerComponent.
  As such, when making changes in one, consider whether the same changes need to be made in the other one.
  TODO combine these two components into a single one to handle dual responsibility
*/
/**
 *
 *
 *
 *
 *
 * TODO
 * WARNING
 * This file has many issues that need to be fixed and needs to be rewritten.
 * Please do not make any changes to this file until this warning is removed.
 *
 *  - Josh
 *
 *
 *
 */
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Node } from '@contentful/rich-text-types'
import { AffirmClient, GatsbyImage } from '@lib/components'
import {
  getMonitoringGiftItems,
  getNonMonitoringGiftItems,
  useOptimizelyAffirm,
  useOptimizelyTrackSiteEvents
} from '@lib/tracking'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { useExperiment } from '@lib/optimizely'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { selectCartLoading } from '@simplisafe/ss-ecomm-data/cart/select'
import { ProductBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import {
  getLocalizedString,
  Product
} from '@simplisafe/ss-ecomm-data/commercetools/products'
import {
  initializeMiniCart,
  MiniCartLineItem,
  setMiniCartLineItem
} from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import { GiftItemContainer } from '@simplisafe/ss-ecomm-data/prices/service'
import { selectPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoFlag,
  selectActivePromoOverrideDiscountText,
  selectLocale,
  selectMiniCartLineItems,
  selectPackage,
  selectProduct,
  selectProducts,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  AffirmPromoMessage,
  IncludedItemProduct,
  ItemContainer,
  Price,
  SSInput
} from '@simplisafe/ss-react-components'
import {
  AdditionalOptionItemsProps,
  AdditionalOptionItemType
} from '@simplisafe/ss-react-components/AdditionalOptionItems'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Caution, Info } from '@simplisafe/ss-react-components/icons'
import {
  IncludedItemProps,
  ItemProduct
} from '@simplisafe/ss-react-components/IncludedItem'
import { ItemContainerProps } from '@simplisafe/ss-react-components/ItemContainer'
import { ItemDetailProps } from '@simplisafe/ss-react-components/ItemDetail'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { window } from 'browser-monads-ts'
import { option as O } from 'fp-ts'
import { pipe as pipeFpTs } from 'fp-ts/lib/function'
import { graphql, navigate } from 'gatsby'
import { Either, Just, Maybe, None } from 'monet'
import add from 'ramda/src/add'
import always from 'ramda/src/always'
// TODO replace applySpec with transformObject
import applySpec from 'ramda/src/applySpec'
import concat from 'ramda/src/concat'
import cond from 'ramda/src/cond'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import pathEq from 'ramda/src/pathEq'
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import reduce from 'ramda/src/reduce'
import subtract from 'ramda/src/subtract'
import sum from 'ramda/src/sum'
import T from 'ramda/src/T'
import unless from 'ramda/src/unless'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  BmsSensorsFragment,
  ContentfulGuaranteeBadge,
  ContentfulImage,
  ContentfulModal,
  ContentfulPdpCarouselImage,
  ContentfulPriceOption,
  ContentfulProduct,
  ContentfulProductDetail,
  ContentfulRichTextWithOptions,
  ContentfulSystemComponent
} from '../../../graphql'
import {
  componentsNotInStock,
  CoreComponentsData,
  renderCoreComponentsNotInStockMsg,
  renderOutOfStockMessage,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import {
  formatDisplayPrice,
  formatPercentage,
  getProductFinalPrice
} from '../../commercetools/price'
import { PackageDiscountKey } from '../../commercetools/utils'
import {
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import AddToCartError, {
  AddToCartErrorType
} from '../../errorComponents/AddToCartError'
import usePriceVariation from '../../hooks/usePriceVariation'
import useRequestPrice from '../../hooks/useRequestPrice'
import { PriceProvider } from '../../providers/PriceProvider'
import {
  trackAddToCartEvent,
  trackAddToCartPackageWithExtrasEvent
} from '../../util/analytics/addToCart'
import { trackPackageDetailEvent } from '../../util/analytics/details'
import { findFirstJust, toButton } from '../../util/helper'
import { replaceTokensWithPrice } from '../../util/monitoring-price-placeholders'
import { renderComponentFromData } from '../../util/render'
import BmsSensor from '../BmsSensorGroup/BmsSensor'
import CardBadge, { CardBadgeProps } from '../CardBadge'
import ContentfulRichText from '../ContentfulRichText'
import {
  MonthOfFreeService,
  MonthOfServiceText,
  renderEmbeddedEntry
} from '../ContentfulRichText/embeddedEntries'
import {
  linkContentRichTextOptions,
  priceCalculationCustomOptions
} from '../DynamicPackageHeroBannerComponent/embeddedComponentOptions'
import EditPackageSensorComponent from '../EditPackageSensorComponent'
import ModalComponent from '../ModalComponent'
import ProductPlanComponent from '../ProductPlanComponent'

// These values need to line up with CTFL; see the Product Type field in the priceOption/"Pick Your Price Toggle" content type
export const withPlanKey = 'With Plan'
export const noPlanKey = 'No Plan'
export const refurbKey = 'Refurb'
export const ss2UpgradeKey = 'SS2 Upgrade'

// TODO: move this to ecomm-data
// this could be a generic function that takes in a Maybe
// we could also change selectProduct to take in a Maybe<string> instead of a string
// This all seems weird anyway since the entire product plan sku should be in a different component that doesn't render if it isn't needed
export const selectProductPlanById =
  (productPlan: Maybe<string>) => (state: ImmutableState) => {
    return productPlan
      .chain(id => (isNotEmpty(id) ? Just(id) : None<string>()))
      .chain(id => selectProduct(id)(state).toMaybe())
  }

export type ItemContainerComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ContentfulProduct
}

type PdpIncludedItem = ItemProduct & {
  readonly sku: string
}

const US_LOCALE = 'en-US'
const GTM_TOOLTIP_CATEGORY = 'package-details'
const GTM_TOOLTIP_ACTION = 'tooltip'
const GTM_TOOLTIP_EVENT = 'package-detail-tooltip'

// TODO move this into it's own component
export function OutOfStock({
  coreComponetsNotInStockList,
  skuID
}: {
  readonly coreComponetsNotInStockList: readonly CoreComponentsData[]
  readonly skuID: string
}) {
  const product = useSelector(selectProduct(skuID))
  const outOfStockMessage = renderOutOfStockMessage({
    coreComponentsNotInStock: coreComponetsNotInStockList,
    includedInPackage: true,
    lowStockTemplate: true,
    product: product
  })

  return outOfStockMessage ? (
    <>
      <Caution />
      <span>{outOfStockMessage}</span>
    </>
  ) : null
}

const toProductList = (locale: Locale) =>
  transformObject<PackageProduct, PdpIncludedItem>({
    productName: x =>
      pipe(
        val => prop('name', val),
        getLocalizedString(locale),
        (prodName: string) =>
          concat(prop('quantity', x).toString(), ` ${prodName}`)
      )(x),
    sku: x => prop('sku', x) || '' // TODO this empty sku isn't great, but it'll take more work to refactor this
  })

const toBadgeIcon = x => [<GatsbyImage image={x} key={x.id} />]

const toCardBadge = transformObject<ContentfulGuaranteeBadge, CardBadgeProps>({
  cardImage: pipe(path(['images', '1']), toBadgeIcon),
  description: val =>
    safePath(['description', 'description'], val).getOrElse(''),
  image: pipe(path(['images', '0']), toBadgeIcon),
  title: val => safePath(['title', 'title'], val).getOrElse('')
})

const toProductImage = (img, isMobile, isDesktop) => {
  // TODO this needs to be done in react components, not here
  // TODO also move it out of DynamicPackageHeroBannerComponent
  const getHeight = isMobile
    ? {
        height: '340px',
        minWidth: '380px'
      }
    : isDesktop
    ? {
        height: '600px',
        width: '100%'
      }
    : {
        height: '100%',
        width: '100%'
      }
  return (
    <GatsbyImage
      alt={img.description || ''}
      image={img}
      style={{ ...getHeight }}
    />
  )
}

const toolTipModalTrackEvent = (productName, trackEvent) => {
  trackEvent({
    action: GTM_TOOLTIP_ACTION,
    category: GTM_TOOLTIP_CATEGORY,
    event: GTM_TOOLTIP_EVENT,
    label: productName
  })
}

export const includedItemsRichTextOptions: Options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node: Node) => {
      const getFieldValue = (fieldName: string) =>
        pipeFpTs(
          O.fromNullable(node?.data?.target?.[fieldName]),
          O.getOrElse(() => '')
        )

      const linkText = getFieldValue('linkText')
      const linkUrl = getFieldValue('linkUrl')
      const sysType = pipeFpTs(
        O.fromNullable(node?.data?.target?.internal?.type),
        O.getOrElse(() => '')
      )

      return sysType === 'ContentfulLink' ? (
        <a href={linkUrl}>{linkText}</a>
      ) : null
    },
    [BLOCKS.PARAGRAPH]: (_, children) => children && <p>{children}</p>
  }
}

const toIncludedItemsProps = (
  childData,
  includedItemsPopup,
  setSelectedIncludedItemSku,
  coreComponetsNotInStockList,
  refurbishedPackage: boolean,
  trackEvent,
  modalProp
) => {
  const modalContentIncludedItemsLinkContent = prop('modalContent', modalProp)

  return transformObject<ContentfulProductDetail, IncludedItemProps>({
    includedItems: data =>
      childData.map(item => {
        const modalData = defaultTo({})(
          includedItemsPopup &&
            includedItemsPopup.find(
              pathEq(['systemComponent', 'sku'], item.sku)
            )
        )
        const modalContent: ContentfulComponent = defaultTo({})(
          prop('modalContent', modalData)
        )
        const ModalComponent = getMappedComponent(modalContent)
        const productSku: string = safeProp('sku', item).getOrElse(
          item.productName
        )
        const hiddenItems = path(['hiddenItems', 0, 'products'], data)
        const isHiddenItem = Maybe.fromNull(hiddenItems).chain(
          safeFind<ContentfulSystemComponent>(propEq('sku', item.sku))
        )
        const isFreeItem: boolean = safeProp('isFreeItem', item).getOrElse(
          false
        )

        return isHiddenItem.cata(
          () => (
            <IncludedItemProduct
              {...item}
              isFreeItem={isFreeItem}
              // override SKU to avoid dupe key when free item matches pkg component
              key={isFreeItem ? `FREE-${productSku}` : productSku}
              modalContent={
                ModalComponent && <ModalComponent data={modalContent} />
              }
              modalType="link"
              onClick={() =>
                toolTipModalTrackEvent(prop('productName', item), trackEvent)
              }
              onHoverEnd={() => setSelectedIncludedItemSku(None())}
              onHoverStart={() => setSelectedIncludedItemSku(item.sku)}
              outOfStockMessage={
                isNotEmpty(productSku) &&
                !refurbishedPackage && (
                  <OutOfStock
                    coreComponetsNotInStockList={coreComponetsNotInStockList}
                    skuID={productSku}
                  />
                )
              }
            />
          ),
          always(null)
        )
      }),
    includedItemsLinkContent: data => (
      <>
        {Maybe.fromNull(modalProp)
          .map(modalData => (
            <ModalComponent
              data={modalData}
              key={modalData.id}
              modalContent={
                <EditPackageSensorComponent
                  childList={childData.map(
                    applySpec({
                      quantity: d =>
                        safeProp('productName', d)
                          .map(name => name.match(/\d/g).join(''))
                          .map(Number)
                          .orJust(0),
                      sku: prop('sku')
                    })
                  )}
                  data={modalContentIncludedItemsLinkContent}
                />
              }
              style={{ content: { padding: '30px' } }}
            />
          ))
          .orNull()}
        <div className="prose text-sm font-medium">
          <ContentfulRichText
            optionsCustom={includedItemsRichTextOptions}
            raw={data?.customizedSystem?.description?.raw}
            references={data?.customizedSystem?.description?.references}
          />
        </div>
      </>
    ),
    includedItemsTitle: data =>
      safeProp('includedItemsTitle', data).getOrElse(''),
    numColumnsDesktop: () => 3,
    numColumnsMobile: () => 2,
    numColumnsTablet: () => 3
  })
}

//TODO This need to be handled in contentful
const modalStyle = { content: { padding: '30px' } }

const toModalComponent = (modalContent: ContentfulModal) => (
  <ModalComponent
    clickTarget={
      <button className="cursor-pointer border-0 bg-transparent px-0 py-3 underline hover:no-underline">
        {modalContent.clickTarget?.buttonText}
      </button>
    }
    data={modalContent}
    style={modalStyle}
  />
)

const formatAbsolute = val =>
  formatDisplayPrice(val, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })

export const getDiscountValueBasedKey = (data: Package) => (key: string) =>
  key === withPlanKey
    ? {
        absoluteDiscount: data.absoluteDiscountWithServicePlan,
        formattedDiscountString: findFirstJust([
          data.absoluteDiscountWithServicePlan.chain(formatAbsolute),
          data.relativeDiscountWithServicePlan.map(formatPercentage)
        ]),
        relativeDiscount: data.relativeDiscountWithServicePlan
      }
    : {
        absoluteDiscount: data.absoluteDiscount,
        formattedDiscountString: findFirstJust([
          data.absoluteDiscount.chain(formatAbsolute),
          data.relativeDiscount.map(formatPercentage)
        ]),
        relativeDiscount: data.relativeDiscount
      }

export function getDiscount(data: Package) {
  return function <T extends { readonly productType?: string | null }>(x: T) {
    return pipe(
      (y: T) => prop('productType', y),
      defaultTo(''),
      getDiscountValueBasedKey(data)
    )(x)
  }
}

export const getPackageDiscountValues = (p?: Package) =>
  Maybe.fromUndefined(p).map(d => getDiscountValueBasedKey(d)(noPlanKey))

const filterDetailsModalData = (modal: ContentfulModal, planPrice: number) => {
  return safeProp('modalContent', modal)
    .map((richTextWithOptions: ContentfulRichTextWithOptions) => {
      const filteredJson = safePath(['richText', 'raw'], richTextWithOptions)
        .map((raw: string) => replaceTokensWithPrice(raw, planPrice))
        .getOrElse('')

      return {
        ...richTextWithOptions,
        richText: { raw: filteredJson }
      }
    })
    .orUndefined()
}

const getDetailsModal = (
  priceOption: ContentfulPriceOption,
  planPrice: number
) => {
  return safeProp('detailsModal', priceOption)
    .map(data => ({
      ...data,
      modalContent: filterDetailsModalData(data, planPrice)
    }))
    .map(data => toModalComponent(data))
    .orUndefined()
}

// Renders service plan description in the Price Options Box
const getServicePlanDescription = (
  priceOption: ContentfulPriceOption,
  planPrice: number
) => {
  const serviceDescriptionContent = safePath(
    ['description', 'raw'],
    priceOption
  )
    .map(raw => replaceTokensWithPrice(raw, planPrice))
    .getOrElse('')

  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => {
        return ifElse(
          equals('Months of Free Service Description'),
          () => <MonthOfServiceText />,
          () => renderEmbeddedEntry(node)
        )(node?.data?.target?.type)
      },

      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target && <ProductPlanComponent data={data.target} />
    }
  }

  return (
    serviceDescriptionContent && (
      <ContentfulRichText
        optionsCustom={options}
        raw={serviceDescriptionContent}
        references={priceOption?.description?.references}
      />
    )
  )
}

// Extracted from getServicePlanTabHeader to render the options title as well.
export const getInlineDiscountOrMonthsText = (
  discountValue: string
): Options => ({
  renderNode: {
    // Inlines
    [INLINES.EMBEDDED_ENTRY]: node => {
      return ifElse(
        equals('Discount Percentage'),
        () => <>{discountValue}</>,
        ifElse(
          equals('Months of Free Service'),
          () => <MonthOfFreeService />,
          () => renderEmbeddedEntry(node)
        )
      )(node?.data?.target?.type)
    }
  }
})

// Renders tab header for a service plan in the Price Options Box
const getServicePlanTabHeader = (
  priceOption: ContentfulPriceOption,
  withPlanKey: string,
  discountText: string | undefined,
  discountTextWithMonitoring: string | undefined
) => {
  const productWithPlanKey: boolean =
    prop('productType', priceOption) === withPlanKey
  const hasPromo = productWithPlanKey
    ? !!discountTextWithMonitoring
    : !!discountText
  const discountValue = productWithPlanKey
    ? discountTextWithMonitoring
    : discountText
  const titlePath: string = hasPromo ? 'titleDiscountText' : 'productName'
  const title: string = path([titlePath, 'raw'], priceOption)
  const references: string = path([titlePath, 'references'], priceOption)

  return (
    <ContentfulRichText
      optionsCustom={getInlineDiscountOrMonthsText(discountValue)}
      raw={title}
      references={references}
    />
  )
}

export const toAdditionalOptionItems = (
  _data: Package | undefined,
  discountText: string | undefined,
  discountTextWithMonitoring: string | undefined,
  planPrice: number,
  isRefurb?: boolean,
  overrideTextMaybe: Maybe<string>,
  hasOverrideText: boolean
) => {
  // TODO replace with transformObject
  return applySpec<AdditionalOptionItemType>({
    content: (spec: ContentfulPriceOption) =>
      getServicePlanDescription(spec, planPrice),
    detailsModal: (spec: ContentfulPriceOption) =>
      getDetailsModal(spec, planPrice),
    discount: v => {
      const productType = prop('productType', v)
      return !isRefurb && productType === withPlanKey
        ? hasOverrideText
          ? overrideTextMaybe.some()
          : discountTextWithMonitoring
        : discountText
    },
    inlineTitle: v => safeProp('titleProductName', v).getOrElse(''),
    planSkuId: prop('productSku'),
    sensorListDiscountText: v =>
      safeProp('sensorListDiscountText', v).getOrElse(''),
    skuId: prop('productType'),
    titleContent: (spec: ContentfulPriceOption) =>
      getServicePlanTabHeader(
        spec,
        withPlanKey,
        discountText,
        !isRefurb && hasOverrideText
          ? overrideTextMaybe.some()
          : discountTextWithMonitoring
      )
  })
}

export const getRichTextPriceOptionsTitle =
  (data?: Package, _isRefurb?: boolean) =>
  (document: ContentfulProduct['rightSideContainer']): Maybe<string> =>
    getPackageDiscountValues(data)
      .chain(__ => safePath(['toggleBoxesHeadline', 'raw'], document))
      .orUndefined()

export const toAdditionalOptionItem = (
  onChange: (__plan: string, __planSkuId?: string) => void,
  data: Package | undefined,
  isRefurb: boolean | undefined,
  discountText: string | undefined,
  discountTextWithMonitoring: string | undefined,
  planPrice: number,
  overrideTextMaybe: Maybe<string>,
  hasOverrideText: boolean
) =>
  transformObject<ContentfulProductDetail, AdditionalOptionItemsProps>({
    additionalOptionItems: v =>
      Maybe.fromFalsy(v)
        .chain(safeProp('priceOptions'))
        .map(x =>
          x.map(
            toAdditionalOptionItems(
              data,
              discountText,
              discountTextWithMonitoring,
              planPrice,
              isRefurb,
              overrideTextMaybe,
              hasOverrideText
            )
          )
        )
        .just(),
    onClick: () => onChange,
    priceOptionsTitle: v =>
      Maybe.fromFalsy(v).chain(safeProp('priceOptionsTitle')).getOrElse(''),
    priceOptionsTitleContent: _data => (
      <ContentfulRichText
        optionsCustom={getInlineDiscountOrMonthsText(
          !isRefurb && hasOverrideText
            ? overrideTextMaybe.getOrElse('')
            : discountTextWithMonitoring
            ? discountTextWithMonitoring
            : discountText
        )}
        raw={getRichTextPriceOptionsTitle(data, isRefurb)(_data)}
        references={_data?.toggleBoxesHeadline?.references ?? []}
      />
    )
  })

// TODO: this doesn't seem to be used
export const getPlanText =
  (key: string) => (data /** TODO fix this any type */) =>
    safeProp('priceOptions', data)
      .chain(
        safeFind((x: ContentfulPriceOption) => propEq('productType', key)(x))
      )
      .chain<string>(safePath(['savingDetails', 'raw']))
      .orUndefined()

export const getDiscountKey = cond([
  [
    equals(withPlanKey),
    always<PackageDiscountKey>('discountedPriceWithServicePlan')
  ],
  [
    equals(refurbKey),
    always<PackageDiscountKey>('discountedPriceWithServicePlan')
  ],
  [T, always<PackageDiscountKey>('discountedPrice')]
])

// TODO connect this to real data
export const safeDiscountPrice = (_a, _b) => None<number>()
// (key: PackageDiscountKey, _package: Package) =>
//   safeProp(key, _package)
//     .chain(discountPrice => Maybe.isInstance(discountPrice) ? discountPrice : None<number>())

const defaultPrice = 0

const getAdditionalItem = (
  proSetupCheckbox,
  customLayoutField,
  proSetupModal,
  setChecked,
  isChecked,
  trackEvent
) =>
  always(
    proSetupCheckbox && (
      <SSInput
        checked={isChecked}
        defaultChecked={prop('defaultChecked', proSetupCheckbox)}
        disabled={false}
        id={prop('propName', proSetupCheckbox)}
        label={
          <div className="flex items-baseline prose-p:mb-0 prose-p:text-xs">
            <ContentfulRichText
              raw={prop('raw', customLayoutField) as string}
              textColor="neutralMediumGray"
              textSize="xs"
            />
            <ModalComponent
              clickTarget={
                <Info
                  className="-mt-2"
                  forceButtonMode={true}
                  titleA11y="show more info"
                />
              }
              data={proSetupModal}
              onClick={() => {
                toolTipModalTrackEvent('pro setup help', trackEvent)
              }}
              style={modalStyle}
            />
          </div>
        }
        name={prop('propName', proSetupCheckbox)}
        onChange={() => {
          setChecked(!isChecked)
        }}
        placeholder={prop('placeholderText', proSetupCheckbox)}
        type={prop('type', proSetupCheckbox)}
        value={prop('value', proSetupCheckbox)}
      />
    )
  )

// TODO: This function takes in way too many arguments and is overly complicated
export const toItemDetail = (
  onClick: (url: string) => void,
  childData,
  onChange: (plan: string, planSkuId?: string) => void,
  showSpinner: boolean,
  rightSideData,
  includedItemsPopup,
  setSelectedIncludedItemSku,
  isProSetUpChecked,
  setProSetUpCheckbox,
  coreComponetsNotInStockList,
  trackEvent,
  isRefurb,
  totalQuantityProduct,
  _package,
  planPrice,
  overrideToggleTextMaybe,
  hasOverrideToggleText,
  cartIsLoading
) => {
  const proSetupCheckbox = prop('proSetupCheckbox', rightSideData)
  const customLayoutField = prop('customLayoutField', proSetupCheckbox)
  const proSetupModal = prop('proSetupModal', rightSideData)
  const modalProp = prop('customizedSystem', rightSideData)
  const addtionalOption = prop('priceOptions', rightSideData)
  const productQuantity = safeProp('productQuantity', rightSideData)
    .map(data =>
      toRichTextProductQuantity(
        data as ContentfulRichTextWithOptions,
        totalQuantityProduct
      )
    )
    .orNull()

  return applySpec<ItemDetailProps>({
    additionalItem: getAdditionalItem(
      proSetupCheckbox,
      customLayoutField,
      proSetupModal,
      setProSetUpCheckbox,
      isProSetUpChecked,
      trackEvent
    ),
    additionalOptionItemsProps:
      addtionalOption &&
      toAdditionalOptionItem(
        onChange,
        _package,
        isRefurb,
        undefined,
        undefined,
        planPrice,
        overrideToggleTextMaybe,
        hasOverrideToggleText
      ),
    buttonProps: (x): SSButtonProps => ({
      ...pipe(prop('button'), toButton)(x),
      isLoading: cartIsLoading,
      showSpinner: showSpinner || cartIsLoading
    }),
    content: x => (
      <ContentfulRichText
        raw={path(['description', 'raw'], x)}
        references={path(['description', 'references'], x)}
      />
    ),
    descriptionWithOptions: data =>
      renderComponentFromData(prop('descriptionRichTextWithOptions', data)),
    includedItemProps: toIncludedItemsProps(
      childData,
      includedItemsPopup,
      setSelectedIncludedItemSku,
      coreComponetsNotInStockList,
      isRefurb,
      trackEvent,
      modalProp
    ),
    onClickButton: x => () => onClick(path(['button', 'url'], x)),
    productQuantity: always(productQuantity),
    productTitle: prop('productTitle')
  })(rightSideData)
}

const toBmsSensor = (sensor: BmsSensorsFragment, pkg?: Package) => (
  <BmsSensor data={sensor} key={sensor.id} pkg={pkg} />
)

const toItemContainer = ({
  data,
  discountText,
  priceList,
  setSensorsVisible,
  showSpinner,
  pkg
}: {
  readonly data: ContentfulProduct
  readonly priceList: Record<string, string>
  readonly discountText: string
  readonly showSpinner: boolean
  readonly pkg?: Package
  readonly setSensorsVisible: (val: boolean) => void
}) => {
  // TODO replace applySpec with transformObject
  return applySpec<ItemContainerProps>({
    cardBadge: val =>
      safePath(['leftSideContainer', 'guaranteeBadge'], val)
        .map(toCardBadge)
        .orUndefined(),
    checkOutButtonFooter: (value): SSButtonProps => ({
      ...pipe(
        path(['leftSideContainer', 'extraSensors', 'button']),
        toButton
      )(value),
      showSpinner
    }),
    linkContent: data => (
      <div className="flex h-full items-center prose-p:text-white">
        <ContentfulRichText
          optionsCustom={linkContentRichTextOptions(() =>
            setSensorsVisible(true)
          )}
          raw={data?.leftSideContainer?.sensorLinkText?.raw}
        />
      </div>
    ),
    priceCalculationContent: d => (
      <ContentfulRichText
        optionsCustom={priceCalculationCustomOptions(priceList, discountText)}
        raw={d?.leftSideContainer?.extraSensors?.priceCalculation?.raw}
        references={
          d?.leftSideContainer?.extraSensors?.priceCalculation?.references
        }
      />
    ),
    sensors: pipe(
      path(['leftSideContainer', 'extraSensors', 'sensor']),
      unless(
        isNil,
        map((sensor: BmsSensorsFragment) => toBmsSensor(sensor, pkg))
      ),
      defaultTo(<></>)
    )
  })(data)
}

const toItemProducts = (locale: Locale) => (packageVal: Package) =>
  safeProp('products', packageVal).map(products =>
    products
      .filter(packageProduct => !!prop('isViewable', packageProduct))
      .map(toProductList(locale))
  )
const totalQuantity = (packageVal: Package) =>
  safeProp('products', packageVal).map(products =>
    products
      .filter(packageProduct => !!prop('isViewable', packageProduct))
      .map(item => {
        return prop('quantity', item)
      })
  )
const toProductBody = transformObject<MiniCartLineItem, ProductBody>({
  quantity: prop('quantity'),
  sku: prop('sku')
})

const multipliedPrice = (x: MiniCartLineItem): number =>
  x.quantity * getProductFinalPrice(x)

const getExtraPrice = (itemList: readonly MiniCartLineItem[]) =>
  Maybe.fromUndefined(itemList).map(x =>
    reduce(add, 0, map(multipliedPrice)(x))
  )

// export const isShowPromotionalTag =
//   (discountValue: string | undefined, _package: Package): boolean =>
//     (isNotEmpty(discountValue) || !(isNotEmpty(_package.discountService)  || isNotEmpty(_package.discount)))

// TODO connect this to real data
// export const isShowPromotionalTag = (_a, _b) => false

export const getAddToCartRedirectUrl =
  (locale: Locale) =>
  (
    originalUrl: string,
    radioKey: string,
    isMonitoringVariation: boolean,
    noPlanMonitoringURL: string
  ) => {
    // TODO Need to move this logic/url to CTFL
    const isNoPlan = radioKey !== withPlanKey && radioKey !== ss2UpgradeKey
    const monitoringURL = isMonitoringVariation ? '/cart' : originalUrl
    return isNoPlan ? noPlanMonitoringURL : monitoringURL
  }

export const maybeAddMonitoringToProducts =
  (locale: Locale) =>
  (
    products: ReadonlyArray<ProductBody>,
    radioKey: string,
    isMonitoringVariation: boolean
  ) => {
    const addMonitoringToProducts: boolean =
      isMonitoringVariation || locale === US_LOCALE

    return ifElse(
      equals(true),
      always(
        ifElse(
          equals(withPlanKey),
          always(
            products.concat([
              {
                quantity: 1,
                // TODO Need to move value to CTFL
                sku:
                  locale === 'en-US'
                    ? 'SSEDSM2__4867366'
                    : 'SSEDSM2_GB__5229044'
              }
            ])
          ),
          always(products)
        )(radioKey)
      ),
      always(products)
    )(addMonitoringToProducts)
  }

export const isRefurbishedPageContent = priceOptions => {
  const priceOption = priceOptions.filter(option => {
    return option.productType === refurbKey
  })
  return priceOption.length !== 0 ? true : false
}

const toRichTextProductQuantity = (
  data: ContentfulRichTextWithOptions,
  total: number
) => (
  <ContentfulRichText
    optionsCustom={{
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (_node: Node) => <>{total}</>
      }
    }}
    raw={path(['richText', 'raw'], data)}
  />
)

export default function ItemContainerComponent({
  affirmClient = window.affirm,
  data
}: ItemContainerComponentProps) {
  const locale = useSelector(selectLocale)
  const [areSensorsVisible, setSensorsVisible] = useState(false)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()

  const isCartLoading = useSelector(selectCartLoading)
  const [cartIsLoading, setCartIsLoading] = useState(true)

  const [addToCartError, setAddToCartError] = useState<AddToCartErrorType>(null)

  const isMobile = !useMediaQuery('TabletAndUp')
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const isDesktop = useMediaQuery('DesktopAndUp')

  const skuID = path(['rightSideContainer', 'productId'], data) || ''
  const plaPackageProduct: boolean = skuID.includes('sscs3-pla-')
  const priceOptions: ReadonlyArray<Record<string, unknown>> = data
    ?.rightSideContainer?.priceOptions ?? [{}]
  const isRefurb = isRefurbishedPageContent(priceOptions)

  // TODO should this default to the first priceOptions' productType instead of always withPlanKey?
  const defaultPlanKey: string = plaPackageProduct ? noPlanKey : withPlanKey
  const [radioKey, setRadioKey] = useState(defaultPlanKey)

  const _package: Either<Error, Package> = useSelector(selectPackage(skuID))
  const itemList = useSelector(selectMiniCartLineItems)
  const product = useSelector(selectProduct(skuID as string))
  const selectState = useSelector((state: ImmutableState) => state)
  const [shouldTrackEventsDetails, setShouldTrackEventDetails] =
    useState<boolean>(true)
  const dispatch = useDispatch()
  const productValue = useMemo(
    () =>
      product.cata(
        () => undefined,
        p => p
      ),
    [product]
  )
  const packageValue = useMemo(
    () =>
      _package.cata(
        () => undefined,
        p => p
      ),
    [_package]
  )
  const packageProps = useMemo(
    () => _package.map(toItemProducts(locale)),
    [locale, _package]
  )
  const baseProductPrice = useMemo(
    () => _package.cata(() => 0, prop('price')),
    [_package]
  )
  const promoFlag = useSelector(selectActivePromoFlag)
  const tagBackgroundColor = promoFlag
    .chain(chainProp('backgroundColor'))
    .orUndefined()
  const tagTextColor = promoFlag.chain(chainProp('textColor')).orUndefined()
  const taggingText = path(
    ['leftSideContainer', 'promoCode', 'taggingText', 'raw'],
    data
  )
  const taggingTextContent = path(
    ['leftSideContainer', 'promoCode', 'taggingText', 'references'],
    data
  )
  const stringifiedTaggingText = taggingText || ''
  const modifiedTaggingText = stringifiedTaggingText.replace(
    '"value":" OFF"',
    '"value":""'
  )
  const formattedPrice = formatDisplayPrice(baseProductPrice).orJust('')

  const [variation, clientReady, didTimeout] = useExperiment(
    'all___uk___prebuilt_packages_bms___monitoring'
  )
  const optimizelyMonitoringReady = clientReady || didTimeout
  const isMonitoringVariation =
    optimizelyMonitoringReady && variation === 'variation_1'
  const noPlanMonitoringURL =
    locale === 'en-US' ? '/choose-monitoring' : '/choose-monitoring2'

  const {
    getPrice,
    getDiscountedPrice,
    getDiscountedPriceWithServicePlan,
    getDiscountedText: promoDiscountText,
    getDiscountedTextWithServicePlan: promoWithMonitoringDiscountText
  } = useRequestPrice(
    skuID,
    data.leftSideContainer?.showAbsoluteDiscountAsRelative
  )

  const originalPrice = getPrice.chain(formatDisplayPrice).orUndefined()
  const [ogPrice, setOgPrice] = useState<string | undefined>(originalPrice)
  useEffect(() => {
    setOgPrice(originalPrice)
    originalPrice &&
      setTimeout(() => {
        window.prerenderReady = true
      }, 8000)
  }, [originalPrice])

  const isNoPlanSelected = radioKey !== withPlanKey
  const selectedDiscountedPrice =
    isNoPlanSelected || isRefurb
      ? getDiscountedPrice
      : getDiscountedPriceWithServicePlan

  const formattedSelectedDiscountedPrice = selectedDiscountedPrice
    .chain(formatDisplayPrice)
    .orUndefined()

  const hasDiscount = selectedDiscountedPrice
    .map(_discountedPrice =>
      getPrice.cata(
        () => false,
        _price => _discountedPrice !== _price
      )
    )
    .orJust(false)
  const price = (
    <Price
      discountedPrice={
        hasDiscount ? formattedSelectedDiscountedPrice : undefined
      }
      regularPrice={ogPrice}
    />
  )

  const imageWithProductId = path(
    ['leftSideContainer', 'imageGallery', 'imagesWithProductId'],
    data
  ) as ReadonlyArray<ContentfulImage | ContentfulPdpCarouselImage>
  const [selectedIncludedItemSku, setSelectedIncludedItemSku] = useState<
    Maybe<string>
  >(None())
  const selectedImageIndex =
    imageWithProductId &&
    Math.max(
      0,
      imageWithProductId.findIndex(i => {
        const skuCarouselImage = path(['systemComponent', 'sku'], i)
        return skuCarouselImage === selectedIncludedItemSku
      })
    )
  const productTitle: string = safePath(
    ['rightSideContainer', 'productTitle'],
    data
  ).getOrElse('')
  const totalQuantityProduct =
    packageValue &&
    sum(
      totalQuantity(packageValue).cata(
        () => [0],
        p => p
      )
    )
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    setCartIsLoading(isCartLoading)
    setShowSpinner(isCartLoading)
  }, [isCartLoading])

  const { Track, trackEvent } = useTracking()
  const rightSideData = prop('rightSideContainer', data)

  const discountValue =
    radioKey === withPlanKey
      ? promoWithMonitoringDiscountText
      : promoDiscountText

  // TODO the pro-setup logic needs to live in it's own component
  // This component is way to big and tries to do way to much and needs to be split up into many smaller components

  const proSetupId: Maybe<string> = safePath(
    ['rightSideContainer', 'proSetupId'],
    data
  )

  const proSetupProduct = useSelector((state: ImmutableState) => {
    return proSetupId.chain(id => selectProduct(id)(state).toMaybe())
  })

  /** Does MiniCartLineItems contain a sku for pro setup?  */
  const isProSetUpChecked = useMemo(() => {
    return proSetupId
      .chain(id => safeFind(propEq('masterSku', id), itemList))
      .isSome()
  }, [itemList, proSetupId])

  const setProSetUpCheckbox = (checked: boolean) => {
    proSetupProduct.forEach(product => {
      dispatch(
        setMiniCartLineItem({
          ...product,
          checked: true,
          quantity: checked ? 1 : 0
        })
      )
    })
  }

  const productServicePlan: Maybe<string> = Maybe.fromUndefined(
    rightSideData?.priceOptions?.[0]?.productSku
  )

  const [productPlan, setProductPlan] = useState(productServicePlan)

  const planProduct = usePriceVariation(productPlan.orJust(''))
  const planProductPrice = planProduct.cata(
    () => 0,
    value => prop('price', value)
  )

  const trackPlanToggleEvent = (trackEvent, plan: string) => {
    trackEvent({
      action: 'package-details',
      category: 'monitoring-toggle',
      event: 'onChange',
      label: ifElse(
        equals(noPlanKey),
        always('no-plan'),
        always('add-plan')
      )(plan)
    })
  }

  // TODO: planSkuId should just be Maybe<string>
  const onPlanChange = useCallback((plan: string, planSkuId?: string) => {
    setRadioKey(plan)
    setProductPlan(Maybe.fromNull(planSkuId))
  }, [])

  // TODO this hook is way to large
  // TODO split this up into smaller tested funtions

  const onAddToCart = useCallback(
    (url: string) => {
      setAddToCartError(null)

      const itemListWithPackage = itemList.map(toProductBody).concat([
        {
          quantity: 1,
          sku: skuID
        }
      ])

      const products = productPlan.cata(
        () => itemListWithPackage,
        sku =>
          itemListWithPackage.concat([
            {
              quantity: 1,
              sku
            }
          ])
      )

      const urlRedirect = (url: string) => {
        // TODO Need to move the logic in CTFL
        const addtionalOption = Maybe.fromNull(rightSideData)
          .chain(safeProp('priceOptions'))
          .orUndefined()
        const urlRedirect = addtionalOption
          ? getAddToCartRedirectUrl(locale)(
              url,
              radioKey,
              isMonitoringVariation,
              noPlanMonitoringURL
            )
          : noPlanMonitoringURL
        navigate(urlRedirect, { state: { packageSku: _package.right().sku } })
      }

      const handleSuccess = () => {
        dispatch(initializeMiniCart({}))
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartPackageWithExtrasEvent(
          _package.toMaybe(),
          product.toMaybe(),
          itemList,
          false,
          trackEvent,
          selectState,
          radioKey === withPlanKey
        )
        trackAddToCartEvent(planProduct, trackEvent, 1)
        setShowSpinner(false)
        url && urlRedirect(url)
      }

      const handleFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const skuIdWithOrNullProductError = skuID
        ? `no product with sku ${skuID} found in redux`
        : 'received null/empty sku'

      _package.cata(
        () => {
          setAddToCartError('unrecoverable')
          setShowSpinner(false)
          logError(Error(`Cannot add to cart: ${skuIdWithOrNullProductError}`))
        },
        () =>
          dispatch(
            IOAddToCart(
              {
                discountCode: isRefurb ? 'SIMPLI25REFURB' : undefined,
                products
              },
              handleFailure,
              handleSuccess
            )
          )
      )
    },
    // with this many dependencies, *maybe* this callback is doing too much
    [
      itemList,
      skuID,
      productPlan,
      _package,
      product,
      rightSideData,
      locale,
      radioKey,
      isRefurb,
      dispatch,
      optimizelyTrackSiteEvents,
      trackEvent,
      selectState,
      planProduct
    ]
  )

  const coreComponetsProducts = useSelector(
    selectProducts(systemCoreComponents)
  )
  const coreComponetsNotInStockList: readonly CoreComponentsData[] =
    componentsNotInStock(coreComponetsProducts)

  const overrideTextMaybe = useSelector(selectActivePromoOverrideDiscountText)
    .chain(safeProp('productFlag'))
    .chain(val => val)
  const hasOverrideText = !overrideTextMaybe.isNone()

  const overrideSummaryTextMaybe = useSelector(
    selectActivePromoOverrideDiscountText
  )
    .chain(safeProp('discountSummary'))
    .chain(val => val)
  const hasOverrideSummaryText = !overrideSummaryTextMaybe.isNone()

  const overrideToggleTextMaybe = useSelector(
    selectActivePromoOverrideDiscountText
  )
    .chain(safeProp('toggleBox'))
    .chain(val => val)
  const hasOverrideToggleText = !overrideToggleTextMaybe.isNone()

  const withMonitoringGiftItem = getMonitoringGiftItems()
  const withoutMonitoringGiftItem = getNonMonitoringGiftItems()

  const applicableGiftItem = useMemo(() => {
    const freeGiftItems: GiftItemContainer = {
      withMonitoring: withMonitoringGiftItem || null,
      withoutMonitoring: withoutMonitoringGiftItem || null
    }
    const freeGiftsExist = withMonitoringGiftItem || withoutMonitoringGiftItem
    const monitoringPlan =
      radioKey === withPlanKey ? 'withMonitoring' : 'withoutMonitoring'
    // first need to ensure freeGiftItems and at least one relevant field exist
    return freeGiftsExist
      ? // then check that a gift item exists for that plan before returning its title
        freeGiftItems[monitoringPlan]
        ? {
            isFreeItem: true,
            productName: `1 FREE ${freeGiftItems[monitoringPlan].title}`,
            sku: freeGiftItems[monitoringPlan].sku
          }
        : null
      : null
  }, [withMonitoringGiftItem, withoutMonitoringGiftItem, radioKey])

  const includedItemsPopup = path(
    ['rightSideContainer', 'includedItemsPopup'],
    data
  )
  // TODO extracting the data out at this stage seems premature
  const itemDetailProps = toItemDetail(
    onAddToCart,
    packageProps.cata(
      () => [],
      p => {
        const packages = p.getOrElse([])
        return applicableGiftItem ? [...packages, applicableGiftItem] : packages
      }
    ),
    onPlanChange,
    showSpinner,
    rightSideData,
    includedItemsPopup,
    setSelectedIncludedItemSku,
    isProSetUpChecked,
    setProSetUpCheckbox,
    coreComponetsNotInStockList,
    trackEvent,
    isRefurb,
    totalQuantityProduct,
    _package.cata(
      () => undefined,
      i => i
    ),
    planProductPrice,
    overrideToggleTextMaybe,
    hasOverrideToggleText,
    cartIsLoading
  )

  const mounted = useRef(false)
  useEffect(() => {
    !mounted.current
      ? (mounted.current = true)
      : trackPlanToggleEvent(trackEvent, radioKey)
  }, [trackEvent, radioKey])

  const isPartnerPage = useSelector(selectPartnerBanner).isSome()

  // TODO move this logic into ecomm-data
  const [personalizePrices, setPersonalizePrices] = useState(None<number>())
  // useCallback is only for user interactions or other outside into
  const getPriceProps = useCallback(
    (_packageVal: Package) => {
      const rawRegularPrice = safeProp('price', _packageVal)
      const addExtrasPrice = (p: number) => add(p, personalizePrices.orJust(0))

      const discountedPrice = selectedDiscountedPrice
        .map(addExtrasPrice)
        .chain(formatDisplayPrice)
        .orUndefined()

      //don't format discount price as a string, returns as a number instead
      const rawdiscountedPrice = selectedDiscountedPrice
        .orElse(rawRegularPrice)
        .map(addExtrasPrice)
        .getOrElse(_packageVal.price)

      const discountValuePrice = selectedDiscountedPrice
        .chain(_discountedPrice =>
          rawRegularPrice.map(subtract(_discountedPrice))
        )
        .map(formatDisplayPrice)
        .orUndefined()

      // TODO move this out into it's own function and unit test it
      const placeholderText = cond([
        [
          equals(withPlanKey),
          always(
            selectedDiscountedPrice.ap(rawRegularPrice.map(p => subtract(p)))
          )
        ],
        [
          equals(refurbKey),
          always(
            safeDiscountPrice(
              'discountedPriceWithServicePlan',
              _packageVal
            ).map(addExtrasPrice)
          )
        ],
        [
          equals(noPlanKey),
          always(
            safeDiscountPrice(
              'discountedPriceWithServicePlan',
              _packageVal
            ).map(addExtrasPrice)
          )
        ]
      ])(radioKey)
        .chain(formatDisplayPrice)
        .orUndefined()

      // TODO move this out into it's own function and unit test it
      const additionalOptionItemsProps = Maybe.fromNull(rightSideData)
        .chain(safeProp('priceOptions'))
        .map(__ =>
          toAdditionalOptionItem(
            onPlanChange,
            _packageVal,
            isRefurb,
            promoDiscountText.orUndefined(),
            promoWithMonitoringDiscountText.orUndefined(),
            planProductPrice,
            overrideToggleTextMaybe,
            hasOverrideToggleText
          )(rightSideData)
        )
        .orUndefined()

      // additionalData.discount is the problem, it's pulling from redux and not from the package

      // TODO move this out into it's own function and unit test it
      const additionalData = Maybe.fromUndefined(additionalOptionItemsProps)
        .chain(safeProp('additionalOptionItems'))
        .chain(safeFind<AdditionalOptionItemType>(propEq('skuId', radioKey)))
        .orUndefined()

      const discountText = discountValue
        .map(defaultDiscount => {
          const service = prop(
            'sensorListDiscountText',
            defaultTo({})(additionalData)
          )
          const summaryText =
            !isRefurb && radioKey === withPlanKey
              ? hasOverrideSummaryText
                ? overrideSummaryTextMaybe.some()
                : defaultDiscount
              : defaultDiscount
          return `+ ${summaryText} ${service}:`
        })
        .orUndefined()

      const affirmPromoMessage = (
        <AffirmPromoMessage
          affirmClient={affirmClient}
          className="affirm-as-low-as"
          onLearnMoreClick={optimizelyAffirmLearnMore}
          pageType="product"
          price={rawdiscountedPrice}
        />
      )

      // if one of core components is out of stock, add delayed shipping message to all packages except the refurbished packages
      const showDelayedShippingMessage: boolean =
        !isRefurbishedPageContent(priceOptions) &&
        coreComponetsNotInStockList.length > 0
      // TODO split this up into smaller functions that are each tested
      const itemDetail = additionalOptionItemsProps
        ? {
            ...itemDetailProps,
            additionalOptionItemsProps,
            affirmPromoMessage,
            // TODO Reintroduce the planText when safeDiscountPrice is reimplemented
            interactivePlan: placeholderText, // placeholderText && getPlanText(radioKey)(rightSideData),
            outOfStockMessage: showDelayedShippingMessage
              ? renderCoreComponentsNotInStockMsg(coreComponetsNotInStockList)
              : undefined,
            placeholderText,
            price
          }
        : {
            ...itemDetailProps,
            affirmPromoMessage,
            placeholderText,
            price
          }

      const offerTag = discountValue
        .filter(val => !!val && !!taggingText)
        .map(val => {
          const percentageText =
            !isPartnerPage && !isRefurb && radioKey === withPlanKey
              ? hasOverrideText
                ? overrideTextMaybe.some()
                : val
              : val
          return {
            content: (
              <div
                className="prose-p:text-inherit"
                style={{ color: tagTextColor }}
              >
                <ContentfulRichText
                  optionsCustom={{
                    renderNode: {
                      [INLINES.EMBEDDED_ENTRY]: node =>
                        renderEmbeddedEntry(node, {
                          'Discount Percentage': percentageText
                        })
                    }
                  }}
                  raw={
                    radioKey === withPlanKey &&
                    !isPartnerPage &&
                    hasOverrideText
                      ? modifiedTaggingText
                      : taggingText
                  }
                  references={taggingTextContent}
                />
              </div>
            ),
            placeholderText: percentageText,
            tagBackgroundColor,
            tagTextColor
          }
        })
        .orUndefined()

      return {
        discountAppliedPrice: discountValuePrice,
        discountText: discountText,
        extrasPrice: formatDisplayPrice(
          personalizePrices.getOrElse(defaultPrice)
        ).orJust(''),
        itemDetail: itemDetail,
        offerTag: !plaPackageProduct ? offerTag : undefined,
        packagePrice: formatDisplayPrice(_packageVal?.price).orJust(''),
        systemTotalPrice: discountedPrice
      }
    },
    // we want to ignore _package changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      personalizePrices,
      radioKey,
      onPlanChange,
      rightSideData,
      isPromoTopBanner,
      taggingText,
      tagBackgroundColor,
      itemDetailProps.additionalOptionItemsProps,
      discountValue
    ]
  )

  useEffect(() => {
    const trackEvents = (packageValue: Package, productValue: Product) => {
      trackPackageDetailEvent(
        productValue,
        packageValue,
        false,
        trackEvent,
        selectState,
        radioKey === withPlanKey
      )
      setShouldTrackEventDetails(false)
    }

    !!(
      productValue &&
      packageValue &&
      selectState &&
      shouldTrackEventsDetails
    ) && trackEvents(packageValue, productValue)
  }, [
    productValue,
    packageValue,
    radioKey,
    selectState,
    shouldTrackEventsDetails,
    trackEvent
  ])

  useEffect(() => {
    const prices = getExtraPrice(itemList)
    setPersonalizePrices(prices)
  }, [itemList])

  const skus = safeProp('leftSideContainer', data)
    .chain(safeProp('extraSensors'))
    .chain(safeProp('sensor'))
    .map(sensors =>
      sensors.filter(
        (sensor): sensor is BmsSensorsFragment =>
          !!sensor && 'productId' in sensor
      )
    )
    .map(sensors => sensors.map(prop('productId')))
    .orJust([])
    .filter(isNotNil)

  // TODO This 1 component is doing a lot
  // We need to split this up into smaller components
  // It should be the carousel, the system details, the monitoring choice, a component for each custom item (map over an array), and the subtotal/add to cart section
  // This woould make it a lot easier to make changes or find bugs
  const images = path(
    ['leftSideContainer', 'imageGallery', 'images'],
    data
  ) as ReadonlyArray<ContentfulImage>
  const imageProps = !isNil(imageWithProductId) ? imageWithProductId : images
  const toImage = imageProps.map(image =>
    Maybe.fromUndefined(
      'imageItem' in image ? prop('imageItem', image) : prop('image', image)
    )
      .orElse(Just(image))
      .map(i => toProductImage(i, isMobile, isDesktop))
      .some()
  )

  const priceProps = _package.cata(
    () => ({}), // TODO should this really be empty?
    _packageVal => getPriceProps(_packageVal)
  )

  // Extracted from old ItemContainer.
  const priceList = {
    'Discount Text & Value': priceProps.discountAppliedPrice,
    'Package Price': priceProps.packagePrice,
    'Product Price': priceProps.extrasPrice,
    Total: priceProps.systemTotalPrice
  }
  const itemContainerProps = toItemContainer({
    data,
    discountText: priceProps.discountText,
    pkg: _package.fold<Package | undefined>(() => undefined, identity),
    priceList,
    setSensorsVisible,
    showSpinner
  })

  return (
    <Track>
      <PriceProvider skus={skus}>
        <div className="relative" data-component="ItemContainerComponent">
          <ItemContainer
            areSensorsVisible={areSensorsVisible}
            {...itemContainerProps}
            cardBadge={null}
            checkOutClick={() => {
              const url = path([
                'leftSideContainer',
                'extraSensors',
                'button',
                'url'
              ])(data) as string // TODO remove "as string"
              onAddToCart(url)
            }}
            errorMessage={
              addToCartError && <AddToCartError errorType={addToCartError} />
            }
            itemDetail={itemDetailProps}
            packagePrice={formattedPrice}
            {...priceProps}
            images={toImage}
            selectedImageIndex={selectedImageIndex}
            setSensorsVisible={setSensorsVisible}
          />
          <CardBadge {...itemContainerProps.cardBadge} />
        </div>
      </PriceProvider>
    </Track>
  )
}

export const query = graphql`
  fragment productFragment on ContentfulProduct {
    id
    internal {
      type
    }
    leftSideContainer {
      promoCode {
        ...promotionalTagging
      }
      showAbsoluteDiscountAsRelative
      guaranteeBadge {
        description {
          description
        }
        images {
          # id
          title
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          description # TODO get description from gatsbyImageData
        }
        title {
          title
        }
      }
      imageGallery {
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 833, placeholder: BLURRED)
          # id
          description # TODO get description from gatsbyImageData
        }
        imagesWithProductId {
          ... on ContentfulImage {
            ...contentfulImage
          }
          ... on ContentfulPdpCarouselImage {
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 833
                placeholder: BLURRED
              )
              id
            }
            systemComponent {
              displayName
              sku
            }
          }
        }
      }
      sensorLinkText {
        raw # todo - No references
      }
      extraSensors {
        sensor {
          ... on ContentfulBmsSensors {
            ...bmsSensors
          }
          ... on ContentfulVariationContainer {
            ...variationContainer
          }
        }
        priceCalculation {
          raw
          references {
            ... on ContentfulPlaceholder {
              contentful_id
              __typename
              label
              type
            }
          }
        }
        button {
          text
          type
          url
        }
      }
    }
    rightSideContainer {
      id
      productId
      productTitle
      description {
        raw # todo 2 references ContentfulModal ContentfulRichText (ECP-5798)
      }
      productQuantity {
        ... on ContentfulRichTextWithOptions {
          ...richTextWithOptions
        }
      }
      includedItemsTitle
      priceOptionsTitle
      proSetupId
      proSetupCheckbox {
        answerOptions
        characterType
        checked
        customLayoutField {
          raw # todo 1 reference (ECP-5799)
        }
        defaultChecked
        maximumCharacter
        placeholderText
        propName
        title
        type
      }
      proSetupModal {
        ... on ContentfulModal {
          ...modalFragment
        }
      }
      priceOptions {
        title
        description {
          raw
          references {
            ... on ContentfulPlaceholder {
              __typename
              contentful_id
              type
            }
            ... on ContentfulProductPlan {
              __typename
              contentful_id
              ...contentfulProductPlan
            }
          }
        }
        productSku
        detailsModal {
          ... on ContentfulModal {
            ...modalFragment
          }
        }
        savingDetails {
          raw # todo 1 reference - no type (ECP-5801)
        }
        productType
        titleDiscountText {
          raw
          references {
            ... on ContentfulPlaceholder {
              __typename
              contentful_id
              type
            }
          }
        }
        titleProductName
        sensorListDiscountText
        productName {
          raw
          references {
            ... on ContentfulPlaceholder {
              __typename
              contentful_id
              type
            }
          }
        }
      }
      toggleBoxesHeadline {
        raw
        references {
          ... on ContentfulPlaceholder {
            __typename
            contentful_id
            type
          }
        }
      }
      button {
        text
        type
        url
      }
      customizedSystem {
        ... on ContentfulSmallTextSection {
          id
          description {
            raw # todo 2 references ContentfulModal ContentfulSmallTextSection (ECP-5805)
            references {
              ... on ContentfulLink {
                ...contentfulLinkFragment
              }
            }
          }
        }
        ... on ContentfulModal {
          ...modalFragment
        }
      }
      includedItemsPopup {
        systemComponent {
          sku
        }
        modalContent {
          ... on ContentfulBanner {
            ...contentfulBanner
          }
        }
      }
      descriptionRichTextWithOptions {
        ... on ContentfulRichTextWithOptions {
          ...richTextWithOptions
        }
      }
      hiddenItems {
        ... on ContentfulSystemCollection {
          id
          products {
            sku
          }
        }
      }
    }
  }
`
