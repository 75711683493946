import React, { FC, ReactNode } from 'react'
import { useTracking } from 'react-tracking'

import handleTrackingEvent from '../src/tracking'

type TrackingProviderProps = {
  readonly children: ReactNode
}

const TrackingProvider: FC<TrackingProviderProps> = ({
  children
}: TrackingProviderProps) => {
  const { Track } = useTracking({}, { dispatch: handleTrackingEvent })
  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: ReactNode; }' has no properties ... Remove this comment to see the full error message
    <Track>{children}</Track>
  )
}

export default TrackingProvider
