import { FormField, FormRadio, Text } from '@simplisafe/ss-react-components'
import React from 'react'

import { ContentfulRadioButton } from '../../../../graphql'

type Props = {
  readonly label: string
  readonly options: readonly Pick<ContentfulRadioButton, 'text' | 'value'>[]
}

function NumLocations({ label, options }: Props) {
  return (
    <div data-component="numLocations">
      <FormField hideLabel={true} label={label} name="numLocations">
        <Text fontWeight="medium">{label}</Text>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {options &&
            options.map((option, idx) => (
              <div key={idx} style={{ minWidth: 'auto' }}>
                <FormRadio
                  label={option.text || option.value || ''}
                  name="numLocations"
                  value={option.value || ''}
                />
              </div>
            ))}
        </div>
      </FormField>
    </div>
  )
}

export default NumLocations
