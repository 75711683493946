import { SSImg } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import React, { ReactNode } from 'react'

export type CartWarningMessageProps = {
  readonly message: ReactNode
  readonly showIcon: boolean
  readonly iconSrc?: string
  readonly showChangePlanLink?: boolean
}

function CartWarningMessage({
  iconSrc,
  message,
  showIcon,
  showChangePlanLink
}: CartWarningMessageProps) {
  return (
    <div className="border border-solid border-neutral-medium-100 mb-[20px] md:mb-[32px] mx-auto px-[25px] md:px-[60px] py-[15px] md:py-[30px] lg:py[60px] max-w-[563px] text-center md:text-left">
      {/* TODO: Pull this image from content model */}
      {showIcon && iconSrc && (
        <SSImg
          alt="Icon - Caution (Grey)"
          className="block mx-auto"
          height={32}
          src={iconSrc}
          width={32}
        />
      )}
      <p className="text-center">{message}</p>
      {showChangePlanLink ? (
        <Link
          className="block mx-auto underline text-base  md:text-lg hover:no-underline text-[#006fee] max-w-[127px]"
          to="/change-monitoring"
        >
          CHANGE PLAN
        </Link>
      ) : (
        <Link
          className="block mx-auto underline text-base  md:text-lg hover:no-underline text-[#006fee] max-w-[99px]"
          to="/home-security-shop"
        >
          Shop now
        </Link>
      )}
    </div>
  )
}

export default CartWarningMessage
