import { Address } from '@commercetools/platform-sdk'
import { visitorIdAtAt } from '@lib/tracking'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking'
import { set as setSessionStorage } from '@lib/utils'
import { TrackingData } from '@lib/tracking'
import __ from '@simplisafe/ewok/__'
import path from '@simplisafe/ewok/ramda/path'
import isNilOrEmpty from '@simplisafe/ewok/ramda-adjunct/isNilOrEmpty'
import { safeProp } from '@simplisafe/monda'
import {
  AffirmCheckoutData,
  selectAffirmCheckoutData
} from '@simplisafe/ss-ecomm-data/affirm'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  Locale,
  localeInfo
} from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import {
  fetchPaymentMethod,
  PaymentMethodResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  buildPaymentFormAddressData,
  paymentFormForkedFetch,
  PaymentFormRequestParams,
  PaymentFormResponse
} from '@simplisafe/ss-ecomm-data/simplisafe/paymentForm'
import {
  fetchSafeTechCollectorInfo,
  PaymentExperience,
  SafeTechCollectorInfoResponse
} from '@simplisafe/ss-ecomm-data/simplisafe/paymentsClient'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { ZuoraPaymentResponse } from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import { window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import concat from 'ramda/src/concat'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import when from 'ramda/src/when'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { debounce } from 'throttle-debounce'
import Cookies from 'universal-cookie'
import { v4 as uuidv4 } from 'uuid'

import { getPaymentExperience } from '../../util/zuora'
import createOrder, { OrderData } from './createOrder'
import submitAffirmOrder from './submitAffirmOrder'
import submitChaseOrder from './submitChaseOrder'
import submitZuoraOrder from './submitZuoraOrder'
import {
  handlePreactivationCookie,
  handlePreactivationEvents
} from './utils/pre-activation'

// @ts-expect-error TS(2769) FIXME: No overload matches this call.
const cssPath: (_windowOrigin: string) => string = ifElse(
  isNil,
  always(''),
  concat(__, '/ecomm-payment-plugin/payment-form.css?v=2022101903')
)

// @ts-expect-error TS(2769) FIXME: No overload matches this call.
const redirectPath: (_windowOrigin: string) => string = ifElse(
  isNil,
  always(''),
  concat(__, '/ecomm-payment-plugin/payment-callback.html')
)

// @ts-expect-error TS(2769) FIXME: No overload matches this call.
const usCssPath: (_windowOrigin: string) => string = ifElse(
  isNil,
  always(''),
  concat(__, '/ecomm-payment-plugin/payment-form-us.css?v=2022101903')
)

/* To trigger the gtm event when the error is occured */
export const handlePaymentErrorEvent =
  (trackEvent: <T>(_data: T) => void) =>
  <E>(e: E) => {
    trackEvent({
      errorMessage: e,
      event: 'buttonClick'
    })
  }

export const fetchChasePaymentForm =
  (cart: ImmutableCart, locale: Locale, domain: string, secret?: string) =>
  (onError: () => void) =>
  (onSuccess: (_res: Maybe<PaymentFormResponse>) => void) => {
    const billingData = buildPaymentFormAddressData(
      cart.billingAddress.map<Partial<Address>>(addr => addr).orJust({})
    )

    const payload: PaymentFormRequestParams['payload'] =
      locale === 'en-GB'
        ? {
            cssPath: cssPath(path(['location', 'origin'], window)),
            data: billingData,
            orderId: cart.id,
            redirectPath: redirectPath(path(['location', 'origin'], window)),
            secret: secret
          }
        : {
            cssPath: usCssPath(path(['location', 'origin'], window)),
            data: billingData,
            orderId: cart.id,
            redirectPath: redirectPath(path(['location', 'origin'], window)),
            secret: undefined
          }

    paymentFormForkedFetch({
      endpoint: domain,
      method: 'POST',
      payload
    })(e => {
      logError(e)
      onError()
    })(onSuccess)
  }

export const fetchZuoraPaymentForm =
  () =>
  (onError: () => void) =>
  (onSuccess: (_res: Maybe<PaymentMethodResponse>) => void) =>
    fetchPaymentMethod()((e: Error) => {
      logError(e)
      onError()
    })(onSuccess)

export type PaymentState =
  | 'complete'
  | 'error'
  | 'loading'
  | 'processing'
  | 'ready'

const usePayment = (
  cart: RemoteData<ImmutableCart>,
  cookies: Cookies,
  trackEvent: (__data: TrackingData) => void,
  // allows passing in a different value for affirmClient in unit tests
  // @ts-expect-error TS(2339) FIXME: Property 'affirm' does not exist on type 'Window'.
  affirmClient = window.affirm
) => {
  const locale = useSelector(selectLocale)
  const domain = path([locale, 'domain'], localeInfo)
  const sessionId = visitorIdAtAt()

  const [paymentState, setPaymentState] = useState<PaymentState>('loading')
  const [isPaymentFormRequested, setIsPaymentFormRequested] = useState(false)

  const [secret, setsecret] = useState('')
  const [iframeSrc, setIframeSrc] = useState('')
  const [token, settoken] = useState('')
  const [dataCollectorSrc, setDataCollectorSrc] = useState('')
  // Error codes returned by Chase on payment failure
  const [chaseErrorCodes, setChaseErrorCodes] = useState([])

  const [paymentMethodRequired, setPaymentMethodRequired] = useState(true)
  const [zuoraPaymentMethod, setZuoraPaymentMethod] =
    useState<PaymentMethodResponse>()
  const [creditPaymentExperience, setCreditPaymentExperience] =
    useState<PaymentExperience>()
  const [safeTechSdkUrl, setSafeTechSdkUrl] = useState<string>()
  const [windowWidth, setWindowWidth] = useState(window.outerWidth)

  const setChaseErrors = useCallback<(_chaseErrors?: string) => void>(
    (chaseErrors = '') => {
      const errorCodes = chaseErrors.split('|').filter(e => !isNilOrEmpty(e))
      setChaseErrorCodes(errorCodes)
    },
    [setChaseErrorCodes]
  )

  const paymentFormSecret = useRef(uuidv4())
  const affirmCheckoutData = useSelector<
    ImmutableState,
    Maybe<AffirmCheckoutData>
  >(state =>
    selectAffirmCheckoutData(state, {
      financingProgram: ''
    })
  )

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  // Enable logging domain with payment-callback errors https://simplisafe.atlassian.net/browse/ECP-2261
  useEffect(() => setSessionStorage('payment-domain', domain))

  const email = cart
    .chain(safeProp('shippingAddress'))
    .chain((address: Maybe<Address>) => address.chain(safeProp('email')))
    .orUndefined()

  useEffect(() => {
    cart.forEach(cart => {
      const cartPaymentExperience = getPaymentExperience(cart)
      const shouldLog =
        cartPaymentExperience.isNone() && isNil(creditPaymentExperience)

      const log = () => {
        logError(
          new Error(
            `No associated creditPaymentExperience for cart ${cart.get(
              'id'
            )} - falling back to chase.`
          )
        )
      }

      when(equals(true), log, shouldLog)

      setCreditPaymentExperience(cartPaymentExperience.orJust('chase'))
    })
  }, [cart, creditPaymentExperience])

  useEffect(() => {
    cookies.set('email', email, cookiesOption)
  }, [cookies, email])

  // For Zuora, the SafeTech SDK URL is managed by the payment decorator API
  useEffect(() => {
    const isZuora = creditPaymentExperience === 'zuora'
    const shouldFetch = isZuora && isNil(safeTechSdkUrl)

    const fetch = () => {
      fetchSafeTechCollectorInfo({
        domain,
        sessionId
      })(() => {
        setSafeTechSdkUrl('')
      })((response: Maybe<SafeTechCollectorInfoResponse>) => {
        response.forEach(response => {
          setSafeTechSdkUrl(response.sdkUrl)
        })
      })
    }

    when(equals(true), () => fetch(), shouldFetch)
  }, [creditPaymentExperience, domain, safeTechSdkUrl, sessionId])

  // Fetch the Zuora payment method
  useEffect(() => {
    const isZuora = creditPaymentExperience === 'zuora'
    const shouldFetch = isZuora && paymentMethodRequired

    const fetch = () => {
      setPaymentState('loading')
      setPaymentMethodRequired(false)

      fetchZuoraPaymentForm()(() => {
        setPaymentState('error')
      })(response => {
        response.forEach((response: PaymentMethodResponse) => {
          setZuoraPaymentMethod(response)
        })
      })
    }

    when(equals(true), () => fetch(), shouldFetch)
  }, [creditPaymentExperience, paymentMethodRequired])

  // Fetch the Chase iframe data
  useEffect(() => {
    cart.forEach(cart => {
      const isChase = creditPaymentExperience === 'chase'
      const shouldFetch = isChase && !isPaymentFormRequested

      const fetch = () => {
        fetchChasePaymentForm(
          cart,
          locale,
          domain,
          paymentFormSecret.current
        )(() => {
          setPaymentState('error')
        })(response => {
          response.forEach(res => {
            safeProp('iframeSrc', res).forEach(setIframeSrc)
            safeProp('secret', res).forEach(setsecret)
            safeProp('token', res).forEach(settoken)
            safeProp('dataCollectorSrc', res).forEach(setDataCollectorSrc)
          })
          setPaymentState('ready')
        })

        setIsPaymentFormRequested(true)
      }

      when(equals(true), () => fetch(), shouldFetch)
    })
  }, [cart, creditPaymentExperience, isPaymentFormRequested, domain, locale])

  const handleResizeDebounced = debounce(500, () => {
    when(equals(false), () => {
      const zuoraIFrame: Maybe<HTMLElement> = Maybe.fromNull(
        document.getElementById('z_hppm_iframe')
      )
      zuoraIFrame.forEach((iframeDOMElement: HTMLElement) => {
        // Remove the iframe from the DOM before re-rendering it to prevent issue
        // where loading begins before the old iframe is removed
        iframeDOMElement.remove()
        // Trigger a re-render of the payment form
        setPaymentMethodRequired(true)
        // Track the window width through a React state to avoid to only re-render
        // when the width has changed, preventing issues on mobile
        setWindowWidth(window.outerWidth)
      })
    })(windowWidth === window.outerWidth)
  })

  const resizeRef = useRef(handleResizeDebounced)

  useEffect(() => {
    resizeRef.current = handleResizeDebounced
    const resizeHandler = () => resizeRef.current()
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [windowWidth, handleResizeDebounced])

  const handleError = useCallback(
    (error: Error) => {
      setPaymentState('loading')
      handlePaymentErrorEvent(trackEvent)(error)
    },
    [trackEvent]
  )

  /* Trigger GTM/Optimizely events and set the preactivation cookie */
  const handlePreactivationReady = useCallback(
    (webappToken: string) => {
      handlePreactivationEvents(trackEvent)(optimizelyTrackSiteEvents)
      handlePreactivationCookie(cookies)(webappToken)
    },
    [cookies, trackEvent, optimizelyTrackSiteEvents]
  )

  /* Partially applied `createOrder` call. The returned function takes order data (e.g. chase token, transaction secret) and submits the order. */
  const handleCreateOrder = useCallback(
    (orderData: OrderData) =>
      createOrder({
        cartId: cart.chain<string>(safeProp('id')).orJust(''),
        // Toggles payment complete message while post payment flows initialize
        onPaymentComplete: () => setPaymentState('complete'),
        onPaymentError: handleError,
        onPreactivationReady: handlePreactivationReady
      })(orderData),
    [cart, handleError, handlePreactivationReady]
  )

  const handleSubmitChaseOrder = useCallback(() => {
    // clear any existing errors before processing the order
    setChaseErrorCodes([])

    submitChaseOrder({
      chaseToken: token,
      createOrder: handleCreateOrder,
      locale,

      onPaymentError: handleError,
      // Controls the loader when payment has been submitted
      onPaymentProcessing: () => setPaymentState('processing'),
      transactionSecret: secret
    })
  }, [handleError, handleCreateOrder, secret, token, locale])

  const handleSubmitZuoraOrder = useCallback(
    (zuoraResponse: ZuoraPaymentResponse) => {
      submitZuoraOrder({
        createOrder: handleCreateOrder,
        onFormLoadError: () => setPaymentState('error'),
        onPaymentError: handleError,
        // Controls the loader when payment has been submitted
        onPaymentProcessing: () => setPaymentState('processing'),
        trackEvent,
        zuoraResponse
      })
    },
    [handleError, handleCreateOrder, trackEvent]
  )

  const handleZuoraFormRender = useCallback(() => {
    when(equals(true), () => setPaymentState('ready'), paymentState !== 'error')
  }, [paymentState])

  const handleSubmitAffirmOrder = useCallback(
    setFormSubmitted => {
      const onCanceled = () => {
        setFormSubmitted && setFormSubmitted(false)
        return setPaymentState('ready')
      }
      const onError = (error: Error) => {
        setFormSubmitted && setFormSubmitted(false)
        return handleError(error)
      }
      const onProcessing = () => {
        setFormSubmitted && setFormSubmitted(true)
        return setPaymentState('processing')
      }

      submitAffirmOrder({
        affirmCheckoutData,
        affirmClient,
        createOrder: handleCreateOrder,
        onPaymentCanceled: onCanceled,
        onPaymentError: onError,
        // Controls the loader when payment has been submitted
        onPaymentProcessing: onProcessing
      })
    },
    [affirmCheckoutData, affirmClient, handleCreateOrder, handleError]
  )

  useLayoutEffect(() => {
    /**
     * For use in payment-callback.ts for Chase credit card orders.
     */
    window['submit-chase-order'] = handleSubmitChaseOrder
  }, [handleSubmitChaseOrder])

  useLayoutEffect(() => {
    /**
     * For use in payment-callback.ts for Chase credit card orders.
     */
    window['handle-chase-errors'] = setChaseErrors
  }, [setChaseErrors])

  return {
    chaseErrorCodes,
    creditPaymentExperience,
    dataCollectorSrc,
    domain,
    handleSubmitAffirmOrder,
    handleSubmitZuoraOrder,
    handleZuoraFormRender,
    iframeSrc,
    paymentState,
    safeTechSdkUrl,
    token,
    zuoraPaymentMethod
  }
}

export default usePayment
