import { currencyCode, getLocale, noValue } from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import values from 'ramda/src/values'

import type {
  Ecommerce,
  Product as GtmProduct,
  TrackingData
} from './types/tracking'

export type GtmData = {
  readonly event: string
  readonly eventCategory?: string
  readonly eventAction?: string
  readonly eventLabel?: string
  readonly ecommerce?: unknown
}

export type VariantItem = {
  readonly id?: string
  readonly name?: unknown
  readonly quantity?: number
  readonly sku: string
  readonly price?: number
}

export type CTLineItemChild = {
  readonly name: Record<string, string>
  readonly quantity: number
  readonly sku: string
}

export type productGTM = {
  readonly id?: string
  readonly name?: string
  readonly price?: number
  readonly quantity?: number
  readonly variant?: readonly VariantItem[]
  readonly category?: string
  readonly list?: string
  readonly brand?: string
}

export type GtmCustomEvent = {
  readonly event: 'buttonClick' | 'linkClick'
  readonly eventAction?: string
  readonly eventCategory: string
  readonly eventLabel?: string
}

export type TrackEvent = (
  _data: Partial<Record<string, unknown> | TrackingData>
) => void

const BMS_NAME = 'bms'

export const getCommerceDataFromPackageWithExtras = (
  _package: Package,
  product: Product,
  lineItems: readonly Maybe<Product>[],
  isBms: boolean,
  action: string,
  selectState: ImmutableState,
  packageFinalPrice?: number,
  applyPackageBrandtoExtras = false
): Ecommerce => {
  const packageProducts = packageToEcommProducts(
    _package,
    product,
    isBms,
    selectState,
    packageFinalPrice
  )
  const extrasPrice = isBms ? 0 : noValue()
  const brand =
    applyPackageBrandtoExtras && packageProducts.length > 0
      ? packageProducts[0].brand
      : noValue()
  const extraProducts = lineItems
    .filter(m => m.isSome())
    .map(p => p.some())
    .map(lineItem =>
      productToEcommProduct(
        lineItem,
        propOr(0, 'quantity', lineItem),
        isBms ? BMS_NAME : brand,
        extrasPrice
      )
    )
  const packageDetails = { products: packageProducts.concat(extraProducts) }
  return {
    [action]: packageDetails,
    currencyCode
  }
}

export const getCommerceDataFromProduct = (
  product: Product,
  action: string,
  quantity: number
): Ecommerce => {
  const price = prop('price')(product)
  const discountedPrice = prop('discountedPrice', product)
  const productPrice = ifElse(isNil, always(price), () =>
    discountedPrice.fold(price)(response => response)
  )(discountedPrice)
  const products = getProductsGTMFromProduct(
    {
      ...product,
      price: productPrice
    },
    quantity
  )
  return {
    [action]: products,
    currencyCode
  }
}

/**
 * Take ProductName object (keyed by locale) and safely return
 * the name for the locale
 */
export const getNameFromProductName =
  (locale: string) => (nameObject: Record<string, string>) =>
    safeProp(locale, nameObject)

/* ####### BEGIN PACKAGE HELPERS ####### */

export const getPackagePrice =
  (hasMonitoring: boolean) => (_package: Package) =>
    (hasMonitoring
      ? prop('discountedPriceWithServicePlan', _package)
      : prop('discountedPrice', _package)
    ).getOrElse(prop('price', _package))

/**
 * Convert a Product and PackageProduct to a Record<string, unknown> variant
 */
const productToVariant = (product: Product, includedProduct: PackageProduct) =>
  transformObject<Product, Record<string, unknown>>({
    name: product => propOr({}, 'name', product),
    price: () => 0,
    quantity: () => propOr(0, 'quantity', includedProduct),
    sku: () => propOr('', 'sku', includedProduct)
  })(product)

/**
 * Convert a PackageProduct to a Record<string, unknown> variant using state
 */
const packageProductToVariant = (
  includedProduct: PackageProduct,
  state: ImmutableState
) =>
  safeProp('sku', includedProduct)
    .chain(sku => selectProduct(sku)(state).toMaybe())
    .map(product => productToVariant(product, includedProduct))

/**
 * Convert a Package's included products to an array of Record<string, unknown> variants using state
 */
const includedProductsToVariants = (_package: Package, state: ImmutableState) =>
  safeProp('products', _package)
    .map(includedProducts =>
      includedProducts
        .map(includedProduct =>
          packageProductToVariant(includedProduct, state).orNull()
        )
        .filter(isNotNil)
    )
    .getOrElse([])

/**
 * Convert a Package to a GtmProduct including variants
 */
const packageToEcommProduct = (
  _package: Package,
  brand: string,
  state: ImmutableState,
  packagePrice?: number
): GtmProduct => {
  const variants = includedProductsToVariants(_package, state)

  return {
    brand: brand,
    id: propOr('', 'sku', _package),
    name: brand,
    price: packagePrice ? packagePrice : propOr(0, 'price', _package),
    quantity: 1,
    // TODO ecomm-ts-types.GtmProduct.variant is a string but we've always treated it as an object
    // @ts-expect-error TS(2322): Type 'Record<string, unknown>[]' is not assignable... Remove this comment to see the full error message
    variant: variants
  }
}

/**
 * Convert a Product/PackageProduct pair to a GtmProduct
 */
const packageProductToEcommProduct = (
  product: Product,
  packageProduct: PackageProduct,
  brand: string
) =>
  transformObject<Product, GtmProduct>({
    brand: () => brand,
    id: () => propOr('', 'sku', packageProduct),

    name: product => pathOr('', ['name', getLocale()], product),
    price: () => 0,
    quantity: () => propOr(0, 'quantity', packageProduct)
  })(product)

/**
 * Convert a Package's included products to an array of GtmProduct using state
 */
const includedProductsToEcommProducts = (
  _package: Package,
  brand: string,
  state: ImmutableState
) =>
  safeProp('products', _package)
    .map(includedProducts =>
      includedProducts
        .map(includedProduct =>
          safeProp('sku', includedProduct)
            .chain(sku => selectProduct(sku)(state).toMaybe())
            .map(product =>
              packageProductToEcommProduct(product, includedProduct, brand)
            )
            .orNull()
        )
        .filter(isNotNil)
    )
    .getOrElse([])

/**
 * Convert a Package/Product pair to an array of GtmProduct. Includes an entry for the package itself -- with
 * included items as variants within -- as well as entries for incuded items in parallel.
 */
export const packageToEcommProducts = (
  _package: Package,
  packageProduct: Product,
  isBms: boolean,
  state: ImmutableState,
  packagePrice?: number
) =>
  Maybe.fromNull(_package)
    .chain(_package =>
      Maybe.fromNull(packageProduct).map(packageProduct => {
        const packageName = isBms
          ? BMS_NAME
          : safeProp('name', packageProduct)
              .chain(getNameFromProductName(getLocale()))
              .getOrElse('')

        const packageEntry = packageToEcommProduct(
          _package,
          packageName,
          state,
          packagePrice
        )

        const includedProductEntries = includedProductsToEcommProducts(
          _package,
          packageName,
          state
        )

        return [packageEntry].concat(includedProductEntries)
      })
    )
    .getOrElse([])

/* ####### END PACKAGE HELPERS ####### */

/* ####### BEGIN PRODUCT HELPERS ####### */

const getProductsGTMFromProduct = (product: Product, quantity: number) => {
  const productObject = productToEcommProduct(product, quantity)
  return { products: values({ productObject }) }
}

const productToEcommProduct = (
  product: Product,
  quantity: number,
  brand?: string,
  price?: number
) =>
  transformObject<Product, GtmProduct>({
    brand: () => brand,
    id: () => propOr('', 'masterSku', product),
    name: product => pathOr('', ['name', getLocale()], product),
    price: product =>
      Maybe.fromNull(price).getOrElse(propOr(0, 'price', product)),
    quantity: () => quantity
  })(product)

/* ####### END PRODUCT HELPERS ####### */
