import {
  selectPromoDiscountText,
  selectPromoWithMonitoringDiscountText
} from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectDisplayMonitoringDiscount } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

const regexMonitoringDiscount = /(?:{{monitoring_discount}})/g

/**
 * Will replace any {{monitoring_discount}} occurrences in text with the selectPromoWithMonitoringDiscountText
 * return value if selectDisplayMonitoringDiscount is true. Otherwise it will use selectPromoDiscountText return value
 * and if that resolves to None, replace them with defaultsTo
 *
 * @param text        string
 * @param defaultsTo  string
 * @returns           string
 */
export const usePlaceholderForMonitoringDiscount = (
  text: string,
  defaultsTo = ''
) => {
  const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
  const discountWithMonitoringText = useSelector(
    selectPromoWithMonitoringDiscountText
  )
  const discountText = useSelector(selectPromoDiscountText)

  const placeholderText = displayMonitoringDiscount
    ? discountWithMonitoringText
    : discountText

  return text.replace(
    regexMonitoringDiscount,
    placeholderText.orSome(defaultsTo)
  )
}
