import type {
  AffirmClient,
  CheckoutFailureResponse,
  CheckoutSuccessResponse
} from '@lib/components'
import { safeProp } from '@simplisafe/monda'
import type { AffirmCheckoutData } from '@simplisafe/ss-ecomm-data/affirm'
import { Maybe } from 'monet'
import equals from 'ramda/src/equals'

import {
  AffirmOrderData,
  forwardToPaymentErrorUrl,
  logErrorWithOrderInfo
} from './createOrder'

const handleError = (error: Error, onError: (e: Error) => void) => {
  logErrorWithOrderInfo(error)
  onError(error)
  forwardToPaymentErrorUrl(error)
}

const submitAffirmOrder = ({
  affirmCheckoutData,
  affirmClient,
  createOrder,
  onPaymentCanceled,
  onPaymentError,
  onPaymentProcessing
}: {
  readonly affirmCheckoutData: Maybe<AffirmCheckoutData>
  readonly affirmClient?: AffirmClient
  readonly createOrder: (orderData: AffirmOrderData) => void
  readonly onPaymentCanceled: () => void
  readonly onPaymentError: (e: Error) => void
  readonly onPaymentProcessing: () => void
}) => {
  affirmCheckoutData.forEach(_affirmData => {
    affirmClient && affirmClient.checkout(_affirmData)
  })

  affirmClient &&
    affirmClient.checkout.open({
      onFail: (e: CheckoutFailureResponse | undefined) => {
        safeProp('reason', e)
          .filter(equals('canceled'))
          .cata(() => {
            const error = Error(
              `submitAffirmOrder: Affirm checkout failed - ${JSON.stringify(e)}`
            )
            handleError(error, onPaymentError)
          }, onPaymentCanceled)
      },
      onOpen: onPaymentProcessing,
      onSuccess: (res: CheckoutSuccessResponse | undefined) => {
        safeProp('checkout_token', res).cata(
          () => {
            const error = Error(
              'submitAffirmOrder: Affirm checkout response missing checkout_token'
            )
            handleError(error, onPaymentError)
          },
          token =>
            createOrder({
              affirmCheckoutToken: token,
              type: 'affirm'
            })
        )
      }
    })
}

export default submitAffirmOrder
