import { Column, Row } from '@simplisafe/ss-react-components'
import { Maybe } from 'monet'
import React from 'react'

const IFRAME_HEIGHT = '830px'

function IframePlaceholder({ iframeUrl }: { readonly iframeUrl: string }) {
  return Maybe.fromFalsy(iframeUrl)
    .map((url: string) => (
      <Row alignItems="center" key={url}>
        <Column shadow="soft">
          <iframe
            frameBorder="0"
            height={IFRAME_HEIGHT}
            src={url}
            width="100%"
          />
        </Column>
      </Row>
    ))
    .orNull()
}

export default IframePlaceholder
