import { Address, ShippingInfo } from '@commercetools/platform-sdk'
import { TrackingData } from '@lib/tracking'
import { useEnv } from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import propOr from '@simplisafe/ewok/ramda/propOr'
import isNilOrEmpty from '@simplisafe/ewok/ramda-adjunct/isNilOrEmpty'
import { safeProp } from '@simplisafe/monda'
import {
  getCartDetails,
  ImmutableCart
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  selectCart,
  selectHiddenProductSkus
} from '@simplisafe/ss-ecomm-data/redux/select'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import {
  BannerError,
  LoadingSpinner,
  OrderSummary,
  OrderSummaryRedesign
} from '@simplisafe/ss-react-components'
import {
  CouponCodeField,
  OrderSummaryProps,
  PriceCalculationField,
  ShippingDetailsProps
} from '@simplisafe/ss-react-components/OrderSummary'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import applySpec from 'ramda/src/applySpec'
import pathOr from 'ramda/src/pathOr'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulCheckoutOrderDetails } from '../../../graphql'
import { formatDisplayPrice } from '../../commercetools/price'
import {
  trackPaymentPageView,
  trackShippingPageView
} from '../../util/analytics'
import {
  getCartDiscountCode,
  getCartDiscountValue,
  getShippingInfoPrice,
  toItemList
} from '../CartDetailsComponent/transformLineItem'

type CheckoutOrderDetailProps = {
  readonly data: ContentfulCheckoutOrderDetails
  readonly isOrderSummaryRedesign?: boolean
}

type PriceCalculationProps = {
  readonly checkoutShippingValue: string
  readonly couponCode: string
  readonly discountLabel: string
  readonly discountValue: string
  readonly shippingLabel: string
  readonly subtotal: string
  readonly total: string
  readonly vAT: string
}

// TODO this needs to be exported and unit tested
// TODO this needs a type for the object being passed to the functin this returns
const toShippingAddressData = applySpec<ShippingDetailsProps>({
  addressLine1: prop('streetName'),
  addressLine2: prop('additionalStreetInfo'),
  city: prop('city'),
  email: prop('email'),
  firstName: prop('firstName'),
  lastName: prop('lastName'),
  phoneNumber: prop('phone'),
  postalCode: prop('postalCode'),
  state: prop('state')
})

const toOrderSummaryProps = applySpec<OrderSummaryProps>({
  section2Title: prop('section2Title')
})

const toPriceCalculationFields = (
  values: PriceCalculationProps,
  data: ContentfulCheckoutOrderDetails
): readonly PriceCalculationField[] =>
  [
    {
      label: propOr('', 'subtotalLabel')(data),
      value: values.subtotal
    },
    {
      couponCode: values.couponCode,
      isDiscount: true,
      label: values.discountLabel,
      value: values.discountValue
    },
    {
      label: values.shippingLabel,
      value: values.checkoutShippingValue
    },
    {
      label: propOr('', 'vatLabel')(data),
      value: values.vAT
    },
    {
      label: propOr('', 'totalLabel')(data),
      value: values.total
    }
  ].filter(({ value }) => !isNilOrEmpty(value))

const toCouponCodeField = (
  { couponCode }: PriceCalculationProps,
  data: ContentfulCheckoutOrderDetails
): CouponCodeField | undefined =>
  !isNilOrEmpty(couponCode)
    ? {
        content: {
          label: propOr('', 'promoCodeLabel')(data),
          value: couponCode
        },
        imgUrl: pathOr('', ['promoCodeIcon', 'file', 'url'], data)
      }
    : undefined

export const getShipppingLabel = (
  shippingInfoPrice: Maybe<number>,
  shippingInfo: Maybe<ShippingInfo>
) =>
  shippingInfoPrice.map(val =>
    val > 0
      ? shippingInfo
          .map(info => `${prop('shippingMethodName', info)}:`)
          .getOrElse('')
      : // TODO get this from CTFL
        'Free Shipping:'
  )

export const handleShippingPageView = (
  cart: RemoteData<ImmutableCart>,
  trackEvent: (_trackingData: Partial<TrackingData>) => void,
  setHasViewedShippingPage: React.Dispatch<React.SetStateAction<boolean>>,
  setHasViewedPaymentPage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  cart.forEach(response => {
    setHasViewedShippingPage(true)
    setHasViewedPaymentPage(false)
    const itemList = response.lineItems
    trackShippingPageView(itemList)(trackEvent)
  })
}

export const handlePaymentPageView = (
  cart: RemoteData<ImmutableCart>,
  trackEvent: (_trackingData: Partial<TrackingData>) => void,
  setHasViewedShippingPage: React.Dispatch<React.SetStateAction<boolean>>,
  setHasViewedPaymentPage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  cart.forEach(response => {
    setHasViewedShippingPage(false)
    setHasViewedPaymentPage(true)
    const itemList = response.lineItems
    trackPaymentPageView(itemList)(trackEvent)
  })
}

function CheckoutOrderDetails({
  data,
  isOrderSummaryRedesign
}: CheckoutOrderDetailProps) {
  const { locale } = useEnv()

  const propsOrderSummary = toOrderSummaryProps(data)
  const cart: RemoteData<ImmutableCart> = useSelector(selectCart)
  const hiddenProductSkus = useSelector(selectHiddenProductSkus)

  const { Track, trackEvent } = useTracking()
  const [hasViewedShippingPage, setHasViewedShippingPage] = useState(false)
  const [hasViewedPaymentPage, setHasViewedPaymentPage] = useState(false)

  useEffect(() => {
    const checkShippingPage = () => {
      !hasViewedShippingPage &&
        window.location.pathname === '/cart/checkout' &&
        handleShippingPageView(
          cart,
          trackEvent,
          setHasViewedShippingPage,
          setHasViewedPaymentPage
        )
    }

    const checkPaymentPage = () => {
      !hasViewedPaymentPage &&
        window.location.pathname === '/payment-page' &&
        handlePaymentPageView(
          cart,
          trackEvent,
          setHasViewedShippingPage,
          setHasViewedPaymentPage
        )
    }

    checkShippingPage()
    checkPaymentPage()
  }, [cart, hasViewedPaymentPage, hasViewedShippingPage, trackEvent])

  const cartRetrievalErrorMessage = () => {
    const cartRetrievalErrorPhoneNumber: string =
      locale === 'en-GB' ? '0800-920-2420' : '888-910-1458'
    const retrievalErrorHeading =
      "Something isn't right. Try reloading the page."
    const supportText = `If the issue persists, please call ${cartRetrievalErrorPhoneNumber} to complete your order.`
    return (
      <div>
        <h2>{retrievalErrorHeading}</h2>
        <p>{supportText}</p>
      </div>
    )
  }

  const linkText = safeProp('seePackageDetailLinkText', data).orUndefined()

  const section1Title = safeProp('section1Title', data).getOrElse('')

  return (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <div>
        {cart.cata(
          () => (
            <div className="alignCenter">
              <h2>Loading your cart...</h2>
              <LoadingSpinner />
            </div>
          ),
          () => (
            <BannerError height="responsive">
              {cartRetrievalErrorMessage()}
            </BannerError>
          ),
          // If the cart is empty, there's logic elsewhere to redirect the user to the /cart page
          () => (
            <div className="alignCenter">
              <h2>Loading your cart...</h2>
              <LoadingSpinner />
            </div>
          ),
          (x: ImmutableCart) => {
            const itemList = toItemList(
              getCartDetails(hiddenProductSkus)(x.lineItems)
            )(trackEvent, undefined, undefined, linkText).getOrElse([])
            const total = prop('totalPrice', x)
            const shippingAddress = toShippingAddressData(
              x.shippingAddress.map<Partial<Address>>(addr => addr).orJust({})
            )
            const shippingSectionTitle = safeProp(
              'addressLine1',
              shippingAddress
            ).cata(
              () => '',
              () => section1Title
            )
            const subTotal = prop('subTotal', x)
            const taxedPrice = prop('taxedPrice', x).getOrElse(0)
            const discountValue = getCartDiscountValue(x)
            const discountCode = getCartDiscountCode(x)

            const couponLabelText =
              isOrderSummaryRedesign === true
                ? `${propOr('', 'discountLabel')(data)}`
                : `${discountCode} ${propOr('', 'discountLabel')(data)}`

            // FYI getShippingInfoPrice comes back as a Just, even if it's 0
            const shippingInfoPrice = getShippingInfoPrice(x)
            const shippingPrice = shippingInfoPrice.chain(formatDisplayPrice)
            const shippingLabel = getShipppingLabel(
              shippingInfoPrice,
              x.shippingInfo
            )

            const priceCal = {
              checkoutShippingValue: shippingPrice.getOrElse(''),
              couponCode: Maybe.fromUndefined(discountCode).getOrElse(''),
              discountLabel: couponLabelText,
              discountValue: Maybe.fromNull(discountValue).getOrElse(''),
              shippingLabel: shippingLabel.getOrElse(''),
              subtotal: formatDisplayPrice(subTotal).getOrElse(''),
              total: formatDisplayPrice(total).getOrElse(''),
              vAT: formatDisplayPrice(taxedPrice).getOrElse('')
            }

            const couponField = toCouponCodeField(priceCal, data)
            const priceCalculationFields = toPriceCalculationFields(
              priceCal,
              data
            )

            return isOrderSummaryRedesign === true ? (
              <OrderSummaryRedesign
                {...propsOrderSummary}
                couponField={couponField}
                itemList={itemList}
                priceCalculationFields={priceCalculationFields}
                section1Title={shippingSectionTitle}
                shippingDetails={shippingAddress}
              />
            ) : (
              <OrderSummary
                {...propsOrderSummary}
                collapseOnDesktop={true}
                couponField={couponField}
                itemList={itemList}
                priceCalculationFields={priceCalculationFields}
                section1Title={shippingSectionTitle}
                shippingDetails={shippingAddress}
              />
            )
          }
        )}
      </div>
    </Track>
  )
}

export const query = graphql`
  #graphql
  fragment checkoutOrderDetails on ContentfulCheckoutOrderDetails {
    id
    internal {
      type
    }
    title
    section1Title
    section2Title
    promoCodeIcon {
      file {
        url
      }
    }
    promoCodeLabel
    subtotalLabel
    discountLabel
    vatLabel
    totalLabel
    seePackageDetailLinkText
  }
`
export default CheckoutOrderDetails
