import { useLocation } from '@reach/router'
import { window } from 'browser-monads-ts'
import { useEffect } from 'react'

const useScrollToHash = () => {
  const { hash } = useLocation()

  useEffect(() => {
    const element = document.getElementById(hash?.replace('#', '')) || undefined
    function scrollToElement(element: HTMLElement) {
      const timer = setTimeout(
        () =>
          window.scrollTo({
            behavior: 'smooth',
            top: element.offsetTop
          }),
        0
      )
      return () => clearTimeout(timer)
    }

    return element && scrollToElement(element)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useScrollToHash
