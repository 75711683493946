import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Price } from '@simplisafe/ss-react-components'
import React from 'react'

//OldVersion.tsx uses these options as well as a way to solve this quickly for gatsby-4-upgrade
export const linkContentRichTextOptions = (showPopUp: Function): Options => ({
  renderMark: {
    [MARKS.BOLD]: text => (
      <button
        className="text-body-size hover:no-underline appearance-none bg-transparent border-0 text-white cursor-pointer font-medium p-0 underline"
        key={`key-b-${text}`}
        onClick={() => showPopUp()}
      >
        {text}
      </button>
    )
  }
})

export const priceCalculationCustomOptions = (
  priceList: Record<string, string | undefined>,
  discountText?: string
): Options => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const isPlaceHolder =
        node.data?.target?.__typename === 'ContentfulPlaceholder'
      const type: string = node.data?.target?.type
      const label: string = node.data?.target?.label
      const price: string = priceList[type] ?? ''
      const priceNode = <span className="ml-2">{price}</span>

      const element =
        type === 'Discount Text & Value' ? (
          discountText && (
            <div className="text-sale">
              <span className="text-right inline-block min-w-[70px]">
                {discountText}
              </span>
              {priceNode}
            </div>
          )
        ) : (
          <div className="first:pt-2">
            <span className="text-right inline-block min-w-[70px]">
              {label}
            </span>
            {priceNode}
          </div>
        )

      return isPlaceHolder && price && element
    }
  }
})

export const interactivePlanCustomOptions = (
  placeholderText: string
): Options => ({
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: node => {
      const isPlaceHolder =
        node.data?.target?.__typename === 'ContentfulPlaceholder'
      return isPlaceHolder && <Price discountedPrice={placeholderText} />
    }
  }
})
