import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import {
  CartUpdatedMessage,
  Heading,
  LoadingSpinner,
  QuantityChanger,
  SimpleButton,
  Text
} from '@simplisafe/ss-react-components'
import T from 'ramda/src/T'
import React from 'react'

import { ContentfulModalSensorAdditionToSystem } from '../../../graphql'
import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import AddToCartError from '../../errorComponents/AddToCartError'
import useRequestPrice from '../../hooks/useRequestPrice'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import ContentfulRichText from '../ContentfulRichText'
import { getDefaultProps } from './helpers'
import { useAddExtraSensors } from './hooks'

export type AddExtraSensorsComponentProps = {
  readonly data: ContentfulModalSensorAdditionToSystem
}

export default function AddExtraSensorsComponent({
  data
}: AddExtraSensorsComponentProps) {
  const {
    additionalIconFixedProp,
    headerText,
    maximumQuantity,
    outOfStockButtonText,
    outOfStockText,
    defaultQuantity,
    cartUpdatedText,
    sku
  } = getDefaultProps(data)

  const {
    addToCart,
    addToCartError,
    isCartUpdateSuccess,
    isCartable,
    product,
    Track,
    showSpinner,
    setQuantity,
    quantity
  } = useAddExtraSensors(sku, defaultQuantity)

  const { getFormattedPrice } = useRequestPrice(sku.orJust(''))
  const price = getFormattedPrice(priceDefault)

  const outOfStockMessage = renderOutOfStockMessage({ product: product })

  const additionalText = (
    <ContentfulRichText raw={path(['note', 'text', 'raw'], data)} />
  )
  const errorMessage = addToCartError && (
    <AddToCartError errorType={addToCartError} textAlign="center" />
  )
  const isOnStock = product
    .map(p => prop('isOnStock', p))
    .cata(T, _isOnStock => _isOnStock)
  const disabled: boolean = !isOnStock || !isCartable

  return safeProp('button', data)
    .map(button => {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      const buttonText =
        disabled && outOfStockButtonText
          ? outOfStockButtonText
          : prop('buttonText', button)
      const maxQuantity = disabled ? 0 : maximumQuantity

      // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
      return (
        <Track>
          <div className="flex flex-col items-center max-w-lg px-8 py-11 lg:px-16">
            <div className="w-full">
              <Heading
                element="h2"
                headingAspect="h3"
                margin="tiny"
                useTailwind
              >
                {headerText}
              </Heading>
            </div>
            <div className="flex flex-wrap items-center justify-between w-full gap-4 my-8">
              <Text useTailwind>
                <b>{price}</b>
                {/* validations here */}
                {outOfStockMessage && (
                  <small className="ml-2">{outOfStockMessage}</small>
                )}
                {disabled && outOfStockText && (
                  <p className="m-0">{outOfStockText}</p>
                )}
              </Text>
              <QuantityChanger
                defaultValue={defaultQuantity}
                max={maxQuantity}
                min={0}
                onChange={q => setQuantity(q)}
              />
              <div className="relative w-full lg:basis-2/5">
                <SimpleButton
                  disabled={disabled || quantity < 1}
                  onClick={addToCart}
                  size="full"
                  variant="solid"
                >
                  {showSpinner ? (
                    <LoadingSpinner fillColor="currentColor" size={24} />
                  ) : (
                    buttonText
                  )}
                </SimpleButton>
                {isCartUpdateSuccess && !errorMessage && (
                  <div className="absolute left-0 w-full text-center -bottom-5">
                    <CartUpdatedMessage message={cartUpdatedText} />
                  </div>
                )}
              </div>
            </div>
            {errorMessage}
            {additionalText && (
              <div className="flex items-center w-full">
                <GatsbyImage
                  className="h-12 mr-4 max-w-[30px]"
                  image={additionalIconFixedProp}
                  imgStyle={{ objectFit: 'contain' }}
                />
                <Text className="-mb-4" useTailwind>
                  {additionalText}
                </Text>
              </div>
            )}
          </div>
        </Track>
      )
    })
    .orNull()
}
