import React from 'react'

function FourZeroFourPage(__) {
  return (
    <div>
      <h1>404</h1>
      <p>Not found</p>
    </div>
  )
}

export default FourZeroFourPage
