import { useOptimizelyParams, userAttributes } from '@lib/tracking'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { selectCustomerGroupKey } from '@simplisafe/ss-ecomm-data/cart/select'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import {
  GiftItemDTO,
  Prices,
  requestPrices
} from '@simplisafe/ss-ecomm-data/prices/service'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { selectItemsFromSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import { Price } from '@simplisafe/ss-react-components'
import { constant, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { Maybe, None } from 'monet'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'

import {
  absoluteDiscountToRelative,
  formatDisplayPrice,
  formatPercentage
} from '../../commercetools/price'
import useIsPartner from '../../hooks/useIsPartner'
import { findFirstJust } from '../../util/helper'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import { usePriceVariations } from './../../hooks/usePriceVariation'
import { formatPrice, PriceFormatter } from './formatter'
import { PriceContextProps } from './types'
import {
  divideByFractionDigits,
  getPriceWithFractionDigits,
  giftMatchesSku,
  isPLAsku,
  mergePriceVariations
} from './utils'

const PriceContext = createContext<PriceContextProps>({
  getDiscountedPrice: (_sku: string) => None(),
  getDiscountedPriceWithServicePlan: (_sku: string) => None(),
  getDiscountedText: (_sku: string) => None(),
  getDiscountedTextWithServicePlan: (_sku: string) => None(),
  getFormattedPrice: _skuID => (_formatter, _showDiscountedPrice) => null,
  getFreeGiftItems: (_sku: string): Maybe<GiftItemDTO> => None(),
  getFreeGiftItemsWithServicePlan: (_sku: string): Maybe<GiftItemDTO> => None(),
  getPrice: (_sku: string) => None()
})

export const usePriceContext = () => useContext(PriceContext)

/**
 * Renders formatted price details for the given sku, optionally including strikethrough price (default: true) and service plan discount (default: false).
 */
export const getFormattedPriceHelper =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string) =>
    function (
      formatter: PriceFormatter,
      showDiscountedPrice = true,
      isServiceDiscount = true
    ) {
      const price = getRawPrice(prices, fallbacks, isLoading)(sku)
      const discountedPrice = getRawDiscountedPrice(
        prices,
        fallbacks,
        isLoading
      )(sku)
      const discountedPriceWithServicePlan =
        getRawDiscountedPriceWithServicePlan(prices, fallbacks, isLoading)(sku)
      getFreeGiftItemHelper(prices)(sku)
      getFreeGiftItemWithServicePlanHelper(prices)(sku)
      const finalDiscountedPrice =
        isServiceDiscount && discountedPriceWithServicePlan.isSome()
          ? discountedPriceWithServicePlan
          : discountedPrice

      const hasDiscount = finalDiscountedPrice
        .map(discountedPrice =>
          price.cata(
            () => false,
            _price => discountedPrice !== _price
          )
        )
        .orJust(false)

      return (
        <Price
          discountedPrice={
            showDiscountedPrice && hasDiscount
              ? formatPrice(finalDiscountedPrice, formatter)
              : undefined
          }
          regularPrice={formatPrice(price, formatter)}
        />
      )
    }

const getPriceData = (sku: string, prices: Prices) =>
  safeProp(sku, prices).filter(priceData => isNotNil(priceData.price))

export const getRawPrice =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string) =>
    getPriceData(sku, prices).cata<Maybe<number>>(
      () =>
        isPLAsku(sku) && isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(safeProp('price')),
      _priceData =>
        safeProp('price', _priceData).map(divideByFractionDigits(sku, prices))
    )

export const getRawDiscountedPrice =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string) =>
    getPriceData(sku, prices).cata<Maybe<number>>(
      () =>
        isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(chainProp('discountedPrice')),
      _priceData =>
        safeProp('discountedPrice', _priceData).map(
          divideByFractionDigits(sku, prices)
        )
    )

export const getRawDiscountedPriceWithServicePlan =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string) =>
    getPriceData(sku, prices).cata<Maybe<number>>(
      () =>
        isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(
              chainProp('discountedPriceWithServicePlan')
            ),
      _priceData =>
        safeProp('discountedPriceWithServicePlan', _priceData).map(
          divideByFractionDigits(sku, prices)
        )
    )

export const getDiscountedTextHelper =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    hasServicePlan: boolean,
    isLoading: boolean,
    showAbsoluteDiscountAsRelative = false
  ) =>
  (sku: string) =>
    getPriceData(sku, prices).cata<Maybe<string>>(
      () =>
        isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(product =>
              product['@@type'] === 'package'
                ? findFirstJust([
                    (hasServicePlan
                      ? product.relativeDiscountWithServicePlan
                      : product.relativeDiscount
                    ).map(formatPercentage),
                    showAbsoluteDiscountAsRelative
                      ? Maybe.of(absoluteDiscountToRelative)
                          .apTo(Maybe.fromUndefined(product.price))
                          .apTo(
                            hasServicePlan
                              ? product.absoluteDiscountWithServicePlan
                              : product.absoluteDiscount
                          )
                          .chain(percent => percent.map(formatPercentage))
                      : (hasServicePlan
                          ? product.absoluteDiscountWithServicePlan
                          : product.absoluteDiscount
                        ).chain(formatDisplayPrice)
                  ])
                : None()
            ),
      _priceData => {
        const price = safeProp('price', _priceData).map(price => price / 100)

        const absoluteDiscount = safeProp(
          hasServicePlan
            ? 'absoluteDiscountWithServicePlan'
            : 'absoluteDiscount',
          _priceData
        ).map(price => price / 100)

        const absoluteDiscountAsRelative = Maybe.of(absoluteDiscountToRelative)
          .apTo(price)
          .apTo(absoluteDiscount)

        const relativeDiscount = safeProp(
          hasServicePlan
            ? 'relativeDiscountWithServicePlan'
            : 'relativeDiscount',
          _priceData
        ).map(price => price / 100)

        return findFirstJust([
          showAbsoluteDiscountAsRelative
            ? absoluteDiscountAsRelative.chain(percent =>
                percent.map(formatPercentage)
              )
            : absoluteDiscount.chain(formatDisplayPrice),
          relativeDiscount.map(formatPercentage)
        ])
      }
    )

export const getFreeGiftItemHelper =
  (prices: Prices) =>
  (sku: string): Maybe<GiftItemDTO> =>
    getPriceData(sku, prices).cata<Maybe<GiftItemDTO>>(
      () => Maybe.none(),
      _priceData => {
        return safeProp('withoutMonitoringGifts', _priceData).cata(
          () => Maybe.none(),
          (gift: readonly GiftItemDTO[]) => giftMatchesSku(sku, gift)
        )
      }
    )

export const getFreeGiftItemWithServicePlanHelper =
  (prices: Prices) =>
  (sku: string): Maybe<GiftItemDTO> =>
    getPriceData(sku, prices).cata<Maybe<GiftItemDTO>>(
      () => Maybe.none(),
      _priceData => {
        return safeProp('withMonitoringGifts', _priceData).cata(
          () => Maybe.none(),
          (gift: readonly GiftItemDTO[]) => giftMatchesSku(sku, gift)
        )
      }
    )

type PriceProviderProps = {
  readonly children: ReactNode | readonly ReactNode[]
  readonly skus: readonly string[]
}

const excludedSkus = ['SSPSH-ON']

export function PriceProvider({ children, skus }: PriceProviderProps) {
  const [prices, setPrices] = useState<Prices>({})
  const [isLoading, setLoading] = useState(true)
  const customerGroupMaybe = useSelector(selectCustomerGroupKey)
  const customerGroupCookie =
    getValueFromPartnerCookie('partnerGroup') || undefined
  const optimizelyParams = useOptimizelyParams()
  const partnerName = getValueFromPartnerCookie('partnerName') || undefined

  const fallbacks = useSelector(selectItemsFromSkus(skus))

  const customerGroup = customerGroupCookie || customerGroupMaybe.orUndefined()
  const isPartner = useIsPartner()
  const priceVariations = usePriceVariations(skus)
  const priceExperiments = pipe(
    isPartner,
    O.fromPredicate(Boolean),
    O.match(
      () =>
        pipe(
          priceVariations,
          O.map(getPriceWithFractionDigits),
          O.getOrElse(constant({}))
        ),
      constant({})
    )
  )

  useEffect(() => {
    const includedSkus = skus.filter(sku => !excludedSkus.includes(sku))
    const attributes = userAttributes()
    includedSkus.length > 0 &&
      requestPrices(
        includedSkus,
        attributes,
        customerGroup,
        optimizelyParams,
        partnerName
      )(() => {
        setLoading(false)
      })(response => {
        response.cata(
          () => null,
          newPrices => {
            const mergedPricesVariations = mergePriceVariations(
              priceExperiments,
              newPrices
            )
            setPrices(mergedPricesVariations)
          }
        )
        setLoading(false)
      })
    // TODO: With exhaustive deps, the pricing service gets hit ~10 times per page load. Something in Page/index.tsx is creating unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerGroup])

  return (
    <PriceContext.Provider
      value={{
        getDiscountedPrice: getRawDiscountedPrice(prices, fallbacks, isLoading),
        getDiscountedPriceWithServicePlan: getRawDiscountedPriceWithServicePlan(
          prices,
          fallbacks,
          isLoading
        ),
        getDiscountedText: getDiscountedTextHelper(
          prices,
          fallbacks,
          false,
          isLoading
        ),
        getDiscountedTextWithServicePlan: getDiscountedTextHelper(
          prices,
          fallbacks,
          true,
          isLoading
        ),
        getFormattedPrice: getFormattedPriceHelper(
          prices,
          fallbacks,
          isLoading
        ),
        getFreeGiftItems: getFreeGiftItemHelper(prices),
        getFreeGiftItemsWithServicePlan:
          getFreeGiftItemWithServicePlanHelper(prices),
        getPrice: getRawPrice(prices, fallbacks, isLoading)
      }}
    >
      {children}
    </PriceContext.Provider>
  )
}
