import { Modal, SSButton, Text } from '@simplisafe/ss-react-components'
import { equals } from 'ramda'
import React, { useState } from 'react'

import { IncludedItem } from '.'

type IncludedItemModalProps = {
  readonly item: IncludedItem
  readonly showCount?: boolean
}
function IncludedItemModal({
  item,
  showCount = false
}: IncludedItemModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const name =
    showCount && item.quantity > 1 && item.displayNamePlural
      ? item.displayNamePlural
      : item.displayName
  return (
    <>
      {item.modalContent && (
        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
          {item.modalContent}
        </Modal>
      )}
      {showCount && <span>{item.quantity} </span>}
      {item.modalContent ? (
        // override default SSButton styles for inline link button
        <SSButton
          className="p-0 min-h-0 w-auto"
          color="link"
          onClick={() => setIsOpen(true)}
        >
          {name}
        </SSButton>
      ) : (
        <span>{name}</span>
      )}
    </>
  )
}

type QuizRecommendationExplanationProps = {
  readonly includedItems: readonly IncludedItem[]
}

function QuizRecommendationExplanation({
  includedItems
}: QuizRecommendationExplanationProps) {
  const componentExplanations = [
    {
      renderContent: (component: IncludedItem) => (
        <>
          We included an <IncludedItemModal item={component} /> for each of your
          ground floor doors.
        </>
      ),
      sku: 'SSES3' // entry sensor
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          Homes of your size generally need{' '}
          <IncludedItemModal item={component} showCount /> to cover the key
          areas an intruder would have to pass through.
        </>
      ),
      sku: 'SSMS3' // motion sensor
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          We’ve added a <IncludedItemModal item={component} /> to get you help
          fast in case of a break-in, fire or medical emergency.
        </>
      ),
      sku: 'SSPB3' // panic button
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          Homes of your size generally need a{' '}
          <IncludedItemModal item={component} /> to help deter intruders
          anywhere in your home.
        </>
      ),
      sku: 'SSWS3' // auxiliary siren
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          You were concerned about water damage & leaks, a{' '}
          <IncludedItemModal item={component} /> will help monitor water leaks
          and the expensive damage that can follow.
        </>
      ),
      sku: 'SSWT3' // water sensor
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          You were concerned about freezing or bursting pipes, a{' '}
          <IncludedItemModal item={component} /> will help monitor extreme
          temperature.
        </>
      ),
      sku: 'SSFS3' // temperature sensor
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          We’ve added a <IncludedItemModal item={component} /> because you were
          interested in preventing fires.
        </>
      ),
      sku: 'SSSD3' // smoke detector
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          You were concerned about package theft.{' '}
          <IncludedItemModal item={component} /> will help prevent package theft
          and keep an eye on your front door.
        </>
      ),
      sku: 'SSDB3' // Video Doorbell Pro
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          You were interested in indoor video footage. Our{' '}
          <IncludedItemModal item={component} /> will keep watch inside your
          home and enable Fast Protect™️ Technology so we can take action in an
          emergency.
        </>
      ),
      sku: 'SSCM2' // SimpliCam
    },
    {
      renderContent: (component: IncludedItem) => (
        <>
          You were interested in outdoor video footage. Our{' '}
          <IncludedItemModal item={component} /> will keep an eye outside of
          your home day and night in crisp 1080P vision.
        </>
      ),
      sku: 'CMOB1' // Outdoor Security Camera
    }
  ]

  const baseStationItem = includedItems.find(({ sku }) =>
    sku.map(equals('SSBS3W')).orJust(false)
  )
  const keypadItem = includedItems.find(({ sku }) =>
    sku.map(equals('SSKP3W')).orJust(false)
  )

  return (
    <Text fontWeight="body" listStyle="checkmark" textSize="lg">
      <ul>
        {baseStationItem && keypadItem && (
          <li className="mb-4">
            Every system comes with a{' '}
            <IncludedItemModal item={baseStationItem} /> and{' '}
            <IncludedItemModal item={keypadItem} /> to control your system.
          </li>
        )}
        {componentExplanations
          .map(explanation => ({
            includedItem: includedItems.find(({ sku }) =>
              sku.map(equals(explanation.sku)).orJust(false)
            ),
            renderContent: explanation.renderContent
          }))
          .map(({ includedItem, renderContent }, i) =>
            includedItem ? (
              <li className="mb-4" key={i}>
                {renderContent(includedItem)}
              </li>
            ) : null
          )}
      </ul>
    </Text>
  )
}

export default QuizRecommendationExplanation
