import { brazeTrackCartEvent, fbTrackAddProductsToCart } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Either, Maybe } from 'monet'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'

import { locale } from '../../commercetools/utils'
import {
  getCommerceDataFromLineItem,
  getCommerceDataFromPackageWithExtras,
  getCommerceDataFromProduct,
  getNameFromProductName,
  getPackagePrice,
  TrackEvent
} from '.'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

const GTM_ADD_CART_EVENT = 'eec.add'
const GTM_ADD_CART_CATEGORY = 'eec'
const GTM_ADD_CART_ACTION = 'addtocart'

export const trackAddLineItemToCartEvent = (
  lineItem: LineItem,
  trackEvent: TrackEvent,
  lineItems: readonly LineItem[],
  quantity?: number
) => {
  const quantityAdjustedLineItem = {
    ...lineItem,
    quantity: quantity || lineItem.quantity
  }

  const ecomm = getCommerceDataFromLineItem('add')(lineItems)(
    quantityAdjustedLineItem
  )

  trackEvent({
    ecommerce: ecomm,
    event: 'addToCart'
  })

  trackEvent({
    ecommerce: ecomm,
    event: GTM_ADD_CART_EVENT,
    eventAction: GTM_ADD_CART_ACTION,
    eventCategory: GTM_ADD_CART_CATEGORY
  })

  brazeTrackCartEvent({
    eventType: 'add_to_cart',
    productTrackingData: ecomm
  })
}

export const trackAddToCartEvent = (
  productDetail: Either<Error, Product> | Maybe<Product>,
  trackEvent: TrackEvent,
  quantity: number,
  label?: string
) => {
  productDetail.forEach(productDetail => {
    const productName = path(['name', locale], productDetail)

    const ecomm = getCommerceDataFromProduct(productDetail, 'add', quantity)

    trackEvent({
      ecommerce: ecomm,
      event: 'addToCart'
    })

    trackEvent({
      ecommerce: ecomm,
      // todo add eec.add to TrackingEvent union type
      event: GTM_ADD_CART_EVENT,
      // todo add eventAction param to TrackingData
      eventAction: GTM_ADD_CART_ACTION,
      // todo add eventCategory param to TrackingData
      eventCategory: GTM_ADD_CART_CATEGORY,
      // todo add eventLabel param to TrackingData

      eventLabel: label ? label : productName
    })

    brazeTrackCartEvent({
      eventType: 'add_to_cart',
      productTrackingData: ecomm
    })
    ecomm.add?.products && fbTrackAddProductsToCart(ecomm.add.products)
  })
}

export const trackAddToCartPackageWithExtrasEvent = (
  pkg: Maybe<Package>,
  pkgProduct: Maybe<Product>,
  extraLineItems: readonly MiniCartLineItem[],
  isBms: boolean,
  trackEvent: TrackEvent,
  selectState: ImmutableState,
  hasMonitoring: boolean,
  label?: string,
  packagePrice?: number,
  applyPackageBrandtoExtras = false
) => {
  pkg.forEach(_package => {
    pkgProduct.forEach(_packageProduct => {
      const adjustedPackagePrice =
        packagePrice || getPackagePrice(hasMonitoring)(_package)

      const ecomm = getCommerceDataFromPackageWithExtras(
        _package,
        _packageProduct,
        extraLineItems,
        isBms,
        'add',
        selectState,
        adjustedPackagePrice,
        applyPackageBrandtoExtras
      )

      trackEvent({
        ecommerce: ecomm,
        event: 'addToCart'
      })
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Product' is not assignable to pa... Remove this comment to see the full error message
      const productName =
        getNameFromProductName(locale)(_packageProduct).getOrElse('')
      trackEvent({
        ecommerce: ecomm,
        // todo add eec.add to TrackingEvent union type
        event: GTM_ADD_CART_EVENT,
        // todo add eventAction param to TrackingData
        eventAction: GTM_ADD_CART_ACTION,
        // todo add eventCategory param to TrackingData
        eventCategory: GTM_ADD_CART_CATEGORY,
        // todo add eventLabel param to TrackingData
        eventLabel: label || productName
      })

      brazeTrackCartEvent({
        eventType: 'add_to_cart',
        productTrackingData: ecomm
      })

      ecomm.add?.products &&
        fbTrackAddProductsToCart(ecomm.add.products, productName)
    })
  })
}

// todo this is convoluted to unpack and repack the Maybe; find a better way
export const trackAddToCartFloatingBannerEvent = (
  productDetail: Either<Error, Product>,
  trackEvent: TrackEvent,
  quantity: number
) => {
  productDetail.forEach(response => {
    const productPrice = getPriceDiscountFloatingBanner(response)
    trackAddToCartEvent(
      Maybe.of({
        ...response,
        discountedPrice: Maybe.of(productPrice)
      }),
      trackEvent,
      quantity
    )
  })
}

const getPriceDiscountFloatingBanner = (product: Product) =>
  ifElse(isNil, always(prop('price')(product)), () =>
    prop('discountedPrice', product).fold(0)(response => response)
  )(prop('discountedPrice', product))
