import React from 'react'

function CartAffirmCartMessage() {
  return (
    <div className="flex justify-end col-start-2 col-end-3 order-4 lg:flex text-sm my-4">
      <div className="defaultTextColor">
        <p className="inline">Select</p>
        <img
          alt="Affirm"
          className="object-contain max-w-full mx-1"
          height="19"
          src="//images.ctfassets.net/br4ichkdqihc/6tCzpY2HyllDEQJha2103K/b485b7bfa0e0a763875dd958f7af92f6/affirm_black.svg"
          width="47"
        />
        <p className="inline">at checkout to pay over time</p>
      </div>
    </div>
  )
}

export default CartAffirmCartMessage
