import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Maybe } from 'monet'

import {
  getCommerceDataFromPackageWithExtras,
  getPackagePrice,
  TrackEvent
} from '.'

/**
 * Tracks recommended system from guided system builder in GTM
 */
export const trackGuidedSystemBuilderRecommendationEvent = (
  pkg: Maybe<Package>,
  pkgProduct: Maybe<Product>,
  extraLineItems: readonly MiniCartLineItem[],
  trackEvent: TrackEvent,
  selectState: ImmutableState,
  hasMonitoring: boolean,
  packagePrice: Maybe<number>
) => {
  pkg.forEach(_package => {
    pkgProduct.forEach(_packageProduct => {
      const adjustedPackagePrice =
        packagePrice.getOrElse(0) || getPackagePrice(hasMonitoring)(_package)
      const ecomm = getCommerceDataFromPackageWithExtras(
        _package,
        _packageProduct,
        extraLineItems,
        false,
        'detail',
        selectState,
        adjustedPackagePrice,
        true
      )
      trackEvent({
        ecommerce: ecomm,
        event: 'recommendSystem'
      })
    })
  })
}
