import { Cancel } from '@simplisafe/ss-react-components/icons'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import React from 'react'

import type { HolidayPromoBannerType } from '.'
import BottomLayout from './BottomLayout'
import TopLayout from './TopLayout'
import useScrollCondition from './useScrollCondition'

export type BottomBannerProps = {
  readonly isDismissed: boolean
  readonly isDismissedAfterScrolling: boolean
  readonly isMinimized: boolean
  readonly onDismiss: () => void
  readonly type: HolidayPromoBannerType
}

function BottomBanner({
  isDismissed,
  isDismissedAfterScrolling,
  isMinimized,
  onDismiss,
  type
}: BottomBannerProps) {
  const hasScrollCondition = useScrollCondition(true)

  const closeBtn = (
    <span
      style={{
        position: 'absolute',
        right: '12px',
        top: '12px'
      }}
    >
      <Cancel
        className="closebutton"
        onClick={() => onDismiss()}
        titleA11y="Close"
      />
    </span>
  )

  const isCart = type === 'cart'
  // Don't show on cart. Show if not dismissed, or show if dismissed but not dismissed after scrolling.
  const shouldShow =
    (!isCart && !isDismissed) ||
    (isDismissed && !isDismissedAfterScrolling && hasScrollCondition)

  return shouldShow ? (
    <Promo.PromoBanner
      data-component="MobileBottomBanner"
      position="bottom"
      style={{
        paddingBottom: '10px',
        paddingTop: '20px'
      }}
    >
      {closeBtn}
      {isMinimized ? <TopLayout type={type} /> : <BottomLayout type={type} />}
    </Promo.PromoBanner>
  ) : null
}

export default BottomBanner
