import { GatsbyImage } from '@lib/components'
import { TrackingData } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import { CartCount } from '@simplisafe/ss-react-components'
import { Profile } from '@simplisafe/ss-react-components/icons'
import { Link } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { handleLinkClick } from './helpers'
import { ContentfulLinkFragment, NavItem } from './query'

type NavigationDrawerBottomItemProps = {
  readonly item: NavItem
  readonly cartItemCount: number
  readonly trackEvent: (_data: Partial<TrackingData>) => void
}
function NavigationDrawerBottomItem({
  item,
  cartItemCount,
  trackEvent
}: NavigationDrawerBottomItemProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkText = propOr<string, string>('', 'linkText', item)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkUrl = propOr<string, string>('', 'linkUrl', item)
  const id = prop('id', item)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkIcon = propOr<
    ContentfulLinkFragment['linkIcon'],
    ContentfulLinkFragment['linkIcon']
  >({}, 'linkIcon', item)

  // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  const isCart = linkUrl === '/cart'
  const icon = isCart ? (
    <CartCount count={cartItemCount} key={`cart-count-${cartItemCount}`} />
  ) : (
    <Profile />
  )

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Link
      key={id}
      // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      onClick={() => handleLinkClick(linkText, trackEvent)}
      state={{ source: 'header' }}
      style={{ textDecoration: 'inherit' }}
      to={linkUrl}
    >
      {linkIcon && !isCart && (
        <GatsbyImage
          // @ts-expect-error TS(2560) FIXME: Value of type '<V>(p: string) => V' has no propert... Remove this comment to see the full error message
          image={linkIcon}
          loading="eager"
        />
      )}
      {icon}
      {linkText}
    </Link>
  )
}

export default NavigationDrawerBottomItem
