import { GatsbyImageSchema } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { Either } from 'monet'
import { T } from 'ramda'
import propOr from 'ramda/src/propOr'

import { ContentfulModalSensorAdditionToSystem } from '../../../graphql'
import { AddExtraSensorsDefaultProps } from './types'

export const getProductId = (data: ContentfulModalSensorAdditionToSystem) =>
  safeProp('productId', data)
export const getIsSellable = (product: Either<Error, Product>): boolean =>
  product.cata(T, p => (prop('isSellable', p) ? true : false))

export const getIsOnStock = (product: Either<Error, Product>): boolean =>
  product.cata(T, p => (prop('isOnStock', p) ? true : false))
export const getHeaderText = (data: ContentfulModalSensorAdditionToSystem) =>
  prop('headerText', data)
export const getCartUpdateText = (
  data: ContentfulModalSensorAdditionToSystem
) => path(['cartUpdatedText', 'text', 'text'], data)
// @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
export const getMaximumQuantity = (
  data: ContentfulModalSensorAdditionToSystem
) => propOr<number, number>(0, 'maximumQuantity', data)
export const getOutOfStockButtonText = (
  data: ContentfulModalSensorAdditionToSystem
) => prop('outOfStockButtonText', data)
export const getOutOfStockText = (
  data: ContentfulModalSensorAdditionToSystem
) => prop('outOfStockText', data)
// @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
export const getDefaultQuantity = (
  data: ContentfulModalSensorAdditionToSystem
) => propOr<number, number>(0, 'defaultQuantity', data)
export const getAdditionalIconFixedProp = (
  data: ContentfulModalSensorAdditionToSystem
): GatsbyImageSchema | undefined => {
  const icon = path(['note', 'icon'], data)
  return icon ? icon : undefined
}

export const getDefaultProps = (data: ContentfulModalSensorAdditionToSystem) =>
  transformObject<
    ContentfulModalSensorAdditionToSystem,
    AddExtraSensorsDefaultProps
  >({
    additionalIconFixedProp: getAdditionalIconFixedProp,
    cartUpdatedText: getCartUpdateText,
    // @ts-expect-error TS(2322) FIXME: Type '(data: ContentfulModalSensorAdditionToSystem... Remove this comment to see the full error message
    defaultQuantity: getDefaultQuantity,
    headerText: getHeaderText,
    // @ts-expect-error TS(2322) FIXME: Type '(data: ContentfulModalSensorAdditionToSystem... Remove this comment to see the full error message
    maximumQuantity: getMaximumQuantity,
    outOfStockButtonText: getOutOfStockButtonText,
    outOfStockText: getOutOfStockText,
    sku: getProductId
  })(data)
