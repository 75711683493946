import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import { SSButton, SSInput } from '@simplisafe/ss-react-components'
import { Search } from '@simplisafe/ss-react-components/icons'
import { navigate } from 'gatsby'
import ifElse from 'ramda/src/ifElse'
import React, { KeyboardEvent, useState } from 'react'

import LocationButton from './LocationButton'
import SuggestionsList from './SuggestionsList'
import { CrimeLocationBannerProps, IPlaceModel, Position } from './types'

const AUGURISK_API = process.env.AUGURISK_API
const AUGURISK_API_GEOCODING = process.env.AUGURISK_API_GEOCODING
const AUGURISK_API_KEY = process.env.AUGURISK_API_KEY
const MIN_LETTERS_TO_SEARCH = 3

function CrimeLocationBannerComponent({ data }: CrimeLocationBannerProps) {
  const {
    title,
    addressInputPlaceholder,
    emptyAddressErrorMessage,
    noSuggestionsErrorMessage
  } = data
  const [displayAddresses, setDisplayAddresses] = useState<boolean>(true)
  const [address, setAddress] = useState<string>('')
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | undefined>(
    undefined
  )
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<readonly IPlaceModel[]>([])
  const [selectedAddress, setSelectedAddress] = useState<
    IPlaceModel | undefined
  >(undefined)
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false)

  const getSuggestions = async (address: string, maxResults: number) => {
    const data = await fetch(
      `${AUGURISK_API}v1/geocoding/?address=${address}&maxResults=${maxResults}`,
      {
        headers: { Apikey: AUGURISK_API_KEY! },
        method: 'get'
      }
    )

    return await data.json()
  }

  const fetchDataOnChange = (address: string) => {
    timer && clearTimeout(timer)
    setLoading(true)
    const newTimer = setTimeout(() => {
      setDisplayAddresses(true)
      setSelectedAddress(undefined)
      // eslint-disable-next-line promise/catch-or-return
      getSuggestions(address, 5)
        // eslint-disable-next-line promise/always-return
        .then((res: readonly IPlaceModel[]) => {
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          ifElse(
            isNotEmpty,
            () => {
              setAddresses(res)
              setDisabledSearch(false)
              setErrorMsg('')
            },
            () => {
              setAddresses([])
              setDisabledSearch(true)
              setErrorMsg(noSuggestionsErrorMessage || '')
            }
          )(res)
        })
        .catch(({ message }: { readonly message: string }) => {
          setErrorMsg(message)
          setAddresses([])
          setLoading(false)
          setDisplayAddresses(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }, 300)
    setTimer(newTimer)
  }

  const handleChange = (address: string) => {
    timer && clearTimeout(timer)
    setLoading(false)

    const isSearchableAddress = address.length >= MIN_LETTERS_TO_SEARCH

    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    ifElse(
      isNotEmpty,
      () => {
        setAddress(address)
        setErrorMsg('')
      },
      () => {
        setAddress('')
      }
    )(address)

    isSearchableAddress && fetchDataOnChange(address)
  }

  const redirectToDetails = (selectedAddress: IPlaceModel | undefined) => {
    const route = `/crime-near-you/address?address=${address}`
    const navigateRoute = selectedAddress
      ? `${route}&lat=${selectedAddress.Place.Geometry.Point[1]}&lon=${selectedAddress.Place.Geometry.Point[0]}`
      : route
    navigate(navigateRoute)
  }

  const generateReportWithSelectedAddress = () => {
    setErrorMsg('')
    redirectToDetails(selectedAddress)
  }

  const generateReportWithAddress = () => {
    // eslint-disable-next-line promise/catch-or-return
    getSuggestions(address, 1)
      // eslint-disable-next-line promise/always-return
      .then((res: readonly IPlaceModel[]) => {
        redirectToDetails(res[0])
      })
      .catch(({ message }: { readonly message: string }) => {
        setErrorMsg(message)
        setDisplayAddresses(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const generateReport = () => {
    const hasSelectedAddress = !!selectedAddress
    const hasAddress = !selectedAddress && !!address
    const noAddress = !selectedAddress && !address

    timer && clearTimeout(timer)
    hasSelectedAddress && generateReportWithSelectedAddress()
    hasAddress && generateReportWithAddress()
    noAddress && setErrorMsg(emptyAddressErrorMessage || '')
  }

  const onSelectedAddress = (index: number) => {
    setSelectedAddress(addresses[index])
    setAddress(addresses[index].Place.Label)
    setDisplayAddresses(false)
  }

  const fetchCurrentPosition = (pos: Position) => {
    const crd = pos.coords

    fetch(
      `${AUGURISK_API_GEOCODING}?type=position&lat=${crd.latitude}&long=${crd.longitude}&use=single`,
      { method: 'get' }
    )
      .then(res => res.json())
      // eslint-disable-next-line promise/always-return
      .then((res: { readonly Results: readonly IPlaceModel[] }) => {
        redirectToDetails(res.Results[0])
      })
      .catch(({ message }: { readonly message: string }) => {
        setErrorMsg(message)
      })
  }

  const getLocation = () => {
    navigator?.geolocation?.getCurrentPosition((pos: Position) =>
      fetchCurrentPosition(pos)
    )
  }

  const handleKeypress = (e: KeyboardEvent<HTMLElement>) => {
    e.key === 'Enter' && generateReport()
  }

  return (
    <div className="bg-[#162546] rc-w-full pb-24">
      <div className="font-sans p-5 mx-auto mb-5 text-2xl sm:text-4xl md:text-4xl text-white font-semibold rounded py-2 px-4 text-center w-80 md:w-[450px]">
        {title}
      </div>

      <div className="flex items-center justify-center my-2 mx-2">
        <div className="relative mr-2 lg:mr-0 grow md:grow-0">
          <SSInput
            className="py-3 px-4 rounded-lg placeholder-gray-600 text-sm mr-3 w-70 sm:w-70 md:w-70 lg:w-80 xl:w-80 2xl:w-96"
            label=""
            onChange={e => handleChange(e.target.value)}
            onKeyPress={handleKeypress}
            placeholder={addressInputPlaceholder || ''}
            type="text"
            value={address}
          />

          <SuggestionsList
            errorMsg={errorMsg}
            isLoading={loading}
            onSelectedSuggestion={onSelectedAddress}
            showSuggestions={
              loading ||
              (displayAddresses &&
                addresses.length > 0 &&
                address.length >= MIN_LETTERS_TO_SEARCH)
            }
            suggestions={addresses}
          />
        </div>

        <LocationButton onClick={getLocation} />

        <SSButton
          a11yLabel="Search Button"
          className="ml-2 w-10 sm:p-0 min-w-fit"
          disabled={disabledSearch}
          minWidth="small"
          onClick={generateReport}
        >
          <Search className="w-5 h-5" />
        </SSButton>
      </div>
      <div className="flex items-center justify-center text-center text-lg font-light my-1 text-simplyblue-500 mt-3 text-rose-500">
        {errorMsg}
      </div>
    </div>
  )
}

export default CrimeLocationBannerComponent
