import { chainProp } from '@simplisafe/monda/chain'
import { selectPromotionBanner } from '@simplisafe/ss-ecomm-data/redux/select'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { SimpliSafeCSSProperties } from '../types/SimpliSafeCSSProperties'

export type PromoStylerProps = {
  readonly children: ReactNode
}

function PromoStyler({ children }: PromoStylerProps) {
  const bannerProps = useSelector(selectPromotionBanner)
  const backgroundColor = bannerProps
    .chain(chainProp('backgroundColor'))
    .orUndefined()
  const primaryTextColor = bannerProps
    .chain(chainProp('primaryTextColor'))
    .orUndefined()
  const secondaryTextColor = bannerProps
    .chain(chainProp('secondaryTextColor'))
    .orUndefined()

  const styles: SimpliSafeCSSProperties = {
    '--promo-banner-bg': backgroundColor,
    '--promo-flag-bg': backgroundColor,
    '--promo-flag-text': primaryTextColor,
    '--promo-primary-text': primaryTextColor,
    '--promo-secondary-text': secondaryTextColor,
    '--prose-links-color': primaryTextColor,
    color: primaryTextColor
  }

  return (
    <div data-component="PromoStyler" style={styles}>
      {children}
    </div>
  )
}

export default PromoStyler
