import {
  Cookie,
  params,
  TrackingData,
  useOptimizelyTrackSiteEvents,
  useTrackingCookies
} from '@lib/tracking'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { get } from 'local-storage'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'
import { StringParam, useQueryParam } from 'use-query-params'

import { ContentfulPaymentFormErrorMessage } from '../../../graphql'

type PaymentFormErrorMessageComponentProps = {
  readonly id?: string
  readonly data: Partial<ContentfulPaymentFormErrorMessage>
}

export function handleError(
  error: string,
  trackEvent: (data: Partial<TrackingData>) => void,
  cookies: Cookie
) {
  const vid: string = cookies.vid || ''
  const drupalUid: string = cookies.DRUPAL_UID || ''
  const atAtToken: string = cookies.atat_v2 || ''
  const cartId: string = get(LOCAL_STORAGE_CARTID) || 'unknown'

  logError(Error(`PAYMENT_FORM_ERROR: ${error}`), {
    atAtToken,
    cartId,
    drupalUid,
    vid
  })

  trackEvent({
    event: 'paymentFormError',
    transactionId: cartId
  })
}

function PaymentFormErrorMessageComponent({
  data
}: PaymentFormErrorMessageComponentProps) {
  const { Track, trackEvent } = useTracking({ appSection: 'paymentForm' })

  const [error] = useQueryParam(params.error, StringParam)
  const isCVVError = error && error.includes('"type":"InvalidCVV"')
  const errorMessage = isCVVError
    ? data?.invalidCVVMessage?.raw
    : data?.errorMessage?.raw

  const [scrolled, setScrolled] = useState(false)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
    setScrolled(true)
  }

  const [cookies] = useTrackingCookies()

  useEffect(() => {
    error && handleError(error, trackEvent, cookies)
  }, [error, trackEvent, cookies])

  useEffect(() => {
    error && !scrolled && scrollToTop()
  }, [error, scrolled])

  error &&
    !scrolled &&
    optimizelyTrackSiteEvents({ eventType: 'website_error' })

  return error ? (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <SmallTextSection
        dataComponent="payment-form-error"
        // @ts-expect-error TS(2322) FIXME: Type '{ dataComponent: string; description: string... Remove this comment to see the full error message
        description={errorMessage}
        textColor="errorRed"
        theme="errorMessage"
      />
    </Track>
  ) : null
}
export default PaymentFormErrorMessageComponent

export const query = graphql`
  #graphql
  fragment paymentFormErrorMessageFragment on ContentfulPaymentFormErrorMessage {
    id
    title
    errorMessage {
      raw
    }
    invalidCVVMessage {
      raw
    }
    internal {
      type
    }
  }
`
