import { IOGetCart } from '@simplisafe/ss-ecomm-data/cart'
import React, { FC, ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'

type InitializeStoreProps = {
  readonly children: ReactElement
}

const InitializeStore: FC<InitializeStoreProps> = ({
  children
}: InitializeStoreProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(IOGetCart())
  }, [dispatch])

  return <>{children}</>
}

export default InitializeStore
