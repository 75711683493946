/* eslint-disable functional/prefer-readonly-type */
import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import {
  IArtDirectedImage,
  IGatsbyImageData,
  withArtDirection
} from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import React, { CSSProperties, ReactNode } from 'react'

import {
  ContentfulAsset,
  ContentfulImageWithArtDirection
} from '../../../graphql'

export type ImageWithArtDirectionProps = {
  /** Renders a background image if children are passed */
  readonly children?: ReactNode
  /** If rendering a background image for a Row, it needs to accept a class name for styling */
  readonly className?: string
  readonly style?: CSSProperties
  readonly data: Partial<ContentfulImageWithArtDirection>
  readonly roundedCorners?: boolean
}

type DataWithArtDirection = Omit<
  ContentfulImageWithArtDirection,
  'desktopImage' | 'mobileImage' | 'tabletImage'
> & {
  readonly mobileImage: NonNullable<Required<ContentfulAsset>>
  readonly tabletImage: NonNullable<Required<ContentfulAsset>>
  readonly desktopImage: NonNullable<Required<ContentfulAsset>>
}

const isImageWithDirection = (
  data: Partial<ContentfulImageWithArtDirection>
): data is DataWithArtDirection => {
  return Boolean(
    data?.mobileImage?.gatsbyImageData &&
      data?.tabletImage?.gatsbyImageData &&
      data?.desktopImage?.gatsbyImageData
  )
}

const transformImageData = (data: DataWithArtDirection): IGatsbyImageData => {
  const desktopImage = data.desktopImage.gatsbyImageData?.images?.sources || []

  const tabletImage = data.tabletImage.gatsbyImageData?.images?.sources || []

  const mobileImage = data.mobileImage.gatsbyImageData?.images?.sources || []

  return {
    ...data.desktopImage.gatsbyImageData,
    images: {
      ...data.desktopImage.gatsbyImageData?.images,
      sources: [...desktopImage, ...tabletImage, ...mobileImage]
    }
  }
}

const getWithArtDirectionParams = (
  data: DataWithArtDirection
): [defaultImage: IGatsbyImageData, artDirected: IArtDirectedImage[]] => {
  const { mobileImage, tabletImage, desktopImage } = data
  return [
    desktopImage.gatsbyImageData,
    [
      {
        image: mobileImage.gatsbyImageData,
        media: '(max-width: 768px)'
      },
      {
        image: tabletImage.gatsbyImageData,
        media: '(max-width: 1200px)'
      },
      {
        image: desktopImage.gatsbyImageData,
        media: '(min-width: 1440px)'
      }
    ]
  ]
}

export default function ImageWithArtDirection({
  children = null,
  className = '',
  data,
  style = {},
  roundedCorners = true
}: ImageWithArtDirectionProps) {
  const title = path(['desktopImage', 'title'], data)
  const description = path(['desktopImage', 'description'], data)
  const customClassNames = classNames('h-full', className, {
    'rc-rounded-lg': roundedCorners
  })

  const image = isImageWithDirection(data) && transformImageData(data)
  const imagesWithArtDirection = isImageWithDirection(data)
    ? withArtDirection(...getWithArtDirectionParams(data))
    : null

  return children ? (
    <BgImage
      alt={description}
      className={customClassNames}
      image={image}
      style={style}
      title={title}
    >
      {children}
    </BgImage>
  ) : imagesWithArtDirection ? (
    <GatsbyImage
      className={customClassNames}
      image={{
        title,
        description,
        gatsbyImageData: imagesWithArtDirection
      }}
      style={style}
    />
  ) : null
}

export const query = graphql`
  #graphql
  fragment imageWithArtDirection on ContentfulImageWithArtDirection {
    id
    internal {
      type
    }
    mobileImage {
      gatsbyImageData(layout: CONSTRAINED, width: 768, placeholder: BLURRED)
    }
    tabletImage {
      gatsbyImageData(layout: CONSTRAINED, width: 1200, placeholder: BLURRED)
    }
    desktopImage {
      title
      description
      gatsbyImageData(layout: CONSTRAINED, width: 1440, placeholder: BLURRED)
    }
  }
`
