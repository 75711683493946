// @ts-expect-error TS(2305) FIXME: Module '"@simplisafe/ss-react-components"' has no ... Remove this comment to see the full error message
import { SSTable } from '@simplisafe/ss-react-components'
import React from 'react'
import { last } from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

type EmbeddedCrimeTableProps = {
  readonly data: {
    readonly pageContext: {
      readonly locationCrimesTable?: {
        readonly head: readonly string[]
        readonly body: ReadonlyArray<ReadonlyArray<string>>
      }
      readonly url: string
    }
  }
}

function EmbeddedCrimeTable({
  data
}: EmbeddedCrimeTableProps): React.ReactElement | null {
  const locationsCrimesTable = data?.pageContext?.locationCrimesTable

  if (!locationsCrimesTable) {
    return null
  } else {
    const url = data?.pageContext?.url
    const location = pipe(last(url.split('-')), O.toUndefined)
    const tableBody = locationsCrimesTable?.body ?? []
    const activeIndex = tableBody.findIndex(
      row => row[0]?.toLowerCase() === location?.toLowerCase()
    )

    return <SSTable {...locationsCrimesTable} activeIndex={[activeIndex]} />
  }
}

export default EmbeddedCrimeTable
