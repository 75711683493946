import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { graphql, Link } from 'gatsby'
import React from 'react'

import { ContentfulRichTextWithButtons } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { ContentfulButtonProps } from '../ButtonComponent'
import ContentfulRichText from '../ContentfulRichText'

type RichTextWithButtonsComponentProps = {
  readonly data: Partial<ContentfulRichTextWithButtons>
  readonly onUrlFragmentClick?: () => void
}

export const onClickFragmentUrl =
  (url: string, onClick?: () => void) =>
  (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const scrollTimeout = onClick ? 350 : 0

    onClick && onClick()
    const button: HTMLButtonElement = event.currentTarget

    const anchorTarget = button.ownerDocument.getElementById(url.substr(1))
    // This sets a timeout because the onClick() call can cause the browser to scroll back to the original element if
    // the callback and the scrolling happen too close together
    anchorTarget &&
      setTimeout(() => {
        anchorTarget.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }, scrollTimeout)
  }

function RichTextWithButtonsComponent({
  data,
  onUrlFragmentClick
}: RichTextWithButtonsComponentProps) {
  const buttonsData = data?.buttons || []

  const buttons = buttonsData.map((_button, i) => {
    const button = _button || {}
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const ButtonComponent = getMappedComponent<ContentfulButtonProps>(button)
    // TODO: fix type
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const buttonUrl = prop('url', button) || ''
    // TODO: fix type
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const isButtonComponent =
      path(['internal', 'type'], button) === 'ContentfulButton'
    // TODO: fix type

    const buttonHasFragmentUrl = buttonUrl.startsWith('#')

    // TODO: fix type
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const linkText = prop('linkText', button)

    // TODO: fix type
    //TODO Ensure that the url is NOT an external url because the Gatsby <Link /> can only use internal urls.
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const linkUrl = prop('linkUrl', button)

    const buttonClasses = 'min-w-[228px] whitespace-pre'

    return (
      <div
        className="items-center flex flex-col justify-start mt-4"
        key={`button-${i}`}
      >
        {ButtonComponent && (
          <ButtonComponent
            className={buttonClasses}
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulButtonContentfulLinkContentfulLink... Remove this comment to see the full error message
            data={button}
            // TODO: fix type
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            key={prop('id', button)}
            // If we have a ButtonComponent and it has a hash url, we want to use its `onClick` prop to scroll to that anchor
            // tag and call the provided callback if it exists (currently used by ModalComponent to close the parent modal).
            onClick={
              isButtonComponent && buttonHasFragmentUrl
                ? onClickFragmentUrl(buttonUrl, onUrlFragmentClick)
                : undefined
            }
          />
        )}

        {!ButtonComponent && linkText && linkUrl && (
          <Link
            className={buttonClasses}
            // TODO: fix type
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            key={prop('id', button)}
            to={linkUrl}
          >
            {linkText}
          </Link>
        )}
      </div>
    )
  })

  return (
    <div className="p-8" data-component="RichTextWithButtonsComponent">
      <ContentfulRichText raw={data?.description?.raw} />
      <div className="items-center flex flex-wrap justify-around p-0">
        {buttons}
      </div>
    </div>
  )
}

export default RichTextWithButtonsComponent

export const RichTextWithButtonsQuery = graphql`
  #graphql
  fragment contentfulRichTextWithButtonsFragment on ContentfulRichTextWithButtons {
    buttons {
      ... on ContentfulLink {
        id
        internal {
          type
        }
        linkSubtext
        linkText
        linkType
        linkUrl
      }

      ... on ContentfulButton {
        ...contentfulButtonFragment
      }

      ... on ContentfulLinkAddToCart {
        ...contentfulLinkAddToCart
      }
    }
    description {
      raw
    }
    id
    internal {
      type
    }
  }
`
