import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import type { PartnerWithUsFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsForm'
import { PartnerWithUsSchema } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsFormSchema'
import { partnerWithUsFormSubmit } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsFormSubmission'
import { startPartnerWithUsContactFetch } from '@simplisafe/ss-ecomm-data/deprecated/partnerWithUs/actions'
import { selectPartnerWithUsSubmit } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Form, Formik, FormikHelpers } from 'formik'
import propOr from 'ramda/src/propOr'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContentfulPartnerWithUsForm } from '../../../graphql'
import ButtonComponent from '../ButtonComponent'
import { BUSINESS_TYPE, PHONE } from './const'
import PartnerWithUsInput from './form-sections/PartnerWithUsInput'
import Submission from './form-sections/Submission'
import SuccessMessage from './form-sections/SuccessMessage'
import Title from './form-sections/Title'

export type FormValues = {
  readonly firstName: string
  readonly lastName: string
  readonly companyName: string
  readonly email: string
}

const initialValues: FormValues = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: ''
}

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type PartnerWithUsProps = {
  readonly data: ContentfulPartnerWithUsForm
}

export default function PartnerWithUsForm({ data }: PartnerWithUsProps) {
  const dispatch = useDispatch()

  const isSubmittedSuccessfully = useSelector(selectPartnerWithUsSubmit)

  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        data?.target && <ButtonComponent data={data.target} />
    }
  }

  const partnerWithUsFormSuccessMessageDescription = (
    <ContentfulRichText
      optionsCustom={options}
      raw={data?.successMessageDescription?.raw}
      references={data?.successMessageDescription?.references}
    />
  )

  const onSubmit = async (
    formData: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    const submitFormValues: PartnerWithUsFormSubmitBody = {
      ...formData,
      businessType: BUSINESS_TYPE,
      numLocations: '',
      phone: PHONE,
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      source: propOr<string, string>('Partner with us form', 'source', data)
    }

    const handleSuccess = () => {
      setStatus({ message: 'success' })
      dispatch(startPartnerWithUsContactFetch())
      setSubmitting(false)
    }

    const handleFailure = (error: Error) => {
      setSubmitting(false)
      logError(error)
    }

    partnerWithUsFormSubmit(submitFormValues)(handleFailure)(handleSuccess)
  }

  return (
    <div
      className="bg-neutral-100 m-0 pt-1 px-8 pb-8"
      data-component={'PartnerWithUsForm'}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px 0px' }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={PartnerWithUsSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            {!isSubmittedSuccessfully && (
              <>
                {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
                <Title
                  formTitle={propOr<string, string>('', 'formTitle', data)}
                />
                <PartnerWithUsInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>(
                    '',
                    'firstNameFieldLabel',
                    data
                  )}
                  name="firstName"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'firstNameFieldPlaceholder',
                    data
                  )}
                />
                <PartnerWithUsInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'lastNameFieldLabel', data)}
                  name="lastName"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'lastNameFieldPlaceholder',
                    data
                  )}
                />
                <PartnerWithUsInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'companyFieldLabel', data)}
                  name="companyName"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'companyFieldPlaceholder',
                    data
                  )}
                />
                <PartnerWithUsInput
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  label={propOr<string, string>('', 'emailFieldLabel', data)}
                  name="email"
                  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                  placeholder={propOr<string, string>(
                    '',
                    'emailFieldPlaceholder',
                    data
                  )}
                />
                {/* @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message */}
                <Submission
                  disabled={isSubmitting}
                  label={propOr<string, string>('', 'submitButtonLabel', data)}
                />
              </>
            )}
            {isSubmittedSuccessfully && (
              <SuccessMessage
                description={partnerWithUsFormSuccessMessageDescription}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                title={propOr<string, string>('', 'successMessageTitle', data)}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
