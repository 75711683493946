import { FormField, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly formTitle: string
}

function Title({ formTitle }: Props) {
  return (
    <div data-component={'Title'}>
      <FormField hideLabel={true} name="getAQuoteFormDescription">
        <Text fontWeight="light">
          <h2>{formTitle}</h2>
        </Text>
      </FormField>
    </div>
  )
}

export default Title
