import { OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk'
import { render, RenderOptions } from '@testing-library/react'
import { ReactElement, ReactNode } from 'react'

import { TestableOptimizelyClient } from './client'

export const mockOptimizely = new TestableOptimizelyClient({})

type Props = {
  readonly children?: ReactNode
  readonly optimizely?: ReactSDKClient
}

/** A wrapper with a barebones Optimizely provider */
export function MockOptimizelyProvider({
  children = null,
  optimizely = mockOptimizely
}: Props) {
  return (
    <OptimizelyProvider isServerSide={true} optimizely={optimizely}>
      {children}
    </OptimizelyProvider>
  )
}

/** A custom render function for React Testing Library to wrap the component with Optimizely */
export const renderWithOptimizely = (
  ui: ReactElement,
  options?: RenderOptions
) =>
  render(ui, {
    wrapper: MockOptimizelyProvider,
    ...options
  })
