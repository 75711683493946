import { useExperiment } from '@lib/optimizely'

export default function useShippingAddressValidation() {
  const [variation, clientReady, didTimeout] = useExperiment('ECP_6548')

  return {
    optimizelyReady: clientReady || didTimeout,
    shippingAddressValidationExperimentVariation: variation
  }
}
