import { isString } from 'fp-ts/string'

/**
 * Takes in any value and always returns a string.
 */
function validateString<T>(str: T): string {
  return isString(str) ? str : ''
}

export default validateString
