import { TrackingData } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { ZuoraPaymentResponse } from '@simplisafe/ss-ecomm-data/thirdparty/zuora'

import {
  forwardToPaymentErrorUrl,
  logErrorWithOrderInfo,
  ZuoraOrderData
} from './createOrder'

type CreateOrder = (_orderData: ZuoraOrderData) => void
type OnFormLoadError = () => void
type OnPaymentError = (_error: Error) => void
type OnPaymentProcessing = () => void
type TrackEvent = (_data: TrackingData) => void

type SubmitCreditCardOrderProps = {
  readonly createOrder: CreateOrder
  readonly onFormLoadError: OnFormLoadError
  readonly onPaymentError: OnPaymentError
  readonly onPaymentProcessing: OnPaymentProcessing
  readonly trackEvent: TrackEvent
  readonly zuoraResponse: ZuoraPaymentResponse
}

/**
 * Handle the response from a credit card payment method submission with Zuora.
 *
 * On success, the payment is tracked, an order is created in our system payment is processed, and the user
 * is forwarded appropriately to either the confirmation page or the preactivation flow.
 *
 * On error, the Zuora error code is tracked, the response is logged and payment state is updated.
 */
const submitZuoraOrder = (props: SubmitCreditCardOrderProps): void => {
  prop('success', props.zuoraResponse) === 'true'
    ? handleSuccess(
        props.zuoraResponse,
        props.createOrder,
        props.onPaymentProcessing
      )
    : handleError(
        props.zuoraResponse,
        props.onFormLoadError,
        props.onPaymentError,
        props.trackEvent
      )
}

const handleSuccess = (
  zuoraResponse: ZuoraPaymentResponse,
  createOrder: CreateOrder,
  onPaymentProcessing: OnPaymentProcessing
): void => {
  onPaymentProcessing()

  createOrder({
    paymentMethodId: zuoraResponse.refId,
    provider: 'zuora',
    token: zuoraResponse.token,
    type: 'credit'
  })
}

const handleError = (
  zuoraResponse: ZuoraPaymentResponse,
  onFormLoadError: OnFormLoadError,
  onPaymentError: OnPaymentError,
  trackEvent: TrackEvent
): void => {
  const errorCode = safeProp('errorCode', zuoraResponse).orJust('')
  const errorMessage = safeProp('errorMessage', zuoraResponse).orJust('')
  const source = prop('responseFrom', zuoraResponse)

  trackEvent({
    errorID: errorCode,
    event: 'paymentFormCreError'
  })

  source === 'Response_From_Submit_Page'
    ? handleErrorOnSubmit(errorMessage, onPaymentError)
    : handleErrorOnLoad(errorMessage, onFormLoadError)
}

const handleErrorOnSubmit = (
  message: string,
  onPaymentError: OnPaymentError
): void => {
  const error = Error(
    `submitCreditOrder: Credit Card submission failed - ${message}`
  )

  logErrorWithOrderInfo(error)
  onPaymentError(error)
  forwardToPaymentErrorUrl(error)
}

const handleErrorOnLoad = (
  message: string,
  onFormLoadError: OnFormLoadError
): void => {
  const error = Error(
    `submitCreditOrder: Credit Card form failed to load - ${message}`
  )

  logErrorWithOrderInfo(error)
  onFormLoadError()
}

export default submitZuoraOrder
