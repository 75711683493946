import prop from '@simplisafe/ewok/ramda/prop'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulDeviceVariations } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'

type DeviceVariationsComponentProps = {
  readonly data: ContentfulDeviceVariations
}

export default function DeviceVariationsComponent({
  data
}: DeviceVariationsComponentProps) {
  const isMobile = !useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('DesktopAndUp')
  const mobileComponent = prop('mobile', data) || undefined
  const tabletComponent = prop('tablet', data) || undefined
  const desktopComponent = prop('desktop', data) || undefined

  const dataComponent = isMobile
    ? mobileComponent
    : isDesktop
    ? desktopComponent
    : tabletComponent
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const Component = dataComponent && getMappedComponent(dataComponent)
  return Component ? (
    // @ts-expect-error TS(2322) FIXME: Type 'ContentfulGroupSection | ContentfulSmallText... Remove this comment to see the full error message
    <Component data={dataComponent} />
  ) : (
    <></>
  )
}

export const query = graphql`
  #graphql
  fragment deviceVariationsFragment on ContentfulDeviceVariations {
    id
    internal {
      type
    }
    mobile {
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
    }
    desktop {
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
    }
    tablet {
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
    }
  }
`
