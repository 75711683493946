import { SSButton } from '@simplisafe/ss-react-components'
import { PageAnchorNavigation } from '@simplisafe/ss-react-components'
import {
  ButtonType,
  SSButtonProps
} from '@simplisafe/ss-react-components/SSButton'
import { graphql } from 'gatsby'
import React, { MouseEvent } from 'react'

import {
  ContentfulButton,
  ContentfulPageAnchorNavigation
} from '../../../graphql'
import useWindowScrollRender from '../../hooks/useWindowScrollRender'
import { nullToUndefined, toButton } from '../../util/helper'

export type PageAnchorNavigationProps = {
  readonly data: ContentfulPageAnchorNavigation
}

type allowedTypes = ContentfulButton | null

export const renderButton = (obj: ContentfulButton) => {
  const toButtonProps: SSButtonProps = toButton(obj)
  const key = obj.id
  const href = toButtonProps.href || '#'

  const handleClick = (event: MouseEvent, href: string) => {
    event.preventDefault()
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
    const button = event.currentTarget as HTMLButtonElement

    const anchorTarget = button.ownerDocument.getElementById(href.substr(1))
    anchorTarget &&
      anchorTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  const isInternalLink = href.startsWith('#')
  const props = {
    ...toButtonProps,
    ...(isInternalLink && { onClick: (e: MouseEvent) => handleClick(e, href) }),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
    ...(!isInternalLink && { type: 'link' as ButtonType })
  }

  return <SSButton {...props} key={key} />
}

const componentMappings = { ContentfulButton: renderButton }

const mapResultToComponent = (item: allowedTypes, _idx: number) => {
  const internalType = item ? item.internal?.type : null

  return internalType ? componentMappings[internalType](item) : null
}

function PageAnchorNavigationComponent({ data }: PageAnchorNavigationProps) {
  const { title, navigationItems, additionalItems } = data

  const navElements =
    (navigationItems && navigationItems.map(mapResultToComponent)) || []
  const additionalElements =
    (additionalItems && additionalItems.map(mapResultToComponent)) || []

  const shouldRender = useWindowScrollRender(90)

  return shouldRender ? (
    <PageAnchorNavigation
      additionalItems={additionalElements}
      navigationItems={navElements}
      title={nullToUndefined(title)}
    />
  ) : null
}

export default PageAnchorNavigationComponent

export const pageAnchorNavigationQuery = graphql`
  #graphql
  fragment pageAnchorNavigation on ContentfulPageAnchorNavigation {
    title
    navigationItems {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    additionalItems {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
  }
`
