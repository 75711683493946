import { z } from 'zod'

// For other data points not within standard Promotion entry fields.
const promoOverridesSchema = z.object({
  chooseMonitoringPage: z.string().optional(), // '30% Plus an Extra 10%'
  discountSummary: z.string().optional(), // 'EXTRA 10% OFF'
  // begin holiday banner workflows.
  endsTextLabel: z.string().optional(), // 'Early Access'
  heroStickerBackgroundColor: z.string().optional(), // '#5DBA82'
  heroStickerDisclaimerText: z.string().optional(), // 'Any new system with Interactive Monitoring'
  heroStickerFreeGiftLineItemText: z.string().optional(), // '+ Free Camera'
  heroStickerImage: z.string().optional(), // For a potential A/B test showing promo product image inside the sticker.
  heroStickerSaleName: z.string().optional(), // 'Black Friday Sale')
  heroStickerTextColor: z.string().optional(), // '#FFFFFF'
  isHolidayBanner: z.boolean().default(false).optional(), // Determines if HolidayPromoBanner should replace ActivePromoBanner.
  // end holiday banner workflows.
  listingFlag: z.string().optional(), // 'an extra 10%'
  productFlag: z.string().optional(), // 'EXTRA 10% OFF'
  toggleBox: z.string().optional() // 'an extra 10%'
})

export type PromoOverridesSchema = z.TypeOf<typeof promoOverridesSchema>

export default promoOverridesSchema
