/* eslint-disable functional/no-conditional-statement */
import { TrackingData } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import React, { ReactElement } from 'react'

import FloatingQuizDialogue from '../FloatingQuizDialogue'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import { HeaderAccountNav } from './HeaderAccountNav'
import { HeaderButton } from './HeaderButton'
import { HeaderDropdown } from './HeaderDropdown'
import { HeaderLink } from './HeaderLink'
import { flattenInternalType } from './helpers'
import { NavItem } from './query'

export const renderNavItem = (
  data: NavItem,
  showImage: boolean,
  cartItemCount: any,
  isMobile: boolean,
  trackEvent: (_data: Partial<TrackingData>) => void
): ReactElement | null => {
  const item: NavItem = flattenInternalType(data)
  if (!item) {
    return null
  }
  if (item.internalType === 'ContentfulLink') {
    return (
      <HeaderLink
        cartItemCount={cartItemCount}
        key={`header-link${item.id}`}
        link={item}
        trackEvent={trackEvent}
      />
    )
  }
  if (item.internalType === 'ContentfulMenuItems') {
    const subNav = prop('subNav', item)
    return isNotNil(subNav) ? (
      <HeaderDropdown
        dropDown={item}
        isMobile={isMobile}
        key={item.id}
        showImage={showImage}
        trackEvent={trackEvent}
      />
    ) : (
      <HeaderLink
        key={`nav_item_${item.id}`}
        link={item}
        trackEvent={trackEvent}
      />
    )
  }
  if (item.internalType === 'ContentfulButton') {
    return (
      <HeaderButton
        id={item.id}
        key={`header-button${item.id}`}
        text={item.text}
        trackEvent={trackEvent}
        url={item.url}
      />
    )
  }
  if (item.internalType === 'ContentfulAccountNav') {
    return <HeaderAccountNav item={item} key={`header_account_nav${item.id}`} />
  }
  if (item.internalType === 'ContentfulFloatingBar') {
    return (
      <FloatingQuizDialogue
        data={item}
        key={`header-quote-wizard-${item.id}`}
      />
    )
  }
  if (item.internalType === 'ContentfulRichTextWithOptions') {
    return (
      <div
        key={`header-rich-text-${item.id}`}
        style={{
          flexGrow: 1,
          margin: prop('containerMargin', item),
          textTransform: 'none'
        }}
      >
        <RichTextWithOptionsComponent data={item} />
      </div>
    )
  }
  return null
}
