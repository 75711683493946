import { GatsbyImage } from '@lib/components'
import { TrackingData } from '@lib/tracking'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  HeaderDropdown as HeaderDropdownAtom,
  HeaderDropdownItem as HeaderDropdownItemAtom,
  HeaderDropdownMultipleItems
} from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import toLower from 'ramda/src/toLower'
import React from 'react'

import { rewritePartnerShopUrl } from '../../util/helper'
import { handleLinkClick } from './helpers'
import { ContentfulMenuItemsFragment, NavItem } from './query'

type HeaderDropdownItemProps = {
  readonly item?: NavItem
  readonly showImage: boolean
  readonly isMobile: boolean
  readonly trackEvent: (_trackingData: Partial<TrackingData>) => void
}

export const HeaderDropdownItem = ({
  item,
  showImage,
  isMobile,
  trackEvent
}: HeaderDropdownItemProps) => {
  const contentsToGroup = item?.['contentsToGroup']

  const groupingType = item?.['groupingTypeDesktop']
  const renderLink = (link: NavItem, trackEvent: any) => {
    const linkText = link?.['linkText'] ?? ''

    const linkUrl = link?.['linkUrl'] ?? ''
    const id = prop('id', link)
    const url = rewritePartnerShopUrl(linkUrl)

    const linkSubtext = link?.['linkSubtext']

    const linkImage = link?.['linkImage']

    return (
      <Link
        key={id}
        onClick={() => url && handleLinkClick(linkText, trackEvent)}
        state={{ source: 'header' }}
        style={{ textDecoration: 'inherit' }}
        to={url}
      >
        {showImage && (
          <HeaderDropdownItemAtom
            image={
              linkImage && (
                <GatsbyImage
                  image={linkImage}
                  imgStyle={{ objectFit: 'contain' }}
                  loading="eager"
                  style={{
                    display: 'block',
                    height: '100%',
                    objectPosition: 'center center',
                    width: '100%'
                  }}
                />
              )
            }
            label={linkText}
            labelPosition={!isMobile ? 'right' : 'bottom'}
            subText={linkSubtext}
          />
        )}
        {!showImage && linkText}
      </Link>
    )
  }

  return Maybe.fromFalsy(contentsToGroup)
    .map(contents => (
      <HeaderDropdownMultipleItems
        key={prop('id', item)}
        position={Maybe.fromNull(groupingType)
          /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code, legacy code */
          .map(g => toLower(g) as 'column' | 'row')
          .orUndefined()}
      >
        {} {contents.map((link: any) => renderLink(link, trackEvent))}
      </HeaderDropdownMultipleItems>
    ))
    .getOrElse(renderLink(item, trackEvent))
}

export type HeaderDropdown = {
  readonly dropDown: ContentfulMenuItemsFragment
  readonly showImage: boolean
  readonly isMobile: boolean
  readonly trackEvent: (_trackingData: Partial<TrackingData>) => void
}

export function HeaderDropdown({
  dropDown,
  showImage,
  isMobile,
  trackEvent
}: HeaderDropdown) {
  const id = prop('id', dropDown)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkText = propOr<string, string>('', 'linkText', dropDown)
  const subNav = prop('subNav', dropDown)

  return (
    <HeaderDropdownAtom
      id={id}
      key={id}
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      label={linkText}
    >
      {/* @ts-expect-error TS(2322) FIXME: Type 'ContentfulGroupSection | ContentfulLinkFragm... Remove this comment to see the full error message */}
      {subNav &&
        subNav.map(item => (
          <HeaderDropdownItem
            isMobile={isMobile}
            item={item}
            key={item.id}
            showImage={showImage}
            trackEvent={trackEvent}
          />
        ))}
    </HeaderDropdownAtom>
  )
}
