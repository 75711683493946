import { toModalSize } from '@lib/components'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { IncludedItemProduct as IncludedItem } from '@simplisafe/ss-react-components'
import type { ItemProduct as IncludedItemProps } from '@simplisafe/ss-react-components/IncludedItem'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulIncludedItem } from '../../../graphql'
import { renderComponentFromData } from '../../util/render'

export type IncludedItemComponentProps = {
  readonly data: ContentfulIncludedItem
}

export const toIncludedItemProps = transformObject<
  ContentfulIncludedItem,
  IncludedItemProps
>({
  modalContent: x =>
    safeProp('infoModal', x).cata(
      () => undefined,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulGroupSectionContentful... Remove this comment to see the full error message
      modalData => renderComponentFromData(modalData)
    ),
  modalProps: x =>
    safeProp('infoModalSize', x).cata(
      () => undefined,
      y => ({ size: toModalSize(y) })
    ),
  outOfStockMessage: x => prop('outOfStockMessaging', x),
  productName: x => safeProp('label', x).getOrElse('')
})

export default function IncludedItemComponent({
  data
}: IncludedItemComponentProps) {
  return safeProp('label', data).cata(
    () => null,
    () => (
      <IncludedItem
        key={safeProp('id', data).getOrElse('')}
        {...toIncludedItemProps(data)}
      />
    )
  )
}

export const IncludedItemQuery = graphql`
  #graphql
  fragment includedItem on ContentfulIncludedItem {
    id
    infoModal {
      # Note: This really should be able to support any modal content.
      # Consider re-using that logic as something like ... on AllComponents.
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
    }
    infoModalSize
    internal {
      type
    }
    label
    outOfStockMessaging
  }
`
