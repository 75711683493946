import React, { createContext, useMemo } from 'react'

import { Env, EnvProviderProps } from './types'

export const EnvContext = createContext<Env>({ locale: 'en-US' })

/**
 * EnvProvider creates a root wrapper for applications to access client-facing environment variables and eliminate
 * pollution/dependency on global scope for `process.env.${values}`
 *
 * Future Use-Cases:
 *  - Eliminate singleton helper functions such as getLocale that interface with `process.env`.
 *  - Introduce zod validation when env config grows.
 *  - Consider generic types if env config grows and only certain packages will contain a sub-set of config properties.
 */
export function EnvProvider({ children, config }: EnvProviderProps) {
  const { locale } = config

  const env: Env = useMemo(() => {
    return { locale }
  }, [locale])

  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
}
