import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly name: string
  readonly label: string
  readonly placeholder: string
}

function PartnerWithUsInput({ name, label, placeholder }: Props) {
  return (
    <div className="my-7" data-component={name}>
      <FormField hideLabel={true} label={label} name={name}>
        <Text className="my-7 mb-1.5" fontWeight="medium">
          {label}
        </Text>
        <FormTextInput
          fullWidth={true}
          id={name}
          name={name}
          placeholder={placeholder}
        />
      </FormField>
    </div>
  )
}

export default PartnerWithUsInput
