import { INLINES } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import { TrackingEvent, useOptimizelyTrackSiteEvents } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import {
  Wizard,
  WizardEmailSubmit,
  WizardFinal,
  WizardMultipleChoice,
  WizardPhoneSubmit
} from '@simplisafe/ss-react-components'
import { Text as Typography } from '@simplisafe/ss-react-components'
import type { ButtonColor } from '@simplisafe/ss-react-components/SSButton'
import type {
  WizardProps,
  WizardTheme
} from '@simplisafe/ss-react-components/Wizard/Wizard'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import toLower from 'ramda/src/toLower'
import React, { ReactNode } from 'react'
import { useTracking } from 'react-tracking'
import { DeepPartial } from 'redux'

import {
  ContentfulFindYourPerfectSystem,
  ContentfulForms,
  ContentfulQuizAnswer,
  ContentfulQuizQuestions,
  ContentfulSystemComponent
} from '../../../graphql'
import { WizardSubmit } from './WizardSubmit'

// This prop is aliased on ContentfulFindYourPerfectSystem because it conflicts with other components that have a termsAndConditions field
// of type String.
type AliasedContentfulFindYourPerfectSystem =
  ContentfulFindYourPerfectSystem & {
    readonly terms: ContentfulFindYourPerfectSystem['termsAndConditions']
  }

export type QuoteWizardProps = {
  readonly handleEmailSubmit: (
    responses: Record<string, string | unknown>
  ) => void
  readonly handlePhoneSubmit?: <T>(val: T) => void
  readonly data: DeepPartial<AliasedContentfulFindYourPerfectSystem>
  readonly includePhone?: boolean
  readonly type: 'embedded' | 'floating' | 'popup'
  readonly finalTabDescription?: ReactNode
  readonly finalTabContents: readonly ReactNode[]
  readonly firstTab?: ReactNode
  readonly showTitle?: boolean
  readonly onClick?: () => void
  readonly defaultEmail?: string
  readonly defaultPhone?: string
}

const wizardTypeMap = {
  Docked: 'popup',
  Embedded: 'embedded',
  Floating: 'floating'
}

const quoteWizardQuestionTypeMapper: { [key: number]: TrackingEvent } = {
  1: 'quoteWizardQuestion1',
  2: 'quoteWizardQuestion2',
  3: 'quoteWizardQuestion3',
  4: 'quoteWizardQuestion4'
}

export const toQuoteWizardQuestionType = (
  value: number
): TrackingEvent | undefined =>
  value ? prop(value, quoteWizardQuestionTypeMapper) : undefined

// @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
export const toWizardType = (
  value: string | null | undefined
): WizardProps['type'] => wizardTypeMap[value] || 'embedded'
export const toRichText = (
  key: keyof Partial<ContentfulFindYourPerfectSystem>,
  data: DeepPartial<AliasedContentfulFindYourPerfectSystem>,
  email?: string
) =>
  safeProp(key, data)
    .map(field => (
      <Typography
        className="prose prose-p:text-md prose-p:mb-0 text-center"
        key={`RichText${key}`}
        useTailwind
      >
        <ContentfulRichText
          optionsCustom={{
            renderNode: {
              [INLINES.EMBEDDED_ENTRY]: (_, __) =>
                email && isNotEmpty(email) ? (
                  <span id="qwEmail">{email}</span>
                ) : null
            }
          }}
          raw={field.raw}
        />
      </Typography>
    ))
    .orUndefined()

function QuoteWizardComponent({
  handleEmailSubmit,
  handlePhoneSubmit,
  data,
  includePhone,
  finalTabContents,
  finalTabDescription,
  firstTab = null,
  showTitle = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
  type = 'embedded',
  defaultEmail = '',
  defaultPhone = ''
}: QuoteWizardProps) {
  const wizardType: WizardProps['type'] = toWizardType(prop('wizardType', data))
  const showPhone =
    !!includePhone && !!handlePhoneSubmit && !!data.additionalInfoFields
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const quizQuestions = propOr<
    readonly ContentfulQuizQuestions[],
    readonly ContentfulQuizQuestions[]
  >([], 'quizQuestions', data).filter(isNotNil)

  const emailInputPlaceholder = pathOr<string, string>(
    '',
    ['emailDetails', 'formInput', '0', 'placeholderText'],
    data
  )
  const emailTermsMessage = (
    <ContentfulRichText
      optionsCustom={{
        renderNode: {
          [INLINES.HYPERLINK]: ({ data }, children) => (
            <a href={data?.uri || '#'} rel="noreferrer" target="_blank">
              {children}
            </a>
          )
        }
      }}
      raw={data?.termsAndConditions?.raw || data?.terms?.raw}
    />
  )
  const emailSubmitButtonLabel = pathOr<string, string>(
    '',
    ['emailDetails', 'button', 'text'],
    data
  )
  const emailSubmissionErrorMessage = pathOr<string, string>(
    '',
    ['emailDetails', 'submissionErrorMessage'],
    data
  )
  // This type assertion is because toUpper changes ButtonColor to string
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const emailSubmitButtonType = toLower(
    pathOr<string, string>('', ['emailDetails', 'button', 'type'], data)
  ) as ButtonColor

  // ! Support new Phone field https://simplisafe.atlassian.net/browse/ECP-6278
  const combinedPhoneInputPlaceholder = pathOr<string, string>(
    '',
    ['phoneDetails', 'formInput', '0', 'placeholderText'],
    data
  )
  const combinedPhoneSubmissionErrorMessage = pathOr<string, string>(
    '',
    ['phoneDetails', 'submissionErrorMessage'],
    data
  )
  const combinedPhoneFormLabel = pathOr<string, string>(
    '',
    ['phoneDetails', 'formInput', '0', 'title'],
    data
  )
  const submissionPrompt = (
    <Typography className="prose prose-p:text-md" useTailwind>
      <ContentfulRichText raw={data.message?.raw} />
    </Typography>
  )

  // Current phone submission props
  const phoneFormPlaceholders = showPhone
    ? pathOr<readonly ContentfulForms[], readonly ContentfulForms[]>(
        [],
        ['additionalInfoFields', 'formInput'],
        data
      )
    : []
  const phoneSubtitle = showPhone ? (
    <p>
      <strong>{pathOr('', ['additionalInfoFields', 'subTitle'], data)}</strong>
    </p>
  ) : null
  const phoneMessage = showPhone ? (
    <p>
      {pathOr('', ['additionalInfoFields', 'description', 'description'], data)}
    </p>
  ) : null
  const nameInputPlaceholder =
    (showPhone && phoneFormPlaceholders[0]?.placeholderText) || ''
  const phoneInputPlaceholder =
    (showPhone && phoneFormPlaceholders[1]?.placeholderText) || ''
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const phoneSubmitButtonLabel = showPhone
    ? pathOr<string, string>(
        '',
        ['additionalInfoFields', 'button', 'text'],
        data
      )
    : ''
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const emailFormLabel = pathOr<string, string>(
    '',
    ['emailDetails', 'formInput', '0', 'title'],
    data
  )
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const nameInputLabel = pathOr<string, string>(
    '',
    ['additionalInfoFields', 'formInput', '0', 'title'],
    data
  )
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  const phoneInputLabel = pathOr<string, string>(
    '',
    ['additionalInfoFields', 'formInput', '1', 'title'],
    data
  )

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const uiTheme = data?.uiTheme as WizardTheme | undefined
  const { trackEvent } = useTracking()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const handleTrackingEvent = (currentStep: number, type: string) => {
    // eslint-disable-next-line functional/no-let
    let quoteWizardStep = currentStep
    type !== 'embedded' && quoteWizardStep--
    quoteWizardStep > quizQuestions.length &&
      optimizelyTrackSiteEvents({ eventType: 'impacted_22778431687' })
    const eventName = toQuoteWizardQuestionType(quoteWizardStep)
    quoteWizardStep > 0 && eventName && trackEvent({ event: eventName })
  }

  return (
    <Wizard
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      backButtonText={propOr<string, string>('', 'backButtonText', data)}
      handleTrackingEvent={handleTrackingEvent}
      nextButtonText={propOr<string, string>('', 'nextButtonText', data)}
      theme={uiTheme}
      title={showTitle ? propOr<string, string>('', 'title', data) : ''}
      type={wizardType}
    >
      {firstTab}
      {quizQuestions.map((quizQuestion: ContentfulQuizQuestions) => {
        const answersPerRow = prop('answersPerRow', quizQuestion)
        const helpText = prop('helpText', quizQuestion)
        const questionAnswers = propOr<
          readonly ContentfulQuizAnswer[],
          readonly ContentfulQuizAnswer[]
        >([], 'answers', quizQuestion).filter(isNotNil)
        const answers = questionAnswers.map(qAns => {
          const label = prop('text', qAns)
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          const sComponents = propOr<
            readonly ContentfulSystemComponent[],
            readonly ContentfulSystemComponent[]
          >([], 'systemComponents', qAns).filter(isNotNil)
          const value = ifElse(
            isEmpty,
            () => qAns.answer_id,
            map((sComp: ContentfulSystemComponent) => sComp.sku)
          )(sComponents)
          return {
            label,
            value
          }
        })

        return (
          <WizardMultipleChoice
            answers={answers}
            answersPerRow={answersPerRow}
            helpText={helpText}
            id={`${
              path(['systemComponent', 'sku'], quizQuestion) ||
              prop('question_id', quizQuestion)
            }`}
            isMultiSelect={quizQuestion.answerType === 'Multiple Choice'}
            key={prop('id', quizQuestion)}
            question={<ContentfulRichText raw={quizQuestion?.question?.raw} />}
            theme={uiTheme}
            type={wizardType}
          />
        )
      })}
      {combinedPhoneSubmissionErrorMessage ? (
        <WizardSubmit
          buttonProps={{ color: emailSubmitButtonType }}
          data-testid="WizardEmailSubmit"
          defaultEmail={defaultEmail}
          defaultPhone={defaultPhone}
          emailErrorMessage={emailSubmissionErrorMessage}
          // should remain hardcoded value "email" so long as
          // leadGenClient.buildCreateBody() is expecting it
          emailInputPlaceholder={emailInputPlaceholder}
          id="email"
          label={emailFormLabel}
          legalText={emailTermsMessage}
          message={submissionPrompt}
          onSubmit={handleEmailSubmit}
          phoneErrorMessage={combinedPhoneSubmissionErrorMessage}
          phoneInputPlaceholder={combinedPhoneInputPlaceholder}
          phoneLabel={combinedPhoneFormLabel}
          submitButtonLabel={emailSubmitButtonLabel}
        />
      ) : (
        <WizardEmailSubmit
          buttonProps={{ color: emailSubmitButtonType }}
          data-testid="WizardEmailSubmit"
          defaultEmail={defaultEmail}
          emailErrorMessage={emailSubmissionErrorMessage}
          // should remain hardcoded value "email" so long as
          // leadGenClient.buildCreateBody() is expecting it
          id="email"
          label={emailFormLabel}
          legalText={emailTermsMessage}
          message={submissionPrompt}
          onSubmit={handleEmailSubmit}
          placeholder={emailInputPlaceholder}
          submitButtonLabel={emailSubmitButtonLabel}
          theme={uiTheme}
          type={wizardType}
        />
      )}
      {showPhone && (
        <WizardPhoneSubmit
          message={phoneMessage}
          nameLabel={nameInputLabel}
          onSubmit={handlePhoneSubmit ?? always(null)}
          phoneLabel={phoneInputLabel}
          placeholderName={nameInputPlaceholder}
          placeholderPhone={phoneInputPlaceholder}
          submitButtonLabel={phoneSubmitButtonLabel}
          subtitle={phoneSubtitle}
        />
      )}
      <WizardFinal>
        {finalTabDescription}
        {finalTabContents}
      </WizardFinal>
    </Wizard>
  )
}

export default QuoteWizardComponent
