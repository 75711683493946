import { Options } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import {
  COOKIE_LEAD_DATA,
  fbTrackLeadCreated,
  handleBrazeTrackingEvent
} from '@lib/tracking'
import { OptimizelyEvent, useOptimizelyTrackSiteEvents } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import { safeProp } from '@simplisafe/monda'
import { navigate } from 'gatsby'
import {
  CheckoutFormValues,
  clearCartError,
  ShippingOption
} from '@simplisafe/ss-ecomm-data/cart'
import {
  IOSetShippingMethod,
  IOUpdateCart,
  IOUpdateDiscountCodeToCart
} from '@simplisafe/ss-ecomm-data/cart/actions'
import {
  selectCartLoading,
  selectCheckoutFormSubmitActions,
  selectShippingOptions
} from '@simplisafe/ss-ecomm-data/cart/select'
import {
  UKAddressSchema,
  USAddressSchema
} from '@simplisafe/ss-ecomm-data/checkout/address'
import {
  emailSchema,
  ukPostalCodeSchema,
  usPostalCodeSchema
} from '@simplisafe/ss-ecomm-data/checkout/fields'
import {
  buildShippingAddressUpdateAction,
  commercetoolsGetShippingMethods,
  ImmutableCart,
  ShippingMethod,
  ShippingMethodPagedQueryResponse
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  cookiesOption,
  fetchUserCheckoutDataWithPopup,
  leadGenCapture,
  LeadGenCaptureParams,
  LeadGenCaptureResponse,
  leadGenUnsub
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { UserCheckoutData } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { fork } from 'fluture'
import { Form, Formik, useFormikContext } from 'formik'
import { get as getLocalStorage, set as setLocalStorage } from 'local-storage'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import propOr from 'ramda/src/propOr'
import when from 'ramda/src/when'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { ContentfulCheckoutForm } from '../../../graphql'
import { toMaybeOrNone } from '../../commercetools/price'
import type { TrackEvent } from '../../util/analytics'
import {
  trackEpsilonAbacusOptIn,
  trackSubmitLeadEvent
} from '../../util/analytics'
import { setDeviceId } from '../../util/helper'
import { getCartDiscountCode } from '../CartDetailsComponent/transformLineItem'
import Coupons from './form-sections/Coupons'
import CustomerService from './form-sections/CustomerService'
import Email from './form-sections/Email'
import ShippingAddress from './form-sections/ShippingAddress'
import ShippingOptions from './form-sections/ShippingOptions'
import Submission from './form-sections/Submission'
import {
  usePaymentExperience,
  UserIdentification
} from './hooks/usePaymentExperience'
import ShippingAddressModalValidation from './ShippingAddressModalValidation'
import useShippingAddressValidation from './hooks/useShippingAddressValidation'
import {
  getCheckoutPaymentUrl,
  getFirstSuggestionAddress,
  getPersonalUserDataFromCheckout,
  getFormattedUserAddress,
  hasItFoundAddressSuggestion,
  shouldRenderShippingAddressModal,
  isCorrectShippingAddressVariation,
  isCorrectShippingAddressLocale,
  isAddressEmpty,
  getCheckoutBody,
  combineUserDataAndAddress,
  shouldSilentlyUpdate,
  shouldFireAddressImpactedFlag
} from './ShippingAddressModalValidation/utils'
import {
  handleCheckoutSubmit,
  CheckoutRequestResponse
} from '@simplisafe/ss-ecomm-data/checkout/checkout'
import { ShippingValidationMetadataSchema } from './ShippingAddressModalValidation/schema'

export const onLoggedInEvent = (trackEvent: TrackEvent) =>
  trackEvent({
    action: 'logged_in_on_checkout',
    category: 'checkout',
    event: 'logged_in',
    label: ''
  })

export const onOpenShippingAddressModal = (trackEvent: TrackEvent) =>
  trackEvent({
    action: 'modal_opened',
    category: 'checkout',
    event: 'shipping_address_modal_validation_opened',
    label: ''
  })

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type CheckoutFormProps = {
  readonly data: ContentfulCheckoutForm
}

function CheckoutForm({ data }: CheckoutFormProps) {
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const locale = useSelector(selectLocale)
  const cartIsLoading = useSelector(selectCartLoading)

  const isMobile = !useMediaQuery('TabletAndUp')
  const { trackEvent } = useTracking()
  setDeviceId()
  const cookies = new Cookies()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const localeSchema = ifElse(
    equals('en-US'),
    always(USAddressSchema),
    always(UKAddressSchema)
  )(locale)

  const localePostalCodeSchema = ifElse(
    equals('en-US'),
    always(usPostalCodeSchema),
    always(ukPostalCodeSchema)
  )(locale)

  const countryAbbreviation = ifElse(
    equals('en-US'),
    always('US'),
    always('GB')
  )(locale)

  const initialCoupon = cart
    .map(_cart => getCartDiscountCode(_cart))
    .toMaybe()
    .getOrElse('')

  const initialFormValues: CheckoutFormValues = {
    additionalFoundInfoThrough: '',
    additionalStreetInfo: '',
    city: '',
    country: countryAbbreviation,
    couponCode: initialCoupon,
    email: '',
    epsilonAbacusOptIn: false,
    firstName: '',
    foundInfoThrough: '',
    lastName: '',
    offerAndTip: true,
    phone: '',
    postalCode: '',
    sameAddress: true,
    shippingOption: '',
    state: '',
    streetName: ''
  }

  const [currentCoupon, setCurrentCouponValue] = useState(initialCoupon)
  const [couponSubmissionMessage, setCouponSubmissionMessage] = useState('')
  const [couponSubmissionSuccess, setCouponSubmissionSuccess] = useState(false)
  const [couponSubmissionFail, setCouponSubmissionFail] = useState(false)
  const [validatedFormValues, setValidatedFormValues] =
    useState(initialFormValues)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [currentShippingOptions, setCurrentShippingOptions] = useState<
    readonly ShippingMethod[]
  >([])
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [userAddress, setUserAddress] = useState({})
  // User ID and email pair used to identify the user for payment experience selection
  const [userIdentification, setUserIdentification] =
    useState<UserIdentification>({})
  // Signify when shipping address is being updated on the cart to help prevent concurrent updates
  const [updatingShippingInfo, setUpdatingShippingInfo] =
    useState<boolean>(false)
  const [
    shippingAddressModalValidationOpen,
    setShippingAddressModalValidationOpen
  ] = useState(false)
  const [addressValidationResponseSchema, setAddressValidationResponseSchema] =
    useState({
      validatedAddress: {},
      userCheckoutData: {},
      shippingMethod: '',
      metadata: {},
      config: {}
    })
  const { optimizelyReady, shippingAddressValidationExperimentVariation } =
    useShippingAddressValidation()

  usePaymentExperience(userIdentification, !updatingShippingInfo)

  const captureLead = (
    promoCode: string,
    locale: string,
    optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void,
    email: string,
    offerAndTip: boolean,
    loginSuccess: boolean
  ) => {
    const trackLeadSuccess = () => {
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackSubmitLeadEvent(trackEvent)
    }
    const leadHandleSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      // Always cookie lead data and pass data to Braze
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())

      // we track lead when offerAndTip is true AND user is not logged in
      const shouldTrackLeadEvent = offerAndTip && !loginSuccess
      shouldTrackLeadEvent && trackLeadSuccess()

      // we unsubscribe when offerAndTip is false AND user is not logged in
      const shouldUnsubscribe = !offerAndTip && !loginSuccess
      shouldUnsubscribe && leadGenUnsub({ email })(() => null)(() => null)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      value.forEach(
        async response =>
          response.email && (await fbTrackLeadCreated(response.email))
      )
    }

    const leadHandleError = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const leadGenParams: LeadGenCaptureParams = {
      email,
      leadSource: 'exit_intent',
      locale,
      promoCode,
      source: 'checkout',
      sourceType: 'cart'
    }
    leadGenCapture(leadGenParams)(leadHandleError)(leadHandleSuccess)
  }

  const handleSubmitSuccess = (
    checkoutResponse: Maybe<CheckoutRequestResponse>
  ) => {
    setShowErrorMessage(false)

    checkoutResponse.cata(
      () => logError(Error('something went wrong parsing user address')),
      ({ addressValidation, cart }) => {
        shouldFireAddressImpactedFlag(addressValidation)(locale) &&
          optimizelyTrackSiteEvents({ eventType: 'impacted_22878822366' })

        const handleRedirect = () => {
          setDisableSubmit(false)
          setIsFormLoading(false)
          navigate(getCheckoutPaymentUrl(data))
        }

        // Set the validated address behind the scenes (without opening the modal) if it found an address suggestion with invalid corrections
        const handleSetValidatedAddressSilently = () => {
          const addressData = combineUserDataAndAddress(
            getPersonalUserDataFromCheckout(cart?.shippingAddress)
          )(getFirstSuggestionAddress(addressValidation) || {})(locale)

          return shouldSilentlyUpdate(addressValidation)(
            shippingAddressValidationExperimentVariation
          )(locale)
            ? dispatch(
                handleCheckoutSubmit(
                  getCheckoutBody({
                    billingAddress: addressData,
                    shippingAddress: addressData,
                    setBillingAddress: isAddressEmpty(cart.billingAddress),
                    shippingMethod: cart.shippingInfo?.shippingMethod?.id || '',
                    shouldValidate: false,
                    lead: cart.custom?.fields?.leadSource,
                    leadOther: cart.custom?.fields?.leadOther
                  })
                )(handleSubmitError)(handleRedirect)
              )
            : handleRedirect()
        }

        const handleOpenShippingAddressModal = () => {
          setAddressValidationResponseSchema({
            userCheckoutData: getPersonalUserDataFromCheckout(
              cart?.shippingAddress
            ),
            validatedAddress:
              getFirstSuggestionAddress(addressValidation) || {},
            shippingMethod: cart.shippingInfo?.shippingMethod?.id || '',
            metadata: {
              lead: cart.custom?.fields?.leadSource,
              leadOther: cart.custom?.fields?.leadOther
            },
            config: { setBillingAddress: isAddressEmpty(cart?.billingAddress) }
          })
          onOpenShippingAddressModal(trackEvent)
          setIsFormLoading(false)
          setShippingAddressModalValidationOpen(true)
        }

        optimizelyReady &&
        shouldRenderShippingAddressModal(addressValidation)(
          shippingAddressValidationExperimentVariation
        )(locale)
          ? handleOpenShippingAddressModal()
          : handleSetValidatedAddressSilently()
      }
    )
  }

  const handleSubmitError = (err: Error) => {
    logError(Error(`something went wrong with the checkout: ${err.message}`))
    window && window.scrollTo(0, 0)
    setDisableSubmit(false)
  }

  const handleSubmit = (values: CheckoutFormValues) => {
    setIsFormLoading(true)

    Maybe.fromNull(values)
      .chain(safeProp('epsilonAbacusOptIn'))
      .forEach(trackEpsilonAbacusOptIn(trackEvent))
    captureLead(
      currentCoupon,
      locale,
      optimizelyTrackSiteEvents,
      prop('email', values),
      prop('offerAndTip', values),
      loginSuccess
    )

    const checkoutValues = getFormattedUserAddress(values, locale)
    const checkoutBody = getCheckoutBody({
      billingAddress: checkoutValues,
      setBillingAddress: values.sameAddress,
      shippingAddress: checkoutValues,
      shippingMethod: values.shippingOption,
      lead: values.foundInfoThrough,
      leadOther: values.additionalFoundInfoThrough,
      shouldValidate: isCorrectShippingAddressLocale(locale)
    })

    dispatch(
      handleCheckoutSubmit(checkoutBody)(handleSubmitError)(handleSubmitSuccess)
    )
  }

  const validatePostalCode = (code: string) => {
    return localePostalCodeSchema.isValidSync(code)
  }

  const formatAddress = applySpec<Record<string, string>>({
    additionalStreetInfo: path(['addresses', 0, 'street2']),
    city: path(['addresses', 0, 'city']),
    email: prop('emailAddress'),
    firstName: path(['addresses', 0, 'firstName']),
    lastName: path(['addresses', 0, 'lastName']),
    phone: path(['addresses', 0, 'phone']),
    postalCode: path(['addresses', 0, 'zip']),
    state: path(['addresses', 0, 'state']),
    streetName: path(['addresses', 0, 'street1'])
  })

  const fetchUserCheckoutDataSuccess = (
    userCheckoutData: Maybe<UserCheckoutData>
  ) => {
    const formattedAddress = formatAddress(userCheckoutData.orNull())
    //im not sure what this is used for but it was being set in the old form
    setLocalStorage('postalCode', propOr('', 'postalCode', formattedAddress))

    setUserAddress(formattedAddress)
    setLoginSuccess(true)
    onLoggedInEvent(trackEvent)

    userCheckoutData.forEach(userData => {
      setUserIdentification({
        email: prop('emailAddress', userData),
        id: prop('id', userData)
      })
    })
  }

  const fetchUserCheckoutDataFailure = () => {
    logError(Error('CheckoutForm: UserCheckoutData is empty'))
  }

  const getReturningCustomerAddress = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    fetchUserCheckoutDataWithPopup(getLocalStorage('deviceId'))(
      fetchUserCheckoutDataFailure
    )(fetchUserCheckoutDataSuccess)
  }

  const options: Options = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data, content }) =>
        content.length &&
        content[0].nodeType === 'text' && (
          <a href={data?.uri || '#'} onClick={getReturningCustomerAddress}>
            {content[0].value}
          </a>
        )
    }
  }
  const emailDescription = (
    <ContentfulRichText
      optionsCustom={options}
      raw={data?.emailDescription?.raw}
    />
  )
  const validatedDescription = (
    <ContentfulRichText raw={data?.validatedDescription?.raw} />
  )

  const getShippingMethodId = (cart: ImmutableCart) =>
    safeProp('shippingInfo', cart)
      .chain(toMaybeOrNone)
      .chain(safeProp('shippingMethod'))
      .chain(safeProp('id'))

  const maybeShippingOptions: Maybe<readonly ShippingOption[]> = useSelector(
    selectShippingOptions(currentShippingOptions)
  )
  const formattedShippingOptions = maybeShippingOptions.getOrElse([])
  const maybeActions = useSelector(
    selectCheckoutFormSubmitActions(validatedFormValues)
  )

  const handleShippingOptionsSuccess = (cartId: string) => () => {
    setUpdatingShippingInfo(false)

    commercetoolsGetShippingMethods(cartId).pipe(
      fork((e: Error) => {
        logError(e)
      })((shipResp: Maybe<ShippingMethodPagedQueryResponse>) => {
        const shippingData = shipResp.getOrElse({
          count: 0,
          results: []
        })
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        const shippingResults = propOr<
          readonly ShippingMethod[],
          readonly ShippingMethod[]
        >([], 'results', shippingData)
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
        setCurrentShippingOptions(shippingResults)
      })
    )
  }

  const handleShippingOptionsError = () => {
    setUpdatingShippingInfo(false)
    logError(Error('something went wrong when requesting shipping options'))
  }

  const showCouponSuccessMessage = () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setCouponSubmissionMessage(
      propOr<string, string>('', 'couponSuccessMessage', data)
    )
    setCouponSubmissionFail(false)
    setCouponSubmissionSuccess(true)
  }

  const hideCouponSuccessMessage = () => {
    setCouponSubmissionMessage('')
    setCouponSubmissionSuccess(false)
  }

  const handleCouponSubmissionSuccess = () => {
    showCouponSuccessMessage()
    //set a timeout so that the submission message fades away after a code is added
    setTimeout(() => hideCouponSuccessMessage(), 5100)
  }

  const handleCouponSubmissionError = () => {
    setCouponSubmissionSuccess(false)
    setCouponSubmissionFail(true)
    // use this dispatch event to prevent cart from erroring on the screen
    dispatch(clearCartError())
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setCouponSubmissionMessage(
      propOr<string, string>('', 'couponErrorMessage', data)
    )
  }

  const submitNewCouponOnClick = () => {
    when(equals(true), () => {
      dispatch(
        IOUpdateDiscountCodeToCart(
          [currentCoupon],
          handleCouponSubmissionError,
          handleCouponSubmissionSuccess
        )
      )
    })(currentCoupon !== '')
  }

  const submitNewCouponOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    when(equals(true), () => {
      e.preventDefault()
      dispatch(
        IOUpdateDiscountCodeToCart(
          [e.currentTarget.value],
          handleCouponSubmissionError,
          handleCouponSubmissionSuccess
        )
      )
    })(
      e.keyCode === 13 &&
        e.currentTarget.name === 'couponCode' &&
        e.currentTarget.value !== ''
    )
  }

  const shouldLoadShippingOptions = (stateValue: string) => {
    //only load shipping options if the state field is not empty on the us site. For uk, always load shipping option
    return countryAbbreviation === 'GB' ? true : isNotEmpty(stateValue)
  }

  // Fetch the credit payment experience when the email field changes
  function LoadCreditPaymentExperience() {
    const { values, touched, isSubmitting, setFieldTouched } =
      useFormikContext<CheckoutFormValues>()

    useEffect(() => {
      const isTouched = prop('email', touched) ?? false
      const email = prop('email', values) ?? ''
      const fieldChanged =
        isTouched &&
        !isSubmitting &&
        email !== userIdentification.email &&
        emailSchema.isValidSync(email)

      const update = () => {
        setFieldTouched('email', false)
        setUserIdentification({ email })
      }

      when(equals(true), update, fieldChanged)
    }, [values, touched, isSubmitting, setFieldTouched])

    return null
  }

  // use for loading shipping options from commercetools after postal code filled in
  function LoadShippingOptions() {
    const { values, touched, setFieldTouched, setFieldValue, isSubmitting } =
      useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      const streetValue = propOr<string, string>('', 'streetName', values)
      const touchedPostalCode = propOr<boolean, boolean>(
        false,
        'postalCode',
        touched
      )
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      const postalCodeValue = propOr<string, string>('', 'postalCode', values)
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      const stateValue = propOr<string, string>('', 'state', values)
      const cityValue = propOr<string, string>('', 'city', values)
      const shippingAddressUpdateAction = buildShippingAddressUpdateAction({
        country: countryAbbreviation,
        // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
        postalCode: postalCodeValue,
        state: stateValue,
        streetName: streetValue,
        city: cityValue
      })
      // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      const validPostalCode: boolean = validatePostalCode(postalCodeValue)
      when(equals(true), () => {
        cart.forEach(_cart => {
          // Pre-emptively set flag to prevent cart update collisions between shipping info and payment experience
          setUpdatingShippingInfo(true)
          // Fake touched on email field to force a payment experience update if necessary
          setFieldTouched('email', true)
          setFieldTouched('postalCode', false)
          dispatch(
            IOUpdateCart(
              [shippingAddressUpdateAction],
              handleShippingOptionsError,
              handleShippingOptionsSuccess(_cart.id)
            )
          )
        })
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      })(
        validPostalCode &&
          touchedPostalCode &&
          isSubmitting === false &&
          shouldLoadShippingOptions(stateValue)
      )
    }, [isSubmitting, setFieldTouched, setFieldValue, values, touched])
    return null
  }

  function SetShippingOption() {
    const { values, setFieldValue } = useFormikContext<CheckoutFormValues>()
    const [firstShippingResult] = formattedShippingOptions
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const shippingOptionValue = propOr<string, string>(
      '',
      'shippingOption',
      values
    )
    useEffect(() => {
      setDisableSubmit(cartIsLoading)
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      const firstShippingResultId = propOr<string, string>(
        '',
        'id',
        firstShippingResult
      )
      ifElse(
        equals(''),
        () => setFieldValue('shippingOption', firstShippingResultId),
        () => setFieldValue('shippingOption', shippingOptionValue)
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
      )(shippingOptionValue)
    }, [firstShippingResult, setFieldValue, shippingOptionValue])
    return null
  }

  // updates cart with the currently selected shipping option
  function UpdateCartShippingOption() {
    const { values, isSubmitting } = useFormikContext<CheckoutFormValues>()
    const shippingMethodIdFromCart: string = cart
      .chain(getShippingMethodId)
      .getOrElse('')
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const shippingOptionValue = propOr<string, string>(
      '',
      'shippingOption',
      values
    )
    useEffect(() => {
      when(equals(true), () => {
        setDisableSubmit(cartIsLoading)
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
        dispatch(IOSetShippingMethod(shippingOptionValue))
        // @ts-expect-error TS(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
      })(
        shippingMethodIdFromCart !== shippingOptionValue &&
          isNotEmpty(shippingOptionValue) &&
          isSubmitting === false
      )
    }, [shippingMethodIdFromCart, shippingOptionValue, isSubmitting])
    return null
  }

  function UpdateCoupon() {
    const { values } = useFormikContext<CheckoutFormValues>()
    const couponValue = propOr('', 'couponCode', values)
    useEffect(() => {
      setDisableSubmit(cartIsLoading)
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      setCurrentCouponValue(couponValue)
    }, [couponValue])
    return null
  }

  function UpdatePostalCode() {
    const { values, setFieldValue } = useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      when(equals(true), () => {
        // @ts-expect-error TS(2339) FIXME: Property 'toUpperCase' does not exist on type 'unk... Remove this comment to see the full error message
        setFieldValue(
          'postalCode',
          propOr('', 'postalCode', values).toUpperCase()
        )
      })(locale === 'en-GB')
    }, [values, setFieldValue])
    return null
  }

  function ValidateFormValues() {
    const { isSubmitting, values } = useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      const isValid = localeSchema.isValidSync(values)
      when(equals(true), () => {
        ifElse(
          equals(true),
          () => {
            setDisableSubmit(true)
            setValidatedFormValues(values)
          },
          () => {
            setShowErrorMessage(true)
          }
        )(isValid)
      })(isSubmitting)
    }, [isSubmitting, values])
    return null
  }

  function SetReturningCustomerAddress() {
    const { setFieldValue, setFieldTouched, isSubmitting } =
      useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      when(equals(true), () => {
        setFieldValue('postalCode', propOr('', 'postalCode', userAddress))
        setFieldTouched('postalCode', true)
        setFieldValue(
          'additionalStreetInfo',
          propOr('', 'additionalStreetInfo', userAddress)
        )
        setFieldValue('email', propOr('', 'email', userAddress))
        setFieldValue('firstName', propOr('', 'firstName', userAddress))
        setFieldValue('lastName', propOr('', 'lastName', userAddress))
        setFieldValue('streetName', propOr('', 'streetName', userAddress))
        setFieldValue('phone', propOr('', 'phone', userAddress))
        setFieldValue('city', propOr('', 'city', userAddress))
        setFieldValue('state', propOr('', 'state', userAddress))
        // set offerAndTip field to false here because we don't want to register returning customers as a lead
        setFieldValue('offerAndTip', false)
        setUpdatingShippingInfo(true)
        //empty userAddress so that setFieldValues stop executing
        setUserAddress({})
      })(loginSuccess && isNotEmpty(userAddress) && isSubmitting === false)
    }, [setFieldValue, isSubmitting, setFieldTouched])
    return null
  }

  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={localeSchema}
      >
        <Form data-component="CheckoutForm">
          <LoadCreditPaymentExperience />
          <LoadShippingOptions />
          <SetShippingOption />
          <UpdatePostalCode />
          <UpdateCartShippingOption />
          <UpdateCoupon />
          <ValidateFormValues />
          <SetReturningCustomerAddress />
          <Email
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            emailCheckboxLabel={propOr<string, string>(
              '',
              'emailCheckboxLabel',
              data
            )}
            emailDescription={emailDescription}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            emailFieldLabel={propOr<string, string>(
              '',
              'emailFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            emailFieldPlaceholder={propOr<string, string>(
              '',
              'emailFieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            emailSectionTitle={propOr<string, string>(
              '',
              'emailSectionTitle',
              data
            )}
            isMobile={isMobile}
            isValidCustomer={false}
            locale={locale}
            loginSuccess={loginSuccess}
            validatedDescription={validatedDescription}
          />
          <ShippingAddress
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            cityFieldLabel={propOr<string, string>('', 'cityFieldLabel', data)}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            cityFieldPlaceholder={propOr<string, string>(
              '',
              'cityFieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            countryFieldLabel={propOr<string, string>(
              '',
              'countryFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            emailFieldPlaceholder={propOr<string, string>(
              '',
              'emailFieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            firstNameFieldLabel={propOr<string, string>(
              '',
              'firstNameFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            firstNameFieldPlaceholder={propOr<string, string>(
              '',
              'firstNameFieldPlaceholder',
              data
            )}
            isMobile={isMobile}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            lastNameFieldLabel={propOr<string, string>(
              '',
              'lastNameFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            lastNameFieldPlaceholder={propOr<string, string>(
              '',
              'lastNameFieldPlaceholder',
              data
            )}
            locale={locale}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            phoneNumberFieldLabel={propOr<string, string>(
              '',
              'phoneNumberFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            phoneNumberFieldPlaceholder={propOr<string, string>(
              '',
              'phoneNumberFieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            postalCodeFieldLabel={propOr<string, string>(
              '',
              'postalCodeFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            postalCodeFieldPlaceholder={propOr<string, string>(
              '',
              'postalCodeFieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            sameAddressFieldLabel={propOr<string, string>(
              '',
              'sameAddressFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            selectCountry={propOr<readonly string[], readonly string[]>(
              [''],
              'selectCountry',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            shippingSectionTitle={propOr<string, string>(
              '',
              'shippingSectionTitle',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            stateFieldLabel={propOr<string, string>(
              '',
              'stateFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            streetAddress2FieldLabel={propOr<string, string>(
              '',
              'streetAddress2FieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            streetAddress2FieldPlaceholder={propOr<string, string>(
              '',
              'streetAddress2FieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            streetAddressFieldLabel={propOr<string, string>(
              '',
              'streetAddressFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            streetAddressFieldPlaceholder={propOr<string, string>(
              '',
              'streetAddressFieldPlaceholder',
              data
            )}
          />
          <Coupons
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            couponButtonLabel={propOr<string, string>(
              '',
              'couponButtonLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            couponFieldLabel={propOr<string, string>(
              '',
              'couponFieldLabel',
              data
            )}
            couponSubmissionFail={couponSubmissionFail}
            couponSubmissionMessage={couponSubmissionMessage}
            couponSubmissionSuccess={couponSubmissionSuccess}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            couponsSectionTitle={propOr<string, string>(
              '',
              'couponsSectionTitle',
              data
            )}
            isMobile={isMobile}
            locale={locale}
            submitNewCouponOnClick={submitNewCouponOnClick}
            submitNewCouponOnEnter={submitNewCouponOnEnter}
          />
          <ShippingOptions
            isMobile={isMobile}
            shippingOptions={formattedShippingOptions}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            shippingOptionsSectionText={propOr<string, string>(
              '',
              'shippingOptionsSectionText',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            shippingOptionsSectionTitle={propOr<string, string>(
              '',
              'shippingOptionsSectionTitle',
              data
            )}
          />
          <CustomerService
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            additionDetailsFieldLabel={propOr<string, string>(
              '',
              'additionDetailsFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            additionalDetailsFieldPlaceholder={propOr<string, string>(
              '',
              'additionalDetailsFieldPlaceholder',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            epsilonAbacusOptInLabel={propOr<string, string>(
              '',
              'epsilonAbacusOptInLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            foundInfoThrough={propOr<readonly string[], readonly string[]>(
              [''],
              'foundInfoThrough',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            foundInfoThroughPlaceholder={propOr<string, string>(
              '',
              'foundInfoThroughPlaceholder',
              data
            )}
            isMobile={isMobile}
            locale={locale}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            sourceFieldLabel={propOr<string, string>(
              '',
              'sourceFieldLabel',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            sourceSectionText={propOr<string, string>(
              '',
              'sourceSectionText',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            sourceSectionTitle={propOr<string, string>(
              '',
              'sourceSectionTitle',
              data
            )}
          />
          <Submission
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            cancelButtonLabel={propOr<string, string>(
              '',
              'cancelButtonLabel',
              data
            )}
            disableSubmit={cartIsLoading || isFormLoading}
            isMobile={isMobile}
            showErrorMessage={showErrorMessage}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            submissionErrorMessage={propOr<string, string>(
              '',
              'submissionErrorMessage',
              data
            )}
            // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
            submitButtonLabel={propOr<string, string>(
              '',
              'submitButtonLabel',
              data
            )}
          />
        </Form>
      </Formik>
      {shippingAddressModalValidationOpen && (
        <ShippingAddressModalValidation
          modal={{
            data: addressValidationResponseSchema,
            redirectUrl: getCheckoutPaymentUrl(data)
          }}
          open={shippingAddressModalValidationOpen}
          setOpen={setShippingAddressModalValidationOpen}
        />
      )}
    </>
  )
}

export default CheckoutForm
