import { SSButton, Text } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

export type TermsOfSaleProps = {
  readonly content?: ReactNode
  readonly onClick?: () => void
  readonly buttonText: string
  readonly isHidden?: boolean
}

function TermsOfSale({
  content,
  onClick,
  buttonText,
  isHidden
}: TermsOfSaleProps) {
  const outerStyles = classNames('relative md:left-[247px]', {
    hidden: isHidden
  })

  return (
    <div className={outerStyles} data-component="TermsOfSale">
      <div className="hidden md:inline-block">
        <SSButton
          className="text-complementary-blue-100"
          color="link"
          onClick={onClick}
        >
          {buttonText}
        </SSButton>
      </div>
      <Text
        className="w-full text-center prose-p:mx-2.5 prose-p:text-xs prose-p:leading-normal prose-a:text-xs prose-a:font-medium prose-a:leading-normal md:inline-block md:w-56"
        useTailwind
      >
        {content}
      </Text>
    </div>
  )
}

export default TermsOfSale
