import {
  FormField,
  FormTextAreaInput,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type MessageProps = {
  readonly messageFieldLabel: string
  readonly messageFieldPlaceholder: string
}

function Message(props: MessageProps) {
  return (
    <div data-component={'Message'}>
      <FormField
        hideLabel={true}
        label={props.messageFieldLabel}
        name="message"
      >
        <Text fontWeight="medium">{props.messageFieldLabel}</Text>
        <FormTextAreaInput
          name="message"
          placeholder={props.messageFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Message
