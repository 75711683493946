import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import { CartLineItem } from '@simplisafe/ss-react-components'
import { CartLineItemProps } from '@simplisafe/ss-react-components/CartLineItem'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import length from 'ramda/src/length'
import pipe from 'ramda/src/pipe'
import React, { FC, ReactElement, ReactNode, useMemo } from 'react'

import CartApplyCoupon from './cart-sections/CartApplyCoupon'
import CartBelowContents from './cart-sections/CartBelowContents'
import CartEmptyMessage from './cart-sections/CartEmptyMessage'
import CartFooter from './cart-sections/CartFooter'
import CartHeader from './cart-sections/CartHeader'
import CartPartnerCaptureFormSection from './cart-sections/CartPartnerCaptureFormSection'
import CartShippingInfo from './cart-sections/CartShippingInfo'
import CartSubtotal from './cart-sections/CartSubtotal'
import CartWarningMessages from './cart-sections/CartWarningMessages'
import CartWidgets from './cart-sections/CartWidgets'

export type LinkHeaderProps = {
  readonly linkText: string
  readonly linkUrl: string
}

export type CartDetailsProps = {
  readonly itemList: readonly CartLineItemProps[]
  readonly linkHeader: LinkHeaderProps
  readonly titleHeader: string
  readonly affirmPromoMessage?: ReactNode
  readonly showWarning?: boolean
  readonly showMixedComponentsWarning?: boolean
  readonly mixedComponentsMessage?: ReactNode
  readonly withoutSystemMessage: ReactNode
  readonly checkOutButtonHeader: SSButtonProps
  readonly checkOutButtonFooter: SSButtonProps
  readonly coreComponentNotInStock?: boolean
  readonly coreComponentShipEstimate?: ReactNode
  readonly coreComponentsShipDelayText?: ReactNode
  readonly subTotal?: string
  /** Data for empty Cart Message */
  /** The content of the empty cart */
  readonly emptyCartcontent?: ReactNode
  /** ReactElement to Render the  cartMiniBanner with iconRichText */
  readonly cartMiniBanner?: readonly ReactElement[]
  /** ReactElement to Render the  customerReviewCard for US Site  */
  readonly customerReviewCard?: ReactElement
  /** ReactElement to Render the  guaranteeCard for US Site*/
  readonly guaranteeCard?: ReactElement
  readonly affirmCard?: ReactNode
  readonly tryItTestItCard?: ReactNode
  readonly customerServiceCard?: ReactNode
  /** headerText for cartMiniBanner */
  readonly headerText?: string
  /* creditCardLogoImg reactElement for credit Card Logo Image */
  readonly creditCardLogo: readonly ReactElement[]
  readonly affirmMessage?: ReactElement
  /**  cartMonitoringPlan widget */
  readonly cartMonitoringPlan?: ReactElement
  /** Handles the applying coupon form display */
  readonly showApplyCoupon?: boolean
  /** Handles the applying coupon code click event */
  readonly applyCouponCodeClick?: (
    code: string,
    showApplyCouponError?: boolean
  ) => void
  /** Handles the apply coupon code success/error message display */
  readonly showApplyCouponError: boolean
  /** Renders the partner capture form modal based on partner type */
  readonly partnerCaptureForm?: ReactNode
  /** Component(s) to be rendered directly below the cart line item section */
  readonly belowCartContentsSection?: ReactNode
  /** Handles the applying coupon form display */
  readonly disabledCartSubmit?: boolean
  /** Renders warning about quantity limit for the product */
  readonly quantityLimitMessage?: ReactNode
  readonly mobileLink?: ReactNode
  readonly selfMonitoringInCart?: boolean
}

const CartContent: FC<CartDetailsProps> = ({
  linkHeader,
  titleHeader,
  itemList,
  showWarning,
  showMixedComponentsWarning,
  tryItTestItCard,
  affirmCard,
  customerServiceCard,
  affirmPromoMessage,
  coreComponentNotInStock = false,
  coreComponentShipEstimate,
  coreComponentsShipDelayText,
  checkOutButtonHeader,
  checkOutButtonFooter,
  creditCardLogo,
  subTotal,
  quantityLimitMessage,
  mixedComponentsMessage,
  cartMonitoringPlan,
  showApplyCoupon = false,
  applyCouponCodeClick,
  showApplyCouponError,
  partnerCaptureForm,
  belowCartContentsSection,
  withoutSystemMessage,
  disabledCartSubmit = false,
  selfMonitoringInCart
}: CartDetailsProps) => {
  const toCartLineItem = (item: CartLineItemProps, idx: number) => (
    <CartLineItem {...item} key={idx} />
  )

  const getListItems = () =>
    itemList &&
    itemList
      // Removes line items that do not have an itemName.
      .filter(pipe(prop('itemName'), isNotEmpty))
      .map(toCartLineItem)

  const renderListItems = useMemo(getListItems, [itemList])

  if (!itemList || !length(itemList)) {
    return <CartEmptyMessage />
  }

  return (
    <div
      className="grid lg:grid-cols-10 gap-x-28 gap-y-4"
      data-component="CartContent"
    >
      <div className="order-1 lg:order-2 lg:col-span-3">
        <CartWidgets
          affirmCard={affirmCard}
          cartMonitoringPlan={cartMonitoringPlan}
          customerServiceCard={customerServiceCard}
          tryItTestItCard={tryItTestItCard}
        />
      </div>

      <div className="col-span-full lg:order-1 lg:row-start-1 lg:row-end-3 lg:col-span-7">
        <CartHeader
          checkoutButton={checkOutButtonHeader}
          disabledCartSubmit={disabledCartSubmit}
          link={linkHeader}
          title={titleHeader}
        />
        <div className="lg:pt-[32px]">
          <CartWarningMessages
            mixedComponentsMessage={mixedComponentsMessage}
            quantityLimitMessage={quantityLimitMessage}
            selfMonitoringInCart={selfMonitoringInCart}
            showMixedComponentsWarning={showMixedComponentsWarning}
            showQuantityLimitMessage={!!quantityLimitMessage}
            showWithoutSystemMessage={showWarning}
            withoutSystemMessage={withoutSystemMessage}
          />
          {renderListItems}
          <CartPartnerCaptureFormSection>
            {partnerCaptureForm}
          </CartPartnerCaptureFormSection>
          {coreComponentNotInStock && (
            <CartShippingInfo
              delayText={coreComponentsShipDelayText}
              estimateText={coreComponentShipEstimate}
            />
          )}
          <CartApplyCoupon
            applyCouponCodeClick={applyCouponCodeClick}
            showApplyCoupon={showApplyCoupon}
            showApplyCouponError={showApplyCouponError}
          />
          <CartSubtotal
            affirmPromoMessage={affirmPromoMessage}
            subtotal={subTotal}
          />
        </div>
        <div className="flex flex-wrap md:justify-end">
          <CartFooter
            affirmPromoMessage={affirmPromoMessage}
            checkOutButtonFooter={checkOutButtonFooter}
            creditCardLogo={creditCardLogo}
            disabledCartSubmit={disabledCartSubmit}
            link={linkHeader}
          />
        </div>
      </div>

      {belowCartContentsSection && (
        <div className="md:order-3 lg:col-span-full">
          <CartBelowContents>{belowCartContentsSection}</CartBelowContents>
        </div>
      )}
    </div>
  )
}

export default CartContent
