import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { GridContentBlock } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import lensIndex from 'ramda/src/lensIndex'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import set from 'ramda/src/set'
import T from 'ramda/src/T'
import toLower from 'ramda/src/toLower'
import React, { ReactNode } from 'react'

import { ContentfulComparisonBanner as ContentfulComparisonBannerType } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type ContentfulComparisonBannerProps = {
  readonly id: string
  readonly data: ContentfulComparisonBannerType
}

const toHeaderLogo = (x: any) => (
  <GatsbyImage
    alt={path(['headerLogo', 'description'], x) || 'SimpliSafe logo'}
    image={getImage(prop('headerLogo', x))}
    style={{ width: '100%' }}
  />
)

const simplisafeIndex = 1

const textColumn = (value: string) => ({
  type: 'text',
  value
})

const toHeaderData = (headerData: ReadonlyArray<string>, imgUrl: ReactNode) => {
  const getHeaderRow = cond([
    [
      equals('Simplisafe'),
      always({
        type: 'icon',
        value: imgUrl
      })
    ],
    [isEmpty, always({})],
    [T, textColumn]
  ])
  return map(getHeaderRow, headerData)
}

const toRowData = (rowData: ReadonlyArray<string>, ssIndex: number) => {
  const getRowData = cond([
    [
      pipe(toLower, equals('yes')),
      always({
        type: 'icon',
        // TODO can we validate these icon names somehow? or at least don't break the site if it's wrong
        value: 'CheckMarkGrayCircle'
      })
    ],
    [pipe(toLower, equals('no')), always({})],
    [T, textColumn]
  ])
  const row = map(getRowData, rowData)
  const ssLens = lensIndex(ssIndex)
  const rowResult = set(
    ssLens,
    {
      type: 'icon',
      value: 'CheckMarkBlueCircle'
    },
    row
  )
  return { row: rowResult }
}

export default function ContentfulComparisonBanner({
  data
}: ContentfulComparisonBannerProps) {
  const headerLogo = pipe(toHeaderLogo)(data)
  const tableData = path(['table', 'tableData'], data)
  const title = prop('title', data)

  const [headerData, ...rowData] = tableData ? tableData : []

  const headerArray = headerData ? toHeaderData(headerData, headerLogo) : []
  const rowArray = rowData.map((item: readonly string[]) =>
    toRowData(item, simplisafeIndex)
  )
  const table = {
    header: headerArray,
    rows: rowArray
  }
  return (
    <GridContentBlock
      content={
        data.description?.raw ? (
          <ContentfulRichText raw={data.description?.raw} />
        ) : (
          <></>
        )
      }
      iconPopup={false}
      tableList={table}
      title={title || ''}
    />
  )
}

export const contentfulComparisonFragment = graphql`
  #graphql
  fragment contentfulComparisonBanner on ContentfulComparisonBanner {
    id
    internal {
      type
    }
    title
    headerLogo {
      file {
        url
      }
      gatsbyImageData(layout: CONSTRAINED, width: 300, placeholder: BLURRED)
      title
      # id
      description # TODO get description from gatsbyImageData
    }
    description {
      raw
    }
    table {
      tableData
    }
  }
`
