import { FormField, FormTextInput, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type EmailProps = {
  readonly label: string
  readonly placeholder: string
}

function Email(props: EmailProps) {
  return (
    <div data-component={'Email'}>
      <FormField hideLabel={true} name="email">
        <Text fontWeight="medium">{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name="email"
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default Email
