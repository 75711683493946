import {
  paymentFormProfileFetch,
  PaymentProfileResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { chain, fork, FutureInstance, reject, resolve } from 'fluture'
import { Maybe } from 'monet'

import {
  ChaseOrderData,
  forwardToPaymentErrorUrl,
  logErrorWithOrderInfo
} from './createOrder'

const validateResponseBody = chain((response: Maybe<PaymentProfileResponse>) =>
  response.cata<FutureInstance<Error, PaymentProfileResponse>>(
    () =>
      reject(
        Error(
          'submitChaseOrder: payment profile response does not have a valid body'
        )
      ),
    _res => resolve(_res)
  )
)

const validateCustomerRef = chain(
  (response: PaymentProfileResponse): FutureInstance<Error, string> =>
    response.customerRefNum
      ? resolve(response.customerRefNum.toString())
      : reject(
          Error(
            'submitChaseOrder: payment profile response missing customerRefNum'
          )
        )
)

/**
 * Submits payment to Chase, completes the other and forwards the user to
 * either the payment confirmation page or the webapp for preactivation.
 */
const submitChaseOrder = ({
  chaseToken,
  createOrder,
  onPaymentError,
  onPaymentProcessing,
  transactionSecret,
  locale
}: {
  readonly chaseToken: string
  readonly createOrder: (orderData: ChaseOrderData) => void
  readonly onPaymentError: (e: Error) => void
  readonly onPaymentProcessing: () => void
  readonly transactionSecret: string
  readonly locale: string
}) => {
  onPaymentProcessing()

  return paymentFormProfileFetch(chaseToken, transactionSecret)
    .pipe(validateResponseBody)
    .pipe(validateCustomerRef)
    .pipe(
      fork((e: Error) => {
        logErrorWithOrderInfo(e)
        onPaymentError(e)
        forwardToPaymentErrorUrl(e)
      })(customerRefNumber => {
        createOrder({
          chaseCustomerRefNumber: customerRefNumber,
          chaseToken,
          locale,
          provider: 'chase',
          transactionSecret,
          type: 'credit'
        })
      })
    )
}

export default submitChaseOrder
