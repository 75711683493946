import { GatsbyImage } from '@lib/components'
import { ProductCard } from '@simplisafe/ss-react-components'
import React, { Dispatch, SetStateAction } from 'react'

import { ContentfulProductCardFloorPlan } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type FloorPlanProductCardListProps = {
  readonly items: readonly ContentfulProductCardFloorPlan[]
  readonly selectedItemIndex: number
  readonly selectItemIndex: Dispatch<SetStateAction<number>>
}

export default function FloorPlanProductCardList({
  items,
  selectedItemIndex,
  selectItemIndex
}: FloorPlanProductCardListProps): JSX.Element {
  return (
    <>
      {items.map((card: ContentfulProductCardFloorPlan, idx: number) => {
        const descriptionRichText = card?.description?.raw

        const image = card?.productImage

        const cardImage = image ? (
          <GatsbyImage className="w-32" image={image} />
        ) : null

        return descriptionRichText && image ? (
          <div
            className="col-span-12 md:col-span-6 lg:col-span-3"
            data-component={`card-parent-${idx}`}
            data-test={idx}
            key={idx}
            onClick={() => selectItemIndex(idx)}
            onMouseEnter={() => selectItemIndex(idx)}
          >
            <ProductCard
              // @ts-expect-error
              description={
                <ContentfulRichText
                  raw={card?.description?.raw}
                  references={card?.description?.references || []}
                />
              }
              img={cardImage}
              isActive={selectedItemIndex === idx}
            />
          </div>
        ) : null
      })}
    </>
  )
}
