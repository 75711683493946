import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

export type TrustPilot = {
  readonly dataBusinessunitId: string
  readonly dataLocale: string
  readonly dataStars: string
  readonly dataStyleHeight: string
  readonly dataStyleWidth: string
  readonly dataTemplateId: string
  readonly dataTheme: string
}

export type TrustPilotBannerProps = {
  readonly children?: React.ReactElement
  readonly className?: string
  readonly widget: TrustPilot
}

export default function TrustpilotBanner({
  widget,
  children,
  className
}: TrustPilotBannerProps) {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)

  /** If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
   * If it's not, it means the script isn't loaded yet.
   * When it is, it will automatically load the TrustBox.
   */
  useEffect(() => {
    const current = ref.current
    current &&
      window.Trustpilot &&
      window.Trustpilot?.loadFromElement(current, true)
  }, [])

  return (
    <>
      <Helmet>
        <script
          async
          src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          type="text/javascript"
        ></script>
      </Helmet>

      <div
        className={classNames(
          'cursor-pointer pointer-events-none trustpilot-widget',
          className
        )}
        data-businessunit-id={widget.dataBusinessunitId}
        data-component={TrustpilotBanner.name}
        data-locale={widget.dataLocale}
        data-stars={widget.dataStars}
        data-style-height={widget.dataStyleHeight}
        data-style-width={widget.dataStyleWidth}
        data-template-id={widget.dataTemplateId}
        data-theme={widget.dataTheme}
        ref={ref}
      >
        <div className="w-full text-center">{children}</div>
      </div>
    </>
  )
}
