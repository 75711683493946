import prop from '@simplisafe/ewok/ramda/prop'
import { Column, HoverBorder } from '@simplisafe/ss-react-components'
import { Spans } from '@simplisafe/ss-react-components/Column'
import React, { MouseEventHandler } from 'react'

import { nullToUndefined } from '../../util/helper'
import SimpleBannerComponent from '../SimpleBannerComponent'
import { SimpleBannerComponentProps } from '../SimpleBannerComponent/types'

export type SimpleBannerWrapperProps = {
  readonly data: SimpleBannerComponentProps['data']
  readonly spans: Spans
  readonly isSelected: boolean
  readonly onClick?: MouseEventHandler<HTMLElement>
}

export default function SimpleBannerWrapper({
  data,
  spans,
  isSelected,
  onClick
}: SimpleBannerWrapperProps) {
  const styles = {
    button: {
      appearance: 'none',
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      width: '100%'
    }
  } as const

  return (
    <Column
      key={data.id}
      rounded={'none'}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      spans={spans as Spans}
    >
      <button
        aria-controls="alarm-sensors-list"
        aria-expanded={isSelected}
        aria-label={nullToUndefined(prop('title', data))}
        onClick={onClick}
        style={styles.button}
      >
        <HoverBorder isSelected={isSelected}>
          {[<SimpleBannerComponent data={data} id={data.id} key={data.id} />]}
        </HoverBorder>
      </button>
    </Column>
  )
}
