import { Text } from '@simplisafe/ss-react-components'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import React from 'react'

import usePromoBannerData from './usePromoBannerData'

function Countdown() {
  const {
    displayEndTimeCalloutOverride,
    endTime,
    endTimeCalloutOverride,
    hasCountdown
  } = usePromoBannerData()

  const endDate = displayEndTimeCalloutOverride
    ? endTimeCalloutOverride
    : endTime
  const datetime = endDate ? new Date(endDate) : false
  const isExpired = datetime ? Promo.isExpired(datetime) : false

  return hasCountdown && datetime && !isExpired ? (
    <Text
      className={'mb-2 rc-text-xs md:mb-0 md:ml-4 lg:ml-20 lg:text-sm'}
      inheritTextColor={true}
      useTailwind={true}
    >
      <style>
        {
          // Hack to overwrite classes and styles applied in ss-react-components.
          `
            [data-component="Countdown"] {
              grid-column-gap: 4px;
              grid-template-columns: 2em min-content 2em min-content 2em !important;
              text-transform: lowercase !important;
            }

            [data-component="Countdown"] .rc-text-4xl {
              font-size: 1.125rem;
              line-height: 1.56;
            }

            @media screen and (min-width: 1240px) {
              [data-component="Countdown"] .rc-text-4xl {
                font-size: 1.5rem;
                line-height: 1.166666666666667;
              }
            }
          `
        }
      </style>
      <Promo.Countdown endTime={datetime} showLabels={true} />
    </Text>
  ) : null
}

export default Countdown
