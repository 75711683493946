import { SimpleButton } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import React from 'react'

import { SimpliSafeCSSProperties } from '../types/SimpliSafeCSSProperties'
import usePromoBannerData from './usePromoBannerData'

type ButtonCtaProps = {
  readonly isFull?: boolean
}

function ButtonCta({ isFull = false }: ButtonCtaProps) {
  const { buttonBackgroundColor, buttonTextColor, locale } =
    usePromoBannerData()

  const buttonStyles: SimpliSafeCSSProperties = {
    '--btn-accent': buttonTextColor,
    '--btn-primary': buttonBackgroundColor,
    '--btn-primary-light': buttonBackgroundColor,
    borderColor: buttonTextColor,
    borderWidth: '2px'
  }

  const buttonHoverColor =
    locale === 'en-US' ? '#FFFFFF' : buttonBackgroundColor

  return (
    <a className={classNames({ 'w-full': isFull })} href="/home-security-shop">
      <style>
        {`
            .holiday-btn {
              background-color: ${buttonBackgroundColor};
              color: ${buttonTextColor};
            }

            .holiday-btn:hover {
              background-color: ${buttonTextColor};
              color: ${buttonHoverColor};
            }
          `}
      </style>
      <SimpleButton
        className={'holiday-btn md:justify-self-end no-underline'}
        size={isFull ? 'full' : 'auto'}
        style={buttonStyles}
        type="submit"
      >
        Shop deals
      </SimpleButton>
    </a>
  )
}

export default ButtonCta
