import {
  FormCheckbox,
  FormField,
  FormSection,
  FormSelect,
  FormTextInput
} from '@simplisafe/ss-react-components'
import React from 'react'

type ShippingAddressProps = {
  readonly shippingSectionTitle: string
  readonly firstNameFieldLabel: string
  readonly firstNameFieldPlaceholder: string
  readonly emailFieldPlaceholder: string
  readonly lastNameFieldLabel: string
  readonly lastNameFieldPlaceholder: string
  readonly locale: string
  readonly stateFieldLabel: string
  readonly selectCountry: readonly string[]
  readonly streetAddressFieldLabel: string
  readonly streetAddressFieldPlaceholder: string
  readonly streetAddress2FieldLabel: string
  readonly streetAddress2FieldPlaceholder: string
  readonly cityFieldLabel: string
  readonly cityFieldPlaceholder: string
  readonly isMobile: boolean
  readonly postalCodeFieldLabel: string
  readonly postalCodeFieldPlaceholder: string
  readonly countryFieldLabel: string
  readonly phoneNumberFieldLabel: string
  readonly phoneNumberFieldPlaceholder: string
  readonly sameAddressFieldLabel: string
}

function ShippingAddress(props: ShippingAddressProps) {
  return (
    <div data-component={'ShippingAddress'}>
      <FormSection name={props.shippingSectionTitle}>
        <FormField
          hideLabel={props.isMobile}
          id="firstName"
          label={props.firstNameFieldLabel}
          name="firstName"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="firstName"
            name="firstName"
            placeholder={props.firstNameFieldPlaceholder}
          />
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          id="lastName"
          label={props.lastNameFieldLabel}
          name="lastName"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="lastName"
            name="lastName"
            placeholder={props.lastNameFieldPlaceholder}
          />
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          id="streetName"
          label={props.streetAddressFieldLabel}
          name="streetName"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="streetName"
            name="streetName"
            placeholder={props.streetAddressFieldPlaceholder}
          />
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          id="additionalStreetInfo"
          label={props.streetAddress2FieldLabel}
          name="additionalStreetInfo"
          opacityZero={!props.isMobile}
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="additionalStreetInfo"
            name="additionalStreetInfo"
            placeholder={props.streetAddress2FieldPlaceholder}
          />
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          id="city"
          label={props.cityFieldLabel}
          name="city"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="city"
            name="city"
            placeholder={props.cityFieldPlaceholder}
          />
        </FormField>
        {props.locale && props.locale === 'en-US' ? (
          <FormField
            hideLabel={props.isMobile}
            id="state"
            label={props.stateFieldLabel}
            name="state"
          >
            <FormSelect
              fullWidth={props.isMobile}
              name="state"
              options={[
                'AK',
                'AL',
                'AR',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY'
              ]}
              placeholder="Please Select"
            />
          </FormField>
        ) : null}
        <FormField
          hideLabel={props.isMobile}
          id="postalCode"
          label={props.postalCodeFieldLabel}
          name="postalCode"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="postalCode"
            name="postalCode"
            placeholder={props.postalCodeFieldPlaceholder}
          />
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          id="country"
          label={props.countryFieldLabel}
          name="country"
        >
          <FormSelect
            disabled
            fullWidth={props.isMobile}
            name="country"
            options={props.selectCountry}
          />
        </FormField>
        <FormField
          hideLabel={props.isMobile}
          id="phone"
          label={props.phoneNumberFieldLabel}
          name="phone"
        >
          <FormTextInput
            fullWidth={props.isMobile}
            id="phone"
            name="phone"
            placeholder={props.phoneNumberFieldPlaceholder}
          />
        </FormField>
        <FormField hideLabel={props.isMobile} name={'same-billing'}>
          <FormCheckbox
            checked
            label={props.sameAddressFieldLabel}
            name="sameAddress"
          />
        </FormField>
      </FormSection>
    </div>
  )
}

export default ShippingAddress
