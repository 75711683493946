import path from '@simplisafe/ewok/ramda/path'
import { safeHead, safeProp } from '@simplisafe/monda'
import { Maybe } from 'monet'

import { ContentfulProductDetail } from '../../../graphql'
import type { DynamicPackageHeroBannerProps } from '.'

export const extractHeroBannerComponentByType =
  (data: DynamicPackageHeroBannerProps['data']) => (componentType: string) =>
    safeHead(
      safeProp('components', data)
        // @ts-expect-error TS(2769): No overload matches this call.
        .map(components =>
          components.filter(
            component =>
              component &&
              path(['internal', 'type'], component) === componentType
          )
        )
        .map(components =>
          components
            .map(component => Maybe.fromNull(component))
            .filter(val => val.isSome())
            .map(val => val.just())
        )
        .getOrElse([])
    )

export const getPlanText = (key: string, data: ContentfulProductDetail) => {
  const option = data.priceOptions?.find(po => po?.productType === key)
  return option
    ? {
        raw: option.savingDetails?.raw ?? '',
        references: option.savingDetails?.references ?? []
      }
    : {}
}
