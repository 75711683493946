import type { Locale } from '@lib/utils'

import type { Product } from '../types/tracking'
import type { PackageProductSchema, ProductSchema } from '../utils/schema'

export const bmsToTrackingProducts = (
  basePackage: PackageProductSchema,
  productList: readonly ProductSchema[],
  locale: Locale,
  totalPrice: number
): readonly Product[] => {
  return [
    packageProductToTrackingProduct(basePackage, totalPrice, locale),
    ...includedProductsToTrackingProducts(basePackage.products),
    ...productsToTrackingProducts(productList)
  ]
}

const packageProductToTrackingProduct = (
  basePackage: PackageProductSchema,
  price: number,
  locale: Locale
): Product => ({
  brand: 'bms',
  id: basePackage.baseProduct,
  name: 'bms',
  price: Math.round((price + Number.EPSILON) * 100) / 100,
  quantity: 1,
  // @ts-expect-error
  variant: basePackage.products.map(product => ({
    name: { [locale]: product.name },
    price: 0,
    quantity: 1,
    sku: product.sku
  }))
})

const includedProductsToTrackingProducts = (
  products: PackageProductSchema['products']
): readonly Product[] =>
  products.map(product => ({
    brand: 'bms',
    id: product.sku,
    name: product.name,
    price: 0,
    quantity: 1
  }))

const productsToTrackingProducts = (
  products: readonly ProductSchema[]
): readonly Product[] =>
  products.map(product => ({
    brand: 'bms',
    id: product.sku,
    name: product.name,
    price: 0,
    quantity: product.quantity
  }))
