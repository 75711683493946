import path from '@simplisafe/ewok/ramda/path'
import { safePath, safeProp } from '@simplisafe/monda'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { PageBody, PageWrapper } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql, PageProps } from 'gatsby'
import { None } from 'monet'
import { prop, propOr } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { PageContext } from '../../config/wrap-with-context'
import {
  ContentfulCheckoutForm,
  ContentfulCheckoutOrderDetails,
  ContentfulCheckoutPage,
  ContentfulCheckoutPageTemplate,
  ContentfulFooter,
  ContentfulHeaderWithProgressBar,
  ContentfulPaymentForm,
  ContentfulPaymentFormErrorMessage,
  ContentfulSiteWideMessages,
  ContentfulSmallTextSection
} from '../../graphql'
import CheckoutPageWrapper from '../components/CheckoutPageWrapper'
import FooterComponent from '../components/FooterComponent'
import HeaderProgressBarComponent from '../components/HeaderProgressBarComponent'
import LiveChat from '../components/LiveChat'
import SimpleFooterComponent from '../components/SimpleFooterComponent'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'
import ErrorBoundary from '../errorComponents/ErrorBoundary'
import { PageTitleContext } from '../tracking/pageTitleContext'
import { trackEventIsMobile } from '../util/analytics'
import SEO from '../util/seo'
export type CheckoutPageContext = PageContext & {
  readonly id: string
  readonly slug?: string
}

export type CheckoutPageQuery = {
  readonly contentfulSiteWideMessages?: Partial<ContentfulSiteWideMessages> | null
  readonly contentfulCheckoutPage?: Pick<
    ContentfulCheckoutPage,
    'metaTitle' | 'pageTitle' | 'site' | 'url'
  > & {
    readonly template?: Pick<
      ContentfulCheckoutPageTemplate,
      'defaultPageTitle' | 'hasLiveChat' | 'liveChatAppId'
    > & {
      readonly header?: Partial<ContentfulHeaderWithProgressBar> | null
      readonly usCheckoutFooter?: Partial<ContentfulFooter> | null
      readonly ukCheckoutFooter?: {
        readonly description?: {
          readonly raw?: string | null
        }
      } | null
    }
    readonly form?:
      | Partial<ContentfulCheckoutForm>
      | Partial<ContentfulPaymentForm>
      | null
    readonly paymentErrorMessage?: Partial<ContentfulPaymentFormErrorMessage> | null
    readonly orderSummary?: Partial<ContentfulCheckoutOrderDetails> | null
  }
}

export type CheckoutPageProps = PageProps<
  CheckoutPageQuery,
  CheckoutPageContext
>

function CheckoutPage({ data }: CheckoutPageProps) {
  const setPageTitle = useContext(PageTitleContext)
  const { trackEvent } = useTracking()
  const isTabletUp = useMediaQuery('TabletAndUp')

  const contentfulCheckoutPage = prop('contentfulCheckoutPage', data)
  const pageUrl = path(['contentfulCheckoutPage', 'url'], data) || ''
  const metaTitle = path(['contentfulCheckoutPage', 'metaTitle'], data) || ''

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const pageTitle = propOr<string, string>(
    '',
    'pageTitle',
    contentfulCheckoutPage
  )
  const locale = useSelector(selectLocale)

  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])

  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [isTabletUp, trackEvent])

  useEffect(() => {
    window && window.scrollTo(0, 0)
  }, [])

  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const form: ContentfulCheckoutForm | ContentfulPaymentForm = safePath(
    ['contentfulCheckoutPage', 'form'],
    data
  ).orJust({})
  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const orderSummary: ContentfulCheckoutOrderDetails = safePath(
    ['contentfulCheckoutPage', 'orderSummary'],
    data
  ).orJust({})
  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const paymentErrorMessage: Partial<ContentfulPaymentFormErrorMessage> =
    safePath(['contentfulCheckoutPage', 'paymentErrorMessage'], data).orJust({})

  const liveChatId: string = safePath(
    ['contentfulCheckoutPage', 'template', 'liveChatAppId'],
    data
  ).orJust('')
  const liveChatEnabled: boolean = safePath(
    ['contentfulCheckoutPage', 'template', 'hasLiveChat'],
    data
  ).orJust(false)

  return (
    <ErrorBoundary>
      <SiteWideMessagesContext.Provider
        // @ts-expect-error TS(2322) FIXME: Type 'Partial<ContentfulSiteWideMessages>' is not ... Remove this comment to see the full error message
        value={safeProp('contentfulSiteWideMessages', data).orJust({})}
      >
        <PageWrapper>
          <SEO
            lang={locale}
            metaTitle={metaTitle}
            pageUrl={pageUrl}
            productId={None()}
          />
          {safePath(['contentfulCheckoutPage', 'template', 'header'], data)
            .map((header: Partial<ContentfulHeaderWithProgressBar>) => {
              return (
                // key not needed for Maybe.map

                <ErrorBoundary>
                  <div data-component="HeaderProgressBar">
                    <HeaderProgressBarComponent data={header} />
                  </div>
                </ErrorBoundary>
              )
            })
            .orNull()}
          <ErrorBoundary>
            <PageBody>
              <CheckoutPageWrapper
                formData={form}
                orderSummaryData={orderSummary}
                // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
                pageTitle={pageTitle}
                paymentErrorMessage={paymentErrorMessage}
              />
            </PageBody>
          </ErrorBoundary>
          {locale === 'en-US' &&
            safePath(
              ['contentfulCheckoutPage', 'template', 'usCheckoutFooter'],
              data
            )
              .map((usCheckoutFooter: ContentfulFooter) => (
                // us checkout pages use the footer component
                // key not needed for Maybe.map

                <ErrorBoundary>
                  <FooterComponent data={usCheckoutFooter} />
                </ErrorBoundary>
              ))
              .orNull()}

          {locale === 'en-GB' &&
            safePath(
              ['contentfulCheckoutPage', 'template', 'ukCheckoutFooter'],
              data
            )
              // @ts-expect-error TS(2345) FIXME: Argument of type '(ukCheckoutFooter: ContentfulSma... Remove this comment to see the full error message
              .map((ukCheckoutFooter: ContentfulSmallTextSection) => (
                // uk checkout pages use the simplefooter component
                // key not needed for Maybe.map

                <ErrorBoundary>
                  <SimpleFooterComponent data={ukCheckoutFooter} />
                </ErrorBoundary>
              ))
              .orNull()}
          {liveChatEnabled && <LiveChat appId={liveChatId} />}
        </PageWrapper>
      </SiteWideMessagesContext.Provider>
    </ErrorBoundary>
  )
}
export default CheckoutPage

export const query = graphql`
  #graphql
  query CheckoutPage($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulCheckoutPage(id: { eq: $id }) {
      pageTitle
      site
      url
      metaTitle
      template {
        defaultPageTitle
        hasLiveChat
        header {
          ... on ContentfulHeaderWithProgressBar {
            ...headerProgressBarFragment
          }
        }
        liveChatAppId
        usCheckoutFooter {
          ... on ContentfulFooter {
            ...contentfulFooter
          }
        }
        ukCheckoutFooter {
          ... on ContentfulSmallTextSection {
            description {
              raw
              references {
                ... on ContentfulLink {
                  contentful_id
                  ...contentfulLinkFragment
                }
              }
            }
          }
        }
      }
      form {
        ... on ContentfulCheckoutForm {
          ...checkoutForm
        }
        ... on ContentfulPaymentForm {
          ...paymentForm
        }
      }
      paymentErrorMessage {
        ... on ContentfulPaymentFormErrorMessage {
          ...paymentFormErrorMessageFragment
        }
      }
      orderSummary {
        ... on ContentfulCheckoutOrderDetails {
          ...checkoutOrderDetails
        }
      }
    }
  }
`
