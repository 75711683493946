import { useDecision } from '@lib/optimizely'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import equals from 'ramda/src/equals'
import when from 'ramda/src/when'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const HEIGHT_DESKTOP_TABLET_UK = '560px'
const HEIGHT_DESKTOP_TABLET_US = '620px'
const HEIGHT_MOBILE = '900px'
const HEIGHT_DESKTOP_TABLET_US_WITH_CAPTCHA = '820px'
const HEIGHT_MOBILE_US_WITH_CAPTCHA = '1160px'

export const useChaseIframeHeight = () => {
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const locale = useSelector(selectLocale)

  const [tabletDesktopIframeHeight, setTabletDesktopIframeHeight] = useState(
    locale === 'en-GB' ? HEIGHT_DESKTOP_TABLET_UK : HEIGHT_DESKTOP_TABLET_US
  )
  const [mobileIframeHeight, setMobileIframeHeight] = useState(HEIGHT_MOBILE)

  const [isUSChaseIframeWithCaptcha] = useDecision(
    'fcp_us_chase_iframe_height_with_captcha'
  )

  useEffect(() => {
    const hasCaptcha = locale === 'en-US' && isUSChaseIframeWithCaptcha.enabled

    when(equals(true), () => {
      setMobileIframeHeight(HEIGHT_MOBILE_US_WITH_CAPTCHA)
      setTabletDesktopIframeHeight(HEIGHT_DESKTOP_TABLET_US_WITH_CAPTCHA)
    })(hasCaptcha)
  }, [locale, isUSChaseIframeWithCaptcha.enabled])

  return isTabletAndUp ? tabletDesktopIframeHeight : mobileIframeHeight
}
