import * as Promo from '@simplisafe/ss-react-components/promotions'
import React from 'react'

import type { HolidayPromoBannerType } from '.'
import CartLayout from './CartLayout'
import TopLayout from './TopLayout'

export type TopBannerProps = { readonly type: HolidayPromoBannerType }

function TopBanner({ type }: TopBannerProps) {
  return (
    <Promo.PromoBanner position="top" role="banner">
      {type === 'cart' ? <CartLayout /> : <TopLayout type={type} />}
    </Promo.PromoBanner>
  )
}

export default TopBanner
