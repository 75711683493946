import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { GatsbyImage } from '@lib/components'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking'
import isLeftValue from '@simplisafe/ewok/monet-utils/isLeftValue'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { setMiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import {
  selectMiniCartLineItems,
  selectProduct
} from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  CartUpdatedMessage,
  Modal,
  OfferTag
} from '@simplisafe/ss-react-components'
import { CardItem } from '@simplisafe/ss-react-components'
import { CartItemDialog } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import isEmpty from 'ramda/src/isEmpty'
import join from 'ramda/src/join'
import pathOr from 'ramda/src/pathOr'
import split from 'ramda/src/split'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { pipe } from 'ts-functional-pipe'

import {
  ContentfulAccessoriesCardVariation,
  ContentfulAsset
} from '../../../graphql'
import { ContentfulProductCardAccessories } from '../../../graphql'
import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import AddToCartError, {
  AddToCartErrorType
} from '../../errorComponents/AddToCartError'
import { usePriceContext } from '../../providers/PriceProvider'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import {
  leadingSlashIt,
  nullToUndefined,
  toFirstCharLower
} from '../../util/helper'
import { verifyButtonColor } from '../../util/verifyButtonColor'
import ContentfulRichText from '../ContentfulRichText'

type AccessoriesCardProps = {
  readonly children?: ReactNode
  readonly data: Partial<ContentfulProductCardAccessories>
}

type VariationState = {
  readonly [x: string]: {
    readonly image?: ContentfulAsset
    readonly productId?: string | null
  }
}

const getTitle = (data: Partial<ContentfulProductCardAccessories>) => {
  const url: string = path(['pageLink', 'pageUrl'], data) || ''
  const title: string | undefined = path(['productName', 'linkText'], data)
  return !isEmpty(url) ? <Link to={leadingSlashIt(url)}>{title}</Link> : title
}

// TODO: create component to take a ContentfulAsset and render a Gatsby Image
export const toFormImg = (image?: ContentfulAsset) => {
  return image ? (
    <GatsbyImage
      className="h-full w-full"
      image={image}
      key={prop('id', image)}
    />
  ) : null
}

const getGatsbyImage = (
  data: Partial<ContentfulProductCardAccessories>
): ReactNode => {
  return <GatsbyImage className="h-full w-full" image={data?.image?.[0]} />
}

export default function AccessoriesCardComponent({
  data
}: AccessoriesCardProps) {
  const dispatch = useDispatch()

  const [isAddToCardModalVisible, setAddToCartModalVisibility] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const defaultQuantity = safeProp('defaultQuantity', data).orJust(0)
  const maxQuantity = safeProp('maximumQuantity', data).orUndefined()
  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const [addToCartError, setAddToCartError] = useState<AddToCartErrorType>(null)
  const { Track, trackEvent } = useTracking()
  const [isCartUpdated, setCartUpdated] = useState(false)

  // TODO: this eslint warning will go away once we upgrade to TS 4 and the Monda 3

  const cartUpdatedText: Maybe<string> = safePath(
    ['cartUpdatedText', 'text', 'text'],
    data
  )
  const productLimitMessage: string | undefined = safeProp(
    'productAvailability',
    data
  ).orUndefined()

  const defaultColor: string = safeProp('defaultColor', data).getOrElse('')

  const defaultVariation: VariationState = useMemo(
    () => ({
      [defaultColor]: {
        image: path(['image', 0], data),
        productId: prop('productId', data)
      }
    }),
    [data, defaultColor]
  )

  const [variations, setVariations] = useState<VariationState>(defaultVariation)
  const [selectedColor, setSelectedColor] = useState(defaultColor)
  const selectedVariation = variations[selectedColor]
  const skuID = safeProp('productId', selectedVariation)

  const product = useSelector(selectProduct(selectedVariation.productId || ''))

  // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type 'Con... Remove this comment to see the full error message
  const addToCartModalContent =
    data?.popupButton?.popupContent?.description || {}

  // Some accessory cards add to the BMS mini cart instead of directly to cart
  const shouldAddToMiniCart = Maybe.fromFalsy(prop('addToMiniCart', data))
  const miniCartLineItems = useSelector(selectMiniCartLineItems)
  // The quantity of the product in the minicart, or 0 if the sku isn't in the minicart
  const miniCartQuantity = product
    .toMaybe()
    .chain(_product =>
      safeFind(lineItem => lineItem.sku === _product.sku, miniCartLineItems)
    )
    .chain(safeProp('quantity'))
    .orJust(0)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  useMemo(() => {
    const newVariations = safeProp('variations', data)
      .orJust([])
      .reduce(
        (
          obj: VariationState,
          variation: ContentfulAccessoriesCardVariation | null | undefined
        ) =>
          variation && variation.color
            ? {
                ...obj,
                [variation.color]: {
                  image: nullToUndefined(prop('image', variation)),
                  productId: prop('productId', variation)
                }
              }
            : obj,
        defaultVariation
      )
    setVariations(newVariations)
  }, [data, defaultVariation])

  const addToCartButtonClick = useCallback(
    (quantity: number) => {
      setShowSpinner(true)
      setSelectedQuantity(quantity)
      setAddToCartError(null)

      const handleSuccess = () => {
        setShowSpinner(false)
        addToCartModalContent.raw && setAddToCartModalVisibility(true)
        setCartUpdated(true)
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartEvent(product, trackEvent, quantity)
      }
      const handleFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const message = skuID.cata(
        () => 'received null/empty sku',
        sku => `no product with sku ${sku} found in redux`
      )

      product.cata(
        () => {
          setAddToCartError('unrecoverable')
          setShowSpinner(false)
          logError(Error(`Cannot add to cart: ${message}`))
        },
        _product => {
          dispatch(
            IOAddToCart(
              {
                products: [
                  {
                    quantity,
                    sku: _product.masterSku
                  }
                ]
              },
              handleFailure,
              handleSuccess
            )
          )
        }
      )
    },
    [
      dispatch,
      product,
      skuID,
      trackEvent,
      addToCartModalContent,
      optimizelyTrackSiteEvents
    ]
  )

  const onMiniCartQuantityChange = useCallback(
    (quantity: number) => {
      product.cata(
        () => {
          setAddToCartError('unrecoverable')
        },
        _product => {
          setAddToCartError(null)
          setCartUpdated(true)

          dispatch(
            setMiniCartLineItem({
              ..._product,
              maxQuantity,
              quantity
            })
          )
        }
      )
    },
    [dispatch, maxQuantity, product]
  )

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdated(false), 3000)
    return () => clearTimeout(timer)
  }, [isCartUpdated])

  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => {
        const buttonText: string = node?.data?.target?.buttonText
        const buttonUrl: string = node?.data?.target?.url
        return !buttonUrl ? (
          <button
            className="w-full border-0 px-4 my-4 py-2 cursor-pointer md:w-1/2 text-lg underline hover:no-underline bg-transparent"
            onClick={() => setAddToCartModalVisibility(false)}
          >
            {buttonText}
          </button>
        ) : (
          <a
            className="w-full border-0 px-4 my-4 py-2 block float-right text-center min-h-[3rem] cursor-pointer no-underline md:w-1/2 text-lg bg-btn-primary rounded-base text-white hover:bg-btn-light"
            href={buttonUrl}
          >
            {buttonText}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const entryType = node?.data?.target?.internal?.type
        return entryType === 'ContentfulIconWithText' ? (
          <div className="md:py-4 justify-center flex flex-row items-start mb-8 prose-headings:mt-0">
            <GatsbyImage
              className="mr-4 py-2 max-w-[40px] max-h-[40px]"
              image={node.data?.target?.icon}
            />
            <ContentfulRichText raw={node.data?.target?.text?.raw} />
          </div>
        ) : (
          <div className="items-center flex flex-row">
            <div className="pr-2 basis-1/6">{getGatsbyImage(data)}</div>
            <div className="pr-2 basis-3/6">
              {data?.productName?.linkText ?? ''} ({selectedQuantity})
            </div>
            {!!price && <div className="basis-2/6 text-right">{price}</div>}
          </div>
        )
      }
    }
  }

  const cartItemDialogProps = {
    content: (
      <ContentfulRichText
        optionsCustom={options}
        raw={addToCartModalContent.raw}
        references={addToCartModalContent.references}
      />
    ),
    doContinueShopping: () => setAddToCartModalVisibility(false),
    productImage: getGatsbyImage(data),
    quantity: selectedQuantity,
    // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
    title: pathOr<string, string>('', ['productName', 'linkText'], data)
  }

  const { getFormattedPrice } = usePriceContext()
  const price = getFormattedPrice(skuID.orJust(''))(pricePerUnit)

  const initialButtonProps = {
    children: path(['popupButton', 'buttonText'], data),
    // TODO: replace this pipe with ts-functional-pipe
    // @ts-expect-error TS(2345) FIXME: Argument of type '<U>(b: U) => string | U' is not ... Remove this comment to see the full error message
    color: pipe(
      path(['popupButton', 'buttonType']),
      defaultTo(''),
      toFirstCharLower,
      split(' '),
      join(''),
      verifyButtonColor
    )(data),
    showSpinner
  }

  const outOfStockButtonText = prop('outOfStockButtonText', data)
  const [isSellable, setIsSellable] = useState(true)
  const [isOnStock, setIsOnStock] = useState(true)

  useEffect(() => {
    product.cata(
      () => {
        setIsSellable(true)
        setIsOnStock(true)
      },
      p => {
        prop('isSellable', p) ? setIsSellable(true) : setIsSellable(false)
        prop('isOnStock', p) ? setIsOnStock(true) : setIsOnStock(false)
      }
    )
  }, [product])

  const outOfStockButtonProps = {
    ...initialButtonProps,
    children: outOfStockButtonText
      ? outOfStockButtonText
      : prop('children', initialButtonProps),
    disabled: true
  }

  // TODO: Gatsby-4-Upgrade - Test new OfferTag and placeholder.
  const imageTag = safeProp('productTag', data)
    .map(tagData => {
      // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulPlaceholder[]' is not ass... Remove this comment to see the full error message
      const offerTagRichText = (
        <ContentfulRichText
          raw={tagData?.taggingText?.raw}
          references={tagData?.taggingText?.references}
        />
      )
      return (
        <OfferTag
          className={''}
          content={offerTagRichText}
          key={tagData?.id}
          tagBackgroundColor={tagData?.tagBackgroundColor}
          theme={'autoWidth'}
        />
      )
    })
    .orUndefined()

  return (
    <Track>
      <CardItem
        buttonProps={
          !isSellable || !isOnStock ? outOfStockButtonProps : initialButtonProps
        }
        cartUpdatedText={cartUpdatedText
          .map(text => (
            <CartUpdatedMessage
              isVisible={isCartUpdated}
              key={text}
              message={text}
            />
          ))
          .orUndefined()}
        defaultQuantity={shouldAddToMiniCart.cata(
          always(defaultQuantity),
          always(miniCartQuantity)
        )}
        description={pathOr(
          '',
          ['productDescription', 'productDescription'],
          data
        )}
        errorMessage={
          addToCartError && (
            <AddToCartError errorType={addToCartError} textAlign="center" />
          )
        }
        imageTag={imageTag}
        img={
          selectedVariation && selectedVariation.image
            ? toFormImg(selectedVariation.image)
            : null
        }
        isCartable={isOnStock && isSellable ? true : false}
        key={prop('id', data)}
        maxQuantity={maxQuantity}
        minQuantity={shouldAddToMiniCart.cata(always(1), always(0))}
        onButtonClick={addToCartButtonClick}
        onQuantityChange={shouldAddToMiniCart
          .map(always(onMiniCartQuantityChange))
          .orUndefined()}
        onVariationClick={(color: string) => setSelectedColor(color)}
        outOfStockMessage={
          isSellable && renderOutOfStockMessage({ product: product })
        }
        price={price}
        productLimitMessage={productLimitMessage}
        selectedVariation={selectedColor}
        showButton={isLeftValue(shouldAddToMiniCart)}
        title={getTitle(data)}
        variations={Object.keys(variations)}
      />
      <Modal
        appElementId="___gatsby"
        data-component="Modal-Cart-Item-Dialog"
        isOpen={isAddToCardModalVisible}
        key={`CartItemModal${prop('id', data)}`}
        onRequestClose={() => {
          setAddToCartModalVisibility(false)
          setShowSpinner(false)
        }}
      >
        <CartItemDialog {...cartItemDialogProps} price={price} />
      </Modal>
    </Track>
  )
}
