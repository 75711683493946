import { ActivePromoBanner } from '@lib/components'
import { devParams } from '@lib/tracking'
import path from '@simplisafe/ewok/ramda/path'
import { safePath, safeProp } from '@simplisafe/monda'
import {
  PageBody,
  PageSection,
  PageWrapper,
  PartnerHero,
  SmallTextSection
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql, PageProps } from 'gatsby'
import { prop, propOr } from 'ramda'
import map from 'ramda/src/map'
import React, { useContext, useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { PageContext } from '../../config/wrap-with-context'
import {
  ContentfulBanner,
  ContentfulDeviceVariations,
  ContentfulFloatingBar,
  ContentfulFooter,
  ContentfulGroupSection,
  ContentfulImageWithArtDirection,
  ContentfulMediaPage,
  ContentfulMediaPageTemplate,
  ContentfulSiteWideMessages,
  ContentfulSmallTextSection,
  ContentfulTwoColumn,
  ContentfulVariationContainer
} from '../../graphql'
import { ContentfulComponent, getMappedComponent } from '../componentMappings'
import ContentfulRichText from '../components/ContentfulRichText'
import ContentfulVariationContainerComponent from '../components/ContentfulVariationContainerComponent'
import CountryRedirectModal from '../components/CountryRedirectModal'
import FooterComponent from '../components/FooterComponent'
import Header from '../components/Header'
import { ContentfulHeaderFragment } from '../components/Header/query'
import ImageWithArtDirection from '../components/ImageWithArtDirection'
import PopupWizard from '../components/PopupWizard'
import { HidePopupWizard } from '../contexts/hidePopupWizardContext'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'
import ErrorBoundary from '../errorComponents/ErrorBoundary'
import { PageTitleContext } from '../tracking/pageTitleContext'
import { trackEventIsMobile } from '../util/analytics'
import { SmallTextSectionRedirect } from './DefaultPage'

// gatsby-4-upgrade manually defined media page query type
// caution: fragments are no longer generated types, so they are substituted for Partial<ContentfulXYZ> Schema types.
export type MediaPageQuery = {
  readonly contentfulSiteWideMessages?: Partial<ContentfulSiteWideMessages> | null
  readonly contentfulMediaPage?:
    | (Pick<
        ContentfulMediaPage,
        'mediaSource' | 'pageTitle' | 'site' | 'url'
      > & {
        readonly heroText?: { readonly raw?: string } | null
        readonly heroImage?: Partial<ContentfulImageWithArtDirection> | null
        readonly mediaPageTemplate?:
          | (Pick<ContentfulMediaPageTemplate, 'defaultPageTitle'> & {
              readonly header?: ContentfulHeaderFragment | null
              readonly defaultHero?: Partial<ContentfulSmallTextSection> | null
              readonly defaultHeroImage?: Partial<ContentfulImageWithArtDirection> | null
              readonly mainContent?: ReadonlyArray<
                | ContentfulBanner
                | ContentfulDeviceVariations
                | ContentfulGroupSection
                | ContentfulTwoColumn
                | null
              > | null
              readonly footer?: Partial<ContentfulFooter> | null
              readonly popupWizard?: Partial<ContentfulFloatingBar> | null
              readonly countryRedirectModal?: Partial<ContentfulSmallTextSection> | null
            })
          | null
      })
    | null
}

export type MediaPageContext = PageContext & {
  readonly id: string
  readonly slug?: string
}

export type MediaPageProps = PageProps<MediaPageQuery, MediaPageContext>

function MediaPage({ data, pageContext, location }: MediaPageProps) {
  const [hidePopupWizard, setHidePopupWizard] = useState(true)
  const setPageTitle = useContext(PageTitleContext)
  const { trackEvent } = useTracking()
  const isTabletUp = useMediaQuery('TabletAndUp')
  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)

  const handleHidePopup = (hide: boolean) => setHidePopupWizard(hide)

  const contentfulMediaPage = prop('contentfulMediaPage', data)

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const pageTitle = propOr<string, string>('', 'pageTitle', contentfulMediaPage)

  const customHeroText = path(['heroText', 'raw'], contentfulMediaPage)
  const defaultHeroText = path(
    ['mediaPageTemplate', 'defaultHero', 'description', 'raw'],
    contentfulMediaPage
  )

  const defaultHeroImage = path(
    ['mediaPageTemplate', 'defaultHeroImage'],
    contentfulMediaPage
  )
  const heroImage = propOr(defaultHeroImage, 'heroImage', contentfulMediaPage)

  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])

  useEffect(() => {
    window && window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [isTabletUp, trackEvent])

  return (
    <ErrorBoundary>
      <HidePopupWizard.Provider
        value={{
          handleHidePopup,
          hidePopupWizard
        }}
      >
        <SiteWideMessagesContext.Provider
          // @ts-expect-error TS(2322) FIXME: Type 'Partial<ContentfulSiteWideMessages>' is not ... Remove this comment to see the full error message
          value={safeProp('contentfulSiteWideMessages', data).orJust({})}
        >
          <PageWrapper>
            <ActivePromoBanner type="standard" />
            {safePath(
              ['contentfulMediaPage', 'mediaPageTemplate', 'header'],
              data
            )
              .map(
                (
                  header:
                    | ContentfulHeaderFragment
                    | ContentfulVariationContainer
                ) => (
                  // key not needed for Maybe.map

                  <ErrorBoundary>
                    {
                      /* eslint-disable @typescript-eslint/consistent-type-assertions*/
                      header?.internal?.type ===
                      'ContentfulVariationContainer' ? (
                        <ContentfulVariationContainerComponent
                          data={header as ContentfulVariationContainer}
                          renderVariant={variant => (
                            <Header
                              data={variant as ContentfulHeaderFragment}
                            />
                          )}
                        />
                      ) : (
                        <Header data={header as ContentfulHeaderFragment} />
                      )
                    }
                  </ErrorBoundary>
                )
              )
              .orUndefined()}
            <ErrorBoundary>
              <PageBody>
                <PartnerHero
                  appElementId="___gatsby"
                  body={
                    <SmallTextSection
                      content={
                        <div>
                          <ContentfulRichText
                            raw={customHeroText || defaultHeroText}
                          />
                        </div>
                      }
                    />
                  }
                  img={
                    <ImageWithArtDirection
                      data={heroImage}
                      style={{
                        height: '100%',
                        width: '100%'
                      }}
                    />
                  }
                />
                {safePath(
                  ['contentfulMediaPage', 'mediaPageTemplate', 'mainContent'],
                  data
                )
                  .map(
                    map((entry: ContentfulComponent) => {
                      const Component = getMappedComponent(entry)
                      return Component ? (
                        <ErrorBoundary key={entry.id}>
                          <PageSection>
                            <Component
                              data={entry}
                              location={location}
                              pageContext={pageContext}
                            />
                          </PageSection>
                        </ErrorBoundary>
                      ) : null
                    })
                  )
                  .orUndefined()}
              </PageBody>
            </ErrorBoundary>
            {safePath(
              ['contentfulMediaPage', 'mediaPageTemplate', 'footer'],
              data
            )
              .map((footer: ContentfulFooter) => (
                // key not needed for Maybe.map

                <ErrorBoundary>
                  <FooterComponent data={footer} />
                </ErrorBoundary>
              ))
              .orUndefined()}
            {safePath(
              [
                'contentfulMediaPage',
                'mediaPageTemplate',
                'countryRedirectModal'
              ],
              data
            )
              .map(
                // @ts-expect-error TS(2345) FIXME: Argument of type '(countryRedirectModal: SmallText... Remove this comment to see the full error message
                (countryRedirectModal: SmallTextSectionRedirect) =>
                  !hidePopups && (
                    <ErrorBoundary>
                      <div data-component="CountryRedirectModal">
                        <CountryRedirectModal data={countryRedirectModal} />
                      </div>
                    </ErrorBoundary>
                  )
              )
              .orUndefined()}
            {safePath(
              ['contentfulMediaPage', 'mediaPageTemplate', 'popupWizard'],
              data
            )
              .map((popupWizard: Partial<ContentfulFloatingBar>) => (
                // key not needed for Maybe.map

                <ErrorBoundary>
                  <div data-component="PopupWizard">
                    <PopupWizard data={popupWizard} />
                  </div>
                </ErrorBoundary>
              ))
              .orUndefined()}
          </PageWrapper>
        </SiteWideMessagesContext.Provider>
      </HidePopupWizard.Provider>
    </ErrorBoundary>
  )
}

export default MediaPage

export const query = graphql`
  #graphql
  query MediaPage($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulMediaPage(id: { eq: $id }) {
      pageTitle
      site
      url
      heroText {
        raw
      }
      heroImage {
        ... on ContentfulImageWithArtDirection {
          ...imageWithArtDirection
        }
      }
      mediaSource
      mediaPageTemplate {
        defaultPageTitle
        header {
          ... on ContentfulHeader {
            ...contentfulHeaderFragment
          }
          ... on ContentfulVariationContainer {
            ...variationContainer
          }
        }
        defaultHero {
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
        }
        defaultHeroImage {
          ... on ContentfulImageWithArtDirection {
            ...imageWithArtDirection
          }
        }
        mainContent {
          ... on ContentfulGroupSection {
            ...contentfulGroupSectionFragment
          }
          ... on ContentfulTwoColumn {
            ...contentfulTwoColumnFragment
          }
          ... on ContentfulBanner {
            ...contentfulBanner
          }
          ... on ContentfulDeviceVariations {
            ...deviceVariationsFragment
          }
        }
        footer {
          ... on ContentfulFooter {
            ...contentfulFooter
          }
        }
        popupWizard {
          ... on ContentfulFloatingBar {
            ...popupWizardFragment
          }
        }
        countryRedirectModal {
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
        }
      }
    }
  }
`
