import { TrackingData } from '@lib/tracking'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Maybe } from 'monet'
import { useEffect, useRef } from 'react'

import { trackGuidedSystemBuilderRecommendationEvent } from '../util/analytics/guidedSystemBuilderRecommendation'

/**
 * Tracks recommended system in GTM
 * @param basePackage
 * @param product
 * @param miniCartItems
 * @param trackEvent
 * @param state
 */
export const useGuidedSystemBuilderTrackRecommendation = (
  basePackage: Maybe<Package>,
  product: Maybe<Product>,
  miniCartItems: readonly MiniCartLineItem[],
  trackEvent: (data: Partial<TrackingData>) => void,
  state: ImmutableState,
  packagePrice: Maybe<number>
) => {
  const didTrackRecommendation = useRef(false)
  useEffect(() => {
    if (
      !didTrackRecommendation.current &&
      basePackage.isSome() &&
      product.isSome() &&
      miniCartItems &&
      miniCartItems.length > 0 &&
      trackEvent &&
      packagePrice.isSome()
    ) {
      trackGuidedSystemBuilderRecommendationEvent(
        basePackage,
        product,
        miniCartItems,
        trackEvent,
        state,
        false,
        packagePrice
      )
      didTrackRecommendation.current = true
      // eslint-disable-next-line no-undefined
      return undefined
    } else {
      // eslint-disable-next-line no-undefined
      return undefined
    }
  }, [
    basePackage,
    product,
    miniCartItems,
    state,
    trackEvent,
    packagePrice,
    didTrackRecommendation
  ])
}
