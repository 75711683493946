import { ContentfulRichText } from '@lib/components'
import React, { HTMLAttributes } from 'react'

import { getImageCardPadding } from './helpers'
import ReviewCardImage from './ReviewCardImage'
import { ReviewCardProps } from './types'

export const getTextPositionClasses = (textPosition?: string): string => {
  return textPosition === 'Right' ? 'absolute' : ''
}

function ReviewCard({
  text,
  icon,
  iconSize,
  iconPosition,
  textPosition,
  className
}: HTMLAttributes<HTMLDivElement> & ReviewCardProps) {
  return (
    <div
      className={`${className} ${getImageCardPadding(
        iconPosition
      )} neutralLightTanBackgroundColor flex flex-col justify-center text-center items-center rounded-lg`}
    >
      <ReviewCardImage
        icon={icon}
        iconPosition={iconPosition}
        iconSize={iconSize}
      />
      {/* TODO: Gatsby 4 rich text - should be checked for any references or embedded entries that need to be handled since this is no longer using ss-react-component's RichText */}
      <div className={getTextPositionClasses(textPosition)}>
        <ContentfulRichText
          raw={text?.raw}
          references={text?.references || []}
        />
      </div>
    </div>
  )
}

export default ReviewCard
