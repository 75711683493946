import z from 'zod'

// Reference: https://github.com/gatsbyjs/gatsby/blob/35703b6e4db2d008a03f538097e05a70aae3c6b8/packages/gatsby-plugin-image/src/components/picture.tsx#L9-L19
const sources = z.union([
  z.object({
    media: z.string(),
    srcSet: z.string(),
    type: z.string().optional()
  }),
  z.object({
    media: z.string().optional(),
    srcSet: z.string(),
    type: z.string()
  })
])

// Reference: https://github.com/gatsbyjs/gatsby/blob/a259ff8e9c08b169cc767ea4f450ce3e509877bc/packages/gatsby-plugin-image/src/components/hooks.ts#L24-L26
export const gatsbyImageSchema = z.object({
  description: z.string().nullish(),
  gatsbyImageData: z.object({
    height: z.number(),
    images: z.object({
      fallback: z.object({ src: z.string() }).optional(),
      sources: z.array(sources).optional()
    }),
    layout: z.union([
      z.literal('fixed'),
      z.literal('fullWidth'),
      z.literal('constrained')
    ]),
    width: z.number()
  }),
  title: z.string()
})

export type GatsbyImageSchema = z.TypeOf<typeof gatsbyImageSchema>

export default gatsbyImageSchema
