import { SSButton } from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly handleSubmit: () => void
  readonly isLoading: boolean
}

export default function ShippingAddressModalValidationSubmission({
  handleSubmit,
  isLoading = false
}: Props) {
  return (
    <SSButton
      disabled={isLoading}
      className="py-3 md:py-1 px-3 md:px-8 mt-8"
      onClick={handleSubmit}
    >
      <span className="flex justify-center text-lg md:text-sm font-medium">
        Confirm and go to Payment
      </span>
      <div className="hidden lg:flex">
        <span className="pl-1 text-lg md:text-sm font-medium">Information</span>
      </div>
    </SSButton>
  )
}
