import nullToUndefined from '@simplisafe/ewok/nullToUndefined'
import { Divider as DividerAtom } from '@simplisafe/ss-react-components'
import { prop } from 'lodash/fp'
import React from 'react'

import { DividerFragment } from './query.types'

type Props = DividerFragment

/**
 * A wrapper for the Divider Atom to pass in data from Contentful.
 */
function Divider({ borderWidth, id, width, color }: Props) {
  return (
    <DividerAtom
      borderWidth={nullToUndefined(borderWidth)}
      color={prop('color', color)}
      key={id}
      width={nullToUndefined(width)}
    />
  )
}

export default React.memo(Divider)
