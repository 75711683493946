import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import { getImageStyle, getImageWrapperStyle } from './helpers'
import { ReviewCardImageProps } from './types'

function ReviewCardImage({
  icon,
  iconSize,
  iconPosition
}: ReviewCardImageProps) {
  return (
    <div className={getImageWrapperStyle(iconPosition, iconSize)}>
      {/* @ts-expect-error TS(2741) FIXME: Property 'alt' is missing in type '{ image: IGatsb... Remove this comment to see the full error message */}
      <GatsbyImage
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
        image={getImage(icon)}
        style={getImageStyle(iconPosition)}
      />
    </div>
  )
}

export default ReviewCardImage
