import React, { ReactNode } from 'react'

export type CartBelowContentsProps = {
  readonly children: ReactNode
}

/** Used to render content that should always be rendered right below the cart contents section */
function BelowCartContents({ children }: CartBelowContentsProps) {
  return (
    <div className="my-8 lg:mt-16 lg:col-span-full [&>h3]:pl-4 [&>p]:pl-4">
      {children}
    </div>
  )
}

export default BelowCartContents
