import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@lib/components'
import transformObject from '@simplisafe/ewok/transformObject'
import {
  Carousel,
  Divider,
  OverlayBanner
} from '@simplisafe/ss-react-components'
import { CarouselProps } from '@simplisafe/ss-react-components/Carousel'
import { PreloadLink } from '@simplisafe/ss-react-components/HeroSlide'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { OverlayBannerProps } from '@simplisafe/ss-react-components/OverlayBanner'
import { isEmpty } from 'fp-ts/Record'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { None } from 'monet'
import React from 'react'

import { ContentfulAdditionalInfoBanner } from '../../../graphql'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type OverlayBannerComponentProps = {
  readonly id: string
  readonly data: ContentfulAdditionalInfoBanner
}

type BGImageBreakpoint =
  | 'mediaOverlay'
  | 'mediaOverlayMobile'
  | 'mediaOverlayTablet'

const getBackgroundImageProps = (
  banner: ContentfulAdditionalInfoBanner,
  media: BGImageBreakpoint
) => {
  const image = banner?.[media]?.[0]

  return (
    {
      alt: image?.description,
      image: getImage(image)
    } ?? {}
  )
}

const overlayBannerRichTextOptions = {
  renderNode: {
    [BLOCKS.HR]: () => <Divider width={132} />
  }
}

const toOverlayBanner = transformObject<
  ContentfulAdditionalInfoBanner,
  OverlayBannerProps
>({
  BackgroundComponent: () => BgImage,
  additional: d => (
    <ContentfulRichText
      optionsCustom={overlayBannerRichTextOptions}
      raw={d.additionalContent?.[0]?.description?.raw}
    />
  ),
  backgroundComponentProps: d => getBackgroundImageProps(d, 'mediaOverlay'),
  content: d => (
    <ContentfulRichText
      optionsCustom={overlayBannerRichTextOptions}
      raw={d?.description?.raw}
    />
  ),
  title: d => d?.title ?? ''
})

const toCarouselData = (
  data: ContentfulAdditionalInfoBanner,
  isMobile: boolean,
  isDesktop: boolean
): CarouselProps => {
  const bgImage: BGImageBreakpoint = isMobile
    ? 'mediaOverlayMobile'
    : isDesktop
    ? 'mediaOverlay'
    : 'mediaOverlayTablet'

  const toCarousel = transformObject<
    ContentfulAdditionalInfoBanner,
    CarouselProps
  >({
    carouselContainerData: d => ({
      autoPlaySpeed: (d.autoPlaySpeed ?? 4) * 1000,
      paginationPosition: 'vertical',
      playButton: false
    }),
    slideData: d =>
      d?.additionalContent?.map(ac => ({
        BackgroundComponent: BgImage,
        additionalContent: {
          content: (
            <ContentfulRichText
              raw={ac?.description?.raw}
              references={
                ac?.description?.references?.filter(
                  r => r !== null && !isEmpty(r)
                ) ?? []
              }
            />
          )
        },
        backgroundComponentProps: getBackgroundImageProps(data, bgImage),
        content: {
          content: <ContentfulRichText raw={d?.description?.raw} />,
          title: d?.title ?? ''
        },
        paginationPosition: 'vertical',
        preload: None<PreloadLink>(),
        textColor: 'dark'
      })) ?? []
  })

  return toCarousel(data)
}
function OverlayBannerComponent({ data }: OverlayBannerComponentProps) {
  const bannerType = data?.bannerType ?? ''
  const isMobile = !useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('DesktopAndUp')

  return (
    <div className="prose md:prose-md">
      {bannerType === 'Overlay' ? (
        <OverlayBanner {...toOverlayBanner(data)} />
      ) : (
        // TODO why is an overlay banner sometimes a carousel instead of those components just being carousels in contentful?
        <Carousel {...toCarouselData(data, isMobile, isDesktop)} />
      )}
    </div>
  )
}

export default OverlayBannerComponent
