import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import CartAffirmPromoMessage from './CartAffirmPromoMessage'

export type CartSubtotalProps = {
  readonly subtotal: string | undefined
  readonly affirmPromoMessage: ReactNode | null
}

function CartSubtotal({ subtotal, affirmPromoMessage }: CartSubtotalProps) {
  const locale = useSelector(selectLocale)

  if (!subtotal) {
    return null
  }

  return (
    <div className="w-full px-10 py-4 bg-neutral-light-50 rounded-[0.625rem] my-4">
      <h4 className="font-medium text-right text-lg m-0 mb-2 leading-none">
        Subtotal: {subtotal}
      </h4>
      {locale === 'en-GB' ? (
        <p className="text-right text-lg m-0 mb-2 leading-none">
          (Including VAT)
        </p>
      ) : (
        affirmPromoMessage && (
          <CartAffirmPromoMessage content={affirmPromoMessage} />
        )
      )}
    </div>
  )
}

export default CartSubtotal
