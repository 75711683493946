import { toGapValue, toPaddingValue } from '@lib/components'
import deriveHtmlId from '@simplisafe/ewok/contentful-utils/deriveHtmlId'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import round from '@simplisafe/ewok/ramda-adjunct/round'
import { safeProp } from '@simplisafe/monda'
import { Column, HiddenAnchor, Row } from '@simplisafe/ss-react-components'
import { CarouselContainer } from '@simplisafe/ss-react-components'
import { ColumnProps, Spans } from '@simplisafe/ss-react-components/Column'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { HeightTypes, RowProps } from '@simplisafe/ss-react-components/Row'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import length from 'ramda/src/length'
import pathOr from 'ramda/src/pathOr'
import React from 'react'
import { DeepPartial } from 'redux'

import {
  ContentfulGroupSection,
  ContentfulProductCardAccessories
} from '../../../graphql'
import {
  ComponentMappedProps,
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import { usePlaceholderForMonitoringDiscount } from '../../hooks/usePlaceholderForMonitoringDiscount'
import { PriceProvider } from '../../providers/PriceProvider'
import { nullToUndefined } from '../../util/helper'
import CardItemSmallBannerComponent from '../CardItemSmallBannerComponent'
import PartnerCaptureForm from '../PartnerCaptureForm'

// This prop is aliased on GroupSection because it conflicts with other components that have a backgroundColor field
// of type String. It doesn't seem like the plugin that generates our GraphQL types takes this into account.
type AliasedContentfulGroupSection = ContentfulGroupSection & {
  readonly groupSectionBackgroundColor: ContentfulGroupSection['backgroundColor']
}

export type GroupSectionProps = {
  readonly data: DeepPartial<AliasedContentfulGroupSection>
}

type displayType = string | null | undefined
type displayCountType = number | null | undefined
type PlainTextProps = {
  readonly data: { readonly text: { readonly text: string } }
}
const groupSectionComponentMapping = {
  ContentfulPartnerCaptureForm: PartnerCaptureForm,
  ContentfulPlainText: ({
    data: {
      text: { text }
    }
  }: PlainTextProps) => (
    <div className="text-center">
      {usePlaceholderForMonitoringDiscount(text, '15%')}
    </div>
  ),
  ContentfulProductCard: CardItemSmallBannerComponent
}

type ContentfulGroupSectionInnerComponent = ContentfulComponent & {
  readonly __typename?: string
}

export const getMappedGroupSectionComponent = (
  contentfulComponent: ContentfulComponent
): React.FC<ComponentMappedProps> | null =>
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  groupSectionComponentMapping[
    pathOr<string, string>('', ['internal', 'type'], contentfulComponent)
  ] ?? null

export const renderComponentFromGroupSectionData = (
  _data?: ContentfulGroupSectionInnerComponent | null,
  groupSectionTitle?: string | null | undefined
) => {
  const data = _data || {}
  const id = prop('id', data)
  const Component = Maybe.fromNull(getMappedGroupSectionComponent(data))
    .orElse(Maybe.fromNull(getMappedComponent(data)))
    .orNull()

  return Component ? (
    <Component
      // @ts-expect-error TS(2322) FIXME: Type '{ className: string; data: ContentfulCompone... Remove this comment to see the full error message
      className={classNames({
        'max-w-[126px]': groupSectionTitle === 'US News logo' // TO-DO: Restricting the width of the component based on the section title is a hack. We need to find a better way to do this.
      })}
      data={data}
      key={id}
    />
  ) : null
}

/**
 * For mapping Contentful string value to number that can be used for dividing
 * grid tracks (12) by a finite number of columns.
 */
export const groupingTypeMapper: { [key: string]: number } = {
  '2 Columns': 6,
  '3 Columns': 4,
  '4 Columns': 3,
  Carousel: 12,
  Row: 12
}

/** Calculate the span of each column based on the display settings for mobile, tablet, and desktop. */
export const calculateColumns = (
  columns: number,
  mobile: displayType,
  tablet: displayType,
  desktop: displayType
) =>
  [mobile, tablet, desktop].map((groupingType: displayType) =>
    Maybe.fromNull(groupingType)
      .chain(groupingType => safeProp(groupingType, groupingTypeMapper))
      .cata(
        // If undefined or an unsupported groupingType, default number of columns to number of items equally.
        () => round(12 / columns),
        value => value
      )
  )

export const deriveContents = (
  _rawContentsToGroup: DeepPartial<ContentfulGroupSection['contentsToGroup']>,
  displayCountTablet: displayCountType,
  displayCountMobile: displayCountType,
  isDesktop: boolean,
  isMobile: boolean
) => {
  const rawContentsToGroup = _rawContentsToGroup || []
  const maxLength = length(rawContentsToGroup)
  const tabletCount = displayCountTablet || maxLength
  const mobileCount = displayCountMobile || maxLength
  return isDesktop
    ? rawContentsToGroup
    : isMobile
    ? rawContentsToGroup.slice(0, mobileCount)
    : rawContentsToGroup.slice(0, tabletCount)
}

const alignItemsValueMapper: { [key: string]: RowProps['alignItems'] } = {
  Center: 'center',
  Stretch: 'stretch'
}
const toAlignItemsValue = (value: string | null | undefined) =>
  prop(value, alignItemsValueMapper)

const justifyContentValueMapper: { [key: string]: ColumnProps['justifySelf'] } =
  {
    Center: 'center',
    Stretch: 'stretch'
  }
const toJustifyContentValue = (value: string | null | undefined) =>
  prop(value, justifyContentValueMapper)

export default function GroupSection({ data }: GroupSectionProps) {
  const {
    alignItems,
    columnPadding,
    contentsToGroup: rawContentsToGroup,
    equalHeightChildren,
    equalHeightRows,
    gapSize,
    groupingTypeMobile,
    groupingTypeTablet,
    groupingTypeDesktop,
    displayCountTablet,
    displayCountMobile,
    height,
    id,
    justifyContent,
    roundedCorners,
    rowPadding,
    rowSidePadding,
    rowVerticalPadding,
    separatorLine,
    title: groupSectionTitle
  } = data

  const htmlId = deriveHtmlId({
    ...data,
    title: nullToUndefined(data.title)
  })

  const backgroundColor = path(['groupSectionBackgroundColor', 'color'], data)

  const isMobile = !useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isMobileCarousel = isMobile && groupingTypeMobile === 'Carousel'

  const contentsToGroup = deriveContents(
    rawContentsToGroup,
    displayCountTablet,
    displayCountMobile,
    isDesktop,
    isMobile
  )

  const numberOfColumns = length(contentsToGroup)

  const spans =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
    calculateColumns(
      numberOfColumns,
      groupingTypeMobile,
      groupingTypeTablet,
      groupingTypeDesktop
    ) as unknown

  const skus = safeProp('contentsToGroup', data)
    .map(contents =>
      contents.filter(
        (content): content is ContentfulProductCardAccessories =>
          !!content && 'productId' in content
      )
    )
    // TODO: fix type
    // @ts-expect-error TS(2345) FIXME: Argument of type '<O extends { productId: V; }, T ... Remove this comment to see the full error message
    .map(contents => contents.map(prop('productId')))
    .orJust([])
    .filter(isNotNil)

  return (
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type 'unknown[]' is not assignable to type 'readon... Remove this comment to see the full error message
    <PriceProvider skus={skus}>
      <div>
        <HiddenAnchor id={htmlId} key={htmlId} />
        <Row
          alignItems={toAlignItemsValue(alignItems)}
          backgroundColor={nullToUndefined(backgroundColor)}
          divider={separatorLine || false}
          equalHeightRows={equalHeightRows ? true : false}
          gap={toGapValue(gapSize)}
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
          height={nullToUndefined(height) as HeightTypes}
          key={`${id}-row`}
          padding={toPaddingValue(rowPadding)}
          rounded={roundedCorners}
          sidePadding={rowSidePadding}
          textColor="none"
          verticalPadding={rowVerticalPadding}
        >
          {isMobileCarousel && contentsToGroup && (
            <Column
              equalHeightChildren={!!equalHeightChildren}
              justifySelf={toJustifyContentValue(justifyContent)}
              padding={toPaddingValue(columnPadding)}
              rounded={roundedCorners}
              spans={[12]}
            >
              <CarouselContainer
                autoplay={false}
                paginationPosition="bottom-right-to-center"
                slides={contentsToGroup
                  // @ts-expect-error TS(2345) FIXME: Argument of type '(_data?: ContentfulComponent | n... Remove this comment to see the full error message
                  .map(renderComponentFromGroupSectionData)
                  .filter(component => !!component)}
              />
            </Column>
          )}
          {!isMobileCarousel &&
            contentsToGroup &&
            contentsToGroup.map((contentData, idx) => {
              return (
                <Column
                  equalHeightChildren={!!equalHeightChildren}
                  justifySelf={toJustifyContentValue(justifyContent)}
                  key={`${id}-column-${idx}`}
                  padding={toPaddingValue(columnPadding)}
                  rounded={roundedCorners}
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
                  spans={spans as Spans}
                >
                  {/* @ts-expect-error TS(2345) FIXME: Argument of type 'DeepPartial<ContentfulAccordionC... Remove this comment to see the full error message */}
                  {renderComponentFromGroupSectionData(
                    contentData,
                    groupSectionTitle
                  )}
                </Column>
              )
            })}
        </Row>
      </div>
    </PriceProvider>
  )
}
export const contentfulGroupSection = graphql`
  #graphql
  fragment contentfulGroupSectionFragment on ContentfulGroupSection {
    ...__groupSectionFields
    contentsToGroup {
      ... on ContentfulGroupSection {
        ...__groupSectionFields
        contentsToGroup {
          ... on ContentfulGroupSection {
            ...__groupSectionFields
            contentsToGroup {
              ... on ContentfulGroupSection {
                ...__groupSectionFields
              }
            }
          }
        }
      }
    }
  }

  # This alternative fragment omits certain content in order to avoid self-referential/cyclical queries in certain
  # situations (e.g. a GroupSection that holds a ProductPlan that holds a Modal that holds another GroupSection).
  fragment nonCyclicalGroupSectionFragment on ContentfulGroupSection {
    ...__groupSectionFieldsBasic
    contentsToGroup {
      ... on ContentfulGroupSection {
        ...__groupSectionFieldsBasic
        contentsToGroup {
          ... on ContentfulGroupSection {
            ...__groupSectionFieldsBasic
          }
        }
      }
    }
  }

  fragment __groupSectionFieldsBasic on ContentfulGroupSection {
    ...groupSectionInternals
    # aliased because backgroundColor conflicts with other components' fields of type String
    groupSectionBackgroundColor: backgroundColor {
      color
    }
    contentsToGroup {
      ... on ContentfulVariationContainer {
        id
        internal {
          type
        }
        experimentId
        experimentKey
        experimentTitle
        meta {
          internal {
            content
          }
        }
        variations {
          ... on ContentfulFindYourPerfectSystem {
            contentful_id
            ...aliasedTermsAndConditionsQuoteWizard
          }
        }
      }
      ... on ContentfulAccordion {
        ...contentfulAccordionItemFragment
      }
      ... on ContentfulSmallTextSection {
        ...nonCyclicalSmallTextSectionFragment
      }
      ... on ContentfulIconWithText {
        ...contentfulIconWithText
      }
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulProductCardAccessories {
        ...productCardAccessoriesFragment
      }
      ... on ContentfulProductCard {
        ...contentfulCardItemSmallBanner
      }
      ... on ContentfulCrimeLocationGrid {
        ...contentfulLocationGrid
      }
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulHeading {
        ...headingFragment
      }
      ... on ContentfulCardShop {
        ...cardShop
      }
      ... on ContentfulTestimonialCard {
        ...testimonialCard
      }
      ... on ContentfulBmsHeroItem {
        ...contentfulBmsHeroItemFragment
      }
      ... on ContentfulMeetTheSystem {
        ...meetTheSystem
      }
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
      ... on ContentfulContactUsForm {
        ...contactUsForm
      }
      ... on ContentfulImageWithFloatingBadge {
        ...imageWithFloatingBadge
      }
      ... on ContentfulGetaQuoteForm {
        ...getAQuoteForm
      }
      ... on ContentfulImageWithFocalPoint {
        ...imageWithFocalPoint
      }
      ... on ContentfulCommunicationsContent {
        ...communicationsContent
      }
      ... on ContentfulBannerText {
        ...bannerText
      }
      ... on ContentfulFeedbackForm {
        ...feedbackForm
      }
      ... on ContentfulDivider {
        ...divider
      }
      ... on ContentfulLiveGuardForm {
        ...liveGuardForm
      }
      ... on ContentfulPlainText {
        id
        internal {
          type
        }
        text {
          text
        }
      }
      ... on ContentfulImageWithCaption {
        id
        internal {
          type
        }
        caption
        position
        media {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ... on ContentfulTable {
        ...tableContainer
      }
      ... on ContentfulAccordionGroup {
        ...accordionGroup
      }
      ... on ContentfulResponsiveContainer {
        ...nonCyclicalResponsiveContainer
      }
      ... on ContentfulPackageCard {
        ...packageCard
      }
    }
  }

  # This contains fragments for grouped content that can otherwise be cyclical
  # (e.g. a GroupSection that holds a ProductPlan that holds a Modal that can hold another GroupSection)
  fragment __groupSectionFields on ContentfulGroupSection {
    ...__groupSectionFieldsBasic
    contentsToGroup {
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulModal {
        ...modalFragment
      }
      ... on ContentfulBanner {
        ...contentfulBanner
      }
      ... on ContentfulVariationContainer {
        ...variationContainer
      }
      ... on ContentfulCardKit {
        ...cardKit
      }
      ... on ContentfulResponsiveContainer {
        ...responsiveContainer
      }
    }
  }

  fragment groupSectionInternals on ContentfulGroupSection {
    id
    alignItems
    columnPadding
    equalHeightChildren
    equalHeightRows
    internal {
      type
    }
    gapSize
    justifyContent
    rowPadding
    groupingTypeMobile
    groupingTypeTablet
    groupingTypeDesktop
    displayCountTablet
    displayCountMobile
    height
    roundedCorners
    rowSidePadding
    rowVerticalPadding
    title
  }

  fragment groupSectionSelfReference on ContentfulGroupSection {
    contentsToGroup {
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
    }
  }
`
