import { GatsbyImageSchema } from '@lib/components'
import { Modal, Text } from '@simplisafe/ss-react-components'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'

type InfoModalProps = {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly title: string
  readonly description: ReactNode
  readonly image?: GatsbyImageSchema
}

function InfoModal({
  isOpen,
  onClose,
  title,
  description,
  image
}: InfoModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      size={'small'}
      style={{
        content: {
          background: 'white',
          color: 'black'
        },
        overlay: { background: 'accentBlueGray' }
      }}
    >
      <div className="p-16">
        <Text textAlignment="center">
          <p
            style={{
              fontSize: '36px',
              fontWeight: 200,
              lineHeight: '44px'
            }}
          >
            {title}
          </p>
        </Text>
        <Text className="mb-8 font-thin">{description}</Text>

        {image && (
          <GatsbyImage
            image={getImage(image)}
            // @ts-expect-error TS(2322) FIXME: Type '{ image: IGatsbyImageData; style: { backgrou... Remove this comment to see the full error message
            style={{
              backgroundSize: 'contain',
              height: '500px',
              width: '100%'
            }}
          />
        )}
      </div>
    </Modal>
  )
}

export default InfoModal
