import { ActivePromoBanner, HolidayPromoBanner } from '@lib/components'
import { toPromoBannerStyleValue } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import { safePath } from '@simplisafe/monda'
import React from 'react'

import { ContentfulBannerTopPage } from '../../../graphql'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import PartnerBanner from '../PartnerBanner'
import { usePromoBannerData } from './hooks'
import { PageProps } from './types'

function PromoBanner({ pageData }: { readonly pageData: PageProps['data'] }) {
  const {
    isActivePromoLoading,
    isHolidayBanner,
    isPartnerBannerLoading,
    partnerBanner,
    monthsOfServiceDisplay,
    discountText,
    lineItemText
  } = usePromoBannerData()
  const partnerName = getValueFromPartnerCookie('partnerName')
  const promoBannerStyle =
    toPromoBannerStyleValue(
      path(['contentfulPage', 'pageLayout', 'promoBannerStyle'], pageData)
    ) || 'none'

  const pageTopBanner: ContentfulBannerTopPage = safePath(
    ['contentfulPage', 'pageTopBanner'],
    pageData
  ).orUndefined()
  const pagePath = path(['contentfulPage', 'pageUrl'], pageData) || ''

  return (
    <>
      {!pageTopBanner &&
        !(isPartnerBannerLoading || isActivePromoLoading) &&
        partnerBanner
          .map(
            data =>
              promoBannerStyle !== 'cart' &&
              promoBannerStyle !== 'none' && (
                <PartnerBanner
                  backgroundColor={data.backgroundColor.orUndefined()}
                  discountSecondaryText={data.discountSecondaryText.orUndefined()}
                  discountText={discountText.orSome('15% off')}
                  displayEmailInputField={data.displayEmailInputField.orUndefined()}
                  lineItemText={lineItemText.orSome('free SimpliCam')}
                  linkPath={data.linkPath.orUndefined()}
                  logoDescription={data.logoDescription.orUndefined()}
                  logoUrl={data.logoUrl.orUndefined()}
                  monitoringText={`${monthsOfServiceDisplay} of monitoring`}
                  partnerName={partnerName || undefined}
                  primaryOfferText={data.primaryOfferText.orUndefined()}
                  primaryTextColor={data.primaryTextColor.orUndefined()}
                  secondaryOfferText={data.secondaryOfferText.orUndefined()}
                  secondaryOfferTextMobile={data.secondaryOfferTextMobile.orUndefined()}
                  secondaryTextColor={data.secondaryTextColor.orUndefined()}
                  showCta={promoBannerStyle !== 'pdp-plp'}
                ></PartnerBanner>
              )
          )
          .orSome(
            isHolidayBanner ? (
              <HolidayPromoBanner type={promoBannerStyle} />
            ) : (
              <ActivePromoBanner pagePath={pagePath} type={promoBannerStyle} />
            )
          )}
    </>
  )
}

export default PromoBanner
