import { ContentfulRichText } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { ComparisonTableRows } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import defaultTo from 'ramda/src/defaultTo'
import React from 'react'

import { ContentfulPlanComparisonTable } from '../../../graphql'
import ComparisonTableRowComponent from '../ComparisonTableRowComponent'

type PlanComparisonTableRowsProps = {
  readonly data: Partial<ContentfulPlanComparisonTable>
}

export default function PlanComparisonTableRows({
  data
}: PlanComparisonTableRowsProps) {
  const componentId = defaultTo('')(path(['sectionId', 'referenceId'], data))

  const description = data?.description?.raw

  const footers = prop('tableFooters', data) || []

  const headers = prop('tableHeaders', data) || []

  const rows = prop('tableRows', data) || []

  return (
    <ComparisonTableRows
      componentId={componentId}
      description={description && <ContentfulRichText raw={description} />}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      footers={footers as readonly string[]}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      headers={headers as readonly string[]}
      rowsList={rows.map((row, i) => {
        return (
          <ComparisonTableRowComponent
            // @ts-expect-error TS(2304) FIXME: Cannot find name 'ContentfulPlanComparisonTableRow... Remove this comment to see the full error message
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
            data={row as ContentfulPlanComparisonTableRow}
            key={`row-${i}`}
          />
        )
      })}
    />
  )
}

export const PlanComparisonTableRowsQuery = graphql`
  #graphql
  fragment planComparisonTableRowsFragment on ContentfulPlanComparisonTable {
    description {
      raw
    }
    id
    internal {
      type
    }
    sectionId {
      referenceId
    }
    tableFooters
    tableHeaders
    tableRows {
      ... on ContentfulPlanComparisonTableRow {
        ...comparisonTableRowFragment
      }
    }
  }
`
