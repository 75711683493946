import prop from '@simplisafe/ewok/ramda/prop'
import { Column, Row } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulBannerText } from '../../../graphql'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'

export type BannerTextComponentProps = {
  readonly data: Partial<ContentfulBannerText>
}

export default function BannerTextComponent({
  data
}: BannerTextComponentProps) {
  const text = prop('text', data)
  return text ? (
    <Row>
      <Column justifySelf="center">
        <RichTextWithOptionsComponent data={text} />
      </Column>
    </Row>
  ) : null
}

export const query = graphql`
  #graphql
  fragment bannerText on ContentfulBannerText {
    id
    internal {
      type
    }
    text {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
  }
`
