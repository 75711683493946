import { GatsbyImageSchema } from '@lib/components'
import { GatsbyImage } from '@lib/components'
import { Column } from '@simplisafe/ss-react-components'
import React from 'react'

type ImageColumnProps = {
  readonly image: GatsbyImageSchema & { readonly title: string }
  readonly positionMobile?: string
  readonly imageObjectFit?: string
}

export default function ImageColumn({
  image,
  imageObjectFit = 'contain',
  positionMobile
}: ImageColumnProps) {
  return (
    <Column
      firstRow={positionMobile === 'Bottom' ? false : true}
      spans={[12, 6]}
    >
      <GatsbyImage
        image={image}
        // @ts-expect-error TS(2322) FIXME: Type '{ image: { title?: string; description?: str... Remove this comment to see the full error message
        objectFit={imageObjectFit}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    </Column>
  )
}
