import { brazeLogCustomEvent, COOKIE_LEAD_DATA, cookies } from '@lib/tracking'
import {
  brazeSetCustomUserAttributes,
  fbTrackLeadCreated,
  handleBrazeTrackingEvent
} from '@lib/tracking'
import {
  leadGenCapture,
  LeadGenCaptureParams,
  LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Text } from '@simplisafe/ss-react-components'
import { Form, Formik, FormikHelpers } from 'formik'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import React from 'react'
import * as Yup from 'yup'

import Email from './form-sections/Email'
import Submission from './form-sections/Submission'

type FormValues = {
  readonly email: string
}

function LiveGuardForm() {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().trim().required('email is a required field')
  })

  const onSubmit = async (
    formData: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(false)

    const leadGenParams: LeadGenCaptureParams = {
      email: formData.email.trim(),
      leadSource: 'scout_beta',
      source: 'liveguardprotection',
      sourceType: 'scout_beta'
    }

    const handleSuccess = async (value: Maybe<LeadGenCaptureResponse>) => {
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())
      brazeSetCustomUserAttributes({ 'Scout opt-in': true })
      brazeLogCustomEvent('lead_created', { lead_source: 'scout_beta' })
      await fbTrackLeadCreated(formData.email)
      setStatus({ message: 'success' })
    }

    const handleFailure = (error: Error) => logError(error)

    leadGenCapture(leadGenParams)(handleFailure)(handleSuccess)
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form>
          {!status && (
            <>
              <div className="rc-grid rc-w-full rc-grid-cols-12">
                <Email
                  emailFieldLabel={'Email Address'}
                  emailFieldPlaceholder={'Enter email'}
                />
                <Submission
                  submitButtonDisabled={isSubmitting}
                  submitButtonLabel={'Submit'}
                />
              </div>
              <Text className={'m1_t'} textSize="xs" useTailwind={true}>
                <span>
                  You may receive email offers from us in accordance with our{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </span>
              </Text>
            </>
          )}
          {status && status.message && (
            <Text>
              <span>
                <strong>Thank you for submitting your email address.</strong>
              </span>
            </Text>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default LiveGuardForm
