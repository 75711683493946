import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import React from 'react'

import { ContentfulContentCollection } from '../../../graphql'
import { PageProps } from '../Page'
import { renderPageComponents } from '../Page/helpers'

export type ContentCollectionComponentProps = {
  readonly data: ContentfulContentCollection
  readonly location?: PageProps['location']
  readonly pageContext?: PageProps['pageContext']
}

function ContentCollectionComponent({
  data,
  location,
  pageContext
}: ContentCollectionComponentProps) {
  const contents = (prop('contents', data) || []).filter(isNotNil)

  return (
    <>
      {/* @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulBannerContentfulDevice... Remove this comment to see the full error message */}
      {renderPageComponents(pageContext, location)(contents)}
    </>
  )
}

export default ContentCollectionComponent
