import { Column, Row, Text } from '@simplisafe/ss-react-components'
import React from 'react'

import ContentfulRichText from '../../ContentfulRichText'
import { PaymentState } from '../usePayment'

type CardVerificationNoteProps = {
  readonly cardVerificationNote?: { readonly raw?: string }
  readonly paymentState: PaymentState
}

function CardVerificationNote(props: CardVerificationNoteProps): JSX.Element {
  return props.cardVerificationNote && props.paymentState === 'ready' ? (
    <Row key="payment-verification-note" padding="small">
      <Column
        backgroundColor="neutralLightGray"
        justifySelf="center"
        padding="small"
      >
        <Text maxWidth={true} textColor="neutralBlack">
          <ContentfulRichText raw={props.cardVerificationNote.raw} />
        </Text>
      </Column>
    </Row>
  ) : (
    <></>
  )
}

export default CardVerificationNote
