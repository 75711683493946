import { toTextPositionValue } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import {
  selectPromoDiscountText,
  selectPromoWithMonitoringDiscountText
} from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { Carousel } from '@simplisafe/ss-react-components'
import { CarouselProps } from '@simplisafe/ss-react-components/Carousel'
import {
  HeroSlideProps,
  LinkItem,
  PreloadLink
} from '@simplisafe/ss-react-components/HeroSlide'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Maybe, None } from 'monet'
import allPass from 'ramda/src/allPass'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import filter from 'ramda/src/filter'
import has from 'ramda/src/has'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import multiply from 'ramda/src/multiply'
import toLower from 'ramda/src/toLower'
import unless from 'ramda/src/unless'
import when from 'ramda/src/when'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { pipe } from 'ts-functional-pipe'

import {
  ContentfulAsset,
  ContentfulCarousel,
  ContentfulImageWithArtDirection
} from '../../../graphql'
import { GtmData, sendGtmCustomEvent } from '../../util/analytics'
import { getBadgeDiscount } from '../BadgeText'
import ContentfulRichText from '../ContentfulRichText'
import ImageWithArtDirection from '../ImageWithArtDirection'
import { toFloatingBadge } from './utils'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type CarouselComponentProps = {
  readonly data: ContentfulCarousel
}

const getLinkUrl = prop('linkUrl')

const getContent = (keyDescription: string) => {
  return applySpec({
    // TODO: Gatsby 4 rich text - HeroSlide in react-components needs to be updated to render IContent's content prop instead of its deprecated description prop
    content: data => {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      const description = has(keyDescription, data)
        ? path([keyDescription, 'raw'], data)
        : data.description?.raw
      return <ContentfulRichText raw={description} />
    },
    title: prop('title')
  })
}

const getDisplayMode = (isMobile: boolean) =>
  ifElse(
    equals(true),
    always(pipe(prop('textPositionMobile'), toTextPositionValue)),
    always(pipe(prop('textPosition'), toTextPositionValue))
  )(isMobile)

const getBannerImage = (
  banner: ContentfulCarousel['banner'],
  isMobile: boolean
): Maybe<ContentfulAsset> =>
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<number | (() => string) | (() => strin... Remove this comment to see the full error message
  ifElse(
    equals(true),
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    always(
      safePath(['mediaMobile', '0'], banner)
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        .orElse(safePath(['media', '0'], banner))
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        .filter(img => !!prop('gatsbyImageData', img))
    ),
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    always(
      safePath(['media', '0'], banner)
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        .orElse(safePath(['backgroundImage', '0'], banner))
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        .filter(img => !!prop('gatsbyImageData', img))
    )
  )(isMobile)

const getImageWithArtDirection = (
  banner: ContentfulCarousel['banner']
): Maybe<ContentfulImageWithArtDirection> =>
  // TODO: fix this type
  // imageWithArtDirection does not exist on type banner
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<number | ContentfulBannerContentfulBan... Remove this comment to see the full error message
  Maybe.fromNull(banner && prop('imageWithArtDirection', banner))

const getHeroSlide = (keyDescription: string, isMobile: boolean) => {
  const toContent = getContent(keyDescription)
  const toTextColor = ifElse(
    equals('neutralWhite'),
    always('light'),
    always('dark')
  )

  return applySpec<HeroSlideProps>({
    BackgroundComponent: (banner: ContentfulCarousel['banner']) => {
      const newImage = getImageWithArtDirection(banner)
        .map(() => ImageWithArtDirection)
        .orUndefined()
      const oldImage = getBannerImage(banner, isMobile)
        .map(() => BgImage)
        .orUndefined()
      return newImage ? newImage : oldImage
    },
    backgroundComponentProps: (banner: ContentfulCarousel['banner']) => {
      const newImageProps = getImageWithArtDirection(banner)
        .map(data => ({ data: data }))
        .orUndefined()
      const oldImageProps = getBannerImage(banner, isMobile)
        .map(img => ({
          alt: prop('description', img),
          image: getImage(prop('gatsbyImageData', img)),
          loading: 'eager',
          title: prop('title', img)
        }))
        .orUndefined()
      return newImageProps ? newImageProps : oldImageProps
    },
    content: pipe(unless(isNil, toContent), defaultTo({})),
    displayMode: getDisplayMode(isMobile),
    linkText: prop('linkText'),
    linkUrl: getLinkUrl,
    preload: () => None<PreloadLink>(),
    textColor: pipe(
      path(['textColor', 'color']),
      when(isNil, always('dark')),
      toTextColor
    )
  })
}

const toCarouselContainerData = applySpec({
  autoPlaySpeed: pipe(
    prop('switchDuration'),
    when(isNil, always(4)),
    multiply(1000)
  ),
  displayBadgeIcon: prop('displayDiscountBadge'),
  paginationPosition: pipe(prop('paginationPosition'), toLower),
  playButton: prop('playButton')
})

const toMediaType = (data: ContentfulCarousel) => {
  const toBanner = path(['banner', 0], data)
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const maybeBannerType = safePath(['internal', 'type'], toBanner)
  // @ts-expect-error TS(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
  return maybeBannerType.getOrElse('') === 'ContentfulBannerVideo'
    ? 'video'
    : 'image'
}

const getCarouselData = (
  contentfulCarousel: ContentfulCarousel,
  isPromoTopBanner: boolean,
  placeholders: Record<string, string>,
  isMobile: boolean
) => {
  const toDesktopHeroSlide = getHeroSlide('descriptionDesktop', isMobile)
  const toMobileHeroSlide = getHeroSlide('descriptionMobile', isMobile)
  const displayDiscountBadge = prop('displayDiscountBadge')
  const toCarousel = applySpec<CarouselProps>({
    carouselContainerData: toCarouselContainerData,
    floatingBadge: ifElse(
      allPass([displayDiscountBadge, always(!isPromoTopBanner)]),
      x => toFloatingBadge(x, placeholders),
      always(undefined)
    ),
    mediaType: toMediaType,
    slideData: pipe(prop('banner'), map(toDesktopHeroSlide)),
    // @ts-expect-error TS(2345) FIXME: Argument of type '(list: readonly unknown[]) => He... Remove this comment to see the full error message
    slideMobileData: pipe(
      prop('banner'),
      filter(has('descriptionMobile')),
      map(toMobileHeroSlide)
    )
  })
  return toCarousel(contentfulCarousel)
}

const setLinkGtmCustomEvent = (linkItem: LinkItem) => {
  const gtmData: GtmData = {
    event: 'buttonClick',
    eventAction: linkItem.id === '#' ? 'play' : linkItem.name,
    eventCategory: linkItem.id === '#' ? 'video' : 'cta',
    eventLabel: linkItem.id === '#' ? linkItem.url : linkItem.name
  }
  sendGtmCustomEvent(gtmData)
}

export default function CarouselComponent({ data }: CarouselComponentProps) {
  const [placeholders, setPlaceholders] = useState<Record<string, string>>({})
  const isMobile = !useMediaQuery('TabletAndUp')
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountTextWithMonitoring: Maybe<string> = useSelector(
    selectPromoWithMonitoringDiscountText
  )
  const discountTextWithoutMonitoring: Maybe<string> = useSelector(
    selectPromoDiscountText
  )
  const discountText = discountTextWithMonitoring.orElse(
    discountTextWithoutMonitoring
  )

  const handleFailure = () => {
    setPlaceholders({})
  }

  const onClickLink = useCallback((linkItem: LinkItem) => {
    setLinkGtmCustomEvent(linkItem)
  }, [])

  useEffect(() => {
    discountText.cata(handleFailure, text =>
      setPlaceholders(getBadgeDiscount(text))
    )
  }, [discountText])
  return (
    <Carousel
      {...getCarouselData(data, isPromoTopBanner, placeholders, isMobile)}
      onClickLink={onClickLink}
    />
  )
}

export const query = graphql`
  #graphql
  fragment Carousel on ContentfulCarousel {
    id
    internal {
      type
    }
    title
    displayDiscountBadge
    promoBadge {
      text {
        raw
        references {
          ... on ContentfulPlaceholder {
            ...placeholderFragment
          }
        }
      }
      promoCode
    }
    discountBadgeUrl
    paginationPosition
    playButton
    switchDuration
    banner {
      ... on ContentfulBanner {
        internal {
          type
        }
        id
        textPosition
        title
        mediaPosition
        linkText
        linkUrl
        description {
          raw
        }
        media {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          id
          title
        }
        mediaMobile {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          id
          title
        }
        textColor {
          color
        }
      }
      # TODO the only "Banner - Video" in Contentful has a jpg for an asset, and the video is embedded in a RichText
      # field (that gets opened in a popup?) (for desktop only). Could we switch this over to something else and get
      # rid of this content type entirely?
      ... on ContentfulBannerVideo {
        internal {
          type
        }
        id
        textColor {
          color
        }
        textPosition
        title
        backgroundImage {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          id
          title
        }
        imageWithArtDirection {
          ... on ContentfulImageWithArtDirection {
            ...imageWithArtDirection
          }
        }
        descriptionMobile {
          raw # todo
        }
        descriptionDesktop {
          raw # todo
        }
      }
    }
  }
`
