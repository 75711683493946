import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import {
  selectActivePromoDiscountTextWithOverrides,
  selectActivePromoEndTime,
  selectLocale,
  selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'
import { z } from 'zod'

import promoBannerSchema from './promoBannerSchema'

const usePromoBannerData = () => {
  const bannerProps = useSelector(selectPromotionBanner)

  const data = promoBannerSchema.parse({
    buttonBackgroundColor: bannerProps
      .chain(chainProp('buttonBackgroundColor'))
      .getOrElse(''),
    buttonTextColor: bannerProps
      .chain(chainProp('buttonTextColor'))
      .getOrElse(''),
    discountText: useSelector(
      selectActivePromoDiscountTextWithOverrides
    ).getOrElse(''),
    displayEndTimeCalloutOverride: bannerProps
      .chain(safeProp('displayEndTimeCalloutOverride'))
      .getOrElse(false),
    endTime: useSelector(selectActivePromoEndTime).getOrElse(''),
    endTimeCalloutOverride: bannerProps
      .chain(chainProp('endTimeCalloutOverride'))
      .getOrElse(''),
    hasCountdown: bannerProps.chain(safeProp('hasCountdown')).getOrElse(false),
    hasEmailInput: bannerProps
      .chain(safeProp('hasEmailInput'))
      .getOrElse(false),
    hasEndDateCallout: bannerProps
      .chain(safeProp('hasEndDateCallout'))
      .getOrElse(false),
    hasFreeGiftItem: bannerProps
      .chain(safeProp('hasFreeGiftItem'))
      .getOrElse(false),
    hasSaleName: bannerProps.chain(safeProp('hasSaleName')).getOrElse(false),
    lineItemLabel: bannerProps
      .chain(chainProp('freeGiftItemSecondaryText'))
      .getOrElse(''),
    lineItemTitle: bannerProps
      .chain(chainProp('freeGiftItemPrimaryText'))
      .getOrElse(''),
    locale: useSelector(selectLocale),
    offerLabel: bannerProps
      .chain(chainProp('discountSecondaryText'))
      .getOrElse(''),
    offerTitle: useSelector(selectActivePromoDiscountTextWithOverrides)
      .map(discountText => `${discountText} off`)
      .getOrElse(''),
    saleName: bannerProps.chain(chainProp('saleName')).getOrElse('')
  })

  // extract the inferred type
  type data = z.infer<typeof promoBannerSchema>

  return data
}

export default usePromoBannerData
