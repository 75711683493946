import React from 'react'

import ReviewBlock from './ReviewBlock'
import { ReviewGridComponentProps } from './types'

function ContentfulReviewGridComponent({ data }: ReviewGridComponentProps) {
  const { rightContent, leftContent, rowOrderParity } = data
  const dataComponent = 'ReviewGrid'
  return (
    <section
      className="grid gap-4 md:grid-cols-2"
      data-component={dataComponent}
    >
      <ReviewBlock
        className={rowOrderParity ? 'order-1 md:order-first' : ''}
        content={leftContent}
        key="review_block_1"
      />
      <ReviewBlock content={rightContent} key="review_block_2" />
    </section>
  )
}

export default React.memo(ContentfulReviewGridComponent)
