import { usePromoOverrideData } from '@lib/components'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import {
  selectPartnerBanner,
  selectPartnerBannerLoading
} from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoDiscountText,
  selectActivePromoLoading,
  selectMonthsOfServiceDisplay,
  selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import { navigate } from 'gatsby'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import { PageProps } from './types'

export function usePromoBannerData() {
  const isActivePromoLoading = useSelector(selectActivePromoLoading)
  const isPartnerBannerLoading = useSelector(selectPartnerBannerLoading)
  const partnerBanner = useSelector(selectPartnerBanner)
  const monthsOfServiceDisplay = useSelector(
    selectMonthsOfServiceDisplay(true, true)
  )
  const discountText = useSelector(selectActivePromoDiscountText).map(
    text => `${text} off`
  )
  const lineItemText = useSelector(selectPromotionBanner).chain(
    chainProp('freeGiftItemPrimaryText')
  )
  const overrideData = usePromoOverrideData()
  const isHolidayBanner = safePath(['isHolidayBanner'], overrideData).getOrElse(
    false
  )
  return {
    discountText,
    isActivePromoLoading,
    isHolidayBanner,
    isPartnerBannerLoading,
    lineItemText,
    monthsOfServiceDisplay,
    partnerBanner
  }
}

export function usePartnerShopRedirect(location: PageProps['location']) {
  const partnerShopURL = useMemo(
    () => getValueFromPartnerCookie('customShopUrl'),
    []
  )
  const pathname = useMemo(
    () => safePath(['pathname'], location).orJust(''),
    []
  )
  useEffect(() => {
    isNotNil(partnerShopURL) &&
      pathname === '/home-security-shop-packages' &&
      navigate(partnerShopURL)
  }, [pathname, partnerShopURL])
}
