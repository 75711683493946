import { FormField, SSButton } from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly label: string
  readonly disabled: boolean
}

function Submission({ disabled, label }: Props) {
  return (
    <div data-component={'Submission'}>
      <FormField hideLabel={true} label={label} name="submit">
        <SSButton disabled={disabled} minWidth="full" type="submit">
          {label}
        </SSButton>
      </FormField>
    </div>
  )
}

export default Submission
