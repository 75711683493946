import { GatsbyImage } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { selectMiniCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { Carousel } from '@simplisafe/ss-react-components'
import {
  HeroSlideProps,
  IDisplayMode,
  PreloadLink
} from '@simplisafe/ss-react-components/HeroSlide'
import { graphql } from 'gatsby'
import { List, None } from 'monet'
import propEq from 'ramda/src/propEq'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  ContentfulDynamicPackageFloorplanBanner,
  ContentfulPdpFloorplanCarouselSlide
} from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type DynamicPackageFloorplanBannerComponentProps = {
  readonly data: ContentfulDynamicPackageFloorplanBanner
}

export default function DynamicPackageFloorplanBannerComponent({
  data
}: DynamicPackageFloorplanBannerComponentProps) {
  const miniCart = useSelector(selectMiniCart)
  const miniCartLineItems = List.from(miniCart.orJust([]))

  const possibleSlides = prop('possibleSlides', data)
  const heroSlideProps: readonly HeroSlideProps[] | undefined =
    possibleSlides &&
    possibleSlides
      .map((possibleSlide: ContentfulPdpFloorplanCarouselSlide) =>
        miniCartLineItems
          .find(
            propEq('masterSku', path(['systemComponent', 'sku'], possibleSlide))
          )
          .map(() => {
            const title: string = prop('blurbTitle', data) || ''
            const displayMode: IDisplayMode = 'left'
            const textColor: 'dark' | 'light' = 'dark'
            const childrenPosition: 'center' | 'left' | 'right' = 'right'
            const backgroundColor = 'gray' as const
            const alt: string | undefined = path(
              ['systemComponent', 'displayName'],
              possibleSlide
            )
            const key: string | undefined = path(['image', 'id'], possibleSlide)

            return {
              backgroundColor,
              children: (
                <GatsbyImage
                  // @ts-expect-error TS(2322) FIXME: Type '{ alt: string; image: ContentfulAsset; key: ... Remove this comment to see the full error message
                  alt={alt || ''}
                  image={possibleSlide?.image}
                  key={key}
                />
              ),
              childrenPosition: childrenPosition,
              content: {
                // TODO: Gatsby 4 rich text - HeroSlide in react-components needs to render this `content` prop instead of `description` when present, and
                // there's a bunch of custom rich text options defined in HeroSlide.tsx that will need to be handled somehow as well.
                content: <ContentfulRichText raw={possibleSlide?.blurb?.raw} />,
                title: title
              },
              displayMode: displayMode,
              preload: None<PreloadLink>(),
              textColor: textColor
            }
          })
      )
      .filter(x => x.isSome())
      .map(x => x.just())

  return miniCart
    .map(_miniCart => {
      return heroSlideProps ? (
        <Carousel
          carouselContainerData={{
            arrows: true,
            autoplay: false,
            dotsColor: 'blue',
            enableDots: false,
            //TODO should be configurable from content
            paginationPosition: 'below-text',
            playButton: false
          }}
          enableInlineArrow={true}
          key={data.id} // we're not mapping over an array, but eslint still sees that we're calling something called map and wants a key
          mediaType={'image'}
          slideData={heroSlideProps} // TODO csims
          // TODO needs a separate mobile version? Really?
          slideMobileData={heroSlideProps} // TODO csims
        />
      ) : null
    })
    .orNull()
}

export const query = graphql`
  #graphql
  fragment dynamicPackageFloorplanBanner on ContentfulDynamicPackageFloorplanBanner {
    id
    internal {
      type
    }
    blurbTitle
    possibleSlides {
      blurb {
        raw
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 833, placeholder: BLURRED)
        id
      }
      systemComponent {
        displayName
        sku
      }
    }
  }
`
