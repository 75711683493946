import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import pathOr from 'ramda/src/pathOr'

/**
 * Parses arrays coming in from Contentful that might be nil and contain nil, and returns an array that is correctly typed.
 *
 * For example, this:
 * ```js
 * Maybe<Array<Maybe<ContentfulCheckoutFormSectionContentfulFormsUnion>>>
 * ```
 *
 * Becomes this:
 * ```js
 * ReadonlyArray<ContentfulCheckoutFormSectionContentfulFormsUnion>
 * ```
 *
 * Remember, `Maybe` from Contentful is not an actual `Maybe`, it just means the value could be `null` or `undefined`.
 */
export function parseArray<T>(
  array?: ReadonlyArray<T | null | undefined> | null | undefined
): ReadonlyArray<T> {
  return Array.isArray(array) && isNotNil(array) ? array.filter(isNotNil) : []
}

/**
 * An easy way to do `propOr('')` for values that need to fall back to an empty string.
 *
 * Note: this does not enforce the type the object returns, but the type returned is accurate.
 *
 * It is expected that you only use this with a string value.
 */
export const stringProp = <T, U extends string & keyof T>(key: U, obj?: T) =>
  obj && isNotNil(obj[key]) ? obj[key] : ''

/**
 * An easy way to do `pathOr('')` for values that need to fall back to an empty string.
 *
 * Note: This does not enforce or check the type the object returns and just always marks the type as string.
 */
export const stringPath =
  // TODO: replace this with a better version of path at some point.
  // @ts-expect-error TS(2558) FIXME: Expected 1 type arguments, but got 2.
  <T>(keys: readonly string[], obj?: T) =>
    pathOr<string, string>('', keys, obj || {})
