import * as Promo from '@simplisafe/ss-react-components/promotions'
import React from 'react'

import type { ActivePromoBannerType } from '.'
import CartLayout from './CartLayout'
import MobileMinimizedLayout from './MobileMinimizedLayout'

export type MobileTopBannerProps = {
  readonly isFormSubmitted: boolean
  readonly onRedeemClick: () => void
  readonly type: ActivePromoBannerType
}

function MobileTopBanner({
  isFormSubmitted,
  onRedeemClick,
  type
}: MobileTopBannerProps) {
  return (
    <Promo.PromoBanner
      data-component="MobileTopBanner"
      position="top"
      role="banner"
    >
      {type === 'cart' ? (
        <CartLayout />
      ) : (
        <MobileMinimizedLayout
          isFormSubmitted={isFormSubmitted}
          onRedeemClick={onRedeemClick}
        />
      )}
    </Promo.PromoBanner>
  )
}

export default MobileTopBanner
