import { SSButton } from '@simplisafe/ss-react-components'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import classNames from 'classnames'
import React from 'react'

export type CartHeaderProps = {
  readonly title: string
  readonly link: {
    readonly linkText: string
    readonly linkUrl: string
  }
  readonly checkoutButton: SSButtonProps
  readonly disabledCartSubmit?: boolean
}

function Header({
  title,
  link,
  checkoutButton,
  disabledCartSubmit
}: CartHeaderProps) {
  return (
    <div className="md:flex md:items-center md:border-y-0 md:justify-between md:pt-[35px] md:px-0 md:pb-[25px] lg:pt-0 lg:px-0 lg:pb-[25px]">
      <h2 className={classNames('h3', 'text-[24px] md:text-[36px] md:mb-0')}>
        {title}
      </h2>
      <div className="hidden md:!flex items-center justify-between">
        <SSButton
          className="mr-[16px] w-auto p-0 text-[#006fee] min-h-[auto]"
          color="link"
          href={link?.linkUrl}
          type="link"
        >
          {' '}
          {link?.linkText}{' '}
        </SSButton>
        <SSButton
          {...checkoutButton}
          className="w-auto min-h-[auto]"
          disabled={disabledCartSubmit}
          type="link"
        />
      </div>
    </div>
  )
}

export default Header
