import { handleGTMTrackingEvent } from '@lib/tracking'
import type { TrackingData } from '@lib/tracking'
import isNotUndefined from '@simplisafe/ewok/ramda-adjunct/isNotUndefined'
import { GeoJSONSourceOptions } from 'mapbox-gl'
import { Just, Maybe, None } from 'monet'
import { ifElse } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'

import CrimeRiskAssessment from './CrimeRiskAssessment'
import HeroBanner from './HeroBanner'
import Map from './Map'
import { CrimeLocationResultsProps, IRiskDataModel } from './types'

const AUGURISK_API = process.env.AUGURISK_API
const AUGURISK_API_KEY = process.env.AUGURISK_API_KEY

export default function CrimeLocationResultsComponent({
  data,
  location
}: CrimeLocationResultsProps) {
  const [error, setError] = useState<string>('')
  const [mapError, setMapError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  // eslint-disable-next-line no-undefined
  const [geojsonData, setGeojsonData] =
    useState<GeoJSONSourceOptions['data']>(undefined)
  const [riskData, setRiskData] = useState<Maybe<IRiskDataModel>>(None())

  const lat = Number(new URLSearchParams(location?.search || '').get('lat'))
  const lon = Number(new URLSearchParams(location?.search || '').get('lon'))
  const address = new URLSearchParams(location?.search || '').get('address')

  const searchCrimeRisk = useCallback(() => {
    const apiRoute =
      !isNaN(lat) && !isNaN(lon)
        ? `getRisksMetered/?latitude=${lat}&longitude=${lon}`
        : `/getRisksMetered/?address=${address}`

    // eslint-disable-next-line promise/catch-or-return
    fetch(`${AUGURISK_API}v1/${apiRoute}`, {
      headers: { Apikey: AUGURISK_API_KEY || '' },
      method: 'get'
    })
      .then(response => response.json())
      // eslint-disable-next-line promise/always-return
      .then((actualData: IRiskDataModel) => {
        ifElse(
          isNotUndefined,
          () => setError(actualData.message || ''),
          () => {
            setRiskData(Just(actualData))
            setError('')
            const trackingData: TrackingData = {
              appSection: 'CrimeInTheUS',
              event: 'crime_score',
              scorePropertyCrime: actualData.crimeData?.propertyCrimeScore,
              scoreViolentCrime: actualData.crimeData?.violentCrimeScore
            }
            trackingData.scorePropertyCrime &&
              trackingData.scoreViolentCrime &&
              handleGTMTrackingEvent(trackingData)
          }
        )(actualData.message)
      })
      .catch(({ message }: { readonly message: string }) => {
        setRiskData(None())
        setError(message)
      })
      .finally(() => {
        setLoading(false)
      })

    !isNaN(lat) &&
      !isNaN(lon) &&
      fetch(
        `${AUGURISK_API}v1/layers-radius/crime/blockgroup/?lon=${lon}&lat=${lat}&radius=10000&srid=4326&riskData=true`,
        {
          headers: { Apikey: AUGURISK_API_KEY || '' },
          method: 'get'
        }
      )
        .then(response => response.json())
        // eslint-disable-next-line promise/always-return
        .then((actualData: GeoJSONSourceOptions['data']) => {
          setGeojsonData(actualData)
        })
        .catch(({ message }: { readonly message: string }) => {
          setMapError(message)
        })
  }, [address, lat, lon])

  useEffect(() => {
    searchCrimeRisk()
  }, [searchCrimeRisk])

  return (
    <>
      <HeroBanner
        address={address || ''}
        data={data}
        error={error}
        loading={loading}
        riskData={riskData.orUndefined()}
      />

      {geojsonData && (
        <div className="max-w-8xl lg:px-7 m-auto mt-5">
          <Map
            data={data}
            error={mapError}
            geojsonData={geojsonData}
            latitude={Number(lat)}
            longitude={Number(lon)}
            zoom={12}
          />
        </div>
      )}

      <div className="border-solid border mx-12 border-gray-100 my-8 d:my-14"></div>

      {riskData.cata(
        () => null,
        data => (
          <CrimeRiskAssessment riskData={data} />
        )
      )}
    </>
  )
}
